import React from 'react'

//Router dom
import { Link } from 'react-router-dom'

//MUI
import { Alert, Box, Button, Card, CardActions, CardContent, CardMedia, Container, Divider, Fab, Grid, Tooltip, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';

import img from '../../../assets/images/Productos_Nefropolis/albúmina-huevo-300g_1.png'
import img_1 from '../../../assets/images/Productos_Nefropolis/acetato-calcio_1.png'
import img_2 from '../../../assets/images/Productos_Nefropolis/simbin-rnl_1.png'
import img_3 from '../../../assets/images/Productos_Nefropolis/Simbin-Gel-1.png'
import img_4 from '../../../assets/images/Productos_nueva_imagen/1bebida-alta-200g.png'

const ofertas = [
    {
        id: 1,
        name: 'Albumina de huevo natural NIN 300g',
        price: 1200,
        NewPrice: 1000,
        expirationDate: '20/12/2023',
        img: img
    },
    {
        id: 2,
        name: 'Acetato de calcio NIN',
        price: 499,
        NewPrice: 399,
        expirationDate: '20/12/2023',
        img: img_1
    },
    {
        id: 3,
        name: 'Simbin RNL 60 capsulas',
        price: 1300,
        NewPrice: 1099,
        expirationDate: '20/12/2023',
        img: img_2
    },
    {
        id: 4,
        name: 'Simbin RNL gel',
        price: 1301,
        NewPrice: 999,
        expirationDate: '20/12/2023',
        img: img_3
    },
    {
        id: 5,
        name: 'Bebida de arroz alta',
        price: 300,
        NewPrice: 260,
        expirationDate: '20/12/2023',
        img: img_4
    }
]

const FlashSale = () => {
    return (
        <Container maxWidth='xl' sx={{ mb: 5, mt: '90px' }}>
            <Typography variant='h5' sx={{ fontWeight: 'light' }} paragraph>Orfertas relámpago</Typography>
            {
                ofertas.length > 0
                    ?
                    <Grid container spacing={4}>
                        {
                            ofertas.map((res) => (
                                <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={res.id}>
                                    <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
                                        <CardMedia
                                            sx={{ height: 200, backgroundSize: 'contain' }}
                                            image={res.img}
                                            title={res.img}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="body1" sx={{ fontWeight: 'bold' }}>
                                                {res.name}
                                            </Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', flexWrap: 'nowrap' }}>
                                                <Typography variant='h6' sx={{ color: '#F90606' }}>
                                                    <strike>${new Intl.NumberFormat('en-US').format(res.price)}</strike>
                                                </Typography>
                                                <Typography variant='body1' sx={{ my: 'auto', color: '#B12704' }}>
                                                    ${new Intl.NumberFormat('en-US').format(res.NewPrice)}
                                                </Typography>
                                            </Box>
                                            <Divider sx={{ my: 1 }} />
                                            <Typography>
                                                Fecha de expiración: {res.expirationDate}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button>Editar</Button>
                                            <Button>Eliminar</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                    :
                    <Alert severity="info">No existen ofertas relámpago vigentes</Alert>
            }
            <Tooltip title='Nueva oferta relámpago' placement='left'>
                <Fab color="primary" aria-label="add"
                    LinkComponent={Link} to="/nefro-admin/nueva-oferta-relampago"
                    sx={{
                        '&:hover': {
                            color: '#fff'
                        },
                        position: "fixed",
                        bottom: 20,
                        right: 20,
                        zIndex: 1
                    }}>
                    <AddIcon />
                </Fab>
            </Tooltip>
        </Container>
    )
}

export default FlashSale