import { createContext, useState } from "react";

export const NavContext = createContext()

const { Provider } = NavContext

const MyProvider = ({ children }) => {

    const pathname = window.location.pathname

    const [value, setValue] = useState(pathname)

    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorMenu, setAnchorMenu] = useState(null);
    const [anchorUser, setAnchorUser] = useState(null);

    return (
        <Provider value={{ value, setValue, setAnchorUser, anchorUser, anchorEl, setAnchorEl, anchorMenu, setAnchorMenu }}>
            {children}
        </Provider>
    )
}

export default MyProvider