import React, { useState, useEffect } from 'react';

import { ODOO_PRODUCT_COLUMNS } from '../../../../constants/constants';
import { odooProductList } from '../../../../actions/admin/odoo_product';

import MaterialTable, { MTableToolbar } from "material-table";
import {
    Container, Typography, Box, Alert, AlertTitle
    // Stack
} from '@mui/material';

const OdooProductList = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        loadList();
    }, []);

    // cargar lista de productos de Odoo (ID y nombre)
    const loadList = async() => {
        const req = await odooProductList();
        if (req.status) setData(req.resp);
    };

    return (
        <Container maxWidth='xxl' sx={{ mb: 5 }} style={{ marginTop: '90px' }}>
            {/* <Stack direction="row" spacing={2}>
                <Typography variant='h4' sx={{ fontWeight: 'light' }}>
                    Productos en Odoo
                </Typography>
                <Alert variant="outlined" severity="info">La información proviene de Odoo.</Alert>
            </Stack> */}

            <Alert variant="outlined" severity="info">
                <AlertTitle>
                    <Typography variant='h4' sx={{ fontWeight: 'light' }}>
                        Productos en Odoo
                    </Typography>
                </AlertTitle>
                <Typography variant="button" display="block" gutterBottom>
                    La información mostrada proviene de Odoo, exceptuando el campo indicado.
                </Typography>
            </Alert>
            {/* Tabla de Pedidos */}
            <Box sx={{ width: '100%', height: '100%', mt: 3 }}>
                <MaterialTable
                    title="Lista de productos"
                    data={data}
                    columns={ODOO_PRODUCT_COLUMNS}
                    options={{
                        pageSize: 200,
                        exportButton: true,
                        filtering: true,
                        headerStyle: {
                            zIndex: 1
                        },
                    }}
                    components={{
                        Toolbar: props => (<MTableToolbar {...props} classes={{ root: "dummy-class" }} />),
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "No hay datos por mostrar",
                            addTooltip: 'Agregar',
                            deleteTooltip: 'Eliminar',
                            editTooltip: 'Editar',
                            filterRow: {
                                filterTooltip: 'Filtrar'
                            },
                            editRow: {
                                deleteText: '¿Segura(o) que quiere eliminar?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Guardar'
                            }
                        },
                        grouping: {
                            placeholder: "Arrastre un encabezado aquí para agrupar",
                            groupedBy: 'Agrupado por:'
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'filas',
                            labelRowsPerPage: 'Filas por página:',
                            firstAriaLabel: 'Primera página',
                            firstTooltip: 'Primera página',
                            previousAriaLabel: 'Página anterior',
                            previousTooltip: 'Página anterior',
                            nextAriaLabel: 'Página siguiente',
                            nextTooltip: 'Página siguiente',
                            lastAriaLabel: 'Última página',
                            lastTooltip: 'Última página'
                        },
                        toolbar: {
                            addRemoveColumns: 'Agregar o eliminar columnas',
                            nRowsSelected: '{0} fila(s) seleccionada(s)',
                            showColumnsTitle: 'Mostrar columnas',
                            showColumnsAriaLabel: 'Mostrar columnas',
                            exportTitle: 'Exportar',
                            exportAriaLabel: 'Exportar',
                            exportName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            searchTooltip: 'Buscar',
                            searchPlaceholder: 'Buscar'
                        }
                    }}
                />
            </Box>
            {/* Fin Tabla de Pedidos */}
        </Container>
    );
};

export default OdooProductList;