import React, { useState } from 'react'

//MUI
import { Grid, Card, CardActions, CardContent, CardMedia, Button, Typography, TextField, InputAdornment } from '@mui/material'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';

const CardSucursal = ({ sucursal }) => {

    //Search
    const [search, setNewSearch] = useState("");
    const handleSearchChange = (e) => {
        setNewSearch(e.target.value);
    };
    const filtered = !search
        ? sucursal
        : sucursal.filter((suc) =>
            suc.name.toLowerCase().includes(search.toLowerCase())
            ||
            suc.location.toString().toLowerCase().includes(search.toLowerCase())
            ||
            suc.locationAccented.toString().toLowerCase().includes(search.toLowerCase())
        );

    return (
        <>
            <TextField
                fullWidth
                value={search}
                onChange={handleSearchChange}
                size="small"
                placeholder="Buscar sucursal por nombre o estado"
                InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                }}
                variant="standard" sx={{ mb: 2 }}
            />
            <Grid container spacing={4}>
                {filtered.map((suc, index) => (
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index}>
                        <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
                            <CardMedia
                                component="img"
                                height="140"
                                image={suc.img}
                                alt={suc.img}
                                sx={{ backgroundSize: 'contain' }}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Sucursal {suc.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <LocalPhoneIcon /> {suc.phone}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <WhatsAppIcon /> {suc.whatsapp}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" component={Link} to={`${suc.link}`}>Ver más</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default CardSucursal