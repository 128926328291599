import { types } from '../types/types';

const USER = {
    _id: '0',
    email: '',
    username: '',
    platform: '',
    page: 'index'
};

const initialState = {
    loggedUser: USER,
    loading: true
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.userLoggedIn:
            return {
                ...state,
                loggedUser: action.payload,
                loading: false
            }
        case types.userLoggedOut:
            return {
                ...state,
                loggedUser: USER,
                loading: false
            }
        case types.loadingDone:
            return {
                ...state,
                loading: false
            }
        case types.clearRdrPage:
            return {
                ...state,
                loggedUser: {
                    ...state.loggedUser,
                    page: ''
                }
            }
        default:
            return state;
    }
};