import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { gapi } from 'gapi-script';
// import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { signUp } from '../../../../actions/user';
import {
    // basicMsg,
    msg
} from '../../../../actions/swal_msg';

//CSS
import '../SignUp.css'

//IMG
import logo_mobile from '../../../../assets/images/Logo.png'
import logo_desktop from '../../../../assets/images/Logo_Nefropolis.png'

import { regex_email } from '../../../../constants/regex';

//MUI
import {
    FormControl, TextField, InputLabel, OutlinedInput, InputAdornment,
    IconButton, Container, Box, Typography, FormControlLabel, Checkbox,
    Button, Grid, Link
} from '@mui/material';

const clientId = process.env.REACT_APP_CLIENT_ID;
const init = {
    username: '',
    email: '',
    password: ''
};

const Registro = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [values, setValues] = useState(init);
    const { username, email, password } = values;
    const [togglePasword, setTogglepassword] = useState(false);

    useEffect(() => {
        const initClient = () => {
            gapi.auth2.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    }, []);

    // éxito respuesta de Google oAUth
    // const onSuccess = (res) => {
    //     dispatch(signUp({
    //         googleId: res.googleId,
    //         username: res.profileObj.name,
    //         email: res.profileObj.email,
    //         page: props.page
    //         // tokenId: res.tokenId
    //     }));

    // };
    // const onFailure = (err) => {
    //     basicMsg('No se pudo conectar con Google');
    // };

    // éxito respuesta registro con Facebook
    // const responseFacebook = (response) => {
    //     if (!response.status) {
    //         dispatch(signUp({
    //             facebookId: response.userID,
    //             username: response.name,
    //             email: response.email,
    //             page: props.page
    //         }));
    //     }
    // };

    // cambios formulario
    const handleChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        setValues({ ...values, [name]: value });
    };

    const submit = () => {
        if (formValidator())
            dispatch(signUp({
                ...values,
                googleId: '',
                facebookId: '',
                page: props.page
            }));
    };

    // validación formulario
    const formValidator = () => {
        const user_len = username.trim().length;
        const email_len = email.trim().length;
        const pwd_len = password.trim().length;
        if (user_len > 2 && user_len <= 100) {
            if (email_len > 5 && email_len <= 120
                && regex_email.test(email)) {
                if (pwd_len > 4 && pwd_len <= 60)
                    return true;
                else
                    msg('warning', 'Registro',
                    'Ingrese una contraseña segura');
            } else
                msg('warning', 'Registro',
                'Ingrese su correo electrónico');
        } else
            msg('warning', 'Registro',
            'Ingrese un nombre de ususario');
        return false;
    };

    const togglePassword = () => {
        setTogglepassword(!togglePasword);
    };

    return (
        <div className="login-bg-img">
            <Container maxWidth='xl'>
                {/* Logo */}
                <Box sx={{ textAlign: 'center' }}>
                    <img className="login-logo-desktop" src={logo_desktop} width="25%" alt="logo_desktop" />
                    <img className="login-logo-mobile" src={logo_mobile} width="55%" alt="logo_mobile" />
                </Box>

                <Box sx={{ mt: 4 }}>
                    <Typography variant='h5' sx={{ fontWeight: 'light' }} align='center'>Crear cuenta</Typography>
                </Box>

                {/* Formulario */}
                <Box sx={{ mt: 3 }} className="form-register">

                    {/* Nombre */}
                    <FormControl fullWidth sx={{ mb: 2.5 }}>
                        <TextField id="" label="Nombre y apellidos" variant="outlined"
                            name='username'
                            value={username}
                            onChange={handleChange} />
                    </FormControl>

                    {/* Correo electronico */}
                    <FormControl fullWidth sx={{ mb: 2.5 }}>
                        <InputLabel>Correo electrónico</InputLabel>
                        <OutlinedInput
                            id="pass-input"
                            type='email'
                            placeholder=""
                            label="Correo electrónico"
                            name='email'
                            value={email}
                            onChange={handleChange}
                        />
                    </FormControl>

                    {/* Contraseña con metodo para mostrar y ocultar */}
                    <FormControl fullWidth>
                        <InputLabel>Contraseña</InputLabel>
                        <OutlinedInput
                            id="pass-input1"
                            type={togglePasword ? "text" : "password"}
                            placeholder="Contraseña"
                            style={{ backgroundColor: '#fff' }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <i className="fa-solid fa-key"></i>
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={togglePassword}

                                        edge="end"
                                    >
                                        <i className={togglePasword ? 'far fa-eye' : 'far fa-eye-slash'}></i>
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña"
                            name='password'
                            value={password}
                            onChange={handleChange}
                        />
                    </FormControl>

                    {/* Recordar contraseña */}
                    <Box>
                        <FormControlLabel control={<Checkbox defaultChecked={false} size="small" />} label="Recordar contraseña" />
                    </Box>

                    {/* button continuar con registro */}
                    <Box sx={{ mt: 2.5, textAlign: 'center' }}>
                        <Button variant='contained' sx={{ fontWeight: '600', width: '100%', height: '55px' }} onClick={submit}>
                            <span style={{ fontSize: '13px' }}>Crear cuenta</span>
                        </Button>
                        {/* onClick={props.nextstep} */}
                    </Box>
                </Box>

                {/* <Box sx={{ mt: 3.5, textAlign: 'center' }}>
                    <Typography variant='body1'>O también puedes registrarte con</Typography>
                </Box> */}

                {/* Buttons social-register desktop */}
                {/* <Box sx={{ mt: 3.5, textAlign: 'center' }} className="buttons-desktop-register">
                    <div>
                        <GoogleLogin
                            clientId={clientId}
                            render={renderProps => (
                                <Button onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    variant='contained' color='inherit' sx={{ textTransform: 'none', mb: 2 }}>
                                    <i className="fab fa-google-plus-g" style={{ marginRight: '8px' }}></i> Registrarse con Google</Button>
                            )}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                    <div>
                        <FacebookLogin
                            appId="805831810847865"
                            fields="name,email,picture"
                            callback={responseFacebook}
                            language='es_MX'
                            render={renderProps => (
                                <Button onClick={renderProps.onClick}
                                    variant='contained' sx={{ textTransform: 'none' }}>
                                    <i className="fab fa-facebook-f" style={{ marginRight: '8px' }}></i> Registrarse con Facebook</Button>
                            )} />
                    </div>
                </Box> */}

                {/* Buttons social-register mobile */}
                <Box sx={{ pt: 3, textAlign: 'center', m: 'auto' }} className="buttons-mobile-register">
                    <Grid container spacing={0}>
                        <Grid item xs={6} sx={{ m: 'auto' }}>
                            <Button variant='outlined' sx={{ width: '60px', height: '60px', borderRadius: '100%' }}>
                                <i className="fab fa-facebook-f" style={{ fontSize: 20 }}></i>
                            </Button>
                            {/* onClick={props.nextstep} */}
                        </Grid>
                    </Grid>

                    {/* <Box sx={{ mt: 3.5, textAlign: 'center' }}>
                        <Typography variant='body1'>O también puedes registrarte con</Typography>
                    </Box> */}

                    {/* Buttons social-register desktop */}
                    {/* <Box sx={{ mt: 3.5, textAlign: 'center' }} className="buttons-desktop-register">
                        <div>
                            <GoogleLogin
                                clientId={clientId}
                                render={renderProps => (
                                    <Button onClick={renderProps.onClick}
                                        disabled={renderProps.disabled}
                                        variant='contained' color='inherit' sx={{ textTransform: 'none', mb: 2 }}>
                                        <i className="fab fa-google-plus-g" style={{ marginRight: '8px' }}></i> Registrarse con Google</Button>
                                )}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>
                        <div>
                            <FacebookLogin
                                appId="805831810847865"
                                fields="name,email,picture"
                                callback={responseFacebook}
                                language='es_MX'
                                render={renderProps => (
                                    <Button onClick={renderProps.onClick}
                                        variant='contained' sx={{ textTransform: 'none' }}>
                                        <i className="fab fa-facebook-f" style={{ marginRight: '8px' }}></i> Registrarse con Facebook</Button>
                                )} />
                        </div>
                    </Box> */}

                    {/* Buttons social-register mobile */}
                    {/* <Box sx={{ pt: 3, textAlign: 'center', m: 'auto' }} className="buttons-mobile-register">
                        <Grid container spacing={0}>
                            <Grid item xs={6} sx={{ m: 'auto' }}>
                                <Button variant='outlined' sx={{ width: '60px', height: '60px', borderRadius: '100%' }}>
                                    <i className="fab fa-facebook-f" style={{ fontSize: 20 }}></i>
                                </Button>
                            </Grid>

                            <Grid item xs={6} sx={{ m: 'auto' }}>
                                <Button variant='outlined' sx={{ width: '60px', height: '60px', borderRadius: '100%' }}>
                                    <i className="fab fa-google-plus-g" style={{ fontSize: 20 }}></i>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box> */}

                    {/* Link iniciar sesión */}
                    <Box sx={{ mt: 3, textAlign: 'center' }}>
                        <Typography variant='body1'>
                            Ya tienes cuenta? <u>
                                <Link component="button"
                                    style={{ color: '#2699FB' }}
                                    onClick={() => {
                                        navigate("/login", { state: { page: props.page } })
                                    }}>Iniciar sesión
                                </Link>
                            </u>
                        </Typography>
                    </Box>

                    {/* Avisos de privacidad */}
                    <Box sx={{ mt: 3, textAlign: 'center' }}>
                        <Typography variant='body1'>
                            Al crear una cuenta aceptas las <u>
                                <Link component="button"
                                    style={{ color: '#2699FB' }}
                                    onClick={() => {
                                        navigate("/terminos-y-condiciones")
                                    }}>condiciones de uso
                                </Link>
                            </u> y el <u>
                                <Link component="button"
                                    style={{ color: '#2699FB' }}
                                    onClick={() => {
                                        navigate("/politica-de-privacidad")
                                    }}>aviso de privacidad
                                </Link>
                            </u> de Nefrópolis
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </div>
    );
};

export default Registro;