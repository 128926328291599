import { blockInEdition, blogInEdition } from '../constants/admin/blog_constants';
import { types } from '../types/types';

const initialState = {
    blog: [],
    categories: [],
    blockInEdition,
    blogInEdition,
    userBlog: [],
};

export const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.blogLoaded:
            return {
                ...state,
                blog: [...action.payload]
            };
        case types.blogSaved:
            return {
                ...state,
                blog: [...state.blog, action.payload]
            };
        case types.blogUpdated:
            return {
                ...state,
                blog: state.blog.map(e =>
                    (e._id === action.payload._id) ?
                        { ...e, ...action.payload }
                    : e
                )
            };
        case types.blogInEdition:
            return {
                ...state,
                blogInEdition: {
                    ...state.blogInEdition,
                    ...action.payload
                }
            };
        case types.blogDeleted:
            return {
                ...state,
                blog: state.blog.filter(
                    e => (e._id !== action.payload)
                )
            };
        case types.blogCategoriesLoaded:
            return {
                ...state,
                categories: [...action.payload]
            };
        case types.blogCategorySaved:
            return {
                ...state,
                categories: [...state.categories, action.payload]
            }
        case types.blogBlockInEdition:
            return {
                ...state,
                blockInEdition: {
                    ...state.blockInEdition,
                    ...action.payload
                }
            }
        case types.userBlogLoaded:
            const { posts, categories } = action.payload
            return {
                ...state,
                userBlog: posts,
                categories
            };
        default:
            return state;
    }
};