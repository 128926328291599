import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { basicTimerMsg } from '../../../../actions/swal_msg';
import { blogCategoriesLoaded, saveBlogCategory } from '../../../../actions/admin/blog';

import {
    List, ListItem, ListItemButton, ListItemText,
    ListItemAvatar, Grid, Checkbox, Avatar,
    Stack, TextField, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BookIcon from '@mui/icons-material/Book';

const BlogCategory = () => {
    const dispatch = useDispatch();
    const { categories } = useSelector(state => state.blog);
    const [category, setCategory] = useState('');
    const [categoryLength, setCategoryLength] = useState(0);

    useEffect(() => {
        if (categories.length !== categoryLength)
            setCategory('');
        setCategoryLength(categories.length);
    }, [categories]);

    // campo categoría
    const handleOnChange = ({ target }) => {
        setCategory(target.value);
    };

    // guardar categoría
    const save = () => {
        if (category.length > 3 && category.length <= 100)
            dispatch(saveBlogCategory(category));
        else basicTimerMsg('Ingrese nombre de categoría');
    };

    // categoría seleccionada
    const handleToggle = _id => () => {
        dispatch(blogCategoriesLoaded(
            categories.map(e => e._id === _id ? { ...e, checked: !e.checked } : e)
        ));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {categories.map(({ _id, category, checked }) => {
                        const labelId = `checkbox-list-secondary-label-${_id}`;
                        return (
                            <ListItem
                                key={_id}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        onChange={ handleToggle(_id) }
                                        checked={ checked }
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                }
                                disablePadding>
                                <ListItemButton>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <BookIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText id={labelId} primary={ category } />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>
            <Grid item xs={12} md={4}>
                <Stack direction="row" spacing={2}>
                    <TextField fullWidth
                        variant='outlined'
                        label="Nueva categoría"
                        size='small'
                        name='category'
                        inputProps={{ maxLength: 100 }}
                        value={ category }
                        onChange={ handleOnChange }
                    />
                    <IconButton aria-label="add"
                        onClick={ save }>
                        <AddIcon />
                    </IconButton>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default BlogCategory;