import React, { useRef } from 'react'

import './InsuficienciaRenal.css'

import nr from '../../assets/images/asesoria_nr2.png'

import { useReactToPrint } from "react-to-print";

//MUI
import {
    Box, Button, Container, Fade, Grid, Link, Paper, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useScrollTrigger
} from '@mui/material'
import PrintIcon from '@mui/icons-material/Print';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';

//Componente para imprimir contenido
import PrintComponent from './PrintComponent';

const NrButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#6BC900',
    '&:hover': {
        backgroundColor: '#58a400',
    },
}));

function createData(predisponentes, iniciadores, perpetuadores) {
    return { predisponentes, iniciadores, perpetuadores };
}

const rows = [
    createData('>60 años', 'Diabetes', 'Proteinuria'),
    createData('Antecedentes familiares', 'Hipertensión arterial sistémica', 'Tensión arterial >130 mm/Hg'),
    createData('Síndrome metabólico', 'AINES', 'Obesidad'),
    createData('Obesidad', '', 'Ingesta elevada de proteínas'),
    createData('Ingesta elevada de proteínas', '', 'Dislipidemia'),
    createData('Dislipidemia', '', 'Enfermedad cardiovascular'),
    createData('Anemia', '', ''),
];

function ScrollTop(props) {

    const matches = useMediaQuery('(max-width: 425.9px)')

    const { win } = props;
    const trigger = useScrollTrigger({
        target: win ? window() : undefined,
        disableHysteresis: true,
        threshold: 1200,
    });

    const handleClick = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                // sx={{ position: 'fixed', bottom: matches ? 230 : 140, right: 0 }}
                sx={matches ? { position: 'fixed', bottom: 85, left: 0 } : { position: 'fixed', bottom: 50, right: 0 }}
            >
                <button className='article_btn'>
                    <KeyboardArrowUpIcon />
                </button>
            </Box>
        </Fade>
    );
}

const InsuficienciaRenal = (props) => {

    const matches_2 = useMediaQuery('(max-width: 950px)')

    const element_1 = useRef(null);
    const element_2 = useRef(null);
    const element_3 = useRef(null);
    const element_4 = useRef(null);
    const element_5 = useRef(null);
    const element_6 = useRef(null);
    const element_7 = useRef(null);
    const element_8 = useRef(null);
    const element_9 = useRef(null);
    const element_10 = useRef(null);
    const element_11 = useRef(null);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    return (
        <>
            <Container maxWidth='xl' className='last-view' sx={{ mt: matches_2 ? 5 : 18 }}>
                <Grid container spacing={4}>
                    <Grid item xl={8.5} lg={8.5} md={12} sm={12} xs={12}>
                        <Box sx={{ p: 3, bgcolor: '#f5f5f5', borderRadius: '5px' }}>
                            <Typography variant='h6' sx={{ fontWeight: '600' }} gutterBottom>Contenido:</Typography>
                            <ul style={{ marginBottom: 0 }}>
                                <li>
                                    <Link
                                        component="button"
                                        underline="hover"
                                        sx={{ all: 'unset', color: '#1976d2' }}
                                        onClick={() => {
                                            element_1.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >
                                        Funciones del riñón
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        component="button"
                                        underline="hover"
                                        sx={{ all: 'unset', color: '#1976d2' }}
                                        onClick={() => {
                                            element_2.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >
                                        ¿Qué es la Insuficiencia Renal Crónica?
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        component="button"
                                        underline="hover"
                                        sx={{ all: 'unset', color: '#1976d2' }}
                                        onClick={() => {
                                            element_3.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >
                                        Causas y sintomas de la ERC
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        component="button"
                                        underline="hover"
                                        sx={{ all: 'unset', color: '#1976d2' }}
                                        onClick={() => {
                                            element_4.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >
                                        Etapas de la ERC
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        component="button"
                                        underline="hover"
                                        sx={{ all: 'unset', color: '#1976d2' }}
                                        onClick={() => {
                                            element_5.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >
                                        Hemodiálisis
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        component="button"
                                        underline="hover"
                                        sx={{ all: 'unset', color: '#1976d2' }}
                                        onClick={() => {
                                            element_6.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >
                                        Diálisis Peritoneal
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        component="button"
                                        underline="hover"
                                        sx={{ all: 'unset', color: '#1976d2' }}
                                        onClick={() => {
                                            element_7.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >
                                        Trasplante renal
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        component="button"
                                        underline="hover"
                                        sx={{ all: 'unset', color: '#1976d2' }}
                                        onClick={() => {
                                            element_8.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >
                                        Factores de Riesgo
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        component="button"
                                        underline="hover"
                                        sx={{ all: 'unset', color: '#1976d2' }}
                                        onClick={() => {
                                            element_9.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >
                                        Prevención
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        component="button"
                                        underline="hover"
                                        sx={{ all: 'unset', color: '#1976d2' }}
                                        onClick={() => {
                                            element_10.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >
                                        Tratamiento
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        component="button"
                                        underline="hover"
                                        sx={{ all: 'unset', color: '#1976d2' }}
                                        onClick={() => {
                                            element_11.current.scrollIntoView({ behavior: 'smooth' });
                                        }}
                                    >
                                        Alimentación
                                    </Link>
                                </li>
                            </ul>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                            <Button onClick={handlePrint} startIcon={<PrintIcon />} sx={{ textTransform: 'none' }}>Imprimir</Button>
                        </Box>

                        <Box>
                            <Box sx={{ mt: 3 }} ref={element_1}>
                                <Typography sx={{ fontWeight: '700', fontSize: '26px', color: '#3498DB' }} paragraph>Funciones del riñón</Typography>
                                <Typography paragraph>
                                    Los riñones son 2 órganos vitales en forma de frijol ubicados justo debajo
                                    del torax y son importantes para el adecuado funcionamiento de nuestro cuerpo,
                                    las actividades que desempeñan van desde la eliminación de líquidos y toxinas
                                    hasta la producción de hormonas. En promedio, el riñón de un adulto mide
                                    entre 11 y 13 cm y pesa entre 125 a 170 g en hombres y entre 115 a 155 g en
                                    mujeres 19. Cada uno de los riñones está formado por aproximadamente 1 millón
                                    de células llamadas nefronas.
                                </Typography>
                                <Typography paragraph>
                                    Los riñones no sólo limpian la sangre, sino que también tienen un rol vital en
                                    la salud al desempeñar funciones importantes que involucran a todas las partes
                                    del cuerpo:
                                </Typography>
                                <ul className='list_margin'>
                                    <li>
                                        <b>Mantienen en equilibrio los líquidos:</b> Ayudan a eliminar parte de los líquidos
                                        que se toman durante el día a través de la orina, evitando que se acumulen
                                        en el cuerpo.
                                    </li>
                                    <li>
                                        <b>Regulan los electrolitos:</b> Los electrolitos son minerales como el sodio, potasio
                                        y fósforo, se encuentran en el cuerpo y viajan a través de la sangre. Aunque intervienen
                                        en muchos procesos importantes, el exceso de estos electrolitos puede ser de riesgo
                                        para la salud, por lo que los riñones se encargan de mantenerlos en equilibrio12.
                                    </li>
                                    <li>
                                        <b>Eliminan los desechos:</b> Después de la digestión y absorción de nutrientes, quedan
                                        algunas toxinas que deben ser eliminadas por el organismo. Los riñones al formar la orina,
                                        van a incluir en ella estas toxinas. Lo mismo harán con los residuos de medicamentos que
                                        se toman.
                                    </li>
                                    <li>
                                        <b>Promueven el equilibrio ácido-base:</b> Por diversos procesos en el cuerpo que incluyen
                                        el metabolismo de algunos alimentos, el cuerpo mantiene un medio ácido, el cual implica un
                                        riesgo para la salud, por lo que los riñones identifican este problema y se encargan de
                                        corregirlo regulando la cantidad de bicarbonato que se produce; este bicarbonato es la
                                        sustancia que va a ayudar a minimizar el ambiente ácido en el cuerpo.
                                    </li>
                                    <li>
                                        <b>Controlan la presión arterial:</b> Los riñones ayudan a mantener niveles adecuados de
                                        presión arterial, identifican si es necesario elevarla o disminuirla.
                                    </li>
                                    <li>
                                        <b>Mejoran la utilización de vitamina D:</b> El cuerpo obtiene parte de vitamina D del sol;
                                        sin embargo, los riñones tienen un papel muy importante en su producción y aprovechamiento.
                                        Un daño en la función renal conllevaría a tener deficiencia de vitamina D.
                                    </li>
                                    <li>
                                        <b>Contribuyen al equilibrio del calcio en los huesos:</b> Dentro de los minerales regulados
                                        por los riñones se encuentra el calcio. Los riñones están encargados de su eliminación o
                                        reabsorción en caso de ser necesario. Unos riñones sanos permiten que el calcio permanezca
                                        en los huesos de manera correcta manteniéndolos fuertes y resistentes.
                                    </li>
                                    <li>
                                        <b>Estimulan la producción de glóbulos rojos:</b> Los riñones se encargan de fabricar una
                                        hormona llamada eritropoyetina. Esta hormona es la que estimula la creación de glóbulos
                                        rojos, también llamados eritrocitos, previniendo la anemia19.
                                    </li>
                                </ul>
                            </Box>

                            <Box sx={{ mt: 5 }} ref={element_2}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#3498DB' }} paragraph>¿Qué es la Insuficiencia Renal Crónica?</Typography>
                                <Typography paragraph>
                                    La Insuficiencia Renal crónica o Enfermedad Renal Crónica (ERC) es
                                    un daño permanente y progresivo de los riñones que reduce su capacidad
                                    para llevar acabo las funciones que un riñón sano realiza. La enfermedad
                                    es progresiva, porque el daño avanza lentamente hasta llegar a la falla
                                    total. Este daño produce que los desechos se acumulen en el cuerpo, y
                                    ocasione otros problemas de salud como la elevación de la presión arterial,
                                    la retención de liquidos, anemia, desnutrición, enfermedades oseas, entre
                                    otras complicaciones.
                                </Typography>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    ¿Qué tan común es?
                                </Typography>
                                <Typography>
                                    Afecta a 850 millones de personas en todo el mundo.
                                    En México, uno de cada diez adultos la padece.
                                    Se prevé que en 2040 se convierta en la quinta causa más común de años de vida perdidos
                                    en todo el mundo.
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 5 }} ref={element_3}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#3498DB' }} paragraph>Causas y sintomas de la ERC</Typography>
                                <Typography>
                                    Las principales causas de la Enfermedad Renal crónica son:
                                </Typography>
                                <ul>
                                    <li>
                                        Diabetes
                                    </li>
                                    <li>
                                        Hipertensión
                                    </li>
                                    <li>
                                        Obesidad
                                    </li>
                                </ul>
                                <Typography>
                                    Y existen otras causas menos frecuentes como:
                                </Typography>
                                <ul>
                                    <li>
                                        Abuso de medicamentos
                                    </li>
                                    <li>
                                        La presencia de Lupus o quistes
                                    </li>
                                </ul>
                                <Typography paragraph>
                                    La enfermedad renal crónica en sus etapas iniciales suele no presentar síntomas. Los síntomas
                                    se desarrollan a medida que avanza la enfermedad.
                                </Typography>
                                <Typography>
                                    Algunos síntomas presentes en etapas avanzadas son:
                                </Typography>
                                <ul>
                                    <li>Hinchazón</li>
                                    <li>Pérdida de Apetito</li>
                                    <li>Pérdida de peso</li>
                                    <li>Dolor torácico</li>
                                    <li>Dolores de cabeza</li>
                                    <li>Cansancio</li>
                                    <li>Falta de aliento</li>
                                    <li>Piel seca</li>
                                    <li>Picazón o entumecimiento</li>
                                    <li>Calambres musculares</li>
                                    <li>Naúseas y vómito</li>
                                    <li>Problemas para dormir</li>
                                    <li>Problemas de concentración</li>
                                </ul>
                            </Box>

                            <Box sx={{ mt: 5 }} ref={element_4}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#3498DB' }} paragraph>Etapas de la ERC</Typography>
                                <Typography paragraph>
                                    La Enfermedad Renal Crónica, se clasifica en 5 categorías, según el grado de funcionamiento
                                    de los riñones:
                                </Typography>
                                <Typography paragraph>
                                    <b>Etapa 1:</b> También conocida como etapa temprana, la función de los riñones puede parecer
                                    normal, pero están ya presentes signos de daño renal temprano como es la presencia de proteína
                                    o albúmina en la orina. El tratamiento para esta etapa se centra en mejorar el estilo de vida
                                    y la alimentación, además de controlar los problemas de salud previos como son diabetes,
                                    obesidad e hipertensión arterial.
                                </Typography>
                                <Typography paragraph>
                                    <b>Etapa 2:</b> Se observa una pérdida de la función leve, pero la enfermedad sigue siendo
                                    silenciosa. El tratamiento también se centra en detener el avance de la enfermedad con una
                                    alimentación saludable, buena hidratación, actividad física, evitar el tabaco y la
                                    automedicación.
                                </Typography>
                                <Typography paragraph>
                                    <b>Etapa 3:</b> La pérdida de la función del riñón es moderada, y se observa un funcionamiento
                                    renal menor al 60%. En esta etapa es común observar algunas complicaciones como presión
                                    arterial elevada, anemia, desnutrición y material general. Su tratamiento requiere de una
                                    alimentación reducida en proteínas.
                                </Typography>
                                <Typography paragraph>
                                    <b>Etapa 4:</b> El funcionamiento del riñon va del 15 al 29%. El tratamiento podría incluir
                                    la preparación para el ingreso a tratamiento de diálisis, hemodiálisis o bien a recibir un
                                    trasplante de riñon.
                                </Typography>
                                <Typography>
                                    <b>Etapa 5:</b> Conocida como etapa terminal, la función de los riñones es menos al 15%,
                                    es decir, ninguno de los dos riñones trabaja para lo suficiente para mantener saludable
                                    el organismo. Debe se tratada como tratamiento de diálisis peritoneal, hemodiálisis o
                                    trasplante de riñon que busca sustituir en gran medida las funciones vitales de los riñones
                                    y por supuesto, requiere de la aderencia a una alimnentación saludable y especializada para
                                    mantener el equilibrio en el cuerpo.
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 5 }} ref={element_5}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#3498DB' }} paragraph>Hemodiálisis</Typography>
                                <Typography paragraph>
                                    En hemodiálisis, es necesario colocar quirúrgicamente un acceso al torrente sanguíneo
                                    con alguna de las siguientes opciones:
                                </Typography>
                                <ul className='list_margin'>
                                    <li>
                                        Un catéter que se coloca en una vena grande del cuello o de la ingle.
                                    </li>
                                    <li>
                                        Una fístula que se realiza uniendo una arteria a una vena cercana debajo de la piel para
                                        formar un vaso sanguíneo más grande.
                                    </li>
                                </ul>
                                <Typography>
                                    De esta manera, el paciente acude al hospital o clínica de hemodiálisis donde el personal
                                    de enfermería conectará el catéter o fístula a una máquina de hemodiálisis. La sangre es
                                    bombeada a través de tubos blandos hacia un filtro especial que deja pasar las toxinas
                                    y el líquido extra que hay en el cuerpo del paciente. Posteriormente, la sangre limpia
                                    regresa al cuerpo. Este proceso toma alrededor de 4 horas para alcanzar a filtrar toda
                                    la sangre y se realiza en promedio 3 veces por semana17.
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 5 }} ref={element_6}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#3498DB' }} paragraph>Diálisis Peritoneal</Typography>
                                <Typography paragraph>
                                    Para llevar a cabo la diálisis peritoneal es necesario colocar quirúrgicamente
                                    un catéter en el abdomen para introducir una solución de diálisis. Existen 3
                                    tipos de solución de diálisis de acuerdo a su capacidad de extracción de toxinas
                                    y líquido:
                                </Typography>
                                <ul>
                                    <li>Amarilla</li>
                                    <li>Verde</li>
                                    <li>Roja</li>
                                </ul>
                                <Typography paragraph>
                                    El peritoneo es una capa que sirve de recubrimiento para los órganos del abdomen, el cual,
                                    durante la diálisis peritoneal, actúa como un filtro natural. Al introducir la solución
                                    de diálisis, el peritoneo deja pasar las toxinas y el líquido extra de la sangre a través
                                    de sí mismo para quedarse en la solución de diálisis. Para realizar correctamente su trabajo,
                                    la solución de diálisis debe permanecer en la cavidad abdominal cierta cantidad de horas,
                                    dependiendo la necesidad de cada persona y la modalidad de DP utilizada. Una vez transcurrido
                                    el tiempo de permanencia, la solución (que ahora contiene las toxinas y el líquido sobrante)
                                    es drenada desde el catéter hacia una bolsa vacía para ser desechada e infundir una
                                    siguiente solución de diálisis; a esto se le llama “recambio”16.
                                </Typography>
                                <Typography paragraph>
                                    Existen 3 modalidades diferentes de diálisis peritoneal:
                                </Typography>
                                <Typography paragraph>
                                    <b>Diálisis Peritoneal Continua Ambulatoria (DPCA):</b> Es la más comúnmente utilizada.
                                    En esta modalidad de DP, el llenado y el drenaje de la solución se realizan por gravedad,
                                    es decir, que no se utiliza ninguna máquina de apoyo. El propio paciente se realiza los
                                    recambios de manera manual, o en su defecto, si el paciente necesita ayuda, algún familiar
                                    realiza el procedimiento. Para esta modalidad se utilizan bolsas de 2 L haciendo recambios
                                    en un promedio de 4 al día.
                                </Typography>
                                <Typography paragraph>
                                    <b>Diálisis Peritoneal Automatizada (DPA):</b> En esta modalidad el paciente o familiar
                                    conecta el catéter a un equipo llamado “máquina cicladora” por la noche, antes de dormir.
                                    La máquina cicladora realizará los recambios mientras duerme durante un aproximado de 8
                                    horas, llenando la cavidad abdominal con solución nueva y drenándola en forma automática.
                                    De esta manera, a la mañana siguiente se procede a la desconexión. Para esta modalidad se
                                    utilizan bolsas de 6 L.
                                </Typography>
                                <Typography>
                                    <b>Diálisis Peritoneal Intermitente (DPI):</b> Consiste en realizar varios recambios cortos
                                    durante un determinado tiempo. Esta DPI la realiza el personal de enfermería dentro de las
                                    instalaciones de una clínica u hospital y de manera intermitente, es decir, que no se realiza
                                    todos los días, sino que se dejan varios días o incluso semanas para volver a acudir a una DPI.
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 5 }} ref={element_7}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#3498DB' }} paragraph>Trasplante renal</Typography>
                                <Typography paragraph>
                                    El trasplante de riñón es un procedimiento quirúrgico que consiste en colocar un riñón
                                    sano proveniente de un donador vivo o fallecido en una persona cuyos riñones ya no
                                    funcionan correctamente.
                                </Typography>
                                <Typography paragraph>
                                    No todos los pacientes pueden ser candidatos para recibir un trasplante renal por diversas
                                    razones que el nefrólogo evaluará en su momento para determinar si es viable o no el
                                    trasplante:
                                </Typography>
                                <ul>
                                    <li>Situaciones de riesgo previo a la cirugía</li>
                                    <li>Edad avanzada</li>
                                    <li>Riesgos asociados a la inmunosupresión</li>
                                    <li>Algunas otras enfermedades</li>
                                </ul>
                                <Typography>
                                    Además, existen varias pruebas que se deben realizar antes de la cirugía para determinar
                                    que el riñón sano sea compatible con el paciente y así disminuir las probabilidades de
                                    rechazo del órgano.
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 5 }} ref={element_8}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#3498DB' }} paragraph>Factores de Riesgo</Typography>
                                <Typography paragraph>
                                    Para un diagnóstico oportuno el primer paso es identificar los factores de riesgo, se
                                    recomienda un monitoreo cercano en todos aquellos que presenten uno o más factores
                                    de riesgo, particularmente quienes viven con diabetes mellitus, hipertensión, enfermedad
                                    cardiovascular, infección por VIH o hepatitis.
                                </Typography>
                                <TableContainer component={Paper} sx={{ mb: 3 }} className='tab_table'>
                                    <Table sx={{ width: '100%' }}>
                                        <TableHead sx={{ bgcolor: '#34A571' }}>
                                            <TableRow>
                                                <TableCell align='center' sx={{ color: '#fff' }}>Predisponentes</TableCell>
                                                <TableCell align='center' sx={{ color: '#fff' }}>Iniciadores</TableCell>
                                                <TableCell align='center' sx={{ color: '#fff' }}>Perpetuadores</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow
                                                    key={row.predisponentes}
                                                >
                                                    <TableCell>
                                                        {row.predisponentes}
                                                    </TableCell>
                                                    <TableCell>{row.iniciadores}</TableCell>
                                                    <TableCell>{row.perpetuadores}</TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Typography gutterBottom>OTROS FACTORES</Typography>
                                <ul>
                                    <li>Drogas Nefrotóxicas</li>
                                    <li>Infecciones Crónicas</li>
                                    <li>Infecciones Sistémicas</li>
                                    <li>Ciertos tipos de Cáncer</li>
                                    <li>Enfermedades Autoinmunes</li>
                                    <li>Litiasis Recurrente</li>
                                    <li>Pielonefritis Crónica</li>
                                    <li>Masa Renal Disminuida</li>
                                    <li>Trasplante Renal Previo</li>
                                </ul>
                                <Typography gutterBottom>El tamizaje de la Enfermedad Renal debe incluir:</Typography>
                                <ul>
                                    <li>Medición de la presión arterial.</li>
                                    <li>Medición de la creatinina sérica, para estimar la tasa de filtración glomerular
                                        con alguna fórmula apropiada para el método. Por ejemplo CKD-EPI.</li>
                                    <li>Evaluación de albuminuria y proteinuria.</li>
                                    <li>Examen general de orina.</li>
                                </ul>
                                <Typography>
                                    En el caso de encontrar anormalidades en la función renal se debe confirmar el diagnóstico
                                    tres meses después de iniciar tratamiento. Si el resultado es normal, se debe tratar los
                                    factores de riesgo identificados y realizar evaluaciones anuales de detección temprana.
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 5 }} ref={element_9}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#3498DB' }} paragraph>Prevención</Typography>
                                <Typography paragraph>Para la prevención de la Enfermedad renal, existen 7 reglas de oro:</Typography>
                                <ol className='list_margin'>
                                    <li>Hacerse chequeos regulares: Asistir a nuestro médico de confianza cada vez que sea posible.
                                        Debido a que es una enfermedad silenciosa, para detectarla de manera oportuna o descartar
                                        el riesgo, se recomienda hacerse estudios de laboratorio por lo menos una vez al año.
                                    </li>
                                    <li>Regular la presión arterial: La presión arterial alta, puede dañar los riñones y aumentar
                                        la posibilidad de tener enfermedad renal. Hacer cambios en el estilo de vida como reducir
                                        el consumo de sal, alcohol, perder peso y hacer ejercicio puede mantener la presión arterial
                                        en buenos niveles.
                                    </li>
                                    <li>Mantener la glucosa en niveles saludables:  Con el tiempo, los niveles elevados de glucosa
                                        en sangre, pueden hacer que los vasos sanguineos dentro del riñón se estrechen y se tapen,
                                        lo que puede dañar los riñones.
                                    </li>
                                    <li>Llevar una alimentación saludable.</li>
                                    <li>Realizar Ejercicio: Caminar, practicar deporte y hacer ejercicio aerobico
                                        (trotar, nadar, caminar, andar en bicicleta).
                                    </li>
                                    <li>Dejar de Fumar: El tabaco causa enfermedades en todos los órganos del cuerpo incluido
                                        los riñones.
                                    </li>
                                    <li>No automedicarse: El uso prolongado de AINES (Aintinflamatorios No esteroideos) como el
                                        iboprofeno, diclofenaco, etc. en dosis altas reduce el flujo en sangre en el riñón, lo
                                        que causa daños en el tejido renal. </li>
                                </ol>
                            </Box>

                            <Box sx={{ mt: 5 }} ref={element_10}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#3498DB' }} paragraph>Tratamiento</Typography>
                                <ul>
                                    <li>Corregir los factores causales o de progresión que sean reversibles o evitables:
                                        Evitar la automedicación, Dejar de fumar, realizar ejercicio frecuente, mantenerse
                                        bien hidratado, controlar la presión arterial y la glucosa en sangre.</li>
                                    <li>Apegarse a medidas reno protectoras: Para reducir la carga de trabajo de los riñones
                                        para que se mantentan funcionando por más tiempo.</li>
                                    <li>Tratamiento de complicaciones propias de enfermedad.</li>
                                    <li>Preparar al paciente para el ingreso a diálisis.</li>
                                </ul>
                            </Box>

                            <Box sx={{ mt: 5 }} ref={element_11}>
                                <Typography sx={{ fontWeight: 'bold', fontSize: '26px', color: '#3498DB' }} paragraph>Alimentación</Typography>
                                <Typography paragraph>
                                    La alimentación en cualquier etapa de la vida y en cualquier condición de salud representa
                                    un papel muy importante. Específicamente en ERC, llevar una correcta alimentación de acuerdo
                                    a la etapa de la enfermedad en la que se encuentre una persona, es crucial para un óptimo
                                    tratamiento médico y una mejor evolución de la enfermedad.
                                </Typography>
                                <Typography paragraph>
                                    El nutriólogo especialista conoce la mejor manera de evaluar la situación actual del paciente
                                    para identificar los aspectos a controlar durante la alimentación, incluso si necesita
                                    recomendar algún tipo de suplemento que contribuya a un mejor control nutricio. Existen
                                    muchos componentes a cuidar en la alimentación, sin embargo, hay algunos que comúnmente
                                    se controlan en la mayoría de las etapas de la ERC y que con el apoyo del personal de
                                    nutrición se pueden llegar a controlar de una mejor manera, tales como:
                                </Typography>
                                <Typography paragraph><b>Proteínas:</b> Son sustancias esenciales en la alimentación que permiten
                                    mantener un buen estado de nutrición. En etapas prediálisis es necesario disminuir el consumo de
                                    proteína para liberar la carga a los riñones y evitar que trabajen de más.
                                </Typography>
                                <Typography paragraph><b>Urea:</b> Cuando el cuerpo digiere los alimentos, se forma la urea como
                                    producto de desecho, principalmente de las proteínas de origen animal. Al disminuir la cantidad
                                    de proteínas que se ingieren en etapas prediálisis, puede ayudar a que los riñones trabajen menos
                                    y tengan menos urea que eliminar.
                                </Typography>
                                <Typography paragraph><b>Fósforo:</b> El fósforo es un mineral que se encuentra en los huesos
                                    junto con el calcio, es necesario para el desarrollo de huesos saludables, así como para mantener
                                    sanas otras partes del cuerpo. Sin embargo, cuando la función de los riñones no es adecuada, el
                                    fósforo no se elimina correctamente elevándose de igual manera en sangre.
                                </Typography>
                                <Typography paragraph><b>Potasio:</b> Es un mineral que ayuda a mantener el ritmo cardiaco y al
                                    correcto funcionamiento de los músculos. Sin embargo, cuando los riñones no funcionan bien, es
                                    común que sea necesario limitar ciertos alimentos que lo contengan en grandes cantidades ya que
                                    pueden incrementar el potasio en sangre.
                                </Typography>
                                <Typography paragraph><b>Sodio:</b> Sodio es un mineral que se encuentra naturalmente en comidas,
                                    siendo la sal de mesa apenas una de las distintas fuentes de sodio. El aumento de sodio en el
                                    cuerpo puede manifestarse con sed, lo cual promueve un mayor consumo de líquidos y puede aumentar
                                    el riesgo de que se acumulen en el cuerpo causando edema y problemas cardiacos.
                                </Typography>
                                <Typography paragraph><b>Líquidos:</b> La hidratación es esencial en el cuerpo humano; sin embargo,
                                    en la ERC cuando no se orina de manera correcta, no se equilibran los líquidos en el cuerpo, por
                                    lo que se deberá disminuir la cantidad que se consuma todos los días.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xl={3.5} lg={3.5} md={12} sm={12} xs={12}>
                        <Box className='sticky_fixed'>
                            {/* <Box>
                                <Typography variant='h6' sx={{ fontWeight: '600' }}>Otros temas:</Typography>
                                <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                                    <li><RouterDomLink to='/'>Tema 1</RouterDomLink></li>
                                    <li><RouterDomLink to='/'>Tema 2</RouterDomLink></li>
                                    <li><RouterDomLink to='/'>Tema 3</RouterDomLink></li>
                                </ul>
                            </Box> */}
                            <Box sx={{ mt: 3 }}>
                                <a href="https://nutricionrenal.com/servicios/consulta-en-nutricion-renal"
                                    target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}
                                >
                                    <NrButton sx={{ width: '100%', height: '45px', textTransform: 'none', mb: 2 }}>
                                        Consulta nutrición
                                    </NrButton>
                                </a>
                                <img src={nr} alt={nr} width='100%' style={{ borderRadius: '5px', pointerEvents: 'none' }} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>


            <Box sx={{ display: 'none' }}>
                <PrintComponent ref={componentRef} />
            </Box>

            <ScrollTop {...props} />

        </>
    )
}

export default InsuficienciaRenal