// validaciones para NewAddressComponent.jsx
import { msg } from "../actions/swal_msg";

// validación formulario
export const userShippingAddressFormValidator = ({ name, lastname, tel, street, ext_num,
    int_num, crossing_streets, postalcode, colony, city, state, references }) => {
    const name_len = name.trim().length;
    const lastname_len = lastname.trim().length;
    const tel_len = tel.trim().length;
    const street_len = street.trim().length;
    const ext_len = ext_num.trim().length;
    const int_len = int_num.trim().length;
    const crossing_len = crossing_streets.trim().length;
    const pc_len = postalcode.trim().length;
    const colony_len = colony.trim().length;
    const city_len = city.trim().length;
    const state_len = state.trim().length;
    const ref_len = references.trim().length;
    if (name_len > 1 && name_len <= 60) {
        if (lastname_len > 1 && lastname_len <= 60) {
            if (tel_len > 3 && tel_len <= 10) {
                if (street_len > 5 && street_len <= 100) {
                    if (ext_len > 0 && ext_len <= 4) {
                        if (int_num === '' || (int_len > 0 && int_len <= 8)) {
                            if (crossing_len > 3 && crossing_len <= 200) {
                                if (pc_len > 0 && pc_len <= 5) {
                                    if (colony_len > 2 && colony_len <= 100) {
                                        if (city_len > 2 && city_len <= 100) {
                                            if (state_len > 2 && state_len <= 100) {
                                                if (ref_len > 3 && state_len <= 200)
                                                    return true;
                                                else
                                                    msg('warning', 'Checkout',
                                                    'Ingrese referencias sobre su domicilio');
                                            } else
                                                msg('warning', 'Checkout',
                                                'Ingrese estado de residencia');
                                        } else
                                            msg('warning', 'Checkout',
                                            'Ingrese ciudad de residencia');
                                    } else
                                        msg('warning', 'Checkout',
                                        'Ingrese su colonia');
                                } else {
                                    msg('warning', 'Checkout',
                                    'Ingrese su código postal');
                                }
                            } else
                                msg('warning', 'Checkout',
                                '¿Entre qué calles se encuentra su domicilio?');
                        } else {
                            msg('warning', 'Checkout',
                            'El número interior de su domicilio no es válido');
                        }
                    } else {
                        msg('warning', 'Checkout',
                        'Ingrese número exterior de su domicilio');
                    }
                } else {
                    msg('warning', 'Checkout',
                    'Ingrese la calle de su domicilio');
                }
            } else {
                msg('warning', 'Checkout',
                'Ingrese su número de teléfono');
            }
        } else {
            msg('warning', 'Checkout',
            'Ingrese su apellido');
        }
    } else {
        msg('warning', 'Checkout',
        'Ingrese su nombre');
    }
    return false;
};

export const userUpdShippingAddressFormValidator = ({ _id, name, lastname, tel, street, ext_num,
    int_num, crossing_streets, postalcode, colony, city, state, references }) => {
    const name_len = name.trim().length;
    const lastname_len = lastname.trim().length;
    const tel_len = tel.trim().length;
    const street_len = street.trim().length;
    const ext_len = ext_num.trim().length;
    const int_len = int_num.trim().length;
    const crossing_len = crossing_streets.trim().length;
    const pc_len = postalcode.trim().length;
    const colony_len = colony.trim().length;
    const city_len = city.trim().length;
    const state_len = state.trim().length;
    const ref_len = references.trim().length;
    if (_id && _id !== '') {
        if (name_len > 1 && name_len <= 60) {
            if (lastname_len > 1 && lastname_len <= 60) {
                if (tel_len > 3 && tel_len <= 10) {
                    if (street_len > 5 && street_len <= 100) {
                        if (ext_len > 0 && ext_len <= 4) {
                            if (int_num === '' || (int_len > 0 && int_len <= 8)) {
                                if (crossing_len > 3 && crossing_len <= 200) {
                                    if (pc_len > 0 && pc_len <= 5) {
                                        if (colony_len > 2 && colony_len <= 100) {
                                            if (city_len > 2 && city_len <= 100) {
                                                if (state_len > 2 && state_len <= 100) {
                                                    if (ref_len > 3 && state_len <= 200)
                                                        return true;
                                                    else
                                                        msg('warning', 'Checkout',
                                                        'Ingrese referencias sobre su domicilio');
                                                } else
                                                    msg('warning', 'Checkout',
                                                    'Ingrese estado de residencia');
                                            } else
                                                msg('warning', 'Checkout',
                                                'Ingrese ciudad de residencia');
                                        } else
                                            msg('warning', 'Checkout',
                                            'Ingrese su colonia');
                                    } else {
                                        msg('warning', 'Checkout',
                                        'Ingrese su código postal');
                                    }
                                } else
                                    msg('warning', 'Checkout',
                                    '¿Entre qué calles se encuentra su domicilio?');
                            } else {
                                msg('warning', 'Checkout',
                                'El número interior de su domicilio no es válido');
                            }
                        } else {
                            msg('warning', 'Checkout',
                            'Ingrese número exterior de su domicilio');
                        }
                    } else {
                        msg('warning', 'Checkout',
                        'Ingrese la calle de su domicilio');
                    }
                } else {
                    msg('warning', 'Checkout',
                    'Ingrese su número de teléfono');
                }
            } else {
                msg('warning', 'Checkout',
                'Ingrese su apellido');
            }
        } else {
            msg('warning', 'Checkout',
            'Ingrese su nombre');
        }
    } else {
        msg('warning', 'Checkout',
        'No se encuentra el ID de su direccióm');
    }
    return false;
};