import React, { useState, useEffect } from 'react';

import { Box, Typography } from '@mui/material';

const CartPromotionCountdown = ({ endDate }) => {
    // Cuenta regresiva
    // const endDate = new Date('12-12-2023 00:00:00');
    const [dias, setDias] = useState(0);
    const [horas, setHoras] = useState(0);
    const [minutos, setMinutos] = useState(0);
    const [segundos, setSegundos] = useState(0);
    const [expired, setExpired] = useState(false);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            calculateCountdown();
        }, 1000);

        return () => {
            clearTimeout(timeOut);
        };
    }, [segundos]);

    const getTwoDigitValue = value => {
        if (value < 10) {
            return "0" + value;
        }
        return "" + value;
    };

    const calculateCountdown = () => {
        const startDate = new Date();

        const timeRemaining = endDate.getTime() - startDate.getTime();

        if (timeRemaining > 0) {
            const start_date = new Date(startDate);
            const end_date = new Date(endDate);
            const start_millis = start_date.getTime();
            const end_millis = end_date.getTime();

            const old_sec = start_millis / 1000;
            const current_sec = end_millis / 1000;

            let seconds = current_sec - old_sec;

            let days = Math.floor(seconds / (24 * 60 * 60));
            seconds -= days * 24 * 60 * 60;

            let hours = Math.floor(seconds / (60 * 60));
            seconds -= hours * 60 * 60;

            let minutes = Math.floor(seconds / 60);
            seconds -= minutes * 60;

            days = Math.abs(days);
            hours = Math.abs(hours);
            minutes = Math.abs(minutes);
            seconds = Math.floor(Math.abs(seconds));


            setDias(days);
            setHoras(hours);
            setMinutos(minutes);
            setSegundos(seconds);
        } else setExpired(true);
    };

    const convertedDays = getTwoDigitValue(dias);
    const convertedHours = getTwoDigitValue(horas);
    const convertedMins = getTwoDigitValue(minutos);
    const convertedSeconds = getTwoDigitValue(segundos);

    return (
        <Box>
            {
                expired ?
                    <Typography variant='body1' sx={{color: '#F90606'}}>Oferta expirada</Typography>
                :
                    <Typography variant='body1' sx={{color: '#F90606'}}>Oferta termina en:</Typography>
            }
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '20px', textAlign: 'center' }}>
                <Box>
                    <Typography variant='body1' sx={{ fontWeight: '600', fontFamily: 'sans-serif' }}>{convertedDays}</Typography>
                    <Typography variant='body2'>Días</Typography>
                </Box>
                <Box>
                    <Typography variant='body1' sx={{ fontWeight: '600', fontFamily: 'sans-serif' }}>{convertedHours}</Typography>
                    <Typography variant='body2'>Horas</Typography>
                </Box>
                <Box>
                    <Typography variant='body1' sx={{ fontWeight: '600', fontFamily: 'sans-serif' }}>{convertedMins}</Typography>
                    <Typography variant='body2'>Minutos</Typography>
                </Box>
                <Box>
                    <Typography variant='body1' sx={{ fontWeight: '600', fontFamily: 'sans-serif' }}>{convertedSeconds}</Typography>
                    <Typography variant='body2'>Segundos</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default CartPromotionCountdown;