import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';

import { myOrders, orderSelected } from "../../../actions/user-account";

import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';

import { GOOGLE_DRIVE_CDN } from "../../../constants/constants";

//CSS
import './pedidos.css'

//MUI
import {
    Box, Button, Card, CardActions, CardContent,
    Container, Divider, Grid, Tab, Typography,
    Breadcrumbs, Alert, useMediaQuery
} from '@mui/material';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { styled } from '@mui/material/styles';
import LoopIcon from '@mui/icons-material/Loop';

//Componentes
import BuyAgain from "./BuyAgain/BuyAgain";

const date_options = { year: 'numeric', month: 'long', day: 'numeric' };

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontSize: '15px',
        marginRight: '8px',
    }),
);

const PedidosComponent = () => {
    const matches = useMediaQuery('(max-width:425.9px)');
    const matches_img = useMediaQuery('(max-width: 530px)');

    const matches_2 = useMediaQuery('(max-width: 950px)');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { orders } = useSelector(state => state.user_account);

    useEffect(() => {
        dispatch(myOrders());
    }, []);

    const goToProduct = url => () => {
        navigate(`/tienda/producto/${url}`);
    };

    const orderDetails = order_num => () => {
        const filter = orders.find(e => e.order_num === order_num);
        if (filter) {
            dispatch(orderSelected({ ...filter }));
            navigate('/mi-cuenta/detalles-pedido');
        }
    };

    const orderTracking = order_num => () => {
        const filter = orders.find(e => e.order_num === order_num);
        if (filter)
            navigate(`/rastrear-pedido/${filter.payment.nefro_reference}`, { state: filter })
    };

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Container maxWidth='xl' sx={{ mt: matches_2 ? 5 : 18 }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                    <Link underline="hover" color="inherit" to="/mi-cuenta">
                        Mi cuenta
                    </Link>
                    <Typography color="text.primary">Mis pedidos</Typography>
                </Breadcrumbs>
                <Typography variant='h4' sx={{ fontWeight: 'light' }}>Mis pedidos</Typography>
            </Container>

            <Container maxWidth='xl' sx={{ mt: 3 }} className="last-view">
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <StyledTab label="Pedidos" value="1" />
                            <StyledTab label="Comprar de nuevo" value="2" />
                        </TabList>
                    </Box>
                    {/* Pedidos */}
                    <TabPanel value="1" sx={{ px: 0 }}>
                        {
                            orders.length > 0 ?
                                orders.map(({ order_num, products, shipping, order_date }) => {
                                    const len = products.length;
                                    const today = new Date(order_date);
                                    const status = shipping.esp_status;
                                    return (
                                        <Box sx={{ mb: 4 }} key={order_num}>
                                            <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
                                                <CardContent onClick={matches ? orderTracking(order_num) : null}>
                                                    <Box sx={{
                                                        flexWrap: 'wrap', justifyContent: 'space-between',
                                                        display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' }
                                                    }}>
                                                        <Box sx={{ mb: { xs: 0, sm: 0, md: 1.5, lg: 0, xl: 0 } }}>
                                                            <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                                                No. Pedido: <b>ONL-{order_num}</b>
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                            <Typography sx={{ fontSize: 14 }}>
                                                                {`Estatus del pedido: `}
                                                                <Link to="/rastrear-pedido"
                                                                    style={
                                                                        ['Enviado', 'Entregado'].includes(status) ?
                                                                            { color: '#229954', fontWeight: '700' }
                                                                            :
                                                                            ['En espera', 'Preparando'].includes(status) ?
                                                                                { color: '#F1C40F', fontWeight: '700' }
                                                                                :
                                                                                { color: '#C0392B', fontWeight: '700' }
                                                                    }>
                                                                    {status}
                                                                </Link>
                                                            </Typography>
                                                            <Divider orientation="vertical" sx={{ mx: 1.5 }} />
                                                            <Typography sx={{ fontSize: 14 }}>
                                                                Fecha de compra: <b>{` ${today.toLocaleDateString("es-MX", date_options)}`}</b>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Divider sx={{ my: 1.8, display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }} />
                                                    <Grid container spacing={matches ? 1.5 : 2} sx={{ px: { xs: 0, sm: 0, md: 0, lg: 2 } }}>
                                                        {
                                                            len > 1 ?
                                                                <>
                                                                    {
                                                                        products.map((res, index) => (
                                                                            <React.Fragment key={index}>
                                                                                <Grid item xl={3} lg={3} md={4} sm={4} xs={4} className="animate__animated animate__fadeInLeft">
                                                                                    {
                                                                                        res.images.length > 0 ?
                                                                                            <img width={matches_img ? '100%' : '90%'} alt={res.images[0].alt}
                                                                                                src={`${GOOGLE_DRIVE_CDN}${res.images[0].id}`}
                                                                                                onClick={goToProduct(products[0].url)} style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                            :
                                                                                            <img
                                                                                                src={no_image} alt="producto-nefropolis"
                                                                                                width={matches_img ? '100%' : '90%'}
                                                                                                onClick={goToProduct(products[0].url)} style={{ cursor: 'pointer' }}
                                                                                            />
                                                                                    }
                                                                                </Grid>
                                                                                <Grid item xl={9} lg={9} md={8} sm={8} xs={8}
                                                                                    sx={{ my: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' } }}>
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <Box>
                                                                                            <Typography variant={matches ? 'body1' : 'h6'} sx={{ fontWeight: '600' }} gutterBottom>
                                                                                                {`${res.title} ${res.variant_title}`.trim()}
                                                                                            </Typography>
                                                                                            <Typography variant='body2' sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
                                                                                                Fecha de compra: <b>{`${today.toLocaleDateString("es-MX", date_options)}`}</b>
                                                                                            </Typography>
                                                                                            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                                                                                                <Typography variant='body2' color='text.secondary'
                                                                                                    sx={{ textTransform: 'uppercase' }} gutterBottom>
                                                                                                    Categorías: {
                                                                                                        res.category.map((e, i) => {
                                                                                                            return i === res.category.length - 1 ? `${e}` : `${e}, `
                                                                                                        })
                                                                                                    }
                                                                                                </Typography>
                                                                                                <Button
                                                                                                    variant='contained'
                                                                                                    onClick={() => {
                                                                                                        setValue('2')
                                                                                                        window.scrollTo({ top: 0, left: 0 })
                                                                                                    }}
                                                                                                    size='small'
                                                                                                    sx={{ textTransform: 'none' }}
                                                                                                    endIcon={<LoopIcon />}>
                                                                                                    Comprar nuevamente
                                                                                                </Button>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Grid>
                                                                            </React.Fragment>
                                                                        ))
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <Grid item xl={3} lg={3} md={4} sm={4} xs={4}
                                                                        className="animate__animated animate__fadeInLeft">
                                                                        {
                                                                            products[0].images.length > 0
                                                                                ?
                                                                                <img width={matches_img ? '100%' : '90%'} alt={products[0].images[0].alt}
                                                                                    src={`${GOOGLE_DRIVE_CDN}${products[0].images[0].id}`}
                                                                                    onClick={goToProduct(products[0].url)} style={{ cursor: 'pointer' }}
                                                                                />
                                                                                :
                                                                                <img src={no_image} width={matches_img ? '100%' : '90%'} alt="producto-nefropolis"
                                                                                    onClick={goToProduct(products[0].url)} style={{ cursor: 'pointer' }}
                                                                                />
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xl={9} lg={9} md={8} sm={8} xs={8} sx={{ my: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' } }}>
                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                            <Box>
                                                                                <Typography variant={matches ? 'body1' : 'h6'} sx={{ fontWeight: '600' }} gutterBottom>
                                                                                    {products[0].title}
                                                                                    {len > 1 && (<span>{` + ${len - 1} producto(s) `}</span>)}
                                                                                </Typography>
                                                                                <Typography variant='body2' sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
                                                                                    Fecha de compra: <b>{` ${today.toLocaleDateString("es-MX", date_options)}`}</b>
                                                                                </Typography>
                                                                                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                                                                                    <Typography variant='body2' color='text.secondary' sx={{ textTransform: 'uppercase' }} gutterBottom>
                                                                                        Categorías: {
                                                                                            products[0].category.map((e, i) => {
                                                                                                return i === products[0].category.length - 1 ? `${e}` : `${e}, `
                                                                                            })
                                                                                        }
                                                                                    </Typography>
                                                                                    <Button
                                                                                        variant='contained'
                                                                                        onClick={() => {
                                                                                            setValue('2')
                                                                                            window.scrollTo({ top: 0, left: 0 })
                                                                                        }}
                                                                                        size='small'
                                                                                        sx={{ textTransform: 'none' }}
                                                                                        endIcon={<LoopIcon />}>
                                                                                        Comprar nuevamente
                                                                                    </Button>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Grid>
                                                                </>
                                                        }
                                                    </Grid>
                                                </CardContent>
                                                <Divider />
                                                <CardActions>
                                                    <Button
                                                        onClick={orderTracking(order_num)}
                                                        sx={{ textTransform: 'none' }}
                                                    >
                                                        Rastrear pedido
                                                    </Button>
                                                    <Button
                                                        onClick={orderDetails(order_num)}
                                                        sx={{ textTransform: 'none' }}
                                                    >
                                                        Ver detalles
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </Box>
                                    )
                                })
                                :
                                <Container maxWidth="xl" sx={{ mt: 3 }}>
                                    <Alert severity="info">No tiene pedidos por el momento</Alert>
                                </Container>
                        }
                    </TabPanel>

                    {/* Comprar nuevamente */}
                    <TabPanel value="2" sx={{ px: 0 }}>
                        <BuyAgain />
                    </TabPanel>
                </TabContext>
            </Container>
        </>
    );
};

export default PedidosComponent;