import { types } from '../types/types';
import { msg as msgFn } from './swal_msg';
import { request } from '../helpers/requestHelper';

import axios from 'axios';
import { commentModalToggle } from './ui';

const base_url = process.env.REACT_APP_NEFRO_API_URL;
const close = {
    open: false,
    action: 'add',
    loading: false
};

// agregar reseña
export const revAdd = (model) => {
    return async(dispatch) => {
        try {
            dispatch(loading({ loading: true }));
            const { images, ...rmng } = model;
            const resp = await request('review/add', rmng, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { _id, folderId } = body.resp;
                rmng._id = _id;
                rmng.folderId = folderId;
                if (images.length > 0)
                    uploadReviewImages(dispatch, images, rmng);
                else {
                    msgFn('success', 'Comentario publicado',
                    'Le agradecemos sus comentarios');
                    dispatch(revAdded({ ...rmng, images: [] }));
                    dispatch(loading(close));
                }
            } else {
                if (body.msg === 'miss')
                    msgFn('warning', 'Comentario',
                    'Faltan datos en el comentario');
                else if (body.msg === 'prod-not-found')
                    msgFn('warning', 'Comentario',
                    'No se encontró el producto');
                else if (body.msg === 'not-saved')
                    msgFn('warning', 'Comentario',
                    'No se pudo guardar el comentario');
                else
                    msgFn('warning', 'Comentario no publicado',
                    'Inténtelo de nuevo, por favor');
                dispatch(loading({ loading: false }));
            }
        } catch (err) {
            msgFn('error', 'Comentario',
                'No se pudo agregar el comentario');
            dispatch(loading({ loading: false }));
        }
    }
};

// subir imágenes de reseña
const uploadReviewImages = async (dispatch, images, saved) => {
    const { prod_id, _id } = saved;
    const data = new FormData();
    data.append('prod_id', prod_id);
    data.append('_id', _id);
    images.forEach(img => {
        data.append('file', img);
    });
    const url = `${base_url}/review/images-upload`;
    try {
        const axios_resp = await axios.post(url, data);
        if (axios_resp) {
            const { status, resp } = axios_resp.data;
            if (status) {
                msgFn('success', 'Comentario publicado',
                'Le agradecemos sus comentarios');
                dispatch(revAdded({ ...saved, images: [...resp] }));
            } else {
                msgFn('warning', 'Comentario publicado',
                'No se pudieron subir las imágenes');
                dispatch(revAdded({ ...saved, images: [] }));
            }
        }
        dispatch(loading(close));
    } catch (err) {
        msgFn('error', 'Comentario',
        'No se pudo agregar el comentario');
        dispatch(loading({ loading: false }));
    }
};

// cargando operaciones
const loading = (payload) => ({
    type: types.ui_commentLoading,
    payload
});

// reseña agregada
const revAdded = (payload) => ({
    type: types.reviewAdded,
    payload
});

// selecciona reseña y abre modal
export const revEdition = (model) => {
    return async(dispatch) => {
        dispatch(revSelected(model));
        dispatch(commentModalToggle({
            open: true,
            action: 'edit',
            loading: false
        }));
    };
};

// reseña activa
const revSelected = (obj) => ({
    type: types.reviewSelected,
    payload: obj
});

// actualizar reseña
export const revUpd = (model, newImages, deletedImages) => {
    return async(dispatch) => {
        try {
            dispatch(loading({ loading: true }));
            //const { images, ...rmng } = model;
            model.deletedImages = deletedImages;
            const resp = await request('review/update', model, 'PUT');
            const body = await resp.json();
            if (body.status) {
                if (newImages.length > 0) {
                    //const { _id } = body.resp;
                    appendReviewImages(dispatch, newImages, model);
                } else {
                    msgFn('success', 'Comentario actualizado',
                    'Le agradecemos sus comentarios');
                    const { deletedImages, ...review } = model;
                    dispatch(revUpdated({ ...review }));
                    dispatch(loading(close));
                }
            } else {
                if (body.msg === 'miss')
                    msgFn('warning', 'Comentario',
                    'Faltan datos en el comentario');
                else if (body.msg === 'not-found')
                    msgFn('warning', 'Comentario',
                    'No se encontró el producto');
                else
                    msgFn('warning', 'Comentario no publicado',
                    'Inténtelo de nuevo, por favor');
                dispatch(loading({ loading: false }));
            }
        } catch (err) {
            msgFn('error', 'Comentario',
                'No se pudo actualizar la reseña');
            dispatch(loading({ loading: false }));
        }
    }
};

// añadir imágenes de reseña
const appendReviewImages = async (dispatch, newImages, saved) => {
    const { prod_id, _id, folderId } = saved;
    const data = new FormData();
    data.append('prod_id', prod_id);
    data.append('_id', _id);
    data.append('folderId', folderId);
    newImages.forEach(img => {
        data.append('file', img);
    });
    const url = `${base_url}/review/images-append`;
    try {
        const axios_resp = await axios.post(url, data);
        if (axios_resp) {
            const { status, resp } = axios_resp.data;
            if (status) {
                msgFn('success', 'Comentario actualizado',
                'Le agradecemos sus comentarios');
                dispatch(revUpdated({ ...saved, images: [...resp] }));
            } else {
                msgFn('warning', 'Comentario actualizado',
                'No se pudieron subir las imágenes');
                dispatch(revUpdated({ ...saved }));
            }
        }
        dispatch(loading(close));
    } catch (err) {
        msgFn('error', 'Comentario',
        'No se pudo actualizar el comentario');
        dispatch(revUpdated({ ...saved }));
        dispatch(loading({ loading: false }));
    }
};

// reseña actualizada
const revUpdated = (payload) => ({
    type: types.reviewUpdated,
    payload
});

// eliminar reseña
export const revDelete = (model) => {
    return async(dispatch) => {
        try {
            const resp = await request('review/delete', model, 'DELETE');
            const body = await resp.json();
            if (body.status) {
                msgFn('success', 'Comentario',
                    'Su comentario ha sido eliminado');
                dispatch(revDeleted(model));
            } else {
                    msgFn('warning', 'Comentario no eliminado',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msgFn('error', 'Comentario',
                'No se pudo eliminar su comentario');
        }
    }
};

// reseña eliminada
const revDeleted = (payload) => ({
    type: types.reviewDeleted,
    payload
});