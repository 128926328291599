import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import '../FeaturedProducts/FeaturedProducts.css';

//IMG
import no_image from '../../../../assets/images/banners/coleccion-todos1.jpg';
import shape from '../../../../assets/resources/shape.png';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

//MUI
import {
    Box, Typography, Stack
} from '@mui/material';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Autoplay } from "swiper";

const PromotionProducts = () => {
    // productos en promoción
    const { products } = useSelector(state => state.shop);
    const [promotionProducts, setPromotionProducts] = useState([]);

    useEffect(() => {
        const prods = products.filter(e => {
            const prod = e.prod_variants.filter(u => u.item_promotion && u.item_promotion.promo !== '')
            if (prod.length > 0)
                return true;
            else return false;
        });
        setPromotionProducts(prods);
    }, [products]);

    return (
        <>
            {
                promotionProducts.length >= 1
                    ?
                    <>
                        <Typography variant='h5' sx={{ fontWeight: 'light' }}
                            paragraph>Productos en promoción</Typography>

                        <Swiper
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                400: {
                                    slidesPerView: 2,
                                },
                                600: {
                                    slidesPerView: 3,
                                },
                                700: {
                                    slidesPerView: 3,
                                },
                                1000: {
                                    slidesPerView: 4,
                                },
                            }}
                            spaceBetween={30}
                            loop={false}
                            pagination={{
                                clickable: true,
                            }}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true
                            }}
                            modules={[Pagination, Autoplay]}
                            className="mySwiper">
                            {
                                promotionProducts.map(e => {
                                    let review_prom = 0; // promedio reseñas
                                    let review_count = 0 // número reseñas

                                    // precios de variantes
                                    const prices = e.prod_variants.map(e => e.price);
                                    // precio más económico de variante
                                    const min_price = Math.min(...prices);

                                    // variante con promoción
                                    const promoVariant = e.prod_variants.find(e => e.item_promotion && e.item_promotion.promo !== '');
                                    // variante con precio más bajo
                                    const minPriceVariant = e.prod_variants.find(e => e.price === min_price);

                                    // variante a mostrar
                                    const _variant = (!promoVariant && !minPriceVariant) ?
                                        e.prod_variants[0]
                                        :
                                        promoVariant ? promoVariant : minPriceVariant;

                                    if (e.reviews.length > 0) {
                                        e.reviews.forEach(element => {
                                            review_prom += element.rating;
                                            review_count++;
                                        });
                                        // redondea promedio de reseñas
                                        review_prom = Math.round(review_prom / review_count);
                                    }
                                    return (
                                        <SwiperSlide style={{ textAlign: 'center' }} key={e._id}>
                                            {
                                                _variant ?
                                                    <Link to={`/tienda/producto/${e.url}`}>
                                                        <div className="product-in-promotion">
                                                            {
                                                                _variant.images.length > 0 ?
                                                                    <img src={`${GOOGLE_DRIVE_CDN}${_variant.images[0].id}`}
                                                                        width='230' height="230" style={{ objectFit: 'contain' }}
                                                                        className="shop-product"
                                                                        alt={_variant.images[0].alt} />
                                                                    :
                                                                    <img src={no_image}
                                                                        width='100%' className="shop-product"
                                                                        alt="producto-nefropolis" />
                                                            }
                                                            {
                                                                _variant.item_promotion.promo !== '' &&
                                                                <div className="shop_top-image">
                                                                    <img src={shape} alt={shape} width='75' style={{ pointerEvents: 'none' }} />
                                                                    <div className="shop_centered_image">
                                                                        {
                                                                            ['2x1', '4x3', 'Descuento%Producto'].includes(_variant.item_promotion.promo)
                                                                                ?
                                                                                <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>
                                                                                    {_variant.item_promotion.text}
                                                                                </Typography>
                                                                                :
                                                                                <Typography sx={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>
                                                                                    Promoción
                                                                                </Typography>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Link>
                                                    :
                                                    <Link to={`/tienda/producto/${e.url}`}>
                                                        {
                                                            e.prod_variants[0] && e.prod_variants[0].images.length > 0 ?
                                                                <img src={`${GOOGLE_DRIVE_CDN}${_variant.images[0].id}`}
                                                                    width='230' height="230" style={{ objectFit: 'contain' }}
                                                                    className="shop-product"
                                                                    alt={_variant.images[0].alt} />
                                                                :
                                                                <img src={no_image}
                                                                    width='100%' className="shop-product"
                                                                    alt="producto-nefropolis" />
                                                        }
                                                    </Link>
                                            }

                                            <Box sx={{ textAlign: 'center', mt: .5 }}>
                                                <Link to={`/tienda/producto/${e.url}`} style={{ textDecoration: 'none', color: '#000' }}>
                                                    <Typography
                                                        variant="body1"
                                                        component="div"
                                                        sx={{ fontWeight: '600' }}
                                                    >
                                                        {e.title}
                                                    </Typography>
                                                </Link>
                                            </Box>

                                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: .5 }}>
                                                <div className="ratings-shop">
                                                    {
                                                        review_count > 0 ?
                                                            Array.from(Array(review_prom), (e, j) => {
                                                                return <i className="fas fa-star"
                                                                    style={{
                                                                        color: '#FFA41C',
                                                                        stroke: "#FFA41C",
                                                                        strokeWidth: 0
                                                                    }}
                                                                    key={j}></i>
                                                            })
                                                            :
                                                            <>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                            </>
                                                    }
                                                </div>
                                            </Box>

                                            <Box sx={{
                                                display: 'flex', mt: 1,
                                                justifyContent: { xs: 'center', sm: 'center', md: 'center' },
                                            }}>
                                                {
                                                    ['Descuento$Producto', 'Descuento%Producto'].includes(_variant.item_promotion.promo) ?
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="baseline"
                                                            spacing={1}>
                                                            <Typography variant="h6"
                                                                sx={{ fontWeight: '600', color: '#000', textDecoration: 'line-through' }}>
                                                                ${min_price}
                                                            </Typography>
                                                            <Typography variant="h6"
                                                                sx={{ fontWeight: '600', color: '#F90606' }} align='center'>
                                                                ${_variant.item_promotion.price}
                                                            </Typography>
                                                        </Stack>
                                                        :
                                                        <Typography variant="h6"
                                                            sx={{ fontWeight: '600', color: '#F90606' }} align='center'>
                                                            ${min_price}
                                                        </Typography>
                                                }
                                            </Box>

                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper >
                    </>
                    :
                    null
            }
        </>
    );
};

export default PromotionProducts;