import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { getOrder } from "../../../actions/shop";
import { orderInit } from "../../../constants/constants";

//CSS
import './typage.css'

import logo from '../../../assets/images/Logo.png';

//MUI
import {
    List, ListItem, Typography, ListItemText,
    Stack, Box, Button, Container, Divider, Popover,
    Link as MUILink, useMediaQuery
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

const ThankYouPageComponent = () => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width: 950px)');

    const { loggedUser } = useSelector(state => state.user);

    // referencia pedido
    const { reference } = useParams();
    const [order, setOrder] = useState(orderInit);
    const {
        order_num, summary, address, payment,
        coupon, products, shipping,
    } = order;
    
    const { promos_discount, discount, total } = summary;
    const { name, street, ext_num, int_num, colony } = address;
    const { method } = payment;
    const { status, partner, cost, tracking_guide, est_delivery_date } = shipping;
    
    useEffect(() => {
        getMyOrder();
    }, []);

    // cargar orden de pago mediante referencia en url
    const getMyOrder = async () => {
        const data = await getOrder(reference);
        if (data.status)
            setOrder({
                ...order,
                ...data.resp
            });
    };

    const trackOrder = () => {
        navigate(`/rastrear-pedido/${reference}`, { state: order })
    };

    // dirigirse a página de variante
    const goToProductPage = (nav_url) => () => {
        navigate(`/tienda/producto/${nav_url}`);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        navigator.clipboard.writeText(tracking_guide);
        // navigator.clipboard.writeText('481-' + yomefacturo_ticketId);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // ir a la página de seguimiento de Melonn
    const goToTrackingPage = () => {
        window.location.href = tracking_guide;
    };

    const open = Boolean(anchorEl);
    const popover_id = open ? 'simple-popover' : undefined;

    return (
        <>
            {/* Contenido */}
            <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }} className="last-view">
                {
                    name ?
                        <Box sx={{ p: 3, mx: 'auto' }} className="ty-page">
                            {/* TY-page header */}
                            <div className="ty-header">
                                <Box sx={{ textAlign: 'center', mb: 2 }}>
                                    <img src={logo} width="200" alt="logo" />
                                </Box>
                                <Typography variant='h5' sx={{ fontWeight: '600' }} align='center'>¡Gracias por tu compra {name}!</Typography>
                            </div>
                            {/* Fin TY-page header */}
                            <Divider sx={{ my: 2 }} />
                            {/* Contenido TY-Page (Número orden, productos, otros) */}
                            <div className="ty-content">
                                <Box sx={{ mb: 3 }}>
                                    <Typography variant="body1" align="center" paragraph>Número de orden: ONL-{order_num}</Typography>
                                    {
                                        status === 'standby' &&
                                            <Typography variant="body1" align="center" gutterBottom>
                                                {
                                                    method === 'MercadoPago' ?
                                                        'En espera de pago'
                                                    :
                                                        'Estamos validando su pago. Esto no debe tardar mucho'
                                                }
                                            </Typography>
                                    }

                                    {
                                        status === 'preparing' &&
                                            <Typography variant="body1" align="center" gutterBottom>
                                                Estamos preparando tu pedido. Te notificaremos vía correo electrónico cuando sea enviado.
                                            </Typography>
                                    }

                                    {
                                        status === 'shipped' &&
                                            <Typography variant="body1" align="center" gutterBottom>
                                                { `Tu pedido ha sido enviado a través de ${ partner }` }
                                            </Typography>
                                    }

                                    {
                                        status === 'delivered' &&
                                            <Typography variant="body1" align="center" gutterBottom>
                                                Tu paquete ha sido entregado en tu domicilio
                                            </Typography>
                                    }

                                    {
                                        (['shipped', 'delivered'].includes(status)) &&
                                        <>
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="baseline"
                                                spacing={1}>
                                                <Typography variant="body2" gutterBottom>
                                                    { `Guía de seguimiento: ` }
                                                </Typography>
                                                {
                                                    tracking_guide.includes('melonn') ?
                                                        <Button variant="contained" size="small"
                                                            endIcon={<VisibilityIcon />} onClick={ goToTrackingPage }>
                                                            Ver
                                                        </Button>
                                                    :
                                                        <Button aria-describedby={ popover_id }
                                                            variant="text" size="small" onClick={ handleClick }>
                                                            { tracking_guide }
                                                        </Button>
                                                }
                                            </Stack>

                                            <Typography variant="body2" gutterBottom>
                                                Fecha estimada de entrega: { est_delivery_date }
                                            </Typography>
                                            <Popover
                                                id={ popover_id }
                                                open={ open }
                                                anchorEl={ anchorEl }
                                                onClose={ handleClose }
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}>
                                                <Typography sx={{ p: 2 }}>Guía copiada</Typography>
                                            </Popover>
                                        </>
                                    }

                                    {
                                        loggedUser.email !== '' ?
                                            <Box sx={{ textAlign: 'center' }}>
                                                <Link to='/mi-cuenta/mis-pedidos' style={{ textDecoration: 'none' }}>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        sx={{ textTransform: 'none' }}>
                                                        Mis pedidos
                                                    </Button>
                                                </Link>
                                            </Box>
                                        :
                                            (['Enviado', 'Entregado'].includes(status)) &&
                                                <Box sx={{ textAlign: 'center' }}>
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        sx={{ textTransform: 'none' }}
                                                        onClick={ trackOrder }>
                                                        Rastrear pedido
                                                    </Button>
                                                </Box>
                                    }
                                </Box>

                                <Typography variant='h6' sx={{ fontWeight: '600', mb: 1 }}>Detalles de envío:</Typography>
                                <Typography variant="body1" gutterBottom>
                                    { `Domicilio: ${street}, número exterior ${ext_num}${int_num && `, interior ${int_num}`}` }
                                </Typography>
                                <Typography variant="body1" gutterBottom>{ `Colonia ${colony}` }</Typography>
                                
                                {/* Productos y envio */}
                                <Typography variant='h5' sx={{ fontWeight: '600', mt: 2 }}>Tu orden:</Typography>
                                <List disablePadding>
                                    {
                                        products.map(({ prod_var_id, title, variant_title, url, quantity, discount: { total, item_total } }, i) => (
                                            <div key={i}>
                                                {/* Lista de productos */}
                                                <ListItem sx={{ px: 0 }}>
                                                    <ListItemText primary={
                                                        <MUILink
                                                            component="button"
                                                            sx={{ color: '#000', mb: 1, textDecoration: 'none' }}
                                                            onClick={ goToProductPage(url, prod_var_id) }>
                                                            {
                                                                variant_title ?
                                                                    `${title} - ${variant_title} (${quantity})`
                                                                :
                                                                    `${title} (${quantity})`
                                                            }
                                                        </MUILink>
                                                    } secondary={ total !== 0 && `Descuento de $${ total }` } />
                                                    <Typography sx={{ color: '#229954', fontWeight: '600' }} variant="body1">
                                                        ${ item_total }
                                                    </Typography>
                                                </ListItem>
                                                {/* fin lista de productos */}
                                            </div>
                                        ))
                                    }
                                    <hr />
                                    {
                                        (coupon && coupon.name !== '') && (
                                            <ListItem sx={{ px: 0 }}>
                                                <ListItemText
                                                    primary={ coupon.name }
                                                    secondary={
                                                        coupon._class === 'order_discount' ?
                                                            `Cupón ${
                                                                coupon.discount.type === 'fixed' ?
                                                                    `$${coupon.discount._value}`
                                                                :
                                                                    `${coupon.discount._value}%`
                                                            }`
                                                        :
                                                            `Cupón Envío Gratis`
                                                    }
                                                />
                                                <Typography sx={{ color: '#C0392B', fontWeight: '600' }}
                                                    variant="body2">
                                                    {
                                                        coupon._class === 'order_discount' ?
                                                            `- $${
                                                                coupon.discount.type === 'fixed'
                                                                    ? coupon.discount._value
                                                                    : discount
                                                            }`
                                                        :
                                                            `¡Tiene envío gratis!`
                                                    }
                                                </Typography>
                                            </ListItem>
                                        )
                                    }
                                    
                                    {
                                        promos_discount !== 0 &&
                                            <ListItem sx={{ px: 0 }}>
                                                <ListItemText primary="Descuento por promociones" secondary={
                                                    `${ products.filter(e => e.promotion).length } activa(s)`
                                                } />
                                                <Typography sx={{ color: '#C0392B', fontWeight: '600' }}
                                                    variant="body1">
                                                    - ${ promos_discount }
                                                </Typography>
                                            </ListItem>
                                    }

                                    {/* Envío */}
                                    <ListItem sx={{ px: 0 }}>
                                        <ListItemText primary="Envío" />
                                        <Typography sx={{ color: '#229954', fontWeight: '600' }} variant="body2">
                                            { cost === 0 ? 'Gratis' : `$${cost}` }
                                        </Typography>
                                    </ListItem>
                                    {/* Total */}
                                    <ListItem sx={{ px: 0 }}>
                                        <ListItemText primary="Total + envío" />
                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            ${ total }
                                        </Typography>
                                    </ListItem>
                                </List>
                                {/* Fin productos y envios */}
                                <Divider sx={{ my: 2 }} />
                                {
                                    loggedUser.email !== ''
                                        ?
                                        null
                                        :
                                        <Typography variant="body2" paragraph>
                                            ¿No tienes cuenta? <Link to='/signup'>Crea una aquí.</Link>
                                        </Typography>
                                }
                                <Typography variant="body2" paragraph>
                                    { `Recuerda que una vez que recibas tu producto sólo tienes 48 hrs para reportar cualquier incidencia con tu pedido`.toUpperCase() }
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    Recibirás un correo electrónico con el resumen de tu compra. Revisa tu bandeja de entrada o spam.
                                    Conserva tu número de pedido para dar seguimiento a tu compra.
                                </Typography>
                            </div>
                            {/* Fin contenido TY-Page */}
                        </Box>
                        :
                        <Box sx={{ p: 3, mx: 'auto' }} className="ty-page">
                            {/* TY-page header */}
                            <div className="ty-header">
                                <Box sx={{ textAlign: 'center', mb: 2 }}>
                                    <img src={logo} width="200" alt="logo" />
                                </Box>
                                <Typography variant='h5' sx={{ fontWeight: '600' }} align='center'>Pedido no encontrado</Typography>
                            </div>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="body2" paragraph>
                                Si su pedido no se muestra aquí y ha completado el pago exitosamente, comuníquese con nosotros: 33 3826 8009
                            </Typography>
                        </Box>
                }

                <Box sx={{ mt: 3, textAlign: 'center' }}>
                    <Link to='/' style={{ textDecoration: 'none' }}>
                        <Button variant='contained' sx={{ textTransform: 'none' }}>Ir a la página de inicio</Button>
                    </Link>
                </Box>
            </Container>
            {/* Fin contenido */}
        </>
    );
};

export default ThankYouPageComponent;