import React, { useState } from "react";
import { useSelector } from "react-redux";
import TagManager from 'react-gtm-module';

// import { basicMsg } from "../../../../actions/swal_msg";

import MyPayPalProvider from "../Pago/PayPal/MyPayPalProvider";
import MPCheckoutPro from "../../../../helpers/mercadopago/MercadoPagoCheckoutPro";
// import EPHostedCheckout from "../../../../helpers/evopayments/EvoPaymentsHostedCheckout";

//CSS
import './previewcheckout.css';

//MUI
import {
    List, ListItem, ListItemText, Typography,
    Grid, Box, Divider, Button, Stack
} from "@mui/material";

const tagManagerArgs = {
    gtmId: 'GTM-KQMW2JG'
};
TagManager.initialize(tagManagerArgs);

const PreviewCheckoutComponent = ({ shippingValues, billingValues, paymentValues }) => {
    const {
        cart, // carrito
        shipping: {
            methods: { selected: { cost } }
        }
    } = useSelector(state => state.shop);
    const { item_summary, summary, coupon } = cart;
    const { name } = coupon; // cupón aplicado
    const { discount, total } = summary;

    // régimen fiscal y CFDI disponible
    const { taxSystemData, CFDIData } = useSelector(state => state.checkout);
    
    // información de envío
    const { street, ext_num, int_num, postalcode, colony, city, state } = shippingValues;

    // información de facturación
    const {
        req_invoice, business_name, rfc, tax_system, cfdi,
        address, billing_postalcode, billing_colony, billing_city,
        billing_state
    } = billingValues;

    // referencias de pago
    const {
        payment_method, // método de pago
        // session_id, // EvoPayments
        preference_id, // MercadoPago
        redirect_url, // OpenPay
    } = paymentValues;

    // carga script MercadoPago
    MPCheckoutPro(preference_id);

    // helper EvoPayments
    // const [checkout, setCheckout] = useState(undefined);
    // carga script EvoPayments
    // EPHostedCheckout(session_id, setCheckout);

    // deshabilitar botón de EvoPayments
    const [openPayButton, setOpenPayButton] = useState(false);

    // dirigirse a página de pago con EvoPayments
    // const evoPaymentsPage = () => {
    //     if (checkout) {
    //         // Tag Manager
    //         window.dataLayer.push({
    //             event: 'nefro-order'
    //         });
    //         checkout.showPaymentPage();
    //     } else basicMsg('No se ha cargado la página de pago');
    // };

    // compra con MercadoPago
    const mercadoPago = () => {
        // notificar Tag Manager
        window.dataLayer.push({
            event: 'nefro-order'
        });
    };

    // redirigir al formulario de Openpay
    const openPay = () => {
        setOpenPayButton(true);
        // notificar Tag Manager
        window.dataLayer.push({
            event: 'nefro-order'
        });
        window.location.href = redirect_url;
    };

    return (
        <Box sx={{ mt: 4 }}>
            <Box sx={{ p: 3, mx: 'auto' }} className="resumen-checkout">
                <Typography variant='h5'
                    sx={{ fontWeight: '600', textAlign: { xs: 'center', sm: 'center', md: 'left' } }}>
                    Tu orden
                </Typography>
                {/* Orden */}
                <List disablePadding>
                    {/* Lista de productos */}
                    {
                        item_summary.map((e, i) =>
                            <ListItem sx={{ px: 0 }} key={i}>
                                <ListItemText
                                    primary={`${e.title} ${e.variant_title} (${e.quantity})`}
                                    secondary={
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary">
                                            { e.promo_text !== '' ? e.promo_text : '' }
                                        </Typography>
                                    }
                                />
                                {
                                    (
                                        e.discount_by_promo !== 0 ||
                                        e.discount_by_coupon !== 0
                                    ) ?
                                        <Stack spacing={1}>
                                            <Typography sx={{ color: '#000', fontWeight: '600', textDecoration: 'line-through' }}
                                                variant="body2">
                                                ${ e.item_subtotal }
                                            </Typography>
                                            <Typography sx={{ color: '#229954', fontWeight: '600' }} variant="body2">
                                                ${ e.item_total }
                                            </Typography>
                                        </Stack>
                                    :
                                        <Typography sx={{ color: '#229954', fontWeight: '600' }} variant="body2">
                                            ${ e.item_subtotal }
                                        </Typography>
                                }
                            </ListItem>
                        )
                    }

                    {
                        name !== '' && (
                            <ListItem sx={{ px: 0 }}>
                                <ListItemText
                                    primary={name}
                                    secondary={
                                        coupon._class === 'order_discount' ?
                                            `Cupón ${
                                                coupon.discount.type === 'fixed' ?
                                                    `$${coupon.discount._value}`
                                                :
                                                    `${coupon.discount._value}%`
                                            }`
                                        :
                                            `Cupón Envío Gratis`
                                    }
                                />
                                <Typography sx={{ color: '#C0392B', fontWeight: '600' }}
                                    variant="body2">
                                    {
                                        coupon._class === 'order_discount' ?
                                            `- $${
                                                coupon.discount.type === 'fixed'
                                                    ? coupon.discount._value
                                                    : discount
                                            }`
                                        :
                                            `¡Tiene envío gratis!`
                                    }
                                </Typography>
                            </ListItem>
                        )
                    }
                    {/* fin lista de productos */}
                    <Divider sx={{ my: 2 }} />

                    {/* Envío */}
                    <ListItem sx={{ px: 0 }}>
                        <ListItemText primary="Envío" />
                        <Typography sx={{ color: '#229954', fontWeight: '600' }}
                            variant="body2">
                            { cost === 0 ? 'Gratis' : `$${cost}` }
                        </Typography>
                    </ListItem>

                    {/* Total */}
                    <ListItem sx={{ px: 0 }}>
                        <ListItemText primary="Total + envío" />
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            ${ total }
                        </Typography>
                    </ListItem>
                </List>
                {/* Fin orden */}

                <Divider sx={{ mt: 2, mb: 3 }} />

                <Grid container spacing={4}>
                    {/* Detalles del envío */}
                    <Grid item xs={12}>
                        <Typography variant='h5'
                            sx={{ fontWeight: 'light' }}
                            gutterBottom>
                            Detalles de envío
                        </Typography>
                        <Typography variant='body1'>
                            {street}, exterior {ext_num}{`${int_num && `, interior ${int_num}`}`}
                        </Typography>
                        <Typography variant='body1' sx={{ textTransform: 'uppercase' }}>
                            {colony}</Typography>
                        <Typography variant='body1' sx={{ textTransform: 'uppercase' }}>
                            {city}, {state}, {postalcode}</Typography>
                    </Grid>
                    {/* Fin Detalles del envío */}
                    
                    {/* información de pago */}
                    <Grid item xs={12}>
                        <Typography variant='h5' sx={{ fontWeight: 'light' }} gutterBottom>
                            Información de pago
                        </Typography>
                        {/* Metodo de pago */}
                        <Typography gutterBottom>
                            Método de pago: <b>{payment_method}</b>
                        </Typography>
                        {/* Fin metodo de pago */}

                        <Divider sx={{ mt: 2, mb: 3 }} />

                        <Typography sx={{ mt: 2 }}>
                            {`Recuerda que una vez que recibas tu producto sólo tienes 48 hrs para reportar cualquier incidencia con tu pedido`.toUpperCase()}
                        </Typography>
                    </Grid>
                    {/* Fin información de pago */}

                    {/* Datos de facturación */}
                    {
                        req_invoice &&
                        <Grid item xs={12}>
                            <Typography variant='h5' sx={{ fontWeight: 'light' }} gutterBottom>Datos de facturación</Typography>
                            <Typography>
                                <b>Razón social: </b>
                                {business_name}
                            </Typography>
                            <Typography>
                                <b>RFC: </b>
                                {rfc}
                            </Typography>
                            <Typography>
                                <b>Régimen fiscal: </b>
                                {tax_system} - {taxSystemData.map(({ codigo, descripcion }) => {
                                    if (codigo === tax_system)
                                        return descripcion;
                                    else return '';
                                })}
                            </Typography>
                            <Typography>
                                <b>CFDI: </b>
                                {cfdi} - {CFDIData.map(({ codigo, descripcion }) => {
                                    if (codigo === cfdi) return descripcion;
                                    else return '';
                                })}
                            </Typography>
                            <Typography variant='body1'>{address}</Typography>
                            <Typography variant='body1' sx={{ textTransform: 'uppercase' }}>
                                {billing_colony}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: 'uppercase' }}>
                                {billing_city}, {billing_state}, {billing_postalcode}
                            </Typography>
                        </Grid>
                    }
                    {/* Fin datos de facturación */}
                </Grid>

                {/* Pagar */}
                <Box sx={{ mt: 2 }}>
                    {
                        payment_method === 'PayPal' &&
                            <MyPayPalProvider
                                paymentValues={ paymentValues } />
                    }
                    {
                        payment_method === 'MercadoPago' &&
                            <div className="cho-container"
                                style={{ textAlign: 'center' }}
                                onClick={ mercadoPago }>
                            </div>
                    }
                    {/* {
                        payment_method === 'EvoPayments' &&
                            <Button fullWidth
                                onClick={ evoPaymentsPage }
                                variant='contained'>Realizar pedido y pagar
                            </Button>
                    } */}
                    {/* EvoPayments embebido <div id="embed-target"></div> */}
                    {
                        payment_method === 'OpenPay' &&
                            <Button fullWidth
                                onClick={ openPay }
                                disabled={ openPayButton }
                                variant='contained'>Realizar pedido y pagar
                            </Button>
                    }
                </Box>
            </Box>
        </Box>
    );
};

export default PreviewCheckoutComponent;