import React, { useState } from 'react';

// import { adminOrder } from '../../../../constants/constants';
import { approveEvoPaymentsOrder } from '../../../../actions/payment';

import {
    Typography, List, ListItem, ListItemText,
    Dialog, DialogTitle, DialogContent,
    DialogContentText, DialogActions, Button,
    TextField
} from '@mui/material';

const PAInfoGeneral = ({ selectedOrder, setSelectedOrder, setData, setDialog }) => {
    const {
        order_num,
        summary: { discount, subtotal, total },
        address: { name, lastname },
        coupon: { name: coupon_name },
        shipping: { partner, cost },
        payment: { method, status }
    } = selectedOrder;
    // contraseña de admin
    const [password, setPassword] = useState('');
    // Dialog
    const [localDialog, setLocalDialog] = useState(false);

    // abrir/ocultar diálogo
    const handleOpenDialog = () => {
        setLocalDialog(true);
    };
    const handleCloseDialog = () => {
        setLocalDialog(false);
        setPassword('');
    };

    const handleInputChange = ({ target }) => {
        setPassword(target.value);
    };

    const approvePayment = async() => {
        // alert('Deshabilitado');
        if (password.trim() === '')
            alert('Ingrese la contraseña');
        else {
            const req = await approveEvoPaymentsOrder({
                order_num, password,
                payment_method: method
            });
            if (req.status) {
                setPassword('');
                setLocalDialog(false);
                setDialog(false);
                // setSelectedOrder(adminOrder);
                setData(e => e.filter(u => u.order_num !== order_num));
            }
        }
    };

    return (
        <>
            {/* Estatus del pago */}
            {
                status === 'registered' ?
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#BA000D' }} component="div">
                        Pago no completado <i className="fa-sharp fa-solid fa-circle-exclamation"></i>
                    </Typography>
                :
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#BA000D' }} component="div">
                        Estado de pago desconocido <i className="fa-solid fa-bug"></i>
                    </Typography>
            }

            {/* Información del pedido */}
            <List disablePadding>
                {/* subtotal */}
                <ListItem sx={{ px: 0 }}>
                    <ListItemText primary="Subtotal:" />
                    <Typography sx={{ color: '#229954' }} variant="body2">${subtotal}</Typography>
                </ListItem>
                {/* Envío */}
                <ListItem sx={{ px: 0 }}>
                    <ListItemText primary=" Tipo de envío:" secondary={ partner } />
                    <Typography sx={{ color: '#229954' }} variant="body2">
                        { cost === 0 ? 'Gratis' : `$${cost}` }
                    </Typography>
                </ListItem>
                {/* Cupón */}
                {
                    coupon_name && (
                        <ListItem sx={{ px: 0 }}>
                            <ListItemText primary={coupon_name} secondary="cupón" />
                            <Typography sx={{ color: '#C0392B' }} variant="body2">${discount}</Typography>
                        </ListItem>
                    )
                }
                {/* Total */}
                <ListItem sx={{ px: 0 }}>
                    <ListItemText primary="Total" />
                    <Typography sx={{ color: '#3498DB' }} variant="body2">${total}</Typography>
                </ListItem>
            </List>

            {
                method === 'EvoPayments' &&
                    <Button fullWidth
                        variant="outlined"
                        onClick={ handleOpenDialog }>
                        Aprobar pago
                    </Button>
            }

{
                method === 'PayPal' &&
                    <Button fullWidth
                        variant="contained"
                        color="success"
                        onClick={ handleOpenDialog }>
                        Aprobar pago
                    </Button>
            }

            <Dialog
                open={ localDialog }
                onClose={ handleCloseDialog }
                aria-labelledby="pa-dialog-title"
                aria-describedby="pa-dialog-description">
                <DialogTitle id="pa-dialog-title">
                    { `¿Aprobar el pedido #${order_num}?` }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="pa-dialog-description">
                        { `Estás a punto de aprobar el pedido de ${name} ${lastname}. 
                        El total son ${total} pesos. Asegúrese de que el pago ha sigo aprobado en la plataforma. 
                        Por último, ingrese la contraseña que le proporcionó el administrador y pulse 'Aprobar'.` }
                    </DialogContentText>
                    <TextField fullWidth
                        sx={{ mt: 2 }}
                        name="password"
                        label="Contraseña"
                        variant="outlined"
                        value={ password }
                        inputProps={{ maxLength: 16 }}
                        onChange={ handleInputChange } />
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleCloseDialog }>Cancelar</Button>
                    <Button onClick={ approvePayment } autoFocus>
                        Aprobar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PAInfoGeneral;