import { types } from '../types/types';

const initialState = {
    taxSystemData: [],
    CFDIData: []
};

export const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.checkoutTaxSystem:
            return {
                ...state,
                taxSystemData: action.payload
            }
        case types.checkoutCFDI:
            return {
                ...state,
                CFDIData: action.payload
            }
        default:
            return state;
    }
};