import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { basicMsg } from '../../../../actions/swal_msg';
import { categoryReset, saveCategory } from '../../../../actions/category';

import { GOOGLE_DRIVE_CDN, NoImg } from '../../../../constants/constants';

import {
    Stack, Typography, TextField, Button, Grid,
    IconButton, List, ListItem, ListItemText,
    Box, ListItemIcon
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import LabelIcon from '@mui/icons-material/Label';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const categoryInit = {
    img: NoImg,
    imgFile: '',
    category: '',
    subcategory: '',
    subcategories: []
};

const categoryInputError = {
    category_err: false,
    subcategory_err: false
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const AddCategory = () => {
    const dispatch = useDispatch();

    // categorías de producto
    const { action } = useSelector(state => state.category);

    // campo de formulario fue editado
    const [inputFlag, setInputFlag] = useState(false);

    const [mainCategory, setMainCategory] = useState(categoryInit);
    const { img, imgFile, category, subcategory, subcategories } = mainCategory;

    const [inputErr, setInputErr] = useState(categoryInputError);
    const { category_err, subcategory_err } = inputErr;
    
    useEffect(() => { // limpiar campos
        if (action === 'added') {
            setMainCategory(categoryInit);
            dispatch(categoryReset(''));
        }
    }, [action]);
    
    useEffect(() => {
        setInputErr(e => ({
            ...e,
            category_err: (inputFlag && (category.length < 3 ||
                category.length > 60)) ? true : false,
            subcategory_err: (inputFlag && (subcategory.length < 3 ||
                subcategory.length > 60)) ? true : false
        }));
    }, [mainCategory]);

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (['category', 'subcategory'].includes(name)) {
            if (value.length <= 60)
                setMainCategory(e => ({ ...e, [name]: value }));
        }
        setInputFlag(true);
    };

    // subir imagen de categoría
    const uploadImg = ({ target }) => {
        if (target.files.length > 0) {
            if (['image/jpeg', 'image/png', 'image/webp'].includes(target.files[0].type))
                setMainCategory(e => ({ ...e, imgFile: target.files[0] }));
            else basicMsg('Imagen no compatible');
        }
    };

    // agregar subcategoría a lista
    const addSubcategory = () => {
        if (subcategory.length > 2 && subcategory.length <= 60) {
            const subcategoryInArray = subcategories.find(e => e.toLowerCase() === subcategory.toLowerCase());
            if (subcategoryInArray)
                basicMsg('La subcategoría ya está registrada');
            else
                setMainCategory(e => ({ ...e, subcategories: [...e.subcategories, subcategory], subcategory: '' }));
        }
    };

    const formValidator = () => {
        // subcategories.length > 0
        if (category.length > 2 && category.length <= 60) {
            if (imgFile !== '')
                return true;
            else
                basicMsg('No ha subido imagen');
        } else basicMsg('Ingrese la categoría de producto');
        return false;
    };

    const save = () => {
        if (formValidator())
            dispatch(saveCategory(mainCategory));
    };

    const removeSubcategory = subcategory => () => {
        setMainCategory((e) => ({ ...e, subcategories: e.subcategories.filter(u => u !== subcategory ) }));
    };

    return (
        <Stack spacing={2}>
            <Typography variant="subtitle1" gutterBottom>
                Nueva categoría
            </Typography>
            <Grid container>
                <Grid item xs={6}>
                    <Box
                        component="img"
                        sx={{
                            maxHeight: '100%',
                            maxWidth: '100%',
                        }}
                        alt="imagen-categoria"
                        src={
                            imgFile instanceof File ?
                                URL.createObjectURL(imgFile)
                            :
                                `${GOOGLE_DRIVE_CDN}${img}`
                        } />
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={2}>
                        <TextField fullWidth
                            name="category"
                            label="Categoría principal"
                            variant="outlined"
                            size="small"
                            value={ category }
                            error={ category_err }
                            helperText={ category_err ? "Ingrese categoría" : "" }
                            
                            onChange={ handleInputChange } />
                        <Button fullWidth component="label" variant="contained" startIcon={<PhotoCamera />}>
                            Subir imagen
                            <VisuallyHiddenInput accept="image/*" type="file" onChange={ uploadImg } />
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            
            <Typography variant="subtitle1" gutterBottom>
                Lista de subcategorías
            </Typography>
            <Grid container>
                <Grid item xs={10}>
                    <TextField fullWidth
                        name="subcategory"
                        label="Subcategoría"
                        variant="outlined"
                        value={ subcategory }
                        error={ subcategory_err }
                        helperText={ subcategory_err ? "Ingrese subcategoría" : "" }
                        inputProps={{ maxLength: 60 }}
                        size="small"
                        onChange={ handleInputChange } />
                </Grid>
                <Grid item xs={2}>
                    <IconButton aria-label="add" onClick={ addSubcategory }>
                        <AddBoxIcon fontSize="inherit" />
                    </IconButton>
                </Grid>
            </Grid>

            <List sx={{ bgcolor: 'background.paper' }}>
                {
                    subcategories.map((value) => (
                        <ListItem
                            key={ value }
                            disableGutters
                            secondaryAction={
                                <IconButton aria-label="comment"
                                    onClick={ removeSubcategory(value) }>
                                    <DeleteIcon />
                                </IconButton>
                            }>
                            <ListItemIcon>
                                <LabelIcon />
                            </ListItemIcon>
                            <ListItemText primary={ value } />
                        </ListItem>
                    ))
                }
            </List>

            <Button component="label" variant="contained" startIcon={<SaveIcon />}
                onClick={ save }>
                Guardar categoría
            </Button>
        </Stack>
    );
};

export default AddCategory;