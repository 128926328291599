import { useEffect } from "react";
import useScript from "../useScript";

const MP_PUBLIC_KEY = process.env.REACT_APP_MP_PUBLIC_KEY;

const MPCheckoutPro = (preference_id) => {
    // NO RENOMBRAR
    const { MercadoPago } = useScript(
        "https://sdk.mercadopago.com/js/v2",
        "MercadoPago"
    );

    useEffect(() => {
        if (MercadoPago && preference_id !== '') {
            const mp = new MercadoPago(MP_PUBLIC_KEY);
            const checkout = mp.checkout({
                preference: {
                    id: preference_id
                },
                // render: {
                //     container: '.cho-container',
                //     label: 'Realizar el pedido',
                // },
                theme: {
                    headerColor: "#93D50A",
                    elementsColor: "#2699FB"
                }
            });
            checkout.render({
                container: ".cho-container",
                label: "Realizar pedido y pagar",
            });
        }
    }, [MercadoPago]);
};

export default MPCheckoutPro;