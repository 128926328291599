import React from "react";

//Datagrid MUI
import { DataGrid, esES, GridToolbarExport } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container, Divider } from "@mui/material";

//Tabla top 10 clientes
const columns = [
    {
        field: 'id',
        headerName: 'Top',
    },
    {
        field: 'name',
        headerName: 'Nombre',
        minWidth: 100,
        flex: 1,
        editable: true,
    },
    {
        field: 'lastname',
        headerName: 'Apellido',
        minWidth: 100,
        flex: 1,
        editable: true,
    },
    {
        field: 'email',
        minWidth: 100,
        headerName: 'Correo electrónico',
        flex: 1,
        editable: true,
    },
    {
        field: 'purchases',
        headerName: 'Compras',
        minWidth: 100,
        flex: 1,
        editable: true,
    },
];

//Lenguaje tabla
const themeLanguaje = createTheme(
    esES,
);

function CustomToolbar() {
    return (
        <>
            <Container maxWidth='xl' sx={{ my: 1.5 }}>
                <GridToolbarExport />
            </Container>
            <Divider />
        </>

    );
}

const ClientesFrecuentesMensual = ({ rows }) => {
    return (
        <>
            <div style={{ height: 434.5, width: '100%' }}>
                <ThemeProvider theme={themeLanguaje}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                </ThemeProvider>
            </div>
        </>
    )
}

export default ClientesFrecuentesMensual