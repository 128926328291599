import React from 'react';

import PAProductos from './PedidoAbandonado/PAProductos';
import PAInfoCliente from './PedidoAbandonado/PAInfoCliente';
import PAInfoGeneral from './PedidoAbandonado/PAInfoGeneral';
import PAInfoFacturacion from './PedidoAbandonado/PAFacturacionEnvio';

import {
    Box, Card, CardContent, Container, Grid, Dialog,
    Typography, Slide, AppBar, Toolbar, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import PAAprobarPago from './PedidoAbandonado/PAAprobarPago';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const CarritoAbandonadoDialog = ({ setData, selectedOrder, setSelectedOrder, dialog, setDialog }) => {
    const { order_num, payment: { method }, order_date } = selectedOrder;

    // cerrar Dialog
    const handleClose = () => {
        setDialog(false);
    };

    return (
        <Dialog
            fullScreen
            open={dialog}
            onClose={handleClose}
            TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative', backgroundColor: '#04A6E1' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {/* Contenido del dialog */}
            <Container maxWidth="xxl" sx={{ mt: 5, mb: 5 }}>
                {/* Número del pedido */}
                <Typography variant="h5" component="div">
                    Pedido: {order_num}
                </Typography>

                {/* Fecha del pedido */}
                <Typography variant="subtitle1" component="div">
                    Fecha del pedido: {order_date}
                </Typography>

                {/* Método de pago */}
                <Typography variant="subtitle1" component="div">
                    Pagado con: <b style={{ fontWeight: 'bold', color: '#3498DB' }}>{method}</b>
                </Typography>

                <Box sx={{ mt: 5 }}>
                    <Grid container spacing={4}>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <PAProductos
                                selectedOrder={selectedOrder} />
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Card className="card__pedidos">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: '600' }}>
                                        Información del cliente
                                    </Typography>
                                    <PAInfoCliente
                                        selectedOrder={selectedOrder} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ mt: 4 }}>
                    <Grid container spacing={4}>
                        {/* Información del cliente */}
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                            <Card className="card__pedidos">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: '600' }}>
                                        Detalle de facturación
                                    </Typography>
                                    <PAInfoFacturacion
                                        selectedOrder={selectedOrder} />
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* Fin información del cliente */}

                        {/* Información del pedido */}
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                            <Card className="card__pedidos">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: '600' }}>
                                        Información del pedido
                                    </Typography>
                                    <PAInfoGeneral
                                        selectedOrder={selectedOrder}
                                        setSelectedOrder={setSelectedOrder}
                                        setData={setData}
                                        setDialog={setDialog} />
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* Fin información del pedido */}
                    </Grid>
                </Box>

                {/* <Box sx={{ mt: 4 }}>
                    <Grid container spacing={4}>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ display: 'flex' }}>
                            <Card className="card__pedidos">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: '600' }}>
                                        Aprobar pago
                                    </Typography>
                                    <PAAprobarPago
                                        selectedOrder={ selectedOrder } />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box> */}
            </Container>
        </Dialog>
    );
};

export default CarritoAbandonadoDialog;