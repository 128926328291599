import React from 'react'

//MUI
import { Grid, Skeleton, Stack } from '@mui/material'

const MedicalDirectorySkeleton = ({ listsToRender }) => {
    return (
        <>
            <Stack direction='row' mb={3} spacing={4} justifyContent='center'>
                <Skeleton variant="rectangular" width='280px' height={30} sx={{ borderRadius: '5px' }} />
                <Skeleton variant="rectangular" width='280px' height={30} sx={{ borderRadius: '5px' }} />
                <Skeleton variant="rectangular" width='280px' height={30} sx={{ borderRadius: '5px' }} />
            </Stack>

            <Grid container spacing={4}>
                {
                    Array(listsToRender)
                        .fill(1)
                        .map((index, i) => (
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={i}>
                                <Skeleton variant="rectangular" width="100%" height={420} sx={{ borderRadius: '5px' }} />
                            </Grid>
                        ))
                }
            </Grid>
        </>
    )
}

export default MedicalDirectorySkeleton