export const setData = (obj) => {
    const { labels, data } = obj;
    return {
        labels,
        datasets: [
            {
                label: 'Ventas promedio',
                data,
                backgroundColor: [
                    '#CD6155',
                    '#AF7AC5',
                    '#5499C7',
                    '#48C9B0',
                    '#F4D03F',
                    '#DC7633',
                    '#5F6A6A',
                    '#196F3D',
                    '#212F3C',
                    '#154360'
                ],
                borderColor: [
                    '#CD6155',
                    '#AF7AC5',
                    '#5499C7',
                    '#48C9B0',
                    '#F4D03F',
                    '#DC7633',
                    '#5F6A6A',
                    '#196F3D',
                    '#212F3C',
                    '#154360'
                ]
            }
        ]
        // ...e,
        // labels,
        // datasets: e.datasets.map((e, i) => {
        //     return i === 0 ?
        //         { ...e, data }
        //         : e
        // })
    };
};