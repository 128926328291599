import { types } from '../types/types';

const initialState = {
    action: '',
    categories: []
};

export const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.categoriesLoaded:
            return {
                ...state,
                categories: [...action.payload]
            }
        case types.categoryAdded:
            return {
                ...state,
                action: 'added',
                categories: [
                    ...state.categories,
                    action.payload
                ]
            };
        case types.categoryReset:
            return {
                ...state,
                action: action.payload
            };
        case types.categoryDeleted:
            return {
                ...state,
                categories: [...action.payload]
            };
        case types.categoriesDeleted:
            return {
                ...state,
                categories: state.categories.filter(e => (!action.payload.includes(e._id)))
            };
        case types.categoriesUpdated:
            return {
                ...state,
                categories: action.payload
            };
        default:
            return state;
    }
};