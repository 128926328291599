import React, { useState } from 'react';

import { FieldValues } from '../../../../helpers/admin/branch/branch';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

import {
    Divider, FormControl, Grid, TextField,
    Dialog, DialogTitle, IconButton, Typography
} from '@mui/material';
import { Container } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';

const BranchGoogleMaps = ({
    values, setValues, inputErr, setInputErr, inputRef
}) => {
    const {
        links: { google_maps }
    } = values;
    const { src, title, width, height } = google_maps;

    const {
        iframe_src_err, iframe_title_err,
        iframe_width_err, iframe_height_err
    } = inputErr;

    const {
        iframe_src_ref, iframe_title_ref,
        iframe_width_ref, iframe_height_ref
    } = inputRef;

    // const iframe_src_ref = useRef();
    // const iframe_title_ref = useRef();
    // const iframe_with_ref = useRef();
    // const iframe_height_ref = useRef();

    const [dialog, setDialog] = useState(false);

    const inputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        FieldValues({ name, value }, setValues, { inputErr, setInputErr });
    };

    const handleDialog = () => {
        setDialog(!dialog);
    };

    return (
        <>
            <Divider textAlign="left"
                sx={{ my: 3, fontWeight: 'bold' }}>
                Google Maps
                <IconButton aria-label="info" onClick={ handleDialog }>
                    <InfoIcon />
                </IconButton>
            </Divider>
            <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            variant='outlined'
                            label='src de iFrame'
                            size='small'
                            name='src'
                            value={src}
                            error={iframe_src_err}
                            helperText={iframe_src_err ? "Ingrese el URL del iFrame" : ""}
                            inputRef={iframe_src_ref}
                            onChange={inputChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            variant='outlined'
                            label='Nombre sucursal'
                            size='small'
                            name='title'
                            value={title}
                            error={iframe_title_err}
                            helperText={iframe_title_err ? "Ingrese el nombre de la sucursal" : ""}
                            inputRef={iframe_title_ref}
                            onChange={inputChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            variant='outlined'
                            label='Longitud iFrame'
                            size='small'
                            name='width'
                            value={width}
                            error={iframe_width_err}
                            helperText={iframe_width_err ? "Ingrese longitud de iFrame" : ""}
                            inputRef={iframe_width_ref}
                            onChange={inputChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            variant='outlined'
                            label='Altura iFrame'
                            size='small'
                            name='height'
                            value={height}
                            error={iframe_height_err}
                            helperText={iframe_height_err ? "Ingrese altura de iFrame" : ""}
                            inputRef={iframe_height_ref}
                            onChange={inputChange}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Dialog maxWidth="md" onClose={ handleDialog } open={ dialog }>
                <DialogTitle>iFrame de Google Maps</DialogTitle>
                <Container>
                    <Typography variant="subtitle1" gutterBottom>
                        Para obtener el 'src' del iFrame, diríjase a la ubicación de la sucursal en Google Maps y presione en compartir.
                    </Typography>
                </Container>
                <img src={`${GOOGLE_DRIVE_CDN}1cLtxi-QqA3UAAcz5uV6GSi5Djw6oIs2l`}
                    width="100%" alt="guia1-iframe" />
                <Container sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Le saldrá una ventana con el código, copie solamente lo que hay entre las comillas ("") en los campos que se señalan en la siguiente imagen. Si no desea cambiar el tamaño del mapa, deje los campos 'width' y 'height' con los valores por defecto.
                    </Typography>
                </Container>
                <img src={`${GOOGLE_DRIVE_CDN}1l6D7d9oSiSOcrUeoi9lVyHOGdwZXRx6g`}
                    width="100%" alt="guia2-iframe" />
            </Dialog>
        </>
    );
};

export default BranchGoogleMaps;