import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import './App.css';
import { Provider } from 'react-redux';
import { HelmetProvider } from "react-helmet-async";

import { store } from './store/store';
import AppRoute from './router/AppRoute';

//MUI
import { createTheme, ThemeProvider } from '@mui/material'
import MyProvider from './context/NavContext';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1536,
    },
  },
  typography: {
    fontFamily: 'Raleway',
  },
});

function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

function App() {
  useScrollToTop();
  return (
    <MyProvider>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <HelmetProvider>
            <AppRoute />
          </HelmetProvider>
        </Provider>
      </ThemeProvider>
    </MyProvider>
  );
};

export default App;