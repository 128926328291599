import React, { useState, useEffect, useRef } from 'react'

import '../FlashSale.css'

import DateTimePicker from "react-datetime-picker";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

//MUI
import { Box, Button, Container, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const init = {
    name: '',
    price: '',
    NewPrice: '',
    sku: '',
    description: '',
    sat: '',
    iva: '',
    unidad: '',
    medida: '',
    barCode: '',
    stock: "",
    date: "",
    images: []
}

const initErr = {
    name_err: false,
    price_err: false,
    NewPrice_err: false,
    sku_err: false,
    description_err: false,
    sat_err: false,
    iva_err: false,
    unidad_err: false,
    medida_err: false,
    barCode_err: false,
    stock_err: false,
};

const num_regex = /^[0-9]*$/;

const AddFlashSale = () => {

    const [endDate, setEndDate] = useState('')
    const [dias, setDias] = useState(0)
    const [horas, setHoras] = useState(0)
    const [minutos, setMinutos] = useState(0)
    const [segundos, setSegundos] = useState(0)

    const onEndDateChange = endDate => {
        setEndDate(endDate);
    };

    const getTwoDigitValue = value => {
        if (value < 10) {
            return "0" + value;
        }
        return "" + value;
    };

    const calculateCountdown = () => {
        const startDate = new Date();

        const timeRemaining = endDate.getTime() - startDate.getTime();

        if (timeRemaining > 0) {
            const start_date = new Date(startDate);
            const end_date = new Date(endDate);
            const start_millis = start_date.getTime();
            const end_millis = end_date.getTime();

            const old_sec = start_millis / 1000;
            const current_sec = end_millis / 1000;

            let seconds = current_sec - old_sec;

            let days = Math.floor(seconds / (24 * 60 * 60));
            seconds -= days * 24 * 60 * 60;

            let hours = Math.floor(seconds / (60 * 60));
            seconds -= hours * 60 * 60;

            let minutes = Math.floor(seconds / 60);
            seconds -= minutes * 60;

            days = Math.abs(days);
            hours = Math.abs(hours);
            minutes = Math.abs(minutes);
            seconds = Math.floor(Math.abs(seconds));


            setDias(days)
            setHoras(hours)
            setMinutos(minutes)
            setSegundos(seconds)
        }
        else {
            console.log("Sin tiempo para expirar")
        }
    };

    useEffect(() => {
        setTimeout(calculateCountdown, 1000)
    }, [dias, segundos, horas, minutos])

    const convertedDays = getTwoDigitValue(dias);
    const convertedHours = getTwoDigitValue(horas);
    const convertedMins = getTwoDigitValue(minutos);
    const convertedSeconds = getTwoDigitValue(segundos);

    //
    const [product, setProduct] = useState(init)
    const { name, price, sku, description, sat, iva, unidad, medida, barCode, stock, NewPrice, images } = product;

    const [inputErr, setInputErr] = useState(initErr);
    const { name_err, price_err, sku_err, description_err, sat_err, iva_err,
        unidad_err, medida_err, barCode_err, stock_err, NewPrice_err
    } = inputErr;

    const name_ref = useRef();
    const price_ref = useRef();
    const sku_ref = useRef();
    const description_ref = useRef();
    const sat_ref = useRef();
    const NewPrice_ref = useRef();
    const unidad_ref = useRef();
    const medida_ref = useRef();
    const barCode_ref = useRef();
    const stock_ref = useRef();

    const onChange = ({ target }) => {
        const name = target.name
        const value = target.value;
        if (name === 'price' || name === 'stock' || name === 'NewPrice') {
            if (value === '' || num_regex.test(value)) {
                setProduct(e => ({
                    ...e,
                    [name]: value
                })
                );
            }
        }
        else setProduct(e => ({
            ...e,
            [name]: value
        })
        );
        switch (name) {
            case 'name':
                setInputErr({
                    ...inputErr,
                    name_err: (value.length < 2 ||
                        value.length > 50) ? true : false
                });
                break;
            case 'price':
                setInputErr({
                    ...inputErr,
                    price_err: (value.length < 2 ||
                        value.length > 10) ? true : false
                });
                break;
            case 'NewPrice':
                setInputErr({
                    ...inputErr,
                    NewPrice_err: (value.length < 2 ||
                        value.length > 10) ? true : false
                });
                break;
            case 'sku':
                setInputErr({
                    ...inputErr,
                    sku_err: (value.length < 2 ||
                        value.length > 15) ? true : false
                });
                break;
            case 'description':
                setInputErr({
                    ...inputErr,
                    description_err: (value.length < 3 ||
                        value.length > 500) ? true : false
                });
                break;
            case 'sat':
                setInputErr({
                    ...inputErr,
                    sat_err: (value.length < 6 ||
                        value.length > 60) ? true : false
                });
                break;
            case 'iva':
                setInputErr({
                    ...inputErr,
                    iva_err: (value.length < 1) ? true : false
                });
                break;
            case 'unidad':
                setInputErr({
                    ...inputErr,
                    unidad_err: (value.length < 4 ||
                        value.length > 30) ? true : false
                });
                break;
            case 'medida':
                setInputErr({
                    ...inputErr,
                    medida_err: (value.length < 4 ||
                        value.length > 30) ? true : false
                });
                break;
            case 'barCode':
                setInputErr({
                    ...inputErr,
                    barCode_err: (value.length < 4 ||
                        value.length > 60) ? true : false
                });
                break;
            case 'stock':
                setInputErr({
                    ...inputErr,
                    stock_err: (value.length < 2 ||
                        value.length > 10) ? true : false
                });
                break;
            default:
                break;
        }
    }

    const formValidator = () => {
        //Nombre
        const name_len = name.trim().length;
        if (name_len > 1 && name_len <= 50) {
            //Precio
            const price_len = price.trim().length;
            if (price_len > 1 && price_len <= 10) {
                //Nuevo precio
                const newprice_len = NewPrice.trim().length
                if (newprice_len > 1 && newprice_len <= 10) {
                    //sku
                    const sku_len = sku.trim().length;
                    if (sku_len > 1 && sku_len <= 15) {
                        //sat
                        const sat_len = sat.trim().length;
                        if (sat_len > 5 && sat_len <= 60) {
                            //iva
                            if (iva !== '') {
                                //unidad
                                const unidad_len = unidad.trim().length;
                                if (unidad_len > 3 && unidad_len <= 30) {
                                    //medida
                                    const medida_len = medida.trim().length;
                                    if (medida_len > 3 && medida_len <= 30) {
                                        //código de barras
                                        const bar_len = barCode.trim().length;
                                        if (bar_len > 3 && bar_len <= 60) {
                                            //stock
                                            const stock_len = stock.trim().length;
                                            if (stock_len > 1 && stock_len <= 10) {
                                                //description
                                                const desc_len = description.trim().length;
                                                if (desc_len > 3 && desc_len <= 500) {
                                                    return true;
                                                } else {
                                                    description_ref.current.select();
                                                    setInputErr({
                                                        ...inputErr,
                                                        description_err: true
                                                    });
                                                }
                                            } else {
                                                stock_ref.current.select();
                                                setInputErr({
                                                    ...inputErr,
                                                    stock_err: true
                                                });
                                            }
                                        } else {
                                            barCode_ref.current.select();
                                            setInputErr({
                                                ...inputErr,
                                                barCode_err: true
                                            });
                                        }
                                    } else {
                                        medida_ref.current.select();
                                        setInputErr({
                                            ...inputErr,
                                            medida_err: true
                                        });
                                    }
                                } else {
                                    unidad_ref.current.select();
                                    setInputErr({
                                        ...inputErr,
                                        unidad_err: true
                                    });
                                }
                            } else {
                                setInputErr({
                                    ...inputErr,
                                    iva_err: true
                                });
                            }
                        } else {
                            sat_ref.current.select();
                            setInputErr({
                                ...inputErr,
                                sat_err: true
                            });
                        }

                    } else {
                        sku_ref.current.select();
                        setInputErr({
                            ...inputErr,
                            sku_err: true
                        });
                    }
                }
                else {
                    NewPrice_ref.current.select();
                    setInputErr({
                        ...inputErr,
                        NewPrice_err: true
                    });
                }

            } else {
                price_ref.current.select();
                setInputErr({
                    ...inputErr,
                    price_err: true
                });
            }
        } else {
            name_ref.current.select();
            setInputErr({
                ...inputErr,
                name_err: true
            });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (formValidator()) {
            alert("Todo bien")
            console.log(product)
        }
    }

    // subir imágenes de ordenador
    const loadedImages = ({ target }) => {
        let count = images.length; // total de imágenes
        let exceeded = false; // se alcanzó el límite?
        if (count >= 6) {
            alert('No puede agregar más imágenes en esta variante');
            return;
        }
        if (target.files.length > 0) {
            const target_files = [];
            [...target.files].forEach(element => {
                if (count >= 6) exceeded = true;
                else {
                    if (['image/jpeg', 'image/png', 'image/webp'].includes(element.type)) {
                        target_files.push({ img: element, alt: '' });
                        count++;
                    }
                }
            });
            setProduct(e => ({
                ...e,
                images: [...e.images, ...target_files]
            }));
            if (exceeded) alert('Se alcanzó el límite de imágenes');
        }
    };

    // remueve imágenes de producto
    const removeImg = name => () => {
        setProduct(e => ({
            ...e,
            images: e.images.filter(i => (i.img.name !== name))
        }));
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setProduct(e => ({
            ...e,
            images: items
        }));
    };

    return (
        <Container maxWidth='xl' sx={{ mb: 5 }} style={{ marginTop: '90px' }}>
            <Typography variant='h5' sx={{ fontWeight: 'light' }} paragraph>Nueva oferta relámpago</Typography>
            <Grid container spacing={5}>
                <Grid item xl={7.5} lg={7.5} md={12} sm={12} xs={12}>
                    <Box sx={{ p: 3, borderRadius: '5px', boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px' }}>

                        <Grid container spacing={2}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        name='name'
                                        value={name}
                                        onChange={onChange}
                                        label="Nombre"
                                        error={name_err}
                                        helperText={name_err ? "Ingrese nombre del producto" : ""}
                                        inputRef={name_ref}
                                        size='small'
                                        InputProps={{
                                            maxLength: 50
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        name='price'
                                        value={price}
                                        onChange={onChange}
                                        label="Precio"
                                        error={price_err}
                                        helperText={price_err ? "Ingrese el precio del producto" : ""}
                                        inputRef={price_ref}
                                        size='small'
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            maxLength: 10
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        name='NewPrice'
                                        value={NewPrice}
                                        onChange={onChange}
                                        label="Nuevo precio"
                                        error={NewPrice_err}
                                        helperText={NewPrice_err ? "Ingrese el nuevo precio del producto" : ""}
                                        inputRef={NewPrice_ref}
                                        size='small'
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            maxLength: 10
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        name='sku'
                                        value={sku}
                                        onChange={onChange}
                                        label="SKU"
                                        error={sku_err}
                                        helperText={sku_err ? "Ingrese el SKU" : ""}
                                        inputRef={sku_ref}
                                        size='small'
                                        InputProps={{
                                            maxLength: 15
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        name='sat'
                                        value={sat}
                                        onChange={onChange}
                                        label="Clave SAT"
                                        error={sat_err}
                                        helperText={sat_err ? "Clave SAT invalida" : ""}
                                        inputRef={sat_ref}
                                        size='small'
                                        InputProps={{
                                            maxLength: 60
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="demo-simple-select-label">IVA</InputLabel>
                                    <Select
                                        name='iva'
                                        error={iva_err}
                                        value={iva}
                                        label="IVA"
                                        onChange={onChange}
                                    >
                                        <MenuItem value={0}>0%</MenuItem>
                                        <MenuItem value={16}>16%</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        name='unidad'
                                        value={unidad}
                                        onChange={onChange}
                                        label="Clave unidad"
                                        error={unidad_err}
                                        helperText={unidad_err ? "Ingrese clave unidad" : ""}
                                        inputRef={unidad_ref}
                                        size='small'
                                        InputProps={{
                                            maxLength: 30
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    name='medida'
                                    value={medida}
                                    onChange={onChange}
                                    label="Unidad de medida"
                                    error={medida_err}
                                    helperText={medida_err ? "Ingrese una unidad de medida" : ""}
                                    inputRef={medida_ref}
                                    size='small'
                                    InputProps={{
                                        maxLength: 30
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    name='barCode'
                                    value={barCode}
                                    onChange={onChange}
                                    label="Código de barras"
                                    error={barCode_err}
                                    helperText={barCode_err ? "Ingrese el código de barras" : ""}
                                    inputRef={barCode_ref}
                                    size='small'
                                    InputProps={{
                                        maxLength: 60
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                    fullWidth
                                    name='stock'
                                    value={stock}
                                    onChange={onChange}
                                    label="Cantidad disponible"
                                    error={stock_err}
                                    helperText={stock_err ? "Ingrese la cantidad disponible del producto" : ""}
                                    inputRef={stock_ref}
                                    size='small'
                                    InputProps={{
                                        maxLength: 10
                                    }}
                                />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        name='description'
                                        multiline
                                        rows={5}
                                        value={description}
                                        onChange={onChange}
                                        label="Descripción"
                                        error={description_err}
                                        helperText={description_err ? "Descripción invalida" : ""}
                                        inputRef={description_ref}
                                        size='small'
                                        InputProps={{
                                            maxLength: 500
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xl={4.5} lg={4.5} md={12} sm={12} xs={12}>
                    <Box sx={{ p: 2, borderRadius: '5px', boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px', mb: 3 }}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }} paragraph>Contenido multimedia</Typography>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="file">
                                {(provided) => (
                                    <div className='grid-container' {...provided.droppableProps} ref={provided.innerRef}>
                                        {
                                            images.length > 0 &&
                                            images.map((item, index) => {
                                                console.log(item)
                                                return (
                                                    <Draggable key={item.img.name} draggableId={item.img.name} index={index}>
                                                        {(provided) => (
                                                            <div className='grid_item image' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <div className="img">
                                                                    <img src={URL.createObjectURL(item.img)} alt={item} />
                                                                </div>
                                                                <Box sx={{ left: '10px', position: 'absolute', top: '10px' }}>
                                                                    <RemoveCircleIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={removeImg(item.img.name)} />
                                                                </Box>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })
                                        }
                                        {provided.placeholder}
                                        <div className='uploader grid_item' style={images.length <= 0 ? { height: '170px' } : { height: 'auto' }}>
                                            <input
                                                accept="image/*"
                                                type="file"
                                                id="select-image-1"
                                                multiple
                                                name="images"
                                                onChange={loadedImages}
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor="select-image-1" style={{ marginBottom: 0 }}>
                                                <IconButton variant="outlined" color="primary" component="span">
                                                    <CameraAltIcon />
                                                </IconButton>
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Box>

                    <Box sx={{ p: 3, borderRadius: '5px', boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px' }}>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }} paragraph>Fecha de expiración</Typography>
                        <DateTimePicker
                            format="dd/MM/y h:mm:ss a"
                            onChange={onEndDateChange}
                            value={endDate}
                        />
                        <Box sx={{ mt: 2 }}>
                            <Button
                                variant="contained"
                                type="button"
                                onClick={calculateCountdown}
                            >
                                Empezar cuenta regresiva
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '30px', textAlign: 'center', mt: 3 }}>
                            <Box>
                                <Typography sx={{ fontWeight: '600', fontFamily: 'sans-serif' }}>{convertedDays}</Typography>
                                <Typography>Días</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ fontWeight: '600', fontFamily: 'sans-serif' }}>{convertedHours}</Typography>
                                <Typography>Horas</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ fontWeight: '600', fontFamily: 'sans-serif' }}>{convertedMins}</Typography>
                                <Typography>Minutos</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ fontWeight: '600', fontFamily: 'sans-serif' }}>{convertedSeconds}</Typography>
                                <Typography>Segundos</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="submit" variant='contained' onClick={handleSubmit} sx={{ textTransform: 'none' }}>
                    Crear oferta relámpago
                </Button>
            </Box>
        </Container >
    )
}

export default AddFlashSale