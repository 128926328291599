import React, { useState, useEffect } from 'react'

import '../MedicalDirectory.css'

//Components
import MedicalDirectoryItem from '../MedicalDirectoryItem/MedicalDirectoryItem'

//MUI
import { Box, FormControl, Grid, InputAdornment, MenuItem, OutlinedInput, Select, Button, Typography, Pagination, useMediaQuery } from '@mui/material'
import { makeStyles } from "@material-ui/core/styles";
import { styled } from '@mui/material/styles';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchIcon from '@mui/icons-material/Search';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const ButtonClear = styled(Button)(({ theme }) => ({
    backgroundColor: '#2A2AC0',
    color: '#fff',
    textTransform: 'none'
}));

const estadosArray = [
    'Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 'Chiapas', 'Chihuahua', 'Ciudad de México',
    'Coahuila', 'Colima', 'Durango', 'Estado de México', 'Guanajuato', 'Guerrero', 'Hidalgo', 'Jalisco', 'Michoacán', 'Morelos',
    'Nayarit', 'Nuevo León', 'Oaxaca', 'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí', 'Sinaloa', 'Sonora',
    'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz', 'Yucatán', 'Zacatecas'
]

const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
        color: "#aaa"
    }
}));

const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};

const MedicalDirectoryList = ({ data, myRef }) => {

    const matches = useMediaQuery('(max-width: 500px)')

    const [item, setItem] = useState(data)
    const [searchInput, setSearchInput] = useState('');
    const [stateInput, setStateInput] = useState('');
    const [resultsFound, setResultsFound] = useState(true);

    const handleStateChange = (e) => {
        setStateInput(e.target.value)
        setFilter("")
    }

    const applyFilters = () => {
        let updatedList = data;

        //Search
        if (searchInput) {
            updatedList = updatedList.filter(
                (item) =>
                    item.name.toLowerCase().search(searchInput.toLowerCase().trim()) !== -1
                    ||
                    item.specialty.toLowerCase().search(searchInput.toLowerCase().trim()) !== -1
            );
            setCurrentPage(1)
        }

        if (stateInput) {
            updatedList = updatedList.filter(
                (item) =>
                    item.state === stateInput
            );
            setCurrentPage(1)
        }

        setItem(updatedList);

        !updatedList.length ? setResultsFound(false) : setResultsFound(true);
    };

    useEffect(() => {
        applyFilters();
    }, [searchInput, stateInput])

    const [filter, setFilter] = useState('');

    const onChangeFilter = ({ target }) => {
        setFilter(target.value);
        const arr = [...item];
        switch (target.value) {
            case '10': // Nombre A - Z
                setItem(arr.sort(function (a, b) {
                    return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
                }));
                break;
            case '20': // Nombre Z - A
                setItem(arr.sort(function (a, b) {
                    return (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0);
                }));
                break;
            default:
                break;
        }
    };

    const handleClear = () => {
        setItem(data)
        setStateInput('')
        setSearchInput('')
        setFilter('')
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(9);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = item.slice(indexOfFirstPost, indexOfLastPost);

    const handleChange = (event, value) => {
        setCurrentPage(value);
        myRef.current.scrollIntoView()
    };

    useEffect(() =>{
        if(matches){
            setPostsPerPage(3)
        }
        else{
            setPostsPerPage(9)
        }
    })

    return (
        <>
            <Box display='flex' justifyContent='center' flexWrap='wrap' gap='22px' mb={5}>
                <Box>
                    <FormControl className='medical_directory_inputs' size='small'>
                        <OutlinedInput
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            placeholder="Doctor, Especialidad..."
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: '#2A2AC0' }} />
                                </InputAdornment>
                            }
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                        />
                    </FormControl>
                </Box>
                <Box>
                    <FormControl className='medical_directory_inputs' size='small'>
                        <Select
                            value={stateInput}
                            onChange={handleStateChange}
                            startAdornment={<InputAdornment position="start"><PlaceIcon sx={{ color: '#2A2AC0' }} /></InputAdornment>}
                            displayEmpty
                            renderValue={
                                stateInput !== "" ? undefined : () => <Placeholder>Estado</Placeholder>
                            }
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 }
                            }}
                        >
                            {
                                estadosArray.map((res, index) =>
                                    <MenuItem key={index}
                                        value={res}>
                                        {res}
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl className='medical_directory_inputs' size='small'>
                        <Select
                            value={filter}
                            onChange={onChangeFilter}
                            startAdornment={<InputAdornment position="start"><CalendarMonthIcon sx={{ color: '#2A2AC0' }} /></InputAdornment>}
                            displayEmpty
                            renderValue={
                                filter !== "" ? undefined : () => <Placeholder>Ordenar por</Placeholder>
                            }
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 }
                            }}
                        >
                            <MenuItem value={'10'}>Nombre A - Z</MenuItem>
                            <MenuItem value={'20'}>Nombre Z - A</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ my: 'auto' }}>
                    <ButtonClear onClick={handleClear} variant='contained'>Limpiar filtros</ButtonClear>
                </Box>
            </Box>

            <Grid container spacing={4}>
                {
                    resultsFound
                        ?
                        <>
                            {
                                currentPosts.map((item) => (
                                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={item.id}>
                                        <MedicalDirectoryItem item={item} />
                                    </Grid>
                                ))
                            }
                        </>
                        :
                        <Grid item xs={12} sx={{ textAlign: 'center', my: 5 }}>
                            <ManageSearchIcon sx={{ fontSize: '40px' }} />
                            <Typography variant='h5'>¡Lo sentimos! No se ha encontrado ningún resultado.</Typography>
                            <Typography>No hemos podido encontrar lo que buscaba, Intente de nuevo.</Typography>
                        </Grid>
                }
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                {/* <PaginationComponent
                    postsPerPage={postsPerPage}
                    totalPosts={item.length}
                    paginate={paginate}
                    previousPage={previousPage}
                    nextPage={nextPage}
                /> */}

                <Pagination
                    count={Math.ceil(item.length / postsPerPage)}
                    onChange={handleChange}
                    page={currentPage}
                    color="primary"
                />
            </Box>
        </>
    )
}

export default MedicalDirectoryList