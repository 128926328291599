import localImg from '../../assets/resources/blog/no-image-icon.png';
import localVideo from '../../assets/resources/blog/video-icon.png';

const today = new Date();

export const default_blog_cover = '1hSrUHFV-PUYj4WMVYc8KFb-jffB_UpNL';

// ReactQuill constants
export const modules = {
    toolbar: {
        container: "#toolbar",
    }
};
export const modulesQuill = {
    toolbar: {
        container: "#mytoolbar",
    }
};
export const formats = [
    'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'script',
    'header', 'blockquote', 'code-block',
    'indent', 'list',
    'direction', 'align',
    'link', 'image', 'video', 'formula',
];
const colors = ["red", "green", "blue", "orange", "violet", "brown"];
export const quillFormats = [
    [
        {
            className: "ql-font",
            options: ['serif', 'monospace']
        },
        {
            className: "ql-size",
            options: ["small", "large", "huge"]
        }
    ],
    [
        { className: "ql-bold" }, { className: "ql-italic" }, { className: "ql-underline" }, { className: "ql-strike" }
    ],
    [
        {
            className: "ql-color",
            options: colors
        },
        {
            className: "ql-background",
            options: colors
        }
    ],
    [
        {
            className: "ql-script",
            value: "sub"
        },
        {
            className: "ql-script",
            value: "super"
        }
    ],
    [
        {
            className: "ql-header",
            value: "1"
        },
        {
            className: "ql-header",
            value: "2"
        },
        {
            className: "ql-blockquote"
        },
        {
            className: "ql-code-block"
        }
    ],
    [
        {
            className: "ql-list",
            value: "ordered"
        },
        {
            className: "ql-list",
            value: "bullet"
        },
        {
            className: "ql-indent",
            value: "-1"
        },
        {
            className: "ql-indent",
            value: "+1"
        }
    ],
    [
        {
            className: 'ql-direction',
            value: 'rtl'
        },
        {
            className: 'ql-align',
            options: ['right', 'center', 'justify']
        }
    ],
    [
        { className: 'ql-link' }, { className: 'ql-image' }, { className: 'ql-video' }, { className: 'ql-formula' }
    ]
];

// longitud de columna para bloque
export const blockLength = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
];

// valores iniciales blog
export const blogInit = {
    title: '',
    author: '',
    publication_date: today
};

export const blogView = {
    title: '',
    url: '',
    author: '',
    publication_date: '',
    category: [],
    imgId: default_blog_cover,
    content: [],
    preview: ''
};

export const blogRowInit = [
    {
        col: [],
        id: 'row-0'
    },
    {
        col: [],
        id: 'row-1'
    }
];

// example
// const blogRowInit = [
//     {
//         col: [],
//         id: 'row-0'
//     },
//     {
//         col: [
//             {
//                 item: '<p>Hola uno</p>',
//                 src: '',
//                 file: '',
//                 type: 'txt', // img / video
//                 xs: 12,
//                 md: 12,
//                 id: 'col-1'
//             },
//         ],
//         id: 'row-1'
//     }
// ];

// formato de bloque en edición
export const blockInEdition = {
    item: '',
    src: '',
    file: '',
    type: 'txt',
    xs: 12,
    md: 12,
    id: 'col-0'
};

// contenido de blog en edición
export const blogInEdition = {
    _id: '',
    title: '',
    author: '',
    publication_date: today,
    category: [],
    folderId: '',
    imgId: default_blog_cover,
    imgUploaded: false,
    content: [{
        col: [],
        id: 'row-0'
    }]
};

// portada por defecto de blog
export const blogMainImg = {
    imgSrc: localImg,
    imgFile: ''
};

// portada por defecto de blog (actualización)
export const blogUpdMainImg = {
    imgSrc: localImg,
    imgFile: '',
    imgUploaded: false
};

// portada de video en contenido de blog
export const blogVideo = localVideo;