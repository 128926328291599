import React from 'react';

import { Typography } from '@mui/material';
// import { startShippedSimulation } from '../../../actions/admin/advanced';

const EstadoEnvioMelonn = ({ selectedOrder }) => {
    const {
        delivery_status,
        shipping: { partner, cost, tracking_guide }
    } = selectedOrder;

    return (
        <>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Paquetería:</b> { partner }
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Estado:</b> { delivery_status }
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Guía de seguimiento: </b>
                { tracking_guide ? tracking_guide : 'Aún no disponible' }
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Costo de envío: </b>
                { cost === 0 ? 'Gratis' : `$${cost}` }
            </Typography>
        </>
    );
};

export default EstadoEnvioMelonn;