import { getPostalcodeData } from "../../actions/checkout";

// establecer valores (municipio, estado, colonias válidas)
export const setPostalcodeInfo = async (postalcode, setValues, setColonies, setDisabledFields) => {
    const resp = await getPostalcodeData(postalcode);
    if (resp.status) {
        const { resp: { municipio, estado, asentamiento } } = resp;
        setValues(e => ({
            ...e,
            colony: '',
            city: municipio,
            state: estado
        }));
        setColonies(asentamiento);
        setDisabledFields(e => ({
            ...e,
            cityInput: true, stateInput: true
        }));
    } else {
        setColonies([]);
        setDisabledFields(e => ({
            ...e,
            cityInput: false, stateInput: false
        }));
    }
};