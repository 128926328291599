import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadAdminList } from '../../../actions/admin';
import { adminUserModalToggle } from '../../../actions/ui';

import './adminsuser.css'
import AdminDialog from './AdminDialog';
import AdminUserItem from './AdminUserItem';
import AdminUserModal from './AdminUserModal';

import { Grid, Alert, Fab, Container, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const openAdd = {
    status: true,
    action: 'add'
};

const AdminsUsersList = () => {
    const dispatch = useDispatch();
    const { admins } = useSelector(state => state.admin);

    useEffect(() => {
        dispatch(loadAdminList());
    }, []);

    const addAdminUser = () => {
        dispatch(adminUserModalToggle(openAdd));
    };

    return (
        <>
            <Container maxWidth='xl' sx={{ mt: '90px' }}>
                <Typography variant='h5' sx={{ fontWeight: 'light' }} paragraph>Lista de administradores</Typography>
                <Grid container spacing={4}>
                    {
                        admins.length > 0 ?
                            admins.map(e =>
                                <AdminUserItem key={e._id} admin={e} />
                            )
                            : <Alert severity="warning">No hay administradores</Alert>
                    }
                </Grid>
            </Container>
            <Fab color="primary"
                aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2)
                }}
                onClick={addAdminUser}>
                <AddIcon />
            </Fab>
            <AdminUserModal />
            <AdminDialog />
        </>
    );
};

export default AdminsUsersList;
