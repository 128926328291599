import { types } from '../types/types';

const initialState = {
    categoryToggle: 'list',
    variantAccordion: false,
    adminUserDialog: false,
    adminUserModal: {
        status: false,
        action: 'add'
    },
    formsModal: {
        open: false,
        action: 'add'
    },
    commentModal: {
        open: false,
        action: 'add',
        loading: false
    },
    loginModal: false,
    shopSnackbar: {
        status: false, // mostrar/ocultar snackbar
        origin_page: '', // tipo de error en petición
        err_msg: '', // mensaje de error para snackbar
    },
    productsFBT_flag: false, // products frequently bought together en carrito
    loading1: false,
    loading2: false
};

export const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ui_categoryToggle:
            return {
                ...state,
                categoryToggle: action.payload
            }
        case types.ui_variantAccordion:
            return {
                ...state,
                variantAccordion: action.payload
            }
        case types.ui_adminUserModal:
            return {
                ...state,
                adminUserModal: action.payload
            }
        case types.ui_adminUserDialog:
            return {
                ...state,
                adminUserDialog: action.payload
            }
        case types.ui_formsModal:
            return {
                ...state,
                formsModal: action.payload
            }
        case types.ui_commentLoading:
            return {
                ...state,
                commentModal: {
                    ...state.commentModal,
                    ...action.payload
                }
            }
        case types.ui_commentModal:
            return {
                ...state,
                commentModal: action.payload
            }
        case types.ui_loginModal:
            return {
                ...state,
                loginModal: action.payload
            }
        case types.ui_shopSnackbar:
            return {
                ...state,
                shopSnackbar: action.payload
            }
        case types.ui_shopFBTAdded:
            return {
                ...state,
                productsFBT_flag: action.payload
            }
        case types.uiLoading1:
            return {
                ...state,
                loading1: action.payload
            }
        case types.uiLoading2:
            return {
                ...state,
                loading2: action.payload
            }
        default:
            return state;
    }
};