import React, { useEffect, useState } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

import {
    Card, CardMedia, IconButton, ImageList, CardActions,
    CardContent, Collapse, TextField, useMediaQuery
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    })
}));

const BannerImageList = ({ banner, setBanner, deletedImages, setDeletedImages }) => {
    const { type } = banner;
    const matches = useMediaQuery('(max-width: 768.5px)');

    const [images, setImages] = useState([]);

    useEffect(() => {
        setImages(banner[type].images);
    }, [banner]);
    
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        setBanner(e => ({
            ...e,
            [type]: {
                ...e[type],
                images: items
            }
        }));
    };

    const handleExpandClick = imgId => () => {
        setBanner(e => ({
            ...e,
            [type]: {
                ...e[type],
                images: e[type].images.map(i => (i.imgId === imgId) ? { ...i, expanded: !i.expanded } : i)
            }
        }));
    };

    const handleInputChange = imgId => () => {
        setBanner(e => ({
            ...e,
            [type]: {
                ...e[type],
                images: e[type].images.map(i => (i.imgId === imgId) ? { ...i, expanded: !i.expanded } : i)
            }
        }));
    };

    // remueve imágenes guardadas de banner
    const removeImg = imgId => () => {
        // setBanner(e => ({
        //     ...e,
        //     [type]: {
        //         ...e[type],
        //         images: e[type].images.filter(i => (i !== _item))
        //     }
        // }));
        setBanner(e => ({
            ...e,
            [type]: {
                ...e[type],
                images: e[type].images.filter(i => i.imgId !== imgId)
            }
        }));
        setDeletedImages({
            ...deletedImages,
            [type]: [...deletedImages[type], imgId]
        });
    };

    return (
        <DragDropContext onDragEnd={ handleOnDragEnd }>
            <Droppable droppableId="desktop">
                {(provided) => (
                    <ImageList gap={20} cols={matches ? 2 : 3}
                        {...provided.droppableProps} ref={provided.innerRef}>
                        {
                            images.length > 0 &&
                            images.map(({ imgId, url, expanded }, index) => {
                                return (
                                    <Draggable key={imgId} draggableId={imgId} index={index}>
                                        {(provided) => (
                                            <Card key={index} variant="elevation" ref={provided.innerRef}
                                                {...provided.draggableProps} {...provided.dragHandleProps} sx={{ mb: 1 }}>
                                                <CardMedia
                                                    component="img"
                                                    alt={ 'banner-nefropolis' }
                                                    height="140"
                                                    image={ `${GOOGLE_DRIVE_CDN}${imgId} `}
                                                />
                                                {/* <ImageListItem sx={matches ? { height: '100%' } : { height: '250px !important' }}>
                                                    <img
                                                        src={ `${GOOGLE_DRIVE_CDN}${item} `}
                                                        alt={item}
                                                        loading="lazy"
                                                        style={{ border: '1px solid green' }}
                                                    />
                                                    <ImageListItemBar
                                                        actionIcon={
                                                            <IconButton
                                                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                                onClick={removeImg(item)}
                                                            >
                                                                <RemoveCircleIcon />
                                                            </IconButton>
                                                        }
                                                    />
                                                </ImageListItem> */}
                                                <CardActions>
                                                    <IconButton
                                                        onClick={ removeImg(imgId) }
                                                    >
                                                        <RemoveCircleIcon />
                                                    </IconButton>
                                                    <ExpandMore
                                                        expand={ expanded }
                                                        onClick={ handleExpandClick(imgId) }
                                                        aria-expanded={ expanded }
                                                        aria-label="show more"
                                                    >
                                                        <ExpandMoreIcon />
                                                    </ExpandMore>
                                                </CardActions>
                                                <Collapse in={ expanded } timeout="auto" unmountOnExit>
                                                    <CardContent>
                                                        <TextField fullWidth
                                                            variant="standard"
                                                            label='URL'
                                                            size='small'
                                                            name="URL"
                                                            value={ url }
                                                            onChange={ handleInputChange }
                                                        />
                                                    </CardContent>
                                                </Collapse>
                                            </Card>
                                        )}
                                    </Draggable>
                                );
                            })
                        }
                        {provided.placeholder}
                    </ImageList>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default BannerImageList;