import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import { productDelete, productEdit } from '../../../../actions/product';

const ProductListMenu = ({ anchorEl, setAnchorEl }) => {
    const dispatch = useDispatch();
    const { active } = useSelector(state => state.product);
    const { _id, title, folderId, prod_variants } = active;
    const open = Boolean(anchorEl);

    // editar producto
    const editProduct = () => {
        setAnchorEl(null);
        dispatch(productEdit());
    };

    const deleteProduct = () => {
        setAnchorEl(null);
        Swal.fire({
            title: `¿Deseas eliminar el producto ${title}?`,
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(productDelete(_id, title, folderId, prod_variants));
            }
        });
    };
    // cerrar menú
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Menu id="product-list-menu"
            anchorEl={ anchorEl }
            open={ open }
            onClose={ handleClose }
            MenuListProps={{
                'aria-labelledby': 'basic-settings',
            }}>
            <MenuItem onClick={ editProduct }>Editar</MenuItem>
            <MenuItem onClick={ deleteProduct }>Eliminar</MenuItem>
      </Menu>
    );
};

export default ProductListMenu;