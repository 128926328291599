import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import './ProductReview.css'

//Owl carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

//Router dom
import { Link } from 'react-router-dom';

import { revDelete, revEdition } from '../../../../actions/review';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

import {
    Avatar, Grid, ListItemIcon, ListItemText, Menu, MenuItem,
    IconButton, Card, CardHeader, CardContent, Typography
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const options = {
    margin: 15,
    nav: false,
    dots: true,
    responsive: {
        0: {
            items: 1,
        },
        430: {
            items: 2,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 3,

        }
    },
};

const ProductReview = ({ review, prod_id }) => {
    const dispatch = useDispatch();
    // usuario iniciado
    const { loggedUser } = useSelector(state => state.user);
    const { username: logged_user } = loggedUser;
    const { _id, username, rating, comment, folderId, images } = review;

    // abre modal para añadir reseña
    const editReview = () => {
        dispatch(revEdition(review));
        setAnchorEl(null);
    };

    // elimina reseña
    const deleteReview = () => {
        Swal.fire({
            title: '¿Desea eliminar su comentario?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(revDelete({ prod_id, _id, folderId }));
            }
        });
        setAnchorEl(null);
    };

    const [show, setShow] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Card sx={{ width: '100%', mt: 2, boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: "#2699FB" }} aria-label="recipe">
                        {Array.from(username)[0]}
                    </Avatar>
                }
                action={
                    username === logged_user
                        ?
                        <>
                            <IconButton onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={editReview}>
                                    <ListItemIcon>
                                        <EditIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Editar</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={deleteReview}>
                                    <ListItemIcon>
                                        <DeleteIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Eliminar comentario</ListItemText>
                                </MenuItem>
                            </Menu>
                        </>
                        :
                        null
                }
                title={username}
                subheader="Octubre 12, 2022"
            />
            <CardContent>
                {
                    Array.from(Array(rating), (e, j) => {
                        return <StarIcon
                            key={j}
                            style={{
                                color: '#FFA41C',
                                stroke: "#FFA41C",
                                strokeWidth: 0.2
                            }} />
                    })
                }
                {
                    comment.length > 400
                        ?
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            {show ? comment : `${comment.substring(0, 400)}...`} <Link to={''} onClick={() => { setShow(!show) }}> {show ? 'Mostrar menos' : "Mostrar más"} </Link>
                        </Typography>
                        :
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            {comment}
                        </Typography>
                }
                <Grid container columnSpacing={3} sx={{ mt: 2 }} id='review_image'>
                    {
                        images.map((item, i) => {
                            return (
                                <Grid item xl={3} lg={3} md={4} sm={4} xs={12} key={i}>
                                    <img
                                        src={`${GOOGLE_DRIVE_CDN}${item}`}
                                        width="100%"
                                        alt={item}
                                        style={{ borderRadius: '5px', pointerEvents: 'none' }}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>

                <div className="review_carousel">
                    <OwlCarousel className="owl-theme" {...options}>
                        {
                            images.map((item, i) => {
                                return (
                                    <div className="item" key={i}>
                                        <img
                                            src={`${GOOGLE_DRIVE_CDN}${item}`}
                                            width="100%"
                                            alt={item}
                                            style={{ pointerEvents: 'none', borderRadius: '5px' }}
                                        />
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </div>

            </CardContent>
        </Card>
    );
};

export default ProductReview;