import { types } from '../types/types';

// métodos de envío recuperados
export const shippingMethodsLoaded = (payload) => ({
    type: types.shippingMethodsLoaded,
    payload
});

// método de envío seleccionado
export const shippingMethodSelected = (payload) => ({
    type: types.shippingMethodSelected,
    payload
});