import React from 'react';

import MaterialTable, { MTableToolbar } from "material-table";

const columns = [
    {
        title: "Producto",
        field: "title",
    },
    {
        title: "Variante",
        field: "variant_title",
    },
    {
        title: "Cantidad",
        field: "quantity",
    },
    {
        title: "Precio unitario",
        field: "price",
    },
];

const PAProductos = ({ selectedOrder }) => {
    const { products } = selectedOrder;
    return (
        <>
            <MaterialTable
                title="Detalle de compra"
                data={products}
                columns={columns}
                options={{
                    exportButton: true,
                    headerStyle: {
                        zIndex: 1
                    },
                }}
                components={{
                    Toolbar: props => (<MTableToolbar {...props} classes={{ root: "dummy-class" }} />),
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: "No hay datos por mostrar",
                        addTooltip: 'Agregar',
                        deleteTooltip: 'Eliminar',
                        editTooltip: 'Editar',
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        },
                        editRow: {
                            deleteText: '¿Segura(o) que quiere eliminar?',
                            cancelTooltip: 'Cancelar',
                            saveTooltip: 'Guardar'
                        }
                    },
                    grouping: {
                        placeholder: "Arrastre un encabezado aquí para agrupar",
                        groupedBy: 'Agrupado por:'
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'filas',
                        labelRowsPerPage: 'Filas por página:',
                        firstAriaLabel: 'Primera página',
                        firstTooltip: 'Primera página',
                        previousAriaLabel: 'Página anterior',
                        previousTooltip: 'Página anterior',
                        nextAriaLabel: 'Página siguiente',
                        nextTooltip: 'Página siguiente',
                        lastAriaLabel: 'Última página',
                        lastTooltip: 'Última página'
                    },
                    toolbar: {
                        addRemoveColumns: 'Agregar o eliminar columnas',
                        nRowsSelected: '{0} fila(s) seleccionada(s)',
                        showColumnsTitle: 'Mostrar columnas',
                        showColumnsAriaLabel: 'Mostrar columnas',
                        exportTitle: 'Exportar',
                        exportAriaLabel: 'Exportar',
                        exportName: "Exportar como CSV",
                        exportPDFName: "Exportar como PDF",
                        searchTooltip: 'Buscar',
                        searchPlaceholder: 'Buscar'
                    }
                }}
            />
        </>
    );
};

export default PAProductos;
