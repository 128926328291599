import React from "react";

//CSS
import './distribuidorautorizado.css'

//IMG
import distribuidor_autorizado_banner from '../../assets/images/distribuidor-autorizado-png.png'

//Components
// import PuntosDeVenta from "../PuntosDeVenta/PuntosDeVenta";

//router-dom
import { Link } from "react-router-dom";

//MUI
import { Box, Container, Grid, Typography, useMediaQuery  } from '@mui/material'

const DistribuidoresAutorizadosComponent = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    return (
        <>
            {/* Img distribuidor autorizado */}
            <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18, textAlign: 'center' }}>
                <div className="distribuidor-autorizado-banner">
                    <img src={distribuidor_autorizado_banner} width="40%" alt="Distribuidor Autorizado" />
                </div>
            </Container>

            {/* Container */}
            <Container maxWidth='xl' sx={{ mt: 4 }} className="last-view">
                {/* Row */}
                <Grid container spacing={4}>
                    {/* Nefropolis CIUDAD JUÁREZ */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        {/* Nombre de la sucursal */}
                        <Typography variant='h5' sx={{ fontWeight: 'light' }} align='center'>CIUDAD JUÁREZ</Typography>
                        <Box sx={{ mt: 3 }}>
                            {/* Dirección */}
                            <Typography variant='body1' gutterBottom>
                                <a href="https://www.google.com/search?q=nefropolis%20ciudad%20juarez&ei=3AEGZIiIJtirqtsP-ZK70A8&ved=2ahUKEwj239zUysf9AhVll2oFHd9_BqAQvS56BAgREAE&uact=5&oq=nefropolis+ciudad+juaarez&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIHCCEQoAEQCjIHCCEQoAEQCjoNCAAQjwEQ6gIQtAIYAToNCC4QjwEQ6gIQtAIYAToRCC4QgAQQsQMQgwEQxwEQ0QM6BAguEEM6CwguEIAEELEDEIMBOggIABCABBCxAzoLCAAQgAQQsQMQgwE6BQguEIAEOg4ILhCABBCxAxDHARDRAzoFCAAQgAQ6EAguELEDEIMBEMcBENEDEEM6BwguELEDEEM6BAgAEEM6BwguENQCEEM6EwguEIAEELEDEIMBEMcBENEDEAo6BwgAELEDEEM6BggAEAoQQzoLCC4QgAQQxwEQrwE6CQgAEBYQHhDxBDoGCAAQFhAeOgUIIRCgAUoECEEYAFCnB1jZKGDeKWgBcAB4AIABcIgB7xCSAQQyMy4ymAEAoAEBsAEKwAEB2gEECAEYCg&sclient=gws-wiz-serp&tbs=lf:1,lf_ui:10&tbm=lcl&rflfq=1&num=10&rldimm=925822802364982241&lqi=ChhuZWZyb3BvbGlzIGNpdWRhZCBqdWFyZXpaGiIYbmVmcm9wb2xpcyBjaXVkYWQganVhcmV6kgEMbnV0cml0aW9uaXN0qgESEAEqDiIKbmVmcm9wb2xpcygO&phdesc=jd2uNrU7mAk&sa=X&rlst=f#rlfi=hd:;si:925822802364982241,l,ChhuZWZyb3BvbGlzIGNpdWRhZCBqdWFyZXpaGiIYbmVmcm9wb2xpcyBjaXVkYWQganVhcmV6kgEMbnV0cml0aW9uaXN0qgESEAEqDiIKbmVmcm9wb2xpcygO,y,jd2uNrU7mAk;mv:[[31.736940899999997,-106.4258893],[31.704339500000003,-106.4371651]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!2m1!1e3!3sIAE,lf:1,lf_ui:10"
                                    target="_blank" rel="noopener noreferrer"
                                >
                                    <span style={{ marginRight: '5px' }}>
                                        <i className="fa-solid fa-location-dot"></i>
                                    </span>
                                    Av Valentín Fuentes Varela 2571 Col. Casas Grandes, C.P. 32600 Cd Juárez, Chih.
                                </a>
                            </Typography>

                            {/* Teléfono */}
                            <Typography variant='body1' gutterBottom>
                                <span style={{ marginRight: '5px' }}>
                                    <i className="fa-solid fa-phone"></i>
                                </span>
                                Teléfono: 65 6198 4826
                            </Typography>

                            {/* Correo */}
                            <Typography variant='body1' gutterBottom>
                                <span style={{ marginRight: '5px' }}>
                                    <i className="fa-solid fa-envelope"></i>
                                </span>
                                cdjuarez@nefropolis.com
                            </Typography>

                            {/* landing */}
                            <Typography variant='body1' gutterBottom>
                                <Link to='/juarez-distribuidor-autorizado'>
                                    nefropolis.com/juarez-distribuidor-autorizado
                                </Link>
                            </Typography>

                            {/* Horario */}
                            <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                                <li>Lunes a Viernes</li>
                                <li>9:30 am - 2 pm, 2:30 pm - 6 pm</li>
                                <li>Sábado</li>
                                <li>10:00 am – 2:30 pm</li>
                            </ul>

                        </Box>
                    </Grid>
                    {/* Fin Nefropolis CIUDAD JUÁREZ */}

                    {/* Puerto vallarta */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        {/* Nombre de la sucursal */}
                        <Typography variant='h5' sx={{ fontWeight: 'light' }} align='center'>PTO. VALLARTA</Typography>
                        <Box sx={{ mt: 3 }}>
                            {/* Dirección */}
                            <Typography variant='body1' gutterBottom>
                                <a href="https://www.google.com/maps/place/Aldanaca+177,+Versalles,+48310+Puerto+Vallarta,+Jal./@20.6352013,-105.2269201,17z/data=!3m1!4b1!4m5!3m4!1s0x84214564c8690de5:0xed93b80f1c886dfe!8m2!3d20.6352013!4d-105.2269201"
                                    target="_blank" rel="noopener noreferrer"
                                >
                                    <span style={{ marginRight: '5px' }}>
                                        <i className="fa-solid fa-location-dot"></i>
                                    </span>
                                    Av. Aldanaca #177 Versalles, C.P. 48310, Pto. Vallarta, Jalisco.
                                </a>
                            </Typography>

                            {/* Teléfono */}
                            <Typography variant='body1' gutterBottom>
                                <span style={{ marginRight: '5px' }}>
                                    <i className="fa-solid fa-phone"></i>
                                </span>
                                Teléfono: 32 2369 0057
                            </Typography>

                            {/* Correo */}
                            <Typography variant='body1' gutterBottom>
                                <span style={{ marginRight: '5px' }}>
                                    <i className="fa-solid fa-envelope"></i>
                                </span>
                                farmaciaprovisalud@gmail.com
                            </Typography>

                            {/* Horario */}
                            <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                                <li>Lunes a Viernes</li>
                                <li>7:00 am a 9:00 pm</li>
                                <li>Sábado - Domingo</li>
                                <li>8:00 am – 4:00 pm</li>
                            </ul>

                        </Box>
                    </Grid>
                    {/* Fin Puerto vallarta */}
                </Grid>
                {/* Fin row */}
            </Container>
            {/* Fin container */}

            {/* <div className="last-view">
                <PuntosDeVenta />
            </div> */}
        </>
    )
}

export default DistribuidoresAutorizadosComponent