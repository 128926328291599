import React, { useState, useRef } from 'react';

import { valMsg } from '../../../../../actions/swal_msg';

import {
    Button, TextField, Stack, Typography, Accordion,
    AccordionSummary, AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isValid, format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const regex = /^[0-9\b]+$/;

const initialBatch = {
    batch: '',
    total_pieces: '',
    expiration_date: new Date()
};

const inputErrors = {
    batch_err: false,
    pieces_err: false
};

const VariantBatch = ({ batches, setBatches }) => {
    // referencias a campos
    const batchRef = useRef();
    const piecesRef = useRef();
    // valores de lote
    const [batchValues, setBatch] = useState(initialBatch);
    const { batch, total_pieces, expiration_date } = batchValues;
    // errores en campos
    const [inputErr, setInputErr] = useState(inputErrors);
    const { batch_err, pieces_err } = inputErr;

    // validación formulario de lote
    const formValidator = () => {
        const batch_len = batch.trim().length;
        const pieces_len = total_pieces.trim().length;
        if (batch_len > 1 && batch_len <= 80) {
            if (pieces_len > 0 && pieces_len <= 5
                && regex.test(total_pieces))
                if (isValid(expiration_date)) {
                    const found = batches.find(e => e.batch === batch)
                    if (!found)
                        return true;
                    else
                        valMsg('warning', 'Lote de variante',
                            'Identificador de lote ya existe');
                } else
                    valMsg('warning', 'Lote de variante',
                        'Ingrese una fecha de caducidad');
            else {
                piecesRef.current.select();
                valMsg('warning', 'Lote de variante',
                    'Ingrese cantidad de piezas');
            }
        } else {
            batchRef.current.select();
            valMsg('warning', 'Lote de variante',
                'Ingrese identificador de lote');
        }
        // establece errores en los campos
        setInputErr({
            ...inputErr,
            batch_err: (batch.length < 2 ||
                batch.length > 80) ? true : false,
            pieces_err: (total_pieces.length < 1 ||
                total_pieces.length > 5 ||
                !regex.test(total_pieces)) ? true : false
        });
        return false;
    };

    // agregar lote de producto
    const addBatch = () => {
        if (formValidator()) {
            setBatches(e => ([...e, batchValues]));
            setBatch(initialBatch);
        }
    };

    // cambios en campos de lote
    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'total_pieces') {
            if (value === '' || regex.test(value))
                setBatch(e => ({ ...e, total_pieces: value }));
        } else setBatch(e => ({ ...e, [name]: value }));
        switch (name) {
            case 'batch':
                setInputErr({
                    ...inputErr,
                    batch_err: (value.length < 2 ||
                        value.length > 80) ? true : false
                });
                break;
            case 'pieces':
                setInputErr({
                    ...inputErr,
                    pieces_err: (value.length < 1 ||
                        value.length > 5) ? true : false
                });
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Typography variant="h6" gutterBottom>
                Inventario
            </Typography>
            {
                batches.map((e, i) =>
                    <Accordion key={i}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}>
                            <Typography>{ `Lote: ${e.batch}` }</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                { `Piezas de lote: ${e.total_pieces}` }
                            </Typography>
                            <Typography>
                                { `Fecha de caducidad: ${format(e.expiration_date, 'dd/MM/yyyy')}` }
                            </Typography>
                            <Button variant="outlined"
                                onClick={() => {
                                    setBatches(batches.filter(u => u.batch !== e.batch));
                                }}>
                                Eliminar
                            </Button>
                        </AccordionDetails>
                    </Accordion>
                )
            }

            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="new-batch-content"
                    id="new-batch-header">
                    <Typography>Agregar lote</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField sx={{ mb: 2 }}
                        fullWidth
                        name="batch"
                        label="Lote"
                        variant="outlined"
                        value={ batch }
                        inputProps={{ maxLength: 80 }}
                        error={ batch_err }
                        helperText={ batch_err ? "Ingrese identificador de lote" : "" }
                        inputRef={ batchRef }
                        onChange={ handleInputChange } />
                    <TextField sx={{ mb: 2 }}
                        fullWidth
                        name="total_pieces"
                        label="Piezas en lote"
                        variant="outlined"
                        value={ total_pieces }
                        inputProps={{ maxLength: 5 }}
                        error={ pieces_err }
                        helperText={ pieces_err ? "Ingrese cantidad de piezas" : "" }
                        inputRef={ piecesRef }
                        onChange={ handleInputChange } />
                    <LocalizationProvider dateAdapter={AdapterDateFns }>
                        <DatePicker
                            label="Fecha de caducidad"
                            value={ expiration_date }
                            onChange={(newValue) => {
                                setBatch({ ...batchValues, expiration_date: newValue });
                            }}
                            renderInput={
                                (params) =>
                                <TextField
                                    {...params} />
                            }
                        />
                    </LocalizationProvider>
                    <Stack sx={{ mt: 2 }}
                        alignItems="flex-start">
                        <Button variant="outlined"
                            onClick={ addBatch }>
                            Guardar
                        </Button>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default VariantBatch;