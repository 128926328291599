import React from 'react';

import {
    Dialog, DialogTitle
} from '@mui/material';
import DesktopContainer from './ImagesContainer/DesktopContainer';

const BannerShopDialog = ({
    handleCloseDialog, openDialog,
    banner, setBanner,
    deletedImages, setDeletedImages
}) => {
    return (
        <Dialog onClose={handleCloseDialog} open={openDialog}
            fullWidth={true}
            maxWidth='xl'>
            <DialogTitle>Editar diseño</DialogTitle>
            <DesktopContainer
                banner={ banner }
                setBanner={ setBanner }
                deletedImages={ deletedImages }
                setDeletedImages={ setDeletedImages }
            />
        </Dialog>
    );
};

export default BannerShopDialog;