import React from 'react';

//MUI
import { Container, Typography, useMediaQuery } from '@mui/material';
import { CUSTOMER_EMAIL } from '../../../constants/constants';

const PoliticaEnviosComponent = () => {
    const matches = useMediaQuery('(max-width: 950px)');

    return (
        <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }} className="last-view">
            <Typography variant='h4' sx={{ fontWeight: 'light' }} align='center' paragraph>Política de envíos</Typography>
            <ul style={{ fontSize: '17px' }}>
                <li style={{ marginBottom: '15px' }}>Nuestro servicio de envíos se encuentra disponible únicamente en México. Por el momento no se realizan envíos internacionales.</li>
                <li style={{ marginBottom: '15px' }}>Los Envíos dentro de México abarcan todos los estados de la República Mexicana, a excepción de que la paquetería no cuente con la cobertura o se reserve el derecho de algún destino.</li>
                <li style={{ marginBottom: '15px' }}>El precio estándar de envío a todo México es de <b>$150 MXN</b> siempre y cuando no exceda de los 5 kilogramos.</li>
                <li style={{ marginBottom: '15px' }}>El monto total del pago corresponde al precio de los productos más el envío a domicilio.</li>
                <li style={{ marginBottom: '15px' }}>Si su compra es mayor a 3,000 pesos, el envío no tendrá costo. La elección de la paquetería será la que consideremos más adecuada y tenga la cobertura hacia su código postal.</li>
                <li style={{ marginBottom: '15px' }}>Todas las órdenes de compra realizadas a través del sitio de internet serán entregadas por empresas de paquetería y mensajería ajenas a Nefrópolis.</li>
                <li style={{ marginBottom: '15px' }}>La entrega del producto se realizará en el domicilio señalado por el usuario en el portal de la cuenta o en una dirección diferente proporcionada por el usuario, que deberá realizarse al momento de confirmar el pedido. En caso de que su dirección este incompleta uno/a de nuestros/as asesores/as se comunicará con usted para completar la información de envío.</li>
                <li style={{ marginBottom: '15px' }}>El tiempo de entrega podrá ser de <b>3 a 10 días hábiles</b>, contados a partir de que se ha recibido el importe completo de la compra. El tiempo de entrega dependerá del código postal del destino.</li>
                <li style={{ marginBottom: '15px' }}>En caso de que la paquetería no llegue hasta la localidad que se solicita, el paquete será depositado en la sucursal de la paquetería más cercana.</li>
                <li style={{ marginBottom: '15px' }}>Los pedidos que se hagan después de la 1:00 p.m. saldrán hasta el día habil siguiente.</li>
                <li style={{ marginBottom: '15px' }}>En caso de que no se encuentre nadie para recibir el paquete en la dirección proporcionada en la primera ni en la segunda visita, el paquete será depositado en la sucursal más cercana y se dejará ahí por tres días hábiles, en caso de no ser reclamada, será devuelta al almacén de Nefrópolis y el cliente deberá pagar nuevamente el costo de envío.</li>
                <li style={{ marginBottom: '15px' }}>Las entregas de productos se realizarán únicamente en días hábiles y podrían demorarse en algunos casos hasta 10 días hábiles.</li>
                <li style={{ marginBottom: '15px' }}>En caso de existir alguna inconformidad deberá manifestarla en ese momento y deberá comunicarse al servicio de atención a clientes de Nefrópolis al 33 3826 8009 o enviar un correo a <a href={ `mailto:${CUSTOMER_EMAIL}` }>{ CUSTOMER_EMAIL }</a> para aclarar dicha circunstancia.</li>
                <li style={{ marginBottom: '15px' }}>Todos los productos serán empacados sin ningún costo extra.</li>
                <li style={{ marginBottom: '15px' }}>En un plazo máximo de 48 horas siguientes a tu compra en línea, recibirás un correo con el número de guía para que puedas rastrear el producto. Esta política no aplica para días de puente, asuetos, días no laborables.</li>
                <li style={{ marginBottom: '15px' }}>Al momento de recibir el producto en el domicilio señalado, el usuario está obligado a revisar el producto. En caso de algún desperfecto, el usuario deberá notificarlo en un plazo de 3 días hábiles al correo <a href={ `mailto:${CUSTOMER_EMAIL}` }>{ CUSTOMER_EMAIL }</a> o al 33 3826 8009.</li>
                <li style={{ marginBottom: '15px' }}>¿Tu pedido no estaba completo o te llegó algo incorrecto? <b>Una vez recibido su paquete, usted tiene 24 horas para reportar cualquier incidencia con el mismo y/o solicitar su cambio o devolución al teléfono 3338268009 ext 118.</b></li>
                <li style={{ marginBottom: '15px' }}>Cualquier tipo de aclaración, reporte o duda respecto al servicio de envíos favor de escribir a: <a href={ `mailto:${CUSTOMER_EMAIL}` }>{ CUSTOMER_EMAIL }</a>.</li>
            </ul>
        </Container>
    );
};

export default PoliticaEnviosComponent;