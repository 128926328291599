import { htmlMsg, msg } from '.././swal_msg';
import { protectedReq } from '../../helpers/requestHelper';
import { generateURL } from '../../helpers/admin/productHelper';

// actualización masiva de Odoo ID de productos mediante arreglo
export const updateProductId = async(arr) => {
    try {
        const array = arr.map(({ Producto, ID_Variante, ID_Odoo_BTL }) => {
            return {
                name: Producto ? Producto : 'Producto desconocido',
                nefroapp_id: ID_Variante ? ID_Variante : '',
                odoo_id: ID_Odoo_BTL ? ID_Odoo_BTL : '',
            }
        });
        const resp = await protectedReq('tools/product-odoo-id', { array }, 'PUT');
        const body = await resp.json();
        if (body.status) {
            msg('success', 'Actualización masiva', 'Correcto');
        } else
            msg('warning', 'Actualización masiva',
            'Ocurrió un incidente. Intente de nuevo, por favor');
        return body.status;
    } catch (err) {
        msg('error', 'Actualización masiva',
        "No se pudo hacer actualización de ID's");
        return false;
    }
};

// se actualizan los campos del producto dependiendo las columnas que se incluyan
export const massiveProductUpdate = async(arr) => {
    try {
        // cambiar columna 'test_odoo_id' dependiente el ambiente
        const array = arr.map(({ ID_Producto, Category, Subcategory }) => {
            const category = Category ? Category.split(",").map(e => e.trim()) : [];
            const subcategory = Subcategory ? Subcategory.split(",").map(e => e.trim()) : [];
            const category_url = category.length > 0 ? category.map(e => generateURL(e)) : [];
            return {
                ID_Producto: ID_Producto ? ID_Producto : '',
                category, subcategory, category_url
            }
        });
        // const array = arr.map(({ Producto, ID_Variante, _Stock }) => {
        //     const id_variante = ID_Variante ? ID_Variante : '';
        //     const stock = _Stock ? _Stock : '';
        //     return {
        //         Producto: Producto ? Producto : '',
        //         id_variante, stock
        //     }
        // });
        const resp = await protectedReq('tools/massive-product-update', { array }, 'PUT');
        const body = await resp.json();
        if (body.status) {
            const { failed, success, no_values } = body.resp;
            htmlMsg('success', 'Actualización masiva',
            `<p>ID de odoo actualizados en productos</p><h5>Resumen de carga:</h5>
            <h6>Productos actualizados: ${success.length}</h6>
            <h6>Producto no encontrado/actualizado: ${failed.length}</h6>
            <h6>Productos NO actualizados: ${no_values.length} (falta información)</h6>`);
        } else
            msg('warning', 'Actualización masiva',
            'Ocurrió un incidente. Intente de nuevo, por favor');
        return body.status;
    } catch (err) {
        msg('error', 'Actualización masiva',
        "No se pudo hacer actualización de ID's");
        return false;
    }
};

// actualización masiva de precios
export const massivePriceUpdate = async(arr) => {
    try {
        const array = arr.map(({ iva, price, nefroapp_id }) => {
            return {
                iva: iva === 0.16 ? 16 : 0,
                price: price ? price : '',
                nefroapp_id: nefroapp_id ? nefroapp_id : ''
            }
        });
        const resp = await protectedReq('forbidden/massive-price-update', { array }, 'PUT');
        const body = await resp.json();
        if (body.status) {
            const { failed, success, no_values } = body.resp;
            htmlMsg('success', 'Actualización masiva',
            `<p>Precio de productos actualizado</p><h5>Resumen de carga:</h5>
            <h6>Productos actualizados: ${success.length}</h6>
            <h6>Producto no encontrados/actualizado: ${failed.length}</h6>
            <h6>Productos NO actualizados: ${no_values.length} (falta información)</h6>`);
        } else
            msg('error', 'Actualización de precios',
            'Ocurrió un incidente. Intente de nuevo, por favor');
        return body.status;
    } catch (err) {
        msg('error', 'Actualización de precios',
        "No se pudo hacer actualización de precios");
        return false;
    }
};

// actualización de inventario
export const stockUpdate = async(arr) => {
    try {
        const array = arr.map(({ Producto, ID_Variante, Stock }) => {
            return {
                id_variante: ID_Variante ? ID_Variante : '',
                producto: Producto ? Producto : '',
                stock: Stock ? Stock : 0
            }
        });
        const resp = await protectedReq('tools/massive-stock-update', { array }, 'PUT');
        const body = await resp.json();
        if (body.status) {
            const { failed, success, notFound, noInfo } = body.resp;
            htmlMsg('success', 'Actualización masiva',
            `<p>Inventario actualizado</p><h5>Resumen:</h5>
            <h6>Productos actualizados: ${success.length}</h6>
            <h6>Productos no actualizados: ${failed.length}</h6>
            <h6>Productos no encontrados: ${notFound.length}</h6>
            <h6>Productos sin código de barras: ${noInfo.length}</h6>`);
        } else
            msg('error', 'Actualización de precios',
            'Ocurrió un incidente. Intente de nuevo, por favor');
        return body.status;
    } catch (err) {
        msg('error', 'Actualización de precios',
        "No se pudo hacer actualización de precios");
        return false;
    }
};

export const startShippedSimulation = async(id, order_num, status, name) => {
    try {
        const eventName = status === 'shipped' ? 'SELL_ORDER/SHIPPED' : 'SELL_ORDER/DELIVERED_TO_BUYER';
        const model = {
            eventName,
            eventData: {
                trackingLink: "PRUEBAXXXXXX",
                externalOrderId: id,
                externalOrderNumber: order_num,
                shippingMethod: {
                    name
                },
            }
        };
        const resp = await protectedReq('melonn/nefro-order', model, 'POST');
        const body = await resp.json();
        if (body.status)
            return { status: true, msg: 'success' };
        else
            return { status: false, msg: 'error' };
    } catch (err) {
        return { status: false, msg: 'oops' };
    }
};

export const orderFilter = async() => {
    try {
        // const resp = await protectedReq('forbidden/order-filter');
        const resp = await protectedReq('forbidden/odoo-orders');
        const body = await resp.json();
        return body;
    } catch (err) {
        htmlMsg('error', 'Pedidos en Odoo',
        `<p>No se pudo recuperar pedidos de BD</p><h6>Error: ${err}</h6>`);
        return { status: false };
    };
};

export const productsInfo = async() => {
    try {
        const resp = await protectedReq('tools/products-info');
        const body = await resp.json();
        return body;
    } catch (err) {
        msg('error', 'Configuración',
        'Error');
        return { status: false };
    }
};