import React from 'react'

//MUI
import { Container, Typography, Box, useMediaQuery } from '@mui/material'

const CodigoEtica = () => {

    const matches = useMediaQuery('(max-width: 950px)')
    
    return (
        <>
            <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }}>
                <Typography variant='h4' sx={{ fontWeight: 'light' }} align='center'>Código de ética</Typography>
            </Container>

            <Container maxWidth='xl' sx={{ mt: 5 }} className="last-view">
                {/* Introducción */}
                <Typography variant='h5' sx={{ textTransform: 'uppercase' }}>Introducción</Typography>
                <Typography variant='body1' paragraph>El siguiente código de ética está dirigido para todos nuestros colaboradores y demás
                    grupos de interés. Con el fin de determinar los lineamientos éticos a los que debe
                    apegarse la organización y quién se involucre con ella. Todos somos responsables del
                    cumplimiento de dicho código de ética, por lo cual te invitamos a conocerlo.</Typography>
                {/* Objetivos */}
                <Typography variant='h5' sx={{ textTransform: 'uppercase' }}>Objetivos</Typography>
                <Typography variant='body1'>​Los objetivos del presente código de ética son:</Typography>
                <ul>
                    <li>Establecer las directrices y políticas que regulen el comportamiento ético dentro de la empresa.</li>
                    <li>Dar a conocer los lineamientos éticos generales a nuestros grupos de interés (colaboradores,
                        proveedores, franquiciatarios, distribuidores y clientes) para dirigirnos conforme a este.</li>
                    <li>Ser una empresa ética que se preocupa por la sociedad y su entorno.</li>
                </ul>
                {/* ALCANCE */}
                <Typography variant='h5' sx={{ textTransform: 'uppercase' }}>Alcance</Typography>
                <Typography variant='body1' paragraph   >El presente código de ética es aplicable a toda aquella persona que tenga relación con Nefrópolis®, hablando de socios, inversionistas, franquiciatarios, colaboradores, proveedores,
                    distribuidores y clientes. Todas las partes son responsables del conocimiento y cumplimiento del
                    mismo código.</Typography>
                {/* NUESTROS PRINCIPIOS ÉTICOS */}
                <Typography variant='h5' sx={{ textTransform: 'uppercase' }}>Nuestros principios éticos</Typography>
                <Typography variant='body1'>El actuar de todos los que pertenecemos a Nefrópolis® es basado en los siguientes principios:</Typography>
                <ul >
                    <li><b>Respeto.</b> Actuar con respecto frente a nuestros compañeros, nuestros clientes, nuestros proveedores, frente a la sociedad y al medio ambiente.</li>
                    <li><b>Integridad.</b> Actuar correctamente, viendo el bien común y no afectando a terceros.Honestidad. Actuar con honestidad para ser justos en todas las situaciones que se nos presenten.</li>
                    <li><b>Humanismo.</b> Ver por el otro, ser generosos y compasivos con los que nos rodean.</li>
                    <li><b>Lealtad.</b> Ser fieles y leales a los principios éticos de la empresa. Evitando incurrir en actos desleales.</li>
                    <li><b>Justicia.</b> Actuar de manera justa en nuestras decisiones, sin tener favoritismos o preferencias.</li>
                    <li><b>Equidad.</b> Todos tenemos los mismos derechos, ninguna persona está por encima de otra. Se prohíbe todo tipo de discriminación y desigualdad.</li>
                    <li><b>Responsabilidad.</b> Ser responsables en nuestras acciones, cumpliendo en tiempo y forma con nuestros compromisos.</li>
                </ul>

                <Box sx={{ my: 5 }}>
                    <Typography variant='h5' sx={{ fontWeight: 'bold' }} align='center'>COMPORTAMIENTO ORGANIZACIONAL</Typography>
                </Box>

                {/* RELACIÓN CON CLIENTES Y CONSUMIDORES */}
                <Typography variant='h5' sx={{ textTransform: 'uppercase' }}>RELACIÓN CON CLIENTES Y CONSUMIDORES</Typography>
                <Typography variant='body1' paragraph>Nuestros clientes son muy importantes para nosotros. Se consideran nuestros clientes
                    tanto el consumidor final como nuestros distribuidores y franquiciatarios. Debemos
                    mantener una relación de respeto, brindándoles la confianza y apoyo que necesitan.</Typography>
                {/* RELACIÓN CON PROVEEDORES */}
                <Typography variant='h5' sx={{ textTransform: 'uppercase' }}>RELACIÓN CON PROVEEDORES</Typography>
                <Typography variant='body1' paragraph>Nuestros proveedores también constituyen una parte fundamental para el buen desempeño
                    de Nefrópolis®, es por ello que debemos mantener una relación cordial
                    y de respeto.</Typography>
                {/* POLÍTICA DE REGALOS */}
                <Typography variant='h5' sx={{ textTransform: 'uppercase' }}>POLÍTICA DE REGALOS</Typography>
                <Typography variant='body1'><b>Regalos de proveedores o subcontratistas.</b></Typography>
                <ul>
                    <li>Queda prohibido recibir dinero como regalo de proveedores o subcontratistas.</li>
                    <li>Solo se pueden aceptar regalos de poco valor de una empresa, como un artículo promocional, comida ocasional, invitación a acontecimiento social, la participación a un evento patrocinado o algún detalle de fin de año.</li>
                    <li>La aceptación de regalos o detalles no deberá influir en la selección de proveedores y decisión de compra o tomarse como acto de soborno o corrupción para favoritismos.</li>
                </ul>
                <Typography variant='body1'><b>Regalos de clientes, distribuidores o franquiciatarios.</b></Typography>
                <ul>
                    <li>Queda prohibido recibir “dinero” como regalo de clientes, distribuidores o franquiciatarios.</li>
                    <li>Solo se pueden aceptar regalos de poco valor, que sean simbólicos, como productos típicos o característicos del lugar dónde vienen, productos que ellos mismos elaboran, o pequeños detalles que no induzcan a un favoritismo o acto de soborno o corrupción.</li>
                    <li>La aceptación de regalos o detalles no deberá influir en actos de preferencia o tomarse como acto de soborno o corrupción.</li>
                </ul>
                {/* RELACIÓN CON LA COMPETENCIA */}
                <Typography variant='h5' sx={{ textTransform: 'uppercase' }}>RELACIÓN CON LA COMPETENCIA</Typography>
                <Typography variant='body1'>En la relación con la competencia debe existir un ambiente de respeto y cordialidad.
                    Pueden realizarse actividades como Benchmarking, sin embargo, queda prohíbido todo
                    tipo de competencia desleal como robo de clientes, hablar mal de la competencia
                    afectando su integridad.</Typography>
                <Typography variant='body1'>Se deben evitar prácticas de competencia desleal como:</Typography>
                <ul>
                    <li>Difamar o mentir acerca de los negocios, productos o servicios de un competidor.</li>
                    <li>Inducir a un cliente a no cumplir un contrato con un competidor.</li>
                    <li>Desvirtuar intencionalmente u ocultar información acerca de productos y servicios de la empresa, o hacer declaraciones o afirmaciones erróneas, engañosas o falsas acerca ellos sin ninguna base fáctica.</li>
                    <li>Espionaje industrial o aceptar o utilizar inapropiadamente un secreto comercial de los competidores.</li>
                    <li>Sobornar a agentes y empleados del cliente.</li>
                </ul>
                <Typography variant='body1' paragraph>Nefrópolis® debe fomentar la libre competencia, siempre y cuando se realice limpiamente.</Typography>
                {/* RELACIÓN ENTRE COLABORADORES */}
                <Typography variant='h5' sx={{ textTransform: 'uppercase' }}>RELACIÓN ENTRE COLABORADORES</Typography>
                <Typography variant='body1'>La relación entre colaboradores debe ser de respeto, confianza y de trabajo en equipo. Fomentando la existencia de un buen ambiente laboral.
                    Quedan prohibídos los chismes y acciones que puedan afectar la integridad de las personas del equipo de Nefrópolis®.</Typography>
                <Typography variant='body1'><b>Política anti-hostigamiento</b></Typography>
                <Typography variant='body1'>En Nefrópolis®, se prohíbe cualquier tipo de acoso u hostigamiento que
                    atente contra la integridad del colaborador, proveedor o cliente. Entendiendose como
                    acoso y hostigamiento, los siguientes términos.</Typography>
                <ul>
                    <li><b>Acoso laboral:</b> Conocido también como mobbing. Se refiere al trato hostil o vejatorio al que es sometida una persona en el ámbito laboral de forma sistemática, que le provoca problemas psicológicos y profesionales.</li>
                    <li><b>Acoso sexual:</b> Acción de asedio, acoso, ya sea expresado de forma verbal o física con términos, conceptos, señas, imágenes que tengan una connotación sexual, lasciva o de exhibicionismo corporal, o se aproveche de cualquier circunstancia de necesidad o de desventaja de la víctima, a una o más personas de cualquier sexo, sin que la víctima haya otorgado su expreso consentimiento que tenga el propósito, o produzca el efecto de atentar contra la dignidad de la persona, en particular cuando se crea un entorno intimidatorio, degradante u ofensivo.</li>
                    <li><b>Hostigamiento sexual:</b> Acción de asedio a otra persona solicitándole ejecutar cualquier acto de naturaleza sexual, valiéndose de su posición jerárquica o de poder, derivada de sus relaciones laborales, profesionales o de subordinación.</li>
                </ul>
                <Typography variant='body1'><b>Política antidiscriminación e inequidad</b></Typography>
                <Typography variant='body1'>Nefrópolis® prohíbe cualquier tipo de comportamiento discriminatorio
                    o que incite al mismo. Promoviendo las siguientes acciones:</Typography>
                <ul>
                    <li>Prevención y eliminación de todo acto de discriminación o actos que atenten contra la dignidad de las personas.</li>
                    <li>Rechazo a todo tipo de violencia.</li>
                    <li>Respeto por la diversidad y la individualidad.</li>
                    <li>Respeto de gustos, preferencias, creencias e ideologías. Fomento de una convivencia respetuosa e incluyente. Igualdad de oportunidades.</li>
                    <li>Equidad de genero y equidad laboral.</li>
                </ul>
                <Typography variant='body1'>Además, las contrataciones, promociones o condiciones laborales serán en base a capacidades y
                    competencias, sin importar género, raza, color, religión, edad, estado civil, origen o condición.</Typography>
                <Typography variant='body1'><b>Conflicto de interés</b></Typography>
                <Typography variant='body1'>Un conflicto de interés se genera cuando los intereses personales de un colaborador compiten
                    con los intereses de la empresa y en dichas situaciones el colaborador podría no actuar en
                    favor de la compañía.</Typography>
                <Typography variant='body1' paragraph>Deberán evitarse las situaciones que puedan generar conflicto de interés. En caso de encontrarse
                    en uno, se deberá comunicar al comité de ética para resolver la situación de forma justa y transparente.</Typography>
                {/* RESPETO POR LAS LEYES Y EL MEDIO AMBIENTE */}
                <Typography variant='h5' sx={{ textTransform: 'uppercase' }}>RESPETO POR LAS LEYES Y EL MEDIO AMBIENTE</Typography>
                <Typography variant='body1'><b>Política anticorrupción</b></Typography>
                <Typography variant='body1'>Queda prohíbido cualquier acto de corrupción, soborno o extorsión.</Typography>
                <ul>
                    <li><b>Corrupción.</b> Consiste en el abuso del poder para beneficio propio. Puede clasificarse en corrupción a gran escala, menor y política, según la cantidad de fondos perdidos y el sector en el que se produzca.</li>
                    <li><b>Soborno.</b> Corromper a alguien con dinero, regalos o algún favor para obtener algo de esta persona.</li>
                    <li><b>Extorsión.</b> Amenazas, intimidaciones o agresiones con la finalidad de doblegar la voluntad o el deseo de la víctima. Se trata de un delito y, como tal, se encuentra penado por la ley.</li>
                </ul>
                <Typography variant='body1'>Nefrópolis® se compromete a trabajar con transparencias con sus socios,
                    inversionistas, franquiciatarios, proveedores, clientes y colaboradores.</Typography>
                <Typography variant='body1'><b>Política cuidado del Medio Ambiente</b></Typography>
                <Typography variant='body1'>Nefrópolis® se preocupa por el cuidado del medio ambiente,
                    es por ello que se establecen las siguientes políticas, las cuales deben ser
                    cumplidas por todos sus colaboradores.</Typography>
                <ul>
                    <li>Manten apagados y desconectados los aparatos electrónicos cuando no se estén utilizando.</li>
                    <li>Procura apagar las luces al salir de una oficina.</li>
                    <li>Si observas alguna fuga de agua o gotera, repórtala de inmediato al área de mantenimiento.</li>
                    <li>Respeta la separación de la basura.</li>
                    <li>No tires las hojas que ya no te sirvan, utiliza la parte posterior de las mismas para tus notas y finalmente entrégalas al área de Responsabilidad social para su reciclaje.</li>
                    <li>Recuerda, con pequeños cambios, hacemos la diferencia.</li>
                </ul>
            </Container>
        </>
    )
}

export default CodigoEtica