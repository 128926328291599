import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import VariantBatch from './VariantBatch';

import { basicMsg, msg } from '../../../../../actions/swal_msg';
import { variantCloseModal, variantUpdate } from '../../../../../actions/product';

import { regex_dec, regex_num } from '../../../../../constants/regex';
import { GOOGLE_DRIVE_CDN } from '../../../../../constants/constants';

import {
    InputLabel, MenuItem, FormControl, Select,
    Typography, ImageList, ImageListItem, ImageListItemBar,
    IconButton, Grid, OutlinedInput, InputAdornment, FormGroup,
    Divider, Switch, FormControlLabel, Container, Accordion,
    AccordionSummary, AccordionDetails, TextField, Box, Button,
    Dialog, AppBar, Toolbar, 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function VariantModal() {
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width:600px)');

    const { var_active, var_modal } = useSelector(state => state.product);
    // valor de producto
    const [values, setValues] = useState(var_active);
    const {
        variant_title, price, description,
        stock, iva, sat_key, claveUnidad, unidad,
        barcode, odoo_id, available, images, batches
    } = values;
    // imágenes
    const [newImages, setNewImages] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);
    // lotes de variante
    const [_batches, setBatches] = useState([]);

    const label = { inputProps: { 'aria-label': 'Variant switch' } };

    useEffect(() => {
        const active = {
            ...var_active,
            price: String(var_active.price),
            stock: String(var_active.stock)
        }
        setValues(active);
        if (var_active._id === '') { // reset
            setNewImages([]);
            setDeletedImages([]);
        }
    }, [var_active]);

    useEffect(() => {
        if (!var_modal) {
            setNewImages([]);
            setDeletedImages([]);
            setBatches([]);
        }
    }, [var_modal]);

    // cambios en campos
    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (['iva', 'stock', 'sat_key', 'odoo_id'].includes(name)) {
            if (value === '' || regex_num.test(value))
                setValues(e => ({ ...e, [name]: value }));
        } else if (name === 'price') {
            if (value === '' || regex_dec.test(value))
                setValues(e => ({ ...e, [name]: value }));
        } else setValues(e => ({ ...e, [name]: value }));
    };

    // cambios en switch
    const handleSwitchChange = ({ target }) => {
        setValues(e => ({ ...e, [target.name]: target.checked }));
    };

    // subir imágenes desde ordenador
    const loadedImages = ({ target }) => {
        let count = images.length + newImages.length; // total de imágenes
        let exceeded = false; // se alcanzó el límite?
        if (count >= 6) {
            basicMsg('No puede agregar más imágenes en esta variante');
            return;
        }
        if (target.files.length > 0) {
            const target_files = [];
            [...target.files].forEach(element => {
                if (count >= 6) exceeded = true;
                else {
                    if (['image/jpeg', 'image/png', 'image/webp'].includes(element.type)) {
                        target_files.push({ img: element, alt: '' });
                        count++;
                    }
                }
            });
            setNewImages(e => ([...e, ...target_files]));
            if (exceeded) basicMsg('Se alcanzó el límite de imágenes');
        }
    };

    // cerrar modal
    const handleClose = () => {
        dispatch(variantCloseModal());
    };

    const update = () => {
        if (formValidator())
            dispatch(variantUpdate(values, newImages, deletedImages, _batches));
    };

    // validación básica de formulario
    const formValidator = () => {
        const count = images.length + newImages.length;
        const price_len = price.trim().length;
        const desc_len = description.trim().length;
        const satk_len = sat_key.trim().length;
        const claveUd_len = claveUnidad.trim().length;
        const unidad_len = unidad.trim().length;
        const barcode_len = stock.trim().length;
        const stock_len = stock.trim().length;
        if (price_len > 1 && price_len <= 8
            && desc_len > 2 && desc_len <= 2000
            && satk_len === 8 && regex_num.test(sat_key)
            && (iva === 0 || iva === 16)
            && claveUd_len === 3
            && unidad_len > 1 && unidad_len <= 64
            && barcode_len > 0 && barcode_len <= 32
            && stock_len > 0 && stock_len <= 5
            && count > 0)
            return true;
        else msg('warning', 'Variante',
            'Complete todos los datos de la variante');
        return false;
    };

    // reordenar imágenes GUARDADAS
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setValues(e => ({
            ...e,
            images: items
        }));
    };

    // reordenar imágenes SUBIDAS
    const handleOnDragEnd2 = (result) => {
        if (!result.destination) return;
        const items = Array.from(newImages);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setNewImages(items);
    };

    // editar propiedad ALT de imágenes GUARDADAS
    const handleAltChange = id => ({ target }) => {
        const alt = target.value;
        setValues(e => ({
            ...e,
            images: e.images.map(u => (u.id === id) ? {
                ...u,
                alt
            } : u)
        }));
    };

    // editar propiedad ALT de imágenes SUBIDAS
    const handleAltChange2 = name => ({ target }) => {
        const alt = target.value;
        setNewImages(e => e.map(u => (u.img.name === name) ? {
            ...u,
            alt
        } : u));
    };

    // remueve imágenes de producto GUARDADAS
    const removeImg = id => () => {
        const count = images.length + newImages.length;
        if (count > 1) {
            setDeletedImages(e => [...e, id]);
            setValues(e => ({
                ...e,
                images: e.images.filter(i => (i.id !== id))
            }));
        } else basicMsg('Variante debe contener al menos una imagen');
    };

    // remueve imágenes de producto SUBIDAS
    const removeImg2 = name => () => {
        setNewImages(e => e.filter(u => (u.img.name !== name)));
    };
    
    return (
        <Dialog
            fullScreen
            open={ var_modal }
            onClose={ handleClose }
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{ zIndex: 1000 }}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={ handleClose }
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Editar variante
                    </Typography>
                    <Button variant="outlined" color="inherit" onClick={ update }>
                        Actualizar
                    </Button>
                </Toolbar>
            </AppBar>
            <Container maxWidth='xl' sx={{ my: 5 }}>
                <DragDropContext onDragEnd={ handleOnDragEnd }>
                    <Droppable droppableId="images">
                        {(provided) => (
                            <ImageList cols={matches ? 2 : 4}
                                { ...provided.droppableProps }
                                ref={ provided.innerRef }>
                                {images.map((item, index) => {
                                    return (
                                        <Draggable key={item.id} draggableId={item.id}
                                            index={index}>
                                            {(prov) => (
                                                <ImageListItem
                                                    cols={1} rows={1}
                                                    ref={ prov.innerRef }
                                                    { ...prov.draggableProps }
                                                    { ...prov.dragHandleProps }>
                                                    <img
                                                        src={`${GOOGLE_DRIVE_CDN}${item.id}`}
                                                        alt={item.alt}
                                                        loading="lazy" />
                                                    <ImageListItemBar
                                                        title={
                                                            <TextField label="Atributo ALT"
                                                                variant="standard" size="small"
                                                                value={item.alt}
                                                                onChange={ handleAltChange(item.id) } />
                                                        }
                                                        actionIcon={
                                                            <IconButton
                                                                sx={{ color: 'rgba(0, 0, 0, 0.5)' }}
                                                                aria-label={`imagen-variante-${item.alt}`}
                                                                onClick={ removeImg(item.id) } >
                                                                <RemoveCircleIcon />
                                                            </IconButton>
                                                        }
                                                        position="below" />

                                                </ImageListItem>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </ImageList>
                        )}
                    </Droppable>
                </DragDropContext>

                <Divider sx={{ fontSize: 16, my: 3 }} />

                <DragDropContext onDragEnd={handleOnDragEnd2}>
                    <Droppable droppableId="new_images">
                        {(provided) => (
                            <ImageList cols={matches ? 2 : 4}
                                {...provided.droppableProps} ref={provided.innerRef}>
                                {newImages.map((item, index) => {
                                    return (
                                        <Draggable key={item.img.name} draggableId={item.img.name}
                                            index={index}>
                                            {(prov) => (
                                                <ImageListItem cols={1} rows={1}
                                                    ref={prov.innerRef} {...prov.draggableProps}
                                                    {...prov.dragHandleProps}>
                                                    <img
                                                        src={URL.createObjectURL(item.img)}
                                                        alt={item.img.name}
                                                        loading="lazy" />
                                                    <ImageListItemBar
                                                        title={
                                                            <TextField label="Atributo ALT"
                                                                variant="standard" size="small"
                                                                onChange={handleAltChange2(item.img.name)} />
                                                        }
                                                        actionIcon={
                                                            <IconButton
                                                                sx={{ color: 'rgba(0, 0, 0, 0.5)' }}
                                                                aria-label={`imagen #${index} de variante`}
                                                                onClick={removeImg2(item.img.name)} >
                                                                <RemoveCircleIcon />
                                                            </IconButton>
                                                        }
                                                        position="below" />
                                                </ImageListItem>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </ImageList>
                        )}
                    </Droppable>
                </DragDropContext>

                <Box textAlign='center' sx={{ mb: 2 }}>
                    <Button
                        variant="contained"
                        component="label">
                        Subir archivo
                        <input hidden accept="image/*"
                            multiple type="file" onChange={loadedImages} />
                    </Button>
                </Box>

                <TextField sx={{ mb: 2 }}
                    fullWidth
                    name="variant_title"
                    label="Título de variante"
                    variant="outlined"
                    value={variant_title}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleInputChange} />

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="variant-input-price">Precio</InputLabel>
                            <OutlinedInput
                                id="variant-input-price"
                                name="price"
                                label="Precio"
                                value={price}
                                inputProps={{ maxLength: 8 }}
                                onChange={handleInputChange}
                                startAdornment={
                                    <InputAdornment position="start">$</InputAdornment>
                                } />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField sx={{ mb: 2 }}
                            fullWidth
                            name="barcode"
                            label="Código de barras"
                            variant="outlined"
                            value={barcode}
                            inputProps={{ maxLength: 32 }}
                            onChange={handleInputChange} />
                    </Grid>
                </Grid>

                <TextField sx={{ mb: 2 }}
                    fullWidth
                    name="description"
                    label="Descripción"
                    multiline
                    variant="outlined"
                    rows={4}
                    value={description}
                    inputProps={{ maxLength: 2000 }}
                    onChange={handleInputChange} />

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField fullWidth
                            name="sat_key"
                            label="Clave SAT"
                            variant="outlined"
                            value={sat_key}
                            inputProps={{ maxLength: 8 }}
                            onChange={handleInputChange} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="iva-variant-label">IVA</InputLabel>
                            <Select
                                labelId="iva-variant-label"
                                name="iva"
                                value={iva}
                                label="IVA"
                                onChange={handleInputChange}>
                                <MenuItem value={0}>0%</MenuItem>
                                <MenuItem value={16}>16%</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField fullWidth
                            name="claveUnidad"
                            label="Clave unidad"
                            variant="outlined"
                            value={ claveUnidad }
                            inputProps={{ maxLength: 3 }}
                            onChange={ handleInputChange } />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField fullWidth
                            name="unidad"
                            label="Unidad de medidad"
                            variant="outlined"
                            value={ unidad }
                            inputProps={{ maxLength: 64 }}
                            onChange={ handleInputChange } />
                    </Grid>
                </Grid>

                <TextField sx={{ mb: 2 }}
                    fullWidth
                    name="stock"
                    label="Cantidad disponible"
                    variant="outlined"
                    value={stock}
                    inputProps={{ maxLength: 5 }}
                    onChange={handleInputChange} />
                
                <TextField sx={{ mb: 2 }}
                    fullWidth
                    name="odoo_id"
                    label="ID de producto en Odoo"
                    variant="outlined"
                    value={ odoo_id }
                    inputProps={{ maxLength: 32 }}
                    onChange={ handleInputChange } />

                <FormGroup>
                    <FormControlLabel control={
                        <Switch {...label}
                            name="available"
                            checked={available}
                            onChange={handleSwitchChange} />
                    }
                        label="Mostrar en tienda" />
                </FormGroup>

                {
                    batches.length > 0 &&
                        <>
                            <Typography variant="h6" gutterBottom>
                                Lotes actuales
                            </Typography>
                            {
                                batches.map((e, i) =>
                                    <Accordion key={i}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}>
                                            <Typography>{ `Lote: ${e.batch}` }</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                { `Piezas de lote: ${e.total_pieces}` }
                                            </Typography>
                                            <Typography>
                                                { `Fecha de caducidad: ${format(new Date(e.expiration_date), 'dd/MM/yyyy')}` }
                                            </Typography>
                                            {/* <Button variant="outlined"
                                                onClick={() => {
                                                    
                                                }}>
                                                Eliminar
                                            </Button> */}
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }
                        </>
                }
                
                <VariantBatch
                    batches={_batches}
                    setBatches={setBatches} />
            </Container>
        </Dialog>
    );
};