import React, { useState } from 'react'

//MUI
import { SpeedDial, SpeedDialAction, useMediaQuery } from '@mui/material';
// import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import CloseIcon from '@mui/icons-material/Close';
// import InfoIcon from '@mui/icons-material/Info';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const SpeedDialComponent = () => {

    const matches = useMediaQuery('(max-width:425.9px)')

    const [speedDial, setSpeedDial] = useState(true)
    const handleSpeedDial = () =>{
        setSpeedDial(!speedDial)
    }

    return (
        <div className="speed__dial">
            <SpeedDial
                direction="up"
                ariaLabel="Contacto"
                open={speedDial}
                onClick={handleSpeedDial}
                sx={matches
                    ?
                    { position: 'fixed', bottom: 80, right: 16 }
                    :
                    { position: 'fixed', bottom: 30, left: 16 }
                }
                FabProps={{
                    sx: {
                        bgcolor: '#229954',
                        '&:hover': {
                            bgcolor: '#27AE60',
                        }
                    }
                }}
                icon={<SpeedDialIcon icon={<QuestionMarkIcon />} openIcon={<CloseIcon />} />}
            >
                <SpeedDialAction
                    FabProps={{ style: { backgroundColor: "#3FDC5B", color: 'white' } }}
                    icon={<WhatsAppIcon />}
                    tooltipTitle="WhatsApp"
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = 'https://api.whatsapp.com/send?phone=523324966190&text=Hola,+quisiera+m%C3%A1s+informaci%C3%B3n+sobre+(escriba+el+tema+sobre+el+que+quiera+saber+m%C3%A1s)';
                    }}
                />
            </SpeedDial>
        </div>
    )
}

export default SpeedDialComponent