import React, { useEffect, useState, useContext } from "react";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterDomLink, useNavigate } from 'react-router-dom';

import ShippingMethods from "./ShippingMethods";
import CartPromotionDialog from "./CartPromotionDialog";

import { NavContext } from "../../../context/NavContext";
import { msg, basicMsg, valMsg, basicTimerMsg } from "../../../actions/swal_msg";

import {
    shopCoupons, getCartItems, navigateToProductPage,
    removeItemFromCart, updateItemInCart,
    updateQuantity
} from "../../../actions/shop";

import { minAmount } from "../../../constants/shop/shop_constants";
import { is_visible, no_visible } from "../../../constants/constants";

import img from '../../../assets/resources/Carrito_compras_nefrópolis.png';
import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';

//Swiper
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from "swiper";
// import 'swiper/css';
// import "swiper/css/navigation";

//CSS
import './cart.css';
// import './OpenPayForm.css';

//MUI
import {
    FormControl, TextField, List, ListItem, ListItemText,
    Typography, Container, Button, Grid, Box, Divider, Collapse,
    Chip, Link, Alert, Breadcrumbs, Stack, useMediaQuery
    // CircularProgress
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// aceptar sólo números
const regex = /^\d+$/;

const CartComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setValue } = useContext(NavContext);

    const matches = useMediaQuery('(max-width: 950px)');

    const {
        navigation, // url de navegación
        coupons, // cupones disponibles
        cart: {
            item, // productos de carrito
            summary: {
                promos_discount, discount,
                subtotal, total
            },
            item_summary, // Tu orden
            cart_promotion, // promociones disponibles
            coupon, // cupón aplicado
        },
        shipping: {
            methods: { selected }
        }
    } = useSelector(state => state.shop);

    const { loading1 } = useSelector(state => state.ui);
    
    const { nav_url } = navigation;
    
    // usuario iniciado
    const { loggedUser } = useSelector(state => state.user);
    const { email } = loggedUser;

    // campo de cupón
    const [couponInput, setCouponInput] = useState('');

    useEffect(() => {
        // cargar lista de cupones
        dispatch(shopCoupons());
    }, []);

    useEffect(() => {
        // redireccionar y mostrar información de variante
        if (nav_url !== '')
            navigate(`/tienda/producto/${nav_url}`);
    }, [navigation]);

    // useEffect(() => {
        
    // }, [products]);

    const handleCouponChange = ({ target }) => {
        setCouponInput(target.value.toUpperCase());
    };

    // aplicar cupón de descuento
    const applyCoupon = () => {
        let found = false;
        coupons.forEach(e => {
            if (e.name.toUpperCase() === couponInput.trim().toUpperCase()) {
                found = true;
                if (e.discount > subtotal)
                    msg('warning', 'Cupón',
                    'No puede aplicar este cupón porque supera el total de venta');
                else {
                    localStorage.setItem('coupon', e.name);
                    dispatch(getCartItems(false));
                }
            }
        });
        if (!found)
            msg('warning', 'Cupón',
            'No se ha encontrado el cupón');
    };

    // eliminar producto de carrito
    const remove = _prod_var_id => () => {
        Swal.fire({
            title: '¿Eliminar el producto de su carrito?',
            showDenyButton: true,
            confirmButtonText: 'Sí, eliminar',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeItemFromCart(_prod_var_id));
            }
        });
    };

    // cantidad de producto en carrito
    const handleChange = _prod_var_id => ({ target }) => {
        const abc = target.value;
        const quantity = Number(abc);
        if (abc === '')
            dispatch(updateQuantity({
                prod_var_id: _prod_var_id,
                quantity: abc
            }));
        else if (quantity === 0)
            dispatch(updateQuantity({
                prod_var_id: _prod_var_id,
                quantity
            }));
        else if (quantity > 0 && regex.test(quantity))
            dispatch(updateItemInCart(_prod_var_id, quantity));
    };

    // dirigirse a página de variante
    const goToProductPage = (nav_url, nav_id) => () => {
        dispatch(navigateToProductPage({ nav_url, nav_id }));
    };

    // dirigirse a checkout
    const goToCheckout = () => {
        if (loading1)
            basicTimerMsg('Cargando su carrito...');
        // se ha seleccionado método de pago
        else if (selected._id !== '')
            nextPage();
        else
            basicMsg('Seleccione método de envío');
    };

    // navegación
    const nextPage = () => {
        // productos sin cantidad válida
        const validation = item.find(e => (['', '0', 0].includes(e.quantity)));
        if (validation)
            valMsg('info', 'Carrito',
            'Ingrese cantidad a los productos o elimínelos');
        else {
            if (email !== '')
                navigate('/checkout');
            else navigate('/login-l');
        }
    };
    
    //Collapse para cupón
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    // eliminar cupón aplicado
    const removeOnClick = () => {
        localStorage.removeItem('coupon');
        dispatch(getCartItems(false));
    };
    
    const restante = minAmount - subtotal;
    
    return (
        <>
            {
                item.length > 0
                    ?
                    <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }} className="last-view">
                        {/* Cart header */}
                        <Typography variant="h4" sx={{ fontWeight: 'light' }} gutterBottom>Carrito de compras</Typography>
                        <Breadcrumbs aria-label="breadcrumb">
                            <RouterDomLink to='/' style={{ textDecoration: 'none' }}>
                                Inicio
                            </RouterDomLink>
                            <RouterDomLink to='/tienda' style={{ textDecoration: 'none' }}>
                                Tienda
                            </RouterDomLink>
                            <Typography color="text.primary">Carrito de compras</Typography>
                        </Breadcrumbs>

                        <CartPromotionDialog
                            cart_promotion={ cart_promotion }
                            goToProductPage={ goToProductPage }
                        />

                        {/* Row */}
                        <Grid container spacing={4} sx={{ mt: 1 }}>
                            {/* Productos en carrito */}
                            <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                                {item.map(e => (
                                    <Box sx={{ p: 3 }} className="cart-box-shadow" key={e.prod_var_id}>
                                        <Grid container spacing={2}>
                                            {/* Imagen del producto */}
                                            <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
                                                <Box sx={{ textAlign: 'center' }} className="animate__animated animate__fadeInLeft">
                                                    {
                                                        e.images.length > 0 ?
                                                            <img src={`https://lh3.googleusercontent.com/d/${e.images[0].id}`}
                                                                className="cart-img" alt={e.images[0].alt} width='150' height="150"
                                                                style={{ objectFit: 'contain' }} />
                                                        :
                                                            <img src={ no_image } className="cart-img" alt="producto-nefropolis" width='100%' />
                                                    }
                                                </Box>
                                            </Grid>
                                            {/* Información del producto */}
                                            <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
                                                <Box sx={{ textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'left' } }}>
                                                    <div>
                                                        {/* Nombre del producto */}
                                                        <Link
                                                            component="button"
                                                            variant="body1"
                                                            sx={{ color: '#000', mb: 1, textDecoration: 'none', fontWeight: '600' }}
                                                            onClick={ goToProductPage(e.url, e.prod_var_id) }>
                                                            {
                                                                e.variant_title ? `${e.title} - ${e.variant_title}` : e.title
                                                            }
                                                        </Link>
                                                        {/* Categorías */}
                                                        <Box>
                                                            <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
                                                                Categorías:
                                                                {
                                                                    e.category.map((c, i) =>
                                                                        <RouterDomLink key={i}
                                                                            to={
                                                                                `/tienda/categoria/${
                                                                                    c.replace(/\s+/g, '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
                                                                                }`
                                                                            }
                                                                            style={{ marginLeft: '3px' }}>{
                                                                                i === e.category.length - 1 ?
                                                                                c : `${c},`
                                                                            }
                                                                        </RouterDomLink>
                                                                    )
                                                                }
                                                            </Typography>
                                                        </Box>

                                                        <FormControl sx={{ width: { xs: '100%', md: 'auto' }, mt: 2 }}>
                                                            <TextField
                                                                type="number"
                                                                label="Cantidad"
                                                                variant="outlined"
                                                                value={ e.quantity }
                                                                size="small"
                                                                InputProps={{ inputProps: { min: 1 } }}
                                                                onChange={ handleChange(e.prod_var_id) }
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', mt: 2 }}>
                                                    <Box sx={{ my: 'auto' }}>
                                                        {/* Boton para eliminar elemento del carrito */}
                                                        <Button
                                                            color='error'
                                                            onClick={ remove(e.prod_var_id) }
                                                            startIcon={<DeleteIcon />}>
                                                            Eliminar del carrito
                                                        </Button>
                                                    </Box>
                                                    <Box sx={{ my: 'auto' }}>
                                                        <Typography variant='body1' sx={{ color: 'green', fontWeight: '600' }}>
                                                            ${ e.price }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))
                                }
                            </Grid>
                            {/* Fin productos en carrito */}

                            {/* Total */}
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <Box sx={{ p: 3 }} className="total-cart-box-shadow"
                                    style={ item_summary.length > 0 ? is_visible : no_visible }>
                                    <Typography variant='h5' sx={{ fontWeight: '600', textAlign: { xs: 'center', sm: 'center', md: 'left' } }}>
                                        Tu orden
                                    </Typography>
                                    <List disablePadding>
                                        {
                                            item_summary.map((e, i) => {
                                                return (
                                                    <ListItem sx={{ px: 0, pr: 1 }} key={i}>
                                                        <ListItemText
                                                            primary={
                                                                `${e.title} ${e.variant_title} (${e.quantity})`
                                                            }
                                                            secondary={
                                                                <Typography
                                                                    sx={{ display: 'inline' }}
                                                                    component="span"
                                                                    variant="body2">
                                                                    { `${ e.promo_text } ` }
                                                                </Typography>
                                                            } />
                                                        {
                                                            (
                                                                e.discount_by_promo !== 0 ||
                                                                e.discount_by_coupon !== 0
                                                            ) ?
                                                                <Stack spacing={1}>
                                                                    <Typography sx={{ color: '#000', fontWeight: '600', textDecoration: 'line-through' }}
                                                                        variant="body2">
                                                                        ${ e.item_subtotal }
                                                                    </Typography>
                                                                    <Typography sx={{ color: '#229954', fontWeight: '600' }} variant="body2">
                                                                        ${ e.item_total }
                                                                    </Typography>
                                                                </Stack>
                                                            :
                                                                <Typography sx={{ color: '#229954', fontWeight: '600' }} variant="body2">
                                                                    ${ e.item_subtotal }
                                                                </Typography>
                                                        }
                                                    </ListItem>
                                                )
                                            })
                                        }

                                        <Divider sx={{ my: 2 }} />

                                        {
                                            (coupon && coupon.name) && (
                                                <ListItem sx={{ px: 0 }}>
                                                    <ListItemText
                                                        primary={ coupon.name }
                                                        secondary={
                                                            coupon._class === 'order_discount' ?
                                                                `Cupón ${
                                                                    coupon.discount.type === 'fixed' ?
                                                                        `$${coupon.discount._value}`
                                                                    :
                                                                        `${coupon.discount._value}%`
                                                                }`
                                                            :
                                                                `Cupón Envío Gratis`
                                                        }
                                                    />
                                                    <Typography sx={{ color: '#C0392B', fontWeight: '600' }}
                                                        variant="body2">
                                                        {
                                                            coupon._class === 'order_discount' ?
                                                                `- $${
                                                                    coupon.discount.type === 'fixed'
                                                                        ? coupon.discount._value
                                                                        : discount
                                                                }`
                                                            :
                                                                `¡Tiene envío gratis!`
                                                        }
                                                    </Typography>
                                                </ListItem>
                                            )
                                        }

                                        {
                                            promos_discount !== 0 &&
                                                <ListItem sx={{ px: 0 }}>
                                                    <ListItemText primary="Descuento por promociones" secondary={
                                                        `${ cart_promotion.filter(e => e.active).length } activa(s)`
                                                    } />
                                                    <Typography sx={{ color: '#C0392B', fontWeight: '600' }}
                                                        variant="body2">
                                                        - ${ promos_discount }
                                                    </Typography>
                                                </ListItem>
                                        }

                                        {
                                            (selected && selected._id) &&
                                                <ListItem sx={{ px: 0 }}>
                                                    <ListItemText primary="Envío" />
                                                    <Typography sx={{ color: '#229954', fontWeight: '600' }}
                                                        variant="body2">
                                                        { selected.cost === 0 ? 'Gratis': `$${selected.cost}` }
                                                    </Typography>
                                                </ListItem>
                                        }
                                        {
                                            (
                                                (subtotal < minAmount) &&
                                                (!coupon || (coupon && coupon._class !== 'free_shipping'))
                                            ) &&
                                                <Alert severity="info" sx={{ my: 1 }}>
                                                    {
                                                        `Agrega $${ (Math.round(restante * 1e2) / 1e2) } 
                                                        más al carrito de compras y el envío será ¡GRATIS!`
                                                    }
                                                </Alert>
                                        }
                                        
                                        {
                                            selected._id &&
                                                <Typography sx={{ fontStyle: 'italic', fontWeight: '600', mb: 1 }}
                                                    variant="body2">
                                                    Los tiempos estimados de entrega pueden variar acorde a la paquetería
                                                </Typography>
                                        }

                                        <ShippingMethods
                                            display={
                                                (subtotal < minAmount) &&
                                                (!coupon || (coupon && coupon._class !== 'free_shipping'))
                                                ? true : false
                                            }
                                        />

                                        <ListItem sx={{ px: 0 }}>
                                            <ListItemText primary="Subtotal" />
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                ${ subtotal }
                                            </Typography>
                                        </ListItem>

                                        <Divider sx={{ my: 2 }} />

                                        {
                                            selected._id &&
                                                <ListItem sx={{ px: 0 }}>
                                                    <ListItemText primary="Total + envío" />
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                        ${ total }
                                                    </Typography>
                                                </ListItem>
                                        }
                                    </List>

                                    {
                                        loading1 ?
                                            <LoadingButton
                                                sx={{ width: '100%', height: '50px' }}
                                                loading
                                                loadingPosition="start"
                                                startIcon={<SaveIcon />}
                                                variant="outlined">
                                                Continuar
                                            </LoadingButton>
                                        :
                                            <Button variant='contained' sx={{ width: '100%', height: '50px' }}
                                                onClick={ goToCheckout }>
                                                Continuar
                                            </Button>
                                    }

                                    {/* Sección para aplicar cupón */}
                                    <Box sx={{ mt: 2 }}>
                                        <Link onClick={handleClick} style={{
                                            display: 'flex', justifyContent: 'space-between',
                                            cursor: 'pointer', textDecoration: 'none'
                                        }}>
                                            Aplicar cupón
                                            <span>{ open ? <ExpandLess /> : <ExpandMore /> }</span>
                                        </Link>
                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                            <Box sx={{ display: 'flex', mt: 2 }}>
                                                <TextField label="Código" variant="outlined"
                                                    onChange={ handleCouponChange }
                                                    value={ couponInput }
                                                    size='small' fullWidth sx={{ mr: 1.5 }} />
                                                <Button variant='contained' size='small'
                                                    sx={{ textTransform: 'none' }} onClick={ applyCoupon }>
                                                    Aplicar
                                                </Button>
                                            </Box>
                                        </Collapse>
                                        <Box>
                                            {
                                                coupon.name !== '' && (
                                                    <>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                            mt: 2
                                                        }}>
                                                            <Typography variant='body1'
                                                                sx={{ my: 'auto', fontWeight: '600' }}>
                                                                Cupón aplicado:
                                                            </Typography>
                                                            {
                                                                coupon.use_method === 'by_code' ?
                                                                    <Chip
                                                                        label={ coupon.name }
                                                                        onDelete={ removeOnClick }
                                                                        sx={{ my: 'auto', ml: 1 }}
                                                                        color='primary'
                                                                        size="small"
                                                                    />
                                                                :
                                                                    <Chip
                                                                        label={ coupon.name }
                                                                        sx={{ my: 'auto', ml: 1 }}
                                                                        color='primary'
                                                                        size="small"
                                                                    />
                                                            }
                                                            
                                                        </Box>
                                                        <Typography variant='overline' sx={{ fontStyle: 'italic' }}>
                                                            { coupon.description }
                                                        </Typography>
                                                    </>
                                                )
                                            }
                                        </Box>
                                    </Box>
                                    {/* Fin seccion para aplicar cupón */}
                                </Box>
                            </Grid>
                            {/* Fin Total del carrito */}
                        </Grid>
                        {/* Fin row */}

                        {/* <Box sx={{ mt: 5 }}>
                            <Box sx={{ mb: 1.5, display: 'flex', justifyContent: 'space-between', gap: '10px', flexWrap: 'wrap' }}>
                                <Box sx={{ my: 'auto' }}>
                                    <Typography variant='h5' color='text.primary'>
                                        Los clientes que compraron esto también llevaron
                                    </Typography>
                                </Box>
                                <Stack direction='row' spacing={1.2} justifyContent="flex-end" sx={{ my: 'auto' }}>
                                    <IconButton className='crosell-swiper-button-prev' sx={{ bgcolor: '#F2F3F4', '&:hover': { bgcolor: '#E1E1E1' } }} size='small'>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                    <IconButton className='crosell-swiper-button-next' sx={{ bgcolor: '#F2F3F4', '&:hover': { bgcolor: '#E1E1E1' } }} size='small'>
                                        <ChevronRightIcon />
                                    </IconButton>
                                </Stack>
                            </Box>
                            <Swiper
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    400: {
                                        slidesPerView: 2,
                                    },
                                    600: {
                                        slidesPerView: 3,
                                    },
                                    700: {
                                        slidesPerView: 4,
                                    },
                                    1000: {
                                        slidesPerView: 4,
                                    },
                                }}
                                spaceBetween={20}
                                navigation={{
                                    nextEl: '.crosell-swiper-button-next',
                                    prevEl: '.crosell-swiper-button-prev',
                                }}
                                loop={true}
                                modules={[Navigation]}
                            >
                                {
                                    productos.map((item) => (
                                        <SwiperSlide key={item.id} style={{ textAlign: 'center' }}>
                                            <RouterDomLink to=''>
                                                <img src={item.img} alt={item.img} width='100%' />
                                            </RouterDomLink>
                                            <Typography variant='body1' gutterBottom>{item.name}</Typography>
                                            
                                            <Typography variant='body1' sx={{ color: '#F90606', fontWeight: '600' }} gutterBottom>
                                                ${ item.price }
                                            </Typography>
                                            <Button variant='contained' sx={{ textTransform: 'none' }}>Añadir al carrito</Button>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </Box> */}

                    </Container>
                :
                    <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18, textAlign: 'center' }} className="last-view">
                        <img src={img} width="35%" alt={img} className='abandoned_cart_img' />
                        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mt: 3 }}>
                            ¡Tu carrito está actualmente vacío!
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Antes de finalizar su compra debe añadir algunos productos al carrito de compras. <br />
                            Encontrará muchos productos interesantes en nuestra página de tienda.
                        </Typography>
                        <RouterDomLink to="/tienda" style={{ textDecoration: 'none' }}>
                            <Button variant="contained" sx={{ mt: 1 }} onClick={() => { setValue('/tienda') }}>
                                Regresar a la tienda
                            </Button>
                        </RouterDomLink>
                    </Container>
            }
        </>
    );
};

export default CartComponent;