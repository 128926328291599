import { types } from '../types/types';
import { msg as msgSwal } from './swal_msg';
import { request } from '../helpers/requestHelper';

import axios from 'axios';
import { GOOGLE_DRIVE_CDN } from '../constants/constants';

const txt1 = 'Quejas y sugerencias';
const txt2 = 'Postulación';
const txt3 = 'Distribuidor';
const nefro_url = 'https://nefropolis.com';
const email_nefro = 'no-reply@nefropolis.com';
const body_footer1 = `<p style="color: red; margin-top: 2rem; font-size: 14px">Para responder a este mensaje, es muy importante que seleccione el correo electrónico y escriba el mensaje (nueva conversación). Si responde a este correo, su respuesta no le llegará a la persona indicada `;
const body_footer2 = `, en cambio, le llegará al correo de Nefrópolis &lt;${email_nefro}&gt;</p>`;
const base_url = process.env.REACT_APP_NEFRO_API_URL;

// envío de formulario Quejas y Sugerencias
export const form1Submit = (model) => {
    return async(dispatch) => {
        try {
            dispatch(form1Backdrop(true));
            const { name, lastname, tel, email, message_type, occurrence_site, branch, msg, images } = model;
            const fullname = `${name} ${lastname}`;
            const form = new FormData();
            form.append('fullname', `${fullname}`);
            form.append('tel', tel);
            form.append('email', email);
            form.append('msg', msg);
            const title = bodyTitle(message_type);
            const msg1 = bodyMsg1(message_type, occurrence_site, branch, fullname);
            const msg_body = `
            <div>
                <div style="max-width: 600px; background-color: #ffffff; margin: auto; padding: 30px;">
                    <!-- logo -->
                    <div style="text-align: center;">
                        <img src="https://www.mousterstudio.com/Logo.png" width="240px" alt="Nefropolis">
                    </div>
                    <hr style="margin: 30px 0; background-color: #F2F3F4 !important;">
                    <!-- Datos usuario -->
                    <div>
                        ${title}${msg1}
                    </div>
                    <div style="margin-top: 30px;">
                        <p style="font-style: italic;">"${msg}".</p>
                        <h4><strong>Teléfono: </strong>${tel}</h4>
                        <h4><strong>Correo electrónico: </strong><a href="mailto:${email}">${email}</a></h4>
                    </div>
                    <hr style="margin: 30px 0; background-color: #F2F3F4 !important;">
                    <!-- mensaje -->
                    <div>
                        ${body_footer1}<i>(${fullname})</i>${body_footer2}
                    </div>
                    <p style="font-size: 12px; margin-bottom: 0; margin-top: 20px;">Este correo fue enviado desde <a href="${nefro_url}/contacto">Contacto
                            Nefrópolis</a></p>
                </div>
            </div>
            `;
            form.append('msg_body', msg_body);
            images.forEach(img => {
                form.append('file', img);
            });
            const url = `${base_url}/form/contact`;
            const axios_resp = await axios.post(url, form);
            if (axios_resp.data.status) {
                msgSwal('success', txt1, 'Formulario enviado');
                dispatch(form1Submitted(true));
            } else {
                dispatch(form1Backdrop(false));
                msgSwal('warning', txt1, 'No se pudo enviar el formulario');
            }
        } catch (err) {
            dispatch(form1Backdrop(false));
            msgSwal('warning', txt1, 'Ocurrió un incidente. No se envió el formulario');
        }
    }
};

export const sendFooterContact = (model) => {
    return async(dispatch) => {
        try {
            dispatch(form1Backdrop(true));
            const resp = await request('form/footer-contact', model, 'POST');
            const body = await resp.json();
            if (body.status) {
                msgSwal('success', 'Mensaje enviado',
                'Muchas gracias por sus comentarios');
                dispatch(form1Submitted(true));
            } else {
                msgSwal('warning', 'Contacto',
                'No hemos podido recibir sus comentarios');
                dispatch(form1Backdrop(false));
            }
        } catch (err) {
            dispatch(form1Backdrop(false));
            msgSwal('error', 'Contacto',
            'Ocurrió un incidente. No se enviaron sus comentarios');
        }
    }
};

const bodyTitle = (var1) => {
    const title = {
        msgType1: '<h3>Nueva queja en Nefrópolis.</h3><br>',
        msgType2: '<h3>Nueva sugerencia en Nefrópolis.</h3><br>',
        msgType3: '<h3>Nueva felicitación en Nefrópolis.</h3><br>',
        msgType4: '<h3>Nuevo contacto en Nefrópolis.</h3><br>'
    };
    return title[var1];
};

const bodyMsg1 = (var1, var2, var3, fullname) => {
    let msg = `<p><strong>${fullname}</strong> `;
    if (var1 === 'msgType1') {
        const site = {
            site1: `la sucursal <strong>${var3}</strong>`,
            site2: 'la Tienda en línea',
            site3: 'Atención a clientes'
        };
        msg += `presenta una queja sucedida en ${site[var2]}`;
    } else if (var1 === 'msgType2')
        msg += `presenta una sugerencia a Nefrópolis`;
    else if (var1 === 'msgType3')
        msg += `presenta una felicitación`;
    else
        msg += `se ha contactado desde el sitio web de Nefrópolis`;
    msg += '.</p>';
    return msg;
};

// backdrop en contacto
export const form1Backdrop = (status) => ({
    type: types.form1Backdrop,
    payload: status
});

// envío de formulario Postulación
export const form2Submit = (model) => {
    return async(dispatch) => {
        try {
            dispatch(form2Backdrop(true));
            const { fullname, email, tel, file } = model;
            const form = new FormData();
            form.append('fullname', `${fullname}`);
            form.append('email', email);
            form.append('tel', tel);
            form.append('file', file);
            const msg_body = `
            <div>
                <div style="max-width: 600px; background-color: #ffffff; margin: auto; padding: 30px;">
                    <!-- logo -->
                    <div style="text-align: center;">
                        <img src="${GOOGLE_DRIVE_CDN}1EkIJtp41pz5RMcLVCHEqDzqfIUCO2aO4" width="240px" alt="Nefropolis">
                    </div>
                    <hr style="margin: 30px 0; background-color: #F2F3F4 !important;">
                    <!-- Datos usuario -->
                    <div>
                        <h3 style="color: #000000; margin-bottom: 0;">
                            Nueva postulación en Nefrópolis de ${fullname}
                        </h3>
                    </div>
                    <div style="margin-top: 30px;">
                        <h4 style="margin-bottom: 0;"><strong>Teléfono:</strong> ${tel}</h4>
                        <h4 style="margin-bottom: 0;"><strong>Correo electrónico:</strong> <a
                                href="mailto:${email}">${email}</a></h4>
                    </div>
                    <hr style="margin: 30px 0; background-color: #F2F3F4 !important;">
                    <!-- mensaje -->
                    <div>
                        ${body_footer1}<i>(${fullname})</i>${body_footer2}
                    </div>
                    <p style="font-size: 12px; margin-bottom: 0; margin-top: 20px;">Este correo fue enviado desde <a
                        href="${nefro_url}/nuestro-equipo">Nuestro
                        equipo Nefrópolis</a></p>
                </div>
            </div>
            `;
            form.append('msg_body', msg_body);
            const url = `${base_url}/form/postulation`;
            const axios_resp = await axios.post(url, form);
            if (axios_resp.data.status) {
                msgSwal('success', txt2, 'Formulario enviado');
                dispatch(form2Submitted(true));
            } else {
                dispatch(form2Backdrop(false));
                msgSwal('warning', txt2, 'No se pudo enviar el formulario');
            }
        } catch (err) {
            dispatch(form2Backdrop(false));
            msgSwal('warning', txt2, 'Ocurrió un incidente. No se envió el formulario');
        }
    }
};

// backdrop en postulación
export const form2Backdrop = (status) => ({
    type: types.form2Backdrop,
    payload: status
});

// envío de formulario Distribuidores
export const form3Submit = (model) => {
    return async(dispatch) => {
        try {
            const email_body = `<div style="border: 1px solid whitesmoke; padding: 1rem">
            <img src="https://www.mousterstudio.com/Logo.png" style="width: 20%; max-width: 50%; height: auto; padding: 1rem;">
            <h3>Quiero ser distribuidor de Nefrópolis.</h3><br>
            <p style="font-style: italic">"${model.msg}".</p>
            <h4>Datos de contacto:</h4>
            <h4><strong>Nombre: </strong>${model.fullname}</h4>
            <h4><strong>Teléfono: </strong>${model.tel}</h4>
            <h4><strong>Correo electrónico: </strong><a href="mailto:${model.email}">${model.email}</a></h4>
            <h4><strong>Localidad: </strong>${model.city}, ${model.state}</h4>
            ${body_footer1}<i>(${model.fullname})</i>${body_footer2}
            <p style="font-size: 12px">Este correo fue enviado desde <a href="${nefro_url}/distribuidor-form">Distribuidor Nefrópolis</a></p></div>`;
            model.email_body = email_body;
            const url = `${base_url}/form/distributor`;
            const axios_resp = await axios.post(url, model);
            if (axios_resp.data.status) {
                msgSwal('success', txt3, 'Formulario enviado');
                dispatch(form3Submitted(true));
            } else {
                dispatch(form3Backdrop(false));
                msgSwal('warning', txt3, 'No se pudo enviar el formulario');
            }
        } catch (err) {
            dispatch(form3Backdrop(false));
            msgSwal('warning', txt3, 'Ocurrió un incidente. No se envió el formulario');
        }
    }
};

// backdrop en distribuidores
export const form3Backdrop = (status) => ({
    type: types.form3Backdrop,
    payload: status
});

// recuperar lista de formularios
export const myForms = () => {
    return async(dispatch) => {
        try {
            const resp = await request('form/list');
            const body = await resp.json();
            dispatch(formListLoaded(body.resp));
        } catch (err) {
            dispatch(formListLoaded([]));
            msgSwal('error', 'Formularios',
                'No se puede cargar la lista de formularios');
        }
    }
};

// lista recuperada
const formListLoaded = (obj) => ({
    type: types.formListLoaded,
    payload: obj
});

// registrar formulario 
export const formRegister = (model) => {
    return async(dispatch) => {
        try {
            const resp = await request('form/reg', model, 'POST');
            const body = await resp.json();
            if (body.status) {
                dispatch(formRegistered(body.resp));
                msgSwal('success', 'Formulario',
                    'Registrado con éxito');
            } else {
                msgSwal('warning', 'Formulario no registrado',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msgSwal('error', 'Formulario',
                'No se pudo registrar el formulario');
        }
    }
};

export const formRegistered  = (payload) => ({
    type: types.formRegistered,
    payload
});

// limpia formulario principal (registro de formularios)
export const formReset = () => ({
    type: types.formReset
});

// formulario Quejas y sugerencias enviado
export const form1Submitted = (payload) => ({
    type: types.form1Submitted,
    payload
});

// formulario Postulación enviado
export const form2Submitted = (payload) => ({
    type: types.form2Submitted,
    payload
});

// formulario Distribuidores enviado
export const form3Submitted = (payload) => ({
    type: types.form3Submitted,
    payload
});

// envío formulario de contacto
export const footerFormSubmit = (model) => {
    return async(dispatch) => {
        try {
            dispatch(form1Backdrop(true));
            const { name, lastname, tel, email, message_type, occurrence_site, branch, msg, images } = model;
            const fullname = `${name} ${lastname}`;
            const form = new FormData();
            form.append('fullname', `${fullname}`);
            form.append('tel', tel);
            form.append('email', email);
            form.append('msg', msg);
            const title = bodyTitle(message_type);
            const msg1 = bodyMsg1(message_type, occurrence_site, branch, fullname);
            const msg_body = `
            <div>
                <div style="max-width: 600px; background-color: #ffffff; margin: auto; padding: 30px;">
                    <!-- logo -->
                    <div style="text-align: center;">
                        <img src="https://www.mousterstudio.com/Logo.png" width="240px" alt="Nefropolis">
                    </div>
                    <hr style="margin: 30px 0; background-color: #F2F3F4 !important;">
                    <!-- Datos usuario -->
                    <div>
                        ${title}${msg1}
                    </div>
                    <div style="margin-top: 30px;">
                        <p style="font-style: italic;">"${msg}".</p>
                        <h4><strong>Teléfono: </strong>${tel}</h4>
                        <h4><strong>Correo electrónico: </strong><a href="mailto:${email}">${email}</a></h4>
                    </div>
                    <hr style="margin: 30px 0; background-color: #F2F3F4 !important;">
                    <!-- mensaje -->
                    <div>
                        ${body_footer1}<i>(${fullname})</i>${body_footer2}
                    </div>
                    <p style="font-size: 12px; margin-bottom: 0; margin-top: 20px;">Este correo fue enviado desde <a href="${nefro_url}/contacto">Contacto
                            Nefrópolis</a></p>
                </div>
            </div>
            `;
            form.append('msg_body', msg_body);
            images.forEach(img => {
                form.append('file', img);
            });
            const url = `${base_url}/form/contact`;
            const axios_resp = await axios.post(url, form);
            if (axios_resp.data.status) {
                msgSwal('success', txt1, 'Formulario enviado');
                dispatch(form1Submitted(true));
            } else {
                dispatch(form1Backdrop(false));
                msgSwal('warning', txt1, 'No se pudo enviar el formulario');
            }
        } catch (err) {
            dispatch(form1Backdrop(false));
            msgSwal('warning', txt1, 'Ocurrió un incidente. No se envió el formulario');
        }
    }
};