import { TopProductos } from "../../helpers/admin/dashboard/dashboardHelper";
import { setData } from "../../helpers/admin/dashboard/topProducts/topProductsHelper";
import { protectedReq } from "../../helpers/requestHelper";
import { msg } from "../swal_msg";

// Top 10 productos / Total de ventas / Ticket promedio
export const getTopProducts = async(setTopProductsData) => {
    try {
        const resp = await protectedReq('dashboard/top-products');
        const body = await resp.json();
        const { orders, today } = body.resp;
        const {
            todayTop, weekTop, fortnightTop, monthTop,
            quarterTop, yearToDateTop, annualTop, ...Model1
        } = TopProductos(today, orders);
        setTopProductsData(e => ({
            ...e,
            todayData: setData(todayTop),
            weekData: setData(weekTop),
            fortnightData: setData(fortnightTop),
            monthData: setData(monthTop),
            quarterData: setData(quarterTop),
            yearToDateData: setData(yearToDateTop),
            annualData: setData(annualTop),
            // filteredData: setData(todayTop)
        }));
        return { status: true, resp: Model1 };
    } catch (err) {
        msg('error', 'Top 10 productos',
        'No se pudieron recuperar los productos');
        return { status: false };
    }
};

export const newClients = async() => {
    try {
        const resp = await protectedReq('dashboard/new-clients');
        const body = await resp.json();
        return body;
    } catch (err) {
        msg('error', 'Nuevos clientes',
        'No se pudo recuperar clientes nuevos');
        return { status: false };
    }
};

export const topStates = async() => {
    try {
        const resp = await protectedReq('dashboard/top-states');
        const body = await resp.json();
        return body;
    } catch (err) {
        msg('error', 'Top 5 estados',
        'No se pudo recuperar los estados con mayores ventas');
        return { status: false };
    }
};

export const topClients = async() => {
    try {
        const resp = await protectedReq('dashboard/top-clients');
        const body = await resp.json();
        return body;
    } catch (err) {
        msg('error', 'Top 10 clientes',
        'No se pudieron recuperar clientes frecuentes');
        return { status: false };
    }
};