import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { GOOGLE_DRIVE_CDN, PRODUCT_NO_IMG } from '../../../../constants/constants';

import {
	Grid, IconButton, Typography, Alert, Box,
    Card, CardMedia, CardContent, CardActions,
    Tooltip
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const spanItalic = {
	fontStyle: 'italic',
	marginLeft: '4px'
};

const Category = ({ CustomTabPanel, value, data, setData, i }) => {
    const { categories } = data;
    const { category, url, products } = categories[i-1];

    // mover producto en lista
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(products);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setData(e => ({
            ...e,
            categories: categories.map(
                e => (e.category === category) ? { ...e, products: items } : e
            )
        }));
    };

    // mover producto seleccionado a principio de lista
    const moveToTop = i => () => {
        const items = Array.from(products);
        const [reorderedItem] = items.splice(i, 1);
        items.splice(0, 0, reorderedItem);
        
        setData(e => ({
            ...e,
            categories: categories.map(
                e => (e.category === category) ? { ...e, products: items } : e
            )
        }));
    };

    // mover producto seleccionado al fondo de lista
    const moveToBottom = i => () => {
        const items = Array.from(products);
        const [reorderedItem] = items.splice(i, 1);
        items.splice(items.length, 0, reorderedItem);
        
        setData(e => ({
            ...e,
            categories: categories.map(
                e => (e.category === category) ? { ...e, products: items } : e
            )
        }));
    };

    return (
        <CustomTabPanel value={value} index={i}>
            {
                products.length > 0 ?
                    <DragDropContext onDragEnd={ handleOnDragEnd }>
                        <Droppable droppableId={`${url}-category`}>
                            {(provided) => (
                            <Grid container spacing={2}
                                {...provided.droppableProps} ref={provided.innerRef}>
                                {
                                    products.map(({
                                        _id, title, url, category, total_stock, prod_variants
                                    }, i) => {
                                        const len = prod_variants.length;
                                        const src = (len > 0 && prod_variants[0].images.length > 0) ?
                                            `${GOOGLE_DRIVE_CDN}${prod_variants[0].images[0].id}`
                                        :
                                            `${GOOGLE_DRIVE_CDN}${PRODUCT_NO_IMG}`;

                                        return (
                                            <Draggable key={_id} draggableId={_id} index={i}>
                                                {(provided) => (
                                                <Grid item xs={12} sm={6} md={4}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <Card sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            <CardContent>
                                                                <Typography sx={{ fontSize: 14 }}
                                                                    color="text.secondary" gutterBottom
                                                                    component="div">
                                                                    {
                                                                        `${title} (${len}
                                                                        variante${len === 1 ? '' : 's'})`
                                                                    }
                                                                </Typography>
                                                                <Typography variant="caption" display="block" gutterBottom>
                                                                    Categorías:
                                                                    {
                                                                        category.map((cat, i) =>
                                                                        (i === category.length - 1) ?
                                                                            <span key={i} style={ spanItalic }>
                                                                                { cat }
                                                                            </span>
                                                                        :
                                                                            <span key={i} style={ spanItalic }>
                                                                                { cat } |
                                                                            </span>
                                                                        )
                                                                    }
                                                                </Typography>
                                                                <Typography variant="caption" display="block" gutterBottom>
                                                                    { `Stock total: ${total_stock}` }
                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions>
                                                                <Tooltip title="Mover arriba">
                                                                    <IconButton aria-label="move-top"
                                                                        onClick={ moveToTop(i) }>
                                                                        <ArrowUpwardIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Mover abajo">
                                                                    <IconButton aria-label="move-bottom"
                                                                        onClick={ moveToBottom(i) }>
                                                                        <ArrowDownwardIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </CardActions>
                                                        </Box>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ width: 151 }}
                                                            image={ src }
                                                            alt={ `img-${url}` }
                                                        />
                                                    </Card>
                                                </Grid>
                                            )}
                                            </Draggable>
                                        )}
                                    )
                                }
                                { provided.placeholder }
                            </Grid>
                        )}
                        </Droppable>
                    </DragDropContext>
                :
                    <Alert variant="outlined" severity="info">
                        No hay productos disponibles
                    </Alert>
            }
        </CustomTabPanel>
    );
};

export default Category;