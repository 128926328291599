import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// funciones
import { htmlMsg } from '../../../../actions/swal_msg';
import { categoriesLoad } from '../../../../actions/category';
import { generateURL } from '../../../../helpers/admin/productHelper';
import {
  addProduct, loadProductList, productSnackbarClose
} from '../../../../actions/product';
//CSS
import './newproduct.css';
// componentes
import NewVariant from './NewVariant';
import ProductFBT from './FBT/ProductFBT';
import CategorySelect from './Category/CategorySelect';
import ProductKeywords from './Keywords/ProductKeywords';
// constantes
import { regex_num } from '../../../../constants/regex';
import { productInit, variantInit } from '../../../../constants/constants';

import {
  TextField, Snackbar, IconButton, Button, Fab,
  Backdrop, CircularProgress, ToggleButton, Container,
  Typography, Grid, Box, FormGroup, FormControlLabel,
  Switch,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxIcon from '@mui/icons-material/AddBox';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import useMediaQuery from '@mui/material/useMediaQuery';

export const NewProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:600px)');
  // estado UI de módulo
  const { loading2 } = useSelector(state => state.ui);

  const { products, productSaved, snackbar } = useSelector(state => state.product);
  const { open, message } = snackbar;
  // valor de producto y variantes
  const [prodValues, setProdValues] = useState(productInit);
  const {
    title, info, category, url, featured,
    refrigerated, medicine, keywords, variants
  } = prodValues;
  // contador (identificador de variante - key)
  const [counter, setCounter] = useState(1);
  // variantes como producto único (se utiliza en ProductFBT.js)
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    dispatch(loadProductList()); // productos
    dispatch(categoriesLoad()); // categorías
  }, []);

  useEffect(() => {
    if (productSaved)
      navigate('/nefro-admin/productos');
  }, [productSaved]);
  
  // producto agregado (redireccionar a lista de productos)
  useEffect(() => {
    // al cargar los productos, se toma cada variante como único
    if (products.length > 0) {
      let Products = [];
      for (const e of products) {
        const { _id, keywords, featured, reviews, prod_variants, ..._product } = e;
        for (const variant of prod_variants) {
          const { _id, available, batches, sku, ..._variant } = variant;
          Products = [...Products, {
            prod_id: e._id,
            prod_var_id: variant._id,
            ..._product, ..._variant,
            checked: false
          }];
        }
      }
      setAllProducts(Products);
    }
  }, [products]);
  
  // título / información
  const handleInputChange = ({ target }) => {
    const name = target.name;
    const value = target.value;
    if (name === 'title') {
      const _title = value;
      const _url = generateURL(_title);
      setProdValues({
        ...prodValues,
        title: _title,
        url: _url
      });
    } else {
      setProdValues({
        ...prodValues,
        [name]: value
      });
    }
  };

  // producto destacado / necesita refrigeración
  const handleToggleChange = ({ target }) => {
    const _name = target.name;
    if (_name === 'featured')
      setProdValues({
        ...prodValues,
        featured: !featured
      });
    else if (_name === 'refrigerated')
      setProdValues({
        ...prodValues,
        refrigerated: target.checked
      });
    else if (_name === 'medicine')
      setProdValues({
        ...prodValues,
        medicine: target.checked
      });
  };

  // añadir nueva variante
  const addVariant = () => {
    setProdValues({
      ...prodValues,
      variants: [...variants, {
        ...variantInit,
        key: counter
      }]
    });
    // variante añadida
    setCounter(counter+1);
  };

  // registrar producto y variantes
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = formValidator();
    if (errors.length > 0) { // no ha completado datos del producto
      let err_string = '';
      for (let err of errors) {
        err_string += `<li>${err}</li>`;
      };
      htmlMsg('info', 'Complete los datos del producto',
      `<div style="text-align: left; padding-left: 0pt;">Pendiente: <ul>${err_string}</ul></div>`);
    } else { // registrar producto
      const fbt_item = allProducts.filter(e => e.checked).map(u => {
        const { prod_id, prod_var_id } = u;
        // const { checked, ...obj } = u;
        // return obj;
        return { prod_id, prod_var_id };
      });
      dispatch(addProduct({ ...prodValues, fbt_item}));
    }
    // if (formValidator())
    //   dispatch(addProduct(prodValues));
  };

  // validación básica de formulario
  // const formValidator = () => {
  //   let err_count = 0;
  //   const title_len = title.trim().length;
  //   const info_len = info.trim().length;
  //   const url_len = url.trim().length;
  //   if (title_len > 1 && title_len <= 200
  //     && info_len > 1 && info_len <= 2000
  //     && url_len > 1 && url_len <= 200 && category.length > 0
  //     && variants.length > 0) {
  //     variants.map(e => {
  //       if (e.price.length < 2 || e.price.length > 8
  //         || e.description.length < 3 || e.description.length > 2000
  //         || e.sat_key.length !== 8 || !num_regex.test(e.sat_key)
  //         || (e.iva !== 0 && e.iva !== 16)
  //         || e.claveUnidad.length !== 3
  //         || e.unidad.length < 2 || e.unidad.length > 64
  //         || e.barcode.length < 1 || e.barcode.length > 32
  //         || e.stock.length < 1 || e.stock.length > 5
  //         || e.images <= 0 || e.images > 6) err_count++;
  //       return e;
  //     });
  //     if (err_count === 0) return true;
  //     else msg('warning', 'Variantes de producto',
  //       'Faltan datos en las variantes de producto');
  //   } else msg('warning', 'Producto',
  //     'Complete los datos del producto');
  //   return false;
  // };
  const formValidator = () => {
    let errors = [];
    const title_len = title.trim().length;
    // const info_len = info.trim().length;
    const url_len = url.trim().length;
    if (title_len < 2 || title_len > 200)
      errors = [...errors, 'Ingrese título de producto'];
    // if (info_len < 2 || info_len > 2000)
    //   errors = [...errors, 'Ingrese información de producto'];
    if (url_len < 2 || url_len > 200)
      errors = [...errors, 'No se generó la url'];
    if (category.length <= 0)
      errors = [...errors, 'Seleccione por lo menos una categoría'];
    if (variants.length > 0) {
      variants.map((e, i) => {
        if (e.price.length < 2 || e.price.length > 8)
          errors = [...errors, `Variante #${i+1}: Requiere precio`];
        else if (e.description.length < 3 || e.description.length > 2000)
          errors = [...errors, `Variante #${i+1}: Ingrese descripción`];
        else if (e.sat_key.length !== 8 || !regex_num.test(e.sat_key))
          errors = [...errors, `Variante #${i+1}: Ingrese clave SAT`];
        else if (e.iva !== 0 && e.iva !== 16)
          errors = [...errors, `Variante #${i+1}: Ingrese IVA - 0/16%`];
        else if (e.claveUnidad.length !== 3)
          errors = [...errors, `Variante #${i+1}: Ingrese clave de unidad`];
        else if (e.unidad.length < 2 || e.unidad.length > 64)
          errors = [...errors, `Variante #${i+1}: Ingrese unidad`];
        else if (e.barcode.length < 1 || e.barcode.length > 32)
          errors = [...errors, `Variante #${i+1}: Ingrese código de barras`];
        else if (e.stock.length < 1 || e.stock.length > 5)
          errors = [...errors, `Variante #${i+1}: Ingrese stock`];
        else if (e.images <= 0 || e.images > 6)
          errors = [...errors, `Variante #${i+1}: Ingrese entre 1 y 6 imágenes`];
        return e;
      });
    } else errors = [...errors, `Agregue por lo menos una variante`];
    return errors;
  };

  // cerrar snackbar
  const handleSnackbarClose = () => {
    dispatch(productSnackbarClose());
  };

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        onClick={handleSnackbarClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  
  return (
    <Container maxWidth='xxl' sx={{ marginTop: '90px', mb: 5 }}>
      <Typography variant='h5' sx={{ fontWeight: 'light' }} paragraph>Añadir nuevo producto</Typography>
      <form onSubmit={handleSubmit}>
        {/* Productos destacado */}
        <Box sx={{ mb: 3 }}>
          <ToggleButton fullWidth={matches ? true : false}
            name="featured"
            value="check"
            selected={featured}
            onChange={handleToggleChange}>
            {
              featured ? <>Destacado<StarIcon sx={{ color: '#ffd600', ml: 1.5 }} /></> :
                <>No destacado<StarBorderIcon sx={{ ml: 1.5 }} /></>
            }
          </ToggleButton>
        </Box>

        {/* necesita refrigeración */}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="refrigerated"
                checked={ refrigerated }
                onChange={ handleToggleChange } />}
            label="Necesita refrigeración" />
        </FormGroup>

        {/* es medicamento */}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="medicine"
                checked={ medicine }
                onChange={ handleToggleChange } />}
            label="Es medicamento" />
        </FormGroup>

        <Grid container spacing={4}>
          <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
            {/* Nombre del productos */}
            <Box>
              <TextField
                fullWidth
                name="title"
                label="Título"
                variant="outlined"
                value={title}
                onChange={handleInputChange} />
            </Box>

            {/* Información adicional */}
            <Box sx={{ mt: 3 }}>
              <TextField
                fullWidth
                name="info"
                label="Información adicional"
                variant="outlined"
                multiline
                rows={7.7}
                value={info}
                onChange={handleInputChange} />
            </Box>

            {/* Variantes */}
            <Box sx={{ mt: 3 }}>
              {
                variants.map((variant, i) =>
                  <NewVariant
                    key={variant.key}
                    pos={i}
                    title={title}
                    variant={variant}
                    setProdValues={setProdValues} />
                )
              }
              <Box sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  endIcon={<AddBoxIcon />}
                  onClick={addVariant}>
                  Nueva variante
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
            {/* Categoría del producto */}
            <Box sx={{ p: 3, mb: 3 }} className='box-shadow-add-product-category'>
              <CategorySelect setProdValues={setProdValues} />
            </Box>

            <Box sx={{ p: 3 }} className='box-shadow-add-product-category'>
              <ProductKeywords
                setProdValues={setProdValues}
                keywords={keywords} />
            </Box>

            {/* Comprados juntos frecuentemente */}
            <ProductFBT
              allProducts={ allProducts }
              setAllProducts={ setAllProducts } />
          </Grid>

          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={message}
            action={snackbarAction} />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={ loading2 }>
            <CircularProgress />
          </Backdrop>
          <Fab type="submit"
            color="primary"
            sx={{
              position: "fixed",
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2)
            }}>
            <i className="fa-solid fa-floppy-disk" style={{ fontSize: '1.2rem' }}></i>
          </Fab>
        </Grid>
      </form>
    </Container>
  );
};

export default NewProduct;