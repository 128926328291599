import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { shippingMethodSelected } from '../../../actions/shop_shipping';
import { SHIPPING_METHOD, FREE_SHIPPING } from '../../../constants/shop/shop_constants';

import {
    FormControl, Typography, Alert, FormLabel,
    FormControlLabel, Radio, RadioGroup
} from "@mui/material";

const ShippingMethods = ({ display }) => {
    const dispatch = useDispatch();
    const {
        cart: {
            item, // productos de carrito
            summary: { subtotal },
            coupon, // cupón aplicado
        },
        shipping: {
            methods: {
                full_list, client_list,
                selected, radio_button
            }
        }
    } = useSelector(state => state.shop);

    useEffect(() => {
        if (item.length > 0) { // productos en carrito
            if (full_list.length > 0) { // al menos un método de envío disponible
                if (subtotal > FREE_SHIPPING) // tiene envío gratis
                   OrderFreeShipping();
                else {
                    if (coupon && coupon._class === 'free_shipping')
                        OrderFreeShipping();
                    else {
                        // quita el envío gratuito seleccionado
                        // no tiene envío gratis
                        if (selected.cost === 0)
                            dispatch(shippingMethodSelected({
                                selected: SHIPPING_METHOD,
                                radio_button: null
                            }));
                        // lo deja si está seleccionado cualquier otro
                    }
                }
            } else
                dispatch(shippingMethodSelected({
                    selected: SHIPPING_METHOD,
                    radio_button: null
                }));
        } else
            dispatch(shippingMethodSelected({
                selected: SHIPPING_METHOD,
                radio_button: null
            }));
    }, [subtotal, coupon]);

    // pedido tiene envío gratis
    // (mínimo de compra o mediante cupón)
    const OrderFreeShipping = () => {
        const Method = full_list.find(e => e.cost === 0);
        if (Method)
            dispatch(shippingMethodSelected({
                selected: Method,
                radio_button: null
            }));
        else
            dispatch(shippingMethodSelected({
                selected: SHIPPING_METHOD,
                radio_button: null
            }));
    };

    const onMethodSelect = ({ target }) => {
        const MethodSelected = full_list.find(e => e._id === target.value);
        if (MethodSelected)
            dispatch(shippingMethodSelected({
                selected: MethodSelected,
                radio_button: target.value
            }));
        else
            dispatch(shippingMethodSelected({
                selected: SHIPPING_METHOD,
                radio_button: null
            }));
    };

    return (
        display &&
        (
            client_list.length > 0 ?
                <FormControl>
                    <FormLabel id="shipping-radio-buttons-group-label">
                        Métodos de envío
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="shipping-radio-buttons-group-label"
                        value={ radio_button }
                        name="radio-group"
                        onChange={ onMethodSelect }>
                        {
                            client_list.map((e, i) =>
                                <FormControlLabel key={i}
                                    disabled={ false }
                                    value={ e._id }
                                    control={<Radio />}
                                    label={
                                        <Typography variant="body2">
                                            { e.partner } - ${e.cost} ({e.delivery_time})
                                        </Typography>
                                    }
                                />
                            )
                        }
                    </RadioGroup>
                </FormControl>
            :
                <Alert severity="info">No hay métodos de pago disponibles</Alert>
        )
    )
};

export default ShippingMethods;