import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
    Typography, Input, InputAdornment, Box
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import ProductItem from './ProductItem';
import ProductItem2 from './ProductItem2';

const ProductList = ({ list, setCoupon }) => {
    // lista de productos
    const { products } = useSelector(state => state.product);
    // productos filtrados
    const [allProducts, setAllProducts] = useState([]);
    
    // búsqueda de producto
    const [search, setSearch] = useState('');

    // producto buscando
    const handleInputChange = ({ target }) => {
        setSearch(target.value);
        if (target.value !== '') filterProducts(target.value);
        else setAllProducts([]);
    };

    // filtrar productos por búsqueda
    const filterProducts = (value) => {
        // primero filter y luego set
        if (products.length > 0) {
            let Products = [];
            for (const e of products) {
                const { prod_variants, ..._product } = e;
                for (const variant of prod_variants) {
                    const { _id, ..._variant } = variant;
                    Products = [...Products, {
                        ..._product, ..._variant,
                        prod_var_id: _id,
                        checked: true
                    }];
                }
            }
            const _search = value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            setAllProducts(Products.filter((e) =>
                e.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search)
                ||
                e.variant_title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search)
                ||
                e.category.find(u => u.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(search))
            ));
        }
    };

    return (
        <Box sx={{ my: 5 }}>
            <Typography variant="subtitle1">
                Buscar productos
            </Typography>
            <Input fullWidth sx={{ mb: 2 }}
                placeholder="Buscar producto (título/categoría)"
                value={ search }
                onChange={ handleInputChange }
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
            />
            <ProductItem
                allProducts={ allProducts }
                setAllProducts={ setAllProducts }
                setSearch={ setSearch }
                setCoupon={ setCoupon }
            />
            
            <ProductItem2
                selectedProducts={ list }
                setCoupon={ setCoupon }
            />
        </Box>
    );
};

export default ProductList;