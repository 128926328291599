// Productos comprados frecuentemente (vista de producto en tienda)
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { basicMsg } from '../../../actions/swal_msg';
import { addItemsToCart } from '../../../actions/shop';

import { shopProductsFBTAdded } from '../../../actions/ui';
import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';

import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

import {
    Typography, Button, Box, FormGroup,
    FormControlLabel, Checkbox
} from '@mui/material';

const ProductsFBT = ({ fbtItems, setFBTItems, product, variant, setVariant }) => {
    const dispatch = useDispatch();
    // bandera al agregar productos a carrito
    const { productsFBT_flag } = useSelector(state => state.ui);

    const {
        _id: prod_id, title, url, info,
        category, refrigerated, medicine
    } = product;

    const {
        _id: prod_var_id, variant_title, price,
        images, checked, item_promotion
    } = variant;

    // uno o más productos agregados a producto
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        // se han agregado productos al carrito
        if (productsFBT_flag) {
            setSubmitted(true);
            // setFBTItems(e => e.filter(u => !u.checked));
            dispatch(shopProductsFBTAdded(false));
        }
    }, [productsFBT_flag]);

    // checkbox producto principal
    const uncheckMainProduct = () => {
        setVariant({ ...variant, checked: !checked });
    };

    // checkbox arreglo de productos
    const handleCheckbox = _prod_var_id => () => {
        setFBTItems(e =>
            e.map(u => u.prod_var_id === _prod_var_id ?
                { ...u, checked: !u.checked } : u));
    };

    // agregar productos seleccionados a carrito
    const addToCart = () => {
        let items = fbtItems.filter(e => e.checked);
        if (checked) {
            const model = {
                prod_id, prod_var_id, title, url,
                info, category, refrigerated, medicine,
                variant_title
            };
            items = [...items, model];
        }
        if (items.length > 0)
            dispatch(addItemsToCart(items, () => {}));
        else basicMsg('No ha seleccionado ningún producto');
    };

    return (
        <div className="product-recomended" style={{ marginTop: '60px' }}>
            <Typography variant="h5" sx={{ fontWeight: 'light' }}>
                Frecuentemente se compran juntos
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', my: 3 }}>
                <Box>
                    {
                        images.length > 0 ?
                            <img className="image-buy-tog" width='230' height="230"
                                style={{ objectFit: 'contain' }} alt={images[0].alt}
                                src={`${GOOGLE_DRIVE_CDN}${images[0].id}`} />
                        :
                            <img className="image-buy-tog" width='230' height="230"
                                style={{ objectFit: 'contain' }} alt="producto-nefropolis"
                                src={ no_image } />
                    }
                </Box>

                {
                    fbtItems.length > 0 &&
                        <Box sx={{ my: 'auto' }}>
                            <i className="fas fa-plus icon-bf" style={{ fontSize: '1.5rem' }}></i>
                        </Box>
                }

                {
                    fbtItems.map((e, i) =>
                        <React.Fragment key={e.prod_var_id}>
                            <Box>
                                {
                                    e.images.length > 0 ?
                                        <img className="image-buy-tog" width='230' height="230"
                                            style={{ objectFit: 'contain' }} alt={e.images[0].alt}
                                            src={`${GOOGLE_DRIVE_CDN}${e.images[0].id}`} />
                                    :
                                        <img className="image-buy-tog" width='230' height="230"
                                            style={{ objectFit: 'contain' }} alt="producto-nefropolis"
                                            src={ no_image } />
                                }
                            </Box>
                            {
                                i < fbtItems.length - 1 &&
                                    <Box sx={{ my: 'auto' }}>
                                        <i className="fas fa-plus icon-bf" style={{ fontSize: '1.5rem' }}></i>
                                    </Box>
                            }
                        </React.Fragment>
                    )
                }
            </Box>

            <FormGroup>
                {/* producto en cuestión */}
                <FormControlLabel control={
                    <Checkbox checked={ checked } onChange={ uncheckMainProduct } />
                } label={
                    ['Descuento$Producto', 'Descuento%Producto'].includes(item_promotion.promo) ?
                        `Este producto: ${title} ${variant_title} - $${item_promotion.price}`
                        :
                        `Este producto: ${title} ${variant_title} - $${price}`
                } />

                {/* comprados juntos frecuentemente */}
                {
                    fbtItems.map(({ prod_var_id, title, variant_title, price, checked }) =>
                        <FormControlLabel key={prod_var_id} control={
                            <Checkbox checked={ checked }
                                onChange={ handleCheckbox(prod_var_id) } />
                        } label={`${title} ${variant_title} - $${price}`} />
                    )
                }
            </FormGroup>

            <Button type="submit" variant="contained"
                onClick={ addToCart }>
                {
                    !submitted ?
                        'Agregar todo al carrito'
                    :
                        'Agregar de nuevo al carrito'
                }
            </Button>
        </div>
    );
};

export default ProductsFBT;