import { combineReducers } from 'redux';
import { uiReducer } from './uiReducer';
import { formReducer } from './formReducer';
import { shopReducer } from './shopReducer';
import { userReducer } from './userReducer';
import { blogReducer } from './blogReducer';
import { adminReducer } from './adminReducer';
import { couponReducer } from './couponReducer';
import { doctorReducer } from './doctorReducer';
import { productReducer } from './productReducer';
import { categoryReducer } from './categoryReducer';
import { checkoutReducer } from './checkoutReducer';
import { userAccountReducer } from './userAccountReducer';

export const mainReducer = combineReducers({
    admin: adminReducer,
    blog: blogReducer,
    category: categoryReducer,
    checkout: checkoutReducer,
    coupon: couponReducer,
    doctor: doctorReducer,
    form: formReducer,
    product: productReducer,
    shop: shopReducer,
    user_account: userAccountReducer,
    user: userReducer,
    ui: uiReducer,
});