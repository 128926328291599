import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";

//CSS
import './logincheckout.css';
import { login } from "../../../../actions/user";

import {
    Box, Button, Container, Grid, Typography,
    IconButton, InputLabel, FormControl,
    OutlinedInput, InputAdornment, useMediaQuery
} from '@mui/material';

const initValues = {
    email: '',
    password: ''
};

const LoginCheckOutComponent = () => {
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width: 950px)');

    // mostrar y ocultar contraseña
    const [toggle, setToggle] = useState(false);
    const [values, setValues] = useState(initValues)
    const { email, password } = values;

    const handleClickShowPassword = () => {
        setToggle(!toggle);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleOnChange = ({ target }) => {
        setValues(e => ({ ...e, [target.name]: target.value }));
    };

    const submit = (e) => {
        e.preventDefault();
        dispatch(login({ ...values, page: 'checkout' }));
    };

    return (
        <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }} className="last-view">
            <Typography variant='h5' sx={{ fontWeight: 'light', textTransform: 'uppercase', mb: 3 }} align='center'>
                ¿Cómo quieres continuar con tu compra?
            </Typography>
            <Grid container spacing={4}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Box sx={{ p: 3 }} className="login-checkout-account">
                        <Typography variant='h5' sx={{ fontWeight: 'light', mb: 3 }}>Iniciar sesión</Typography>
                        <form>
                            {/* Correo electronico */}
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <InputLabel>
                                    Correo electrónico
                                </InputLabel>
                                <OutlinedInput
                                    id="email-input"
                                    placeholder="Correo electrónico"
                                    style={{ backgroundColor: '#fff' }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <i className="fa-solid fa-user"></i>
                                        </InputAdornment>
                                    }
                                    inputProps={{ maxLength: 60 }}
                                    label="Correo electrónico"
                                    name="email"
                                    value={email}
                                    onChange={handleOnChange}
                                />
                            </FormControl>

                            {/* Contraseña con metodo para mostrar y ocultar */}
                            <FormControl fullWidth sx={{ mb: .5 }}>
                                <InputLabel>Contraseña</InputLabel>
                                <OutlinedInput
                                    id="pass-input"
                                    type={toggle ? 'text' : 'password'}
                                    placeholder="Contraseña"
                                    style={{ backgroundColor: '#fff' }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <i className="fa-solid fa-key"></i>
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {toggle ?
                                                    <i className="far fa-eye-slash" style={{ fontSize: '1.3rem' }}></i>
                                                    :
                                                    <i className="far fa-eye" style={{ fontSize: '1.3rem' }}></i>
                                                }
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Contraseña"
                                    name="password"
                                    value={password}
                                    onChange={handleOnChange}
                                />
                            </FormControl>
                            <Link to="">Olvidé mi contraseña</Link>
                            <Box sx={{ mt: 3, textAlign: 'center' }}>
                                <Button variant='contained'
                                    onClick={submit}>
                                    Comprar con mi cuenta
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Box sx={{ p: 3, height: '330px' }} className="login-checkout-invitado">
                        <Typography variant='h5' sx={{ fontWeight: 'light' }}>Comprar como invitado</Typography>
                        <Typography variant='body1' sx={{ mt: 3 }}>Haz tu compra como invitado sin la necesidad de crear
                            una cuenta o <Link to={'/signup'}>regístrate</Link> para que recordemos tus datos
                            la próxima vez que compres con nosotros.
                        </Typography>
                        <Box sx={{ mt: 6 }}>
                            <Link to={'/checkout'} style={{ textDecoration: 'none' }}>
                                <Button variant='contained' color='secondary'>Continuar como invitado</Button>
                            </Link>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default LoginCheckOutComponent;