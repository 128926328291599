import React from 'react'

//Table
import MaterialTable, { MTableToolbar } from "material-table";

//Chart js
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

//Opciones para la gráfica
export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
};

//Columna de la tabla
const columns_table = [
    {
        title: "Mes",
        field: "mes",
    },
    {
        title: "$",
        field: "ganancia",
    },
];

function TicketPromedio({ data, data_table }) {
    return (
        <>
            <Typography variant='h5' sx={{ fontWeight: 'light' }} align='center' gutterBottom>Ticket promedio general</Typography>
            {/* Gráfica */}
            <div style={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                <Line options={options} data={data} style={{ maxWidth: '100%' }} />
            </div>
            {/* fin gráfica */}

            {/* inicia tabla */}
            <Box sx={{ width: '100%', mt: 4 }}>
                <MaterialTable
                    title="Ticket promedio general"
                    data={data_table}
                    columns={columns_table}
                    options={{
                        exportButton: true,
                        headerStyle: {
                            zIndex: 1
                        },
                    }}
                    components={{
                        Toolbar: props => (<MTableToolbar {...props} classes={{ root: "dummy-class" }} />),
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "No hay datos por mostrar",
                            addTooltip: 'Agregar',
                            deleteTooltip: 'Eliminar',
                            editTooltip: 'Editar',
                            filterRow: {
                                filterTooltip: 'Filtrar'
                            },
                            editRow: {
                                deleteText: '¿Segura(o) que quiere eliminar?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Guardar'
                            }
                        },
                        grouping: {
                            placeholder: "Arrastre un encabezado aquí para agrupar",
                            groupedBy: 'Agrupado por:'
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'filas',
                            labelRowsPerPage: 'Filas por página:',
                            firstAriaLabel: 'Primera página',
                            firstTooltip: 'Primera página',
                            previousAriaLabel: 'Página anterior',
                            previousTooltip: 'Página anterior',
                            nextAriaLabel: 'Página siguiente',
                            nextTooltip: 'Página siguiente',
                            lastAriaLabel: 'Última página',
                            lastTooltip: 'Última página'
                        },
                        toolbar: {
                            addRemoveColumns: 'Agregar o eliminar columnas',
                            nRowsSelected: '{0} fila(s) seleccionada(s)',
                            showColumnsTitle: 'Mostrar columnas',
                            showColumnsAriaLabel: 'Mostrar columnas',
                            exportTitle: 'Exportar',
                            exportAriaLabel: 'Exportar',
                            exportName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            searchTooltip: 'Buscar',
                            searchPlaceholder: 'Buscar'
                        }
                    }}
                />
            </Box>
            {/* Fin de la tabla */}
        </>
    )
}

export default TicketPromedio