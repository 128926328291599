import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCategory from '../Category/AddCategory';
import ProductCategories from '../Category/ProductCategories';
import { categoriesLoad, categoryReset } from '../../../../actions/category';

import { Grid, Container } from '@mui/material';

const ProductQualities = () => {
    const dispatch = useDispatch();
    const { action } = useSelector(state => state.category);

    useEffect(() => {
        dispatch(categoriesLoad());
    }, []);

    useEffect(() => { // limpiar campos
        if (action === 'added')
            dispatch(categoryReset(''));
    }, [action]);

    return (
            <Container maxWidth="xl">
                <Grid container spacing={6} sx={{ my: 3 }}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <ProductCategories />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <AddCategory />
                    </Grid>
                </Grid>
            </Container>
    );
};

export default ProductQualities;