import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import pagosseguros from '../../../assets/images/banner-envio-compra-cobertura.png';

// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from "swiper";
import { Pagination as SwiperPag } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

import {
    Box, Typography, Container, Grid, IconButton, Link
} from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const CategoryContainer = () => {
    const navigate = useNavigate();

    const { categories } = useSelector(state => state.shop);
    
    return (
        <Container maxWidth='xxl' sx={{ marginTop: '35px', zIndex: '0' }}>
            {/* Slider categorias de busqueda */}
            <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                <Grid item xl={1} lg={1} md={1} sm={2} xs={2} sx={{ my: 'auto' }}>
                    <IconButton className='categories-swiper-button-prev' size='small'>
                        <ChevronLeftIcon />
                    </IconButton>
                </Grid>
                <Grid item xl={10} lg={10} md={10} sm={8} xs={8}>
                    {
                        categories.length > 0 && (
                            <Swiper
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    426: {
                                        slidesPerView: 2,
                                    },
                                    600: {
                                        slidesPerView: 3,
                                    },
                                    769: {
                                        slidesPerView: 4,
                                    },
                                    1025: {
                                        slidesPerView: 5,
                                    },
                                }}
                                spaceBetween={30}
                                navigation={{
                                    nextEl: '.categories-swiper-button-next',
                                    prevEl: '.categories-swiper-button-prev',
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                autoplay={{
                                    delay: 8000,
                                    disableOnInteraction: false,
                                }}
                                loop={true}
                                modules={[Navigation, SwiperPag, Autoplay]}
                            >
                                {
                                    categories.map(({ url, category, imgId }, i) =>
                                        <SwiperSlide key={i}>
                                            <Link component="button"
                                                onClick={ () => navigate(`categoria/${url}`) }>
                                                <img className='category_img'
                                                    width='100%'
                                                    src={`${GOOGLE_DRIVE_CDN}${imgId}`}
                                                    alt={`imagen-${category}`} />
                                            </Link>
                                            <Typography variant='body2' align='center' sx={{ mt: .8 }}>
                                                { category }
                                            </Typography>
                                        </SwiperSlide>
                                    )
                                }
                            </Swiper>
                        )
                    }
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={2} xs={2} sx={{ my: 'auto' }}>
                    <IconButton className='categories-swiper-button-next' size='small'>
                        <ChevronRightIcon />
                    </IconButton>
                </Grid>
            </Grid>

            {/* <Stack direction='row' spacing={4} justifyContent="center" sx={{ my: 'auto' }}>
                <IconButton className='categories-swiper-button-prev' size='small'>
                    <ChevronLeftIcon />
                </IconButton>
                <IconButton className='categories-swiper-button-next' size='small'>
                    <ChevronRightIcon />
                </IconButton>
            </Stack> */}
            {/* Fin Slider categorias de busqueda */}

            <Box sx={{ mb: 5, mt: 5, textAlign: 'center' }}>
                <img src={pagosseguros} width='50%' alt={pagosseguros} style={{ pointerEvents: 'none' }} className="safe_purchase" />
            </Box>

        </Container>
    );
};

export default CategoryContainer;