import React, { useState } from 'react';

import { basicMsg } from '../../../actions/swal_msg';

import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

import {
    Box, Collapse, Divider, Grid, IconButton, Stack,
    Typography, Alert, useMediaQuery
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const BranchList = ({ branches, setAnchorEl, setActiveBranch }) => {
    const matches = useMediaQuery('(max-width: 575px)');

    const handleClick = (id) => (event) => {
        const Branch = branches.find(e => e._id === id);
        if (Branch) {
            const {
                data: { title: name, phone, mobile, email },
                address: { street, colony, postalcode, city, state },
                links, _id, folderId, imgId
            } = Branch;
            setActiveBranch((e) => ({
                ...e, _id, folderId, imgId,
                name, phone, mobile, email, address: street,
                postalCode: postalcode, colony, city, state,
                links
            }));
            setAnchorEl(event.currentTarget);
        } else basicMsg('No se encontró la sucursal');
    };
    
    //Collapse
    const [collapse, setCollapse] = useState({});
    const toggleCollapsable = (id) => () => {
        setCollapse((set) => ({
            ...set,
            [id]: !set[id]
        }));
    };

    return (
        <Stack spacing={2}>
            {
                branches.length > 0 ?
                    branches.map(({
                        _id, imgId,
                        data: { title, phone, mobile, email },
                        address: { street, colony, postalcode, city, state },
                        links: {
                            whatsapp: { number, message },
                            facebook: { url },
                            google_maps: { src, title: { iframe_title }, width, height }
                        }
                    }) => (
                        <Box key={ _id } className='box_branches' p={3}>
                            <Grid container spacing={3}>
                                <Grid item xl={6.5} lg={6.5} md={8} sm={12} xs={12}
                                    order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                                    <Box className='flex_branches'>
                                        <Box sx={{ my: 'auto' }}>
                                            <img src={`${GOOGLE_DRIVE_CDN}${imgId}`}
                                                alt='imagen-sucursal' className='img_branches'
                                            />
                                        </Box>
                                        <Box sx={{ my: 'auto' }}>
                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                                { title }
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary'>
                                                { street } C.P. { postalcode }
                                            </Typography>
                                            <Typography variant='body2' color='text.secondary'>
                                                Col. { colony }, { city }, { state }
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xl={3.5} lg={3.5} md={12} sm={6} xs={12}
                                    order={{ xl: 2, lg: 2, md: 3, sm: 2, xs: 2 }} sx={{ my: 'auto' }}>
                                    <Box className='info_flex_branches'>
                                        <PhoneIcon fontSize="inherit" />
                                        <Typography variant="body2">{ phone }</Typography>
                                    </Box>
                                    <Box className='info_flex_branches'>
                                        <SmartphoneIcon fontSize="inherit" />
                                        <Typography variant="body2">{ mobile }</Typography>
                                    </Box>
                                    <Box className='info_flex_branches'>
                                        <EmailIcon fontSize="inherit" />
                                        <Typography variant="body2">{ email }</Typography>
                                    </Box>
                                </Grid>

                                <Grid item xl={2} lg={2} md={4} sm={6} xs={12}
                                    order={{ xl: 3, lg: 3, md: 2, sm: 3, xs: 3 }}
                                    sx={{ my: 'auto' }}>
                                    <Stack spacing={1} direction="row"
                                        justifyContent={ matches ? 'center' : 'flex-end' } alignItems="center">
                                        <IconButton onClick={ toggleCollapsable(_id) }>
                                            {
                                                collapse[_id] ?
                                                    <KeyboardArrowUpIcon />
                                                :
                                                    <KeyboardArrowDownIcon />
                                            }

                                        </IconButton>
                                        <IconButton onClick={ handleClick(_id) }>
                                            {
                                                matches ?
                                                    <MoreHorizIcon />
                                                :
                                                    <MoreVertIcon />
                                            }
                                        </IconButton>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <Collapse in={collapse[_id]} timeout='auto' unmountOnExit>
                            <Divider sx={{ my: 3 }} />
                            <Grid container spacing={4}>
                                <Grid item xl={6}>
                                    <iframe src={ src }
                                        width={ width } height={ height } title={ iframe_title }
                                        style={{ border: '0', borderRadius: '10px' }}
                                        allowFullScreen="" loading="lazy">
                                    </iframe>
                                </Grid>
                            </Grid>
                            </Collapse>
                        </Box>
                    ))
                :
                    <Alert icon={<InfoIcon fontSize="inherit" />} severity="info">
                        Sin sucursales
                    </Alert>
            }
        </Stack>
    );
};

export default BranchList;