import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CategoryCheckboxActive from './CategoryCheckboxActive';
// import { addCategory } from '../../../../actions/category';
import { setCategoryToggle } from '../../../../actions/ui';

import {
    Grid, TextField, Button, ToggleButton, ToggleButtonGroup,
    Divider, Chip, Box, FormGroup, Alert
} from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';

// agregar nueva categoría
const ModalCategorySelect = ({ activeCategories, setActiveCategories }) => {
    const dispatch = useDispatch();
    // estado de componente categoría
    const { categoryToggle } = useSelector(state => state.ui);
    const [category, setCategory] = useState('');

    const handleChange = ({ target }) => {
        setCategory(target.value);
    };

    // agregar nueva categoría
    const onClick = () => {
        if (category.trim().length > 2) {
            // dispatch(addCategory(category));
            setCategory('');
        }
    };

    // lista de categoría / añadir nueva categoría
    const onCategoryChange = (event, pos) => {
        if (pos !== null)
            dispatch(setCategoryToggle(pos));
    };

    return (
        <>
            <Grid container>
                <Grid item xs={8}>
                    <Divider>
                        <Chip label="Categorías" sx={{ fontSize: 16 }} />
                    </Divider>
                </Grid>
                <Grid item xs={4}>
                    <Box display="flex" justifyContent="flex-end">
                        <ToggleButtonGroup
                            value={categoryToggle}
                            exclusive
                            onChange={onCategoryChange}
                            aria-label="estado categoría"
                            size="small">
                            <ToggleButton value="list" aria-label="category list">
                                <ListIcon />
                            </ToggleButton>
                            <ToggleButton value="add" aria-label="add category">
                                <AddIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Grid>
            </Grid>
            {
                categoryToggle === 'add' ? (
                    <Grid container alignItems="center">
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                name="category"
                                label="Nueva categoría"
                                variant="standard"
                                value={category}
                                onChange={handleChange} />
                        </Grid>
                        <Grid item xs={2} align="center">
                            <Button variant="contained" onClick={onClick}>
                                <CheckCircleOutlineSharpIcon fontSize="medium" />
                            </Button>
                        </Grid>
                    </Grid>
                ) :
                    <FormGroup>
                        {
                            activeCategories.length <= 0 ?
                                <Alert severity="info">
                                    Añada una categoría
                                </Alert> :
                                activeCategories.map(cat =>
                                    <CategoryCheckboxActive
                                        key={cat._id} cat={cat}
                                        setActiveCategories={setActiveCategories} />
                                )
                        }
                    </FormGroup>
            }
        </>
    )
};

export default ModalCategorySelect;