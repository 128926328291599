import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DateRange } from 'react-date-range';
import es from "date-fns/locale/es";
import { startOfDay, endOfDay, addWeeks } from 'date-fns';

import CouponCard from './CouponCard';
// import CouponModal from './CouponModal';
import {
    // couponOpenModal,
    couponList, couponSelectedDelete
} from '../../../actions/coupon';

import {
    Alert, Button, ToggleButton, Container, Stack,
    Snackbar, Grid, TextField, InputAdornment, Fab,
    Dialog, DialogTitle, DialogContent, Box, DialogActions,
    IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewListIcon from '@mui/icons-material/ViewList';

const today = new Date();
const dayStart = startOfDay(today);
const todayPlusWeek = addWeeks(endOfDay(today), 1);

const Coupon = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // lista de cupones
    const { coupons, action } = useSelector(state => state.coupon);

    // seleccionar cupones
    const [filteredCoupons, setFilteredCoupons] = useState([]);

    // seleccionar cupones
    const [list, setList] = useState(false);
    // _id's de cupones seleccionados
    const [selected, setSelected] = useState([]);
    // barra de búsqueda
    const [searchBar, setSearch] = useState('');
    const [snackbarStatus, setSnackbarStatus] = useState({
        open: false,
        message: 'Hola'
    });
    const { open, message } = snackbarStatus;

    // rango de fechas en <DateRange />
    const [range, setRange] = useState([
        {
            startDate: dayStart,
            endDate: todayPlusWeek,
            key: 'selection'
        }
    ]);
    // duración de promoción <DateRange />
    const [defaultRange, setDefaultRange] = useState({
        startDate: dayStart,
        endDate: todayPlusWeek,
    });
    // const { startDate, endDate } = defaultRange;
    // estado de diálogo
    const [dateDialog, setDateDialog] = useState(false);

    useEffect(() => {
        // cargar lista de cupones
        dispatch(couponList());
    }, []);

    useEffect(() => {
        const _search = searchBar.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        setFilteredCoupons(coupons.filter((e) =>
            e.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search)
        ));
    }, [coupons, searchBar]);

    // cupones seleccionados
    useEffect(() => {
        const sel = coupons.filter(e => e.selected).map(e => e._id);
        setSelected(sel);
    }, [coupons]);

    // mostrar snackbar con acción realizada
    useEffect(() => {
        switch (action) {
            case 'added':
                setSnackbarStatus({ open: true, message: 'Cupón agregado' });
                break;
            case 'updated':
                setSnackbarStatus({ open: true, message: 'Cupón actualizado' });
                break;
            case 'deleted':
                setSnackbarStatus({ open: true, message: 'Cupón eliminado' });
                break;
            case 'selectedDel':
                setSnackbarStatus({ open: true, message: 'Cupón(es) eliminado(s)' });
                break;
            default:
                break;
        }
    }, [action]);

    // cambios en barra de búsqueda
    const onChange = ({ target }) => {
        setSearch(target.value);
    };

    // abrir modal
    const handleOpen = () => {
        // dispatch(couponOpenModal());
        navigate('/nefro-admin/cupones/registrar');
    };
    // eliminar cupones seleccionados
    const deleteSelected = () => {
        dispatch(couponSelectedDelete(selected));
    };

    const handleClose = () => {
        setSnackbarStatus({ open: false, message: '' });
    };

    // cambio de fecha
    const filterByDate = () => {
        setDefaultRange({
            ...defaultRange,
            startDate: range[0].startDate, // inicio día
            endDate: endOfDay(range[0].endDate) // fin día
        });
        setDateDialog(false);
    };

    const snackbarAction = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={ handleClose }>
                OK
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={ handleClose }>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Container maxWidth='xl' sx={{ marginTop: '90px' }}>
            <Stack spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center" sx={{ mb: 4 }}>
                <TextField fullWidth
                    label="Buscar..."
                    variant="standard" size='small'
                    value={ searchBar }
                    onChange={ onChange }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <ToggleButton
                    value="check"
                    selected={list}
                    onChange={ () => {
                        setList(!list);
                    } }
                    size="small">
                    <ViewListIcon />
                </ToggleButton>
            </Stack>
            <Button variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={ deleteSelected }
                color='error'
                sx={{ display: selected.length > 0 ? 'flex' : 'none', mb: 2 }}>
                Eliminar seleccionados
            </Button>
            <Grid container spacing={4}>
                {
                    coupons.length <= 0 ?
                        <Grid item xs={12}>
                            <Alert severity="info">
                                No tiene cupones registrados
                            </Alert>
                        </Grid>
                    :
                        filteredCoupons.map(coupon => {
                            return (
                                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}
                                    key={coupon._id}>
                                    <CouponCard
                                        key={coupon._id}
                                        coupon={ coupon }
                                        list={ list }
                                    />
                                </Grid>
                            )
                        })
                }
            </Grid>

            <Fab color="primary"
                aria-label="add" sx={{
                    zIndex: 2,
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2)
                }}
                onClick={ handleOpen }>
                <AddIcon />
            </Fab>

            {/* <CouponModal setDateDialog={setDateDialog}
                startDate={startDate}
                endDate={endDate} /> */}
            
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                action={snackbarAction} />
            
            <Dialog
                open={dateDialog}
                onClose={() => { setDateDialog(false) }}>
                <DialogTitle>
                    Filtrar por fecha
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ border: '1px solid #e1e1e1', borderRadius: '3px' }}>
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => setRange([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={range}
                            locale={es}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDateDialog(false) }}>Cancelar</Button>
                    <Button onClick={filterByDate}>Seleccionar</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default Coupon;