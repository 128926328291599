import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import PaymentCheckoutComponent from "./Pago/PaymentCheckoutComponent";
import PreviewCheckoutComponent from "./Preview-checkout/PreviewCheckoutComponent";
import PersonalInfoCheckoutComponent from "./PersonalInfo-checkout/PersonalInfoCheckoutComponent";

import { CHECKOUT_BILLING, CHECKOUT_PAYMENT_ID, CHECKOUT_SHIPPING } from "../../../constants/constants";

//MUI
import {
    Alert, AlertTitle, Box, Button, Container,
    IconButton, Typography, useMediaQuery 
} from "@mui/material";

const multiStepTitle = ['Información personal', 'Información de pago', 'Resumen de compra'];

const CheckoutComponent = () => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width: 950px)');

    const [page, setPage] = useState(0);

    // datos de envío
    const [shippingValues, setShippingValues] = useState(CHECKOUT_SHIPPING);
    // datos de facturación
    const [billingValues, setBillingValues] = useState(CHECKOUT_BILLING);
    // datos de pago
    const [paymentValues, setPaymentValues] = useState(CHECKOUT_PAYMENT_ID);

    useEffect(() => {
        if (page === 0)
            setBillingValues({
                ...billingValues,
                tax_system: '', cfdi: ''
            });
    }, [page]);
    
    const pageDisplay = () => {
        if (page === 0) {
            return <PersonalInfoCheckoutComponent
                page={page}
                setPage={setPage}
                shippingValues={shippingValues}
                setShippingValues={setShippingValues}
                billingValues={billingValues}
                setBillingValues={setBillingValues}
                paymentValues={paymentValues}
                setPaymentValues={setPaymentValues} />
        }
        else if (page === 1) {
            return <PaymentCheckoutComponent
                page={page}
                setPage={setPage}
                shippingValues={shippingValues}
                billingValues={billingValues}
                paymentValues={paymentValues}
                setPaymentValues={setPaymentValues} />
        }
        else {
            return <PreviewCheckoutComponent
                shippingValues={shippingValues}
                billingValues={billingValues}
                paymentValues={paymentValues} />
        }
    }

    const {
        cart, // carrito
    } = useSelector(state => state.shop);
    const { item } = cart;

    useEffect(() => { // validar carrito
        // productos sin cantidad válida
        const validation = item.find(e => (['', '0', 0].includes(e.quantity)));
        if (validation)
            navigate('/carrito');
    }, [cart]);

    return (
        <>
            {
                item.length > 0
                    ?
                    <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18 }} className="last-view">
                        <div className="form-container">
                            <div className="header">
                                <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                    <Box sx={{ my: 'auto' }}>
                                        <IconButton
                                            color="primary"
                                            sx={{ mr: 1 }}
                                            hidden={page === 0}
                                            onClick={() => {
                                                setPage((currPage) => currPage - 1)
                                                window.scroll(0, 0)
                                            }}
                                        >
                                            <i className="fa-solid fa-left-long" style={{ fontSize: '2rem' }}></i>
                                        </IconButton>
                                    </Box>
                                    <Box sx={{ my: 'auto' }}>
                                        <Typography sx={{ textTransform: 'uppercase', fontWeight: 'light', fontSize: '28px' }}>
                                            {multiStepTitle[page]}
                                        </Typography>
                                    </Box>
                                </Box>
                            </div>
                            <div className="body">
                                {pageDisplay()}
                            </div>
                        </div>
                    </Container>
                :
                    <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18 }} className="last-view">
                        <Alert severity="info">
                            <AlertTitle>Revisa tu carrito</AlertTitle>
                            Parece que no deberías de estar aquí —<Button component={Link} to="/tienda">Regresar a la tienda</Button>
                        </Alert>
                    </Container>
            }
        </>
    );
};

export default CheckoutComponent;