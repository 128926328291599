const getProductPromotions = (promo, activePromotion, savedPromotion, variants) => {
    if (promo === 'ABC') {
        const { productA, productB, productC } = variants;
        const ActivePromotion_A = activePromotion.filter(({ variant_a, variant_b, variant_c }) => (
            [variant_a._id, variant_b?._id, variant_c?._id].includes(productA.variant_id)
        ));
        const SavedPromotion_A = savedPromotion.filter(({ variant_a, variant_b, variant_c }) => (
            [variant_a._id, variant_b?._id, variant_c?._id].includes(productA.variant_id)
        ));
        const ActivePromotion_B = activePromotion.filter(({ variant_a, variant_b, variant_c }) => (
            [variant_a._id, variant_b?._id, variant_c?._id].includes(productB.variant_id)
        ));
        const SavedPromotion_B = savedPromotion.filter(({ variant_a, variant_b, variant_c }) => (
            [variant_a._id, variant_b?._id, variant_c?._id].includes(productB.variant_id)
        ));
        const ActivePromotion_C = activePromotion.filter(({ variant_a, variant_b, variant_c }) => (
            [variant_a._id, variant_b?._id, variant_c?._id].includes(productC.variant_id)
        ));
        const SavedPromotion_C = savedPromotion.filter(({ variant_a, variant_b, variant_c }) => (
            [variant_a._id, variant_b?._id, variant_c?._id].includes(productC.variant_id)
        ));
        if (ActivePromotion_A.length > 0 || SavedPromotion_A.length > 0 ||
            ActivePromotion_B.length > 0 || SavedPromotion_B.length > 0 ||
            ActivePromotion_C.length > 0 || SavedPromotion_C.length > 0) {
            const countA = ActivePromotion_A.length + SavedPromotion_A.length;
            const countB = ActivePromotion_B.length + SavedPromotion_B.length;
            const countC = ActivePromotion_C.length + SavedPromotion_C.length;
            const _productA = `${productA.title} ${productA.variant_title}`.trim();
            const _productB = `${productB.title} ${productB.variant_title}`.trim();
            const _productC = `${productC.title} ${productC.variant_title}`.trim();
            const txtA = countA > 0 ? `<p>${_productA} ya cuenta con ${countA} ${countA === 1 ? 'promoción' : 'promociones'}</p>` : '';
            const txtB = countB > 0 ? `<p>${_productB} ya cuenta con ${countB} ${countB === 1 ? 'promoción' : 'promociones'}</p>` : '';
            const txtC = countC > 0 ? `<p>${_productC} ya cuenta con ${countC} ${countC === 1 ? 'promoción' : 'promociones'}</p>` : '';
            return {
                status: true,
                html: `${txtA}${txtB}${txtC}`
            };
        }
    } else if (['2x1', '4x3', 'Descuento%Producto', 'CompraX%Descuento'].includes(promo)) { // Descuento$Producto
        const { productA: { title, variant_title, variant_id } } = variants;
        // hay promoción activa con el producto A seleccionado
        const ActivePromotion = activePromotion.filter(({ variant_a, variant_b, variant_c }) => (
            [variant_a._id, variant_b?._id, variant_c?._id].includes(variant_id)
        ));
        // hay una promoción guardada con el producto A seleccionado
        const SavedPromotion = savedPromotion.filter(({ variant_a, variant_b, variant_c }) => (
            [variant_a._id, variant_b?._id, variant_c?._id].includes(variant_id)
        ));
        if (ActivePromotion.length > 0 || SavedPromotion.length > 0) {
            const countA = ActivePromotion.length + SavedPromotion.length;
            const _productA = `${title} ${variant_title}`.trim();
            const txtA = countA === 1 ? 'promoción' : 'promociones';
            return {
                status: true,
                html: `<p>${_productA} ya cuenta con ${countA} ${txtA}</p>`
            };
        }
    } else if (['Compra$Descuento', 'Compra%Descuento', 'CompraAB%Descuento'].includes(promo)) {
        const { productA, productB } = variants;
        const ActivePromotion_A = activePromotion.filter(({ variant_a, variant_b, variant_c }) => (
            [variant_a._id, variant_b?._id, variant_c?._id].includes(productA.variant_id)
        ));
        const SavedPromotion_A = savedPromotion.filter(({ variant_a, variant_b, variant_c }) => (
            [variant_a._id, variant_b?._id, variant_c?._id].includes(productA.variant_id)
        ));
        const ActivePromotion_B = activePromotion.filter(({ variant_a, variant_b, variant_c }) => (
            [variant_a._id, variant_b?._id, variant_c?._id].includes(productB.variant_id)
        ));
        const SavedPromotion_B = savedPromotion.filter(({ variant_a, variant_b, variant_c }) => (
            [variant_a._id, variant_b?._id, variant_c?._id].includes(productB.variant_id)
        ));
        if (ActivePromotion_A.length > 0 || SavedPromotion_A.length > 0 ||
            ActivePromotion_B.length > 0 || SavedPromotion_B.length > 0) {
            const countA = ActivePromotion_A.length + SavedPromotion_A.length;
            const countB = ActivePromotion_B.length + SavedPromotion_B.length;
            const _productA = `${productA.title} ${productA.variant_title}`.trim();
            const _productB = `${productB.title} ${productB.variant_title}`.trim();
            const txtA = countA > 0 ? `<p>${_productA} ya cuenta con ${countA} ${countA === 1 ? 'promoción' : 'promociones'}</p>` : '';
            const txtB = countB > 0 ? `<p>${_productB} ya cuenta con ${countB} ${countB === 1 ? 'promoción' : 'promociones'}</p>` : '';
            return {
                status: true,
                html: `${txtA}${txtB}`
            };
        }
    }
    return { status: false };
};

const getPromotionText = (currentPromotion, qty_data, discount_data, setPromoTitle) => {
    const {
        promo, pkg_qty, discount, variant_a, variant_b, variant_c
    } = currentPromotion;
    if (promo === '2x1')
        setPromoTitle(e => ({
            ...e,
            before: `2 productos ${variant_a.title} ${variant_a.variant_title} por el precio de 1`,
            after: `2 productos ${variant_a.title} ${variant_a.variant_title} por el precio de 1`
        }));
    else if (promo === '4x3')
        setPromoTitle(e => ({
            ...e,
            before: `4 productos ${variant_a.title} ${variant_a.variant_title} por el precio de 3`,
            after: `4 productos ${variant_a.title} ${variant_a.variant_title} por el precio de 3`
        }));
    else if (promo === 'ABC')
        setPromoTitle(e => ({
            ...e,
            before: `Compra ${variant_a.title} ${variant_a.variant_title} y ${variant_b.title} ${variant_b.variant_title} y llévate ${variant_c.title} ${variant_c.variant_title} gratis`,
            after: `Compra ${variant_a.title} ${variant_a.variant_title} y ${variant_b.title} ${variant_b.variant_title} y llévate ${variant_c.title} ${variant_c.variant_title} gratis`
        }));
    else if (promo === 'Descuento$Producto')
        setPromoTitle(e => ({
            ...e,
            before: `Descuento de $${discount} en ${variant_a.title} ${variant_a.variant_title}`.trim(),
            after: `Descuento de $${discount_data} en ${variant_a.title} ${variant_a.variant_title}`.trim()
        }));
    else if (promo === 'Descuento%Producto')
        setPromoTitle(e => ({
            ...e,
            before: `Descuento de ${discount}% en ${variant_a.title} ${variant_a.variant_title}`.trim(),
            after: `Descuento de ${discount_data}% en ${variant_a.title} ${variant_a.variant_title}`.trim()
        }));
    else if (promo === 'Compra$Descuento')
        setPromoTitle(e => ({
            ...e,
            before: `Descuento de $${discount} en ${variant_b.title} ${variant_b.variant_title} al comprar ${variant_a.title} ${variant_a.variant_title}`.trim(),
            after: `Descuento de $${discount_data} en ${variant_b.title} ${variant_b.variant_title} al comprar ${variant_a.title} ${variant_a.variant_title}`.trim()
        }));
    else if (promo === 'Compra%Descuento')
        setPromoTitle(e => ({
            ...e,
            before: `Descuento de ${discount}% en ${variant_b.title} ${variant_b.variant_title} al comprar ${variant_a.title} ${variant_a.variant_title}`.trim(),
            after: `Descuento de ${discount_data}% en ${variant_b.title} ${variant_b.variant_title} al comprar ${variant_a.title} ${variant_a.variant_title}`.trim()
        }));
    else if (promo === 'CompraAB%Descuento')
        setPromoTitle(e => ({
            ...e,
            before: `Descuento de ${discount}% en ${variant_a.title} ${variant_a.variant_title} y ${variant_b.title} ${variant_b.variant_title} al comprar ambos productos`,
            after: `Descuento de ${discount_data}% en ${variant_a.title} ${variant_a.variant_title} y ${variant_b.title} ${variant_b.variant_title} al comprar ambos productos`
        }));
    else if (promo === 'CompraX%Descuento')
        setPromoTitle(e => ({
            ...e,
            before: `Descuento de ${discount}% en ${variant_a.title} ${variant_a.variant_title} al comprar ${pkg_qty} piezas (${pkg_qty} pack)`,
            after: `Descuento de ${discount_data}% en ${variant_a.title} ${variant_a.variant_title} al comprar ${qty_data} piezas (${qty_data} pack)`
        }));
};

export {
    getProductPromotions,
    getPromotionText
};