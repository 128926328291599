import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { getPostalcodeData } from '../../../actions/checkout';
import { userUpdShippingAddressFormValidator } from '../../../validators/UserShippingAddressValidator';
import { delShippingAddress, myShippingAddresses, updShippingAddress } from '../../../actions/user-account';

//CSS
import './address.css'

//MUI
import {
    Typography, Breadcrumbs, TextField, InputLabel, MenuItem,
    FormControl, Select, InputAdornment, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Button,
    Snackbar, Box, Card, CardActions, CardContent,
    Container, Grid, useMediaQuery
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import SignpostIcon from '@mui/icons-material/Signpost';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// regex números
const regex = /^[0-9\b]+$/;

const addressInit = {
    _id: '',
    name: '',
    lastname: '',
    email: '',
    tel: '',
    street: '',
    ext_num: '',
    int_num: '',
    crossing_streets: '',
    postalcode: '',
    colony: '',
    city: '',
    state: '',
    references: ''
};

const disabledInit = {
    cityInput: false,
    stateInput: false
};

const AddressComponent = () => {
    const matches = useMediaQuery('(max-width: 950px)');

    // direcciones de envío del usuario
    const [addresses, setAddresses] = useState([]);
    const [activeAddress, setActiveAddress] = useState(addressInit);
    const { _id } = activeAddress;

    useEffect(() => {
        getShippingAdresses();
    }, []);

    // direcciones de envío del usuario
    const getShippingAdresses = async () => {
        const req = await myShippingAddresses();
        if (req.status)
            setAddresses(req.resp);
    };

    //Scrollto
    const testRef = useRef(null);
    const scrollToElement = () => testRef.current.scrollIntoView({ behavior: 'smooth' });

    //Ocultar o mostrar formulario
    const [showForm, setShowForm] = useState(false)
    const showAddress = (address_id) => (e) => {
        e.preventDefault();
        const found = addresses.find(e => e._id === address_id);
        if (found) {
            setActiveAddress(found);
            setShowForm(true);
            setTimeout(() => { scrollToElement() }, 1);
        }
    }

    //Oculta el formulario
    const closeForm = () => {
        setShowForm(false);
        window.scrollTo({
            top: 0,
            //behavior: 'smooth',
        })
    }

    //Dialog eliminar
    const [dialogDelete, setDialogDelete] = useState(false)
    const openDialogDelete = (address_id) => () => {
        const found = addresses.find(e => e._id === address_id);
        if (found) {
            setActiveAddress(found);
            setDialogDelete(true);
        }
    };
    const CloseDialogDelete = () => {
        setDialogDelete(false);
    };

    //Alert eliminar
    const [alertDelete, setalertDelete] = useState(false)
    const closeAlertDelete = () => {
        setalertDelete(false);
    }

    //Funcion cuando acepta editar los cambios en el dialog
    const openAlertDelete = async () => {
        setDialogDelete(false);
        const req = await delShippingAddress(_id);
        if (req.status) {
            closeForm();
            const filtered = addresses.filter(e => e._id !== _id);
            setAddresses([...filtered]);
            setActiveAddress(addressInit);
            setalertDelete(true);
        }
    }

    return (
        <>
            {/* Titulo y breadcrumb */}
            <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }}>
                <Typography variant='h4' sx={{ fontWeight: 'light' }} gutterBottom>Mis direcciones de envío</Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/mi-cuenta">
                        Mi cuenta
                    </Link>
                    <Typography color="text.primary">Direcciones de envío</Typography>
                </Breadcrumbs>
            </Container>
            {/* Fin titulo y breadcrumb */}

            {/* Contenido de direcciónes */}
            <Container maxWidth='xl' sx={{ mt: 5 }} className="last-view">
                {/* Row */}
                <Grid container spacing={3}>
                    {/* Dirección */}
                    {
                        addresses.length <= 0 ?
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                                <Box sx={{ position: 'relative', textAlign: 'center', p: 2 }}
                                    className="box-address">
                                    <Typography variant='h6' sx={{ fontWeight: 'light' }}>
                                        Por el momento no cuenta con ninguna dirección de envío
                                    </Typography>
                                </Box>
                            </Grid>
                            :
                            addresses.map(({ _id, name, street, ext_num, int_num, lastname,
                                colony, city, state, postalcode, tel }) =>
                                <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={_id}>
                                    <Card sx={{boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)'}}>
                                        <CardContent>
                                            <Typography variant='body1' sx={{ fontWeight: '600' }}>{name} {lastname}</Typography>
                                            <Typography variant='body1'>{`${street} #${ext_num} `}
                                                {int_num ? `interior ${int_num}` : ''}</Typography>
                                            <Typography variant='body1'>{colony}</Typography>
                                            <Typography variant='body1'>{`${city}, ${state} ${postalcode}`}</Typography>
                                            <Typography variant='body1'>Número de teléfono: {tel}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button onClick={showAddress(_id)}>
                                                Editar
                                            </Button>
                                            <Button onClick={openDialogDelete(_id)}>
                                                Eliminar
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                    }

                    {/* Fin dirección */}

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Link to={"/mi-cuenta/nueva-direccion-de-envio"} style={{ color: '#000', textDecoration: 'none' }}>
                            <Box sx={{ position: 'relative', textAlign: 'center', p: 2 }}
                                className="box-address">
                                <Typography variant='h6' sx={{ fontWeight: '600' }}>
                                    <AddIcon /> <br /> Agregar dirección
                                </Typography>
                            </Box>
                        </Link>
                    </Grid>

                </Grid>
                {/* Fin row */}

                {/* Referencia para scroll */}
                <div ref={testRef}></div>
            </Container>
            {/* Fin contenido de direcciones */}

            {/* Formulario para editar o agregar direcciones */}
            <Container maxWidth='xl' className="last-view">
                {
                    showForm &&
                    <AddressForm hide={() => closeForm()}
                        addresses={addresses}
                        setAddresses={setAddresses}
                        activeAddress={activeAddress}
                        setActiveAddress={setActiveAddress} />
                }
            </Container>
            {/* fin formulario para editar o agregar direcciones */}

            {/* Dialog */}
            <Dialog
                open={dialogDelete}
                onClose={CloseDialogDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Está seguro que desea eliminar esta dirección?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Esta dirección no puede recuperarse. Sin embargo, podrá volver a ingresar la información en otra dirección
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={CloseDialogDelete}>Cancelar</Button>
                    <Button onClick={openAlertDelete}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={alertDelete} autoHideDuration={2500} onClose={closeAlertDelete}>
                <Alert onClose={closeAlertDelete} severity="error" sx={{ width: '100%' }}>
                    Se ha eliminado correctamente
                </Alert>
            </Snackbar>
        </>
    )
}

const AddressForm = ({ hide, addresses, setAddresses, activeAddress, setActiveAddress }) => {
    const { _id, name, lastname, tel, street, ext_num,
        int_num, crossing_streets, postalcode,
        colony, city, state, references } = activeAddress;

    // información recuperada con el código postal
    // colonias disponibles para campo de envío
    const [colonies, setColonies] = useState([]);

    // deshabilitación de campos
    const [disabledFields, setDisabledFields] = useState(disabledInit);
    const { cityInput, stateInput } = disabledFields;

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        // propiedades con únicamente números
        if ((['ext_num', 'tel', 'postalcode'].includes(name))) {
            if (value === '' || regex.test(value))
                setActiveAddress({ ...activeAddress, [name]: value });
        } else
            setActiveAddress({ ...activeAddress, [name]: value });
    };

    // campo pierde foco
    const handleInputBlur = async ({ target }) => {
        // busca información del CP ingresado
        if (target.name === 'postalcode') {
            const resp = await getPostalcodeData(postalcode);
            if (resp.status) {
                const { resp: { municipio, estado, asentamiento } } = resp;
                setActiveAddress({
                    ...activeAddress,
                    colony: '',
                    city: municipio,
                    state: estado
                });
                setColonies(asentamiento);
                // deshabilita campos
                setDisabledFields({
                    ...disabledFields,
                    cityInput: true, stateInput: true
                });
            } else {
                // limpia colonias disponibles
                setColonies([]);
                // habilita campos
                setDisabledFields({
                    ...disabledFields,
                    cityInput: false, stateInput: false
                });
            }
        }
    };

    //Dialog
    const [open, setOpen] = useState(false);

    const handleClickOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    //Alert
    const [alert, setalert] = useState(false)
    const handleCloseAlert = () => {
        setalert(false);
    }

    //Funcion cuando acepta editar los cambios enel dialog
    const handleEditAddress = async () => {
        setOpen(false);
        if (userUpdShippingAddressFormValidator(activeAddress)) {
            const req = await updShippingAddress(activeAddress);
            if (req.status) {
                const _map = addresses.map(
                    e => (e._id === _id) ? ({ ...e, ...activeAddress }) : e
                );
                setAddresses([..._map]);
                setActiveAddress(addressInit);
                setalert(true);
                hide();
            }
        }
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <Box sx={{ my: 'auto' }}>
                    <Typography variant='h5'>Editar dirección</Typography>
                </Box>

                <Box sx={{ my: 'auto' }}>
                    <Link to={""} onClick={hide} style={{ fontSize: '25px', color: 'red' }}>
                        <i className="fa-solid fa-xmark"></i>
                    </Link>
                </Box>
            </Box>
            {/* Form */}
            <form action="">
                {/* Row */}
                <Grid container spacing={2}>
                    {/* Nombres */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField id="" label="Nombre(s)" variant="standard"
                                name="name"
                                value={name}
                                inputProps={{ maxLength: 60 }}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fa-solid fa-user"></i>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Apellidos */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField id="" label="Apellidos" variant="standard"
                                name="lastname"
                                value={lastname}
                                inputProps={{ maxLength: 60 }}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fa-solid fa-user-group"></i>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Dirección */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField id="" label="Dirección" variant="standard"
                                name="street"
                                value={street}
                                inputProps={{ maxLength: 100 }}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fa-solid fa-location-dot"></i>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Número exterior */}
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <FormControl fullWidth>
                            <TextField id="" label="Número exterior" variant="standard"
                                name="ext_num"
                                value={ext_num}
                                inputProps={{ maxLength: 4 }}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fa-solid fa-hashtag"></i>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Número interior */}
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <FormControl fullWidth>
                            <TextField id="" label="Número interior" variant="standard"
                                name="int_num"
                                value={int_num}
                                inputProps={{ maxLength: 8 }}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fa-solid fa-hashtag"></i>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Cruce de calles */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField id="" label="Entre las calles" variant="standard"
                                name="crossing_streets"
                                value={crossing_streets}
                                inputProps={{ maxLength: 200 }}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fa-solid fa-road"></i>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Código postal */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField id="" label="Código postal" variant="standard"
                                name="postalcode"
                                value={postalcode}
                                inputProps={{ maxLength: 5 }}
                                onChange={handleInputChange}
                                onBlur={handleInputBlur}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MarkunreadMailboxIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Municipio */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField id="" label="Municipio" variant="standard"
                                name="city"
                                value={city}
                                disabled={cityInput}
                                inputProps={{ maxLength: 100 }}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* Colonia */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        {
                            colonies.length <= 0 ?
                                <FormControl fullWidth>
                                    <TextField id="" label="Colonia" variant="standard"
                                        name="colony"
                                        value={colony}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                                :
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel>Colonia</InputLabel>
                                    <Select
                                        id=""
                                        name="colony"
                                        value={colony}
                                        onChange={handleInputChange}
                                        label="Colonia">
                                        {
                                            colonies.map(({ nombre }) =>
                                                <MenuItem key={nombre}
                                                    value={nombre}>{nombre}
                                                </MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                        }
                    </Grid>

                    {/* Estado */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField id="" label="Estado" variant="standard"
                                name="state"
                                value={state}
                                disabled={stateInput}
                                inputProps={{ maxLength: 100 }}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* Teléfono */}
                    <Grid item xl={6} ld={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField id="" label="Teléfono" variant="standard"
                                name="tel"
                                value={tel}
                                inputProps={{ maxLength: 10 }}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fa-solid fa-phone"></i>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Referencias */}
                    <Grid item xl={6} ld={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField id="" label="Referencias" variant="standard"
                                name="references"
                                value={references}
                                inputProps={{ maxLength: 200 }}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SignpostIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {/* Fin row */}
            </form>
            {/* Fin form */}
            {/* button editar dirección */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2.5 }}>
                <Button variant='contained'
                    onClick={handleClickOpenDialog}
                >
                    Guardar cambios</Button>
            </Box>
            {/* Fin button editar dirección */}

            {/* Dialog */}
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Está seguro que desea guardar los cambios?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Su dirección de envío será actualizada. Puede utilizar sus direcciones de envío para agilizar sus compras en la tienda de Nefrópolis
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancelar</Button>
                    <Button onClick={handleEditAddress}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={alert} autoHideDuration={2500} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
                    Se han guardado los cambios
                </Alert>
            </Snackbar>
        </>
    )
}

export default AddressComponent