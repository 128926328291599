import React, { useState, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import CSFDialog from "./CSFDialog";
import CheckoutBillingAdress from "./CheckoutBillingAddress";

import { regex_num } from '../../../../constants/regex';

import { basicMsg } from "../../../../actions/swal_msg";

import {
    Box, Grid, Typography, Button, Switch,
    Select, Avatar, MenuItem, TextField, InputLabel,
    FormControl, InputAdornment, Stack, IconButton
} from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import DomainIcon from '@mui/icons-material/Domain';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

const label = { inputProps: { 'aria-label': 'checkout-billing-switch' } };

const CheckoutBilling = ({
    billingValues, setBillingValues, billingAddresses,
    disabledFields, billingColonies, setBillingColonies,
    handleInputBlur
}) => {
    const {
        req_invoice, business_name, rfc, cif, filename,
        tax_system, cfdi, billing_tel, address, billing_postalcode,
        billing_colony, billing_city, billing_state
    } = billingValues;
    const { billCityInput, billStateInput } = disabledFields;

    // régimen fiscal y CFDI disponible
    const { taxSystemData, CFDIData } = useSelector(state => state.checkout);

    const fileInputRef = useRef();

    // régimen fiscal filtrado
    const [taxSystem, setTaxSystem] = useState([]);
    // CFDI filtrado
    const [CFDI, setCFDI] = useState([]);

    // diálogo de CSF
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (cif !== '')
            fileInputRef.current.value = "";
    }, [cif]);

    const handleClickOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    // cargar CIF
    const loadCIF = ({ target }) => {
        const files = target.files;
        if (files.length > 0) {
            if (['application/pdf', 'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0].type))
                setBillingValues({
                    ...billingValues,
                    cif: files[0],
                    filename: ''
                });
            else basicMsg('Formato no soportado');
        }
    };

    // cambios en datos de facturación
    const billingInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        // propiedades con únicamente números
        if ((['billing_tel', 'billing_postalcode'].includes(name))) {
            if (value === '' || regex_num.test(value))
                setBillingValues({ ...billingValues, [name]: value });
        } else if (name === 'req_invoice') { // requiere factura?
            setBillingValues({
                ...billingValues,
                tax_system: '', cfdi: '',
                [name]: value
            });
        } else if (name === 'rfc') {
            setBillingValues({
                ...billingValues,
                tax_system: '', cfdi: '',
                [name]: value
            });
            if (value.length === 12) { // persona moral
                const filter1 = taxSystemData.filter(e => e.moral === 'Sí');
                const filter2 = CFDIData.filter(e => e.moral === 'Sí');
                setTaxSystem(filter1);
                setCFDI(filter2);
            } else if (value.length === 13) { // persona física
                const filter1 = taxSystemData.filter(e => e.fisica === 'Sí');
                const filter2 = CFDIData.filter(e => e.fisica === 'Sí');
                setTaxSystem(filter1);
                setCFDI(filter2);
            } else {
                setTaxSystem([]);
                setCFDI([]);
            }
        } else
            setBillingValues({ ...billingValues, [name]: value });
    };

    return (
        <Box sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Switch
                    { ...label }
                    checked={ req_invoice }
                    onChange={() => setBillingValues({
                        ...billingValues, req_invoice: !req_invoice
                    })}
                />
                <Box sx={{ my: 'auto' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                        ¿Requiere factura?
                    </Typography>
                </Box>
            </Box>

            {
                req_invoice &&
                <>
                    <CheckoutBillingAdress
                        billingAddresses={ billingAddresses }
                        billingValues={ billingValues }
                        setBillingValues= { setBillingValues }
                        setTaxSystem={ setTaxSystem }
                        setCFDI={ setCFDI }
                        setBillingColonies={ setBillingColonies }
                    />
                    <Box sx={{ mt: 3, p: 3 }} className="personal-info-form">
                        <Box sx={{ mb: 5 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Avatar sx={{ bgcolor: '#2699FB' }}>2</Avatar>
                            </Box>
                            <Typography variant='h6' sx={{ fontWeight: '600', mt: .5 }} align='center'>
                                Datos de facturación
                            </Typography>
                        </Box>
                        <Grid container spacing={3}>
                            {/* Razón social */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id=""
                                        label="Razón social"
                                        inputProps={{ maxLength: 200 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <DomainIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        name="business_name"
                                        value={business_name}
                                        onChange={billingInputChange}
                                    />
                                </FormControl>
                            </Grid>

                            {/* RFC */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id=""
                                        label="RFC"
                                        inputProps={{ maxLength: 13 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-file"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        name="rfc"
                                        value={rfc}
                                        onChange={billingInputChange}
                                    />
                                </FormControl>
                            </Grid>

                            {/* CIF */}
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <Button variant="contained" component="label">
                                        Subir archivo CSF
                                        <input hidden type="file"
                                            ref={ fileInputRef }
                                            accept="application/pdf, application/msword,
                                            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            onChange={ loadCIF } />
                                    </Button>
                                    <IconButton aria-label="delete" size="large"
                                        onClick={ handleClickOpen }>
                                        <HelpIcon fontSize="inherit" />
                                    </IconButton>
                                </Stack>
                                
                                {
                                    cif !== '' &&
                                        <Typography variant="overline" display="block" gutterBottom>
                                            { filename ? filename : cif.name }
                                        </Typography>
                                }
                                
                                <CSFDialog
                                    open={ dialogOpen }
                                    onClose={ handleDialogClose }
                                    inputRef={ fileInputRef }
                                    loadCIF={ loadCIF }
                                />
                            </Grid>

                            {/* Regimen social */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Régimen social</InputLabel>
                                    <Select
                                        id=""
                                        name="tax_system"
                                        value={tax_system}
                                        label="Régimen social"
                                        onChange={billingInputChange}
                                        variant="standard">
                                        {
                                            taxSystem.map(({ codigo, descripcion }) =>
                                                <MenuItem key={codigo}
                                                    value={codigo}>
                                                    {`${codigo} - ${descripcion}`}
                                                </MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* CFDI */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>CFDI</InputLabel>
                                    <Select
                                        id=""
                                        name="cfdi"
                                        value={cfdi}
                                        label="CFDI"
                                        onChange={billingInputChange}
                                        variant="standard">
                                        {
                                            CFDI.map(({ codigo, descripcion }) =>
                                                <MenuItem key={codigo}
                                                    value={codigo}>
                                                    {`${codigo} - ${descripcion}`}
                                                </MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Número de telefono */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id=""
                                        label="Número telefónico"
                                        inputProps={{ maxLength: 15 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-phone"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        name="billing_tel"
                                        value={billing_tel}
                                        onChange={billingInputChange}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Dirección */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id=""
                                        label="Dirección"
                                        inputProps={{ maxLength: 100 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-house"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        name="address"
                                        value={address}
                                        onChange={billingInputChange}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Código postal */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id=""
                                        label="Código postal"
                                        inputProps={{ maxLength: 5 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MarkunreadMailboxIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        name="billing_postalcode"
                                        value={billing_postalcode}
                                        onChange={billingInputChange}
                                        onBlur={handleInputBlur}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Colonia */}
                            {
                                billingColonies.length === 0 ?
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Colonia"
                                                inputProps={{ maxLength: 100 }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LocationCityIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="standard"
                                                name="billing_colony"
                                                value={billing_colony}
                                                onChange={billingInputChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>Colonia</InputLabel>
                                            <Select
                                                variant="standard"
                                                name="billing_colony"
                                                value={billing_colony}
                                                label="Colonia"
                                                onChange={billingInputChange}>
                                                {
                                                    billingColonies.map(({ nombre }) =>
                                                        <MenuItem key={nombre}
                                                            value={nombre}>{nombre}
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                            }

                            {/* Municipio */}
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id=""
                                        label="Municipio"
                                        inputProps={{ maxLength: 100 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <DomainIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        disabled={billCityInput}
                                        name="billing_city"
                                        value={billing_city}
                                        onChange={billingInputChange}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Estado */}
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id=""
                                        label="Estado"
                                        inputProps={{ maxLength: 100 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ApartmentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        disabled={billStateInput}
                                        name="billing_state"
                                        value={billing_state}
                                        onChange={billingInputChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            }
        </Box>
    );
};

export default CheckoutBilling;