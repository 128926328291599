import React from 'react';

//MUI
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

const FilterCategory = ({ handleChangeChecked, cat }) => {

    const { checked, category, _id } = cat;

    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        size='small'
                        checked={checked}
                        onChange={() => handleChangeChecked(_id)}
                    />
                }
                label={category}
            />
        </FormGroup>
    );
};

export default FilterCategory;