import React from 'react'

import './landingpages.css'

import img_1 from '../../assets/resources/Landing-pages/productos_membresia.jpg'
import img_2 from '../../assets/resources/Landing-pages/educación.jpg'
import img_3 from '../../assets/resources/Landing-pages/servicios_membresias.jpg'
import open_pay from '../../assets/resources/Landing-pages/openpay.png'
import bbva from '../../assets/resources/Landing-pages/BBVA_logo.png'

//MUI
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material'

const memberships = [
    {
        id: 1,
        name: 'Plata',
        value: 39826,
        price: 15588,
        monthly: 1299,
        food: [
            {
                value: '1 Bebida de arroz baja proteína 600gr'
            },
            {
                value: '1 Galleta de avena'
            },
            {
                value: '1 Sala se chile morrón'
            },
        ],
        supplements: [
            {
                value: '1 Multivitamínico'
            },
            {
                value: '6 Simbin RNL 120 caps'
            }
        ],
        consultas: [
            {
                value: '7 Consultas de nutrición'
            },
            {
                value: '6 Consultas terapia psicológica'
            }
        ],
        education: [
            {
                value: 'Taller mensual de alimentos'
            },
            {
                value: 'Taller mensual educativo: "Entendiendo más síntomas"'
            },
            {
                value: 'Material educativo digital'
            }
        ]
    },
    {
        id: 2,
        name: 'Oro',
        value: 47142,
        price: 21588,
        monthly: 1799,
        food: [
            {
                value: '6 Bebida de arroz baja proteína 600gr'
            },
            {
                value: '12 Galleta de avena'
            },
            {
                value: '8 Sazonadores'
            },
        ],
        supplements: [
            {
                value: '6 Simbin RNL 120 caps'
            },
            {
                value: '3 Inulina de agave'
            },
            {
                value: '3 Malteada nutritiva NIN 1.2kg'
            }
        ],
        consultas: [
            {
                value: '7 Consultas de nutrición'
            },
            {
                value: '6 Consultas terapia psicológica'
            }
        ],
        education: [
            {
                value: 'Taller mensual de alimentos'
            },
            {
                value: 'Taller mensual educativo: "Entendiendo más síntomas"'
            },
            {
                value: 'Material educativo impreso y digital (recetarios, manuales, semáforos)'
            }
        ]
    },
    {
        id: 1,
        name: 'Platino',
        value: 58534,
        price: 29988,
        monthly: 2499,
        food: [
            {
                value: '6 Bebida de arroz baja proteína 600gr'
            },
            {
                value: '12 Galleta de avena'
            },
            {
                value: '12 Sala se chile morrón'
            },
            {
                value: '8 Sazonadores'
            },
        ],
        supplements: [
            {
                value: '6 Multivitamínico'
            },
            {
                value: '6 Simbin RNL 120 caps'
            },
            {
                value: '6 Inulina de agave'
            },
            {
                value: '6 Malteada nutritiva NIN 1.2kg'
            }
        ],
        consultas: [
            {
                value: '7 Consultas de nutrición'
            },
            {
                value: '6 Consultas terapia psicológica'
            }
        ],
        education: [
            {
                value: 'Taller mensual de alimentos'
            },
            {
                value: 'Taller mensual educativo'
            },
            {
                value: 'Material educativo impreso y digital'
            }
        ]
    }
]

const Membresias = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    return (
        <>
            {/* Header */}
            <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18 }}>
                <Typography align='center' sx={{ fontWeight: '600', textTransform: 'uppercase', fontSize: '26px' }} paragraph>
                    Membresías Nefrópolis prime
                </Typography>
            </Container>

            <Container maxWidth="xl" sx={{ mt: 3 }} className='last-view'>
                <Typography variant='body1' align='center' sx={{ fontSize: '18px' }} paragraph>
                    <b>¿Te gustaría obtener un servicio completo a mitad de precio? </b><br></br>
                    Adquiere tu membresía anual y ahorra hasta 60% en servicios y productos Nefrópolis.
                </Typography>
                <Box sx={{ mb: 5 }}>
                    <Typography variant='body1'>
                        <b>Perfil: </b>Pacientes con Enfermedad Renal Crónica en <b>Etapa Pre Diálisis</b>
                    </Typography>
                </Box>
                <Grid container spacing={4}>
                    {
                        memberships.map((item) => (
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={item.id}>
                                <Box sx={{ boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)', borderRadius: '5px' }}>
                                    <Box sx={{ background: '#1CA29B', p: 2, borderRadius: '5px 5px 0 0' }}>
                                        <Typography variant='body1'
                                            sx={{ fontSize: '22px', color: '#fff', textTransform: 'uppercase', fontWeight: '600' }}
                                            align='center'
                                        >
                                            {item.name}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ p: 3, height: '540px' }} className='card_memberships'>
                                        <Box sx={{ mb: 1 }}>
                                            <Typography variant='body1' sx={{ fontWeight: '600' }}>Alimentos de uso diario:</Typography>
                                            {
                                                item.food.map((res, index) => (
                                                    <Typography variant='body1' key={index}>{res.value}</Typography>
                                                ))
                                            }
                                        </Box>
                                        <Box sx={{ mb: 1 }}>
                                            <Typography variant='body1' sx={{ fontWeight: '600' }}>Suplementos</Typography>
                                            {
                                                item.supplements.map((res, index) => (
                                                    <Typography variant='body1' key={index}>{res.value}</Typography>
                                                ))
                                            }
                                        </Box>
                                        <Box sx={{ mb: 1 }}>
                                            <Typography variant='body1' sx={{ fontWeight: '600' }}>Consultas</Typography>
                                            {
                                                item.consultas.map((res, index) => (
                                                    <Typography variant='body1' key={index}>{res.value}</Typography>
                                                ))
                                            }
                                        </Box>
                                        <Box sx={{ mb: 3 }}>
                                            <Typography variant='body1' sx={{ fontWeight: '600' }}>Educación</Typography>
                                            {
                                                item.education.map((res, index) => (
                                                    <Typography variant='body1' key={index}>{res.value}</Typography>
                                                ))
                                            }
                                        </Box>
                                        <Typography variant='h6' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }} align='center'>
                                            Valor total: <span style={{ color: '#F05048' }}>$</span>
                                            <del style={{ color: '#F05048' }}>
                                                {new Intl.NumberFormat('en-US').format(item.value)}
                                            </del>
                                        </Typography>
                                    </Box>
                                    <Box sx={{ background: '#5552B2', p: 1, borderRadius: '0 0 5px 5px' }}>
                                        <Typography variant='body1'
                                            sx={{ fontSize: '20px', textTransform: 'uppercase', color: '#fff' }}
                                            align='center'
                                        >
                                            <span style={{ color: '#FFC000', fontWeight: '600' }}>Oferta: </span>
                                            ${new Intl.NumberFormat('en-US').format(item.price)}
                                        </Typography>
                                        <Typography variant='body1'
                                            sx={{ fontSize: '20px', color: '#fff' }}
                                            align='center'
                                        >
                                            ${new Intl.NumberFormat('en-US').format(item.monthly)} / Mes
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
                <Box sx={{ mt: 5 }}>
                    <Typography variant='body1' paragraph align='center'>Realiza tu pago a meses sin intereses durante 12 meses.</Typography>
                    <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                        <Grid item xl={6}>
                            <img src={open_pay} alt={open_pay} width='50%' />
                        </Grid>
                        <Grid item xl={6}>
                            <img src={bbva} alt={bbva} width='50%' />
                        </Grid>
                    </Grid>

                    {/* Productos */}
                    <Box sx={{ mt: 5 }}>
                        <Grid container spacing={4}>
                            <Grid item xl={8} sx={{ my: 'auto' }}>
                                <Typography variant='h6' gutterBottom sx={{ fontWeight: '600' }}>Productos:</Typography>
                                <Typography variant='body1'>
                                    Adquiere una selección de los mejores productos NIN (Suplementos, Alimentos
                                    de uso diario, probióticos y recetarios) diseñados espeficamente para el cuidado
                                    renal en etapa pre diálisis.
                                </Typography>
                            </Grid>
                            <Grid item xl={4} sx={{ my: 'auto' }}>
                                <img src={img_1} alt={img_1} width='100%' style={{ borderRadius: '8px' }} />
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Servicios */}
                    <Box sx={{ mt: 5 }}>
                        <Grid container spacing={4}>
                            <Grid item xl={4} sx={{ my: 'auto' }}>
                                <img src={img_3} alt={img_3} width='100%' style={{ borderRadius: '8px' }} />
                            </Grid>
                            <Grid item xl={8} sx={{ my: 'auto' }}>
                                <Typography variant='h6' sx={{ fontWeight: '600' }}>Servicios:</Typography>
                                <ul className='list_memberships'>
                                    <li>
                                        <b>Consulta virtual de Nutrición Renal</b> con especialistas en la enfermedad: Obtén
                                        orientación y asesoramiento profesional para tener una dieta saludable. Incluye una
                                        evaluación nutricional y un plan de alimentación personalizado.
                                    </li>
                                    <li>
                                        <b>Servicio Terapia Psicológica:</b> En alianza con Instituto Xalisco, te ofrecemos el
                                        servicio de consultas psicológicas virtuales con terapeutas capacitados en la Enfermedad
                                        Renal.
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Educación */}
                    <Box sx={{ mt: 5 }}>
                        <Grid container spacing={4}>
                            <Grid item xl={8} sx={{ my: 'auto' }}>
                                <Typography variant='h6' gutterBottom sx={{ fontWeight: '600' }}>Educación:</Typography>
                                <Typography variant='body1'>
                                    Te acompañamos con herramientas y conocimientos sobre la Enfermedad Renal mediante nuestros
                                    talleres privados:
                                </Typography>
                                <ul className='list_memberships'>
                                    <li>
                                        <b>Club de Cocina: Cocinando con NR</b> donde prepararemos recetas exclusivas para
                                        el paciente con ERC en etapa prediálisis.
                                    </li>
                                    <li>
                                        <b>NefroClub “Entendiendo mis síntomas”:</b> Aprende todo sobre la Enfermedad Renal de una
                                        forma amena y educativa (causas, síntomas y complicaciones).
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xl={4} sx={{ my: 'auto' }}>
                                <img src={img_2} alt={img_2} width='100%' style={{ borderRadius: '8px' }} />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default Membresias