import React from 'react';

import {
    Grid, List, ListItem, Typography, ListItemText, ListItemButton,
    Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SubcategorySidebar = ({ subcategory, setSelectedSubcategory }) => {
    //Acordion
    const [expanded, setExpanded] = React.useState('panel1');
    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    // selección de subcategoría
    const subcategorySelected = e => () => {
        setSelectedSubcategory(e);
    };

    return (
        <Grid item xl={2} lg={3} md={12} sm={12} xs={12}>
            <Accordion expanded={ expanded === 'panel1' }
                onChange={ handleChangeAccordion('panel1') }>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Subcategorías</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0' }}>
                    <List>
                        {
                            subcategory.map(e =>
                                <ListItem disablePadding key={e}
                                    onClick={ subcategorySelected(e) }>
                                    <ListItemButton component="a">
                                        <ListItemText secondary={e} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                            <ListItem disablePadding
                                onClick={ subcategorySelected('VER TODO') }>
                                <ListItemButton component="a">
                                    <ListItemText secondary="VER TODO" />
                                </ListItemButton>
                            </ListItem>
                    </List>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};

export default SubcategorySidebar;