import { types } from "../../types/types";
import { basicMsg, htmlMsg } from "../swal_msg";
import { protectedReq } from "../../helpers/requestHelper";

export const loadDoctors = () => {
    // cargar lista de médicos en admin
    return async(dispatch) => {
        try {
            const resp = await protectedReq('doctor/list');
            const body = await resp.json();
            if (body.status)
                dispatch(doctorsLoaded(body.resp));
        } catch (err) {}
    }
};

const doctorsLoaded = (payload) => ({
    type: types.doctorsLoaded,
    payload
});

// registrar médico
export const saveDoctor = async (model) => {
    try {
        const resp = await protectedReq('doctor/save', model, 'POST');
        const body = await resp.json();
        if (!body.status) {
            const { err } = body;
            if (body.msg === 'miss')
                basicMsg('Faltan datos del especialista');
            else if (body.msg === 'server-err')
                basicMsg('Ocurrió un incidente');
            else if (body.msg === 'sql-err')
                htmlMsg('error', 'Médico',
                `<p>No se pudo registrar el médico</p><h6>Error: ${err.sqlMessage}</h6>`);
        }
        return body;
    } catch (err) {
        htmlMsg('error', 'Médico',
        `<p>No se pudo registrar el médico</p><h6>Error: ${err}</h6>`);
        return { status: false };
    };
};

// actualizar médico
export const updDoctor = (model) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('doctor/update', model, 'PUT');
            const body = await resp.json();
            if (!body.status) {
                const { msg } = body;
                if (msg === 'miss')
                    alert('Faltan datos del especialista');
                else
                    alert(msg);
            } else {
                const {
                    name, lastname, email, phone, ced, speciality,
                    sub_speciality, hospital, hospital_type,
                    state, mun, colony, idMedicoEspecialista, idEspecialidad,
                    idSubEspecialidad, idAsentamiento, idHospital, idMunicipio,
                    idEntidadFederativa, idTipoHospital
                } = model;
                const doctor = {
                    name: {
                        firstName: name,
                        lastName: lastname
                    },
                    email: email,
                    speciality,
                    subEspecialidad: sub_speciality,
                    phone,
                    cedulaProfesional: ced,
                    idMedicoEspecialista,
                    idEspecialidad,
                    idSubEspecialidad,
                    idAsentamiento,
                    idMunicipio,
                    idEntidadFederativa,
                    idHospital,
                    idTipoHospital,
                    municipio: mun,
                    estado: state,
                    hospital,
                    tipoHospital: hospital_type,
                    asentamiento: colony,
                    img: []
                };
                dispatch(doctorUpdated(doctor));
                alert('Actualizado');
            }
        } catch (err) {
            htmlMsg('error', 'Médico',
            `<p>No se pudo actualizar el médico</p><h6>Error: ${err}</h6>`);
        };
    };
};

const doctorUpdated = (payload) => ({
    type: types.doctorUpdated,
    payload
});

// eliminar médico
export const deleteDoctor = (row) => {
    return async(dispatch) => {
        try {
            const {
                idMedicoEspecialista, idEspecialidad, idSubEspecialidad, idAsentamiento,
                idMunicipio, idEntidadFederativa, idHospital, idTipoHospital
            } = row;
            const model = {
                idMedicoEspecialista, idEspecialidad, idSubEspecialidad, idAsentamiento,
                idMunicipio, idEntidadFederativa, idHospital, idTipoHospital
            };
            const resp = await protectedReq('doctor/delete', model, 'DELETE');
            const body = await resp.json();
            if (!body.status) {
                const { msg } = body;
                if (msg === 'miss')
                    alert('Faltan datos del especialista');
                else
                    alert(msg);
            } else
                dispatch(doctorDeleted(model));
        } catch (err) {
            htmlMsg('error', 'Médico',
            `<p>No se pudo eliminar el médico</p><h6>Error: ${err}</h6>`);
        };
    };
};

const doctorDeleted = (payload) => ({
    type: types.doctorDeleted,
    payload
});