import { basicMsg } from "../../../../actions/swal_msg";
import { regex_dec, regex_num } from "../../../../constants/regex";

// CouponSave
export const CouponHandleInputChange = ({ name, value }, coupon, setInputError) => {
    const { name: c_name, discount, restrictions, purchase_requirements } = coupon;
    const { min_purchase_amount, minimum_items_quantity } = purchase_requirements;
    const { type } = discount;

    const _name = c_name.trim().length;

    // establece errores en los campos
    setInputError(e => ({
        ...e,
        name_err: (_name < 3 || _name > 30)
            ? true : false
    }));

    if (name === 'discount') {
        if (value.trim() === '' || regex_dec.test(value))
            return { status: true, coupon: { ...coupon, [name]: value } };
    } else if (['min_purchase_amount', 'minimum_items_qty'].includes(name)) {
        if (value === '' || regex_num.test(value))
            return { status: true, coupon: {
                ...coupon,
                purchase_requirements: { ...purchase_requirements, [name]: value }
            }};
    } else if (name === 'type') {
            return { status: true, coupon: {
                ...coupon,
                discount: { ...discount, [name]: value }
            }};
    } else if (name === '_value') {
        if 
            (value === '' ||
            (type === 'percentage' && regex_num.test(value)) ||
            (type === 'fixed' && regex_dec.test(value))
        )
            return { status: true, coupon: {
                ...coupon,
                discount: { ...discount, [name]: value }
            }};
    } else if (name === 'amount') {
        if  (value === '' || regex_dec.test(value))
            return { status: true, coupon: {
                ...coupon,
                purchase_requirements: {
                    ...purchase_requirements,
                    min_purchase_amount: {
                        ...min_purchase_amount,
                        amount: value
                    }
                }
            }};
    } else if (name === 'qty') {
        if  (value === '' || regex_num.test(value))
            return { status: true, coupon: {
                ...coupon,
                purchase_requirements: {
                    ...purchase_requirements,
                    minimum_items_quantity: {
                        ...minimum_items_quantity,
                        qty: value
                    }
                }
            }};
    } else if (name === 'quantity') {
        if  (value === '' || regex_num.test(value))
            return { status: true, coupon: {
                ...coupon,
                restrictions: {
                    ...restrictions,
                    quantity: value
                }
            }};
    } else if (name === 'limited') {

    } else return { status: true, coupon: { ...coupon, [name]: value } };
    return { status: false, coupon };
};

// CouponSave
export const CouponHandleSwitchChange = ({ name, checked }, coupon) => {
    const { use_method } = coupon;
    if (name === 'min_purchase_amount')
        return { status: true, coupon: {
            ...coupon,
            purchase_requirements: {
                ...coupon.purchase_requirements,
                min_purchase_amount: {
                    ...coupon.purchase_requirements.min_purchase_amount,
                    active: checked
                }
            }
        }};
    else if (name === 'minimum_items_quantity')
        return { status: true, coupon: {
            ...coupon,
            purchase_requirements: {
                ...coupon.purchase_requirements,
                minimum_items_quantity: {
                    ...coupon.purchase_requirements.minimum_items_quantity,
                    active: checked
                }
            }
        }};
    else if (name === 'purchase_requirements')
        if (use_method === 'auto' && !checked) {
            basicMsg('Por lo menos un requisito de compra es necesario para descuentos automáticos');
            return { status: true, coupon };
        } else
            return { status: true, coupon: {
                ...coupon,
                purchase_requirements: {
                    ...coupon.purchase_requirements,
                    active: checked
                }
            }};
    else if (['limited', 'one_per_user'].includes(name))
        return { status: true, coupon: {
            ...coupon,
            restrictions: {
                ...coupon.restrictions,
                [name]: checked
            }
        }};
    else if (name === 'product_req_active')
        return { status: true, coupon: {
            ...coupon,
            purchase_requirements: {
                ...coupon.purchase_requirements,
                applies_to: {
                    ...coupon.purchase_requirements.applies_to,
                    product: {
                        ...coupon.purchase_requirements.applies_to.product,
                        active: checked
                    }
                }
            }
        }};
    else return { status: true, coupon: { ...coupon, [name]: checked } };
};