import React, { useRef, useState, useEffect, useContext } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { NavContext } from "../../../context/NavContext";

import { gapi } from 'gapi-script';
// import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import {
    // basicMsg,
    msg
} from '../../../actions/swal_msg';
import { loginModalToggle } from "../../../actions/ui";
import {
    // facebookLogin, googleLogin,
    login, resetRequest
} from '../../../actions/user';

import './login.css'

//IMG
import logo_mobile from '../../../assets/images/Logo.png'
import logo_desktop from '../../../assets/images/Logo_Nefropolis.png'

//MUI react
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import DialogContentText from '@mui/material/DialogContentText';
import {
    Container, FormHelperText, Box, FormControlLabel, Checkbox,
    Typography, // Grid
} from "@mui/material";

const clientId = process.env.REACT_APP_CLIENT_ID;

// estilos para mostrar/ocultar errores
const show_err = { display: "none" };
const hide_err = { display: "flex" };

const EMAIL = {
    email2: '',
    email2_err: false,
};

const valuesInit = {
    email: '',
    password: '',
    remember: false,
    showPassword: false
};

const LoginComponent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;
    const { setValue, setAnchorUser, setAnchorMenu, setAnchorEl } = useContext(NavContext)

    // referencias a campos
    const eInputRef = useRef();
    const pInputRef = useRef();
    const e2InputRef = useRef();
    // estado de modal
    const { loginModal } = useSelector(state => state.ui);

    // restablecimiento de contraseña
    const [_email, setEmail] = useState(EMAIL);
    const { email2, email2_err } = _email;

    // valores de formulario
    const [values, setValues] = useState(valuesInit);
    const { email, password, remember } = values;

    // errores en campos
    const [inputErr, setInputErr] = useState({
        email_err: false,
        pwd_err: false
    });
    const { email_err, pwd_err } = inputErr;

    useEffect(() => {
        const initClient = () => {
            gapi.auth2.init({
                clientId: clientId,
                scope: ''
            });
        };
        gapi.load('client:auth2', initClient);
    }, []);

    useEffect(() => {
        // se envió correo, restablecer valores
        if (!loginModal)
            setEmail(EMAIL);
    }, [loginModal]);

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //Dialog para recuperar contraseña
    const handleClickOpen = () => {
        dispatch(loginModalToggle(true));
    };
    const handleClose = () => {
        dispatch(loginModalToggle(false));
    };

    // restablecer contraseña
    const resetPassword = () => {
        if (!email2_err)
            dispatch(resetRequest(email2));
    };

    // cambios formulario
    const handleChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        setValues({ ...values, [name]: value });
        switch (name) {
            case 'email':
                setInputErr({
                    ...inputErr,
                    email_err: (value.length < 6 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'password':
                setInputErr({
                    ...inputErr,
                    pwd_err: (value.length < 5 ||
                        value.length > 60) ? true : false
                });
                break;
            default:
                break;
        }
    };

    // cambios correo electrónico de modal
    const handleEmailChange = ({ target }) => {
        const value = target.value;
        setEmail({
            email2: value,
            email2_err: (value.length < 6 ||
                value.length > 100) ? true : false
        });
    };

    // inicio de sesión
    const submit = e => {
        e.preventDefault();
        if (formValidator()) {
            dispatch(login({
                ...values,
                page: state ? state.page : 'mi-cuenta'
            }));
            setValue('/mi-cuenta')
            setAnchorUser(null)
            setAnchorMenu(null)
            setAnchorEl(null)
        }
    };

    // validación formulario
    const formValidator = () => {
        const email_len = email.trim().length;
        const pwd_len = password.trim().length;
        if (email_len > 5 && email_len <= 100) {
            if (pwd_len > 4 && pwd_len <= 60)
                return true;
            else {
                pInputRef.current.select();
                msg('warning', 'Inicio de sesión',
                    'Ingrese su contraseña');
            }
        } else {
            eInputRef.current.select();
            msg('warning', 'Inicio de sesión',
                'Ingrese su correo electrónico');
        }
        // establece errores en los campos
        setInputErr({
            ...inputErr,
            email_err: (email_len < 6 ||
                email_len > 100) ? true : false,
            pwd_err: (pwd_len < 5 ||
                pwd_len > 60) ? true : false
        });
        return false;
    };

    // éxito respuesta de Google oAUth
    // const onSuccess = (res) => {
    //     dispatch(googleLogin({
    //         googleId: res.googleId,
    //         email: res.profileObj.email,
    //         username: res.profileObj.name,
    //     }));
    // };

    // fallo respuesta de Google oAUth
    // const onFailure = (err) => {
    //     basicMsg('No se pudo conectar con Google');
    // };

    // éxito respuesta inicio con Facebook
    // const responseFacebook = (response) => {
    //     if (!response.status) {
    //         dispatch(facebookLogin({
    //             facebookId: response.userID,
    //             email: response.email,
    //             username: response.name
    //         }));
    //     }
    // };

    return (
        <form onSubmit={submit} className="login-bg-img">
            <Container maxWidth='xl'>
                {/* Logo */}
                <Box sx={{ textAlign: 'center' }}>
                    <img className="login-logo-desktop" src={logo_desktop} width="25%" alt="logo_desktop" />
                    <img className="login-logo-mobile" src={logo_mobile} width="55%" alt="logo_mobile" />
                </Box>

                {/* Formulario login */}
                <Box sx={{ mt: 6 }} className="login-form">

                    {/* Correo electronico */}
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>
                            Correo electrónico
                        </InputLabel>
                        <OutlinedInput
                            id="email-input"
                            placeholder="Correo electrónico"
                            style={{ backgroundColor: '#fff' }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <i className="fa-solid fa-user"></i>
                                </InputAdornment>
                            }
                            label="Correo electrónico"
                            error={email_err}
                            inputRef={eInputRef}
                            name="email"
                            value={email}
                            onChange={handleChange}
                        />
                        <FormHelperText error style={email_err ? hide_err : show_err}>
                            Ingrese su correo electrónico
                        </FormHelperText>
                    </FormControl>

                    {/* Contraseña con metodo para mostrar y ocultar */}
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <InputLabel>Contraseña</InputLabel>
                        <OutlinedInput
                            id="pass-input"
                            type={values.showPassword ? 'text' : 'password'}
                            placeholder="Contraseña"
                            style={{ backgroundColor: '#fff' }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <i className="fa-solid fa-key"></i>
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <i className="far fa-eye-slash" style={{ fontSize: '1.3rem' }}></i> : <i className="far fa-eye" style={{ fontSize: '1.3rem' }}></i>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña"
                            error={pwd_err}
                            inputRef={pInputRef}
                            name='password'
                            value={password}
                            onChange={handleChange}
                        />
                        <FormHelperText error style={pwd_err ? hide_err : show_err}>
                            Ingrese su contraseña
                        </FormHelperText>
                    </FormControl>


                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <Box>
                            <FormControlLabel onChange={handleChange} control={<Checkbox defaultChecked={remember} size="small" />} label="Recordar contraseña" />
                        </Box>
                        <Box sx={{ mt: .8 }}>
                            <Link to={""} onClick={handleClickOpen}>
                                Olvidé mi contraseña
                            </Link>
                        </Box>
                        {/* Dialog para recuperar contrasñea */}
                        <Dialog open={loginModal} onClose={handleClose}
                            sx={{ zIndex: 1000 }}>
                            <DialogTitle>¿Has olvidado la contraseña?</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Escribe la dirección de correo electrónico que usaste para registrarte. Te enviaremos
                                    un correo electrónico con instrucciones sobre cómo restablecer tu contraseña.
                                </DialogContentText>
                                <Box sx={{ mt: 2 }}>
                                    <TextField
                                        fullWidth
                                        id=""
                                        label="Correo electrónico"
                                        type="email"
                                        variant="standard"
                                        error={email2_err}
                                        helperText={email2_err ? "Ingrese su correo electrónico" : ""}
                                        inputRef={e2InputRef}
                                        name="email2"
                                        value={email2}
                                        onChange={handleEmailChange}
                                    />
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancelar</Button>
                                <Button onClick={resetPassword}>Enviar</Button>
                            </DialogActions>
                        </Dialog>
                        {/* Fin dialog para recuperar contraseña */}
                    </Box>

                    <Box sx={{ textAlign: 'center', mt: 3 }}>
                        <Button type="submit"
                            variant='contained'
                            sx={{ width: '100%', height: '55px', fontWeight: '600' }}>
                            Iniciar sesión
                        </Button>
                    </Box>
                </Box>
                {/* Fin Formulario login */}

                {/* <Box sx={{ textAlign: 'center', mt: 3 }}>
                    <Typography variant='body1'>O también puedes iniciar sesión con</Typography>
                </Box> */}

                {/* Buttons social-login desktop */}
                {/* <Box sx={{ mt: 3, textAlign: 'center' }} className="buttons-desktop-login">
                    <div>
                        <GoogleLogin
                            clientId={clientId}
                            render={renderProps => (
                                <Button onClick={renderProps.onClick}
                                    disabled={renderProps.disabled}
                                    variant='contained' color='inherit' sx={{ mb: 2 }}>
                                    <i className="fab fa-google-plus-g" style={{ marginRight: '8px' }}></i> Iniciar sesión con Google</Button>
                            )}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                    <div>
                        <FacebookLogin
                            appId="805831810847865"
                            fields="name,email,picture"
                            callback={responseFacebook}
                            language='es_MX'
                            render={renderProps => (
                                <Button onClick={renderProps.onClick}
                                    variant='contained'>
                                    <i className="fab fa-facebook-f" style={{ marginRight: '8px' }}></i> Iniciar sesión con Facebook</Button>
                            )}
                        />
                    </div>
                </Box> */}

                {/* Buttons social-login mobile */}
                {/* <Box sx={{ pt: 3, textAlign: 'center', m: 'auto' }} className="buttons-mobile-login">
                    <Grid container spacing={0}>
                        <Grid item xs={6} sx={{ m: 'auto' }}>
                            <FacebookLogin
                                appId="805831810847865"
                                fields="name,email,picture"
                                callback={responseFacebook}
                                language='es_MX'
                                render={renderProps => (
                                    <Button onClick={renderProps.onClick} variant='outlined' sx={{ width: '60px', height: '60px', borderRadius: '100%' }}>
                                        <i className="fab fa-facebook-f" style={{ fontSize: 20 }}></i>
                                    </Button>
                                )}
                            />
                        </Grid>

                        <Grid item xs={6} sx={{ m: 'auto' }}>
                            <GoogleLogin
                                clientId={clientId}
                                render={renderProps => (
                                    <Button onClick={renderProps.onClick} variant='outlined' sx={{ width: '60px', height: '60px', borderRadius: '100%' }}>
                                        <i className="fab fa-google-plus-g" style={{ fontSize: 20 }}></i>
                                    </Button>
                                )}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                cookiePolicy={'single_host_origin'}
                            />
                        </Grid>
                    </Grid>
                </Box> */}

                {/* Crear cuenta */}
                <Box sx={{ textAlign: 'center', mt: 3 }}>
                    <Typography variant='body1'>
                        ¿No tienes cuenta? <Link to="/signup" style={{ color: '#2699FB' }}>Registrate</Link>
                    </Typography>
                </Box>

                {/* Avisos de privacidad */}
                <Box sx={{ textAlign: 'center', mt: 3 }}>
                    <Typography variant='body1'>
                        Al continuar aceptas las <Link to="/terminos-y-condiciones" style={{ color: '#2699FB' }}>condiciones de uso</Link> y el <Link to="/politica-de-privacidad" style={{ color: '#2699FB' }}>aviso de privacidad</Link> de Nefrópolis
                    </Typography>
                </Box>

            </Container>
        </form>
    );
};

export default LoginComponent;