import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Menu, MenuItem } from '@mui/material';
import ListIcon from '@mui/icons-material/List';

const ShopFilter = ({ filteredProducts, setFilteredProducts }) => {
    // obtener precio menor de variantes para filtro
    const getMinPrice = (p) => {
        const prices = p.prod_variants.map(e => e.price);
        const min = Math.min(...prices);
        return min;
    };

    // obtener promedio de reseñas para filtro
    const getAverageReview = (p) => {
        let prom = 0; // promedio reseñas
        let count = 0 // número reseñas
        if (p.reviews.length > 0) {
            p.reviews.forEach(e => {
                prom += e.rating;
                count++;
            });
            // redondea promedio de reseñas
            prom = Math.round(prom / count);
        }
        return prom;
    };

    //Menor precio
    function handlePriceLow() {
        setFilteredProducts([...filteredProducts].sort(function (a, b) {
            const a_min = getMinPrice(a);
            const b_min = getMinPrice(b);
            return (a_min > b_min) ? 1 : ((b_min > a_min) ? -1 : 0);
        }));
        setAnchorEl(null);
    }

    //Mayor precio
    function handlePriceHigh() {
        setFilteredProducts([...filteredProducts].sort(function (a, b) {
            const a_min = getMinPrice(a);
            const b_min = getMinPrice(b);
            return (a_min < b_min) ? 1 : ((b_min < a_min) ? -1 : 0);
        }));
        setAnchorEl(null);
    }

    //Nombre A - Z
    function handleName() {
        const sorted = [...filteredProducts].sort((a, b) => {
            return a.title > b.title ? 1 : -1
        })
        setFilteredProducts(sorted);
        setAnchorEl(null);
    }

    //Nombre Z - A
    function handleName_1() {
        const sorted = [...filteredProducts].sort((a, b) => {
            return a.title < b.title ? 1 : -1
        })
        setFilteredProducts(sorted);
        setAnchorEl(null);
    }

    const handleRating = () => {
        setFilteredProducts([...filteredProducts].sort(function (a, b) {
            const a_prom = getAverageReview(a);
            const b_prom = getAverageReview(b);
            return (a_prom < b_prom) ? 1 : ((b_prom < a_prom) ? -1 : 0);
        }));
        setAnchorEl(null);
    };

    //Menú para filtros
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
            <Link to={""}
                onClick={handleClick}
                style={{ color: 'black', fontSize: '18px', textDecoration: 'none' }}
            >
                Ordenar por<ListIcon sx={{ color: '#2699FB', ml: 1 }}></ListIcon>
            </Link>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handlePriceLow}><i className="fa-solid fa-down-long" style={{ marginRight: '10px' }}></i>Menor precio</MenuItem>
                <MenuItem onClick={handlePriceHigh}><i className="fa-solid fa-up-long" style={{ marginRight: '10px' }}></i>Mayor precio</MenuItem>
                <MenuItem onClick={handleName}><i className="fa-solid fa-up-long" style={{ marginRight: '10px' }}></i>Nombre A - Z</MenuItem>
                <MenuItem onClick={handleName_1}><i className="fa-solid fa-down-long" style={{ marginRight: '10px' }}></i>Nombre Z - A</MenuItem>
                <MenuItem onClick={handleRating}>Rating</MenuItem>
            </Menu>
        </Box>
    );
};

export default ShopFilter;