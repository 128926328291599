import React from 'react';

import { TextField, Stack } from "@mui/material";

import { regex_num } from '../../../../constants/regex';

const PromoOneDialog = ({
    promo, promotionData, setPromotionData
}) => {
    const { pkg_qty, discount } = promotionData;

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'discount') {
            if (['Descuento%Producto', 'Compra%Descuento', 'CompraAB%Descuento', 'CompraX%Descuento'].includes(promo)) {
                if (value === '' || regex_num.test(value))
                    setPromotionData(e => ({ ...e, discount: value }));
            }
        } else if (name === 'pkg_qty') {
            if (['CompraX%Descuento'].includes(promo)) {
                if (value === '' || regex_num.test(value))
                    setPromotionData(e => ({ ...e, pkg_qty: value }));
            }
        }
    };

    return (
        <Stack spacing={2} mt={2}>
            {
                ['Descuento%Producto', 'Compra%Descuento', 'CompraAB%Descuento', 'CompraX%Descuento'].includes(promo) &&
                    <TextField fullWidth
                        name="discount"
                        label="Descuento porcentual"
                        variant="standard"
                        inputProps={{ maxLength: 3 }}
                        value={ discount }
                        onChange={ handleInputChange }
                    />
            }
            
            {
                promo === 'CompraX%Descuento' &&
                    <TextField fullWidth
                        name="pkg_qty"
                        label="Cantidad necesaria para aplicar descuento"
                        variant="standard"
                        inputProps={{ maxLength: 2 }}
                        helperText={ `${pkg_qty} Pack`.trim() }
                        value={ pkg_qty }
                        onChange={ handleInputChange }
                    />
            }
        </Stack>
    );
};

export default PromoOneDialog;