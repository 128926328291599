import React from 'react'

import '../MedicalDirectory.css'

import carne from '../../../assets/resources/carne-de-identidad.png'
import nefro from '../../../assets/resources/nefrologo.png'
import hearth from '../../../assets/resources/enfermedad-del-corazon.png'
import gorro from '../../../assets/resources/gorro-de-graduacion.png'
import nefro_place from '../../../assets/resources/Nefro_place.png'

//Router dom
import { useNavigate } from 'react-router-dom'

//MUI
import { Avatar, Box, Grid, Typography, Button } from '@mui/material'
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    padding: '8px 22px 8px 22px',
    borderRadius: '8px',
    backgroundColor: '#56C596',
    '&:hover': {
        backgroundColor: '#419874',
    },
}));

const ButtonMobileTop = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#475CB8',
    padding: '14px',
    borderRadius: '8px',
    width: '100%',
    '&:hover': {
        backgroundColor: '#419874',
    },
}));

const MedicalDirectoryDetail = ({ data }) => {

    const navigate = useNavigate();

    return (
        <>
            <Box className='btn_back_mobile_top'>
                <ButtonMobileTop startIcon={<ChevronLeftIcon />} onClick={() => navigate(-1)}>Regresar</ButtonMobileTop>
            </Box>

            <Box sx={{ p: 5, border: '1px solid #56C596', borderRadius: '15px', boxShadow: 'rgba(50, 157, 156, 0.30) 0px 5px 15px' }}>
                <Grid container spacing={4}>
                    <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <Box className='medical_directory_detail_flex'>
                            <Box sx={{ my: 'auto' }} className='medical_avatar'>
                                {
                                    data.img.length > 0
                                        ?
                                        <Avatar alt={data.img} src={data.img} sx={{ width: 160, height: 160, mx: 'auto', pointerEvents: 'none' }} />
                                        :
                                        <Avatar sx={{ width: 160, height: 160, mx: 'auto', fontSize: '32px', fontWeight: 'bold', backgroundColor: '#2699FB' }}>
                                            {data.name.charAt(0)}{data.lastname.charAt(0)}
                                        </Avatar>
                                }
                            </Box>
                            <Box sx={{ my: 'auto' }}>
                                <Typography variant='body1' sx={{ fontWeight: '700', fontSize: '18px' }} gutterBottom>{data.name} {data.lastname}</Typography>
                                <Typography variant='body1' sx={{ color: '#5552B2', fontWeight: '600' }} gutterBottom>{data.specialty}</Typography>
                                <Typography variant='body1' sx={{ textTransform: 'uppercase', color: '#205072' }}>Cedula - {data.ic}</Typography>
                                <Box display='flex' justifyContent='space-evenly' gap='25px' mt={1.5} mb={1.5}>
                                    <Typography variant='body2' sx={{ color: '#205072', my: 'auto' }}>
                                        {data.street} {data.int_num} <br />
                                        {data.colony} C.P. {data.postalcode} <br />
                                        {data.city}, {data.state}
                                    </Typography>
                                    <Box sx={{ my: 'auto' }} className='medical_place_icon'>
                                        <img src={nefro_place} alt={nefro_place} width='27' />
                                    </Box>
                                </Box>
                                <Typography variant='body1' sx={{ color: '#5552B2', fontWeight: '500' }}>Tel. {data.phone}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Box>
                            <Box className='medical_info'>
                                <Box sx={{ my: 'auto' }}>
                                    <img src={carne} width='30' alt={carne} />
                                </Box>
                                <Typography variant='body1' align='left' sx={{ my: 'auto', color: '#5552B2', fontWeight: 'bold' }}>Sobre mi:</Typography>
                            </Box>
                            <Typography variant='body1' sx={{ mt: 1, color: '#919191' }}>
                                {data.about}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={{ mt: 10 }}>
                    <Grid container spacing={4}>
                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <Box className='medical_info' mb={3}>
                                <Box sx={{ my: 'auto' }}>
                                    <img src={nefro} alt={nefro} width='30' />
                                </Box>
                                <Typography variant='body1' align='left' sx={{ my: 'auto', color: '#5552B2', fontWeight: 'bold' }}>
                                    Especialista en:
                                </Typography>
                            </Box>
                            <ul className='medical_directory_specialties'>
                                {
                                    data.specialties.map((res, index) => (
                                        <li key={index} style={{ color: '#919191' }}>{res.value}</li>
                                    ))
                                }
                            </ul>
                        </Grid>

                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <Box className='medical_info' mb={3}>
                                <Box sx={{ my: 'auto' }}>
                                    <img src={hearth} alt={hearth} width='30' />
                                </Box>
                                <Typography variant='body1' align='left' sx={{ my: 'auto', color: '#5552B2', fontWeight: 'bold' }}>
                                    Enfermedades tratadas
                                </Typography>
                            </Box>
                            <ul className='medical_directory_diseases_treated'>
                                {
                                    data.diseases_treated.map((res, index) => (
                                        <li key={index} style={{ color: '#919191' }}>{res.value}</li>
                                    ))
                                }
                            </ul>
                        </Grid>

                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <Box className='medical_info' mb={3}>
                                <Box sx={{ my: 'auto' }}>
                                    <img src={gorro} alt={gorro} width='30' />
                                </Box>
                                <Typography variant='body1' align='left' sx={{ my: 'auto', color: '#5552B2', fontWeight: 'bold' }}>
                                    Formación académica
                                </Typography>
                            </Box>
                            <ul className='medical_directory_education'>
                                {
                                    data.education.map((res, index) => (
                                        <li key={index} style={{ color: '#919191' }}>
                                            {res.value}
                                        </li>
                                    ))
                                }
                            </ul>
                        </Grid>
                    </Grid>
                </Box>
                <Box className='btn_back_desktop' mt={10}>
                    <ColorButton startIcon={<ChevronLeftIcon />} onClick={() => navigate(-1)}>Regresar</ColorButton>
                </Box>
            </Box>

            <Box className='btn_back_mobile' mt={5}>
                <ColorButton startIcon={<ChevronLeftIcon />} onClick={() => navigate(-1)}>Regresar</ColorButton>
            </Box>
        </>
    )
}

export default MedicalDirectoryDetail