import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { msg, valMsg } from '../../../actions/swal_msg';
import { protectedReq } from '../../../helpers/requestHelper';

import {
    IconButton, InputAdornment, FormControl, InputLabel,
    OutlinedInput, FormHelperText, Button, Stack
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PasswordIcon from '@mui/icons-material/Password';

// estilos para mostrar/ocultar errores
const hide_err = { display: "none" };
const show_err = { display: "flex" };

const init = {
    password: '',
    pwd_err: false
};

const AdminPwd = ({handleCloseDialog}) => {
    const { activeAdmin: { _id } } = useSelector(state => state.admin);
    // referencia a campo
    const pwdRef = useRef();
    const [values, setValues] = useState(init);
    const { password, pwd_err } = values;
    // mostrar/ocultar contraseña
    const [toggle, setToggle] = useState(false);

    const handlePwdToggle = () => {
        setToggle(!toggle);
    };
    const handleMouseDownPwd = (event) => {
        event.preventDefault();
    };

    const handleInputChange = ({ target }) => {
        setValues({ ...values, password: target.value })
    };

    const formValidator = () => {
        const pwd_len = password.trim().length;
        if (pwd_len > 4 && pwd_len <= 60)
            return true;
        else {
            pwdRef.current.select();
            valMsg('warning', 'Administrador',
                'Ingrese una contraseña válida');
        }
    };

    // actualizar contraseña de admin
    const update = async () => {
        if (formValidator())
            fetch();
            handleCloseDialog()
    };

    // petición
    const fetch = useCallback(async () => {
        try {
            const resp = await protectedReq('admin/update-pwd', { _id, password }, 'PUT');
            const body = await resp.json();
            if (body.status) {
                setValues(init)
                msg('success', '¡Éxito!',
                    'Contraseña actualizada');
            } else {
                if (body.msg === 'not-found')
                    msg('warning', 'Actualización',
                        'No se encontró el administrador');
                else if (body.msg === 'server-err')
                    msg('error', 'Actualización',
                        'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Actualización',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msg('error', 'Actualización',
                'No se pudo actualizar el administrador');
        }
    });

    return (
        <>
            <Stack sx={{ mt: 2 }} spacing={3}>
                <FormControl variant="outlined">
                    <InputLabel>Nueva contraseña</InputLabel>
                    <OutlinedInput
                        fullWidth
                        label="Nueva contraseña"
                        type={toggle ? 'text' : 'password'}
                        error={pwd_err}
                        inputProps={{ maxLength: 60 }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handlePwdToggle}
                                    onMouseDown={handleMouseDownPwd}
                                    edge="end">
                                    {toggle ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        startAdornment={
                            <InputAdornment position="start">
                                <PasswordIcon />
                            </InputAdornment>
                        }
                        inputRef={pwdRef}
                        name="password"
                        value={password}
                        onChange={handleInputChange} />
                    <FormHelperText error style={pwd_err ? show_err : hide_err}>
                        Ingrese una contraseña
                    </FormHelperText>
                </FormControl>
            </Stack>

            <Button variant="contained" sx={{ mt: 2 }}
                onClick={update}>
                Actualizar
            </Button>
        </>
    );
};

export default AdminPwd;