import React from "react";

//Router-dom
import { Link } from 'react-router-dom';

//CSS
import '../About/about.css'

//Imagenes
import suc_3 from '../../assets/images/suc3.jpg'
import encabezado from '../../assets/images/encabezado.jpg'
import valores from '../../assets/images/valores.jpg'
import promesa from '../../assets/images/promesa_de_marca.jpg'
import team from '../../assets/images/Equipo_Nefropolis.png'

//MUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, Container, Grid, styled, useMediaQuery } from "@mui/material";

//PDF
import pdf from '../../assets/resources/comunicacion-sobre-el-progreso_2021-2022.pdf';

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: 'transparent',
    border: '1px solid #fff',
    transition: 'all .3s ease-in-out',
    '&:hover': {
        backgroundColor: '#fff',
        color: '#000',
        border: '1px solid #fff'
    },
}));

const AboutComponent = () => {
    const matches = useMediaQuery('(max-width: 950px)');
    return (
        <>
            {/* Sobre nefrópolis */}
            <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18 }}>
                <Typography variant="h4" sx={{ fontWeight: 'light', fontSize: '2.5rem' }} align="center" gutterBottom>Sobre nosotros</Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <img src={suc_3} width='100%' alt="sucursal" style={{ borderRadius: 5 }} />
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Typography variant="h4" sx={{ fontWeight: 'light', fontSize: '2rem' }} align="center" gutterBottom>
                            Nuestra misión
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Es <b>sanar el entorno</b> de las personas con enfermedad renal, es por eso que:
                        </Typography>
                        <ul style={{ marginTop: '15px' }}>
                            <li>Contamos con el más amplio surtido de productos especializados en la <b>Enfermedad Renal Crónica</b>.</li>
                            <li>Altos estándares de <b>calidad en el servicio</b>, atención y asesoría nutricional a nuestros clientes.</li>
                            <li>Disponibilidad de productos.</li>
                            <li>Precios justos y competitivos.</li>
                        </ul>
                    </Grid>
                </Grid>
            </Container>

            {/* Quienes somos */}
            <Container maxWidth="xl" sx={{ mt: 6 }}>
                <Typography variant="h4" align="center" gutterBottom>¿QUIÉNES SOMOS?</Typography>
                <Typography variant="body1" paragraph>Nefrópolis es una cadena de tiendas especializadas, donde se ofrecen
                    productos y servicios necesarios para los pacientes que viven con
                    Enfermedad renal crónica (ERC).</Typography>

                <Typography variant="body1" paragraph>Desde 2008 hemos crecido con nuestra misión siempre presente “Sanar el
                    entorno de las personas con enfermedad renal” y seguiremos en la búsqueda
                    de ser un referente nacional en nutrición renal.

                    Para lograr este gran objetivo buscamos la presencia en diversos espacios
                    de especialización medica y nutricional, así como eventos para paciente
                    y de esta manera llegar más lejos.</Typography>

                {/* Valores, proposito y promesa de la marca */}
                <Grid container spacing={4}>
                    {/* Proposito */}
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} sx={{ mx: 'auto' }}>
                        <Card className="about-cards" sx={{ maxWidth: '100%', height: '370px' }}>
                            <CardMedia
                                component="img"
                                height="180"
                                image={encabezado}
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Propósito
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Sanar el entorno del paciente renal.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Fin proposito */}

                    {/* Valores */}
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} sx={{ mx: 'auto' }}>
                        <Card className="about-cards" sx={{ maxWidth: '100%', height: '370px' }}>
                            <CardMedia
                                component="img"
                                height="180"
                                image={valores}
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Valores
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <ul>
                                        <li>Hazte cargo.</li>
                                        <li>Anticípate y resuelve.</li>
                                        <li>Ponte en los zapatos del otro.</li>
                                        <li>Ama lo que haces.</li>
                                        <li>Desafíate, aprende y mejora.</li>
                                        <li>Haz lo correcto.</li>
                                    </ul>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Fin valores */}

                    {/* Promesa de marca */}
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} sx={{ mx: 'auto' }}>
                        <Card className="about-cards" sx={{ maxWidth: '100%', height: '370px' }}>
                            <CardMedia
                                component="img"
                                height="180"
                                image={promesa}
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Promesa de marca
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    <ul>
                                        <li>Soluciones efectivas cerca de ti.</li>
                                        <li>Acompañamiento profesional.</li>
                                        <li>Disponibilidad de producto.</li>
                                    </ul>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/* Fin promesa de marca */}
                </Grid>
                {/* Fin Valores, proposito y promesa de la marca */}
            </Container>

            {/* Nuestro equipo */}
            <Container maxWidth='xl' sx={{ mt: 6 }}>
                {/******/}
                <Box
                    sx={{ display: { xl: 'flex', lg: 'flex', md: 'block', sm: 'block' }, justifyContent: 'center', mt: 6 }}
                >
                    <img src={team} className="img_equipofondo" alt="team" />

                    <Box sx={{ px: 3, py: 6 }} id='equipoFondo'>
                        <Typography variant="h4" sx={{ fontWeight: '600' }} gutterBottom>NUESTRO EQUIPO</Typography>
                        <Typography variant="body1" sx={{ fontSize: '20px' }} gutterBottom>
                            Nefrópolis cuenta con grandes profesionales que diariamente buscan
                            innovar para generar mayor impacto y bienestar en el entorno de
                            la enfermedad renal
                        </Typography>
                        <Link to="/nuestro-equipo" style={{ textDecoration: 'none' }}>
                            <ColorButton variant="outlined" sx={{ mt: 2.5 }}>Conócenos</ColorButton>
                        </Link>
                    </Box>
                </Box>
            </Container>

            {/* RESPONSABILIDAD SOCIAL EMPRESARIAL */}
            <div className="last-view">
                <Container maxWidth="xl" sx={{ mt: 6, py: 5, px: 4, backgroundColor: '#4BBBD1', color: '#fff' }}>
                    <Typography variant="h5" sx={{ fontSize: '2rem', fontWeight: 'bold' }} align="center" gutterBottom>RESPONSABILIDAD SOCIAL EMPRESARIAL</Typography>
                    <Typography variant="body1" sx={{ fontSize: '20px' }} paragraph>En Nefrópolis apoyamos al PACTO MUNDIAL</Typography>
                    <Typography variant="body1" sx={{ fontSize: '20px' }} paragraph>El PACTO MUNDIAL es una iniciativa de la Organización de las Naciones
                        Unidas (ONU) que promueve implementar 10 Principios universalmente
                        aceptados para promover el desarrollo sostenible en las actividades
                        y la estrategia de negocio de las empresas, en las siguientes áreas:</Typography>
                    <ul style={{ fontSize: '20px' }}>
                        <li>Derechos Humanos y Empresa</li>
                        <li>Normas Laborales</li>
                        <li>Medio Ambiente</li>
                        <li>Lucha contra la Corrupción</li>
                    </ul>
                    <Typography variant="body1" sx={{ fontSize: '20px' }} gutterBottom>Desde 2014 Nefrópolis está adherido a los principios del PACTO MUNDIAL
                        de la ONU y anualmente compartimos un informe sobre nuestras acciones
                        responsables y sus resultados.</Typography>
                    <div style={{ textAlign: 'center' }}>
                        <a href={pdf} target='_blank' rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                            <ColorButton variant="outlined" sx={{ mt: 2 }}>Ver reporte</ColorButton>
                        </a>
                    </div>
                </Container>
            </div>
            {/* RESPONSABILIDAD SOCIAL EMPRESARIAL */}
        </>
    );
}

export default AboutComponent