import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { BANNER_BACKUP } from "../../../constants/shop/shop_constants";

import ShopSwiper from "./ShopSwiper";
import DrawerCart from "../Drawer/DrawerCart";
import CategoryContainer from "./CategoryContainer";
import PaginationComponent from "../Pagination/Pagination";
import RecentlyViewed from "../RecentlyViewed/RecentlyViewed";
import FeaturedProducts from "./FeaturedProducts/FeaturedProducts";
import PromotionProducts from "./PromotionProducts/PromotionProducts";

//CSS
import './shop.css';

import shape from '../../../assets/resources/shape.png';
import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';

import { GOOGLE_DRIVE_CDN } from "../../../constants/constants";

import {
    Box, InputLabel, MenuItem, FormControl,
    Select, Typography, Pagination,
    Container, Grid, Stack
} from "@mui/material";

// import FlashSaleContainer from "../FlashSale/FlashSaleContainer";

const ShopComponent = () => {
    const {
        products, cart: { item },
        banners: { desktop, mobile, tablet }
    } = useSelector(state => state.shop);
    
    // productos de tienda
    const [myProducts, setProducts] = useState([]);

    const [filter, setFilter] = useState('50');

    useEffect(() => {
        setProducts(products);
    }, [products]);

    //Pagination
    let [page, setPage] = useState(1);
    const PER_PAGE = 12;

    const count = Math.ceil(myProducts.length / PER_PAGE);
    const _DATA = PaginationComponent(myProducts, PER_PAGE);

    const handleChangePagination = (e, p) => {
        setPage(p);
        _DATA.jump(p);
        myRef.current.scrollIntoView()
    };

    const myRef = useRef(null);

    // filtrar productos
    const onChangeFilter = ({ target }) => {
        setFilter(target.value);
        const arr = [...myProducts];
        switch (target.value) {
            case '10': // menor precio
                setProducts(arr.sort(function (a, b) {
                    const a_min = getMinPrice(a);
                    const b_min = getMinPrice(b);
                    return (a_min > b_min) ? 1 : ((b_min > a_min) ? -1 : 0);
                }));
                break;
            case '20': // mayor precio
                setProducts(arr.sort(function (a, b) {
                    const a_min = getMinPrice(a);
                    const b_min = getMinPrice(b);
                    return (a_min < b_min) ? 1 : ((b_min < a_min) ? -1 : 0);
                }));
                break;
            case '30': // nombre ascendente
                setProducts(arr.sort(function (a, b) {
                    return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
                }));
                break;
            case '40': // nombre descendente
                setProducts(arr.sort(function (a, b) {
                    return (a.title < b.title) ? 1 : ((b.title < a.title) ? -1 : 0);
                }));
                break;
            case '50':
                setProducts(arr.sort(function (a, b) {
                    const a_prom = getAverageReview(a);
                    const b_prom = getAverageReview(b);
                    return (a_prom < b_prom) ? 1 : ((b_prom < a_prom) ? -1 : 0);
                }));
                break;
            default:
                break;
        }
    };

    // obtener precio menor de variantes para filtro
    const getMinPrice = (p) => {
        const prices = p.prod_variants.map(e => e.price);
        const min = Math.min(...prices);
        return min;
    };

    // obtener promedio de reseñas para filtro
    const getAverageReview = (p) => {
        let prom = 0; // promedio reseñas
        let count = 0 // número reseñas
        if (p.reviews.length > 0) {
            p.reviews.forEach(e => {
                prom += e.rating;
                count++;
            });
            // redondea promedio de reseñas
            prom = Math.round(prom / count);
        }
        return prom;
    };

    return (
        <>
            {/* BANNERS TIENDA */}
            {/* ../../../assets/images/banners/Banners_nuevos/ */}
            <div className="banners-shop-desktop">
                <ShopSwiper
                    images={ desktop }
                    backup_banner={ BANNER_BACKUP.desktop }
                />
            </div>
            <div className="banners-shop-tablet">
                <ShopSwiper
                    images={ tablet }
                    backup_banner={ BANNER_BACKUP.tablet }
                />
            </div>
            <div className="banners-shop-mobile">
                <ShopSwiper
                    images={ mobile }
                    backup_banner={ BANNER_BACKUP.mobile }
                />
            </div>

            <CategoryContainer />

            {/* Productos en promoción */}
            <Container maxWidth='xl' sx={{ mt: 5 }}>
                <PromotionProducts />
            </Container>

            {/* Modulo para productos destacados */}
            <Container maxWidth='xl' sx={{ mt: 5 }}>
                <FeaturedProducts />
            </Container>
            {/* Fin modulo para productos destacados */}

            {/* Vistos recientemente */}
            <Container maxWidth='xl' sx={{ mt: 5 }}>
                <RecentlyViewed product_id='' />
            </Container>

            {/* Flash sale countdown */}
            {/* <Box>
                <FlashSaleContainer />
            </Box> */}

            <div ref={myRef}></div>

            {/* Tienda */}
            <Container maxWidth='xl' sx={{ mt: 5 }} className="last-view">
                {/* Filtros */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
                    <FormControl className="order-by-shop" size="small">
                        <InputLabel>Ordenar por:</InputLabel>
                        <Select
                            name={filter}
                            label="Ordenar por:"
                            defaultValue={filter}
                            value={filter}
                            onChange={onChangeFilter}>
                            <MenuItem value={'10'}><i className="fa-solid fa-down-long" style={{ marginRight: '10px' }}></i>Menor precio</MenuItem>
                            <MenuItem value={'20'}><i className="fa-solid fa-up-long" style={{ marginRight: '10px' }}></i>Mayor precio</MenuItem>
                            <MenuItem value={'30'}><i className="fa-solid fa-up-long" style={{ marginRight: '10px' }}></i>Nombre A - Z</MenuItem>
                            <MenuItem value={'40'}><i className="fa-solid fa-down-long" style={{ marginRight: '10px' }}></i>Nombre Z - A</MenuItem>
                            <MenuItem value={'50'}>Rating</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {/* Fin filtros */}

                {/* Productos */}
                <Grid container spacing={6}>
                    {
                        _DATA.currentData().map(e => {
                            let review_prom = 0; // promedio reseñas
                            let review_count = 0 // número reseñas

                            // precios de variantes
                            const prices = e.prod_variants.map(e => e.price);
                            // menor precio
                            const min_price = Math.min(...prices);

                            // variante con promoción
                            const promoVariant = e.prod_variants.find(e => e.item_promotion && e.item_promotion.promo !== '');
                            // variante con precio más bajo
                            const minPriceVariant = e.prod_variants.find(e => e.price === min_price);

                            // variante a mostrar
                            const _variant = (!promoVariant && !minPriceVariant) ?
                                e.prod_variants[0]
                                :
                                promoVariant ? promoVariant : minPriceVariant;

                            // reseñas de producto
                            if (e.reviews.length > 0) {
                                e.reviews.forEach(element => {
                                    review_prom += element.rating;
                                    review_count++;
                                });
                                // redondea promedio de reseñas
                                review_prom = Math.round(review_prom / review_count);
                            }
                            return (
                                <Grid item xl={3} lg={4} md={6} sm={6} xs={12} key={e._id}>
                                    <Grid container rowSpacing={1} columnSpacing={3} className="product-shop">
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={5}
                                            sx={{ my: 'auto', textAlign: 'center' }}>
                                            {
                                                _variant ?
                                                    <Link to={`/tienda/producto/${e.url}`}>
                                                        <div className="product-in-promotion">
                                                            {
                                                                _variant.images.length > 0 ?
                                                                    <img src={`${GOOGLE_DRIVE_CDN}${_variant.images[0].id}`}
                                                                        width='100%' style={{ objectFit: 'contain' }}
                                                                        className="shop-product"
                                                                        alt={_variant.images[0].alt} />
                                                                    :
                                                                    <img src={no_image}
                                                                        width='100%' className="shop-product"
                                                                        alt="producto-nefropolis" />
                                                            }
                                                            {
                                                                _variant.item_promotion.promo !== '' &&
                                                                <div className="shop_top-image">
                                                                    <img src={shape} alt={shape} width='75' style={{ pointerEvents: 'none' }} />
                                                                    <div className="shop_centered_image">
                                                                        {
                                                                            ['2x1', '4x3', 'Descuento%Producto'].includes(_variant.item_promotion.promo)
                                                                                ?
                                                                                <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>
                                                                                    {_variant.item_promotion.text}
                                                                                </Typography>
                                                                                :
                                                                                <Typography sx={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>
                                                                                    Promoción
                                                                                </Typography>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Link>
                                                    :
                                                    <Link to={`/tienda/producto/${e.url}`}>
                                                        {
                                                            _variant && _variant.images.length > 0 ?
                                                                <img src={`${GOOGLE_DRIVE_CDN}${_variant.images[0].id}`}
                                                                    width='100%' style={{ objectFit: 'contain' }}
                                                                    className="shop-product"
                                                                    alt={_variant.images[0].alt} />
                                                                :
                                                                <img src={no_image}
                                                                    width='100%' className="shop-product"
                                                                    alt="producto-nefropolis" />
                                                        }
                                                    </Link>
                                            }
                                        </Grid>

                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={7} sx={{ my: 'auto' }}>
                                            <Box sx={{ textAlign: { xs: 'flex-start', sm: 'center', md: 'center' } }}>
                                                <Link to={`/tienda/producto/${e.url}`} style={{ textDecoration: 'none', color: '#000' }}
                                                    className='product_name'>
                                                    <Typography
                                                        variant="body1"
                                                        component="div"
                                                        sx={{ fontWeight: '600' }}
                                                    >
                                                        {e.title}
                                                    </Typography>
                                                </Link>
                                            </Box>

                                            <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'center', md: 'center' }, mt: 1 }}>
                                                <div className="ratings-shop">
                                                    {
                                                        review_count > 0 ?
                                                            Array.from(Array(review_prom), (e, j) => {
                                                                return <i className="fas fa-star"
                                                                    style={{
                                                                        color: '#FFA41C',
                                                                        stroke: "#FFA41C",
                                                                        strokeWidth: 0.2
                                                                    }}
                                                                    key={j}></i>
                                                            })
                                                            :
                                                            <>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                            </>
                                                    }
                                                </div>
                                            </Box>

                                            <Box sx={{
                                                display: 'flex', mt: 1,
                                                justifyContent: { xs: 'flex-start', sm: 'center', md: 'center' }
                                            }}>
                                                {
                                                    _variant &&
                                                        ['Descuento$Producto', 'Descuento%Producto'].includes(
                                                            _variant.item_promotion.promo
                                                        ) ?
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="baseline"
                                                            spacing={1}>
                                                            <Typography variant="h6"
                                                                sx={{ fontWeight: '600', color: '#000', textDecoration: 'line-through' }}>
                                                                ${min_price}
                                                            </Typography>
                                                            <Typography variant="h6"
                                                                sx={{ fontWeight: '600', color: '#F90606' }}>
                                                                ${_variant.item_promotion.price}
                                                            </Typography>
                                                        </Stack>
                                                        :
                                                        <Typography variant="h6"
                                                            sx={{ fontWeight: '600', color: '#F90606' }}>
                                                            ${min_price}
                                                        </Typography>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }

                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                    <Pagination
                        color="primary"
                        count={count}
                        page={page}
                        onChange={handleChangePagination}
                    />
                </Box>
                {/* Fin productos */}
            </Container >
            {/* Fin tienda */}

            < div className="btn-drawer-cart" >
                {
                    item.length > 0 &&
                    <DrawerCart />
                }
            </div >
        </>
    );
};

export default ShopComponent;