import React from 'react'

//MUI
import { Container, Typography, useMediaQuery } from '@mui/material'

const Facturacion = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    return (
        <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }} className="last-view">
            <Typography variant='h4' sx={{ fontWeight: 'light' }} align='center' paragraph>
                Políticas de facturación y pagos
            </Typography>
            <Typography variant='body1' paragraph>
                Estimado Cliente, se le informa que a partir del 01 de Agosto del 2023 entra en vigor la nueva
                política de facturación y cobranza.
            </Typography>
            <Typography variant='body1' paragraph>
                A través de la presente se establece y comunica los lineamientos de la emisión de
                facturación de los servicios prestados por la empresa SOLUCIONES INTEGRALES BTL S.A. DE C.V.
                (en lo sucesivo “NEFROPOLIS”), alineado al cumplimiento de las normas establecidas por la Secretaría
                de Administración Tributaria (SAT) en la miscelánea fiscal actual y en conjunto con las normas de
                facturación versión 4.0, así también como de cobranza de los servicios y productos vendidos.
            </Typography>
            <Typography variant='h5' sx={{ fontWeight: 'bold' }} paragraph>Facturación</Typography>
            <ul>
                <li>Si requiere factura electrónica, solicitarla al momento de pagar y compartir los datos que se soliciten.</li>
            </ul>
            <Typography variant='body1' sx={{ fontWeight: 'bold' }} paragraph>
                *En caso de actualizar sus datos de facturación, deberá proporcionar los nuevos datos a la
                asesora en su siguiente compra.
            </Typography>
            <ul>
                <li>
                    Es indispensable que solicite su factura al momento de realizar su compra, de no ser así, será
                    emitida a Público en General (ello de conformidad a las disposiciones fiscales aplicables).
                </li>
                <li>
                    La factura la recibirá en el correo electrónico registrado y/o proporcionado a la asesora
                    de la sucursal, tanto en formato PDF y XML, a más tardar 24 a 48 hrs. después de haber realizado su compra.
                </li>
            </ul>
            <Typography variant='body1' sx={{ fontWeight: 'bold' }} paragraph>
                *En caso de alguna eventualidad, deberá ser informado al cliente sobre la resolución.
            </Typography>
            <ul>
                <li>
                    De acuerdo a lo establecido por el SAT (anexo 20 CDFI 4.0), el uso de CFDI será en función al
                    régimen fiscal bajo el cual se tributa.
                </li>
                <li>
                    Una vez emitida la factura, no se realizarán cambios, devoluciones ni cancelaciones.
                </li>
            </ul>
            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                *Es importante destacar que nuestras facturas se generan de forma inmediata al momento de la transacción.
            </Typography>
            <Typography variant='h5' sx={{ fontWeight: 'bold', mt: 3 }} paragraph>Métodos de pago</Typography>
            <Typography variant='body1' paragraph>Contamos con estos métodos de pago:</Typography>
            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>1. Tarjeta de débito o crédito</Typography>
            <Typography variant='body1' paragraph>
                Este pago aplica de manera presencial tanto en sucursales como en consultorios.
            </Typography>
            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>2. Pago en efectivo</Typography>
            <Typography variant='body1' paragraph>Directamente en la tienda al momento de hacer la compra.</Typography>
            <Typography variant='body1' sx={{ fontWeight: 'bold' }} gutterBottom>3. Transferencia interbancaria (SPEI).</Typography>
            <ul>
                <li><u>Beneficiario:</u> SOLUCIONES INTEGRALES BTL</li>
                <li><u>Banco:</u> BBVA</li>
                <li><u>CTA:</u> 0113120619</li>
                <li><u>CLABE:</u> 012320001131206194</li>
                <li><u>CONCEPTO:</u> <b>SUCURSAL EN DONDE ADQUIEREN EL PRODUCTO</b></li>
            </ul>
            <Typography variant='body1' sx={{ fontWeight: 'bold' }} gutterBottom>4. Depósito bancario.</Typography>
            <ul>
                <li><u>Beneficiario:</u> SOLUCIONES INTEGRALES BTL</li>
                <li><u>Banco:</u> BBVA</li>
                <li><u>CTA:</u> 0113120619</li>
                <li><u>Concepto:</u> <b>SUCURSAL EN DONDE ADQUIEREN EL PRODUCTO. “Ejemplo: SUCURSAL GDL + NOMBRE DEL CLIENTE”</b></li>
            </ul>
            <Typography variant='body1' paragraph>
                *Consulta nuestras sucursales en: <a href='https://www.nefropolis.com/sucursales' target='_blank' rel="noopener noreferrer">
                    https://www.nefropolis.com/sucursales</a>
            </Typography>
            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                En caso de dudas y/o aclaraciones ponerse en contacto a través de los siguientes correos
                electrónicos: <a href='mailto:facturacion@nefropolis.com'>facturacion@nefropolis.com</a> y <a href='mailto:administracion@nefropolis.com'>administracion@nefropolis.com</a>.
            </Typography>
        </Container>
    )
}

export default Facturacion