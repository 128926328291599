import React, { useState } from 'react';

import { useSelector } from 'react-redux';

//MUI
import {
    Avatar, Box, Container, Drawer, IconButton, MenuItem, Typography, Button, Stack, useMediaQuery,
    Menu, Divider, ListItemIcon
} from '@mui/material'
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';

//Translation
import { useTranslation } from "react-i18next";

//Router dom
import { Link, NavLink } from 'react-router-dom';
// import LangSelect from '../LangSelect/LangSelect';
import { HashLink } from 'react-router-hash-link';

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#ffff',
    backgroundColor: '#475CB8',
    width: '100%',
    height: '45px',
    borderRadius: '10px',
    borderColor: '#475CB8',
    '&:hover': {
        backgroundColor: '#34448b',
        color: '#ffff',
    },
}));

const HeaderSidebar = ({ sidebar, setSidebar, logout_fn }) => {
    const matches = useMediaQuery('(max-width: 500px)');

    const { t } = useTranslation();

    // datos de usuario iniciado
    const { loggedUser } = useSelector(state => state.user);
    const { email, username, privileges } = loggedUser;

    //Menu para usuario
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Drawer
                anchor='right'
                open={sidebar}
                onClose={() => { setSidebar(false) }}
                PaperProps={{
                    sx: { width: matches ? '80%' : "370px" },
                }}
            >
                <Box sx={{ height: '160px', bgcolor: '#ECF1FA', p: 1 }}>
                    <IconButton
                        size="medium"
                        edge="end"
                        sx={{ color: '#2A2AC0' }}
                        onClick={() => { setSidebar(false) }}
                    >
                        <CloseIcon fontSize='medium' />
                    </IconButton>
                    {/* <LangSelect /> */}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '15px', mt: 3, ml: 2 }}>
                        <Box sx={{ my: 'auto' }}>
                            {
                                email !== '' ?
                                    <Avatar
                                        onClick={openMenu}
                                        sx={{ width: 60, height: 60, bgcolor: '#475CB8', color: '#fff', cursor: 'pointer' }}
                                    >
                                        { username.charAt(0).toUpperCase() }
                                    </Avatar>
                                    :
                                    <Avatar component={Link} to='/login'
                                        onClick={() => setSidebar(false)}
                                        sx={[
                                            { width: 60, height: 60, bgcolor: '#475CB8', color: '#fff', cursor: 'pointer' },
                                            {
                                                '&:hover': {
                                                    color: '#fff',
                                                },
                                            },
                                        ]}>
                                    </Avatar>
                            }
                        </Box>
                        <Box sx={{ my: 'auto' }}>
                            {
                                email !== '' ?
                                    <Typography onClick={ openMenu } variant='body2'
                                            sx={{ color: '#2A2AC0', fontWeight: 'bold' }}>
                                            { username }
                                        </Typography>
                                    // <>
                                    //     <Typography onClick={ openMenu } variant='body2'
                                    //         sx={{ color: '#2A2AC0', fontWeight: 'bold' }}>
                                    //         { username }
                                    //     </Typography>
                                    //     <Typography variant='body2' sx={{ color: '#181461' }}>
                                    //         Guadalajara, Jal.
                                    //     </Typography>
                                    // </>
                                :
                                    <Link to='/login' onClick={() => { setSidebar(false) }}>
                                        {t('Iniciar_sesion')}
                                    </Link>
                            }
                        </Box>
                    </Box>
                </Box>
                <Container maxWidth='xl' sx={{ my: 2 }}>
                    <ColorButton LinkComponent={NavLink} to='/tienda' variant='contained' onClick={() => { setSidebar(false) }}
                        startIcon={<ShoppingCartIcon />}
                    >
                        {t('Tienda')}
                    </ColorButton>
                    <Stack spacing={1} sx={{ mt: 3 }} className='links_mobile__'>
                        <Button variant='standard'
                            sx={{ fontWeight: 'bold' }}
                            onClick={() => { setSidebar(false) }}
                            LinkComponent={NavLink}
                            to='/'
                        >
                            {t('Inicio')}
                        </Button>
                        <Button variant='standard'
                            sx={{ fontWeight: 'bold' }}
                            onClick={() => { setSidebar(false) }}
                            LinkComponent={NavLink}
                            to='/sobre-nefropolis'
                        >
                            {t('Sobre_nosotros')}
                        </Button>
                        <Button variant='standard'
                            sx={{ fontWeight: 'bold' }}
                            onClick={() => { setSidebar(false) }}
                            LinkComponent={NavLink}
                            to='/sucursales'
                        >
                            {t('Sucursales')}
                        </Button>
                        <Button variant='standard'
                            sx={{ fontWeight: 'bold' }}
                            onClick={() => { setSidebar(false) }}
                            LinkComponent={HashLink} to="/#servicios"
                        >
                            {t('Servicios')}
                        </Button>
                        {/* <Button variant='standard'
                            sx={{ fontWeight: 'bold' }}
                            onClick={() => { setSidebar(false) }}
                            LinkComponent={NavLink}
                            to='/directorio-medico'
                        >
                            {t('Directorio_medico')}
                        </Button> */}
                    </Stack>
                    {
                        email !== '' &&
                            <Stack spacing={1} sx={{ position: 'absolute', bottom: 10 }}>
                                <Button variant='standard'
                                    sx={{ fontWeight: 'bold' }}
                                    onClick={logout_fn}
                                    startIcon={<LogoutIcon />}
                                >
                                    Cerrar sesión
                                </Button>
                            </Stack>
                    }
                </Container>
            </Drawer>

            {/* Menu para usuario logueado */}
            {
                email !== '' &&
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={closeMenu}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                        },
                    }}
                >
                    <MenuItem
                        component={Link}
                        to={
                            email !== '' ?
                                privileges && privileges.main ?
                                    '/nefro-admin'
                                    :
                                    '/mi-cuenta'
                                : '/login'
                        }
                        onClick={
                            () => {
                                setSidebar(false)
                                setAnchorEl(null)
                            }
                        }
                        sx={[{ '&:hover': { color: '#000' } }]}
                    >
                        <Avatar />
                        {
                            email !== '' ?
                                privileges && privileges.main ?
                                    'Nefro admin'
                                    :
                                    `${t("Cuenta")}`
                                :
                                `${t("Cuenta")}`
                        }
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        sx={[{ '&:hover': { color: '#000' } }]}
                        component={Link}
                        to={
                            email !== '' && '/mi-cuenta/informacion-y-seguridad'
                        }
                        onClick={
                            () => {
                                setSidebar(false)
                                setAnchorEl(null)
                            }
                        }
                    >
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        {t("Configuracion")}
                    </MenuItem>
                    <MenuItem onClick={ logout_fn }>
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        {t("Cerrar_sesion")}
                    </MenuItem>
                </Menu>
            }
        </>
    );
};

export default HeaderSidebar;