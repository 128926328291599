import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import QuillToolbar from './QuillToolbar';
import { blockLength, formats, modulesQuill } from '../../../../constants/admin/blog_constants';

//Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    Box, Button, Dialog, DialogActions, DialogContent,
    DialogTitle, FormControl, InputLabel, Select,
    MenuItem, Grid
} from '@mui/material';

const QuillEditDialog = ({ open, setOpen, row, setRow }) => {
    const { blockInEdition } = useSelector(state => state.blog);
    const { item, type, xs, md, id } = blockInEdition;
    const [text, setText] = useState('');
    const [colXS, setXS] = useState(12);
    const [colMD, setMD] = useState(6);

    useEffect(() => {
        setText(item);
        setXS(xs);
        setMD(md);
    }, [item, open]);

    // campo Quill Editor
    const handleChange = (html) => {
        setText(html);
    };

    // cerrar Dialog
    const handleClose = () => {
        setOpen(false);
    };

    // cambiar longitud en vista escritorio
    const handleDesktopView = ({ target }) => {
        setMD(target.value);
    };
    // cambiar longitud en vista móvil
    const handleMovilView = ({ target }) => {
        setXS(target.value);
    };

    // actualizar bloque de Quill
    const updateBlock = () => {
        const content = row.map(e => (
            {    
                ...e,
                col: [
                    ...e.col.map(u => u.id === id ?
                        {
                            ...u,
                            item: text,
                            xs: colXS,
                            md: colMD
                        }
                    :
                        u
                    )
                ]
            }
        ));
        setRow(content);
        handleClose();
    };

    return (
        <Dialog
            open={ open }
            onClose={ handleClose }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { width: '100%' } }}>
            <DialogTitle id="alert-dialog-title">
                Contenido de bloque
            </DialogTitle>
            <DialogContent>
                {
                    type === 'txt' &&
                        <Box sx={{ my: 3 }}>
                            <QuillToolbar />
                            <ReactQuill
                                value={text}
                                onChange={handleChange}
                                modules={modulesQuill}
                                formats={formats}
                            />
                        </Box>
                }
                
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        {/* Longitud de escritorio */}
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="md-select-label">Longitud de bloque en escritorio</InputLabel>
                            <Select
                                labelId="md-select-label"
                                id="md-select"
                                value={ colMD }
                                label="Longitud de bloque en escritorio"
                                onChange={ handleDesktopView }>
                                {
                                    blockLength.map(e =>
                                        <MenuItem key={e}
                                            value={e}>{e}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {/* Longitud de móviles */}
                        <FormControl fullWidth variant="standard">
                            <InputLabel id="xs-select-label">Longitud de bloque en móviles</InputLabel>
                            <Select
                                labelId="xs-select-label"
                                id="xs-select"
                                value={ colXS }
                                label="Longitud de bloque en móviles"
                                onChange={ handleMovilView }>
                                {
                                    blockLength.map(e =>
                                        <MenuItem key={e}
                                            value={e}>{e}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={ handleClose }>Cancelar</Button>
                <Button onClick={ updateBlock } autoFocus>
                    Actualizar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default QuillEditDialog;