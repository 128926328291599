import React from 'react';

import {List, ListItem, Avatar, ListItemAvatar, ListItemText,
    ListSubheader } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const FileFieldList = () => {
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Columnas de archivo .xlsx
                </ListSubheader>
            }>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <FiberManualRecordIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="titulo"
                    secondary="Título de producto. Campo obligatorio" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <FiberManualRecordIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="titulo_variante"
                    secondary='Identificador de la variante de producto. Ejemplo: "Sabor Fresa 100 piezas"' />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <FiberManualRecordIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="precio"
                    secondary="Columna que se toma en cuenta para asignar el precio" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <FiberManualRecordIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="se_puede_vender"
                    secondary="Si el valor de la columna es diferente a SI, Sí o Si, entonces no se carga el producto" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <FiberManualRecordIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="categoria/etapa"
                    secondary="El valor de estas columnas se toma como categoría del producto" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <FiberManualRecordIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="categorias"
                    secondary="Categorías adicionales. Separar los valores por comas" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <FiberManualRecordIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="info"
                    secondary="Información del producto (todas sus variantes)" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <FiberManualRecordIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="palabras_clave"
                    secondary="Keywords. Separar los valores por comas" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <FiberManualRecordIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="destacado"
                    secondary="Se desea mostrar el producto en la sección de productos destacados. Necesita valor de SI, Sí o Si" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <FiberManualRecordIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="descripcion"
                    secondary="Descripción de la variante de producto" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <FiberManualRecordIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="disponible"
                    secondary="El producto se puede mostrar en tienda" />
            </ListItem>
        </List>
    );
};

export default FileFieldList;