import React, { useState, useEffect } from 'react'

import logo from '../../assets/images/Logo_Nefropolis.png';
import no_image from '../../assets/images/banners/coleccion-todos1.jpg';

import './header.css'

import { Animated } from "react-animated-css";

//Redux
import { useSelector, useDispatch } from 'react-redux';

import SearchMobile from './SearchMobile/SearchMobile';

//Utils
import { logout } from '../../actions/user';
import { navigateToProductPage } from '../../actions/shop';

//Components
import HeaderSidebar from './HeaderSidebar/HeaderSidebar';
// import LangSelect from './LangSelect/LangSelect';

//Translation
import { useTranslation } from "react-i18next";

import { GOOGLE_DRIVE_CDN } from '../../constants/constants';

//MUI
import {
    AppBar, Toolbar, Box, IconButton, Autocomplete,
    Button, TextField, MenuItem, Badge, Avatar, Menu,
    ListItemIcon, Divider, useMediaQuery,
    Paper
} from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import Settings from '@mui/icons-material/Settings';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

//Router dom
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const MuiAutocomplete = styled(Autocomplete)({
    ".MuiAutocomplete-inputRoot": {
        color: "#000000",
        backgroundColor: "#D1D1D1",
        borderRadius: '40px',
        "& .MuiInputBase-input": {
            height: '18px'
        },
        "& .MuiInputAdornment-root": {
            color: "red"
        },
    }
});

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#ffff',
    backgroundColor: '#0099DC',
    borderColor: '#0099DC',
    '&:hover': {
        backgroundColor: '#0076a9',
        color: '#ffff',
    },
}));

const StyledBadgeDesktop = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -5,
        top: 5,
        border: `2px solid 2699FB`,
        padding: '0 4px',
        background: '#2699FB',
        color: '#fff'
    },
}));

function CustomPaper({ children, button, ...other }) {
    return (
        <Paper {...other}>
            {children}
            {button}
        </Paper>
    );
}

const Header = ({ handleRef, handleServicesRef }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width: 1024px)')

    // url de navegación
    const { navigation } = useSelector(state => state.shop);
    const { nav_url } = navigation;

    // datos de usuario iniciado
    const { loggedUser:
        { email, username, privileges }
    } = useSelector(state => state.user);
    // carrito
    const { products, cart } = useSelector(state => state.shop);
    const { item } = cart;
    // cantidad de productos en carrito
    const [itemCount, setItemCount] = useState(0);
    // variantes para barra de búsqueda
    const [options, setOptions] = useState([]);

    const [navbar, setNavbar] = useState(false);
    const changeBackground = () => {
        if (window.scrollY >= 200) {
            setNavbar(true)
        } else {
            setNavbar(false) // Can't perform a React state update on an unmounted component
        }
    };

    //Sidebar const
    const [sidebar, setSidebar] = useState(false);

    useEffect(() => {
        changeBackground()
        window.addEventListener("scroll", changeBackground)
    });

    useEffect(() => {
        // redireccionar y mostrar información de variante
        if (nav_url !== '')
            navigate(`/tienda/producto/${nav_url}`);
    }, [navigation]);

    const OPTIONS_LIMIT = 10;
    const defaultFilterOptions = createFilterOptions();
    const filterOptions = (options, state) => {
        return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
    };

    useEffect(() => {
        if (products.length > 0) {
            let arr = [];
            const prods = [...products].sort(function (a, b) {
                const a_prom = getAverageReview(a);
                const b_prom = getAverageReview(b);
                return (a_prom < b_prom) ? 1 : ((b_prom < a_prom) ? -1 : 0);
            });
            prods.map(_prod => {
                const { prod_variants, title, url } = _prod;
                prod_variants.map(e => {
                    const { _id: prod_var_id, variant_title, images } = e;
                    const _title = `${title} ${variant_title}`;
                    const img = images.length > 0 ?
                        `${GOOGLE_DRIVE_CDN}${images[0].id}` : no_image;
                    const alt = images.length > 0 ?
                        images[0].alt !== '' ?
                            `${GOOGLE_DRIVE_CDN}${images[0].alt}`
                            : 'producto-nefropolis'
                        : 'producto-nefropolis';
                    // arr.push({ prod_var_id, title: _title, url, img, alt });
                    arr = [...arr, { prod_var_id, title: _title, url, img, alt }];
                    return e;
                });
                return _prod;
            });
            setOptions(arr);
        }
    }, [products]);

    // obtener promedio de reseñas para filtro
    const getAverageReview = (p) => {
        let prom = 0; // promedio reseñas
        let count = 0 // número reseñas
        if (p.reviews.length > 0) {
            p.reviews.forEach(e => {
                prom += e.rating;
                count++;
            });
            // redondea promedio de reseñas
            prom = Math.round(prom / count);
        }
        return prom;
    };

    //Menu para usuario
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    // cerrar sesión
    const logout_fn = () => {
        dispatch(logout());
        window.location.reload();
    };

    useEffect(() => { // actualización de carrito
        if (item.length > 0) {
            const stock =
                item.filter(({ quantity }) =>
                    !(['', '0', 0].includes(quantity))).reduce((acum, { quantity }) =>
                        acum + quantity, 0);
            setItemCount(stock);
        } else setItemCount(0);
    }, [cart]);

    // redirigir a product
    const goToProductPage = (event, value) => {
        if (value) {
            const { prod_var_id, url } = value;
            dispatch(navigateToProductPage({ nav_url: url, nav_id: prod_var_id }));
        }
    };

    const [visible, setVisible] = useState(false)


    const [value, setValue] = useState("");

    const [openD, setOpenD] = useState(false);

    //Redirigir la busqueda a otro componente
    const handleSearch = (event) => {
        event.preventDefault();
        if (value)
            navigate(`/tienda/buscar/${value}`);
            // navigate("/tienda/buscar", { state: value, replace: true });
        setValue("")
        setOpenD(false)
    };

    const buttons = (
        <Box sx={{ display: "flex", justifyContent: 'center', py: 1 }}>
            <Button onMouseDown={(event) => { event.preventDefault(); }} onClick={handleSearch} sx={{ textTransform: 'none' }} size='small'>
                Ver todos los productos relacionados
            </Button>
        </Box>
    );

    return (
        <>
            <Box className='navbar_desktop' sx={{ position: 'fixed', width: '100%', zIndex: '3', top: 0 }}>
                <AppBar position="static" sx={{ background: '#4B4B4B' }}>
                    <Toolbar variant='dense'>
                        <Box sx={{ display: 'flex', gap: '15px', marginLeft: 'auto' }}>
                            <IconButton
                                component='a'
                                href='https://www.linkedin.com/company/nefropolis/'
                                target='_blank'
                                size="small"
                                edge="start"
                                color='inherit'
                                sx={[{ '&:hover': { color: '#fff' } }]}
                            >
                                <LinkedInIcon fontSize='small' />
                            </IconButton>
                            <IconButton
                                component='a'
                                href='https://www.youtube.com/@nefropolis1'
                                target='_blank'
                                size="small"
                                edge="start"
                                color='inherit'
                                sx={[{ '&:hover': { color: '#fff' } }]}
                            >
                                <YouTubeIcon fontSize='small' />
                            </IconButton>
                            <IconButton
                                component='a'
                                href='https://www.facebook.com/nefropolis'
                                target='_blank'
                                size="small"
                                edge="start"
                                color='inherit'
                                sx={[{ '&:hover': { color: '#fff' } }]}
                            >
                                <FacebookIcon fontSize='small' />
                            </IconButton>
                            <IconButton
                                component='a'
                                href='https://www.instagram.com/nefropolis/'
                                target='_blank'
                                size="small"
                                edge="start"
                                color='inherit'
                                sx={[{ '&:hover': { color: '#fff' } }]}
                            >
                                <InstagramIcon fontSize='small' />
                            </IconButton>
                        </Box>
                        <Box sx={{ ml: 2 }} component="form" onSubmit={handleSearch}>
                            <MuiAutocomplete
                                open={openD}
                                onOpen={() => setOpenD(true)}
                                onClose={() => {
                                    setOpenD(false)
                                    setValue("")
                                }}
                                disablePortal
                                options={options}
                                getOptionLabel={(option) => option.title}
                                filterOptions={filterOptions}
                                onChange={goToProductPage}
                                sx={{ width: 260 }}
                                renderOption={(props, option) => (
                                    <Box component="li"
                                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            src={option.img}
                                            srcSet={option.img}
                                            alt={option.alt} />
                                        {option.title}
                                    </Box>
                                )}
                                PaperComponent={value.length >= 1 ? CustomPaper : null}
                                componentsProps={{ paper: { button: buttons } }}
                                renderInput={(params) => <TextField {...params} size='small' placeholder={`${t("Buscar")}...`}
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                />
                                }
                            />
                        </Box>
                        <Box sx={{ ml: 3 }}>
                            <Link
                                to={"/carrito"}
                                style={{ textDecoration: 'none', color: '#000' }}
                            >
                                <StyledBadgeDesktop badgeContent={itemCount}>
                                    <ShoppingCartIcon sx={{ color: '#fff' }} />
                                </StyledBadgeDesktop>
                            </Link>
                        </Box>
                        <Box sx={{ ml: 3.5 }}>
                            {
                                email !== '' ?
                                    <Avatar onClick={openMenu}
                                        sx={{ width: 28, height: 28, bgcolor: '#fff', color: '#000', cursor: 'pointer' }}
                                    >
                                        { username.charAt(0).toUpperCase() }
                                    </Avatar>
                                    :
                                    <Avatar component={Link} to='/login'
                                        sx={[
                                            {
                                                width: 28, height: 28, bgcolor: '#fff', color: '#000'
                                            },
                                            {
                                                '&:hover': {
                                                    color: '#000',
                                                },
                                            },
                                        ]}>
                                    </Avatar>
                            }
                        </Box>
                        {
                            email !== '' &&
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={closeMenu}
                                onClick={closeMenu}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 9,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem
                                    component={Link}
                                    to={
                                        email !== '' ?
                                            privileges && privileges.main ?
                                                '/nefro-admin'
                                                :
                                                '/mi-cuenta'
                                            : '/login'
                                    }
                                    sx={[{ '&:hover': { color: '#000' } }]}
                                >
                                    <Avatar />
                                    {
                                        email !== '' ?
                                            privileges && privileges.main ?
                                                'Nefro admin'
                                                :
                                                `${t("Cuenta")}`
                                            :
                                            `${t("Cuenta")}`
                                    }
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                    sx={[{ '&:hover': { color: '#000' } }]}
                                    component={Link}
                                    // to={email !== '' && '/mi-cuenta/informacion-y-seguridad'}
                                    to={
                                        email !== '' ?
                                            privileges && privileges.main ?
                                                '/mi-cuenta'
                                                :
                                                '/mi-cuenta/informacion-y-seguridad'
                                            :
                                            '/mi-cuenta/informacion-y-seguridad'
                                    }
                                >
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                    {t("Configuracion")}
                                </MenuItem>
                                <MenuItem onClick={logout_fn}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    {t("Cerrar_sesion")}
                                </MenuItem>
                            </Menu>
                        }
                        {/* <LangSelect /> */}
                    </Toolbar>
                </AppBar>

                <AppBar position="static" className={navbar ? "appbar active" : "appbar"} sx={{ borderRadius: '0 0 35px 35px', px: 4 }}>
                    <Toolbar sx={{ height: '65px' }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Link to='/'>
                                <img src={logo} alt={logo} width='120' />
                            </Link>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '20px' }} className='links__'>
                            <Button
                                size={matches ? 'small' : 'normal'}
                                LinkComponent={NavLink} to='/'
                                sx={{ color: 'black', fontWeight: '600' }}>{t('Inicio')}</Button>
                            <Button
                                size={matches ? 'small' : 'normal'}
                                LinkComponent={NavLink} to='/sobre-nefropolis'
                                sx={{ color: 'black', fontWeight: '600' }}>{t('Sobre_nosotros')}</Button>
                            <Button
                                size={matches ? 'small' : 'normal'}
                                LinkComponent={NavLink} to='/sucursales'
                                sx={{ color: 'black', fontWeight: '600' }}>{t("Sucursales")}</Button>
                            <Button
                                size={matches ? 'small' : 'normal'}
                                LinkComponent={NavLink} to='/tienda'
                                sx={{ color: 'black', fontWeight: '600' }}>{t('Tienda')}</Button>
                            <Button
                                size={matches ? 'small' : 'normal'}
                                LinkComponent={HashLink} to="/#servicios"
                                sx={{ color: 'black', fontWeight: '600' }}>{t("Servicios")}</Button>
                            {/* <Button
                                size={matches ? 'small' : 'normal'}
                                LinkComponent={NavLink} to='/directorio-medico'
                                sx={{ color: 'black', fontWeight: '600' }}>{t("Medicos")}</Button> */}
                            <ColorButton
                                size={matches ? 'small' : 'normal'}
                                variant='contained' onClick={handleRef}>{t("Contacto")}</ColorButton>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>

            <div className='search_bar'>
                {/* Navbar tablet and mobile */}
                <AppBar position="sticky" className='navbar_mobile' sx={{ bgcolor: '#fff' }}>
                    <Toolbar sx={{ height: '60px' }}>
                        <IconButton
                            size="large"
                            edge="start"
                            sx={{ mr: !visible ? 2 : 0, color: '#94CC27' }}
                            onClick={() => { setSidebar(true) }}
                        >
                            <MenuIcon fontSize='large' />
                        </IconButton>
                        <Animated animationOut="fadeOut" isVisible={!visible}>
                            {
                                !visible &&
                                <Box>
                                    <Link to='/'>
                                        <img src={logo} alt={logo} width='100' />
                                    </Link>
                                </Box>
                            }
                        </Animated>
                        <Box sx={{ flexGrow: 1 }} />

                        <SearchMobile
                            options={options}
                            goToProductPage={goToProductPage}
                            visible={visible}
                            setVisible={setVisible}
                        />

                        <IconButton
                            size="large"
                            component={Link}
                            to="/carrito"
                            edge="end"
                            sx={[
                                { color: '#000', my: 'auto' },
                                {
                                    '&:hover': {
                                        color: '#000',
                                    },
                                },
                            ]}
                        >
                            <Badge badgeContent={itemCount} color="primary">
                                <ShoppingCartIcon />
                            </Badge>
                        </IconButton>

                    </Toolbar>
                </AppBar>
            </div>

            <HeaderSidebar
                sidebar={ sidebar }
                setSidebar={ setSidebar }
                logout_fn={ logout_fn }
            />
        </>
    );
};

export default Header;