import { msg } from "../../../actions/swal_msg";

// formulario para guardar nueva variante
export const form1Validator = ({
    price, description, sat_key, iva,
    claveUnidad, unidad, barcode,
    stock, images
}) => {
    const price_len = price.trim().length;
    const desc_len = description.trim().length;
    const key_len = sat_key.trim().length;
    const clave_len = claveUnidad.trim().length;
    const unidad_len = unidad.trim().length;
    const barcode_len = barcode.trim().length;
    const stock_len = stock.trim().length;
    if (price_len > 1 && price_len <= 8) {
        if (desc_len > 2 && desc_len <= 2000) {
            if (key_len === 8) {
                if (iva === 0 || iva === 16) {
                    if (clave_len === 3) {
                        if (unidad_len > 1 && unidad_len <= 64) {
                            if (barcode_len > 0 && barcode_len <= 32) {
                                if (stock_len > 0 && stock_len <= 5) {
                                    if (images.length > 0 && images.length <= 6)
                                        return true;
                                    else
                                        msg('warning', 'Variante',
                                        'Ingrese por lo menos una imagen (6 max)');
                                } else
                                    msg('warning', 'Variante',
                                    'Ingrese existencias de variante');
                            } else
                                msg('warning', 'Validación',
                                'Ingrese código de barras');
                        } else
                            msg('warning', 'Validación',
                            'Ingrese unidad de producto');
                    } else
                        msg('warning', 'Validación',
                        'Ingrese clave de unidad (3 dígitos)');
                } else
                    msg('warning', 'Validación',
                    'Seleccione IVA (0/16%)');
            } else
                msg('warning', 'Validación',
                'Ingrese clave SAT (8 dígitos)');
        } else
            msg('warning', 'Validación',
            'Ingrese descripción de variante');
    } else
        msg('warning', 'Validación',
        'Ingrese precio de variante');
    return false;
};