import React, { useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import PaginationComponent from "../Pagination/Pagination";

import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';

import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

import {
    Divider, Box, Grid, Typography, Pagination
} from '@mui/material';

const PER_PAGE = 12;

const ShopMoreProducts = ({ moreProducts }) => {
    const { text, products } = moreProducts;
    const { pathname } = useLocation();

    // pagination
    let [page, setPage] = useState(1);
    const count = Math.ceil(products.length / PER_PAGE);
    const _DATA = PaginationComponent(products, PER_PAGE);

    const handleChangePagination = (e, p) => {
        setPage(p);
        _DATA.jump(p);
        myRef.current.scrollIntoView()
    };

    const myRef = useRef(null);

    return (
        <Grid item xl={10} lg={9} md={12} sm={12} xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant='h5'
                sx={{ fontWeight: 'light' }}
                paragraph>
                { text }
            </Typography>
            <Grid container spacing={3}>
                {
                    _DATA.currentData().map(e => {
                        const img = e.prod_variants[0].images.length > 0 ?
                            {
                                id: `${GOOGLE_DRIVE_CDN}${e.prod_variants[0].images[0].id}`,
                                alt: e.prod_variants[0].images[0].alt
                            }
                        :
                            {
                                id: no_image,
                                alt: 'producto-nefropolis'
                            };

                        // precios variantes
                        const prices = e.prod_variants.map(e => e.price);
                        const min_price = Math.min(...prices);
                        const max_price = Math.max(...prices);

                        // producto sin variantes?
                        let unique = false;
                        if (e.prod_variants.length <= 0
                            || min_price === max_price)
                            unique = true;

                        let review_prom = 0; // promedio reseñas
                        let review_count = 0 // número reseñas
                        if (e.reviews.length > 0) {
                            e.reviews.forEach(element => {
                                review_prom += element.rating;
                                review_count++;
                            });
                            // redondea promedio de reseñas
                            review_prom = Math.round(review_prom / review_count);
                        }
                        return (
                            <Grid item xl={3} lg={4} md={4} sm={6} xs={12} key={e._id}>
                                <Grid container spacing={2}>
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={5}
                                        sx={{ my: 'auto', textAlign: 'center' }}>
                                        <Link to={`/tienda/producto/${e.url}`}
                                            state={{ previousPath: pathname }}>
                                            <img src={img.id} className="category-product"
                                                width='100%' style={{ objectFit: 'contain' }}
                                                alt={img.alt} />
                                        </Link>
                                    </Grid>

                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={7} sx={{ my: 'auto' }}>
                                        <Link to={`/tienda/producto/${e.url}`}
                                            className="text-reset text-decoration-none">
                                            <Typography
                                                sx={{
                                                    fontWeight: '600',
                                                    textAlign: { xs: 'left', sm: 'center', md: 'center' }
                                                }}
                                                variant="body1"
                                                component="div"
                                            >
                                                { e.title }
                                            </Typography>
                                        </Link>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: { xs: 'flex-start', sm: 'center', md: 'center' }
                                        }}>
                                            {
                                                unique ?
                                                    <Typography variant='h6' sx={{ color: '#F90606', fontWeight: '600' }}>
                                                        ${ min_price }
                                                    </Typography>
                                                    :
                                                    <Typography variant='h6' sx={{ color: '#F90606', fontWeight: '600' }}>
                                                        ${ min_price } - ${ max_price }
                                                    </Typography>
                                            }
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: { xs: 'flex-start', sm: 'center', md: 'center' }
                                        }}>
                                            <div className="ratings-shop">
                                                {
                                                    review_count > 0 ?
                                                        Array.from(Array(review_prom), (e, j) => {
                                                            return <i className="fas fa-star"
                                                                style={{
                                                                    color: '#FFA41C',
                                                                    stroke: "#FFA41C",
                                                                    strokeWidth: 0.2
                                                                }}
                                                                key={j}></i>
                                                        })
                                                        :
                                                        <>
                                                            <i className="far fa-star"></i>
                                                            <i className="far fa-star"></i>
                                                            <i className="far fa-star"></i>
                                                            <i className="far fa-star"></i>
                                                            <i className="far fa-star"></i>
                                                        </>
                                                }
                                            </div>
                                        </Box>

                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                <Pagination
                    color="primary"
                    count={ count }
                    page={ page }
                    onChange={ handleChangePagination }
                />
            </Box>
        </Grid>
    );
};

export default ShopMoreProducts;