import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { generateURL } from '../../../../../helpers/admin/productHelper';

import {
    Checkbox, List, ListSubheader, ListItemButton,
    ListItemIcon, ListItemText, Collapse,
    ListItem, IconButton
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

const CategoryList = ({ setProdValues }) => {
    // lista de categorías
    const { categories } = useSelector(state => state.category);

    // categorías
    const [allCategories, setCategories] = useState([]);

    useEffect(() => {
        setCategories(
            categories.map(e => ({
                ...e,
                checked: false,
                subcategory_list: e.subcategory.map(u => ({ subcategory: u, sub_checked: false }))
            }))
        );
    }, [categories]);

    // selecciona categoría
    const handleChange = (pos, checked, category) => () => {
        const category_url = generateURL(category);
        setProdValues(e => ({
            ...e,
            category: !checked ?
                [...e.category, category]
            :
                [...e.category.filter(u => u !== category)],
            category_url: !checked ?
                [...e.category_url, category_url]
            :
                [...e.category_url.filter(u => u !== category_url)]
        }));

        setCategories(
            allCategories.map((e, i) => i === pos ? { ...e, checked: !e.checked } : e)
        );
    };

    // selecciona subcategoría
    const handleSubChange = (pos, checked, subcategory) => () => {
        setProdValues(e => ({
            ...e,
            subcategory: !checked ?
                [...e.subcategory, subcategory]
            :
                [...e.subcategory.filter(u => u !== subcategory)],
        }));

        setCategories(
            // allCategories.map((e, i) => i === pos1 ?
            //     {
            //         ...e,
            //         subcategory_list: [
            //             ...e.subcategory_list.map((u, j) => j === pos2 ?
            //                 { ...u, sub_checked: !u.sub_checked }
            //                 : u
            //             )
            //         ]
            //     }
            //     : e
            // )
            allCategories.map(e =>
                ({
                    ...e,
                    subcategory_list: [
                        ...e.subcategory_list.map((u, j) => j === pos ?
                            { ...u, sub_checked: !u.sub_checked }
                            : u
                        )
                    ]
                })
            )
        );
    };

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="category-list"
            subheader={
                <ListSubheader component="div" id="category-list-subheader">
                    Categorías
                </ListSubheader>
            }>
            {
                allCategories.map(({ _id, category, subcategory_list, checked }, i) =>
                    <React.Fragment key={_id}>
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end" aria-label="comments">
                                    { checked ? <ExpandLess /> : <ExpandMore /> }
                                </IconButton>
                            }
                            disablePadding
                        >
                            <ListItemButton
                                role={ undefined }
                                onClick={ handleChange(i, checked, category) }
                                dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={ checked }
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText primary={ category } />
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={ checked } timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    subcategory_list.length <= 0 ?
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <DoNotDisturbOnIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="No hay subcategorías" />
                                        </ListItemButton>
                                    :
                                        subcategory_list.map(({ subcategory, sub_checked }, j) => 
                                            <ListItemButton sx={{ pl: 4 }} key={subcategory}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={ sub_checked }
                                                        onChange={ handleSubChange(j, sub_checked, subcategory) }
                                                        tabIndex={-1}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={ subcategory }
                                                />
                                            </ListItemButton>
                                        )
                                }
                            </List>
                        </Collapse>
                    </React.Fragment>
                )
            }
    </List>
    );
};

export default CategoryList;