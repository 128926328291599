import React, { useState, useEffect } from 'react'

//CSS
import './sucursales.css'

//MUI
import { Container, Typography, useMediaQuery } from '@mui/material';

//Components
import CardSucursal from './CardSucursal';

//Utils
import { SucursalesData } from '../../data/Sucursales/SucursalesData';
import { CustomFetch } from '../../Utils/CustomFecth';

const SucursalesComponent = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    const [sucursal, setSucursal] = useState([])

    useEffect(() => {
        CustomFetch(SucursalesData).then(data => {
            setSucursal(data)
        })
    }, [])

    return (
        <>
            <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18 }} className="map">
                <iframe src="https://www.google.com/maps/d/u/4/embed?mid=1s5j_fBzN7qDS0E4jXgpcN6CDecfDg_o&ehbc=2E312F&noprof=1"
                    width="100%" height="500" style={{ border: '0', overflowX: 'hidden' }} title="Sucursales en México">
                </iframe>
            </Container>

            <Container maxWidth="xl" sx={{ mt: 5 }}>
                <Typography variant="h5" align='center'>
                    Contamos con <b>14</b> sucursales y <b>1</b> distribuidor autorizado dentro del país.
                </Typography>
            </Container>

            <Container maxWidth="xl" sx={{ mt: 5 }} className="last-view">
                <CardSucursal sucursal={sucursal} />
            </Container>
        </>
    )
}

export default SucursalesComponent