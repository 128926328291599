import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { adminUserDialogToggle } from '../../../actions/ui';

import AdminPwd from './AdminPwd';
import AdminForm from './AdminForm';
import AdminPrivileges from './AdminPrivileges';

import {
    Dialog, AppBar, Toolbar, IconButton,
    Avatar, Divider, Slide, Box, Container, Typography, Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AdminDialog = () => {
    const dispatch = useDispatch();
    // referencias a campos
    const eInputRef = useRef();
    const uInputRef = useRef();
    const rInputRef = useRef();
    //Ref para privilegios
    const myRef = useRef(null);

    const { activeAdmin } = useSelector(state => state.admin);
    const { adminUserDialog } = useSelector(state => state.ui);

    const [values, setValues] = useState(activeAdmin);
    const { username, role } = values;
    // errores en campos
    const [inputErr, setInputErr] = useState({
        username_err: false,
        email_err: false
    });
    
    useEffect(() => {
        setValues(activeAdmin);
    }, [activeAdmin]);

    const handleCloseDialog = () => {
        setPrivilegios(false);
        dispatch(adminUserDialogToggle(false));
    };

    //Privilegios
    const [privilegios, setPrivilegios] = useState(false)
    const togglePrivilegios = () => {
        setPrivilegios(true)
        setTimeout(() => {
            myRef.current.scrollIntoView()
        }, 100)
    };

    //Tabs
    const [tabs, setTabs] = useState('1');
    const handleChangeTabs = (event, newValue) => {
        setTabs(newValue);
    };

    return (
        <Dialog
            fullScreen
            open={adminUserDialog}
            onClose={handleCloseDialog}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: '#607d8b', color: 'white' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {/* Administrar usuario */}
            <Container maxWidth='xl' sx={{ my: 5 }}>
                <Box sx={{ position: 'relative' }}>
                    <div className='full-img-profile'>
                        <div className='user-avatar-profile'>
                            <Avatar
                                sx={{ width: 160, height: 160, fontSize: '3rem', backgroundColor: '#2699FB', textTransform: 'uppercase' }}
                            >
                                {username.charAt(0)}</Avatar>

                        </div>
                    </div>
                </Box>
                <Box sx={{ p: 3 }} className='box-shadow-profile'>
                    <Box
                        className='info-profile'
                        sx={{ mt: '5px', ml: '240px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}
                    >
                        <Box>
                            <Typography variant='h5' sx={{ fontWeight: 'bold' }} gutterBottom>{username}</Typography>
                            <Typography variant='body1' style={{ color: '#2980B9' }} gutterBottom>
                                <i className="fa-solid fa-earth-americas"></i> Miembro del grupo - {role}
                            </Typography>
                        </Box>
                        <Box>
                            <Button variant='contained' onClick={togglePrivilegios} sx={{ textTransform: 'none' }}>Administrar privilegios</Button>
                        </Box>
                    </Box>
                    <Divider sx={{ my: 2 }}></Divider>
                    <TabContext value={tabs}>
                        <Box sx={{ mb: 0 }}>
                            <TabList onChange={handleChangeTabs}>
                                <Tab label="Información" value="1" />
                                <Tab label="Contraseña" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <AdminForm
                                values={values}
                                setValues={setValues}
                                inputErr={inputErr}
                                setInputErr={setInputErr}
                                eInputRef={eInputRef}
                                uInputRef={uInputRef}
                                rInputRef={rInputRef}
                                handleCloseDialog={() => { handleCloseDialog() }}
                                />
                        </TabPanel>
                        <TabPanel value="2">
                            <AdminPwd handleCloseDialog={() => { handleCloseDialog() }} />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Container>

            {privilegios ?
                <AdminPrivileges
                    values={values}
                    setValues={setValues}
                    inputErr={inputErr}
                    setInputErr={setInputErr}
                    myRef={myRef}
                    eInputRef={eInputRef}
                    uInputRef={uInputRef}
                    rInputRef={rInputRef}
                    handleCloseDialog={() => { handleCloseDialog() }}
                />
                : null}
        </Dialog>
    );
};

export default AdminDialog;