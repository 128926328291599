import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { shopSnackbarToggle } from '../../actions/ui';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const SnackbarErr1 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // error en petición
    const { shopSnackbar } = useSelector(state => state.ui);
    const { status, origin_page, err_msg } = shopSnackbar;

    useEffect(() => {
        if (origin_page === 'product-sample') {
            navigate('/tienda');
            dispatch(shopSnackbarToggle({
                status: false,
                err_type: '',
                err_msg: ''
            }));
        }
    }, [origin_page]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        dispatch(shopSnackbarToggle({
            status: false,
            err_type: '',
            err_msg: ''
        }));
    };

    const action = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={ handleClose }>
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    return (
        <Snackbar
            open={ status }
            autoHideDuration={ 6000 }
            onClose={ handleClose }
            message={ err_msg }
            action={ action }
        />
    )
};

export default SnackbarErr1;
