import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Components
import BlogItemAdmin from './BlogItemAdmin';

//MUI
import {
    Button, Container, Grid, Typography, Alert,
    Stack
} from '@mui/material';
import BookIcon from '@mui/icons-material/Book';

const Blog = () => {
    const { blog } = useSelector(state => state.blog);

    return (
        <Container maxWidth={ false }
            sx={{ backgroundColor: '#F5F5F5', p: 4, height: '100vh' }}>
            <Stack direction="row"
                alignItems="flex-start"
                spacing={12}>
                <Typography variant='h5' gutterBottom>Blog</Typography>
                <Link to="/nefro-admin/nuevo-blog" style={{ textDecoration: 'none' }}>
                    <Button
                        variant='outlined'
                        sx={{ mb: 3, textTransform: 'none' }}
                        endIcon={<BookIcon />}
                    >
                        Crear nuevo blog
                    </Button>
                </Link>
            </Stack>
            
            <Grid container spacing={4}>
                {
                    blog.length > 0 ?
                        blog.map((item, i) => (
                            <BlogItemAdmin
                                key={i}
                                item={ item }
                            />
                        ))
                    :
                        <Grid item xs={4}>
                            <Alert severity="info"
                                sx={{ bgcolor: 'background.paper' }}>
                                Sin entradas de blog.
                            </Alert>
                        </Grid>
                }
            </Grid>
        </Container>
    );
};

export default Blog;