import React, { useState } from 'react'

//CSS
import '../SignUp.css'

//MUI
import {
    IconButton, InputLabel, MenuItem, FormControl, Select, Grid, FormGroup, FormControlLabel, Checkbox,
    Container,
    Box,
    Typography,
    Button
} from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const InformacionInteres = (props) => {

    //Primer select
    const [first, setFirst] = useState('');
    const handleChangeFirst = (event) => {
        setFirst(event.target.value);
    };

    //Segundo select
    const [second, setSecond] = useState('');
    const handleChangeSecond = (event) => {
        setSecond(event.target.value);
    };

    return (
        <div id="step2" style={{ height: '100%' }}>
            <Container maxWidth='xl' sx={{ my: 5 }}>
                {/* Title header */}
                <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <Box sx={{ my: 'auto', mr: 1 }}>
                        <IconButton onClick={props.prevStep} color="primary">
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ my: 'auto' }}>
                        <Typography variant='h4' align='center' sx={{ fontWeight: 'light' }}>Información de interés</Typography>
                    </Box>
                </Box>
                {/* Fin title */}

                <Box sx={{ mt: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Soy...</InputLabel>
                                <Select
                                    id=""
                                    value={first}
                                    label="Soy..."
                                    onChange={handleChangeFirst}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>¿Cómo nos conoció?</InputLabel>
                                <Select
                                    id=""
                                    value={second}
                                    label="¿Cómo nos conoció?"
                                    onChange={handleChangeSecond}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ mt: 5 }}>
                    <Typography variant='h6' align='center' sx={{ fontWeight: 'bold' }}>¿Qué tipo de contenido le interesa ver?</Typography>
                    <Grid container spacing={0}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Diálisis" />
                                <FormControlLabel control={<Checkbox />} label="Prediálisis" />
                                <FormControlLabel control={<Checkbox />} label="Productos" />
                            </FormGroup>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Promociones" />
                                <FormControlLabel control={<Checkbox />} label="Nutrición" />
                                <FormControlLabel control={<Checkbox />} label="Recetas" />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ mt: 3 }}>
                    <Button variant='contained' onClick={props.nextstep}>Continuar</Button>
                </Box>
            </Container>
        </div>
    )
}

export default InformacionInteres