import React from 'react'

//Owl carousel
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

//Images
// import promocion_1 from '../../../assets/resources/sucursales/alimentos_nefropolis.jpg'
// import promocion_2 from '../../../assets/resources/sucursales/nefrolealtad.png'
// import promocion_3 from '../../../assets/resources/sucursales/Recurso-3.png'
// import promocion_4 from '../../../assets/resources/sucursales/promocional.png'
// import promocion_5 from '../../../assets/resources/sucursales/Promo_Nepro-HP.jpeg'
// import promocion_6 from '../../../assets/resources/sucursales/Todos-los-dias-Nefrolealtad.png'
import logo_whatsapp from '../../../assets/resources/sucursales/Logo_whatsApp.png'
import juarez from '../../../assets/resources/sucursales/IMG-20220901-WA0045.jpg'
import fb from '../../../assets/resources/sucursales/Recurso.png'

//MUI
import { Avatar, Box, Button, Container, Grid, Typography, useMediaQuery } from '@mui/material';

const SucJuarez = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    const options = {
        margin: 10,
        nav: false,
        dots: true,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            800: {
                items: 3,
            },
            1000: {
                items: 3,

            }
        },
    };

    return (
        <>
            {/* Sucursal header */}
            <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18 }}>
                <Typography variant='h4' align='center' sx={{ fontWeight: '600' }}>
                    Ciudad Juarez
                </Typography>
            </Container>
            {/* Fin Sucursal header */}

            {/* sucursal info */}
            <Container maxWidth="xl" sx={{ mt: 5 }}>

                {/* Banner */}
                <Box
                    sx={{ display: 'flex', justifyContent: 'center' }}
                >
                    <Avatar src={juarez} alt={juarez} sx={{ width: '440px', height: '440px' }}></Avatar>
                </Box>

                <Typography variant='h5' align='center' sx={{ fontWeight: '600', mt: 3 }}>
                    SOMOS UNA CADENA DE TIENDAS ESPECIALIZADAS EN LA VENTA DE ALIMENTOS Y SUPLEMENTOS PARA PERSONAS CON ENFERMEDAD RENAL.
                </Typography>

                {/* Información de sucursal */}
                <OwlCarousel className='owl-theme' {...options} style={{ marginTop: '30px', textAlign: 'center' }}>
                    <div className='item'>
                        <i className="fa-solid fa-location-dot" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Dirección</Typography>
                        <Typography variant="body1" gutterBottom>
                            Av. Valentín Fuentes Varela 2571 Col. Casas Grandes, C.P. 32600 Cd Juárez, Chih.
                        </Typography>
                        <a href='https://www.google.com/search?q=nefropolis%20ciudad%20juarez&ei=3AEGZIiIJtirqtsP-ZK70A8&ved=2ahUKEwj239zUysf9AhVll2oFHd9_BqAQvS56BAgREAE&uact=5&oq=nefropolis+ciudad+juaarez&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIHCCEQoAEQCjIHCCEQoAEQCjoNCAAQjwEQ6gIQtAIYAToNCC4QjwEQ6gIQtAIYAToRCC4QgAQQsQMQgwEQxwEQ0QM6BAguEEM6CwguEIAEELEDEIMBOggIABCABBCxAzoLCAAQgAQQsQMQgwE6BQguEIAEOg4ILhCABBCxAxDHARDRAzoFCAAQgAQ6EAguELEDEIMBEMcBENEDEEM6BwguELEDEEM6BAgAEEM6BwguENQCEEM6EwguEIAEELEDEIMBEMcBENEDEAo6BwgAELEDEEM6BggAEAoQQzoLCC4QgAQQxwEQrwE6CQgAEBYQHhDxBDoGCAAQFhAeOgUIIRCgAUoECEEYAFCnB1jZKGDeKWgBcAB4AIABcIgB7xCSAQQyMy4ymAEAoAEBsAEKwAEB2gEECAEYCg&sclient=gws-wiz-serp&tbs=lf:1,lf_ui:10&tbm=lcl&rflfq=1&num=10&rldimm=925822802364982241&lqi=ChhuZWZyb3BvbGlzIGNpdWRhZCBqdWFyZXpaGiIYbmVmcm9wb2xpcyBjaXVkYWQganVhcmV6kgEMbnV0cml0aW9uaXN0qgESEAEqDiIKbmVmcm9wb2xpcygO&phdesc=jd2uNrU7mAk&sa=X&rlst=f#rlfi=hd:;si:925822802364982241,l,ChhuZWZyb3BvbGlzIGNpdWRhZCBqdWFyZXpaGiIYbmVmcm9wb2xpcyBjaXVkYWQganVhcmV6kgEMbnV0cml0aW9uaXN0qgESEAEqDiIKbmVmcm9wb2xpcygO,y,jd2uNrU7mAk;mv:[[31.736940899999997,-106.4258893],[31.704339500000003,-106.4371651]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!2m1!1e3!3sIAE,lf:1,lf_ui:10' target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                            <Button variant='contained'>Ver en mapa</Button>
                        </a>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-address-book" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Contacto</Typography>
                        <Typography variant="body1">
                            Teléfono: 65 6198 4826  <br /> cdjuarez@nefropolis.com
                        </Typography>
                    </div>
                    <div className='item'>
                        <i className="fa-regular fa-clock" style={{ fontSize: '2rem', marginBottom: '10px' }}></i>
                        <Typography variant="h5" sx={{ fontWeight: 'light', color: '#00a7e2' }} gutterBottom>Horario</Typography>
                        <Typography variant="body1">
                            Lunes a Viernes <br /> 9:30 am - 2 pm, 2:30 pm - 6 pm <br /> Sábado <br /> 10:00 am – 2:30 pm
                        </Typography>
                    </div>
                </OwlCarousel>
                {/* Fin Información de sucursal */}

                {/* enlace redes  */}
                <Grid container spacing={4} sx={{ textAlign: 'center', mt: 5 }}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12} sx={{ m: 'auto' }}>
                        <a href="https://wa.me/526561984826?text=Buen%20día,%20me%20gustaría%20obtener%20información%20"
                            target="_blank" rel="noopener noreferrer">
                            <img src={logo_whatsapp} width="80%" className='social-img social-whats' alt={logo_whatsapp} />
                        </a>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12} sx={{ m: 'auto' }}>
                        <a href="https://www.facebook.com/people/Distribuidor-autorizado-Nefr%C3%B3polis/100083386440293/"
                            target="_blank" rel="noopener noreferrer">
                            <img src={fb} alt={fb} width="60%" className='social-img' />
                        </a>
                    </Grid>
                </Grid>
                {/* Fin enlace redes  */}

                <Box sx={{ textAlign: 'center', mt: 5 }}>
                    <Typography variant='body1' sx={{ color: '#00a7e2', fontWeight: 'bold' }}>
                        ¡Visita tu sucursal más cercana!
                    </Typography>
                    <Typography variant="h6" sx={{ color: '#00a7e2', fontWeight: 'bold' }}>
                        CONTAMOS CON MÁS DE 80 PRODUCTOS
                    </Typography>
                </Box>
            </Container>
            {/* Fin sucursal info */}

            {/* Map */}
            <Container maxWidth="xl" sx={{ mt: 1 }} className="last-view">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3393.221876510533!2d-106.42984283491535!3d31.737144231298366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86e75be46ba3a2e5%3A0xff95c325490f2df7!2sNefropolis!5e0!3m2!1ses-419!2smx!4v1662076736009!5m2!1ses-419!2smx"
                    width="100%" height="450" title='juarez' style={{ border: '0' }} allowFullScreen="" loading="lazy"></iframe>
            </Container>
            {/* Fin Map */}

            {/* Promociones */}
            {/* <Container maxWidth="xl" sx={{ mt: 5 }} className="last-view">
                <Typography variant="h4" sx={{ fontWeight: '600' }} align='center'>Promociones en sucursal</Typography>
                <Grid container spacing={4} sx={{ mt: 1 }}>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_1} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_2} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_3} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_4} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_5} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                        <img src={promocion_6} width="100%" style={{ border: '1px solid #E5E8E8' }} alt="promocion" />
                    </Grid>
                </Grid>
            </Container> */}
            {/* Fin Promociones */}
        </>
    )
}

export default SucJuarez