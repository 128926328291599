import React, { useState, useEffect } from 'react'

import banner_1 from '../../../assets/images/banners/Banner_Desktop_DM_02.png'

//Router dom
import { useParams } from 'react-router-dom'

//Utils
import { MedicalCustomFetch } from '../MedicalCustomFetch'
import { MedicalData } from '../MedicalData'
import { Container } from '@mui/material'
import MedicalDirectoryDetail from '../MedicalDirectoryDetail/MedicalDirectoryDetail'

const MedicalDirectoryDetailContainer = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const { name } = useParams()

    useEffect(() => {
        setLoading(false)
        MedicalCustomFetch(MedicalData)
            .then(res => {
                setData(res.find(listProducts => listProducts.name === name))
                setLoading(true)
            })
    }, [name])

    // console.log(data)

    return (
        <div className='bg_medical_detail'>
            <img src={banner_1} alt={banner_1} width='100%' />
            
            <Container maxWidth='xl' sx={{ mt: 5 }} className='last-view'>
                {
                    loading
                        ?
                        <MedicalDirectoryDetail data={data} />
                        :
                        <>Loading...</>
                }
            </Container>
        </div>
    )
}

export default MedicalDirectoryDetailContainer