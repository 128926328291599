import { axiosProtectedReq } from '../../helpers/requestHelper';
// import { msg } from '../swal_msg';

// obtener imágenes de banners
export const bannerList = async() => {
    const request = await axiosProtectedReq('banner/list');
    if (!request.status) {
        if (request.msg !== 'no-results') {
            let txt = 'Ocurrió una incidencia';
            if (request.msg === 'server-err')
                txt = 'Error en servidor';
            else if (request.msg === 'request-err')
                txt = 'Error en petición';
            alert(txt);
        }
    }
    return request;
};

// subir imágenes para banner
export const uploadBannerImages = async(banner, type, images, deletedImages) => {
    const { _id, images: uploadedImages } = banner;
    const data = new FormData();
    data.append('_id', _id);
    data.append('type', type);
    data.append('url', JSON.stringify(images.map(item => item.url)));
    data.append('uploadedImages', JSON.stringify(uploadedImages));
    data.append('deletedImages', JSON.stringify(deletedImages));
    for (const item of images) {
        data.append('file', item.img);
    };
    const request = await axiosProtectedReq('banner/upload-images', data, 'POST');
    if (!request.status) {
        let txt = 'Ocurrió una incidencia';
        if (request.msg === 'server-err')
            txt = 'Error en servidor';
        else if (request.msg === 'request-err')
            txt = 'Error en petición';
        alert(txt);
    }
    return request;
};