import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import es from "date-fns/locale/es";
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { blogView } from '../../constants/admin/blog_constants';
import { GOOGLE_DRIVE_CDN } from '../../constants/constants';

//MUI
import {
  Box, Button, Card, CardContent, CardMedia, Container,
  Grid, Tooltip, Typography, useMediaQuery
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

const Blog = () => {
  const navigate = useNavigate();
  const { userBlog } = useSelector(state => state.blog);

  const { url } = useParams();

  const matches = useMediaQuery('(max-width: 950px)')

  // blog visualizado
  const [item, setItem] = useState(blogView);
  // otros blogs
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (userBlog.length > 0) {
      const blog = userBlog.find(e => e.url === url);
      if (blog)
        setItem({
          ...blog,
          publication_date: `${format(new Date(blog.publication_date), "d 'de' MMMM yyyy", { locale: es })}`
        });
      else navigate('/blog');
    }
  }, [url]);

  useEffect(() => {
    if (userBlog.length > 0) {
      setItems(shuffleArray(userBlog));
      const blog = userBlog.find(e => e.url === url);
      if (blog)
        setItem({
          ...blog,
          publication_date: `${format(new Date(blog.publication_date), "d 'de' MMMM yyyy", { locale: es })}`
        });
      else navigate('/blog');
    }
  }, [userBlog]);

  function shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array.slice(0, 4);
  };

  const [copySuccess, setCopySuccess] = useState("Copiar");

  const handlecopyToClip = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopySuccess("¡Enlace copiado al portapapeles!");
    setTimeout(() => {
      setCopySuccess("Copiar");
    }, 2000);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18 }} className="last-view">
      <Grid container spacing={6}>
        <Grid item xl={8.5} lg={8.5} md={12} sm={12} xs={12}>
          <Typography variant="h4" sx={{ fontWeight: '600' }} gutterBottom>
            {item.title}
          </Typography>
          <Typography variant='body1' paragraph>
            Última actualización: {item.publication_date}
          </Typography>
          <Box sx={{ mb: 3 }}>
            {/* <img src={item.img} alt={item.img} width="100%" /> */}
            <img src={`${GOOGLE_DRIVE_CDN}${item.imgId}`} alt={'blog-Nefropolis'} width="100%" />
          </Box>
          <Grid container spacing={2}>
            {
              item.content.map((e, i) =>
                <Grid item xs={12} key={i}>
                  <Grid container>
                    {
                      e.col.map((u, j) =>
                        <Grid item xs={u.xs} md={u.md} key={j}>
                          <div dangerouslySetInnerHTML={{ __html: u.item }}></div>
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>
              )
            }
          </Grid>

          <Box sx={{ my: 3 }}>
            <Typography variant='body1'>
              <b>Autor: </b>{item.author}
            </Typography>
          </Box>

          <Tooltip title={copySuccess}>
            <Button variant='contained' onClick={handlecopyToClip} sx={{ textTransform: 'none' }}
              endIcon={<ShareIcon />}
            >Compartir</Button>
          </Tooltip>
        </Grid>

        <Grid item xl={3.5} lg={3.5} md={12} sm={12} xs={12}>
          <Grid container spacing={4}>
            {
              items.map((e, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Card sx={{ width: '100%' }}>
                      <CardMedia
                        sx={{ height: 180 }}
                        image={`${GOOGLE_DRIVE_CDN}${e.imgId}`}
                        title={e.title}
                        component={Link}
                        to={`/blog/${e.url}`}
                      />
                      <CardContent>
                        <Typography sx={{ fontSize: '18px', fontWeight: '600' }} component="div" gutterBottom>
                          {e.title}
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                          {`${e.preview}...`} <Link to={`/blog/${e.url}`}>Ver más</Link>
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>

      </Grid>
    </Container>
  );
};

export default Blog;