import React, { useState } from "react";

import { getPostalcodeData } from "../../../../actions/checkout";
import { addShippingAddress } from "../../../../actions/user-account";
import { userShippingAddressFormValidator } from "../../../../validators/UserShippingAddressValidator";

import { regex_num } from "../../../../constants/regex";

//CSS
import './newaddress.css'

//Router-dom
import { Link } from 'react-router-dom';

//MUI
import {
    Typography, Breadcrumbs, TextField, InputLabel,
    MenuItem, FormControl, Select, InputAdornment,
    Box, Button, Container, Grid, useMediaQuery
} from '@mui/material';
import DomainIcon from '@mui/icons-material/Domain';
import SignpostIcon from '@mui/icons-material/Signpost';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

const addressInit = {
    name: '',
    lastname: '',
    tel: '',
    street: '',
    ext_num: '',
    int_num: '',
    crossing_streets: '',
    postalcode: '',
    colony: '',
    city: '',
    state: '',
    references: ''
};

const disabledInit = {
    cityInput: false,
    stateInput: false
};

const NewAddressComponent = () => {

    const matches = useMediaQuery('(max-width: 950px)');

    const [values, setValues] = useState(addressInit);
    const { name, lastname, tel, street,
        ext_num, int_num, crossing_streets, postalcode,
        colony, city, state, references } = values;

    // información recuperada con el código postal
    // colonias disponibles para campo de envío
    const [colonies, setColonies] = useState([]);

    // deshabilitación de campos
    const [disabledFields, setDisabledFields] = useState(disabledInit);
    const { cityInput, stateInput } = disabledFields;

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        // propiedades con únicamente números
        if ((['ext_num', 'tel', 'postalcode'].includes(name))) {
            if (value === '' || regex_num.test(value))
                setValues({ ...values, [name]: value });
        } else
            setValues({ ...values, [name]: value });
    };

    // campo pierde foco
    const handleInputBlur = async ({ target }) => {
        // busca información del CP ingresado
        if (target.name === 'postalcode') {
            const resp = await getPostalcodeData(postalcode);
            if (resp.status) {
                const { resp: { municipio, estado, asentamiento } } = resp;
                setValues({
                    ...values,
                    colony: '',
                    city: municipio,
                    state: estado
                });
                setColonies(asentamiento);
                // deshabilita campos
                setDisabledFields({
                    ...disabledFields,
                    cityInput: true, stateInput: true
                });
            } else {
                // limpia colonias disponibles
                setColonies([]);
                // habilita campos
                setDisabledFields({
                    ...disabledFields,
                    cityInput: false, stateInput: false
                });
            }
        }
    };

    // agregar nueva dirección de envío
    const submit = async () => {
        if (userShippingAddressFormValidator(values)) {
            const req = await addShippingAddress(values);
            if (req.status)
                setValues(addressInit);
        }
    };

    return (
        <>
            {/* Title y breadcrumb */}
            <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }}>
                <Typography variant='h4' sx={{ fontWeight: 'light' }} gutterBottom>Añadir nueva dirección</Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/mi-cuenta">
                        Mi cuenta
                    </Link>
                    <Link underline="hover" color="inherit" to="/mi-cuenta/direcciones-de-envio">
                        Direcciones de envío
                    </Link>
                    <Typography color="text.primary">Nueva dirección</Typography>
                </Breadcrumbs>
            </Container>
            {/* Fin title y breadcrumd */}

            <Container maxWidth='xl' sx={{ mt: 5 }} className="last-view">
                <Box sx={{ p: 3 }} className="new-address-form">
                    {/* Form */}
                    <form action="">
                        {/* Row */}
                        <Grid container spacing={2}>
                            {/* Nombres */}
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="" label="Nombre(s)" variant="standard"
                                        name="name"
                                        value={name}
                                        inputProps={{ maxLength: 60 }}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-user"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Apellidos */}
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="" label="Apellidos" variant="standard"
                                        name="lastname"
                                        value={lastname}
                                        inputProps={{ maxLength: 60 }}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-user-group"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Dirección */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="" label="Dirección" variant="standard"
                                        name="street"
                                        value={street}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-location-dot"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Número exterior */}
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="" label="Número exterior" variant="standard"
                                        name="ext_num"
                                        value={ext_num}
                                        inputProps={{ maxLength: 4 }}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-hashtag"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Número interior */}
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="" label="Número interior" variant="standard"
                                        name="int_num"
                                        value={int_num}
                                        inputProps={{ maxLength: 8 }}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-hashtag"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Cruce de calles */}
                            <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="" label="Entre las calles" variant="standard"
                                        name="crossing_streets"
                                        value={crossing_streets}
                                        inputProps={{ maxLength: 200 }}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-road"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Código postal */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="" label="Código postal" variant="standard"
                                        name="postalcode"
                                        value={postalcode}
                                        inputProps={{ maxLength: 5 }}
                                        onChange={handleInputChange}
                                        onBlur={handleInputBlur}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MarkunreadMailboxIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Estado */}
                            <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="" label="Estado" variant="standard"
                                        name="state"
                                        value={state}
                                        disabled={stateInput}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ApartmentIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Municipio */}
                            <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="" label="Municipio" variant="standard"
                                        name="city"
                                        value={city}
                                        disabled={cityInput}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <DomainIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Colonia */}
                            <Grid item xs={12}>
                                {
                                    colonies.length <= 0 ?
                                        <FormControl fullWidth>
                                            <TextField id="" label="Colonia" variant="standard"
                                                name="colony"
                                                value={colony}
                                                inputProps={{ maxLength: 100 }}
                                                onChange={handleInputChange}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LocationCityIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </FormControl>
                                        :
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel>Colonia</InputLabel>
                                            <Select
                                                id=""
                                                name="colony"
                                                value={colony}
                                                onChange={handleInputChange}
                                                label="Colonia"
                                            >
                                                {
                                                    colonies.map(({ nombre }) =>
                                                        <MenuItem key={nombre}
                                                            value={nombre}>{nombre}
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                }
                            </Grid>

                            {/* Teléfono */}
                            <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="" label="Teléfono" variant="standard"
                                        name="tel"
                                        value={tel}
                                        inputProps={{ maxLength: 10 }}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-phone"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            {/* Referencias de domicilio */}
                            <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField id="" label="Referencias" variant="standard"
                                        name="references"
                                        value={references}
                                        inputProps={{ maxLength: 200 }}
                                        onChange={handleInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SignpostIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {/* Fin row */}
                        <Box sx={{ mt: 3, textAlign: { xs: 'left', sm: 'left', md: 'center' } }}>
                            <Button variant='contained' className="btn-new-address"
                                onClick={submit}>Agregar dirección</Button>
                        </Box>
                    </form>
                    {/* Fin form */}
                </Box>
            </Container>
        </>
    )
}

export default NewAddressComponent