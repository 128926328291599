// actualización de odoo_id en productos de NefroApp (actualización masiva de variantes)
import React, { useState } from 'react';
import * as XLSX from 'xlsx';

import { basicMsg } from '../../../../actions/swal_msg';
import { stockUpdate } from '../../../../actions/admin/advanced';

import {
    Button, Typography, Snackbar, Container,
    Card, CardContent, CardActions, IconButton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ProductStock = () => {
    // archivo a subir
    const [file, setFile] = useState('');
    // botón de carga
    const [loading, setLoading] = useState(false);
    // array de productos obtenidos del archivo
    const [myJson, setJson] = useState([]);
    // snackbar
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('Actualización finalizada');

    // subida de archivo
    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setFile(e.target.files[0].name);
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setJson(json);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    };

    // actualización de odoo_id de productos
    const submit = async () => {
        if (myJson.length > 0) {
            setLoading(true);
            const status = await stockUpdate(myJson);
            setLoading(false);
            if (status) {
                setFile('');
                setJson([]);
                setMessage('Actualización finalizada');
            } else setMessage('Actualización fallida');
            setOpen(true);
        } else basicMsg('Suba archivo');
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={ handleClose }>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Container sx={{ p: 2 }}>
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Actualización inventario de productos
                    </Typography>
                    <Typography variant="body2">
                        Actualizar únicamente existencias de todos los productos de tienda ONL
                    </Typography>
                    {
                        file &&
                            <Typography variant="overline" display="block" gutterBottom>
                                Archivo subido: { file }
                            </Typography>
                    }
                    {
                        file &&
                            <LoadingButton
                                sx={{ mt: 2 }}
                                size='small'
                                endIcon={<CloudUploadIcon />}
                                loading={ loading }
                                loadingPosition="end"
                                variant="contained"
                                onClick={ submit }>
                                Iniciar actualización
                            </LoadingButton>
                    }
                </CardContent>
                <CardActions>
                    <Button component="label">
                        Subir archivo
                        <input hidden
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            multiple type="file" onChange={ readUploadFile } />
                    </Button>
                </CardActions>
            </Card>

            <Snackbar
                open={ open }
                autoHideDuration={6000}
                onClose={ handleClose }
                message={ message }
                action={ action }
            />
        </Container>
    );
};

export default ProductStock;