import React from 'react';

import ShipOrder from './ShipOrder';
import { orderDelivered } from '../../../../actions/orders';

import {
    Stack, Button, Typography,
    Tooltip, IconButton, Alert
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const EnvioAlmacen = ({ setData, selectedOrder, setSelectedOrder }) => {
    const { id, order_num, shipping: { status } } = selectedOrder;

    const markAsDelivered = async () => {
        if (id.length === 24) {
            const req = await orderDelivered({ id, order_num });
            if (req.status) {
                setSelectedOrder({
                    ...selectedOrder,
                    delivery_status: 'Entregado',
                    shipping: {
                        ...selectedOrder.shipping,
                        status: 'delivered'
                    }
                });
                setData(i => i.map(
                    e => (e.id === id) ? ({
                        ...e,
                        delivery_status: 'Entregado',
                        shipping: {
                            ...e.shipping,
                            status: 'delivered'
                        }
                    }) : e
                ));
            }
        } else alert('No se pudo recuperar ID de pedido');
    };

    return (
        <>
            {
                status === 'preparing' &&
                    <ShipOrder
                        setData={ setData }
                        selectedOrder={ selectedOrder }
                        setSelectedOrder={ setSelectedOrder } />
            }
            {
                status === 'shipped' &&
                    <Stack direction="row"
                        justifyContent="flex-start"
                        alignItems="center">
                        <Button variant="outlined"
                            onClick={ markAsDelivered }>
                            Entregado
                        </Button>
                        <Tooltip
                            title={
                                <Typography variant="caption">
                                    Marcar como entregado y actualizar pedido
                                </Typography>
                            }
                        >
                            <IconButton>
                                <HelpOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
            }
            {
                status === 'delivered' &&
                    <Alert severity="success">Pedido entregado con éxito</Alert>
            }
        </>
    );
};

export default EnvioAlmacen;