import React from 'react';
import { useDispatch } from 'react-redux';

import { valMsg } from '../../../actions/swal_msg';
import { updAdmin } from '../../../actions/admin';

import { Stack, TextField, InputAdornment, Button } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import EmailIcon from '@mui/icons-material/Email';

const AdminForm = ({ values, setValues, inputErr, setInputErr, eInputRef, uInputRef, rInputRef, handleCloseDialog }) => {
    const dispatch = useDispatch();

    const { username, email, role } = values;
    const { username_err, email_err, role_err } = inputErr;

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        setValues({ ...values, [name]: value });
        switch (name) {
            case 'email':
                setInputErr({
                    ...inputErr,
                    email_err: (value.length < 6 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'username':
                setInputErr({
                    ...inputErr,
                    username_err: (value.length < 3 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'role':
                setInputErr({
                    ...inputErr,
                    role_err: (value.length < 3 ||
                        value.length > 60) ? true : false
                });
                break;
            default:
                break;
        }
    };

    // validación formulario
    const formValidator = () => {
        const email_len = email.trim().length;
        const username_len = username.trim().length;
        if (email_len > 5 && email_len <= 100) {
            if (username_len > 2 && username_len <= 100) {
                return true;
            } else {
                uInputRef.current.select();
                valMsg('warning', 'Administrador',
                    'Ingrese un nombre de usuario apropiado');
            }
        } else {
            eInputRef.current.select();
            valMsg('warning', 'Administrador',
                'Ingrese un correo electrónico válido');
        }
        // establece errores en los campos
        setInputErr({
            ...inputErr,
            email_err: (email.length < 6 ||
                email.length > 100) ? true : false,
            username_err: (username.length < 3 ||
                username.length > 100) ? true : false,
            role_err: (role.length < 3 ||
                username.length > 100) ? true : false
        });
        return false;
    };

    // actualizar datos y privilegios
    const update = () => {
        if (formValidator()) {
            dispatch(updAdmin(values));
        }
        handleCloseDialog()
    };

    return (
        <>
            <Stack sx={{ mt: 2 }} spacing={3}>
                <TextField
                    label="Correo electrónico"
                    size="large"
                    variant="outlined"
                    error={email_err}
                    helperText={email_err ? "Ingrese correo electrónico" : ""}
                    inputProps={{ maxLength: 120 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        )
                    }}
                    inputRef={eInputRef}
                    name="email"
                    value={email}
                    onChange={handleInputChange} />
                <TextField
                    label="Nombre de usuario"
                    size="large"
                    variant="outlined"
                    error={username_err}
                    helperText={username_err ? "Ingrese nombre de usuario" : ""}
                    inputProps={{ maxLength: 100 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        )
                    }}
                    inputRef={uInputRef}
                    name="username"
                    value={username}
                    onChange={handleInputChange} />
                <TextField
                    label="Rol de usuario"
                    size="large"
                    variant="outlined"
                    error={role_err}
                    helperText={role_err ? "Ingrese rol de usuario" : ""}
                    inputProps={{ maxLength: 60 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        )
                    }}
                    inputRef={rInputRef}
                    name="role"
                    value={role}
                    onChange={handleInputChange} />
            </Stack>

            <Button variant="contained" sx={{mt: 2}}
                onClick={update}>
                Actualizar
            </Button>
        </>
    );
};

export default AdminForm;
