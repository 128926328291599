import { types } from "../types/types";

// toggle inserción productos
export const setCategoryToggle = (pos) => ({
    type: types.ui_categoryToggle,
    payload: pos
});

// abrir/cerrar modal AdminUserModal
export const adminUserModalToggle = (payload) => ({
    type: types.ui_adminUserModal,
    payload
});

// abrir/cerrar modal FormModal
export const formsModalToggle = (payload) => ({
    type: types.ui_formsModal,
    payload
});

// abrir/cerrar modal CommentModal
export const commentModalToggle = (payload) => ({
    type: types.ui_commentModal,
    payload
});

// abrir/cerrar modal LoginComponent
export const loginModalToggle = (payload) => ({
    type: types.ui_loginModal,
    payload
});

// abrir/cerrar dialog AdminsUserList
export const adminUserDialogToggle = (payload) => ({
    type: types.ui_adminUserDialog,
    payload
});

// muestra/oculta snackbar en caso de error en petición
export const shopSnackbarToggle = (payload) => ({
    type: types.ui_shopSnackbar,
    payload
});

// productos comprados juntos frecuentemente agregados a carrito
export const shopProductsFBTAdded = (payload) => ({
    type: types.ui_shopFBTAdded,
    payload
});

// métodos de envío de Melonn
export const uiLoading1 = (payload) => ({
    type: types.uiLoading1,
    payload
});

// registro blog / actualización blog / registro producto (admin)
export const uiLoading2 = (payload) => ({
    type: types.uiLoading2,
    payload
});