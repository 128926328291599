import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { commentModalToggle } from '../../../../actions/ui';
import { revAdd, revUpd } from '../../../../actions/review';
import { basicMsg, msg } from '../../../../actions/swal_msg';

import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

import {
    Button, TextField, Typography, Chip, Rating,
    ImageList, ImageListItem, ImageListItemBar,
    Dialog, DialogTitle, DialogContent, DialogActions,
    Box, IconButton
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import LoadingButton from '@mui/lab/LoadingButton';
import useMediaQuery from '@mui/material/useMediaQuery';

const closeAction = {
    open: false,
    action: 'add',
    loading: false
};

const init = {
    email: '', // email input
    username: '', // username input
    comment: '',
    rating: 5,
    images: [],
};

const error_i = {
    comment_err: false
};

const ReviewModal = ({ prod_id }) => {
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width:600px)');
    const { activeReview } = useSelector(state => state.shop);
    // estado de modal
    const { commentModal } = useSelector(state => state.ui);
    const { open, action, loading } = commentModal;
    // usuario iniciado
    const { loggedUser } = useSelector(state => state.user);
    const { _id, email: a_email, username: a_username } = loggedUser;

    // referencias a campos
    const cInputRef = useRef();

    // formulario
    const [values, setValues] = useState(init);
    const { email, username, comment, rating, images } = values;
    // errores en campos
    const [inputErr, setInputErr] = useState(error_i);
    const { comment_err } = inputErr;
    // imágenes
    const [newImages, setNewImages] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);

    // maybe not neccesary
    useEffect(() => {
        setValues(activeReview);
    }, [activeReview]);

    useEffect(() => {
        // se cierra modal
        if (!open) setValues(init);
        else setValues(activeReview);
        setNewImages([]);
        setDeletedImages([]);
    }, [open]);

    const submit = () => {
        if (_id !== '') {
            if (formValidator())
                dispatch(revAdd({
                    prod_id,
                    email: a_email,
                    username: a_username,
                    comment,
                    rating,
                    images: newImages
                }));
        } else {
            if (formGuestValidator())
                dispatch(revAdd({
                    prod_id,
                    email,
                    username,
                    comment,
                    rating,
                    images: newImages
                }));
        }
    };

    const update = () => {
        if (formValidator() && _id !== '') {
            dispatch(revUpd({ prod_id, ...values }, newImages, deletedImages));
        }
    };

    // validación comentario usuario
    const formValidator = () => {
        const cmt_len = comment.trim().length;
        const img_len = images.length + newImages.length;
        if (a_email !== '' && a_username !== ''
            && cmt_len > 5 && cmt_len <= 2000
            && rating > 0 && rating <= 5
            && img_len < 7)
            return true;
        else msg('warning', 'Comentario',
            'Falta información para publicar su comentario');
        return false;
    };

    // validación comentario invitado
    const formGuestValidator = () => {
        const email_len = email.trim().length;
        const username_len = username.trim().length;
        const cmt_len = comment.trim().length;
        const img_len = images.length + newImages.length;
        if (email_len > 5 && email_len <= 100
            && username_len > 1 && username_len <= 100
            && cmt_len > 5 && cmt_len <= 2000
            && rating > 0 && rating <= 5
            && img_len < 7)
            return true;
        else msg('warning', 'Comentario',
            'Falta información para publicar su comentario');
        return false;
    };

    const inputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'rating') {
            const num = parseInt(value);
            setValues(e => ({
                ...e,
                [name]: num
            })
            );
        } else {
            setValues(e => ({
                ...e,
                [name]: value
            })
            );
            switch (name) {
                case 'email':
                    setInputErr({
                        ...inputErr,
                        email_err: (value.length < 6 ||
                            value.length > 100) ? true : false
                    });
                    break;
                case 'username':
                    setInputErr({
                        ...inputErr,
                        username_err: (value.length < 2 ||
                            value.length > 100) ? true : false
                    });
                    break;
                case 'comment':
                    setInputErr({
                        ...inputErr,
                        comment_err: (value.length < 6 ||
                            value.length > 2000) ? true : false
                    });
                    break;
                default:
                    break;
            }
        }
    };

    // subir imágenes de ordenador
    const loadImages = ({ target }) => {
        // imágenes totales
        let total = images.length + newImages.length;
        if (total < 6) {
            const len = target.files.length;
            let full = false;
            if (len > 0) {
                const target_files = [];
                [...target.files].forEach(element => {
                    if (total < 6) {
                        if (['image/jpeg', 'image/png', 'image/webp'].includes(element.type)) {
                            target_files.push(element);
                            total++;
                        }
                    } else full = true;
                });
                setNewImages([...newImages, ...target_files]);
                full && basicMsg('No se agregaron todas las imágenes. (6 máximo)');
            }
        } else basicMsg('No puede agregar más imágenes. (6 máximo)');
    };

    // elimina imágenes previamente subidas
    const deleteImages = item => () => {
        setValues(e => ({
            ...e,
            images: e.images.filter(i => (i !== item))
        }));
        setDeletedImages([...deletedImages, item]);
    };

    // remueve imágenes
    const removeImages = item => () => {
        setNewImages(newImages.filter(i => (i.name !== item.name)));
    };

    // cerrar modal
    const handleClose = () => {
        dispatch(commentModalToggle(closeAction));
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={open}
            onClose={handleClose}
            sx={{ zIndex: '3' }}
        >
            <DialogTitle>Crear reseña</DialogTitle>
            <DialogContent>
                <Box>
                    <Typography variant="subtitle1" gutterBottom component="div">
                        Correo electrónico (no será publicado): <Chip label={a_email} />
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom component="div">
                        Nombre: <Chip label={a_username} />
                    </Typography>
                </Box>
                <Typography component="legend" sx={{ mt: 3 }}>Calificación</Typography>
                <Rating
                    name="rating"
                    value={rating}
                    onChange={inputChange} />
                <div>
                    <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        label="Comentario sobre producto"
                        variant="outlined"
                        multiline
                        rows={4}
                        error={comment_err}
                        helperText={comment_err ? "Ingrese un comentario" : ""}
                        inputProps={{ maxLength: 2000 }}
                        inputRef={cInputRef}
                        name="comment"
                        value={comment}
                        onChange={inputChange}
                    />
                </div>
                <Box
                    sx={{ mt: 3 }}
                >
                    <input
                        style={{ display: "none" }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={loadImages} />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" color="primary" component="span">
                            Subir imágenes
                        </Button>
                    </label>
                </Box>
                <ImageList cols={matches ? 2 : 3} rowHeight={matches ? 164 : 328}>
                    {
                        images.map((item, i) => {
                            return (
                                <ImageListItem key={item} cols={1} rows={1}>
                                    <img src={`${GOOGLE_DRIVE_CDN}${item}`}
                                        alt={`imagen variante`}
                                        loading="lazy" />
                                    <ImageListItemBar
                                        actionIcon={
                                            <IconButton
                                                sx={{ color: 'rgba(255, 255, 255, 0.5)' }}
                                                aria-label={`imagen`}
                                                onClick={deleteImages(item)}>
                                                <RemoveSharpIcon />
                                            </IconButton>
                                        } />
                                </ImageListItem>
                            )
                        })
                    }
                </ImageList>
                {
                    images.length + newImages.length > 0 ?
                        <ImageList cols={matches ? 2 : 3} rowHeight={matches ? 164 : 328}>
                            {
                                newImages.map((item, i) => (
                                    <ImageListItem key={i} cols={1} rows={1}>
                                        <img
                                            src={URL.createObjectURL(item)}
                                            alt={item.name}
                                            loading="lazy" />
                                        <ImageListItemBar
                                            actionIcon={
                                                <IconButton
                                                    sx={{ color: 'rgba(255, 255, 255, 0.5)' }}
                                                    aria-label={`icono`}
                                                    onClick={removeImages(item)}>
                                                    <RemoveSharpIcon />
                                                </IconButton>
                                            } />
                                    </ImageListItem>
                                ))
                            }
                        </ImageList>
                        :
                        <Typography variant="caption" display="block" gutterBottom>
                            No ha incluido imágenes
                        </Typography>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                {
                    loading ?
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                        >
                            Cargando
                        </LoadingButton>
                        :
                        <>
                            <Button sx={{
                                display: action === 'edit' ? 'none' : 'flex'
                            }}
                                onClick={submit}>
                                Comentar
                            </Button>
                            <Button sx={{
                                display: action === 'edit' ? 'flex' : 'none'
                            }}
                                onClick={update}>
                                Actualizar
                            </Button>
                        </>
                }
            </DialogActions>

        </Dialog>
    );
};

export default ReviewModal;