import React from "react";

//React charts
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

//Opciones gráfica
export const options = {
    plugins: {
        legend: {
            position: 'bottom',
        },
        datasetRadiusBuffer: 25,
        cutoutPercentage: 75,
    },
}

const TopMensual = ({ monthData }) => {
    return (
        <>
            <div style={{ width: '100%' }}>
                <Doughnut data={monthData} options={options} />
            </div>
        </>
    );
};

export default TopMensual;