import { types } from '../types/types';
import { protectedReq } from "../helpers/requestHelper";

import { basicMsg, msg } from './swal_msg';

// cargar lista de cupones
export const couponList = () => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('coupon/list');
            const body = await resp.json();
            if (body.status) {
                if (body.resp.length > 0)
                    dispatch(couponsLoaded(body.resp));
            }
        } catch (err) {
            msg('error', 'Cupones',
            'No se pudo recuperar la lista de cupones');
        }
    }
};

// lista de cupones obtenida
const couponsLoaded = (obj) => ({
    type: types.couponsLoaded,
    payload: obj
});

// agregar cupón (YA NO USAR)
export const couponAdd = (model) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('coupon/add', model, 'POST');
            const body = await resp.json();
            if (body.status) {
                dispatch(added(body.obj));
            } else {
                if (body.msg === 'coupon-exists')
                    msg('warning', 'Cupón',
                    'Ya existe un cupón con el mismo nombre');
                else if (body.msg === 'miss')
                    msg('warning', 'Cupón',
                    'Faltan datos del cupón');
                else if (body.msg === 'server-err')
                    msg('error', 'Cupón',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Cupón',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msg('error', 'Cupón',
            'No se pudo agregar el cupón');
        }
    }
};

// guardar cupón
export const couponSave = async (model) => {
    try {
        const {
            _class, name, description, use_method, discount,
            available, restrictions, purchase_requirements,
            startDate, endDate
        } = model;
        const { type, _value } = discount;
        const { one_per_user, limited, quantity } = restrictions;
        const {
            min_purchase_amount, minimum_items_quantity, applies_to
        } = purchase_requirements;
        const { amount } = min_purchase_amount;
        const { qty } = minimum_items_quantity;
        const { category, product } = applies_to;

        const _discount = _class === 'order_discount' ? {
            type: type.trim(),
            _value: _value.trim()
        } : null;

        const coupon = {
            name: name.trim(),
            _class: _class.trim(),
            description: description.trim(),
            discount: _discount,
            use_method: use_method.trim(),
            start_date: startDate,
		    end_date: endDate,
            available,
            restrictions: {
                one_per_user,
                limited,
                quantity: limited ?
                    quantity.trim() : 0
            },
            purchase_requirements: {
                min_purchase_amount: {
                    active: min_purchase_amount.active,
                    amount: min_purchase_amount.active ?
                        amount.trim() : 0
                },
                minimum_items_qty: {
                    active: minimum_items_quantity.active,
                    items_qty: minimum_items_quantity.active ?
                        qty.trim() : 0
                },
                applies_to: {
                    category: {
                        exclusive: false,
                        list: category.list
                    },
                    product: {
                        exclusive: false,
                        list: product.list
                    }
                }
            }
        };
        
        const route = _class === 'order_discount' ? 'coupon/save-class-one' : 'coupon/save-class-two';
        const resp = await protectedReq(route, coupon, 'POST');
        const body = await resp.json();
        if (body.status) return { status: true };
        else {
            if (body.msg === 'coupon-exists')
                msg('warning', 'Cupón',
                'Ya existe un cupón con el mismo código');
            else if (body.msg === 'miss')
                msg('warning', 'Cupón',
                'Faltan datos del cupón');
            else if (body.msg === 'server-err')
                msg('error', 'Cupón',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else msg('warning', 'Cupón',
                'Inténtelo de nuevo, por favor');
        }
        return { status: false };
    } catch (err) {
        msg('error', 'Cupón',
        'No se pudo agregar el cupón');
        return { status: false };
    };
};

// actualizar cupón
export const couponUpdate = (model) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('coupon/update', model, 'PUT');
            const body = await resp.json();
            if (body.status) {
                dispatch(updated(body.obj));
            } else {
                if (body.msg === 'not-found')
                    msg('warning', 'Cupón',
                    'No se encontró el cupón');
                else if (body.msg === 'server-err')
                    msg('error', 'Cupón',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Cupón',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msg('error', 'Cupón',
            'No se pudo actualizar el cupón');
        }
    }
};

// eliminar cupón
export const couponDelete = (_id) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('coupon/delete', { _id }, 'DELETE');
            const body = await resp.json();
            if (body.status) dispatch(deleted(_id));
            else {
                if (body.msg === 'server-err')
                    msg('error', 'Cupón',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Cupón',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            basicMsg('No se pudo eliminar el cupón');
        }
    }
};

// cupón fue agregado
const added = (obj) => ({
    type: types.couponAdded,
    payload: obj
});

// cupón fue actualizado
const updated = (obj) => ({
    type: types.couponUpdated,
    payload: obj
});

// cupón fue eliminado
const deleted = (id) => ({
    type: types.couponDeleted,
    payload: id
});

// cupón fue selecionado
export const couponSelected = (id) => ({
    type: types.couponSelected,
    payload: id
});

export const couponOpenModal = () => ({ type: types.couponOpenModal });
export const couponCloseModal = () => ({ type: types.couponCloseModal });

// cupón es editado
export const couponEdit = (coupon) => ({
    type: types.couponEdit,
    payload: coupon
});

// eliminar cupones seleccionados
export const couponSelectedDelete = (array) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('coupon/deleteSelected', { array }, 'DELETE');
            const body = await resp.json();
            if (body.status) dispatch(selectedDeleted(array));
            else {
                if (body.msg === 'not-found')
                    msg('warning', 'Cupón',
                    'Uno o más cupones no pudieron ser eliminados');
                else if (body.msg === 'empty-arr')
                    msg('warning', 'Cupón',
                    'Ningún cupón fue eliminado');
                else if (body.msg === 'server-err')
                    msg('error', 'Cupón',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Cupón',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            basicMsg('No se pudo eliminar los cupones seleccionados');
        }
    }
};

// cupones seleccionados fueron eliminados
const selectedDeleted = (array) => ({
    type: types.couponsSelectedDeleted,
    payload: array
});