import React, { useState } from 'react'

import { Animated } from "react-animated-css";

//MUI
import { IconButton, Autocomplete, Box, TextField, Typography, Button, Paper } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useNavigate } from 'react-router-dom';

const MuiAutocomplete = styled(Autocomplete)({
    marginTop: 'auto',
    marginBottom: 'auto',
    ".MuiAutocomplete-inputRoot": {
        "& .MuiInputBase-input": {
            height: '18px'
        },
        "& .MuiInputAdornment-root": {
            color: "red"
        },
    }
});

function CustomPaper({ children, button, ...other }) {
    return (
        <Paper {...other}>
            {children}
            {button}
        </Paper>
    );
}

const SearchMobile = ({ options, goToProductPage, visible, setVisible }) => {

    const OPTIONS_LIMIT = 10;
    const defaultFilterOptions = createFilterOptions();
    const filterOptions = (options, state) => {
        return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
    };

    const handleSearchVisible = () => {
        setVisible(true)
    }

    const handleSearchHide = () => {
        setVisible(false)
    }

    const navigate = useNavigate();

    const [value, setValue] = useState("");

    const [openD, setOpenD] = useState(false);

    //Redirigir la busqueda a otro componente
    const handleSearch = (event) => {
        event.preventDefault();
        if (value)
            navigate(`/tienda/buscar/${value}`);
        setValue("")
        setOpenD(false)
    };

    const buttons = (
        <Box sx={{ display: "flex", justifyContent: 'center', py: 1 }}>
            <Button onMouseDown={(event) => { event.preventDefault(); }} onClick={handleSearch} sx={{ textTransform: 'none' }} size='small'>
                Ver todos los productos relacionados
            </Button>
        </Box>
    );

    return (
        <>


            {
                visible &&
                <Animated animationIn="fadeIn" isVisible={visible} className='search_into_header'>
                    <MuiAutocomplete
                        open={openD}
                        onOpen={() => setOpenD(true)}
                        onClose={() => {
                            setOpenD(false)
                            setValue("")
                        }}
                        disablePortal
                        options={options}
                        getOptionLabel={(option) => option.title}
                        filterOptions={filterOptions}
                        onChange={goToProductPage}
                        PaperComponent={value.length >= 1 ? CustomPaper : null}
                        componentsProps={{ paper: { button: buttons } }}
                        renderOption={(props, option) => (
                            <Box component="li"
                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                    loading="lazy"
                                    width="30"
                                    src={option.img}
                                    srcSet={option.img}
                                    alt={option.alt} />
                                <Typography variant='body1'>{option.title}</Typography>
                            </Box>
                        )}
                        renderInput={(params) => <TextField {...params} size='small' placeholder="Buscar" fullWidth
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        }
                    />
                </Animated>
            }

            {
                visible !== true &&
                <IconButton
                    size="large"
                    onClick={handleSearchVisible}
                    sx={[
                        { color: '#000' },
                        {
                            '&:hover': {
                                color: '#000',
                            },
                        },
                    ]}
                >
                    <SearchIcon />
                </IconButton>
            }

            {
                visible &&
                <IconButton
                    size="large"
                    onClick={handleSearchHide}
                    sx={[
                        { color: '#000' },
                        {
                            '&:hover': {
                                color: '#000',
                            },
                        },
                    ]}
                >
                    <SearchOffIcon />
                </IconButton>
            }
        </>
    );
};

export default SearchMobile;