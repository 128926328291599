import React from 'react';

import {
    Typography, Grid, Box, IconButton, Stack,
    FormGroup, FormControlLabel, Switch
} from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const ProductItem2 = ({ selectedProducts, setCoupon }) => {
    // quitar producto
    const removeProduct = _id => () => {
        setCoupon(e => ({
            ...e,
            purchase_requirements: {
                ...e.purchase_requirements,
                applies_to: {
                    ...e.purchase_requirements.applies_to,
                    product: {
                        ...e.purchase_requirements.applies_to.product,
                        list: [...e.purchase_requirements.applies_to.product.list.filter(item => item.variant_id !== _id)]
                    }
                }
            }
        }));
    };

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch checked={ false } disabled={ true } />
                        }
                        label="Aplicar exclusivamente a estos productos"
                    />
                </FormGroup>
                {
                    selectedProducts.length > 0 &&
                        <Typography variant="subtitle1" sx={{ fontWeight: 'Bold' }}>
                            Productos seleccionados:
                        </Typography>
                }
            </Grid>
            {
                selectedProducts.map(({ variant_id, full_title, image_id }) =>
                    <Grid item xl={4} lg={12} md={12} sm={12} xs={12} key={variant_id}>
                        <Box sx={{
                            display: 'flex', justifyContent: 'flex-start', gap: '15px',
                            borderRadius: '5px', padding: '10px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)'
                        }}>
                            <Box sx={{ my: 'auto' }}>
                                <img width='90' height="90" style={{ objectFit: 'contain' }}
                                    src={ image_id }
                                    alt="producto-nefropolis" />
                            </Box>
                            <Stack
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-end"
                                sx={{ width: '100%' }}
                            >
                                <Typography component="div" variant="subtitle2">
                                    { full_title }
                                </Typography>
                                <IconButton aria-label="delete" onClick={ removeProduct(variant_id) }>
                                    <RemoveCircleIcon />
                                </IconButton>
                            </Stack>
                        </Box>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default ProductItem2;