import { regex_email, regex_num } from "../../../constants/regex";

export const FieldValues = ({ name, value }, setValues, { inputErr, setInputErr }) => {
    if (['name', 'address', 'colony', 'city', 'state'].includes(name)) {
        if (value.length <= 100) {
            setFieldValue({ name, value }, setValues);
            FieldErrors({ name, value }, { inputErr, setInputErr });
        }
    } else if (['phone', 'mobile', 'number'].includes(name)) {
        if ((value === '' || regex_num.test(value)) && value.length <= 15) {
            setFieldValue({ name, value }, setValues);
            FieldErrors({ name, value }, { inputErr, setInputErr });
        }
    } else if (name === 'email') {
        if (value.length <= 120) {
            setFieldValue({ name, value }, setValues);
            FieldErrors({ name, value }, { inputErr, setInputErr });
        }
    } else if (name === 'postalCode') {
        if ((value === '' || regex_num.test(value)) && value.length <= 5) {
            setFieldValue({ name, value }, setValues);
            FieldErrors({ name, value }, { inputErr, setInputErr });
        }
    } else if (name === 'message') {
        if (value.length <= 200) {
            setFieldValue({ name, value }, setValues);
            FieldErrors({ name, value }, { inputErr, setInputErr });
        }
    } else if (name === 'url') {
        if (value.length <= 500) {
            setFieldValue({ name, value }, setValues);
            FieldErrors({ name, value }, { inputErr, setInputErr });
        }
    } else if (name === 'src') {
        if (value.length <= 1000) {
            setFieldValue({ name, value }, setValues);
            FieldErrors({ name, value }, { inputErr, setInputErr });
        }
    } else if (name === 'title') {
        if (value.length <= 60) {
            setFieldValue({ name, value }, setValues);
            FieldErrors({ name, value }, { inputErr, setInputErr });
        }
    } else if (['width', 'height'].includes(name)) {
        if (value.length <= 20) {
            setFieldValue({ name, value }, setValues);
            FieldErrors({ name, value }, { inputErr, setInputErr });
        }
    }
};

// establecer valor en campo
const setFieldValue = ({ name, value }, setValues) => {
    if (['name', 'address', 'colony', 'city', 'state', 'phone', 'mobile', 'email', 'postalCode'].includes(name))
        setValues((e) => ({
            ...e, [name]: value
        }));
    else if (['number', 'message'].includes(name))
        setValues((e) => ({
            ...e, links: {
                ...e.links,
                whatsapp: {
                    ...e.links.whatsapp,
                    [name]: value
                }
            }
        }));
    else if (name === 'url')
        setValues((e) => ({
            ...e, links: {
                ...e.links,
                facebook: {
                    ...e.links.facebook,
                    [name]: value
                }
            }
        }));
    else if (['src', 'title', 'width', 'height'].includes(name))
        setValues((e) => ({
            ...e, links: {
                ...e.links,
                google_maps: {
                    ...e.links.google_maps,
                    [name]: value
                }
            }
        }));
};

const FieldErrors = ({ name, value }, { inputErr, setInputErr}) => {
    switch (name) {
        case 'name':
            setInputErr({
                ...inputErr,
                name_err: (value.length < 11 ||
                    value.length > 100) ? true : false
            });
            break;
        case 'phone':
            setInputErr({
                ...inputErr,
                phone_err: (value.length < 5 ||
                    value.length > 15) ? true : false
            });
            break;
        case 'mobile':
            setInputErr({
                ...inputErr,
                mobile_err: (value.length < 5 ||
                    value.length > 15) ? true : false
            });
            break;
        case 'email':
            setInputErr({
                ...inputErr,
                email_err: (
                    value.length < 5 ||
                    value.length > 120 ||
                    !regex_email.test(value)
                ) ? true : false
            });
            break;
        case 'address':
            setInputErr({
                ...inputErr,
                address_err: (value.length < 5 ||
                    value.length > 100) ? true : false
            });
            break;
        case 'postalCode':
            setInputErr({
                ...inputErr,
                pc_err: (value.length !== 5 || !regex_num.test(value)) ? true : false
            });
            break;
        case 'colony':
            setInputErr({
                ...inputErr,
                colony_err: (value.length < 5 ||
                    value.length > 100) ? true : false
            });
            break;
        case 'city':
            setInputErr({
                ...inputErr,
                city_err: (value.length < 3 ||
                    value.length > 100) ? true : false
            });
            break;
        case 'state':
            setInputErr({
                ...inputErr,
                state_err: (value.length < 3 ||
                    value.length > 100) ? true : false
            });
            break;
        case 'number':
            setInputErr({
                ...inputErr,
                wsp_number_err: (value.length < 5 || value.length > 15) ? true : false
            });
            break;
        case 'message':
            setInputErr({
                ...inputErr,
                wsp_message_err: (value.length < 3 || value.length > 200) ? true : false
            });
            break;
        case 'url':
            setInputErr({
                ...inputErr,
                facebook_url_err: (value.length < 11 || value.length > 500) ? true : false
            });
            break;
        case 'src':
            setInputErr({
                ...inputErr,
                iframe_src_err: (value.length < 11 || value.length > 1000) ? true : false
            });
            break;
        case 'title':
            setInputErr({
                ...inputErr,
                iframe_title_err: (value.length < 4 || value.length > 60) ? true : false
            });
            break;
        case 'width':
            setInputErr({
                ...inputErr,
                iframe_width_err: (value.length < 2 || value.length > 20) ? true : false
            });
            break;
        case 'height':
            setInputErr({
                ...inputErr,
                iframe_height_err: (value.length < 2 || value.length > 20) ? true : false
            });
            break;
        default:
            break;
    };
};