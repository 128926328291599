// data para gráfica Top Productos (TopProductos.js)
export const PRODUCT_DEFAULT = {
    labels: ['Sin ventas'],
    datasets: [
        {
            label: 'Ventas promedio',
            data: [1],
            backgroundColor: [
                '#CD6155',
                '#AF7AC5',
                '#5499C7',
                '#48C9B0',
                '#F4D03F',
                '#DC7633',
                '#5F6A6A',
                '#196F3D',
                '#212F3C',
                '#154360'
            ],
            borderColor: [
                '#CD6155',
                '#AF7AC5',
                '#5499C7',
                '#48C9B0',
                '#F4D03F',
                '#DC7633',
                '#5F6A6A',
                '#196F3D',
                '#212F3C',
                '#154360'
            ]
        }
    ]
};
export const TOP_PRODUCTS_DATA = {
    todayData: PRODUCT_DEFAULT,
    weekData: PRODUCT_DEFAULT,
    fortnightData: PRODUCT_DEFAULT,
    monthData: PRODUCT_DEFAULT,
    quarterData: PRODUCT_DEFAULT,
    yearToDateData: PRODUCT_DEFAULT,
    annualData: PRODUCT_DEFAULT,
    filteredData: PRODUCT_DEFAULT
};
export const PRODUCT_MENU = {
    dia: true,
    semanal: false,
    quincenal: false,
    mensual: false,
    trimestral: false,
    yearToDate: false,
    anual: false,
    filtrado: false
};