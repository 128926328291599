import React from 'react';

//MUI
import Typography from '@mui/material/Typography';

const InfoCliente = ({ selectedOrder }) => {
    const { address: {
        name, lastname, email, tel, street, ext_num, int_num,
        colony, postalcode, city, state, crossing_streets,
        references, residence_type }
    } = selectedOrder;
    return (
        <>
            {/* Nombre */}
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Nombre:</b> {name} {lastname}
            </Typography>
            {/* email */}
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Correo electrónico:</b> {email}
            </Typography>
            {/* Telefono */}
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Teléfono:</b> {tel}
            </Typography>
            {/* Dirección */}
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Calle:</b> {`${street}`}
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Número exterior:</b> {`#${ext_num}`}
            </Typography>
            {
                int_num &&
                    <Typography variant="body1" component="div">
                        <b style={{ fontWeight: '600' }}>Número interior:</b> {`${int_num}`}
                    </Typography>
            }
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>C.P:</b> {postalcode}
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Colonia:</b> {colony}
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Delegación:</b> {city}
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Estado:</b> {state}
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Entre las calles:</b> {crossing_streets}
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Referencias:</b> {references}
            </Typography>
            <Typography variant="body1" component="div">
                <b style={{ fontWeight: '600' }}>Tipo de domicilio:</b>
                { residence_type ? ` ${residence_type}` : ' (No especificado)' }
            </Typography>
        </>
    );
};

export default InfoCliente;