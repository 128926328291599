import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';

import { basicMsg } from "../../../actions/swal_msg";
import { updateMyData, updatePwd, verifiyPwd } from "../../../actions/user-account";

//CSS
import './infoseguridad.css';

//MUI
import { loggedIn } from "../../../actions/user";

import {
    Box, ButtonGroup, Container, Grid, Typography, Breadcrumbs,
    TextField, FormControl, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Button, IconButton,
    OutlinedInput, InputLabel, InputAdornment, CircularProgress, useMediaQuery
} from '@mui/material';

const init = {
    username: '',
    email: ''
};

const UISComponent = () => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width: 950px)');

    /*************************** Cambio de datos personales ********************************/
    // usuario iniciado
    const { loggedUser } = useSelector(state => state.user);

    const [user, setUser] = useState(init);
    const { _id, email, username, platform } = user;

    useEffect(() => {
        if (loggedUser.email !== '') {
            setUser(loggedUser);
        } else {
            basicMsg('Inicie sesión para acceder');
            navigate('/mi-cuenta');
        }
    }, []);

    //Dialog
    const [dialog, setDialog] = useState(false)
    const openDialog = (e) => {
        e.preventDefault()
        setDialog(true)
    }
    const closeDialog = () => {
        setDialog(false)
        setVerifyPass('')
    }

    //Metodo para mostrar y ocultar contraseña
    const [pass, setPass] = useState(false)
    const handleClickShowPass = () => {
        setPass({ ...pass, showPassword: !pass.showPassword });
    };

    //Detecta el cambio en el input
    const [VerifyPass, setVerifyPass] = useState('');
    const handleOnChangePass = (event) => {
        setVerifyPass(event.target.value);
    };

    //Button guardar cambios
    const [showButton, setShowButton] = useState(false)
    //Texfields disabled
    const [disabled, setDisabled] = useState(true)
    //button disabled
    const [btnDisabled, setBtnDisabled] = useState(false)

    //Verifica si el password es correcto, si lo es activa los inputs y muestra el botón para guardar los cambios
    const verificar = async (e) => {
        e.preventDefault();
        if (await verifiyPwd({ _id, password: VerifyPass })) {
            //Activa los inputs de información personal
            setDisabled(false)
            //Muestra el botón "Guardar cambios"
            setShowButton(true)
            //Cierra el Dialog
            setDialog(false);
            //Desactiva el boton para editar la información
            setBtnDisabled(true)
        }
    }

    //Desactiva textfield y oculta botón (Submit)
    const handleSubmit = async (e) => {
        e.preventDefault();
        const status = await updateMyData({ ...user, current_email: loggedUser.email });
        if (status) {
            //Desactiva nuevamente los inputs de información personal
            setDisabled(true);
            //Oculta el botón "Guardar cambios"
            setShowButton(false)
            //Cierra el dialog
            setDialog(false);
            //Activa nuevamente el boton para editar la información
            setBtnDisabled(false);
            //
            setVerifyPass('');
            loggedIn({ ...loggedUser, email, username });
        }
    }

    /*********************** Cambio de contraseña ***************************/

    //Metodo para mostrar y ocultar contraseña
    const [values, setValues] = useState(false)
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    //Detecta el cambio en el input
    const [verify, setVerify] = useState('');
    const handleVerifyPaass = (event) => {
        setVerify(event.target.value);
    };

    const [confirm, setConfirm] = useState(false)
    const [checkButton, setCheckButton] = useState(false)
    const [loading, setLoading] = useState(false)
    const [icon, setIcon] = useState(false)

    //Verifica si el password es correcto, si lo es muestra los inputs para añadir la contraseña nueva
    const verifyPass = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (await verifiyPwd({ _id, password: verify })) {
            setCheckButton(true);
            setLoading(false);
            setIcon(true);
            setConfirm(true);
        }
        else setLoading(false);
    };

    const handleOnChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        setUser({ ...user, [name]: value });
    };

    return (
        <>
            {/* Titulo y breadcrumb */}
            <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }}>
                <Typography variant='h4' sx={{ fontWeight: 'light' }} gutterBottom>Información y seguridad</Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/mi-cuenta">
                        Mi cuenta
                    </Link>
                    <Typography color="text.primary">Información de la cuenta</Typography>
                </Breadcrumbs>
            </Container>
            {/* Fin titulo y breadcrumb */}

            <Container maxWidth='xl' sx={{ mt: 6 }}>
                <Box sx={{ p: 4 }} className="info-form">
                    {/* formulario de información personal */}
                    <form id="form-info" action="" onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField label="Nombre(s)"
                                        variant="outlined"
                                        name="username"
                                        value={username}
                                        onChange={handleOnChange}
                                        disabled={disabled}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField label="Correo electrónico"
                                        variant="outlined"
                                        name="email"
                                        value={email}
                                        onChange={handleOnChange}
                                        disabled={disabled}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <ButtonGroup variant="contained">
                                    {/* Button para abrir dialog con formulario */}
                                    {
                                        platform !== '' ?
                                            <Button onClick={openDialog} disabled={btnDisabled}>Editar</Button>
                                            : null
                                    }
                                    {/* button submit para guardar los cambios */}
                                    {
                                        showButton && <Button type="submit">Guardar cambios</Button>
                                    }
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </form>
                    {/* Fin formulario */}
                </Box>
            </Container>

            <Container maxWidth='xl' sx={{ mt: 6 }} className="last-view">
                <Box sx={{ boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)', p: 3, borderRadius: '3px' }}>
                    <Typography variant='h5' sx={{ fontWeight: 'light' }} gutterBottom>Contraseña</Typography>
                    {/* Form para verificar si la contraseña es correcta y acceder al cambio de contraseña */}
                    <form action="" onSubmit={verifyPass}>
                        <Grid container spacing={2}>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Contraseña</InputLabel>
                                    <OutlinedInput
                                        type={values.showPassword ? 'text' : 'password'}
                                        placeholder="Contraseña"
                                        value={verify}
                                        onChange={handleVerifyPaass}
                                        style={{ backgroundColor: '#fff' }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <i className="fa-solid fa-key"></i>
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword ? <i className="far fa-eye-slash" style={{ fontSize: '1.3rem' }}></i> : <i className="far fa-eye" style={{ fontSize: '1.3rem' }}></i>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Contraseña"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <small>Para proceder, ingrese su contraseña actual.</small>
                        <Box sx={{ display: 'flex', mt: 2, flexWrap: 'wrap' }}>
                            {/* Botón para validar contraseña */}
                            <Box sx={{ my: 'auto', mr: 2 }}>
                                <Button variant='contained' type="submit" disabled={checkButton}>Verificar contraseña</Button>
                            </Box>

                            {/* Circular progress e icono */}
                            <Box sx={{ my: 'auto' }}>
                                {loading ? <CircularProgress style={{ height: '30px', width: '30px' }} /> : null}
                                {icon ? <i className="fa-solid fa-circle-check" style={{ fontSize: '1.7rem', color: 'green' }}></i> : null}
                            </Box>
                        </Box>
                    </form>
                    {confirm ?
                        <FormPass _id={_id}
                            setCheckButton={setCheckButton}
                            setIcon={setIcon}
                            setConfirm={setConfirm}
                            setVerify={setVerify} />
                        : null}
                </Box>
            </Container>

            {/* Dialog */}
            <Dialog open={dialog} onClose={closeDialog}>
                <DialogTitle>Contraseña</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Para poder cambiar su información personal ingrese su contraseña actual
                    </DialogContentText>
                    {/* Form para poder acceder al cambio de información personal Requiere autenticación de contraseña */}
                    <form action="" id="form-dialog" onSubmit={verificar}>
                        <FormControl fullWidth sx={{ mt: 3 }}>
                            <InputLabel>Contraseña</InputLabel>
                            <OutlinedInput
                                type={pass.showPassword ? 'text' : 'password'}
                                placeholder="Contraseña"
                                value={VerifyPass}
                                onChange={handleOnChangePass}
                                style={{ backgroundColor: '#fff' }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <i className="fa-solid fa-key"></i>
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPass}
                                            edge="end"
                                        >
                                            {pass.showPassword ? <i className="far fa-eye-slash" style={{ fontSize: '1.3rem' }}></i> : <i className="far fa-eye" style={{ fontSize: '1.3rem' }}></i>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Contraseña"
                            />
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancelar</Button>
                    {/* button para verificar si la contraseña es correcta */}
                    <Button type="submit" form="form-dialog">Aceptar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const FormPass = ({ _id, setCheckButton, setIcon, setConfirm, setVerify }) => {
    // campos de contraseña
    const [pwds, setPwds] = useState({ pwd1: '', pwd2: '' });
    const { pwd1, pwd2 } = pwds;
    const pwdChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        setPwds({ ...pwds, [name]: value });
    };

    //Metodo para mostrar y ocultar contraseña
    const [values, setValues] = useState(false)
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    //Segundo input de contraseña
    const [values1, setValues1] = useState(false)
    const handleClickShowPassword1 = () => {
        setValues1({ ...values1, showPassword: !values1.showPassword });
    };

    //Submit password
    const handleSubmitPass = async (e) => {
        e.preventDefault();
        if (await updatePwd({ _id, password: pwd1 })) {
            setCheckButton(false);
            setIcon(false);
            setConfirm(false);
            setVerify('');
        }
    };

    return (
        <form style={{ marginTop: '25px' }} action="" onSubmit={handleSubmitPass}>
            <Grid container spacing={3}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Contraseña nueva</InputLabel>
                        <OutlinedInput
                            type={values.showPassword ? 'text' : 'password'}
                            style={{ backgroundColor: '#fff' }}
                            name='pwd1'
                            value={pwd1}
                            onChange={pwdChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <i className="far fa-eye-slash" style={{ fontSize: '1.3rem' }}></i> : <i className="far fa-eye" style={{ fontSize: '1.3rem' }}></i>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña nueva"
                        />
                    </FormControl>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Confirmar contraseña nueva</InputLabel>
                        <OutlinedInput
                            type={values1.showPassword ? 'text' : 'password'}
                            style={{ backgroundColor: '#fff' }}
                            name='pwd2'
                            value={pwd2}
                            onChange={pwdChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword1}
                                        edge="end"
                                    >
                                        {values1.showPassword ? <i className="far fa-eye-slash" style={{ fontSize: '1.3rem' }}></i> : <i className="far fa-eye" style={{ fontSize: '1.3rem' }}></i>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirmar contraseña nueva"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Button variant='contained' type="submit">Cambiar contraseña</Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default UISComponent;