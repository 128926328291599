import full from '../../assets/resources/sucursales/full-sucursal-1.jpg'
import gdl from '../../assets/resources/sucursales/sucursal_gdl.jpeg'
import roma from '../../assets/resources/sucursales/roma_2.jpg'
import mty from '../../assets/resources/sucursales/mty_2.jpg'
import tlane from '../../assets/resources/sucursales/tlalnepantla_2.jpg'
import tlalpan from '../../assets/resources/sucursales/tlalpan_1.jpg'
import valle from '../../assets/resources/sucursales/valle-de-aragon.jpg'
import puebla from '../../assets/resources/sucursales/sucursal-puebla.jpeg'
import tuxtla from '../../assets/resources/sucursales/tuxtla_2.jpg'
import oaxaca from '../../assets/resources/sucursales/oaxaca_2.jpg'
import hermosillo from '../../assets/resources/sucursales/hermosillo_2.jpeg'
import leon from '../../assets/images/banners/Banners_nuevos/1080x920_Nueva_Sucursal.png'
import toluca from '../../assets/images/banners/Banners_nuevos/1080x920_Nueva_Sucursal_Toluca.png'

export const SucursalesData = [
    {
        id: 1,
        name: 'Guadalajara',
        address: 'C. Miguel Lerdo de Tejada #2218-B, Col Americana, Americana, 44150 Guadalajara, Jal.',
        phone: '33 3616 5409',
        whatsapp: '33 2339 2966',
        mail: 'guadalajara@nefropolis.com',
        location: 'Jalisco',
        locationAccented: '',
        img: gdl,
        link: '/sucursal-guadalajara',
        state: 'Jalisco'
    },
    {
        id: 2,
        name: 'Roma',
        address: 'C. Manzanillo 197, Roma Sur, Cuauhtémoc, 06760 Ciudad de México, CDMX.',
        phone: '55 6387 9987',
        whatsapp: '33 2339 3005',
        mail: 'roma@nefropolis.com',
        location: 'Ciudad de Mexico',
        locationAccented: 'Ciudad de México',
        img: roma,
        link: '/sucursal-roma',
        state: 'Ciudad de México'
    },
    {
        id: 3,
        name: 'Iztapalapa',
        address: 'Av. Tlahuac 19, Santa Isabel Industrial, Iztapalapa, 09820 Ciudad de México, CDMX',
        phone: '55 9046 2288',
        whatsapp: '33 2339 2969',
        mail: 'iztapalapa@nefropolis.com',
        location: 'Ciudad de Mexico',
        locationAccented: 'Ciudad de México',
        img: full,
        link: '/sucursal-iztapalapa',
        state: 'Ciudad de México'
    },
    {
        id: 4,
        name: 'Tlalnepantla',
        address: 'Av. Gustavo Baz #3315 Local C-17, Plaza Olímpus, 54000 Ciudad de México, Méx.',
        phone: '55 7601 1937',
        whatsapp: '33 2339 2972',
        mail: 'tlalnepantla@nefropolis.com',
        location: 'Ciudad de Mexico',
        locationAccented: 'Ciudad de México',
        img: tlane,
        link: '/sucursal-tlalnepantla',
        state: 'Ciudad de México'
    },
    {
        id: 5,
        name: 'Tlalpan',
        address: 'Periferico Sur #5580-P.B, El Caracol, Coyoacán, 04739 Ciudad de México, CDMX.',
        phone: '55 8879 4596',
        whatsapp: '33 2312 7853',
        mail: 'tlalpan@nefropolis.com',
        location: 'Ciudad de Mexico',
        locationAccented: 'Ciudad de México',
        img: tlalpan,
        link: '/sucursal-tlalpan',
        state: 'Ciudad de México'
    },
    {
        id: 6,
        name: 'Valle de Aragón',
        address: 'Av. Central 854, Col Valle de Aragon 1era sección, 57100 Nezahualcóyotl, Méx.',
        phone: '55 8948 9747',
        whatsapp: '33 3559 1185',
        mail: 'valledeearagon@nefropolis.com',
        location: 'Ciudad de Mexico',
        locationAccented: 'Ciudad de México',
        img: valle,
        link: '/sucursal-valle-de-aragon',
        state: 'Ciudad de México'
    },
    {
        id: 7,
        name: 'Monterrey',
        address: 'Belisario Domínguez #2470 Local-3 Plaza Semillero Obispado Col, Obispado, 64060 Monterrey, N.L.',
        phone: '81 3143 0837',
        whatsapp: '33 2358 4170',
        mail: 'monterrey@nefropolis.com',
        location: 'Nuevo Leon',
        locationAccented: 'Nuevo León',
        img: mty,
        link: '/sucursal-monterrey',
        state: 'Nuevo León'
    },
    {
        id: 8,
        name: 'Puebla',
        address: 'Av. 31 Oriente #2 Local - 1 Esquina con, C. 16 de Septiembre, El Carmen, 72530 Puebla, Pue.',
        phone: '22 2243 4765',
        whatsapp: '33 2339 2975',
        mail: 'puebla@nefropolis.com',
        location: 'Puebla',
        locationAccented: '',
        img: puebla,
        link: '/sucursal-puebla',
        state: 'Puebla'
    },
    {
        id: 9,
        name: 'Tlaxcala',
        address: 'C. Miguel Hidalgo y Costilla #22-Local 1, Centro, 90000 Tlaxcala de Xicohténcatl, Tlax.',
        phone: '24 6466 8497',
        whatsapp: '33 2339 2982',
        mail: 'tlaxcala@nefropolis.com',
        location: 'Tlaxcala',
        locationAccented: '',
        img: full,
        link: '/sucursal-tlaxcala',
        state: 'Tlaxcala'
    },
    {
        id: 10,
        name: 'Tuxtla',
        address: 'Av 1a. Sur Pte #519-A, Centro, 29000 Tuxtla Gutiérrez, Chis.',
        phone: '96 1613 5691',
        whatsapp: '33 2339 2983',
        mail: 'tuxtla@nefropolis.com',
        location: 'Chiapas',
        locationAccented: '',
        img: tuxtla,
        link: '/sucursal-tuxtla',
        state: 'Chiapas'
    },
    {
        id: 11,
        name: 'Oaxaca',
        address: 'Netzahualcóyotl #202-Local 1, Reforma, 68050 Oaxaca de Juárez, Oax',
        phone: '95 1515 3668',
        whatsapp: '33 2339 4156',
        mail: 'oaxaca@nefropolis.com',
        location: 'Oaxaca',
        locationAccented: '',
        img: oaxaca,
        link: '/sucursal-oaxaca',
        state: 'Oaxaca'
    },
    {
        id: 12,
        name: 'Querétaro',
        address: 'Av. Tecnológico #35 Plaza Santa Fe Local 1-A, Centro, 76000 Santiago de Querétaro, Qro.',
        phone: '44 2241 5329',
        whatsapp: '33 1944 2736',
        mail: 'queretaro@nefropolis.com',
        location: 'Queretaro',
        locationAccented: 'Querétaro',
        img: valle,
        link: '/sucursal-queretaro',
        state: 'Querétaro'
    },
    {
        id: 13,
        name: 'Hermosillo',
        address: 'Blvd. Luis Encinas J. #23-Interior 2, Centro, 83000 Hermosillo, Son.',
        phone: '66 2381 8690',
        whatsapp: '33 2358 7152',
        mail: 'hermosillo@nefropolis.com',
        location: 'Sonora',
        locationAccented: '',
        img: hermosillo,
        link: '/sucursal-hermosillo',
        state: 'Sonora'
    },
    
    {
        id: 14,
        name: 'Lite Toluca',
        address: 'Jose Maria Morelos y Pavón #23, Local Planta Baja, Col. Pilares, Municipio Metepec, Edo de México, CP 52179.',
        phone: '72 2944 2326',
        whatsapp: '23 7119 5208',
        mail: 'toluca@nefropolis.com',
        location: 'Estado de Mexico',
        locationAccented: 'Estado de México',
        img: toluca,
        link: '/sucursal-lite-toluca',
        state: 'Estado de México'
    },
]