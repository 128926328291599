import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import no_image from '../../../../../assets/images/banners/coleccion-todos1.jpg';

import { GOOGLE_DRIVE_CDN } from '../../../../../constants/constants';

import {
    Typography, Grid, Button, Input,
    InputAdornment, Box,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const ProductSelect = ({ text, setProduct }) => {
    // lista de productos
    const { products } = useSelector(state => state.product);
    const [allProducts, setAllProducts] = useState([]);
    // búsqueda de producto
    const [search, setSearch] = useState('');

    // producto buscando
    const handleInputChange = ({ target }) => {
        setSearch(target.value);
        if (target.value !== '') filterProducts(target.value);
        else setAllProducts([]);
    };

    // filtrar productos por búsqueda
    const filterProducts = (value) => {
        // primero filter y luego set
        if (products.length > 0) {
            let Products = [];
            for (const e of products) {
                const { prod_variants, ..._product } = e;
                for (const variant of prod_variants) {
                    const { _id, ..._variant } = variant;
                    Products = [...Products, {
                        ..._product, ..._variant,
                        prod_var_id: _id,
                        checked: true
                    }];
                }
            }
            const _search = value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            setAllProducts(Products.filter((e) =>
                e.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search)
                ||
                e.variant_title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search)
                ||
                e.category.find(u => u.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(search))
            ));
        }
    };

    // seleccionar producto
    const selectProduct = _prod_var_id => () => {
        const item = allProducts.find(e => e.prod_var_id === _prod_var_id);
        if (item) {
            setProduct({
                variant_id: item.prod_var_id,
                title: item.title,
                variant_title: item.variant_title,
                price: item.price,
                image_id: item.images.length > 0 ?
                    `${GOOGLE_DRIVE_CDN}${item.images[0].id}`
                    : no_image
            });
            setSearch('');
            setAllProducts([]);
        }
    };

    return (
        <Box sx={{ my: 5 }}>
            <Typography variant="subtitle1" gutterBottom>
                {text}
            </Typography>
            <Input fullWidth sx={{ mb: 2 }}
                placeholder="Buscar producto (título/categoría)"
                value={search}
                onChange={handleInputChange}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
            />
            <Grid container spacing={4}>
                {
                    allProducts.map(item =>
                        <Grid item xl={4} lg={6} md={6} sm={6} xs={12} key={item.prod_var_id}>
                            <Box sx={{
                                display: 'flex', justifyContent: 'flex-start', gap: '15px',
                                borderRadius: '5px', padding: '10px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)'
                            }}>
                                <Box sx={{ my: 'auto' }}>
                                    <img width='120' height="120" style={{ objectFit: 'contain' }}
                                        src={
                                            item.images.length > 0 ?
                                                `${GOOGLE_DRIVE_CDN}${item.images[0].id}`
                                            :
                                                no_image
                                        }
                                        alt={
                                            item.images.length > 0 ?
                                                item.images[0].alt
                                            :
                                                'producto-nefropolis'
                                        } />
                                </Box>
                                <Box sx={{ my: 'auto' }}>
                                    <Typography component="div" variant="h6">
                                        { item.title }
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary" component="div" gutterBottom>
                                        { item.variant_title }
                                    </Typography>
                                    <Button size="small" variant='outlined'
                                        onClick={ selectProduct(item.prod_var_id) }>
                                        Seleccionar
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    )
                }
            </Grid>
        </Box>
    );
};

export default ProductSelect;