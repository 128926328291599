import { basicTimerMsg } from "../../actions/swal_msg";

// validación formulario
export const blogFormValidator = ({ title, author, publication_date, _categories, imgFile, row }) => {
    const v1_len = title.length;
    const v2_len = author.length;
    let colCount = 0;
    const content = row.filter((e, i) => i !== 0);
    if (v1_len > 3 && v1_len <= 200) {
        if (v2_len > 2 && v2_len <= 100) {
            if (publication_date) {
                if (_categories.length > 0) {
                    if (imgFile) {
                        for (const e of content) {
                            if (e.col.length > 0)
                                colCount ++;
                        }
                        if (colCount > 0)
                            return true;
                        else
                            basicTimerMsg('Ingrese contenido al blog');
                    } else
                        basicTimerMsg('Ingrese imagen de portada');
                } else
                    basicTimerMsg('Seleccione por lo menos una categoría');
            } else
                basicTimerMsg('Ingrese fecha de publicación');
        } else
            basicTimerMsg('Ingrese autor de blog');
    } else
        basicTimerMsg('Ingrese título de blog');
    return false;
};

// validación formulario de edición
export const blogEditionFormValidator = ({ title, author, publication_date, _categories, row }) => {
    const v1_len = title.length;
    const v2_len = author.length;
    let colCount = 0;
    const content = row.filter((e, i) => i !== 0);
    if (v1_len > 3 && v1_len <= 200) {
        if (v2_len > 2 && v2_len <= 100) {
            if (publication_date) {
                if (_categories.length > 0) {
                    for (const e of content) {
                        if (e.col.length > 0)
                            colCount ++;
                    }
                    if (colCount > 0)
                        return true;
                    else
                        basicTimerMsg('Ingrese contenido al blog');
                } else
                    basicTimerMsg('Seleccione por lo menos una categoría');
            } else
                basicTimerMsg('Ingrese fecha de publicación');
        } else
            basicTimerMsg('Ingrese autor de blog');
    } else
        basicTimerMsg('Ingrese título de blog');
    return false;
};