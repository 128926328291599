import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

//Components
import InformacionInteres from './InformacionInteres/InformacionInteres'
import InformacionPersonal from './InformacionPersonal/InformacionPersonal'
import Registro from './Registro/Registro'

const multiStepTitle = ['Registro', 'Información de de interés', 'Información personal'];

const SignUp = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const [page, setPage] = useState(0);
    
    const nextStep = (e) => {
        if (page === multiStepTitle.length - 1) {
            navigate('/gracias-registro');
        }
        else {
            setPage((currPage) => currPage + 1)
            window.scrollTo({
                top: 0,
            });
        }
    }

    const prevStep = () => {
        setPage((currPage) => currPage - 1)
        window.scroll(0, 0)
    }

    const pageDisplay = () => {
        if (page === 0) {
            return <Registro nextstep={() => nextStep()} prevStep={prevStep}
                page={ state ? state.page : 'mi-cuenta' } />
        }
        else if (page === 1) {
            return <InformacionInteres nextstep={() => nextStep()} prevStep={prevStep} />
        }
        else {
            return <InformacionPersonal nextstep={() => nextStep()} prevStep={prevStep} />
        }
    }

    return (
        <>
            <div className="body">
                {pageDisplay()}
            </div>
        </>
    );
};

export default SignUp;