import React from "react";

import { CUSTOMER_EMAIL } from "../../../constants/constants";

//MUI
import { Container, Typography, useMediaQuery } from "@mui/material";

const TerminosCondicionesComponent = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    return (
        <>
            <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }}>
                <Typography
                    variant='h4'
                    sx={{ fontWeight: 'light' }}
                    align='center'
                    paragraph>
                    Términos y Condiciones
                </Typography>
            </Container>

            <Container maxWidth='xl' sx={{ mt: 5 }} className="last-view">
                <p>Bienvenido a SOLUCIONES INTEGRALES BTL S.A DE C.V. en lo sucesivo “PNR”</p>
                <p><b>Declaración de derechos y responsabilidades</b></p>
                <p>Esta Declaración de derechos y responsabilidades (en lo sucesivo, “Declaración”,
                    “Condiciones” o “DDR”) tiene su origen en los Principios de “PNR” y contiene las
                    condiciones de servicio que rigen nuestra relación con los usuarios y con todos
                    aquellos que interactúan con “PNR”, así como con las marcas, los productos y los
                    servicios de “PNR”, que se denominan “Productos”, “servicios de PNR” o “servicios”.</p>
                <p><b>Al utilizar o acceder a los servicios de “PNR” Usted acepta quedar vinculado por
                    las presentes condiciones.</b></p>
                <p>Puesto que “PNR” ofrece una amplia gama de servicios y productos, es posible que te
                    pidamos que leas y aceptes condiciones complementarias aplicables a tu interacción
                    con una aplicación, un producto o un servicio determinados. En caso de que esas
                    condiciones complementarias entren en conflicto con estos TYC, las condiciones
                    complementarias asociadas con la aplicación, el producto o el servicio prevalecerán
                    en lo referente al uso de tales aplicaciones, productos o servicios en caso de
                    conflicto.</p>
                <h5 className="text-uppercase">Condiciones de Uso</h5>
                <p><b>Le rogamos que lea detenidamente las presentes condiciones antes de utilizar los
                    “Servicios o Productos de PNR”. Al utilizar los “Servicios de PNR”, usted acepta
                    quedar vinculado por las presentes condiciones.</b> Ofrecemos una amplia gama de
                    “Servicios y productos de PNR” y en ocasiones pueden aplicarse condiciones adicionales.
                    Además, cada vez que utilice cualquier “Servicio O Producto de PNR”, (por ejemplo:
                    Aplicaciones moviles, productos NIN, alimentos, suplementos, obras literarias etc.),
                    estará igualmente sujeto a los términos y condiciones generales y a las condiciones
                    particulares aplicables a dichos Servicios o productos de PNR (las “Condiciones Generales
                    de los Servicios”). Las Condiciones Generales de los Servicios o productos prevalecerán
                    sobre las presentes Condiciones de Uso en caso de discrepancia entre ambas.</p>
                <p><b>1. Privacidad</b></p>
                <p>Le rogamos que revise nuestro Aviso de Privacidad, y nuestra página sobre Cookies y
                    Publicidad en Internet, que también rigen el uso que realice de los “Servicios o
                    Productos de PNR”, para que conozca nuestras prácticas.</p>
                <p><b>2. Comunicaciones electrónicas</b></p>
                <p>Cada vez que utilice un “Servicio o Producto de PNR” o nos envíe un correo electrónico,
                    un mensaje de texto (SMS) o cualquier otra comunicación desde su ordenador o dispositivo
                    móvil, estará comunicándose electrónicamente con nosotros. Nosotros nos pondremos en
                    contacto con usted electrónicamente por distintos medios, como por ejemplo mediante
                    correo electrónico, mensajes de texto (SMS), notificaciones dentro de aplicaciones,
                    o publicando mensajes o comunicaciones por correo electrónico en el sitio web o a través
                    de cualquier otro “Servicio de PNR”. A efectos del presente contrato, usted consiente recibir
                    comunicaciones de nuestra parte mediante medios electrónicos, y acepta que todos los
                    contratos, avisos y otras notificaciones y comunicaciones que le enviemos por medios
                    electrónicos satisfacen cualquier requisito de forma escrita, salvo que cualquier
                    legislación aplicable con carácter imperativo exigiera una forma distinta de comunicación.</p>
                <p><b>3. Derechos de autor, derechos de propiedad intelectual y derechos sobre bases de datos</b></p>
                <p>Todo contenido alojado o puesto a disposición en cualquiera de los “Servicios o Productos de PNR”,
                    como por ejemplo texto, gráficos, logotipos, iconos de botones, imágenes, clips de audio, descargas
                    digitales, y recopilaciones de datos son propiedad de PNR o de sus proveedores de contenido y
                    está protegido por las Leyes Mexicanas, así como por la legislación internacional sobre derechos
                    de propiedad intelectual, derechos de autor y derechos sobre bases de datos. El conjunto de todo
                    el contenido albergado o puesto a disposición a través de cualquier “Servicio o Producto de PNR”
                    es propiedad exclusiva de “PNR”, y está protegido por las Leyes Mexicanas e Internacionales
                    sobre derechos de propiedad intelectual y derechos sobre bases de datos.</p>
                <p>No está permitida la extracción sistemática ni la reutilización de parte alguna del contenido
                    de ninguno de los “Servicio o Producto de PNR” sin nuestro expreso consentimiento por escrito.
                    En particular, no se permite el uso de herramientas o robots de búsqueda y extracción de datos
                    para la extracción (ya sea en una o varias ocasiones) de partes sustanciales de los “Servicio
                    o Producto de PNR” para su reutilización sin nuestro expreso consentimiento por escrito.
                    Tampoco le está permitido al usuario crear, ni publicar sus propias bases de datos cuando
                    éstas contengan partes sustanciales de cualquiera de los “Servicio o Producto de PNR”
                    (por ejemplo, nuestras listas de productos, servicios y listas de precios) sin nuestro
                    expreso consentimiento por escrito.</p>
                <p><b>4. Marcas registradas</b></p>
                <p>Los gráficos, logotipos, encabezados de página, iconos de botón, scripts y nombres de servicio
                    que aparecen incluidos o están disponibles a través de los “Servicio o Producto de PNR” son
                    marcas registradas o representan la imagen comercial de “PNR” o de sus clientes. No podrán
                    utilizarse las marcas registradas ni la imagen comercial de “PNR” en relación con ningún producto
                    o servicio que no pertenezca a “PNR”, ni en ninguna forma que fuera susceptible de causar confusión
                    entre los usuarios o que pueda menospreciar o desacreditar a “PNR”. El resto de marcas registradas
                    que no son propiedad de “PNR” y que aparecen incluidos o están disponibles a través de los
                    Servicios o Productos de “PNR” pertenecen a sus respectivos propietarios, quienes podrán o no
                    estar afiliados o relacionados de cualquier modo con “PNR”, o patrocinados por “PNR”.</p>
                <p><b>5. Patentes</b></p>
                <p>Diversas patentes pertenecientes a “PNR” son aplicables a los “Servicio o Producto de PNR”,
                    así como a las funciones y servicios accesibles a través de los mismos. Por otro lado,
                    diferentes partes de los “Servicio o Producto de PNR” operan en virtud de distintas licencias
                    de una o varias patentes.</p>
                <p><b>6. Licencia y acceso</b></p>
                <p>Sujeto al cumplimiento por Usted de estas Condiciones de Uso y las Condiciones Generales de
                    los Servicios aplicables, así como al pago del precio aplicable, en su caso, “PNR” o sus
                    proveedores de contenidos le conceden una licencia limitada no exclusiva, no transferible
                    y no sublicenciable, de acceso y utilización, a los “Servicio o Producto de PNR” para fines
                    personales no comerciales. Dicha licencia no incluye derecho alguno de reventa, ni de uso
                    comercial de los “Servicio o Producto de PNR”, ni de sus contenidos, ni derecho alguno a
                    compilar ni utilizar lista alguna de productos, descripciones o precios. Tampoco incluye
                    el derecho a realizar ningún uso derivado de los “Servicio o Producto de PNR”, ni de sus
                    contenidos, ni a descargar o copiar información de cuenta alguna para el beneficio de otra
                    empresa, ni el uso de herramientas o robots de búsqueda y extracción de datos o similar.</p>
                <p>“PNR” y sus licenciantes, proveedores, editores, titulares de derechos u otros proveedores
                    de contenidos se reservan cualquier derecho que no esté expresamente comprendido en estas
                    Condiciones de Uso o en las Condiciones Generales de los Servicios.</p>
                <p>No está permitida la reproducción, duplicación, copia, venta, reventa o explotación de ningún
                    tipo de los “Servicio o Producto de PNR”, ni de parte alguna de los mismos con fines
                    comerciales, en cada caso sin nuestro previo consentimiento por escrito.</p>
                <p>Tampoco está permitido utilizar técnicas de framing para introducir cualquier marca comercial,
                    logotipo u otra información protegida por derechos de autor (incluyendo imágenes, texto,
                    diseños de página o formatos) de “PNR” sin el correspondiente consentimiento previo por
                    escrito. No está permitido el uso de metaetiquetas (meta tags) ni de ningún otro “texto oculto”
                    que utilice los nombres o marcas registradas de “PNR” sin nuestro consentimiento previo y por
                    escrito.</p>
                <p>Le rogamos que haga un uso correcto de los “Servicio o Producto de PNR”. Sólo le está permitido
                    utilizar los “Servicio o Producto de PNR” de forma lícita. Cualquier incumplimiento por Usted
                    de estas Condiciones de Uso o de las Condiciones Generales de los Servicios supondrá la
                    anulación del permiso o la licencia concedidos por “PNR”.</p>
                <p><b>7. Su Cuenta</b></p>
                <p>Cuando usted utiliza los “Servicio o Producto de PNR” es responsable de mantener la confidencialidad
                    de los datos de su cuenta y su contraseña, así como de restringir el acceso a su ordenador y a sus
                    dispositivos. En la medida en que así lo permita la legislación aplicable, usted acepta asumir la
                    responsabilidad que proceda por todas las actividades realizadas desde su cuenta o utilizando su
                    contraseña. Usted deberá tomar todas las medidas necesarias a efectos de asegurar y salvaguardar la
                    confidencialidad de su contraseña, y deberá informarnos inmediatamente en caso de que tenga motivos
                    para creer que su contraseña ha sido puesta en conocimiento de un tercero, o si ésta ha sido utilizada
                    de manera no autorizada o es susceptible de serlo. Es su responsabilidad comprobar que los datos que
                    nos facilita son correctos y completos, viniendo asimismo obligado a informarnos inmediatamente
                    cuando tenga lugar cualquier variación en la información que nos ha facilitado. Puede acceder a
                    su información y actualizarla a través del sitio web.</p>
                <p>No podrá utilizar ningún “Servicio o Producto de PNR”: (i) en forma alguna que cause, o pueda causar,
                    daño o perjuicio alguno a cualquiera de los “Servicio o Producto de PNR” o la interrupción del acceso
                    a los mismos; o (ii) para cualquier fin fraudulento, ni a efectos de la comisión de delito alguno
                    u otra actividad ilícita de ningún otro tipo; o (iii) para generar cualquier tipo de molestia,
                    inconveniente o ansiedad en un tercero.</p>
                <p>Nos reservamos el derecho a denegar el acceso al servicio, a cancelar cualesquiera cuentas y a
                    eliminar o modificar cualquier contenido en caso de que el usuario incumpliera la legislación
                    aplicable, las presentes Condiciones de Uso o cualesquiera otros términos y condiciones o políticas
                    aplicables.</p>
                <p><b>8. Opiniones, comentarios, comunicaciones y otros contenidos</b></p>
                <p>Los usuarios podrán publicar sus opiniones y comentarios, así como publicar otros contenidos,
                    enviar comunicaciones, sugerencias, ideas, comentarios, preguntas u otra información, siempre
                    que el contenido de las mismas no resulte ilícito, obsceno, abusivo, constituya un supuesto de
                    amenaza o difamación, o invada la privacidad de terceros, infrinja derechos de propiedad intelectual
                    o de cualquier otra forma resulte ofensivo para terceros o censurable, ni consista en o contenga
                    virus informáticos, propaganda política o contenido publicitario, correos en cadena, envío masivo de
                    correos o constituya cualquier otro tipo de “spam”. No está permitido el uso de direcciones de correo
                    falsas, ni suplantar la identidad de otra persona o entidad, ni falsear de cualquier otro modo el
                    origen de cualquier otro contenido. Nos reservamos el derecho (sin que no obstante, y en ausencia
                    de un Formulario de Aviso, venga obligada a ello) a eliminar o modificar cualquier contenido. Si
                    considera que cualquier contenido de los “Servicio o Producto de PNR” o cualquier material cuya venta
                    estuviera publicitada en los mismos incluye cualquier afirmación difamatoria, o si entendiera que sus
                    derechos de propiedad intelectual están siendo infringidos por cualquier elemento o información
                    disponible en los “Servicio o Producto de PNR”, le rogamos nos haga saber dicha circunstancia a
                    través de la cumplimentación y presentación del correspondiente Formulario de Aviso, al que
                    responderemos.</p>
                <p>Al publicar cualquier contenido o presentar cualquier material para su publicación, y salvo que se
                    indique lo contrario, Usted otorga: (a) a favor de “PNR” el derecho no exclusivo, gratuito y
                    susceptible de plena cesión y transmisión a favor de un tercero, a utilizar, reproducir, modificar,
                    adaptar, publicar, traducir, crear y explotar cualesquiera obras derivadas y distribuir y exhibir
                    su contenido en todo el mundo y a través de cualquier medio; y (b) a favor de “PNR”, sus
                    sublicenciatarios y quienes derivaran su título de “PNR”, el derecho a utilizar el nombre facilitado
                    por Usted en relación con dicho contenido, si así lo decidiera. La presente disposición no supone la
                    cesión de derecho moral alguno.</p>
                <p>Usted acepta el carácter irrevocable de los derechos así conferidos durante la totalidad del plazo
                    de protección reconocido a sus derechos de propiedad intelectual sobre el contenido y material
                    identificado anteriormente. Asimismo se compromete a llevar a cabo cualesquiera actuaciones adicionales
                    que fueran necesarias a efectos de formalizar la cesión de los derechos que confiere a favor de “PNR”,
                    incluyendo la suscripción de cualesquiera instrumentos y documentación, a nuestra solicitud.</p>
                <p>Usted declara y garantiza ser titular de cualquier otra forma controlar la totalidad de los derechos
                    sobre el contenido que pudiera publicar, manifestando y garantizado asimismo que a la fecha de la
                    remisión de dicho contenido: (i) dicho contenido y material es exacto; y que (ii) la utilización de
                    dicho contenido o material no supondrá incumplimiento alguno de las políticas o directrices aplicables
                    de “PNR”, ni supondrá perjuicio alguno a ninguna persona o entidad (garantizando asimismo que el
                    contenido o material en cuestión no es de carácter difamatorio). Usted se compromete a liberar a “PNR”
                    de cualesquiera reclamaciones presentadas por terceros contra “PNR” derivadas de o en relación con dicho
                    contenido y materiales, salvo en la medida en que dicha responsabilidad derive de la falta de supresión
                    adecuada por nuestra parte de dicho contenido o material tras haber sido notificados (a través del
                    correspondiente Formulario de Aviso) del carácter ilícito de dicho contenido o material.</p>
                <p><b>9. Reclamaciones sobre Propiedad Intelectual</b></p>
                <p>“PNR” respeta la propiedad intelectual de terceros. Si considera que sus derechos de propiedad
                    intelectual han podido ser infringidos, le rogamos que haga uso de nuestra Política y
                    Procedimiento de Reclamaciones por Infracción.</p>
                <p><b>10. Condiciones Generales sobre el Software y WEB de PNR</b></p>
                <p>Todo software o WEB, incluidas las actualizaciones, mejoras y cualquier otra documentación
                    relacionada con dicho software o WEB, que ponemos a disposición de Ud. en cualquier momento
                    en relación con los “Servicio o Producto de PNR” (el “Software o WEB de PNR”) está sujeto a
                    las presentes Condiciones de Uso y Venta.</p>
                <p><b>11. El papel de PNR</b></p>
                <p>“PNR” permite a vendedores externos ofrecer y vender sus productos en la página de internet.
                    A pesar de que “PNR” facilita las transacciones a través de pagina y programas, “PNR” no es
                    ni el comprador, ni el vendedor de los productos ofrecidos por vendedores externos. “PNR”
                    simplemente facilita un espacio donde compradores y vendedores pueden negociar y efectuar
                    sus transacciones. Por consiguiente, el correspondiente contrato que se genera una vez se ha
                    realizado la compra de cualquier producto vendido por un vendedor externo, concierne única y
                    exclusivamente al comprador y al vendedor de dicho producto. “PNR” no forma parte de ese contrato,
                    no asume ninguna responsabilidad relacionada con el mismo, ni actúa como representante del
                    vendedor. El vendedor es responsable de la venta de sus productos, así como de ofrecer asistencia
                    en cuanto a las reclamaciones del comprador, o con respecto a cualquier asunto relacionado con
                    dicho contrato entre el comprador y vendedor. No obstante, y debido a que “PNR” desea garantizar
                    al comprador un espacio seguro donde realizar sus compras, “PNR” ofrece la Garantía de compromiso
                    con nuestros clientes para que estos queden satisfechos, además de cualquier derecho que el
                    consumidor pueda tener por disposición legal o contractual.</p>
                <p><b>12. Nuestra responsabilidad</b></p>
                <p>Realizaremos nuestros mejores esfuerzos para asegurar la disponibilidad, sin interrupciones,
                    de los “Servicio o Producto de PNR”, así como la ausencia de errores en cualquier transmisión
                    de información que pudiera tener lugar. No obstante, y debido a la naturaleza misma de Internet,
                    no es posible garantizar tales extremos. Asimismo, su acceso a los “Servicio o Producto de PNR”
                    pudiera ocasionalmente verse suspendido o restringido a efectos de la realización de trabajos
                    de reparación o mantenimiento, o la introducción de nuevos productos o servicios. Procuraremos
                    limitar la frecuencia y duración de tales suspensiones o restricciones.</p>
                <p>“PNR” no será responsable de (i) cualesquiera pérdidas que no fueran atribuibles a incumplimiento
                    alguno por su parte, (ii) pérdidas empresariales (incluyendo lucro cesante, de ingresos, de
                    contratos, de ahorros previstos, de datos, pérdida del fondo de comercio o gastos innecesarios
                    incurridos), ni de (iii) cualesquiera pérdidas indirectas o de carácter consecuencial que no
                    fueran razonablemente previsibles por ambas partes en el momento en que el usuario hubiera comenzado
                    a utilizar los“Servicio o Producto de PNR”. Tampoco seremos responsables de ninguna demora o falta
                    de cumplimiento de nuestras obligaciones derivadas de las presentes condiciones si dicha demora o
                    falta de cumplimiento fuera atribuible a circunstancias ajenas a nuestro control razonable. Esta
                    disposición no afecta al derecho del cliente a recibir el producto o la prestación del correspondiente
                    servicio en un plazo razonable, o a recibir el oportuno reembolso para el caso de que no pudiéramos
                    suministrarle tales productos o servicios en un plazo razonable por cualquier causa ajena a nuestro
                    control razonable.</p>
                <p>La legislación de algunos países pudiera no permitir alguno o la totalidad de los límites de
                    responsabilidad previstos anteriormente. En caso de que dicha legislación le fuera de aplicación,
                    alguno o la totalidad de dichos límites pudiera no serle aplicable. Asimismo dicha legislación
                    pudiera conferirle derechos adicionales a los aquí previstos.</p>
                <p>Nada de lo dispuesto en las presentes condiciones limita o excluye nuestra responsabilidad en caso
                    de falsedad, ni en supuestos de fallecimiento o daños personales atribuibles a nuestra negligencia
                    o dolo.</p>
                <p><b>13. Ley aplicable</b></p>
                <p>Las presentes condiciones se regirán e interpretarán de conformidad con las leyes Mexicanas,
                    principalmente el Código de Comercio y supletoriamente el Código Civil Federal y los usos y
                    costumbres mercantiles, excluyéndose expresamente la aplicación de la Convención de las Naciones
                    Unidas sobre los Contratos de Compraventa Internacional de Mercaderías. Ambas partes acordamos
                    someternos a la jurisdicción exclusiva de los Tribunales Estatales o Federales de la zona metropolita
                    de Guadalajara, Jalisco, México.</p>
                <p><b>14. Modificación del Servicio o Variación de las Condiciones</b></p>
                <p>Nos reservamos el derecho a realizar cambios en los “Servicio o Producto de PNR”, en nuestras
                    políticas y en nuestros términos y condiciones, incluyendo las presentes Condiciones de Uso y las
                    Condiciones Generales de los Servicios, en cualquier momento. Usted quedará sujeto a los términos
                    y condiciones, políticas, Condiciones de Uso y Condiciones Generales de los Servicios vigentes a
                    la fecha en que utilice los “Servicio o Producto de PNR” Si alguna de las presentes Condiciones
                    de Uso o de las Condiciones Generales de los Servicios fuera declarada inválida, nula o por
                    cualquier causa ineficaz, dicha condición se entenderá excluida sin que dicha declaración pueda
                    afectar a la validez ni a la exigibilidad del resto de condiciones.</p>
                <p><b>15. Renuncia</b></p>
                <p>En caso de incumplimiento por su parte de las presentes Condiciones de Uso, y aun cuando pudiéramos
                    optar por no ejercitar cualesquiera derechos a nuestro alcance en dicho momento, podremos hacer
                    uso de tales derechos y acciones en cualquier otra ocasión en la que Usted pudiera incumplir
                    nuevamente las presentes Condiciones de Uso.</p>
                <p><b>16. Menores de edad</b></p>
                <p>No vendemos productos ni prestamos servicios a menores de edad, ni estos pueden hacer uso de
                    los “Servicio o Producto de PNR”.</p>
                <p><b>17. Nuestros datos</b></p>
                <p>El presente sitio web es propiedad de PNR, quien es responsable asimismo de su mantenimiento.</p>
                <p><b>Información sobre PNR:</b></p>
                <p>PRODUCTOS DE NUTRICION RENAL, S. DE R.L. DE C.V. : Por su parte declara ser una sociedad
                    legalmente constituida al amparo de las leyes mexicanas, bajo la escritura pública número
                    19,906 diecinueve mil novecientos seis de fecha 23 veintitres de abril del año 2012 dos mil
                    doce, pasada ante la fe del Lic. Pablo Gonzalez Vazquez, Notario Público número 35 treinta y
                    cinco de Zapopan, Jalisco, méxico, la cual se encuentra inscrita en el registro público de
                    la propiedad y del comercio de la ciudad de guadalajara, jalisco y tener su domicilio social
                    para recibir notificaciones en la calle lerdo de tejada número 2376-A en la colonia Americana
                    en la ciudad de Guadalajara, Jalisco, con código postal 44160.</p>
                <p><b>18. Procedimiento y formulario de aviso de vulneración de derechos</b></p>
                <p>Si considera que cualquiera de sus derechos ha sido vulnerado, le invitamos a cumplimentar y
                    remitirnos el siguiente Formulario de Aviso. Responderemos de forma inmediata a aquellos titulares
                    de derechos y a sus representantes que pudieran cumplimentar y presentar el Formulario de Aviso a
                    efectos de comunicarnos cualquier inquietud que pudieran tener en relación con cualquier supuesta
                    infracción de sus derechos.</p>
                <p>Una vez recibamos el Formulario de Aviso, podremos iniciar diversas actuaciones, que podrán
                    incluir la supresión de la información o producto en cuestión, sin que ello implique en ningún
                    caso admisión alguna de responsabilidad y sin perjuicio de cualquier derecho, acción o defensa
                    que pudiera asistirnos, todos los cuales quedan expresamente reservados. Asimismo, y en virtud
                    de la remisión por su parte de un Formulario de Aviso, se entenderá que otorga a favor de “PNR”
                    el derecho a utilizar, reproducir, modificar, adaptar, publicar, traducir, crear cualesquiera obras
                    derivadas a partir de dicho contenido y exhibir las mismas en todo el mundo y a través de cualquier
                    medio. Lo anterior incluye el derecho a nuestro favor a remitir el Formulario de Aviso a las
                    partes que hubieran facilitado el contenido supuestamente infractor. Usted se compromete a exonerar
                    de responsabilidad a “PNR” respecto de cualesquiera reclamaciones presentadas por terceros frente a
                    “PNR” derivadas de o relativas a la presentación de un Formulario de Aviso.</p>
                <p><b>Definiciones de ASIN y ISBN-10:</b> ASIN significa “PNR Standard Item (or Identification) Number”
                    (Número de Artículo o de Identificación Estándar de PNR) y representa un identificador propio
                    de PNR formado por diez (10) caracteres. Este número figura en la ficha descriptiva de cada
                    producto en el apartado “Detalles del producto”. “ISBN-10” significa “International Standard
                    Book Number” (Número Estándar Internacional de Libro) y es un identificador formado por diez (10)
                    dígitos que figuran en algunas fichas descriptivas de libros en el apartado “Detalles del producto
                    o servicio”.</p>
                <p><b>Aviso importante:</b> facilitar información falsa, engañosa o incorrecta en el Formulario de Aviso
                    puede dar lugar a responsabilidades tanto civiles como penales. Si tiene dudas al respecto, le
                    recomendamos que consulte con su asesor legal.</p>
                <p><b>20. Condiciones de Uso adicionales del Software y Web de PNR</b></p>
                <p><b>Uso del software o Web de “PNR”.</b> Podrá utilizar el Software o Web de “PNR” única y exclusivamente
                    para utilizar y disfrutar de los “Servicio o Producto de PNR” que le facilita “PNR”, según permiten
                    las Condiciones de Uso, estas Condiciones de Uso de Software o Web y las Condiciones Generales de
                    los Servicios. No podrá separar ninguno de los componentes individuales del Software o Web de “PNR”
                    para usarlos en sus propios programas o compilar cualquier parte de ello junto con sus programas,
                    ni transferirlo para su uso junto con otro servicio, ni podrá vender, alquilar, arrendar, prestar,
                    distribuir, ni otorgar sublicencias, ni ceder de cualquier otro modo ningún derecho del Software o
                    Web de “PNR”, ya sea en parte o en su totalidad. No puede utilizar el Software o Web de “PNR” para
                    un uso ilegal. Podremos anular la prestación del Software o Web de “PNR” y denegarle el derecho de
                    uso del Software o Web de “PNR” en cualquier momento. En caso de incumplimiento de estas Condiciones
                    de Uso de Software, las Condiciones de Uso de “PNR” y otras Condiciones Generales de los Servicios
                    o productos, sus derechos de uso del Software o Web “PNR” cesarán sin notificación previa. Existen
                    condiciones adicionales contenidas o distribuidas junto con el Software o Web de “PNR”, según se
                    especifican en la documentación relacionada que son aplicables al Software o Web de “PNR” y que
                    prevalecerán en el uso de dicho software o Web en caso de conflicto con las presentes Condiciones
                    de Uso de Software o Web. Todo el software o Web utilizado por los Servicios o productos de “PNR” y
                    es propiedad de “PNR” o sus proveedores de contenido software o Web está protegido por las leyes
                    Mexicanas e internacionales sobre derechos de propiedad intelectual e industrial.</p>
                <p><b>Utilización de servicios de terceros.</b> Cuando utilice el Software o Web de “PNR”, puede también
                    estar utilizando uno o varios servicios de terceros, como pueden ser los servicios de datos de un
                    soporte, paqueteria o proveedor de plataformas móviles por utilización de las redes inalámbricas.
                    El uso de dichos servicios prestados por terceros puede estar sujeto a políticas adicionales,
                    condiciones de uso y tasas aplicables.</p>
                <p><b>Prohibición de realizar actos de ingeniería inversa.</b> No le está permitido, ni tampoco incentivar,
                    ayudar o autorizar a cualquier otra persona, a, copiar, realizar actos de ingeniería inversa,
                    descompilar, ni desensamblar, o de cualquier otra forma manipular, el Software o Web de “PNR”,
                    ya sea en parte o en su totalidad, ni crear obras derivadas desde o sobre el Software o Web de
                    “PNR”.</p>
                <p><b>Actualizaciones automáticas.</b> A fin de mantener su Software y Web de “PNR” actualizado, puede
                    que le ofrezcamos, sin previa notificación al efecto, actualizaciones ocasionales automáticas
                    o manuales.</p>
                <p><b>Condiciones de venta</b></p>
                <p>Las presentes Condiciones de Venta se aplican a la venta de productos por parte de “PNR”. Además,
                    cada vez que utilice cualquier servicio presente o futuro de “PNR” estará igualmente sujeto a los
                    términos y condiciones generales y a las condiciones particulares aplicables a dicho servicio
                    (las “Condiciones Generales”). Dichas Condiciones Generales prevalecerán sobre las presentes Condiciones
                    de Venta en caso de discrepancia entre ambas.</p>
                <p><b>Nuestros Productos</b></p>
                <p>Aplicaciones moviles, productos NIN, alimentos, suplementos, obras literarias y otros insumos
                    del mismo giro.</p>
                <p><b>Uso del Servicio</b></p>
                <p>Las presentes Condiciones del Servicio tienen por objeto definir los términos y condiciones de
                    los Usuarios que acceden y utilizan los Servicios, en aras de garantizar las exigencias del
                    Estatuto del Consumidor y demás normas pertinentes.</p>
                <ul>
                    <li>Al ingresar en el sitio Web o software de PNR y realizar una compra usted confirma que ha
                        leído, comprendido y acepta irrestrictamente los términos y condiciones en su calidad de
                        usuario.</li>
                    <li>El servicio consiste en la compra de productos o servicios : ya sean alimentos, suplementos,
                        probióticos, obras literarias y otros insumos.</li>
                    <li>El cliente selecciona los productos que le son de interés y los agrega al carrito de compras, una vez que haya seleccionado todos los productos a comprar, da click en finalizar la compra, registra sus datos de contacto, dirección de envío y procede al pago del o los productos solicitados.</li>
                    <li>En caso de que su dirección este incompleta uno/a de nuestros/as asesores/as se comunicará con usted para completar la información de envió</li>
                    <li>El monto total del pago corresponde al precio de los productos más el envío a domicilio.</li>
                    <li>Los Envíos Foráneos abarcan toda la República Mexicana, a excepción de que la paquetería no cuente con la cobertura o se reserve el derecho de algún destino.</li>
                    <li>El tiempo aproximado de entrega es de 48 horas después de realizar el envío, hay Códigos Postales donde este tiempo de entrega puede variar. Por la contingencia no se cuenta con tiempo en la promesa de entrega.</li>
                    <li>El costo del envío dependerá del peso del producto/s y de la Zona a la que éste se mande.</li>
                    <li>Los envíos se realizarán por medio de una paquetería Externa. La elección de ésta será la que en su caso resulte más económica y llegue a su localidad.</li>
                    <li>Para solicitar información y aclarar cualquier duda respecto a su pedido puede comunicarse al 33 38 26 80 09 o enviar un correo a: <a href={ `mailto:${CUSTOMER_EMAIL}` }>{ CUSTOMER_EMAIL }</a></li>
                    <li>Las ventas a través de internet se realizan a reserva de la existencia de los productos.</li>
                    <li>Si usted desea adquirir alguno de nuestros productos o servicios puede comunicarse al <a href="tel:3338268009">33 38 26 80 09</a> para conocer las fechas de caducidad de los mismos. En caso de que haya realizado el pago y no contemos con la existencia, un asesor/a se pondrá en contacto con usted para informarle y darle la fecha en que el producto le será enviado</li>
                    <li>Solamente se harán cambios de productos por lo siguiente:
                        <ul>
                            <li>Si no cumple con los tiempos de entrega por parte de paquetería contratada y esto compromete la estabilidad del producto.</li>
                            <li>Si el producto se recibe derramado, quebrado, abollado.</li>
                        </ul>
                    </li>
                    <li>Una vez recibido su paquete, usted tiene 24 horas para reportar cualquier incidencia con el mismo y/o solicitar su cambio o devolución al teléfono 3338268009 ext 118.</li>
                    <li>El producto a cambiar deberá ser devuelto en un lapso no mayor a 72 horas</li>
                    <li>Todo producto que se reciba en mal estado, deberá ser reportado mediante evidencia fotográfica al correo { CUSTOMER_EMAIL }, siempre que aplique.</li>
                    <li>No se realizarán cambios si el producto fue abierto o consumido sin necesidad</li>
                    <li>No se realizará cambios cuando el producto tenga un consumo mayor al 20%</li>
                    <li>No se hacen devoluciones en efectivo.</li>
                    <li>Todo cambio o devolución deberá ir respaldado por su comprobante de pago.</li>
                    <li>Los costos del envío por devolución serán cubiertos por el consumidor, en el caso de que sea devolución o incumplimiento de su parte.</li>
                    <li>Los costos del envío por devolución serán cubiertos por PNR, en el caso de que sea devolución o incumplimiento de su parte.</li>
                    <li>En particular, se podrá negar la venta de productos o servicios a:
                        <ul>
                            <li>Cualquier usuario cuyo comportamiento sea irrespetuoso o inapropiado para con el personal de PNR, o en el blog de la página web.</li>
                            <li>Cualquier usuario que tenga un comportamiento delictivo en el Sitio Web o en el uso de los Servicios.</li>
                            <li>Cualquier usuario que use el Sitio Web de forma fraudulenta.</li>
                            <li>Cualquier usuario que tenga adeudos pendientes con PNR.</li>
                        </ul>
                    </li>
                </ul>
                <p><b>Se hace uso de Openpay como pasarela de pagos.</b></p>
                <p>Guadalajara, Jalisco.</p>
                <p>Productos de Nutrición Renal, S. de R.L. de C.V.</p>
            </Container>
        </>
    );
};

export default TerminosCondicionesComponent;