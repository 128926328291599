import React from 'react';
import { Typography, Grid, Button, Box } from "@mui/material";
import { GOOGLE_DRIVE_CDN, PRODUCT_NO_IMG } from '../../../../constants/constants';

const ProductItem = ({ allProducts, setAllProducts, setSearch, setCoupon }) => {

    // seleccionar producto
    const selectProduct = _prod_var_id => () => {
        const item = allProducts.find(e => e.prod_var_id === _prod_var_id);
        if (item) {
            setCoupon(e => ({
                ...e,
                purchase_requirements: {
                    ...e.purchase_requirements,
                    applies_to: {
                        ...e.purchase_requirements.applies_to,
                        product: {
                            ...e.purchase_requirements.applies_to.product,
                            list: [...e.purchase_requirements.applies_to.product.list,
                                {
                                    variant_id: item.prod_var_id,
                                    full_title: `${item.title} ${item.variant_title}`.trim(),
                                    image_id: `${GOOGLE_DRIVE_CDN}${
                                        item.images.length > 0 ? item.images[0].id : PRODUCT_NO_IMG
                                    }`
                                }
                            ]
                        }
                    }
                }
            }));
            setSearch('');
            setAllProducts([]);
        }
    };

    return (
        <Grid container spacing={4} sx={{ mb: 4 }}>
            {
                allProducts.map(({ prod_var_id, title, variant_title, images }) =>
                    <Grid item xl={6} lg={12} md={12} sm={12} xs={12} key={prod_var_id}>
                        <Box sx={{
                            display: 'flex', justifyContent: 'flex-start', gap: '15px',
                            borderRadius: '5px', padding: '10px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)'
                        }}>
                            <Box sx={{ my: 'auto' }}>
                                <img width='120' height="120" style={{ objectFit: 'contain' }}
                                    src={
                                        `${GOOGLE_DRIVE_CDN}${
                                            images.length > 0 ? images[0].id : PRODUCT_NO_IMG
                                        }`
                                    }
                                    alt={
                                        images.length > 0 ?
                                            images[0].alt
                                        :
                                            'producto-nefropolis'
                                    } />
                            </Box>
                            <Box sx={{ my: 'auto' }}>
                                <Typography component="div" variant="h6">
                                    { title }
                                </Typography>
                                <Typography variant="body1" color="text.secondary" component="div" gutterBottom>
                                    { variant_title }
                                </Typography>
                                <Button size="small" variant='outlined'
                                    onClick={ selectProduct(prod_var_id) }>
                                    Seleccionar
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                )
            }
        </Grid>
    );
};

export default ProductItem;