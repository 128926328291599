import { orderInit } from '../constants/constants';
import { types } from '../types/types';

const initialState = {
    orders: [], // pedidos cliente
    buyAgainProducts: [], // productos comprados
    activeOrder: orderInit
};

export const userAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.userOrdersLoaded:
            return {
                ...state,
                ...action.payload
            }
        case types.userOrderSelected:
            return {
                ...state,
                activeOrder: action.payload
            }
        case types.userOrderReset:
            return {
                ...state,
                page: ''
            }
        default:
            return state;
    }
};