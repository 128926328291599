import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CHECKOUT_BILLING } from '../../../../constants/constants';

import {
    Grid, Typography, Button, Card,
    CardContent, CardActions, Dialog,
    DialogContent,
} from "@mui/material";

const CheckoutBillingAdress = ({
    billingAddresses, billingValues, setBillingValues,
    setTaxSystem, setCFDI, setBillingColonies
}) => {
    const { rfc } = billingValues;

    // régimen fiscal y CFDI disponible
    const { taxSystemData, CFDIData } = useSelector(state => state.checkout);

    // dirección fue seleccionada
    const [isActive, setIsActive] = useState(false);
    // dirección de facturación seleccionada
    const [activeBilling, setActiveBilling] = useState(CHECKOUT_BILLING);

    // abrir / cerrar modal
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const selectAddress = address_id => () => {
        handleClose();
        const filtered = billingAddresses.find(e => e._id === address_id);
        if (filtered) {
            setBillingColonies([]);
            const { colony, city, state, postalcode, tel, rfc: _rfc, filename } = filtered;
            setActiveBilling((e) => ({
                ...e,
                ...filtered,
                filename,
                billing_tel: tel,
                billing_postalcode: postalcode,
                billing_colony: colony,
                billing_city: city,
                billing_state: state,
                req_invoice: true
            }));
            setIsActive(true);

            if (rfc === _rfc) { // RFC se mantiene
                setBillingValues((e) => ({
                    ...e,
                    ...filtered,
                    billing_tel: tel,
                    billing_postalcode: postalcode,
                    billing_colony: colony,
                    billing_city: city,
                    billing_state: state,
                    req_invoice: true
                }));
            } else {
                setBillingValues((e) => ({
                    ...e,
                    ...filtered,
                    filename,
                    tax_system: '',
                    cfdi: '',
                    billing_tel: tel,
                    billing_postalcode: postalcode,
                    billing_colony: colony,
                    billing_city: city,
                    billing_state: state,
                    req_invoice: true
                }));
            }
        }
    };

    useEffect(() => {
        if (isActive) {
            // false para no sobreescribir los campos si se edita RFC
            setIsActive(false);
            if (rfc.length === 12) { // persona moral
                const filter1 = taxSystemData.filter(e => e.moral === 'Sí');
                const filter2 = CFDIData.filter(e => e.moral === 'Sí');
                setTaxSystem(filter1);
                setCFDI(filter2);
                setBillingValues((e) => ({
                    ...e,
                    ...activeBilling
                }));
            } else if (rfc.length === 13) { // persona física
                const filter1 = taxSystemData.filter(e => e.fisica === 'Sí');
                const filter2 = CFDIData.filter(e => e.fisica === 'Sí');
                setTaxSystem(filter1);
                setCFDI(filter2);
                setBillingValues((e) => ({
                    ...e,
                    ...activeBilling
                }));
            } else {
                setTaxSystem([]);
                setCFDI([]);
            }
        }
    }, [rfc]);

    return (
        <>
            {
                billingAddresses.length > 0 &&
                    <Button onClick={ handleOpen }>Mis direcciones</Button>
            }
            <Dialog
                open={ open }
                onClose={ handleClose }
                maxWidth='xl'
                fullWidth={true}
            >
                <DialogContent>
                    <Typography variant="h5" paragraph>
                        Mis direcciones de facturación
                    </Typography>
                    <Grid container spacing={3}>
                        {
                            billingAddresses.map(({ _id, business_name, rfc, colony, city, state, postalcode, tel }) =>
                                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}
                                    key={_id}>
                                    <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
                                        <CardContent>
                                            <Typography variant='body1' sx={{ fontWeight: '600' }}>{ business_name }</Typography>
                                            <Typography variant='body1'>{ rfc }</Typography>
                                            <Typography variant='body1'>{ colony }</Typography>
                                            <Typography variant='body1'>{`${city}, ${state}, C.P.${postalcode}`}</Typography>
                                            <Typography variant='body1'>Teléfono: { tel }</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small"
                                                onClick={ selectAddress(_id) }>
                                                Seleccionar
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )}
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CheckoutBillingAdress;