import img from '../../assets/resources/Admins/Diego.jpg'
import img_2 from '../../assets/images/Autofacturación_img.jpeg'
import img_3 from '../../assets/images/Compra_en_linea.png'
import img_4 from '../../assets/images/full-sucursal-2.png'
import img_5 from '../../assets/images/Evento_1.jpeg'
import img_6 from '../../assets/images/tarjetita.jpg'
import img_7 from '../../assets/images/plan-de-lealtad.png'

export const MedicalData = [
    {
        id: 1,
        name: 'Diego Alonso',
        lastname: 'Trinidad Ojeda',
        img: img,
        specialty: 'Front-End',
        ic: '000001',
        phone: '3333994553',
        email: 'diego.trinidad@nefropolis.com',
        street: 'Miguel Lerdo de Tejada',
        int_num: '2218-B',
        colony: 'Col. Americana',
        postalcode: '44150',
        city: 'Guadalajara',
        state: 'Jalisco',
        about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        specialties: [
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Transplante Renal'
            },
            {
                value: 'Hipertensión'
            }
        ],
        diseases_treated: [
            {
                value: 'Glomerulonefritis'
            },
            {
                value: 'Nefropatía diabética'
            },
            {
                value: 'Cálculos renales'
            },
            {
                value: 'Síndrome nefrótico'
            },
            {
                value: 'Enfermedad renal terminal'
            },
            {
                value: 'Hipertensión arterial sistémica'
            },
            {
                value: 'Embarazo y daño renal'
            },
            {
                value: 'Suplementación deportiva y daño renal'
            },
            {
                value: 'Trasplante renal y sus complicaciones'
            },
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Diálisis peritoneal y peritonitis asociada a diálisis peritonea'
            },
        ],
        education:
            [
                {
                    value: 'Universidad Veracruzana 2009 - 2016: Médico Cirujano'
                },
                {
                    value: 'Centro Médico Nacional La Raza IMSS / UNAM 2018 - 2020: Medicina Interna'
                },
                {
                    value: 'Centro Médico Nacional Puebla IMSS / UPAEP 2020 - 2023: Nefrología'
                }
            ]
    },
    {
        id: 2,
        name: 'Juan Pablo',
        lastname: 'Sandoval Alejo',
        img: [],
        specialty: 'Back-End',
        ic: '000002',
        phone: '1122334455',
        email: 'juan.sandoval@nefropolis.com',
        street: 'Miguel Lerdo de Tejada',
        int_num: '2218-B',
        colony: 'Col. Americana',
        postalcode: '44150',
        city: 'Guadalajara',
        state: 'Jalisco',
        about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        specialties: [
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Transplante Renal'
            },
            {
                value: 'Hipertensión'
            }
        ],
        diseases_treated: [
            {
                value: 'Glomerulonefritis'
            },
            {
                value: 'Nefropatía diabética'
            },
            {
                value: 'Cálculos renales'
            },
            {
                value: 'Síndrome nefrótico'
            },
            {
                value: 'Enfermedad renal terminal'
            },
            {
                value: 'Hipertensión arterial sistémica'
            },
            {
                value: 'Embarazo y daño renal'
            },
            {
                value: 'Suplementación deportiva y daño renal'
            },
            {
                value: 'Trasplante renal y sus complicaciones'
            },
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Diálisis peritoneal y peritonitis asociada a diálisis peritonea'
            },
        ],
        education:
            [
                {
                    value: 'Universidad Veracruzana 2009 - 2016: Médico Cirujano'
                },
                {
                    value: 'Centro Médico Nacional La Raza IMSS / UNAM 2018 - 2020: Medicina Interna'
                },
                {
                    value: 'Centro Médico Nacional Puebla IMSS / UPAEP 2020 - 2023: Nefrología'
                }
            ]
    },
    {
        id: 3,
        name: 'Juan Carlos',
        lastname: 'Molina',
        img: [],
        specialty: 'Nefrólogo',
        ic: '000003',
        phone: '1122334455',
        email: 'juan.molina@nefropolis.com',
        street: 'Miguel Lerdo de Tejada',
        int_num: '2218-B',
        colony: 'Col. Americana',
        postalcode: '44150',
        city: 'Guadalajara',
        state: 'Sonora',
        about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        specialties: [
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Transplante Renal'
            },
            {
                value: 'Hipertensión'
            }
        ],
        diseases_treated: [
            {
                value: 'Glomerulonefritis'
            },
            {
                value: 'Nefropatía diabética'
            },
            {
                value: 'Cálculos renales'
            },
            {
                value: 'Síndrome nefrótico'
            },
            {
                value: 'Enfermedad renal terminal'
            },
            {
                value: 'Hipertensión arterial sistémica'
            },
            {
                value: 'Embarazo y daño renal'
            },
            {
                value: 'Suplementación deportiva y daño renal'
            },
            {
                value: 'Trasplante renal y sus complicaciones'
            },
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Diálisis peritoneal y peritonitis asociada a diálisis peritonea'
            },
        ],
        education:
            [
                {
                    value: 'Universidad Veracruzana 2009 - 2016: Médico Cirujano'
                },
                {
                    value: 'Centro Médico Nacional La Raza IMSS / UNAM 2018 - 2020: Medicina Interna'
                },
                {
                    value: 'Centro Médico Nacional Puebla IMSS / UPAEP 2020 - 2023: Nefrología'
                }
            ]
    },
    {
        id: 4,
        name: 'Dr. Martín',
        lastname: 'Pilier',
        img: img_2,
        specialty: 'Nefrólogo',
        ic: '000004',
        phone: '1122334455',
        email: 'juan.molina@nefropolis.com',
        street: 'Miguel Lerdo de Tejada',
        int_num: '2218-B',
        colony: 'Col. Americana',
        postalcode: '44150',
        city: 'Guadalajara',
        state: 'Sonora',
        about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        specialties: [
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Transplante Renal'
            },
            {
                value: 'Hipertensión'
            }
        ],
        diseases_treated: [
            {
                value: 'Glomerulonefritis'
            },
            {
                value: 'Nefropatía diabética'
            },
            {
                value: 'Cálculos renales'
            },
            {
                value: 'Síndrome nefrótico'
            },
            {
                value: 'Enfermedad renal terminal'
            },
            {
                value: 'Hipertensión arterial sistémica'
            },
            {
                value: 'Embarazo y daño renal'
            },
            {
                value: 'Suplementación deportiva y daño renal'
            },
            {
                value: 'Trasplante renal y sus complicaciones'
            },
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Diálisis peritoneal y peritonitis asociada a diálisis peritonea'
            },
        ],
        education:
            [
                {
                    value: 'Universidad Veracruzana 2009 - 2016: Médico Cirujano'
                },
                {
                    value: 'Centro Médico Nacional La Raza IMSS / UNAM 2018 - 2020: Medicina Interna'
                },
                {
                    value: 'Centro Médico Nacional Puebla IMSS / UPAEP 2020 - 2023: Nefrología'
                }
            ]
    },
    {
        id: 5,
        name: 'Omar Alejandro',
        lastname: 'Guzman',
        img: img_3,
        specialty: 'Medico internista',
        ic: '000005',
        phone: '1122334455',
        email: 'juan.molina@nefropolis.com',
        street: 'Miguel Lerdo de Tejada',
        int_num: '2218-B',
        colony: 'Col. Americana',
        postalcode: '44150',
        city: 'Guadalajara',
        state: 'Sonora',
        about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        specialties: [
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Transplante Renal'
            },
            {
                value: 'Hipertensión'
            }
        ],
        diseases_treated: [
            {
                value: 'Glomerulonefritis'
            },
            {
                value: 'Nefropatía diabética'
            },
            {
                value: 'Cálculos renales'
            },
            {
                value: 'Síndrome nefrótico'
            },
            {
                value: 'Enfermedad renal terminal'
            },
            {
                value: 'Hipertensión arterial sistémica'
            },
            {
                value: 'Embarazo y daño renal'
            },
            {
                value: 'Suplementación deportiva y daño renal'
            },
            {
                value: 'Trasplante renal y sus complicaciones'
            },
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Diálisis peritoneal y peritonitis asociada a diálisis peritonea'
            },
        ],
        education:
            [
                {
                    value: 'Universidad Veracruzana 2009 - 2016: Médico Cirujano'
                },
                {
                    value: 'Centro Médico Nacional La Raza IMSS / UNAM 2018 - 2020: Medicina Interna'
                },
                {
                    value: 'Centro Médico Nacional Puebla IMSS / UPAEP 2020 - 2023: Nefrología'
                }
            ]
    },
    {
        id: 6,
        name: 'Fulanito Menguanito',
        lastname: 'Rodriguez',
        img: [],
        specialty: 'Nefrólogo',
        ic: '000006',
        phone: '1122334455',
        email: 'juan.molina@nefropolis.com',
        street: 'Miguel Lerdo de Tejada',
        int_num: '2218-B',
        colony: 'Col. Americana',
        postalcode: '44150',
        city: 'Guadalajara',
        state: 'Michoacán',
        about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        specialties: [
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Transplante Renal'
            },
            {
                value: 'Hipertensión'
            }
        ],
        diseases_treated: [
            {
                value: 'Glomerulonefritis'
            },
            {
                value: 'Nefropatía diabética'
            },
            {
                value: 'Cálculos renales'
            },
            {
                value: 'Síndrome nefrótico'
            },
            {
                value: 'Enfermedad renal terminal'
            },
            {
                value: 'Hipertensión arterial sistémica'
            },
            {
                value: 'Embarazo y daño renal'
            },
            {
                value: 'Suplementación deportiva y daño renal'
            },
            {
                value: 'Trasplante renal y sus complicaciones'
            },
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Diálisis peritoneal y peritonitis asociada a diálisis peritonea'
            },
        ],
        education:
            [
                {
                    value: 'Universidad Veracruzana 2009 - 2016: Médico Cirujano'
                },
                {
                    value: 'Centro Médico Nacional La Raza IMSS / UNAM 2018 - 2020: Medicina Interna'
                },
                {
                    value: 'Centro Médico Nacional Puebla IMSS / UPAEP 2020 - 2023: Nefrología'
                }
            ]
    },
    {
        id: 7,
        name: 'Braulio Martinez',
        lastname: 'Cazares',
        img: img_4,
        specialty: 'Neurólogo',
        ic: '000007',
        phone: '1122334455',
        email: 'juan.molina@nefropolis.com',
        street: 'Miguel Lerdo de Tejada',
        int_num: '2218-B',
        colony: 'Col. Americana',
        postalcode: '44150',
        city: 'Guadalajara',
        state: 'Chihuahua',
        about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        specialties: [
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Transplante Renal'
            },
            {
                value: 'Hipertensión'
            }
        ],
        diseases_treated: [
            {
                value: 'Glomerulonefritis'
            },
            {
                value: 'Nefropatía diabética'
            },
            {
                value: 'Cálculos renales'
            },
            {
                value: 'Síndrome nefrótico'
            },
            {
                value: 'Enfermedad renal terminal'
            },
            {
                value: 'Hipertensión arterial sistémica'
            },
            {
                value: 'Embarazo y daño renal'
            },
            {
                value: 'Suplementación deportiva y daño renal'
            },
            {
                value: 'Trasplante renal y sus complicaciones'
            },
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Diálisis peritoneal y peritonitis asociada a diálisis peritonea'
            },
        ],
        education:
            [
                {
                    value: 'Universidad Veracruzana 2009 - 2016: Médico Cirujano'
                },
                {
                    value: 'Centro Médico Nacional La Raza IMSS / UNAM 2018 - 2020: Medicina Interna'
                },
                {
                    value: 'Centro Médico Nacional Puebla IMSS / UPAEP 2020 - 2023: Nefrología'
                }
            ]
    },
    {
        id: 8,
        name: 'Jonathan David',
        lastname: 'Chavez Flores',
        img: [],
        specialty: 'IT Manager',
        ic: '000008',
        phone: '1122334455',
        email: 'juan.molina@nefropolis.com',
        street: 'Miguel Lerdo de Tejada',
        int_num: '2218-B',
        colony: 'Col. Americana',
        postalcode: '44150',
        city: 'Guadalajara',
        state: 'Jalisco',
        about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        specialties: [
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Transplante Renal'
            },
            {
                value: 'Hipertensión'
            }
        ],
        diseases_treated: [
            {
                value: 'Glomerulonefritis'
            },
            {
                value: 'Nefropatía diabética'
            },
            {
                value: 'Cálculos renales'
            },
            {
                value: 'Síndrome nefrótico'
            },
            {
                value: 'Enfermedad renal terminal'
            },
            {
                value: 'Hipertensión arterial sistémica'
            },
            {
                value: 'Embarazo y daño renal'
            },
            {
                value: 'Suplementación deportiva y daño renal'
            },
            {
                value: 'Trasplante renal y sus complicaciones'
            },
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Diálisis peritoneal y peritonitis asociada a diálisis peritonea'
            },
        ],
        education:
            [
                {
                    value: 'Universidad Veracruzana 2009 - 2016: Médico Cirujano'
                },
                {
                    value: 'Centro Médico Nacional La Raza IMSS / UNAM 2018 - 2020: Medicina Interna'
                },
                {
                    value: 'Centro Médico Nacional Puebla IMSS / UPAEP 2020 - 2023: Nefrología'
                }
            ]
    },
    {
        id: 9,
        name: 'Monica Sanchez',
        lastname: 'Galindo',
        img: img_5,
        specialty: 'Nefrólogo',
        ic: '000009',
        phone: '1122334455',
        email: 'juan.molina@nefropolis.com',
        street: 'Miguel Lerdo de Tejada',
        int_num: '2218-B',
        colony: 'Col. Americana',
        postalcode: '44150',
        city: 'Guadalajara',
        state: 'Morelos',
        about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        specialties: [
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Transplante Renal'
            },
            {
                value: 'Hipertensión'
            }
        ],
        diseases_treated: [
            {
                value: 'Glomerulonefritis'
            },
            {
                value: 'Nefropatía diabética'
            },
            {
                value: 'Cálculos renales'
            },
            {
                value: 'Síndrome nefrótico'
            },
            {
                value: 'Enfermedad renal terminal'
            },
            {
                value: 'Hipertensión arterial sistémica'
            },
            {
                value: 'Embarazo y daño renal'
            },
            {
                value: 'Suplementación deportiva y daño renal'
            },
            {
                value: 'Trasplante renal y sus complicaciones'
            },
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Diálisis peritoneal y peritonitis asociada a diálisis peritonea'
            },
        ],
        education:
            [
                {
                    value: 'Universidad Veracruzana 2009 - 2016: Médico Cirujano'
                },
                {
                    value: 'Centro Médico Nacional La Raza IMSS / UNAM 2018 - 2020: Medicina Interna'
                },
                {
                    value: 'Centro Médico Nacional Puebla IMSS / UPAEP 2020 - 2023: Nefrología'
                }
            ]
    },
    {
        id: 10,
        name: 'Analisia',
        lastname: 'Martinez Alvarez',
        img: [],
        specialty: 'Nefrólogo',
        ic: '000010',
        phone: '1122334455',
        email: 'juan.molina@nefropolis.com',
        street: 'Miguel Lerdo de Tejada',
        int_num: '2218-B',
        colony: 'Col. Americana',
        postalcode: '44150',
        city: 'Guadalajara',
        state: 'Sinaloa',
        about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        specialties: [
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Transplante Renal'
            },
            {
                value: 'Hipertensión'
            }
        ],
        diseases_treated: [
            {
                value: 'Glomerulonefritis'
            },
            {
                value: 'Nefropatía diabética'
            },
            {
                value: 'Cálculos renales'
            },
            {
                value: 'Síndrome nefrótico'
            },
            {
                value: 'Enfermedad renal terminal'
            },
            {
                value: 'Hipertensión arterial sistémica'
            },
            {
                value: 'Embarazo y daño renal'
            },
            {
                value: 'Suplementación deportiva y daño renal'
            },
            {
                value: 'Trasplante renal y sus complicaciones'
            },
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Diálisis peritoneal y peritonitis asociada a diálisis peritonea'
            },
        ],
        education:
            [
                {
                    value: 'Universidad Veracruzana 2009 - 2016: Médico Cirujano'
                },
                {
                    value: 'Centro Médico Nacional La Raza IMSS / UNAM 2018 - 2020: Medicina Interna'
                },
                {
                    value: 'Centro Médico Nacional Puebla IMSS / UPAEP 2020 - 2023: Nefrología'
                }
            ]
    },
    {
        id: 11,
        name: 'Jose Luis',
        lastname: 'Trinidad Zarate',
        img: img_6,
        specialty: 'Otorrinolaringólogo',
        ic: '000011',
        phone: '1122334455',
        email: 'juan.molina@nefropolis.com',
        street: 'Miguel Lerdo de Tejada',
        int_num: '2218-B',
        colony: 'Col. Americana',
        postalcode: '44150',
        city: 'Guadalajara',
        state: 'Sonora',
        about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        specialties: [
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Transplante Renal'
            },
            {
                value: 'Hipertensión'
            }
        ],
        diseases_treated: [
            {
                value: 'Glomerulonefritis'
            },
            {
                value: 'Nefropatía diabética'
            },
            {
                value: 'Cálculos renales'
            },
            {
                value: 'Síndrome nefrótico'
            },
            {
                value: 'Enfermedad renal terminal'
            },
            {
                value: 'Hipertensión arterial sistémica'
            },
            {
                value: 'Embarazo y daño renal'
            },
            {
                value: 'Suplementación deportiva y daño renal'
            },
            {
                value: 'Trasplante renal y sus complicaciones'
            },
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Diálisis peritoneal y peritonitis asociada a diálisis peritonea'
            },
        ],
        education:
            [
                {
                    value: 'Universidad Veracruzana 2009 - 2016: Médico Cirujano'
                },
                {
                    value: 'Centro Médico Nacional La Raza IMSS / UNAM 2018 - 2020: Medicina Interna'
                },
                {
                    value: 'Centro Médico Nacional Puebla IMSS / UPAEP 2020 - 2023: Nefrología'
                }
            ]
    },
    {
        id: 12,
        name: 'Diego Doppelganger',
        lastname: 'Prueba',
        img: img_7,
        specialty: 'Medico internista',
        ic: '000012',
        phone: '1122334455',
        email: 'juan.molina@nefropolis.com',
        street: 'Miguel Lerdo de Tejada',
        int_num: '2218-B',
        colony: 'Col. Americana',
        postalcode: '44150',
        city: 'Guadalajara',
        state: 'Ciudad de México',
        about: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
        specialties: [
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Transplante Renal'
            },
            {
                value: 'Hipertensión'
            }
        ],
        diseases_treated: [
            {
                value: 'Glomerulonefritis'
            },
            {
                value: 'Nefropatía diabética'
            },
            {
                value: 'Cálculos renales'
            },
            {
                value: 'Síndrome nefrótico'
            },
            {
                value: 'Enfermedad renal terminal'
            },
            {
                value: 'Hipertensión arterial sistémica'
            },
            {
                value: 'Embarazo y daño renal'
            },
            {
                value: 'Suplementación deportiva y daño renal'
            },
            {
                value: 'Trasplante renal y sus complicaciones'
            },
            {
                value: 'Hemodiálisis'
            },
            {
                value: 'Diálisis peritoneal y peritonitis asociada a diálisis peritonea'
            },
        ],
        education:
            [
                {
                    value: 'Universidad Veracruzana 2009 - 2016: Médico Cirujano'
                },
                {
                    value: 'Centro Médico Nacional La Raza IMSS / UNAM 2018 - 2020: Medicina Interna'
                },
                {
                    value: 'Centro Médico Nacional Puebla IMSS / UPAEP 2020 - 2023: Nefrología'
                }
            ]
    }
]