import React, { useState, useEffect } from 'react'

//Redux
import { useSelector } from 'react-redux';

//MUI
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, Grid, TextField, InputAdornment, FormHelperText,
    MenuItem
} from '@mui/material'
import DomainIcon from '@mui/icons-material/Domain';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

//Utils
import { regex_num } from '../../../../constants/regex';
import { CHECKOUT_DISABLED_INPUT } from '../../../../constants/constants';

// import { basicMsg } from '../../../../actions/swal_msg';
import { getPostalcodeData } from '../../../../actions/checkout';
import { updBillingAddress } from '../../../../actions/user-account/billing/ua_billing';

const init = {
    _id: '',
    business_name: '',
    rfc: '',
    // cif: '',
    tax_system: '',
    cfdi: '',
    billing_tel: '',
    address: '',
    billing_postalcode: '',
    billing_colony: '',
    billing_city: '',
    billing_state: ''
};

const initErr = {
    business_name_err: '',
    rfc_err: '',
    // cif_err: '',
    tax_system_err: '',
    cfdi_err: '',
    billing_tel_err: '',
    address_err: '',
    billing_postalcode_err: '',
    billing_colony_err: '',
    billing_city_err: '',
    billing_state_err: ''
};

const EditBilling = ({ modal, setModal, values, setAddresses }) => {
    // régimen fiscal y CFDI disponible
    const { taxSystemData, CFDIData } = useSelector(state => state.checkout);

    const [billingValues, setBillingValues] = useState(init);
    const [inputErr, setInputErr] = useState(initErr);

    const {
        _id, business_name, rfc, cif, tax_system, cfdi,
        billing_tel, address, billing_postalcode,
        billing_colony, billing_city, billing_state
    } = billingValues;

    const {
        business_name_err, rfc_err,
        tax_system_err, cfdi_err, billing_tel_err,
        address_err, billing_postalcode_err,
        billing_colony_err, billing_city_err,
        billing_state_err
    } = inputErr;

    // información recuperada con el código postal
    const [billingColonies, setBillingColonies] = useState([]);

    // régimen fiscal filtrado
    const [taxSystem, setTaxSystem] = useState([]);
    // CFDI filtrado
    const [CFDI, setCFDI] = useState([]);

    // deshabilitación de campos
    const [disabledFields, setDisabledFields] = useState(CHECKOUT_DISABLED_INPUT);
    const { billCityInput, billStateInput } = disabledFields;

    useEffect(() => {
        const { tel, postalcode, colony, city, state, ...myValues } = values;
        setBillingValues({
            ...myValues,
            billing_tel: tel,
            billing_postalcode: postalcode,
            billing_colony: colony,
            billing_city: city,
            billing_state: state,
        });
    }, [values]);

    useEffect(() => {
        if (rfc.length === 12) { // persona moral
            const filter1 = taxSystemData.filter(e => e.moral === 'Sí');
            const filter2 = CFDIData.filter(e => e.moral === 'Sí');
            const txFound = filter1.find(e => e.codigo === tax_system);
            const cfdiFound = filter2.find(e => e.codigo === cfdi);
            // limpiar campos si no aplica régimen fiscal/cfdi para el RFC
            if (!txFound && !cfdiFound)
                setBillingValues((e) => ({ ...e, tax_system: '', cfdi: '' }));
            else if (txFound && !cfdiFound)
                setBillingValues((e) => ({ ...e, cfdi: '' }));
            else if (!txFound && cfdiFound)
                setBillingValues((e) => ({ ...e, tax_system: '' }));

            setTaxSystem(filter1);
            setCFDI(filter2);
        } else if (rfc.length === 13) { // persona física
            const filter1 = taxSystemData.filter(e => e.fisica === 'Sí');
            const filter2 = CFDIData.filter(e => e.fisica === 'Sí');
            const txFound = filter1.find(e => e.codigo === tax_system);
            const cfdiFound = filter2.find(e => e.codigo === cfdi);
            // limpiar campos si no aplica régimen fiscal/cfdi para el RFC
            if (!txFound && !cfdiFound)
                setBillingValues((e) => ({ ...e, tax_system: '', cfdi: '' }));
            else if (txFound && !cfdiFound)
                setBillingValues((e) => ({ ...e, cfdi: '' }));
            else if (!txFound && cfdiFound)
                setBillingValues((e) => ({ ...e, tax_system: '' }));

            setTaxSystem(filter1);
            setCFDI(filter2);
        } else {
            setTaxSystem([]);
            setCFDI([]);
        }
    }, [rfc]);

    // cambios en datos de facturación
    const billingInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        // propiedades con únicamente números
        if ((['billing_tel', 'billing_postalcode'].includes(name))) {
            if (value === '' || regex_num.test(value))
                setBillingValues({ ...billingValues, [name]: value });
        }
        else setBillingValues({ ...billingValues, [name]: value });
        switch (name) {
            case 'business_name':
                setInputErr({
                    ...inputErr,
                    business_name_err: (value.length < 2 ||
                        value.length > 200) ? true : false
                });
                break;
            case 'rfc':
                setInputErr({
                    ...inputErr,
                    rfc_err: (value.length < 12 ||
                        value.length > 13) ? true : false
                });
                break;
            case 'cif':
                setInputErr({
                    ...inputErr,
                    cif_err: (cif !== '' && cif instanceof File) ? true : false
                });
                break;
            case 'tax_system':
                setInputErr({
                    ...inputErr,
                    tax_system_err: (value.length < 3 || value.length > 60) ? true : false,
                });
                break;
            case 'cfdi':
                setInputErr({
                    ...inputErr,
                    cfdi_err: (value.length < 3 ||
                        value.length > 60) ? true : false
                });
                break;
            case 'billing_tel':
                setInputErr({
                    ...inputErr,
                    billing_tel_err: (value.length < 4 ||
                        value.length > 15) ? true : false
                });
                break;
            case 'address':
                setInputErr({
                    ...inputErr,
                    address_err: (value.length < 3 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'billing_postalcode':
                setInputErr({
                    ...inputErr,
                    billing_postalcode_err: (value.length !== 5) ? true : false
                });
                break;
            case 'billing_colony':
                setInputErr({
                    ...inputErr,
                    billing_colony_err: (value.length < 3 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'billing_city':
                setInputErr({
                    ...inputErr,
                    billing_city_err: (value.length < 3 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'billing_state':
                setInputErr({
                    ...inputErr,
                    billing_state_err: (value.length < 3 ||
                        value.length > 100) ? true : false
                });
                break;
            default:
                break;
        }
    };

    // campo pierde foco
    const handleInputBlur = async ({ target }) => {
        // busca información del CP ingresado
        if (target.name === 'billing_postalcode') {
            const resp = await getPostalcodeData(billing_postalcode);
            if (resp.status) {
                const { resp: { municipio, estado, asentamiento } } = resp;
                setBillingValues({
                    ...billingValues,
                    billing_colony: '',
                    billing_city: municipio,
                    billing_state: estado
                });
                setBillingColonies(asentamiento);
                setDisabledFields({
                    ...disabledFields,
                    billCityInput: true, billStateInput: true
                });
            } else {
                setBillingColonies([]);
                setDisabledFields({
                    ...disabledFields,
                    billCityInput: false, billStateInput: false
                });
            }
        }
    };

    // cargar CIF
    // const loadCIF = ({ target }) => {
    //     const files = target.files;
    //     if (files.length > 0) {
    //         if (['application/pdf', 'application/msword',
    //             'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0].type))
    //             setBillingValues({
    //                 ...billingValues,
    //                 cif: files[0]
    //             });
    //         else basicMsg('Formato no soportado');
    //     }
    // };

    const formValidator = () => {
        const bname_len = business_name.trim().length;
        const rfc_len = rfc.trim().length;
        const ts_len = tax_system.trim().length;
        const cfdi_len = cfdi.trim().length;
        const btel_len = billing_tel.trim().length;
        const address_len = address.trim().length;
        const bpc_len = billing_postalcode.trim().length;
        const bcolony_len = billing_colony.trim().length;
        const bcity_len = billing_city.trim().length;
        const bstate_len = billing_state.trim().length;
        if (bname_len > 1 && bname_len <= 200) {
            if ((rfc_len === 12 || rfc_len === 13)) { // rfc_regex.test(rfc)
                // if (cif !== '' && cif instanceof File) {
                    if (ts_len > 2 && ts_len <= 60) {
                        if (cfdi_len > 2 && cfdi_len <= 60) {
                            if (btel_len > 3 && btel_len <= 15) {
                                if (address_len > 2 && address_len <= 60) {
                                    if (bpc_len === 5) {
                                        if (bcolony_len > 2 && bcolony_len <= 100) {
                                            if (bcity_len > 2 && bcity_len <= 100) {
                                                if (bstate_len > 2 && bstate_len <= 100)
                                                    return true;
                                                else {
                                                    setInputErr({
                                                        ...inputErr,
                                                        billing_state_err: true
                                                    });
                                                }
                                            } else {
                                                setInputErr({
                                                    ...inputErr,
                                                    billing_city_err: true
                                                });
                                            }
                                        } else {
                                            setInputErr({
                                                ...inputErr,
                                                billing_colony_err: true
                                            });
                                        }
                                    } else {
                                        setInputErr({
                                            ...inputErr,
                                            billing_postalcode_err: true
                                        });
                                    }
                                } else {
                                    setInputErr({
                                        ...inputErr,
                                        address_err: true
                                    });
                                }
                            } else {
                                setInputErr({
                                    ...inputErr,
                                    billing_tel_err: true
                                });
                            }
                        } else {
                            setInputErr({
                                ...inputErr,
                                cfdi_err: true
                            });
                        }
                    } else {
                        setInputErr({
                            ...inputErr,
                            tax_system_err: true
                        });
                    }
                // } else {
                //     setInputErr({
                //         ...inputErr,
                //         cif_err: true
                //     });
                // }
            } else {
                setInputErr({
                    ...inputErr,
                    rfc_err: true
                });
            }
        } else {
            setInputErr({
                ...inputErr,
                business_name_err: true
            });
        }
    };

    // actualizar dirección
    const submit = async(e) => {
        e.preventDefault();
        if (formValidator()) {
            const resp = await updBillingAddress(billingValues);
            if (resp.status) {
                setModal(false);
                setAddresses((e) => e.map(
                    e => (e._id === _id) ? ({ ...e, ...resp }) : e
                ));
            }
        }
    };

    return (
        <Dialog
            open={modal}
            onClose={() => setModal(false)}
            fullWidth={true}
            maxWidth='lg'
        >
            <DialogTitle>
                Editar dirección de facturación
            </DialogTitle>
            <DialogContent>
                {/* Form */}
                <Grid container spacing={3}>
                    {/* Razón social */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id=""
                                label="Razón social"
                                inputProps={{ maxLength: 200 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <DomainIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                name="business_name"
                                value={business_name}
                                error={business_name_err}
                                helperText={business_name_err ? "Ingrese la razón social" : ""}
                                onChange={billingInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* RFC */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id=""
                                label="RFC"
                                inputProps={{ maxLength: 13 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fa-solid fa-file"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                name="rfc"
                                value={rfc}
                                error={rfc_err}
                                helperText={rfc_err ? "Ingrese un RFC correcto" : ""}
                                onChange={billingInputChange}
                                onBlur={handleInputBlur}
                            />
                        </FormControl>
                    </Grid>

                    {/* CIF */}
                    {/* <Grid item xs={12}>
                        <Button variant="contained" component="label">
                            Subir CIF
                            <input hidden type="file"
                                accept="application/pdf, application/msword,
                                application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={loadCIF} />
                        </Button>
                        {cif !== '' &&
                            <Typography variant="overline" display="block" gutterBottom>
                                {cif.name}
                            </Typography>
                        }
                        {
                            cif === ''
                                ?
                                <>
                                    {
                                        cif_err &&
                                        <FormHelperText
                                            error={cif_err}>
                                            Cargue el documento de su CIF
                                        </FormHelperText>
                                    }
                                </>
                                :
                                null
                        }
                    </Grid> */}

                    {/* Regimen social */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>

                            <TextField
                                label='Régimen social'
                                name="tax_system"
                                error={tax_system_err}
                                value={tax_system}
                                onChange={billingInputChange}
                                select
                                variant="standard"
                            >
                                {
                                    taxSystem.map(({ codigo, descripcion }) =>
                                        <MenuItem key={codigo}
                                            value={codigo}>
                                            {`${codigo} - ${descripcion}`}
                                        </MenuItem>)
                                }
                            </TextField>
                            {
                                tax_system_err &&
                                <FormHelperText sx={{ mx: 0 }}
                                    error={tax_system_err}>
                                    Seleccione régimen social
                                </FormHelperText>
                            }
                        </FormControl>
                    </Grid>

                    {/* CFDI */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                name="cfdi"
                                value={cfdi}
                                error={cfdi_err}
                                label="CFDI"
                                onChange={billingInputChange}
                                variant="standard"
                                select
                            >
                                {
                                    CFDI.map(({ codigo, descripcion }) =>
                                        <MenuItem key={codigo}
                                            value={codigo}>
                                            {`${codigo} - ${descripcion}`}
                                        </MenuItem>)
                                }
                            </TextField>
                            {
                                cfdi_err &&
                                <FormHelperText sx={{ mx: 0 }}
                                    error={cfdi_err}>
                                    Seleccione régimen social
                                </FormHelperText>
                            }
                        </FormControl>
                    </Grid>

                    {/* Número de telefono */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id=""
                                label="Número telefónico"
                                inputProps={{ maxLength: 15 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fa-solid fa-phone"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                name="billing_tel"
                                value={billing_tel}
                                error={billing_tel_err}
                                helperText={billing_tel_err ? "Ingrese teléfono para facturación" : ""}
                                onChange={billingInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* Dirección */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id=""
                                label="Dirección"
                                inputProps={{ maxLength: 100 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="fa-solid fa-house"></i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                name="address"
                                value={address}
                                error={address_err}
                                helperText={address_err ? "Ingrese dirección para facturación" : ""}
                                onChange={billingInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* Código postal */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id=""
                                label="Código postal"
                                inputProps={{ maxLength: 5 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MarkunreadMailboxIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                name="billing_postalcode"
                                value={billing_postalcode}
                                error={billing_postalcode_err}
                                helperText={billing_postalcode_err ? "Ingrese código postal para facturación" : ""}
                                onChange={billingInputChange}
                                onBlur={handleInputBlur}
                            />
                        </FormControl>
                    </Grid>

                    {/* Colonia */}
                    {
                        billingColonies.length === 0 ?
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Colonia"
                                        inputProps={{ maxLength: 100 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocationCityIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        name="billing_colony"
                                        value={billing_colony}
                                        error={billing_colony_err}
                                        helperText={billing_colony_err ? "Ingrese colonia para facturación" : ""}
                                        onChange={billingInputChange}
                                    />
                                </FormControl>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        variant="standard"
                                        name="billing_colony"
                                        value={billing_colony}
                                        error={billing_colony_err}
                                        label="Colonia"
                                        select
                                        onChange={billingInputChange}
                                    >
                                        {
                                            billingColonies.map(({ nombre }) =>
                                                <MenuItem key={nombre}
                                                    value={nombre}>{nombre}
                                                </MenuItem>
                                            )
                                        }
                                    </TextField>
                                    {
                                        billing_colony_err &&
                                        <FormHelperText sx={{ mx: 0 }}
                                            error={billing_colony_err}>
                                            Ingrese colonia para facturación
                                        </FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                    }

                    {/* Municipio */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id=""
                                label="Ciudad"
                                inputProps={{ maxLength: 100 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <DomainIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                disabled={billCityInput}
                                name="billing_city"
                                value={billing_city}
                                error={billing_city_err}
                                helperText={billing_city_err ? "Ingrese ciudad para facturación" : ""}
                                onChange={billingInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* Estado */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id=""
                                label="Estado"
                                inputProps={{ maxLength: 100 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <ApartmentIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                disabled={billStateInput}
                                name="billing_state"
                                value={billing_state}
                                error={billing_state_err}
                                helperText={billing_state_err ? "Ingrese estado para facturación" : ""}
                                onChange={billingInputChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setModal(false)}>Cerrar</Button>
                <Button onClick={submit} autoFocus>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditBilling;