import { types } from '../types/types';

// form1 (contacto)
// form2 (postulación)
// form3 (distribuidor)
// loading (backdrop)
const initialState = {
    formMain: false, // registro de formularios
    form1: false,
    form1Loading: false,
    form2: false,
    form2Loading: false,
    form3: false,
    form3Loading: false,
    formList: [],
};

export const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.form1Submitted:
            return {
                ...state,
                form1: action.payload,
                form1Loading: false
            }
        case types.form2Submitted:
            return {
                ...state,
                form2: action.payload,
                form2Loading: false
            }
        case types.form3Submitted:
            return {
                ...state,
                form3: action.payload,
                form3Loading: false
            }
        case types.form1Backdrop:
            return {
                ...state,
                form1Loading: action.payload
            }
        case types.form2Backdrop:
            return {
                ...state,
                form2Loading: action.payload
            }
        case types.form3Backdrop:
            return {
                ...state,
                form3Loading: action.payload
            }
        case types.formListLoaded:
            return {
                ...state,
                formMain: false,
                formList: [...action.payload],
            }
        case types.formReg:
            return {
                ...state,
                formList: [...state.formList, action.payload],
                formMain: false
            }
        case types.formReset:
            return {
                ...state,
                formMain: false
            }
        default:
            return state;
    }
};