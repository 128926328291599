import { msg, basicMsg } from "../swal_msg";
import { protectedReq } from "../../helpers/requestHelper";

// lista de productos de Odoo
export const odooProductList = async() => {
    try {
        const resp = await protectedReq('odoo/list');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'server-err')
                basicMsg('Ocurrió un incidente al cargar lista');
        }
        return body;
    } catch (err) {
        msg('error', 'Lista',
        'No se pudo cargar la lista de productos de Odoo');
        return { status: false };
    }
};