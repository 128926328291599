import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import './shopcategory.css';

import ShopFilter from './ShopFilter';
import ShopProducts from './ShopProducts';
import SubcategorySidebar from './SubcategorySidebar';
import { ALL_CATEGORIES, ALL_SUBCATEGORIES, CATEGORY_DEFAULT } from '../../../constants/shop/shop_constants';

import {
    Container, Breadcrumbs,  Grid, Typography, useMediaQuery
} from '@mui/material';
import ShopMoreProducts from './ShopMoreProducts';

const ShopCategory = () => {
    const { category_url } = useParams();
    const matches = useMediaQuery('(max-width: 950px)');
    
    // categorías y productos de tienda
    const { categories, products } = useSelector(state => state.shop);

    // filtro de productos según categoría/subcategoría
    const [filteredProducts, setFilteredProducts] = useState([]);

    // mostrar más productos
    const [moreProducts, setMoreProducts] = useState({
        text: 'Productos en tienda',
        products: []
    });

    // categoría seleccionada
    const [selectedCategory, setSelectedCategory] = useState(CATEGORY_DEFAULT);
    const { category, subcategory } = selectedCategory;
    // subcategoría seleccionada
    const [selectedSubcategory, setSelectedSubcategory] = useState(ALL_SUBCATEGORIES);
    
    // la ruta (categoría) ha cambiado
    useEffect(() => {
        const found = categories.find(e => e.url === category_url);
        if (found)
            setSelectedCategory(found);
    }, [category_url, categories]);

    // productos actualizados / subcategoría ha cambiado
    // (filtrar productos)
    useEffect(() => {
        if (category === ALL_CATEGORIES)
            setFilteredProducts(products);
        else {
            const filtered = products.filter(e =>
                e.category.some(item => item.toUpperCase() === category.toUpperCase()) &&
                (
                    selectedSubcategory === ALL_SUBCATEGORIES ? true :
                    e.subcategory.some(
                        item => item.toUpperCase() === selectedSubcategory.toUpperCase()
                    )
                )
            );
            const sorted = filtered.sort(
                (a, b) => {
                    const catA = a.position.categories.find(u => u.url === category_url);
                    const catB = b.position.categories.find(u => u.url === category_url);
                    if (!catA || !catB) return 1;
                    return catA.position < catB.position ? -1 : 1
                }
            );
            setFilteredProducts(sorted);
        }
    }, [products, category, selectedSubcategory]);

    useEffect(() => {
        if (category !== '') {
            setMoreProducts({
                ...moreProducts,
                text: 'Productos en otras categorías',
                products: products.filter(e =>
                    !e.category.find(item => item.toUpperCase() === category.toUpperCase())
                ).sort((a, b) => a.position.more_products - b.position.more_products)
            });
        } else
            setMoreProducts({
                ...moreProducts,
                text: 'Productos en tienda',
                products: products.sort((a, b) => a.position.more_products - b.position.more_products)
            });
    }, [filteredProducts]);

    return (
        <>
            {/* Productos */}
            <Container maxWidth='xxl' sx={{ mt: matches ? 5 : 18 }} className="last-view">
                <Typography variant='h5' sx={{ fontWeight: 'light' }} gutterBottom>
                    { category }
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit"
                        to="/tienda">Tienda
                    </Link>
                    <Typography color="text.primary">Categorías</Typography>
                </Breadcrumbs>

                {/* Filtros ordenar por... */}
                <ShopFilter
                    filteredProducts={ filteredProducts }
                    setFilteredProducts={ setFilteredProducts }
                />
                {/* fin filtros ordenar por... */}

                <Grid container
                    spacing={3.5}
                    justifyContent="center">
                    {/* Subcategorías - sidebar */}
                    {
                        subcategory.length > 0 &&
                            <SubcategorySidebar
                                subcategory={ subcategory }
                                setSelectedSubcategory={ setSelectedSubcategory }
                            />
                    }

                    {/* Productos */}
                    <ShopProducts
                        filteredProducts={ filteredProducts }
                        category={ category }
                    />

                    {/* Mostrar más productos de tienda */}
                    {
                        moreProducts.products.length > 0 &&
                            <ShopMoreProducts
                                moreProducts={ moreProducts }
                            />
                    }
                </Grid>
            </Container>
            {/* Fin Productos */}
        </>
    );
};

export default ShopCategory;