import React, { useEffect, useState } from 'react';

//CSS
import './BannersShop.css';
import { bannerList } from '../../../actions/admin/banner';

import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

import BannerShopDialog from './BannerShopDialog';

//MUI
import {
    Box, Card, CardContent, Container, Grid,
    IconButton, Menu, MenuItem, Typography,
    Stack, TextField, ListItemIcon, ListItemText,
    Alert
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const init = {
    type: 'desktop', // vista de banner
    images: [], // nuevas imágenes
    desktop: {
        _id: '',
        images: []
    },
    tablet: {
        _id: '',
        images: []
    },
    mobile: {
        _id: '',
        images: []
    }
};

const BannersShop = () => {
    const [anchorEl, setAnchorEl] = useState({
        anchorEl1: null,
        anchorEl3: null,
        anchorEl2: null
    });
    const { anchorEl1, anchorEl2, anchorEl3 } = anchorEl;
    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);

    // imágenes de banner
    const [banner, setBanner] = useState(init);
    const { desktop, tablet, mobile } = banner;

    // imágenes removidas
    const [deletedImages, setDeletedImages] = useState({
        desktop: [],
        tablet: [],
        mobile: []
    });

    useEffect(() => {
        getBannerList();
    }, []);

    const getBannerList = async() => {
        const req = await bannerList();
        if (req.status)
            setBanner({ ...banner, ...req.resp });
    };

    const handleClick = (_type, anchor) => (event) => {
        setBanner({ ...banner, type: _type });
        setAnchorEl(({ ...anchorEl, [anchor]: event.currentTarget }));
    };
    const handleClose = anchor => () => {
        setAnchorEl((e) => ({ ...e, [anchor]: null }));
    };

    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpenDialog = anchor => () => {
        setOpenDialog(true);
        setAnchorEl((e) => ({ ...e, [anchor]: null }));
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleInputChange = () => {

    };

    return (
        <>
            <Container maxWidth='xl' sx={{ mb: 5, mt: '90px' }}>
                {/* Titulo y botón impulso de producto */}
                <Typography variant='h4' sx={{ fontWeight: 'light' }} gutterBottom>Banners tienda en linea</Typography>

                {/* Sección para visualizar y editar sección de banners */}
                <Grid container spacing={4} sx={{ mt: '10px' }}>
                    <Grid item xl={4}>
                        <Card
                            sx={{
                                borderRadius: '8px',
                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                            }}
                        >
                            <Menu
                                anchorEl={ anchorEl1 }
                                open={ open1 }
                                onClose={ handleClose('anchorEl1') }
                            >
                                <MenuItem onClick={ handleClickOpenDialog('anchorEl1') }>
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                    <ListItemText>Editar</ListItemText>
                                </MenuItem>
                                <MenuItem>
                                    <ListItemIcon>
                                        <SaveIcon color='primary' />
                                    </ListItemIcon>
                                    <ListItemText>Guardar</ListItemText>
                                </MenuItem>
                            </Menu>

                            <CardContent>
                                <Box display='flex' justifyContent='space-between' flexWrap='wrap' gap='15px'>
                                    <Typography variant='body1' sx={{ my: 'auto' }}>
                                        Banners para desktops
                                    </Typography>
                                    
                                    <IconButton sx={{ my: 'auto' }}
                                        onClick={ handleClick('desktop', 'anchorEl1') } >
                                        <MoreVertIcon />
                                    </IconButton>
                                </Box>

                                {
                                    desktop.images.length > 0 ?
                                        <div className='impulse_grid_container'>
                                            {
                                                desktop.images.map(({ img, url }, i) => (
                                                    <Stack key={i}>
                                                        <div className='impulse_grid_item impulse_image'>
                                                            <div className="impulse_img">
                                                                <img src={`${GOOGLE_DRIVE_CDN}${img}`}
                                                                    alt="banner-nefropolis" />
                                                            </div>
                                                        </div>
                                                        {/* <Typography variant="caption" display="block" gutterBottom>
                                                            { `URL: ${url}` }
                                                        </Typography> */}
                                                        <TextField size='small'
                                                            variant="standard"
                                                            label='URL'
                                                            name="URL"
                                                            value={ url }
                                                            onChange={ handleInputChange }
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                        </div>
                                    :
                                        <Alert variant="outlined" severity="info">
                                            Sin banners disponibles
                                        </Alert>
                                }
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xl={4}>
                        <Card
                            sx={{
                                borderRadius: '8px',
                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                            }}
                        >
                            <Menu
                                anchorEl={ anchorEl2 }
                                open={ open2 }
                                onClose={ handleClose('anchorEl2') }
                            >
                                <MenuItem onClick={ handleClickOpenDialog('anchorEl2') }>
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                    <ListItemText>Editar</ListItemText>
                                </MenuItem>
                                <MenuItem>
                                    <ListItemIcon>
                                        <SaveIcon color='primary' />
                                    </ListItemIcon>
                                    <ListItemText>Guardar</ListItemText>
                                </MenuItem>
                            </Menu>

                            <CardContent>
                                <Box display='flex' justifyContent='space-between' flexWrap='wrap' gap='15px'>
                                    <Typography variant='body1' sx={{ my: 'auto' }}>
                                        Banners para tablets
                                    </Typography>
                                    <IconButton onClick={ handleClick('tablet', 'anchorEl2') } sx={{ my: 'auto' }}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </Box>

                                {
                                    tablet.images.length > 0 ?
                                        <div className='impulse_grid_container'>
                                            {
                                                tablet.images.map(({ img, url }, i) => (
                                                    <Stack key={i}>
                                                        <div className='impulse_grid_item impulse_image'>
                                                            <div className="impulse_img">
                                                                <img src={`${GOOGLE_DRIVE_CDN}${img}`}
                                                                    alt="banner-nefropolis" />
                                                            </div>
                                                        </div>
                                                        <TextField size='small'
                                                            variant="standard"
                                                            label='URL'
                                                            name="URL"
                                                            value={ url }
                                                            onChange={ handleInputChange }
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                        </div>
                                    :
                                        <Alert variant="outlined" severity="info">
                                            Sin banners disponibles
                                        </Alert>
                                }
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xl={4}>
                        <Card
                            sx={{
                                borderRadius: '8px',
                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                            }}
                        >
                            <Menu
                                anchorEl={ anchorEl3 }
                                open={ open3 }
                                onClose={ handleClose('anchorEl3') }
                            >
                                <MenuItem onClick={ handleClickOpenDialog('anchorEl3') }>
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                    <ListItemText>Editar</ListItemText>
                                </MenuItem>
                                <MenuItem>
                                    <ListItemIcon>
                                        <SaveIcon color='primary' />
                                    </ListItemIcon>
                                    <ListItemText>Guardar</ListItemText>
                                </MenuItem>
                            </Menu>
                            <CardContent>
                                <Box display='flex' justifyContent='space-between' flexWrap='wrap' gap='15px'>
                                    <Typography variant='body1' sx={{ my: 'auto' }}>
                                        Banners para móviles
                                    </Typography>
                                    <IconButton onClick={ handleClick('mobile', 'anchorEl3') } sx={{ my: 'auto' }}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </Box>
                                {
                                    mobile.images.length > 0 ?
                                        <div className='impulse_grid_container'>
                                            {
                                                mobile.images.map(({ img, url }, i) => (
                                                    <Stack key={i}>
                                                        <div className='impulse_grid_item impulse_image'>
                                                            <div className="impulse_img">
                                                                <img src={`${GOOGLE_DRIVE_CDN}${img}`}
                                                                    alt="banner-nefropolis" />
                                                            </div>
                                                        </div>
                                                        <TextField size='small'
                                                            variant="standard"
                                                            label='URL'
                                                            name="URL"
                                                            value={ url }
                                                            onChange={ handleInputChange }
                                                        />
                                                    </Stack>
                                                ))
                                            }
                                        </div>
                                    :
                                        <Alert variant="outlined" severity="info">
                                            Sin banners disponibles
                                        </Alert>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/* Fin sección para visualizar y editar sección de banners  */}
            </Container>

            <BannerShopDialog
                handleCloseDialog={ handleCloseDialog }
                openDialog={ openDialog }
                banner={ banner }
                setBanner={ setBanner }
                deletedImages={ deletedImages }
                setDeletedImages={ setDeletedImages }
            />
        </>
    );
};

export default BannersShop;