import React from 'react';

import { Typography, List, ListItem, ListItemText } from '@mui/material';

const InfoPedido = ({ selectedOrder }) => {
    const {
        summary: { discount, subtotal, total },
        coupon,
        shipping: { partner, cost },
        payment: { status },
        fileId
    } = selectedOrder;
    return (
        <>
            {/* Estatus del pago */}
            {
                status === 'pending' &&
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#81D4FA' }} component="div">
                        En espera de pago <i className="fa-solid fa-clock"></i>
                    </Typography>
            }
            {
                status === 'approved' &&
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#229954' }} component="div">
                        Pago confirmado <i className="fa-solid fa-check"></i>
                    </Typography>
            }
            {/* Información del pedido */}
            <List disablePadding>
                {/* subtotal */}
                <ListItem sx={{ px: 0 }}>
                    <ListItemText primary="Subtotal:" secondary="Subtotal carrito" />
                    <Typography sx={{ color: '#229954' }} variant="body2">${ subtotal }</Typography>
                </ListItem>
                {/* Envío */}
                <ListItem sx={{ px: 0 }}>
                    <ListItemText primary="Envío:" secondary={`Paquetería: ${ partner }`} />
                    <Typography sx={{ color: '#229954' }} variant="body2">
                        { cost === 0 ? 'Gratis' : `$${cost}` }
                    </Typography>
                </ListItem>
                {/* Cupón */}
                {
                    coupon.name && (
                        <ListItem sx={{ px: 0 }}>
                            <ListItemText primary={ coupon.name } secondary={
                                coupon._class === 'order_discount' ?
                                    `Cupón ${
                                        coupon.discount.type === 'fixed' ?
                                            `-$${coupon.discount._value}`
                                        :
                                            `${coupon.discount._value}%`
                                    }`
                                :
                                    `Cupón Envío Gratis`
                            } />
                            <Typography sx={{ color: '#C0392B' }} variant="body2">${ discount }</Typography>
                        </ListItem>
                    )
                }
                {/* Total */}
                <ListItem sx={{ px: 0 }}>
                    <ListItemText primary="Total" />
                    <Typography sx={{ color: '#3498DB' }} variant="body2">${ total }</Typography>
                </ListItem>
                {
                    fileId &&
                        <Typography variant="body1" component="div">
                            <b style={{ fontWeight: '600' }}>{ `Archivo: ` }</b>
                                <a target="_blank" rel="noreferrer"
                                    href={`https://drive.google.com/file/d/${fileId}/view`}>
                                    Ver
                                </a>
                        </Typography>
                }
            </List>
        </>
    );
};

export default InfoPedido;