import React, { useEffect, useState } from 'react';

//Components
import { getLostOrders } from '../../../actions/orders';
// import CarritoAbandonadoData from './CarritoAbandonadoData';
// import CarritoAbandonadoTable from './CarritoAbandonadoTable';

//MUI
import { Container} from '@mui/material';
import PedidoAbandonado from './PedidoAbandonado';

const dataInit = [
    { name: 'Sin pedidos perdidos', email: '', total: '$0', status: '', order_date: '' }
];

const CarritoAbandonado = () => {
    const [data, setData] = useState(dataInit);
    
    useEffect(() => {
        myOrders();
    }, []);

    // pedidos perdidos y carrito abandonado
    const myOrders = async()  => {
        const req = await getLostOrders();
        if (req.status) {
            const { dropped_orders } = req.resp; // abandoned_sp
            if (dropped_orders.length > 1) setData(dropped_orders);
        }
    };

    return (
        <>
            {/* <CarritoAbandonadoData /> */}

            {/* Tabla con pedidos abandonados */}
            <Container maxWidth="xxl" sx={{ mb: 5, mt: '90px' }}>
                <PedidoAbandonado
                    data={ data }
                    setData={ setData } />
            </Container>
            {/* <PedidoAbandonado data={data} setData={setData} /> */}
        </>
    );
};

export default CarritoAbandonado;