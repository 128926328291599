import React, { useState } from "react";
import { useEffect } from "react";

import { getTopProducts, newClients, topClients, topStates } from "../../../actions/admin/dashboard";

import './dashboard.css'

import Map from "../../../section-dashboard/Map/Map";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import es from "date-fns/locale/es";
import { compareAsc, endOfDay, startOfYear } from 'date-fns';

//Acumulados
import AcumuladoDia from "../../../section-dashboard/Acumulados/AcumuladoDia";
import AcumuladoSemanal from "../../../section-dashboard/Acumulados/AcumuladoSemanal";
import AcumuladoMensual from "../../../section-dashboard/Acumulados/AcumuladoMensual";
import AcumuladoTrimestral from "../../../section-dashboard/Acumulados/AcumuladoTrimestral";
import AcumuladoYearToDate from "../../../section-dashboard/Acumulados/AcumuladoYearToDate";
import AcumuladoFiltro from "../../../section-dashboard/Acumulados/AcumuladoFiltro";

//Top 10 Clientes Frecuentes components
import ClientesFrecuentesMensual from "../../../section-dashboard/Top-Clientes-frecuentes/ClientesFrecuentesMensual";
import ClientesFrecuentesTrimestral from "../../../section-dashboard/Top-Clientes-frecuentes/ClientesFrecuentesTrimestral";
import ClientesFrecuentesYearToDate from "../../../section-dashboard/Top-Clientes-frecuentes/ClientesFrecuentesYearToDate";

//Top 10 productos
import TableMap from "../../../section-dashboard/Map/TableMap";
import TicketPromedio from "../../../section-dashboard/TicketPromedio/TicketPromedio";

import PrimerSemestre from "../../../section-dashboard/Clientes-Nuevos/PrimerSemestre";
import SegundoSemestre from "../../../section-dashboard/Clientes-Nuevos/SegundoSemestre";

import TicketPromedioClientes from "../../../section-dashboard/TicketPrmedioClientes/TicketPromedioClientes";
// import ContribuciónVentalTotal from "../../../section-dashboard/ContribuciónVenta/ContribuciónVentalTotal";

//MUI
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
    AppBar, Card, CardContent, Container, Dialog, DialogActions,
    DialogContent, DialogTitle, Divider, Grid, Toolbar, Typography
} from "@mui/material";
import {
    salesDefault, first_sem_data, second_sem_data,
    stateRows, MexStatesDefault, clientDataRows, avgTicketDefault,
    ticketGraphDefault
} from "../../../constants/constants";
import TopProductos from "./TopProductos/TopProductos";
import { TOP_PRODUCTS_DATA } from "../../../constants/admin/dashboard_constants";

const today = new Date();
const _startOfYear = startOfYear(today);

const DashboardComponent = () => {
    // Total de ventas
    const [todayTotalData, setTodayTotalData] = useState(salesDefault);
    const [weekTotalData, setWeekTotalData] = useState(salesDefault);
    const [monthTotalData, setMonthTotalData] = useState(salesDefault);
    const [quarterTotalData, setQuarterTotalData] = useState(salesDefault);
    const [yearToDateTotalData, setYearToDateTotalData] = useState(salesDefault);
    const [filteredTotalData, setFilteredTotalData] = useState(salesDefault);

    // Top 10 Productos
    const [topProductsData, setTopProductsData] = useState(TOP_PRODUCTS_DATA);
    
    // Clientes nuevos
    const [firstSemester, setFirstSemester] = useState(first_sem_data);
    const [secondSemester, setSecondSemester] = useState(second_sem_data);
    const [totalNewClients, setTotalNewClients] = useState(0);
    // Top estados
    const [countrySalesData, setCountrySalesData] = useState(MexStatesDefault);
    const [topStatesData, setTopStatesData] = useState(stateRows);
    // Top 10 clientes
    const [fqtMonthlyData, setFqtMonthlyData] = useState(clientDataRows);
    const [fqtQuarterlyData, setFqtQuarterlyData] = useState(clientDataRows);
    const [fqtYearToDateData, setFqtYearToDateData] = useState(clientDataRows);
    // Ticket promedio (General / Clientes nuevos)
    const [avgTicket, setAvgTicket] = useState(avgTicketDefault);
    const { general_ticket, clients_ticket } = avgTicket;
    // Ticket promedio (Gráfica)
    const [avgTicketData, setAvgTicketData] = useState(ticketGraphDefault);
    const { general_ticket_data, clients_ticket_data } = avgTicketData;
    // Todos los pedidos
    const [allOrders, setAllOrders] = useState([]);

    // rango de fechas en <DateRange />
    const [range, setRange] = useState([
        {
            startDate: _startOfYear,
            endDate: today,
            key: 'selection'
        }
    ]);
    // último filtro utilizado en <DateRange />
    const [defaultRange, setDefaultRange] = useState({
        startDate: _startOfYear,
        endDate: today,
    });
    const { startDate, endDate } = defaultRange;
    // estado de diálogo
    const [dialogRange, setDialogRange] = useState(false);

    useEffect(() => {
        shopTopProducts();
        getNewClients();
        getTopStates();
        getTopClients();
    }, []);

    // obtener Top 10 productos / Total de ventas en periodos / Ticket promedio
    const shopTopProducts = async () => {
        const req = await getTopProducts(setTopProductsData);
        if (req.status) {
            const {
                todayTotal, weekTotal, monthTotal, quarterTotal, yearToDateTotal,
                avg_general_ticket, generalTicketData, avg_clients_ticket,
                clientsTicketData, all_orders
            } = req.resp;
            // pedidos
            if (all_orders.length > 0) {
                setAllOrders(all_orders);
                const total = all_orders.reduce((partialSum, a) => partialSum + a.summary.total, 0);
                setFilteredTotalData({ total, qty: all_orders.length });
                filteredTopProducts(all_orders, _startOfYear, today);
            }
            setTodayTotalData(todayTotal);
            setWeekTotalData(weekTotal);
            // setFortnightTotalData(fortnightTotal);
            setMonthTotalData(monthTotal);
            setQuarterTotalData(quarterTotal);
            setYearToDateTotalData(yearToDateTotal);
            // setAnnualTotalData(annualTotal);
            
            if (avg_general_ticket.length > 0) {
                const avg_ticket = general_ticket.map(e => {
                    const found = avg_general_ticket.find(i => i.mes === e.mes);
                    return found ? found : e;
                }
                );
                setAvgTicket(e => ({
                    ...e,
                    general_ticket: [...avg_ticket]
                }));
            }
            setAvgTicketData({
                ...avgTicketData,
                general_ticket_data: {
                    ...general_ticket_data,
                    datasets: [{
                        label: 'Cantidad en $',
                        data: generalTicketData,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    }]
                },
                clients_ticket_data: {
                    ...clients_ticket_data,
                    datasets: [{
                        label: 'Cantidad en $',
                        data: clientsTicketData,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    }]
                }
            });
            if (avg_clients_ticket.length > 0) {
                const avg_ticket = clients_ticket.map(e => {
                    const found = avg_clients_ticket.find(i => i.mes === e.mes);
                    return found ? found : e;
                }
                );
                setAvgTicket(e => ({
                    ...e,
                    clients_ticket: [...avg_ticket]
                }));
            }
        }
    };

    // obtener nuevos clientes de los dos semestres del año
    const getNewClients = async () => {
        const req = await newClients();
        if (req.status) {
            const { first_semester_data, second_semester_data, total_new_clients } = req.resp;
            setFirstSemester(e => ({
                ...e,
                datasets: e.datasets.map((e, i) => {
                    return i === 0 ?
                        { ...e, data: first_semester_data }
                        : e
                })
            }));
            setSecondSemester(e => ({
                ...e,
                datasets: e.datasets.map((e, i) => {
                    return i === 0 ?
                        { ...e, data: second_semester_data }
                        : e
                })
            }));
            if (total_new_clients > 0)
                setTotalNewClients(total_new_clients);
        }
    };

    /* obtener ventas por estado &&
    primeros 5 estados con mayores ventas */
    const getTopStates = async () => {
        const req = await topStates();
        if (req.status) {
            const { countrySales, topStates } = req.resp;
            setCountrySalesData(e => ({ ...e, ...countrySales }));
            setTopStatesData(topStates);
        }
    };

    const getTopClients = async () => {
        const req = await topClients();
        if (req.status) {
            const { monthClients, quarterClients, yearToDateClients } = req.resp;
            if (monthClients.length > 0)
                setFqtMonthlyData(monthClients);
            if (quarterClients.length > 0)
                setFqtQuarterlyData(quarterClients);
            if (yearToDateClients.length > 0)
                setFqtYearToDateData(yearToDateClients);
        }
    };

    //Tabs
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //# clientes nuevos
    const [anchorClientes, setAnchorClientes] = useState(null);
    const openClientes = Boolean(anchorClientes);
    const handleClickClientes = (event) => {
        setAnchorClientes(event.currentTarget);
    };
    const handleCloseClientes = () => {
        setAnchorClientes(null);
    };

    const [semestre, setSemestre] = useState(true)
    const [semestre_2, setSemestre_2] = useState(false)

    const handleSemestre = () => {
        setSemestre(true)
        setSemestre_2(false)
        setAnchorClientes(null)
    }

    const handleSemestre_2 = () => {
        setSemestre(false)
        setSemestre_2(true)
        setAnchorClientes(null)
    }

    

    //DropDownTabs
    
    

    const [anchorTabs, setAnchorTabs] = useState(null);
    const openTabs = Boolean(anchorTabs);
    const handleOpenTabs = (event) => {
        setAnchorTabs(event.currentTarget);
    };
    const handleCloseTabs = () => {
        setAnchorTabs(null);
    };

    // Top 10 productos entre fecha seleccionada
    const filteredTopProducts = (filtered, _startDate, _endDate) => {
        let products = [];
        filtered.map(order => {
            order.products.map(prod => {
                const { title, variant_title, price, quantity, ...product } = prod;
                // venta producto
                const sum = price * quantity;
                // posición producto en arreglo
                const i = products.findIndex(e => e.prod_var_id === prod.prod_var_id);
                // producto en arreglo
                if (i > -1)
                    products[i] = {
                        ...products[i],
                        total_sale: products[i].total_sale + sum,
                        total_qty: products[i].total_qty + quantity
                    };
                // agregar producto a arreglo
                else {
                    products = [...products,
                    { ...product,
                        title: `${title} ${variant_title}`,
                        total_sale: sum,
                        total_qty: quantity
                    }];
                }
                return prod;
            });
            return order;
        });
        products.sort((a, b) => b.total_qty - a.total_qty);
        const sliced = products.length > 10 ?
            products.slice(0, 10)
        : products.slice(0, products.length);
        // título productos
        const labels = sliced.map(e => e.title);
        // total de ventas
        const data = sliced.map(e => e.total_qty);
        setTopProductsData(e => ({
            ...e,
            filteredData: {
                ...e.filteredData,
                labels,
                datasets: e.filteredData.datasets.map((u, i) => {
                    return i === 0 ?
                        { ...u, data }
                        : u
                })
            }
        }));
        // setFilteredData(e => ({
        //     ...e,
        //     labels,
        //     datasets: e.datasets.map((e, i) => {
        //         return i === 0 ?
        //             { ...e, data }
        //             : e
        //     })
        // }));
    };

    // cambio de fecha
    const filterByDate = () => {
        const _start = range[0].startDate;
        let _end = range[0].endDate;
        if (compareAsc(_start, _end) === 0) {
            _end = endOfDay(_start);
        }
        setDefaultRange({
            ...defaultRange,
            startDate: _start,
            endDate: _end
        });
        if (allOrders.length > 0) {
            const filtered = allOrders.filter(e =>
                [0, 1].includes(compareAsc(new Date(e.order_date), _start))
                && [0, -1].includes(compareAsc(new Date(e.order_date), _end)));
            if (filtered.length > 0) {
                const total = filtered.reduce((partialSum, a) => partialSum + a.summary.total, 0);
                setFilteredTotalData({ total, qty: filtered.length });
                filteredTopProducts(filtered, _start, _end);
            } else {
                setFilteredTotalData({ total: 0, qty: 0 });
                // setFilteredData(e => ({
                //     ...e,
                //     labels: ['Sin ventas'],
                //     datasets: e.datasets.map((e, i) => {
                //         return i === 0 ?
                //             { ...e, data: [1] }
                //             : e
                //     })
                // }));
                setTopProductsData(e => ({
                    ...e,
                    filteredData: {
                        ...e.filteredData,
                        labels: ['Sin ventas'],
                        datasets: e.filteredData.datasets.map((u, i) => {
                            return i === 0 ?
                                { ...u, data: [1] }
                                : u
                        })
                    }
                }));
            }
        }
        setDialogRange(false);
    };

    return (
        <div style={{ backgroundColor: '#F3F4F6' }}>
            <Container maxWidth='xl' sx={{ py: 3 }}>
                {/* title */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px', mb: 3 }}>
                    <Typography variant='h5' color='text.secondary' paragraph sx={{ my: 'auto' }}>Dashboard</Typography>
                    <IconButton onClick={() => { setDialogRange(true) }} sx={{ my: 'auto' }}>
                        <CalendarMonthIcon />
                    </IconButton>
                </Box>

                <Dialog
                    open={dialogRange}
                    onClose={() => { setDialogRange(false) }}
                >
                    <DialogTitle>
                        Filtrar por fecha
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ border: '1px solid #e1e1e1', borderRadius: '3px' }}>
                            <DateRange
                                editableDateInputs={true}
                                onChange={item => setRange([item.selection])}
                                moveRangeOnFirstSelection={false}
                                ranges={range}
                                locale={es}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setDialogRange(false) }}>Cancelar</Button>
                        <Button onClick={ filterByDate }>Filtrar</Button>
                    </DialogActions>
                </Dialog>

                {/* Acumulados */}
                <Grid container spacing={4}>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <AcumuladoDia total_sales={todayTotalData} />
                    </Grid>

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <AcumuladoSemanal total_sales={weekTotalData} />
                    </Grid>

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <AcumuladoMensual total_sales={monthTotalData} />
                    </Grid>

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <AcumuladoTrimestral total_sales={quarterTotalData} />
                    </Grid>

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <AcumuladoYearToDate total_sales={yearToDateTotalData} />
                    </Grid>

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <AcumuladoFiltro
                            startDate={startDate}
                            endDate={endDate}
                            total_sales={filteredTotalData} />
                    </Grid>
                </Grid>
                {/* Fin acumulados */}

                {/* Row */}
                <Grid container spacing={4} sx={{ mt: 4 }}>
                    {/* Top 10 productos vendidos */}
                    <TopProductos
                        defaultRange={ defaultRange }
                        topProductsData={ topProductsData }
                    />
                    {/* Fin Top 10 productos vendidos */}

                    {/* Clientes nuevos */}
                    <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                        <Card sx={{ width: '100%' }} className='clientes-nuevos-card'>
                            <AppBar position="static" sx={{ bgcolor: '#fff' }} elevation={0}>
                                <Toolbar>
                                    <Typography variant="body1" sx={{ flexGrow: 1, color: '#000', fontWeight: '600' }}>
                                        Clientes nuevos
                                    </Typography>
                                    <Button
                                        color='primary'
                                        onClick={handleClickClientes}
                                        startIcon={<FilterAltIcon />}
                                    >
                                        Filtrar
                                    </Button>
                                    <Menu
                                        anchorEl={anchorClientes}
                                        open={openClientes}
                                        onClose={handleCloseClientes}
                                        TransitionComponent={Fade}
                                    >
                                        <MenuItem onClick={handleSemestre}>1er semestre</MenuItem>
                                        <MenuItem onClick={handleSemestre_2}>2do Semestre</MenuItem>
                                    </Menu>
                                </Toolbar>
                            </AppBar>
                            <CardContent>
                                <Semestre semestre={semestre} firstSemester={firstSemester} />
                                <Semestre2 semestre_2={semestre_2} secondSemester={secondSemester} />
                                <Divider sx={{ my: 3 }} />
                                <Typography variant='h6' color='text.secondary' align='center'>{totalNewClients} </Typography>
                                <Typography variant='body1' color='text.secondary' sx={{ fontWeight: 'bold' }} align='center'>
                                    Total clientes nuevos
                                </Typography>

                            </CardContent>
                        </Card>
                        {/*****/}
                    </Grid>
                    {/* Fin clientes nuevos */}
                </Grid>
                {/* Fin row */}

                {/* <Box sx={{mt: 5}}>
                    <ContribuciónVentalTotal />
                </Box> */}

                {/* Top 5 estados */}
                <Box sx={{ mt: 6 }}>
                    <Card sx={{ width: '100%', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                        <AppBar position="static" sx={{ bgcolor: '#fff' }} elevation={0}>
                            <Toolbar>
                                <Typography variant='body1' sx={{ flexGrow: 1, color: '#000', fontWeight: '600' }}>Top 5 estados en ventas</Typography>
                                <IconButton>
                                    <MoreVertIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <CardContent sx={{ px: 3 }}>
                            <Grid container spacing={3}>
                                <Grid item xl={7} ld={7} md={12} sm={12} xs={12}>

                                    <Map countrySalesData={countrySalesData} />

                                </Grid>

                                <Grid item xl={5} ld={5} md={12} sm={12} xs={12}>
                                    <TableMap topStatesData={topStatesData} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
                {/* Fin Top 5 estados */}

                {/* Top 10 clientes frecuentes */}
                <Box sx={{ mt: 6 }}>
                    <Card sx={{ width: '100%', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                        <TabContext value={value}>
                            <AppBar position="static" sx={{ bgcolor: '#fff' }} elevation={0}>
                                <Toolbar>
                                    <Typography variant='body1' sx={{ flexGrow: 1, color: '#000', fontWeight: '600' }}>Top 10 clientes frecuentes</Typography>
                                    <IconButton onClick={handleOpenTabs}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorTabs}
                                        open={openTabs}
                                        onClose={handleCloseTabs}
                                    >
                                        <TabList onChange={handleChange} orientation="vertical">
                                            <Tab label="Mensual" value="1" />
                                            <Tab label="Trimestral" value="2" />
                                            <Tab label="Year to date" value="3" />
                                        </TabList>
                                    </Menu>
                                </Toolbar>
                            </AppBar>
                            <CardContent sx={{ padding: 0 }}>
                                <TabPanel value="1">
                                    {/* Clientes frecuentes mensual */}
                                    <ClientesFrecuentesMensual rows={fqtMonthlyData} />
                                </TabPanel>
                                <TabPanel value="2">
                                    {/* Clientes frecuentes trimestral */}
                                    <ClientesFrecuentesTrimestral rows={fqtQuarterlyData} />
                                </TabPanel>
                                <TabPanel value="3">
                                    {/* Clientes frecuentes Year to date */}
                                    <ClientesFrecuentesYearToDate rows={fqtYearToDateData} />
                                </TabPanel>
                            </CardContent>
                        </TabContext>
                    </Card>
                </Box>
            </Container>
            {/* Fin contenido */}

            {/* Tickets promedio */}
            <Container maxWidth='xl' sx={{ mt: 5 }} className="last-view">
                <Grid container spacing={4}>
                    <Grid item xl={6} lg={6} md={12} xs={12}>
                        <TicketPromedio data={general_ticket_data}
                            data_table={general_ticket} />
                    </Grid>
                    <Grid item xl={6} lg={6} md={12} xs={12}>
                        <TicketPromedioClientes data={clients_ticket_data}
                            data_table={clients_ticket} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};


//# clientes nuevos

const Semestre = ({ semestre, firstSemester }) => {
    if (!semestre) return null
    return (
        <>
            <PrimerSemestre firstSemester={firstSemester} />
        </>
    )
}

const Semestre2 = ({ semestre_2, secondSemester }) => {
    if (!semestre_2) return null
    return (
        <>
            <SegundoSemestre secondSemester={secondSemester} />
        </>
    )
}

export default DashboardComponent;