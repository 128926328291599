import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

// import CategoryAdd from './CategoryAdd';
import CategoryList from './CategoryList';

import {
    Grid, ToggleButton, ToggleButtonGroup,
    Divider, Chip, Box, FormGroup, Alert,
    Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';

// agregar nueva categoría
const CategorySelect = ({ setProdValues }) => {
    // lista de categorías
    const { categories } = useSelector(state => state.category);
    // estado de componente categoría
    const [toggle, setToggle] = useState('list');

    useEffect(() => {
        setToggle('list');
    }, [categories]);

    // lista de categoría / añadir nueva categoría
    const onCategoryChange = (event, pos) => {
        if (pos !== null)
            setToggle(pos);
    };
    
    return (
        <>
            <Grid container>
                <Grid item xs={8}>
                    <Divider>
                        <Chip label="Categorías" sx={{ fontSize: 16 }} />
                    </Divider>
                </Grid>
                <Grid item xs={4}>
                    <Box display="flex" justifyContent="flex-end">
                        <ToggleButtonGroup
                            value={ toggle }
                            exclusive
                            onChange={ onCategoryChange }
                            aria-label="estado categoría"
                            size="small">
                            <ToggleButton value="list" aria-label="category list">
                                <ListIcon />
                            </ToggleButton>
                            <ToggleButton value="add" aria-label="add category">
                                <AddIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Grid>
            </Grid>
            {
                toggle === 'add' ?
                    // <CategoryAdd />
                    <Typography variant='body1' gutterBottom>
                        <Link to='/nefro-admin/categorias-producto'
                            target="_blank" rel="noopener noreferrer">
                        Ir a Categorías de Producto
                        </Link>
                    </Typography>
                :
                    <FormGroup>
                        {
                            categories.length <= 0 ?
                                <Alert severity="info">
                                    Añada una categoría
                                </Alert>
                            :
                                <CategoryList setProdValues={ setProdValues } />
                        }
                    </FormGroup>
            }
        </>
    );
};

export default CategorySelect;