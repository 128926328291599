import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const PrivateRoute = ({ children }) => {
    const { loggedUser } = useSelector(state => state.user);
    const { email } = loggedUser;

    return (email !== '')
        ? children
        : <Navigate to="/login" />
};