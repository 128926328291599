// export const BRANCH_INIT = {
//     name: '',
//     phone: '',
//     mobile: '',
//     email: '',
//     address: '',
//     postalCode: '',
//     colony: '',
//     city: '',
//     state: '',
//     links: {
//         whatsapp: {
//             number: '',
//             message: 'Buen día, me gustaría obtener información'
//         },
//         facebook: {
//             url: ''
//         },
//         google_maps: {
//             src: '',
//             title: '',
//             width: '100%',
//             height: '450'
//         }
//     }
//     // images: []
// };

export const BRANCH_INIT = {
    name: 'Sucursal Guadalajara',
    phone: '3320233445',
    mobile: '4444444444',
    email: 'jpablo.alejo1@hotmail.com',
    address: 'Volcan Algo #4455',
    postalCode: '44100',
    colony: 'Americana',
    city: 'Guadalajara',
    state: 'Jalisco',
    links: {
        whatsapp: {
            number: '5555555555',
            message: 'Buen día, me gustaría obtener información'
        },
        facebook: {
            url: 'https://www.facebook.com/nutriologa.renalguadalajara.9'
        },
        google_maps: {
            src: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3735.064495476568!2d-100.40448238515326!3d20.585423586240214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d3456ec6700e37%3A0x89f6fb3e96e574a9!2sNefr%C3%B3polis%20Sucursal%20Quer%C3%A9taro!5e0!3m2!1ses-419!2smx!4v1646752337635!5m2!1ses-419!2smx',
            title: 'Guadalajara',
            width: '100%',
            height: '450'
        }
    }
    // images: []
};

export const BRANCH_FIELD_ERR = {
    name_err: false,
    phone_err: false,
    mobile_err: false,
    email_err: false,
    address_err: false,
    pc_err: false,
    colony_err: false,
    city_err: false,
    state_err: false,
    wsp_number_err: false,
    wsp_message_err: false,
    facebook_url_err: false,
    iframe_src_err: false,
    iframe_title_err: false,
    iframe_width_err: false,
    iframe_height_err: false
}