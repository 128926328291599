import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Router-dom
import { Link } from 'react-router-dom';

import { basicMsg } from "../../actions/swal_msg";
import { form2Submit, form2Submitted } from "../../actions/forms";

//CSS
import './bolsatrabajo.css'

//MUI
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Container, Grid, Typography, useMediaQuery  } from '@mui/material'

const name_regex = /^([^0-9]*)$/;
const email_regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const tel_regex = /^[0-9]*$/;

// valores de formulario
const init = {
    fullname: '',
    email: '',
    tel: '',
    file: ''
};

// errores en campos
const initErr = {
    fullname_err: false,
    email_err: false,
    tel_err: false,
};

const BolsaTrabajoComponent = () => {
    const matches = useMediaQuery('(max-width: 950px)')
    const dispatch = useDispatch();
    const { form2, form2Loading } = useSelector(state => state.form);
    // campos
    const [values, setValues] = useState(init);
    const { fullname, email, tel, file } = values;
    // errores en campos
    const [inputErr, setInputErr] = useState(initErr);
    const { fullname_err, email_err, tel_err } = inputErr;
    const [isFilePicked, setIsFilePicked] = useState(false);
    // referencias a campos
    const fullname_ref = useRef();
    const tel_ref = useRef();
    const email_ref = useRef();

    useEffect(() => {
        dispatch(form2Submitted(false));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (form2) {
            setValues({
                fullname: '',
                email: '',
                tel: '',
                file: ''
            });
            dispatch(form2Submitted(false));
            setButton(false);
            setIsFilePicked(false);
        }
    }, [form2]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeHandler = ({ target }) => {
        setValues(e => ({
            ...e,
            file: target.files[0]
        })
        );
        //setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
        setButton(true)
    };

    const deleteFile = () => {
        setValues(e => ({
            ...e,
            file: ''
        })
        );
        //setSelectedFile("");
        setIsFilePicked(false);
        setButton(false)
    }

    const [button, setButton] = useState(false);
    const ButtonDeleteFile = () => {
        return (
            <>
                <Link
                    to={""}
                    style={{ color: 'red' }}
                    onClick={deleteFile}
                >
                    <i className="fa-solid fa-xmark"></i>
                </Link>
            </>
        )
    };

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'fullname') {
            if (value === '' || name_regex.test(value)) {
                setValues(e => ({
                    ...e,
                    [name]: value
                })
                );
            }
        } else if (name === 'tel') {
            if (value === '' || tel_regex.test(value)) {
                setValues(e => ({
                    ...e,
                    [name]: value
                })
                );
            }
        } else setValues(e => ({
            ...e,
            [name]: value
        })
        );
        switch (name) {
            case 'fullname':
                setInputErr({
                    ...inputErr,
                    fullname_err: (value.length < 2 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'tel':
                setInputErr({
                    ...inputErr,
                    tel_err: (value.length < 3 ||
                        value.length > 20) ? true : false
                });
                break;
            case 'email':
                setInputErr({
                    ...inputErr,
                    email_err: (value.length < 6 ||
                        value.length > 100) ? true : false
                });
                break;
            default:
                break;
        }
    };

    // envío de formulario
    const submit = (e) => {
        e.preventDefault();
        if (formValidator())
            dispatch(form2Submit(values));
    };

    // validación básica de formulario
    const formValidator = () => {
        const full_len = fullname.trim().length;
        if (full_len > 1 && full_len <= 100) {
            const email_len = email.trim().length;
            if (email_len > 5 && email_len <= 100 && email_regex.test(email)) {
                const tel_len = tel.trim().length;
                if (tel_len > 2 && tel_len <= 20) {
                    if (file !== '') return true;
                    else basicMsg('Añada su CV (.pdf/.docx)');
                } else {
                    tel_ref.current.select();
                    setInputErr({
                        ...inputErr,
                        tel_err: true
                    });
                }
            } else {
                email_ref.current.select();
                setInputErr({
                    ...inputErr,
                    email_err: true
                });
            }
        } else {
            fullname_ref.current.select();
            setInputErr({
                ...inputErr,
                fullname_err: true
            });
        }
        return false;
    };

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={form2Loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* Title */}
            <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }}>
                <Typography variant='h4' sx={{ fontWeight: 'light' }} align='center'>ÚNETE A NUESTRO EQUIPO.</Typography>
            </Container>

            {/* Contenido */}
            <Container maxWidth='xl' sx={{ mt: 5 }} className="last-view">
                <Typography variant='body1' paragraph>Apoyamos y motivamos a nuestros colaboradores a crecer en más de una
                    dimensión y a explotar todo su potencial tanto personal como profesional
                    logrando un crecimiento, balance intelectual, emocional y social que te
                    llevará a sentirte completamente satisfecho. Buscamos ser la una de las
                    mejores empresas para trabajar.</Typography>

                <Typography variant='body1' paragraph>Gracias al talento de cada uno de sus colaboradores, Nefrópolis es una empresa
                    sólida, y con presencia a nivel nacional. Por ello, te invitamos a que hagas
                    carrera con nosotros, que te desarrolles y te realices plenamente.
                    <b> Esperamos tener un lugar para ti</b></Typography>

                <Typography variant='body1' paragraph><b>Coord. de Capital humano.</b></Typography>

                {/* Formulario */}
                <Box sx={{ p: 3, mt: 5 }} className="box-nuestro-equipo">
                    <form action="">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id=""
                                        name="fullname"
                                        label="Nombre"
                                        type="text"
                                        placeholder="Nombre"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-user"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        inputProps={{ maxLength: 100 }}
                                        error={fullname_err}
                                        helperText={fullname_err ? "Ingrese su nombre" : ""}
                                        inputRef={fullname_ref}
                                        value={fullname}
                                        onChange={handleInputChange} />
                                </FormControl>
                            </Grid>

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id=""
                                        name="email"
                                        label="Correo electrónico"
                                        type="mail"
                                        placeholder="Correo electrónico"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-envelope"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        inputProps={{ maxLength: 100 }}
                                        error={email_err}
                                        helperText={email_err ? "Ingrese su correo electrónico" : ""}
                                        inputRef={email_ref}
                                        value={email}
                                        onChange={handleInputChange} />
                                </FormControl>
                            </Grid>

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id=""
                                        name="tel"
                                        label="Teléfono"
                                        type="text"
                                        placeholder="Teléfono"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <i className="fa-solid fa-phone"></i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                        inputProps={{ maxLength: 10 }}
                                        error={tel_err}
                                        helperText={tel_err ? "Ingrese su número de teléfono" : ""}
                                        inputRef={tel_ref}
                                        value={tel}
                                        onChange={handleInputChange} />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <Box sx={{ mr: 1 }}>
                                        <Button
                                            variant="contained"
                                            component="label"
                                        >
                                            Subir archivo
                                            <input
                                                accept="application/pdf, application/msword, .doc, .docx"
                                                type="file"
                                                hidden
                                                onChange={changeHandler}
                                            />
                                        </Button>
                                    </Box>

                                    <Box sx={{ my: 'auto' }}>
                                        {isFilePicked ? (
                                            <div>
                                                {file.name}
                                            </div>
                                        ) : null}
                                    </Box>

                                    <Box sx={{ my: 'auto', ml: 1 }}>
                                        {button ?
                                            < ButtonDeleteFile ></ButtonDeleteFile>
                                            : null}
                                    </Box>
                                </Box>
                                <Typography variant='body2' sx={{ fontWeight: 'light', mt: .5 }}>
                                    Carga tu CV en formato pdf o word
                                </Typography>
                            </Grid>
                        </Grid>

                        <Button variant='contained' onClick={submit} sx={{ mt: 2 }}>Enviar</Button>
                    </form>
                </Box>
                {/* Fin formulario */}

                <Box sx={{ mt: 6, textAlign: 'center' }}>
                    <Typography variant='h5' sx={{ fontWeight: 'light' }} gutterBottom>CONOCE NUESTRO CÓDIGO DE ÉTICA</Typography>
                    <Link to="/codigo-de-etica">
                        <i className="fa-solid fa-arrow-up-right-from-square" style={{ fontSize: '1.8rem' }}></i>
                    </Link>
                </Box>

            </Container>
            {/* Fin contenido */}
        </>
    )
}

export default BolsaTrabajoComponent