import Swal from 'sweetalert2';

export const msg = (icon, title, text) => {
    Swal.fire({
        icon,
        title,
        text,
        timer: 4000
    });
};

export const timerMsg = (icon, title, text, timer) => {
    Swal.fire({
        icon,
        title,
        text,
        timer
    });
};

export const posMsg = (icon, title) => {
    Swal.fire({
        position: 'center',
        icon,
        title,
        showConfirmButton: false,
        timer: 2000
    });
};

export const valMsg = (icon, title, text) => {
    Swal.fire({
        icon,
        title,
        text
    });
};

export const basicMsg = (text) => {
    Swal.fire(text);
};

export const basicTimerMsg = (title) => {
    Swal.fire({ title, timer: 3000 });
};

export const htmlMsg = (icon, title, html) => {
    Swal.fire({
        icon, title, html,
        confirmButtonText: 'Entendido',
    });
};

export const simpleConfirmDialog = async (title, confirmButtonText) => {
    return await Swal.fire({
        title,
        showDenyButton: true,
        confirmButtonText,
        denyButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed)
            return true;
        return false;
    });
};

export const confirmDialog = async (title, html, confirmButtonText) => {
    return await Swal.fire({
        title, html,
        showDenyButton: true,
        confirmButtonText,
        denyButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6'
    }).then((result) => {
        if (result.isConfirmed)
            return true;
        return false;
    });
};

export const cartDialog = async (title, html, confirmButtonText, cancelButtonText) => {
    return await Swal.fire({
        title, html,
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
        allowOutsideClick: false
    }).then((result) => {
        if (result.isConfirmed)
            return true;
        return false;
    });
};