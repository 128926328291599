import { msg } from "../swal_msg";
import { protectedReq, axiosProtectedReq } from "../../helpers/requestHelper";

export const branchList = async() => {
    try {
        const resp = await protectedReq('branch/list');
        const body = await resp.json();
        return body;
    } catch (err) {
        msg('error', 'Sucursales',
        'No se ha podido carga lista de sucursales');
        return { status: false };
    }
};

export const saveBranchMainImage = async(values) => {
    try {
        const { file, ...branch } = values;
        const { name } = branch;
        const data = new FormData();
        data.append('title', name);
        data.append('file', file);
        const resp = await axiosProtectedReq('branch/save-img', data, 'POST');
        if (resp.status) {
            const { resp: { folderId, imgId } } = resp;
            return await saveBranch({ ...branch, folderId, imgId });
        } else {
            if (resp.msg === 'no-file')
                msg('warning', 'Sucursales',
                'No se ha enviado imagen principal');
            else if (resp.msg === 'folder-not-created')
                msg('warning', 'Sucursales',
                'Carpeta no creada');
            else if (resp.msg === 'file-not-uploaded')
                msg('warning', 'Sucursales',
                'No se ha subido imagen principal');
            else if (resp.msg === 'server-err')
                msg('warning', 'Sucursales',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else msg('warning', 'Sucursales',
                'No se pudo guardar imagen de sucursal');
        }
        return resp;
    } catch (err) {
        msg('error', 'Sucursales',
        'No se ha guardado la sucursal');
        return { status: false };
    }
};

export const updateBranchMainImage = async(values) => {
    try {
        const { file, folderId, imgId, ...branch } = values;
        const data = new FormData();
        data.append('folderId', folderId);
        data.append('imgId', imgId);
        data.append('file', file);
        const resp = await axiosProtectedReq('branch/update-img', data, 'POST');
        if (resp.status) {
            const { resp: { imgId } } = resp;
            return await updateBranch({ ...branch, folderId, imgId });
        } else {
            if (resp.msg === 'no-file')
                alert('No se ha enviado imagen principal');
            else if (resp.msg === 'folder-not-found')
                alert('No existe carpeta de sucursal');
            else if (resp.msg === 'file-not-uploaded')
                alert('No se ha actualizado imagen principal');
            else if (resp.msg === 'server-err')
                alert('Ocurrió un incidente. Intente de nuevo, por favor');
            else
                alert('No se pudo actualizar imagen de sucursal');
            return await updateBranch({ ...branch, folderId, imgId });
        }
    } catch (err) {
        alert('No se ha actualizado la sucursal');
        return { status: false };
    }
};

const saveBranch = async(branch) => {
    const {
        name, phone, mobile, email, address, postalCode,
        colony, city, state, links: { whatsapp, facebook, google_maps },
        folderId, imgId
    } = branch;
    const { number, message } = whatsapp;
    const { url } = facebook;
    const { src, title, width, height } = google_maps;

    const model = {
        data: {
            title: name,
            phone,
            mobile,
            email
        },
        address: {
            street: address,
            colony,
            postalcode: postalCode,
            city,
            state
        },
        links: {
            whatsapp: {
                number,
                message
            },
            facebook: {
                url
            },
            google_maps: {
                src, title,
                width, height
            }
        },
        folderId, imgId
    };
    const resp = await protectedReq('branch/save', model, 'POST');
    const body = await resp.json();
    if (body.status) {
        msg('success', 'Sucursales',
        `${name} ha sido guardada`);
    } else {
        if (body.msg === 'miss')
            msg('error', 'Sucursales',
            'Faltan datos de la sucursal');
        else if (body.msg === 'not-saved')
            msg('error', 'Sucursales',
            'Sucursal no fue guardada');
        else if (body.msg === 'server-err')
            msg('error', 'Sucursales',
            'Ocurrió un incidente');
        else
            msg('error', 'Sucursales',
            'No se pudo guardar la sucursal');
    }
    return body;
};

export const updateBranch = async(branch) => {
    try {
        const {
            _id, name, phone, mobile, email, address, postalCode,
            colony, city, state, links: { whatsapp, facebook, google_maps },
            folderId, imgId
        } = branch;
        const { number, message } = whatsapp;
        const { url } = facebook;
        const { src, title, width, height } = google_maps;

        const model = {
            _id, folderId, imgId,
            data: {
                title: name,
                phone,
                mobile,
                email
            },
            address: {
                street: address,
                colony,
                postalcode: postalCode,
                city,
                state
            },
            links: {
                whatsapp: {
                    number,
                    message
                },
                facebook: {
                    url
                },
                google_maps: {
                    src, title,
                    width, height
                }
            }
        };
        const resp = await protectedReq('branch/update', model, 'PUT');
        const body = await resp.json();
        if (body.status) {
            msg('success', 'Sucursales',
            `${name} ha sido actualizada`);
        } else {
            if (body.msg === 'miss')
                alert('Faltan datos de la sucursal');
            else if (body.msg === 'not-updated')
                alert('Sucursal no fue actualizada');
            else if (body.msg === 'server-err')
                alert('Ocurrió un incidente');
            else
                alert('No se pudo actualizar la sucursal');
        }
        return body;
    } catch (err) {
        alert('error', 'Sucursales',
        'No se ha actualizado la sucursal');
        return { status: false };
    }
};

export const deleteBranch = async(branch) => {
    try {
        const {
            _id, name, folderId
        } = branch;
        const model = { _id, folderId };
        const resp = await protectedReq('branch/delete', model, 'DELETE');
        const body = await resp.json();
        if (body.status) {
            msg('success', 'Sucursales',
            `${name} ha sido eliminada`);
        } else {
            if (body.msg === 'miss')
                alert('Faltan datos de la sucursal');
            else if (body.msg === 'not-deleted')
                alert('Sucursal no fue eliminada');
            else if (body.msg === 'server-err')
                alert('Ocurrió un incidente');
            else
                alert('No se pudo eliminar la sucursal');
        }
        return body;
    } catch (err) {
        alert('No se ha eliminado la sucursal');
        return { status: false };
    }
};