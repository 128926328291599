import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import './Promotion.css'

import { DateRange } from 'react-date-range';
import es from "date-fns/locale/es";
import { format, startOfDay, endOfDay, addWeeks } from 'date-fns';

import ActivePromotions from './ActivePromotions';
import PromoOne from './individual-promo/PromoOne';
import PromoTwo from './individual-promo/PromoTwo';
import PromoFour from './individual-promo/PromoFour';
import PromoFive from './individual-promo/PromoFive';
import PromotionDialog from './Update/PromotionDialog';
import PromoThree from './individual-promo/PromoThree';

import { loadProductList } from '../../../actions/product';
import { promotionList } from '../../../constants/constants';
import { getActivePromotions } from '../../../actions/admin/promotion';

import {
    Box, Button, Dialog, DialogActions, Typography,
    DialogContent, DialogTitle, Grid, TextField,
    IconButton, Stack, FormControl, InputLabel,
    Select, MenuItem, Alert, Container
} from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const today = new Date();
const dayStart = startOfDay(today);
const todayPlusWeek = addWeeks(endOfDay(today), 1);

const Promotion = () => {
    const dispatch = useDispatch();
    // rango de fechas en <DateRange />
    const [range, setRange] = useState([
        {
            startDate: dayStart,
            endDate: todayPlusWeek,
            key: 'selection'
        }
    ]);
    // duración de promoción <DateRange />
    const [defaultRange, setDefaultRange] = useState({
        startDate: dayStart,
        endDate: todayPlusWeek,
    });
    const { startDate, endDate } = defaultRange;
    // estado de diálogo
    const [dateDialog, setDateDialog] = useState(false);
    // tipo de promoción seleccionada
    const [promotionSelected, setPromotionSelected] = useState('');
    // promociones activas
    const [activePromotion, setActivePromotion] = useState([]);
    // promociones registradas
    const [otherPromotion, setOtherPromotion] = useState([]);

    const [dialogStatus, setDialogStatus] = useState(false);
    const [currentPromotion, setCurrentPromotion] = useState({
        promo: '', pkg_qty: '', discount: '', 
        start_date: dayStart, end_date: todayPlusWeek
    });

    useEffect(() => {
        dispatch(loadProductList()); // productos
        getPromotions();
    }, []);

    // obtener promociones activas
    const getPromotions = async () => {
        const req = await getActivePromotions();
        if (req.status) {
            const { resp: { promos, activePromos } } = req;
            setOtherPromotion(promos);
            setActivePromotion(activePromos);
        }
    };

    // cambio de fecha
    const filterByDate = () => {
        setDefaultRange({
            ...defaultRange,
            startDate: range[0].startDate, // inicio día
            endDate: endOfDay(range[0].endDate) // fin día
        });
        setDateDialog(false);
    };

    // selección de categoría
    const handleChange = ({ target }) => {
        setPromotionSelected(target.value);
    };

    return (
        <Container maxWidth='xxl' sx={{ mb: 5, mt: '90px' }}>
            <Typography variant='h5' sx={{ fontWeight: 'light' }} paragraph>Promociones activas</Typography>
            <Grid container spacing={3}>
                {
                    activePromotion.length > 0 ?
                        activePromotion.map((e, i) =>
                            <Grid item key={i}
                                xl={4} lg={4} md={6} sm={6} xs={12} style={{ display: 'flex' }}>
                                <ActivePromotions
                                    promotion={e}
                                    setOtherPromotion={ setOtherPromotion }
                                    setActivePromotion={ setActivePromotion }
                                    setDialogStatus={ setDialogStatus }
                                    setCurrentPromotion={ setCurrentPromotion }
                                />
                            </Grid>
                        )
                        :
                        <Grid item xs={12}>
                            <Alert severity="info">No hay promociones activas en tienda</Alert>
                        </Grid>
                }
            </Grid>

            <Box sx={{ mt: 5 }}>
                <Typography variant='h5' sx={{ fontWeight: 'light' }} paragraph>Promociones guardadas</Typography>
                <Grid container spacing={3}>
                    {
                        otherPromotion.length > 0 ?
                            otherPromotion.map((e, i) =>
                                <Grid item key={i}
                                    xl={4} lg={4} md={6} sm={6} xs={12} style={{ display: 'flex' }}>
                                    <ActivePromotions
                                        promotion={e}
                                        setOtherPromotion={ setOtherPromotion }
                                        setActivePromotion={ setActivePromotion }
                                        setDialogStatus={ setDialogStatus }
                                        setCurrentPromotion={ setCurrentPromotion }
                                    />
                                </Grid>
                            )
                            :
                            <Grid item xs={12}>
                                <Alert severity="info">No hay promociones registradas</Alert>
                            </Grid>
                    }
                </Grid>
            </Box>

            <Box sx={{ mt: 5 }}>
                <Typography sx={{fontSize: '28px'}} paragraph>Agregar nueva promoción</Typography>
                <Grid container spacing={4}>
                    <Grid item xl={12}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="promotion-select-label">Tipo de promoción</InputLabel>
                            <Select
                                labelId="promotion-select-label"
                                value={promotionSelected}
                                label="Tipo de promoción"
                                onChange={handleChange}>
                                <MenuItem
                                    value=''>
                                    Seleccionar...
                                </MenuItem>
                                {
                                    promotionList.map(({ name, value }) =>
                                        <MenuItem key={value}
                                            value={value}>
                                            {name}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            sx={{ mb: 2 }}>
                            <Typography variant="body1">
                                Duración de la promoción
                            </Typography>
                            <IconButton onClick={() => { setDateDialog(true) }} sx={{ my: 'auto' }}>
                                <CalendarMonthIcon />
                            </IconButton>
                        </Stack>

                        <Stack
                            direction="row"
                            spacing={2}>
                            <TextField
                                size='small'
                                fullWidth
                                disabled
                                label="Fecha de inicio"
                                value={`${format(startDate, "d 'de' MMMM yyyy", { locale: es })}`} />
                            <TextField
                                size='small'
                                fullWidth
                                disabled
                                label="Fecha de finalización"
                                value={`${format(endDate, "d 'de' MMMM yyyy", { locale: es })}`} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            (promotionSelected === '2x1' || promotionSelected === '4x3') &&
                            <PromoOne promotion={
                                {
                                    promo: promotionSelected,
                                    start_date: startDate,
                                    end_date: endDate
                                }
                            }
                                activePromotion={activePromotion}
                                setActivePromotion={setActivePromotion}
                                otherPromotion={otherPromotion}
                                setOtherPromotion={setOtherPromotion}
                                today={today} />
                        }
                        {
                            promotionSelected === 'ABC' &&
                            <PromoTwo promotion={
                                {
                                    promo: promotionSelected,
                                    start_date: startDate,
                                    end_date: endDate
                                }
                            }
                                activePromotion={activePromotion}
                                setActivePromotion={setActivePromotion}
                                otherPromotion={otherPromotion}
                                setOtherPromotion={setOtherPromotion}
                                today={today} />
                        }
                        {
                            (promotionSelected === 'Descuento$Producto' || promotionSelected === 'Descuento%Producto') &&
                            <PromoThree promotion={
                                {
                                    promo: promotionSelected,
                                    start_date: startDate,
                                    end_date: endDate
                                }
                            }
                                activePromotion={activePromotion}
                                setActivePromotion={setActivePromotion}
                                otherPromotion={otherPromotion}
                                setOtherPromotion={setOtherPromotion}
                                today={today} />
                        }
                        {
                            ['Compra$Descuento', 'Compra%Descuento', 'CompraAB%Descuento'].includes(promotionSelected) &&
                            <PromoFour promotion={
                                {
                                    promo: promotionSelected,
                                    start_date: startDate,
                                    end_date: endDate
                                }
                            }
                                activePromotion={activePromotion}
                                setActivePromotion={setActivePromotion}
                                otherPromotion={otherPromotion}
                                setOtherPromotion={setOtherPromotion}
                                today={today} />
                        }
                        {
                            promotionSelected === 'CompraX%Descuento' &&
                            <PromoFive promotion={
                                {
                                    promo: promotionSelected,
                                    start_date: startDate,
                                    end_date: endDate
                                }
                            }
                                activePromotion={activePromotion}
                                setActivePromotion={setActivePromotion}
                                otherPromotion={otherPromotion}
                                setOtherPromotion={setOtherPromotion}
                                today={today} />
                        }
                    </Grid>
                </Grid>
            </Box>
            
            {/* Dialog para seleccionar fecha de vigencia */}
            <Dialog
                open={dateDialog}
                onClose={() => { setDateDialog(false) }}>
                <DialogTitle>
                    Filtrar por fecha
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ border: '1px solid #e1e1e1', borderRadius: '3px' }}>
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => setRange([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={range}
                            locale={es}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ () => { setDateDialog(false) } }>Cancelar</Button>
                    <Button onClick={ filterByDate }>Seleccionar</Button>
                </DialogActions>
            </Dialog>
            <PromotionDialog
                currentPromotion={ currentPromotion }
                setActivePromotion={ setActivePromotion }
                setOtherPromotion={ setOtherPromotion }
                dialogStatus={ dialogStatus }
                setDialogStatus={ setDialogStatus }
            />
        </Container>
    );
};

export default Promotion;