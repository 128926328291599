// promoción compra A y B y recibe C
import React, { useState } from 'react';

import ProductSelect from './PromoTwo/ProductSelect';

import { prodPromo } from '../../../../constants/constants';
import { savePromoOne } from '../../../../actions/admin/promotion';
import { basicMsg, confirmDialog } from '../../../../actions/swal_msg';
import { getProductPromotions } from '../../../../helpers/admin/promotion/promotionHelper';

import {
    Typography, Grid, Stack, Card, CardMedia,
    CardActionArea, CardContent, Button, Alert
} from "@mui/material";
import StarPurple500Icon from '@mui/icons-material/StarPurple500';

const PromoTwo = ({
    promotion,
    activePromotion, setActivePromotion,
    otherPromotion, setOtherPromotion,
    today
}) => {
    const { promo, start_date, end_date } = promotion;
    // producto A seleccionado
    const [productA, setProductA] = useState(prodPromo);
    // producto B seleccionado
    const [productB, setProductB] = useState(prodPromo);
    // producto C seleccionado
    const [productC, setProductC] = useState(prodPromo);

    // verificar si productos tienen promoción
    const checkPromo = async () => {
        // mismos productos seleccionados
        if (productA.variant_id === productB.variant_id) basicMsg('Producto A y B son iguales');
        else if (productA.variant_id === productC.variant_id) basicMsg('Producto A y C son iguales');
        else if (productB.variant_id === productC.variant_id) basicMsg('Producto B y C son iguales');
        else {
            const ActivePromotions = getProductPromotions(promo, activePromotion, otherPromotion, { productA, productB, productC });
            if (ActivePromotions.status) {
                if (await confirmDialog('¿Registrar la promoción?', ActivePromotions.html, 'Registrar'))
                    savePromo();
            } else savePromo();
        }
    };

    // guardar promoción en BD
    const savePromo = async () => {
        const req = await savePromoOne({
            ...promotion,
            variant_a: {
                ...productA,
                _id: productA.variant_id,
            },
            variant_b: {
                ...productB,
                _id: productB.variant_id,
            },
            variant_c: {
                ...productC,
                _id: productC.variant_id,
            }
        }, 'save-promo-two');
        if (req.status) {
            // promoción activa
            if (start_date <= today && end_date >= today)
                setActivePromotion(e => ([
                    ...e,
                    {
                        ...promotion,
                        variant_a: {
                            ...productA,
                            _id: productA.variant_id,
                        },
                        variant_b: {
                            ...productB,
                            _id: productB.variant_id,
                        },
                        variant_c: {
                            ...productC,
                            _id: productC.variant_id,
                        },
                        _id: req.resp._id,
                    }
                ]));
            else // promoción guardada (no activa)
                setOtherPromotion(e => ([
                    ...e,
                    {
                        ...promotion,
                        variant_a: {
                            ...productA,
                            _id: productA.variant_id,
                        },
                        variant_b: {
                            ...productB,
                            _id: productB.variant_id,
                        },
                        variant_c: {
                            ...productC,
                            _id: productC.variant_id,
                        },
                        _id: req.resp._id,
                    }
                ]));
            // limpiar campos
            setProductA(prodPromo);
            setProductB(prodPromo);
            setProductC(prodPromo);
        }
    };

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{ mb: 2 }}>
                    <Typography variant="h6">
                        Promoción - En compra de producto A y B, producto C gratis
                    </Typography>
                    <StarPurple500Icon />
                </Stack>

                <Grid container spacing={4}>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        {
                            productA.variant_id !== '' &&
                            <>
                                <Card sx={{ width: '100%' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            sx={{ height: 200, backgroundSize: 'contain' }}
                                            image={productA.image_id}
                                            alt="product-A"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {`${productA.title} ${productA.variant_title}`.trim()}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Producto A seleccionado
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </>
                        }
                    </Grid>

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        {
                            productB.variant_id !== '' &&
                            <>
                                <Card sx={{ width: '100%' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            sx={{ height: 200, backgroundSize: 'contain' }}
                                            image={productB.image_id}
                                            alt="product-B"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {`${productB.title} ${productB.variant_title}`.trim()}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Producto B seleccionado
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </>
                        }
                    </Grid>

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        {
                            productC.variant_id !== '' &&
                            <>
                                <Card sx={{ width: '100%' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            sx={{ height: 200, backgroundSize: 'contain' }}
                                            image={productC.image_id}
                                            alt="product-C"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {`${productC.title} ${productC.variant_title}`.trim()}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Producto C seleccionado
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </>
                        }
                    </Grid>
                </Grid>

                <ProductSelect sx={{ my: 5 }}
                    text={'Producto A'}
                    setProduct={setProductA} />

                <ProductSelect sx={{ my: 5 }}
                    text={'Producto B'}
                    setProduct={setProductB} />

                <ProductSelect sx={{ my: 5 }}
                    text={'Producto C'}
                    setProduct={setProductC} />

                {
                    (productA.variant_id !== '' &&
                        productB.variant_id !== '' &&
                        productC.variant_id !== '') ?
                        <Button variant="contained"
                            onClick={ checkPromo }>
                            Guardar promoción
                        </Button>
                        :
                        <Alert severity="info" sx={{ mt: 5 }}>
                            Seleccione todos los productos para guardar la promoción
                        </Alert>
                }
            </Grid>
        </Grid>
    );
};

export default PromoTwo;