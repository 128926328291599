import React, { useEffect, useState } from 'react';

import './Branches.css'

//Router dom
import { Link } from 'react-router-dom';

import BranchList from './BranchList';
import EditBranch from './EditBranch/EditBranch';

import { branchList, deleteBranch } from '../../../actions/admin/branch';
import { simpleConfirmDialog } from '../../../actions/swal_msg';

import { BRANCH_INIT } from '../../../constants/admin/branch_constants';

//MUI
import {
    Container, Fab, Menu, MenuItem, Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Branches = () => {
    const [branches, setBranches] = useState([]);
    const [activeBranch, setActiveBranch] = useState(BRANCH_INIT);

    const { name } = activeBranch;

    //Menu opciones
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    //Modal para editar
    const [openModal, setOpenModal] = useState(false);
    const handleClickModal = () => {
        setOpenModal(true);
        setAnchorEl(null);
    };
    
    useEffect(() => {
        list();
    }, []);

    // cargar lista de sucursales
    const list = async () => {
        const req = await branchList();
        if (req.status)
        setBranches(req.resp);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // eliminar sucursal
    const deleteActiveBranch = async () => {
        handleClose();
        if (await simpleConfirmDialog(`¿Desea eliminar ${name}?`, 'Sí, continuar')) {
            const req = await deleteBranch(activeBranch);
            if (req.status) {
                setActiveBranch(BRANCH_INIT);
                setBranches(e => e.filter(branch => branch._id !== activeBranch._id));
            }
        }
    };

    return (
        <>
            <Container maxWidth='xl' sx={{ mb: 5 }} style={{ marginTop: '90px' }}>
                <Typography variant='h5' paragraph>Lista de sucursales</Typography>
                <BranchList
                    branches={ branches }
                    setAnchorEl={ setAnchorEl }
                    setActiveBranch={ setActiveBranch }
                />
            </Container>

            {/* Menu */}
            <Menu
                id="branches-menu"
                anchorEl={ anchorEl }
                open={ open }
                onClose={ handleClose }
            >
                <MenuItem
                   onClick={ handleClickModal }>
                    Editar
                </MenuItem>
                <MenuItem onClick={ deleteActiveBranch }>
                    Eliminar sucursal
                </MenuItem>
            </Menu>
        
            <Fab
                LinkComponent={Link}
                to='/nefro-admin/nueva-sucursal'
                color="primary"
                sx={{
                    position: "fixed",
                    bottom: '35px',
                    right: '35px',
                    '&:hover': {
                        color: '#fff',
                    }
                }}
            >
                <AddIcon />
            </Fab>
        
            <EditBranch
                openModal={ openModal }
                setOpenModal={ setOpenModal }
                activeBranch={ activeBranch }
                setActiveBranch={ setActiveBranch }
                setBranches={ setBranches }
            />
        </>
    );
};
    
export default Branches;