import React, { useState } from "react";
import { format } from 'date-fns';

import TopDia from "./TopDia";
import TopAnual from "./TopAnual";
import TopSemanal from "./TopSemanal";
import TopMensual from "./TopMensual";
import TopQuincenal from "./TopQuincenal";
import TopTrimestral from "./TopTrimestral";
import TopYearToDate from "./TopYearToDate";
import TopFiltrado from "./TopFiltrado";

import { PRODUCT_MENU } from "../../../../constants/admin/dashboard_constants";

import {
    AppBar, Card, CardContent, Grid, Toolbar, Typography,
    Button, Menu, MenuItem, Fade, Box, Chip
} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const TopProductos = ({
    defaultRange: { startDate, endDate },
    topProductsData: {
        todayData, weekData, fortnightData,
        monthData, quarterData, yearToDateData,
        annualData, filteredData
    }
}) => {
    // menú filtro
    const [menu, setMenu] = useState(PRODUCT_MENU);
    const {
        dia, semanal, quincenal, mensual, trimestral,
        yearToDate, anual, filtrado
    } = menu;

    //Dropdown
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenu = opt => () => {
        let obj = PRODUCT_MENU;
        for (const [key] of Object.entries(menu)) {
            obj[key] = key === opt ? true : false;
        };
        setMenu(e => ({ ...e, ...obj }));
        setAnchorEl(null);
    };

    return (
        <Grid item xl={5} lg={5} md={8} sm={12} xs={12}
            sx={{ mx: 'auto' }}>
            <Card sx={{
                width: '100%', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
            }}>
                <AppBar position="static" sx={{ bgcolor: '#fff' }} elevation={0}>
                    <Toolbar>
                        <Typography variant="body1" sx={{ flexGrow: 1, color: '#000', fontWeight: '600' }}>
                            Top 10 productos
                        </Typography>
                        <Button
                            color='primary'
                            onClick={handleClick}
                            startIcon={<FilterAltIcon />}
                        >
                            Filtrar
                        </Button>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={ handleMenu('dia') }>Dia</MenuItem>
                            <MenuItem onClick={ handleMenu('semanal') }>Semanal</MenuItem>
                            <MenuItem onClick={ handleMenu('quincenal') }>Quincenal</MenuItem>
                            <MenuItem onClick={ handleMenu('mensual') }>Mensual</MenuItem>
                            <MenuItem onClick={ handleMenu('trimestral') }>Trimestral</MenuItem>
                            <MenuItem onClick={ handleMenu('yearToDate') }>Year to date</MenuItem>
                            <MenuItem onClick={ handleMenu('anual') }>Anual</MenuItem>
                            <MenuItem onClick={ handleMenu('filtrado') }>Entre fechas...</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <CardContent>
                    <Dia dia={dia} todayData={todayData} />
                    <Semanal semanal={semanal} weekData={weekData} />
                    <Quincenal quincenal={quincenal} fortnightData={fortnightData} />
                    <Mensual mensual={mensual} monthData={monthData} />
                    <Trimestral trimestral={trimestral} quarterData={quarterData} />
                    <YearToDate yearToDate={yearToDate} yearToDateData={yearToDateData} />
                    <Anual anual={anual} annualData={annualData} />
                    <Filtrado dropdown={filtrado} data={filteredData}
                        startDate={startDate} endDate={endDate} />
                </CardContent>
            </Card>
        </Grid>
    );
};

const Dia = ({ dia, todayData }) => {
    if (!dia) return null;
    return (
        <>
            <Box sx={{ mb: 3 }}>
                <Chip
                    label="Día"
                    size="small"
                    color='primary'
                    sx={{ borderRadius: '6px', p: 1, fontWeight: 'bold' }}
                />
            </Box>
            <TopDia todayData={ todayData } />
        </>
    );
};
const Semanal = ({ semanal, weekData }) => {
    if (!semanal) return null;
    return (
        <>
            <Box sx={{ mb: 3 }}>
                <Chip
                    label="Semanal"
                    size="small"
                    color='primary'
                    sx={{ borderRadius: '6px', p: 1, fontWeight: 'bold' }}
                />
            </Box>
            <TopSemanal weekData={ weekData } />
        </>
    );
};
const Quincenal = ({ quincenal, fortnightData }) => {
    if (!quincenal) return null;
    return (
        <>
            <Box sx={{ mb: 3 }}>
                <Chip
                    label="Quincenal"
                    size="small"
                    color='primary'
                    sx={{ borderRadius: '6px', p: 1, fontWeight: 'bold' }}
                />
            </Box>
            <TopQuincenal fortnightData={ fortnightData } />
        </>
    );
};
const Mensual = ({ mensual, monthData }) => {
    if (!mensual) return null;
    return (
        <>
            <Box sx={{ mb: 3 }}>
                <Chip
                    label="Mensual"
                    size="small"
                    color='primary'
                    sx={{ borderRadius: '6px', p: 1, fontWeight: 'bold' }}
                />
            </Box>
            <TopMensual monthData={ monthData } />
        </>
    );
};
const Trimestral = ({ trimestral, quarterData }) => {
    if (!trimestral) return null;
    return (
        <>
            <Box sx={{ mb: 3 }}>
                <Chip
                    label="Trimestral"
                    size="small"
                    color='primary'
                    sx={{ borderRadius: '6px', p: 1, fontWeight: 'bold' }}
                />
            </Box>
            <TopTrimestral quarterData={ quarterData } />
        </>
    );
};
const YearToDate = ({ yearToDate, yearToDateData }) => {
    if (!yearToDate) return null;
    return (
        <>
            <Box sx={{ mb: 3 }}>
                <Chip
                    label="Year to date"
                    size="small"
                    color='primary'
                    sx={{ borderRadius: '6px', p: 1, fontWeight: 'bold' }}
                />
            </Box>
            <TopYearToDate yearToDateData={ yearToDateData } />
        </>
    )
}
const Anual = ({ anual, annualData }) => {
    if (!anual) return null;
    return (
        <>
            <Box sx={{ mb: 3 }}>
                <Chip
                    label="Anual"
                    size="small"
                    color='primary'
                    sx={{ borderRadius: '6px', p: 1, fontWeight: 'bold' }}
                />
            </Box>
            <TopAnual annualData={ annualData } />
        </>
    );
};
const Filtrado = ({ dropdown, data, startDate, endDate }) => {
    if (!dropdown) return null;
    return (
        <>
            <Box sx={{ mb: 3 }}>
                <Chip
                    label={
                        `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}`
                    }
                    size="small"
                    color='primary'
                    sx={{ borderRadius: '6px', p: 1 }}
                />
            </Box>
            <TopFiltrado data={ data } />
        </>
    );
};

export default TopProductos;