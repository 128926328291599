import { protectedReq, request } from "../helpers/requestHelper";
import { types } from "../types/types";
import { basicMsg, valMsg } from "./swal_msg";

export const myOrders = () => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('account/my-orders');
            const body = await resp.json();
            if (body.status) {
                const { orders, buyAgainProducts } = body.resp;
                dispatch(ordersLoaded({
                    orders, buyAgainProducts
                }));
            } else valMsg('warning', 'Mis pedidos',
                'Recargue la página para ver los pedidos');
        } catch (err) {
            valMsg('error', 'Mis pedidos',
            'No se pudieron cargar los pedidos');
        }
    }
};

// pedidos del usuario fueron recuperados
const ordersLoaded = (payload) => ({
    type: types.userOrdersLoaded,
    payload
});

// pedido fue seleccionado
export const orderSelected = (payload) => ({
    type: types.userOrderSelected,
    payload
});

// verificar contraseña de usuario en /informacion-y-seguridad
export const verifiyPwd = async(model) => {
    try {
        const resp = await request('account/verify-pwd', model, 'POST');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'not-match')
                alert("Las contraseñas no coinciden");
            else if (body.msg === 'not-possible')
                alert("No es posible cambiar la información de su cuenta");
            else
                alert("No se pudo verificar la contraseña");
        }
        return body.status;
    } catch (err) {
        alert("Ocurrió un incidente");
        return false;
    }
};

// actualizar contraseña de usuario en /informacion-y-seguridad
export const updatePwd = async(model) => {
    try {
        const resp = await request('account/update-pwd', model, 'POST');
        const body = await resp.json();
        if (body.status)
            valMsg('success', 'Contraseña actualizada',
            'Ha actualizado su contraseña con éxito');
        else {
            if (body.msg === 'not-found')
                valMsg('warning', 'Actualizar contraseña',
                'No se encontró su usuario');
            else if (body.msg === 'not-match')
                valMsg('warning', 'Actualizar contraseña',
                'La contraseña no coincide');
            else if (body.msg === 'server-err')
                valMsg('error', 'Actualizar contraseña',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Actualizar contraseña',
                'Inténtelo de nuevo, por favor');
        }
        return body.status;
    } catch (err) {
        valMsg('error', 'Actualizar contraseña',
        'No se pudo actualizar la contraseña');
        return false;
    }
};

export const updateMyData = async(model) => {
    try {
        const resp = await request('account/update-data', model, 'POST');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'not-found')
                valMsg('warning', 'Mi cuenta',
                'No se encontró su cuenta');
            else if (body.msg === 'not-possible')
                valMsg('warning', 'Mi cuenta',
                'No puede actualizar la información de su cuenta');
            else if (body.msg === 'email-exists')
                valMsg('warning', 'Actualizar contraseña',
                'LEl correo que desea utilizar ya está en uso');
            else if (body.msg === 'server-err')
                valMsg('error', 'Mi cuenta',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Mi cuenta',
                'Inténtelo de nuevo, por favor');
        }
        return body.status;
    } catch (err) {
        valMsg('error', 'Mi cuenta',
        'No se pudo actualizar tu información');
        return false;
    }
};

export const myShippingAddresses = async() => {
    try {
        const resp = await protectedReq('account/shipping-addresses');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'server-err')
                valMsg('error', 'Direcciones de envío',
                'Ocurrió un incidente. Intente de nuevo, por favor');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Direcciones de envío',
        'No se pueden mostrar sus direcciones');
        return { status: false };
    }
};

export const addShippingAddress = async(model) => {
    try {
        const { _id, ...address } = model;
        const resp = await protectedReq('account/add-shipping-address', address, 'POST');
        const body = await resp.json();
        if (body.status)
            basicMsg('Dirección de envío guardada');
        else {
            if (body.msg === 'not-saved')
                valMsg('warning', 'Dirección de envío',
                'No se guardó su dirección. Intente de nuevo, por favor');
            else if (body.msg === 'server-err')
                valMsg('error', 'Dirección de envío',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Dirección de envío',
                'Dirección no guardada. Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Direcciones de envío',
        'No se pudo guardar su dirección');
        return { status: false };
    }
};

export const updShippingAddress = async(model) => {
    try {
        const resp = await protectedReq('account/upd-shipping-address', model, 'PUT');
        const body = await resp.json();
        if (body.status)
            basicMsg('Dirección de envío actualizada');
        else {
            if (body.msg === 'not-saved')
                valMsg('warning', 'Dirección de envío',
                'No se actualizó su dirección. Intente de nuevo, por favor');
            else if (body.msg === 'server-err')
                valMsg('error', 'Dirección de envío',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Dirección de envío',
                'Dirección no actualizada. Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Dirección de envío',
        'No se pudo actualizar su dirección');
        return false;
    }
};

export const delShippingAddress = async(_id) => {
    try {
        const resp = await protectedReq('account/del-shipping-address', { _id }, 'DELETE');
        const body = await resp.json();
        if (body.status)
            basicMsg('Dirección de envío eliminada');
        else {
            if (body.msg === 'not-deleted')
                valMsg('warning', 'Dirección de envío',
                'No se eliminó su dirección. Intente de nuevo, por favor');
            else if (body.msg === 'server-err')
                valMsg('error', 'Dirección de envío',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Dirección de envío',
                'Dirección no eliminada. Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Dirección de envío',
        'No se pudo eliminar su dirección');
        return false;
    }
};