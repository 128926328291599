import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { msg } from '../../../../actions/swal_msg';
import { generateURL } from '../../../../helpers/admin/productHelper';
import { productCloseModal, productUpdate } from '../../../../actions/product';

import ProductFBT from '../NewProduct/FBT/ProductFBT';
import ModalCategorySelect from './ModalCategorySelect';
import ProductKeywords from '../NewProduct/Keywords/ProductKeywords';

import {
  Typography, TextField, Button, ToggleButton, FormGroup,
  FormControlLabel, Checkbox, Divider, Chip, Container,
  Box, Switch, Dialog, AppBar, Toolbar, IconButton
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ProductModal() {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:600px)');
  // lista de categorías
  const { categories } = useSelector(state => state.category);
  const { products, active, modal } = useSelector(state => state.product);

  // valor de producto
  const [prodValues, setProdValues] = useState(active);
  const {
    _id: prod_id, title, url, info,
    keywords, featured, refrigerated,
    medicine, fbt_item
  } = prodValues;
  
  // categorías activas
  const [activeCategories, setActiveCategories] = useState([]);
  // categorías no registradas del producto
  const [otherCategories, setOtherCategories] = useState([]);

  // variantes como producto único (se utiliza en ProductFBT.js)
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    let items = [...fbt_item];
    // tomar cada variante como producto único
    if (products.length > 0) {
      let Products = [];
      for (const e of products) { // productos
        const { prod_variants } = e;
        if (prod_id !== e._id) { // no mostrar producto actual como opción
          for (const f of prod_variants) { // variantes
            const checked = items.some(item =>
              item.prod_id === e._id && item.prod_var_id === f._id
            );
            // es producto comprado frecuentemente
            if (checked) {
              Products = [...Products, {
                prod_id: e._id,
                prod_var_id: f._id,
                title: `${e.title} ${f.variant_title}`.trim(),
                checked
              }];
              // quitar elemento de arreglo
              const i = items.findIndex(item =>
                item.prod_id === e._id && item.prod_var_id === f._id
              );
              items.splice(i, 1);
            } else
              Products = [...Products, {
                prod_id: e._id,
                prod_var_id: f._id,
                title: `${e.title} ${f.variant_title}`.trim(),
                checked
              }];
          }
        }
      };
      setAllProducts(Products);
    }
  }, [prod_id]);

  useEffect(() => {
    setProdValues(active);
    setOtherCategories(active.category.map(e => ({ category: e, check: true })));
    // se establecen las categorías del producto
    if (categories.length > 0 && active.category.length > 0) {
      const resp = categories.map(item => {
        if (active.category.some(e => item.category === e)) {
          setOtherCategories(a => (a.filter(e => e.category !== item.category)));
          return { ...item, check: true };
        } else return { ...item, check: false };
      });
      setActiveCategories(resp);
    }
  }, [active]);
  
  useEffect(() => {
    if (categories.length > 0 && active.category.length > 0) {
      const resp = categories.map(item => ({
        ...item, check: active.category.some(cat => item._id === cat._id)
      }));
      setActiveCategories(resp);
    }
  }, [categories]);

  // título, url, información
  const handleInputChange = ({ target }) => {
    const name = target.name;
    const value = target.value;
    if (name === 'title') {
      const _url = generateURL(value);
      setProdValues({
        ...prodValues,
        title: value,
        url: _url
      });
    } else {
      setProdValues({
        ...prodValues,
        [name]: value
      });
    }
  };

  // ¿producto destacado?
  const handleToggleChange = ({ target }) => {
    const _name = target.name;
    if (_name === 'featured')
      setProdValues({
        ...prodValues,
        featured: !featured
      });
    else if (_name === 'refrigerated')
      setProdValues({
        ...prodValues,
        refrigerated: target.checked
      });
    else if (_name === 'medicine')
      setProdValues({
        ...prodValues,
        medicine: target.checked
      });
  };

  const onCheckboxChange = _category => ({ target }) => {
    setOtherCategories(a => (
      a.map(e => e.category === _category ?
        { ...e, check: target.checked } : e
      )
    ));
  };

  // cerrar modal
  const handleClose = () => {
    dispatch(productCloseModal());
  };

  // actualizar producto
  const update = () => {
    if (formValidator()) {
      // productos comprados juntos frecuentemente
      // filtrar productos seleccionados
      const fbt_item = allProducts.filter(e => e.checked).map(u => {
        const { prod_id, prod_var_id } = u;
        return { prod_id, prod_var_id };
      });
      dispatch(productUpdate({
        ...prodValues, fbt_item
      }, otherCategories, activeCategories));
    }
  };

  // validación básica de formulario
  const formValidator = () => {
    const title_len = title.trim().length;
    // const info_len = info.trim().length;
    const url_len = url.trim().length;
    let active_count = 0;
    activeCategories.forEach(e => {
      if (e.check) active_count++;
    });
    if (title_len > 1 && title_len <= 100
      && url_len > 1 && url_len <= 100
      && active_count > 0)
      return true;
    else msg('warning', 'Actualización producto',
      'Complete los datos del producto');
    return false;
  };

  return (
    <Dialog
      fullScreen
      open={modal}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ zIndex: 1000 }}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Editar producto
          </Typography>
          <Button variant="outlined" color="inherit" onClick={update}>
            Actualizar
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth='xl' sx={{ mt: 5, mb: 2 }}>
        {/* Titulo */}
        <Box sx={{ mb: 3 }}>
          <TextField fullWidth
            name="title"
            label="Título"
            variant="standard"
            value={title}
            inputProps={{ maxLength: 200 }}
            onChange={handleInputChange} />
        </Box>

        {/* Información adicional */}
        <Box sx={{ mb: 3 }}>
          <TextField fullWidth
            name="info"
            label="Información adicional"
            variant="standard"
            multiline
            rows={4}
            value={info}
            inputProps={{ maxLength: 2000 }}
            onChange={handleInputChange} />
        </Box>

        {/* Categorías */}
        <Box sx={{ mb: 3 }}>
          <ModalCategorySelect
            activeCategories={activeCategories}
            setActiveCategories={setActiveCategories} />
        </Box>

        <Divider>
          <Chip label="Categorías en producto" sx={{ fontSize: 16 }} />
        </Divider>
        <FormGroup>
          {
            otherCategories.map(({ category, check }, i) =>
              <FormControlLabel key={i}
                control={
                  <Checkbox checked={check}
                    onChange={onCheckboxChange(category)} />
                }
                label={category} />
            )
          }
        </FormGroup>

        {/* Palabras clave */}
        <Box sx={{ mb: 3 }}>
          <ProductKeywords
            setProdValues={setProdValues}
            keywords={keywords} />
        </Box>

        {/* producto destacado */}
        <Box sx={{ mb: 3 }}>
          <ToggleButton fullWidth={matches ? true : false}
            name="featured"
            value="check"
            selected={featured}
            onChange={handleToggleChange}>
            {
              featured ? <>Destacado<StarIcon sx={{ color: '#ffd600' }} /></> :
                <>No destacado<StarBorderIcon /></>
            }
          </ToggleButton>
        </Box>

        {/* necesita refrigeración */}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="refrigerated"
                checked={refrigerated}
                onChange={handleToggleChange} />}
                label="Necesita refrigeración" />
        </FormGroup>

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="medicine"
                checked={medicine}
                onChange={handleToggleChange} />}
                label="Es medicamento" />
        </FormGroup>

        {/* Comprados juntos frecuentemente */}
        <ProductFBT
          allProducts={ allProducts }
          setAllProducts={ setAllProducts } />
      </Container>
    </Dialog>
  );
};