import React, { useState } from "react";

//Router-dom
import { Link } from 'react-router-dom';

//CSS
import './landingpages.css'

//images
import nefropolis_logo from '../../assets/images/Logo_Nefropolis.png'

//MUI
import { Box, Button, Container, Tooltip, Typography, useMediaQuery  } from "@mui/material";

const NRComponent = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    const text = 'NFPS_NR'

    const CopyToclipboard = () => {
        navigator.clipboard.writeText(text);
        setCopy('Cupón copiado!!')
        setTimeout(() => {
            setCopy('Copiar')
        }, 2500)
    }

    const [copy, setCopy] = useState('Copiar')

    return (
        <>
            <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18 }}>
                <Typography variant="h4" align='center' sx={{ fontWeight: '600', textTransform: 'uppercase' }}>Asesoria en nutrición renal</Typography>
            </Container>

            <Container maxWidth="xl" sx={{ mt: 5 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <Box sx={{ my: 'auto', textAlign: 'center', pt: 3 }}>
                        <img src={nefropolis_logo} width="70%" alt="Nefrópolis" />
                    </Box>

                    <Box sx={{ my: 'auto', textAlign: 'center', pt: 3 }}>
                        <img src="https://nutricionrenal.com/assets/Images/Nutricion_Renal.png" width="60%"
                            alt="Nutrición Renal" />
                    </Box>
                </Box>
            </Container>

            <Container maxWidth="xl" sx={{ mt: 6 }} className="last-view">

                <Typography variant="body1" paragraph>
                    Nos unimos con <b style={{ color: '#8bc53f' }}>NUTRICIÓN RENAL</b> para ofrecerte un descuento
                    en tu 1er consulta y que puedas tener más herramientas para alargar la vida de tus riñones.
                </Typography>
                <Typography variant="body1" paragraph>Obtén 10% de descuento al agendar en línea. Ingresando el siguiente cupón👇</Typography>

                <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center' }}>
                    <div className="border-nr">
                        <Box
                            sx={{
                                display: 'flex', flexWrap: 'wrap',
                                justifyContent: { xl: 'space-between', lg: 'space-between', md: 'center', sm: 'center', xs: 'center' }
                            }}
                        >
                            <Box sx={{ my: 'auto' }}>
                                <h4 style={{ marginBottom: 0 }}>Cupón: <b id="textToCopy">{text}</b></h4>
                            </Box>
                            <Box sx={{ my: 'auto', display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' } }}>
                                <Tooltip title={copy} placement="top" arrow disableFocusListener>
                                    <Link to={""} id="copyButton"
                                        className="text-primary"
                                        style={{ cursor: 'pointer', fontSize: '18px' }}
                                        onClick={CopyToclipboard}
                                    >
                                        <i className="fas fa-copy"></i>
                                    </Link>
                                </Tooltip>
                            </Box>
                        </Box>
                    </div>
                </Box>
                <Box sx={{ mt: 6 }}>
                    <img
                        src="https://nutricionrenal.com/assets/Images/Recurso%208.png"
                        width="100%"
                        alt="NR"
                    />
                    <Box sx={{ mt: 3, textAlign: 'center' }}>
                        <a href="https://nutricionrenal.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                            <Button variant="contained" color="success" sx={{ width: '350px', height: '55px' }}>Canjear la oferta</Button>
                        </a>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default NRComponent