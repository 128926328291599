import React, {useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { updDoctor } from '../../../../actions/admin/doctor';
import { DOCTOR_FIELDS_ERR_1 } from '../../../../constants/admin/doctor_constants';

//MUI
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    Box, Grid, FormControl, TextField, Avatar, Typography
} from '@mui/material'
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const name_regex = /^([^0-9]*)$/;
const email_regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const tel_regex = /^[0-9]*$/;

const EditDirectory = ({ openModal, setOpenModal, activeDoctor, setValues }) => {
    const dispatch = useDispatch();
    // valores
    const {
        name, lastname, email, phone, ced, speciality,
        sub_speciality, hospital, hospital_type, state,
        mun, colony
    } = activeDoctor;
    // errores 
    const [inputErr, setInputErr] = useState(DOCTOR_FIELDS_ERR_1);
    const {
        name_err, lastname_err, email_err, phone_err, ced_err,
        speciality_err, sub_speciality_err, hospital_err,
        hospital_type_err, state_err, mun_err, colony_err
    } = inputErr;

    const name_ref = useRef();
    const lastname_ref = useRef();
    const email_ref = useRef();
    const phone_ref = useRef();
    const ced_ref = useRef();
    const speciality_ref = useRef();
    const sub_speciality_ref = useRef();
    const hospital_ref = useRef();
    const hospital_type_ref = useRef();
    const state_ref = useRef();
    const mun_ref = useRef();
    const colony_ref = useRef();

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'name' || name === 'lastname') {
            if (value === '' || name_regex.test(value)) {
                setValues(e => ({
                    ...e,
                    [name]: value
                })
                );
            }
        } else if (name === 'phone') {
            if (value === '' || tel_regex.test(value)) {
                setValues(e => ({
                    ...e,
                    [name]: value
                })
                );
            }
        } else setValues(e => ({
            ...e,
            [name]: value
        })
        );
        switch (name) {
            case 'name':
                setInputErr({
                    ...inputErr,
                    name_err: (value.length < 2 ||
                        value.length > 150) ? true : false
                });
                break;
            case 'lastname':
                setInputErr({
                    ...inputErr,
                    lastname_err: (value.length < 2 ||
                        value.length > 255) ? true : false
                });
                break;
            case 'email':
                setInputErr({
                    ...inputErr,
                    email_err: (value.length < 3 ||
                        value.length > 150) ? true : false
                });
                break;
            case 'phone':
                setInputErr({
                    ...inputErr,
                    phone_err: (value.length < 6 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'ced':
                setInputErr({
                    ...inputErr,
                    ced_err: (value.length < 2 ||
                        value.length > 30) ? true : false
                });
                break;
            case 'speciality':
                setInputErr({
                    ...inputErr,
                    speciality_err: (value.length < 2 ||
                        value.length > 150) ? true : false
                });
                break;
            case 'sub_speciality':
                setInputErr({
                    ...inputErr,
                    sub_speciality_err: (value.length < 2 ||
                        value.length > 150) ? true : false
                });
                break;
            case 'hospital':
                setInputErr({
                    ...inputErr,
                    hospital_err: (value.length < 2 ||
                        value.length > 255) ? true : false
                });
                break;
            case 'hospital_type':
                setInputErr({
                    ...inputErr,
                    hospital_type_err: (value.length < 2 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'state':
                setInputErr({
                    ...inputErr,
                    state_err: (value.length < 2 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'mun':
                setInputErr({
                    ...inputErr,
                    mun_err: (value.length < 2 ||
                        value.length > 255) ? true : false
                });
                break;
            case 'colony':
                setInputErr({
                    ...inputErr,
                    colony_err: (value.length < 2 ||
                        value.length > 255) ? true : false
                });
                break;
            default:
                break;
        }
    };

    const [file, setFile] = useState();
    const handleChange = (e) => {
        setFile(URL.createObjectURL(e.target.files[0]));
    };

    // const handleDelete = () => {
    //     setFile();
    // }

    const formValidator = () => {
        const name_len = name.trim().length;
        if (name_len > 1 && name_len <= 150) {
            const lastname_len = lastname.trim().length;
            if (lastname_len > 1 && lastname_len <= 255) {
                const email_len = email.trim().length;
                if (email_len > 2 && email_len <= 150 && email_regex.test(email)) {
                    const phone_len = phone.trim().length;
                    if (phone_len > 5 && phone_len <= 100) {
                        const ced_len = ced.trim().length;
                        if (ced_len > 1 && ced_len <= 30) {
                            const speciality_len = speciality.trim().length;
                            if (speciality_len > 1 && speciality_len <= 150) {
                                const sub_speciality_len = sub_speciality.trim().length;
                                if (sub_speciality_len > 1 && sub_speciality_len <= 150) {
                                    const hospital_len = hospital.trim().length;
                                    if (hospital_len > 1 && hospital_len <= 255) {
                                        const hospital_type_len = hospital_type.trim().length;
                                        if (hospital_type_len > 1 && hospital_type_len <= 100) {
                                            const state_len = state.trim().length;
                                            if (state_len > 1 && state_len <= 100) {
                                                const mun_len = mun.trim().length;
                                                if (mun_len > 1 && mun_len <= 255) {
                                                    const colony_len = colony.trim().length;
                                                    if (colony_len > 1 && colony_len <= 255) {
                                                        return true;
                                                    } else {
                                                        colony_ref.current.select();
                                                        setInputErr({
                                                            ...inputErr,
                                                            colony_err: true
                                                        });
                                                    }
                                                } else {
                                                    mun_ref.current.select();
                                                    setInputErr({
                                                        ...inputErr,
                                                        mun_err: true
                                                    });
                                                }
                                            } else {
                                                state_ref.current.select();
                                                setInputErr({
                                                    ...inputErr,
                                                    state_err: true
                                                });
                                            }
                                        } else {
                                            hospital_type_ref.current.select();
                                            setInputErr({
                                                ...inputErr,
                                                hospital_type_err: true
                                            });
                                        }
                                    } else {
                                        hospital_ref.current.select();
                                        setInputErr({
                                            ...inputErr,
                                            hospital_err: true
                                        });
                                    }
                                } else {
                                    sub_speciality_ref.current.select();
                                    setInputErr({
                                        ...inputErr,
                                        sub_speciality_err: true
                                    });
                                }
                            } else {
                                speciality_ref.current.select();
                                setInputErr({
                                    ...inputErr,
                                    speciality_err: true
                                });
                            }
                        } else {
                            ced_ref.current.select();
                            setInputErr({
                                ...inputErr,
                                ced_err: true
                            });
                        }
                    } else {
                        phone_ref.current.select();
                        setInputErr({
                            ...inputErr,
                            phone_err: true
                        });
                    }
                } else {
                    email_ref.current.select();
                    setInputErr({
                        ...inputErr,
                        email_err: true
                    });
                }
            } else {
                lastname_ref.current.select();
                setInputErr({
                    ...inputErr,
                    lastname_err: true
                });
            }
        } else {
            name_ref.current.select();
            setInputErr({
                ...inputErr,
                name_err: true
            });
        }
    }

    const submit = (e) => {
        e.preventDefault();
        if (formValidator()) {
            dispatch(updDoctor(activeDoctor));
        }
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='lg'
        >
            <DialogTitle>
                Editar médico
            </DialogTitle>
            <DialogContent>
                <Box textAlign='center' mb={3}>
                    <input
                        accept="image/*"
                        type="file"
                        id="select-image_directory"
                        multiple
                        name="images"
                        style={{ display: 'none' }} onChange={handleChange}
                    />
                    <label htmlFor="select-image_directory">
                        <Avatar src={file}
                            sx={{
                                width: '140px', height: '140px', cursor: 'pointer', bgcolor: '#fff',
                                boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)'
                            }}
                        >
                            <CameraAltIcon fontSize='large' sx={{ color: '#000' }} />
                        </Avatar>
                    </label>
                </Box>

                <Typography variant='body1' gutterBottom sx={{ fontWeight: '600' }}>Información personal</Typography>
                <Grid container spacing={2}>
                    {/* Nombre */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label='Nombre'
                                size='small'
                                name="name"
                                error={name_err}
                                helperText={name_err ? "Ingrese el nombre" : ""}
                                inputRef={name_ref}
                                value={name}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* Apellidos */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label='Apellidos'
                                size='small'
                                name="lastname"
                                error={lastname_err}
                                helperText={lastname_err ? "Ingrese los apellidos" : ""}
                                inputRef={lastname_ref}
                                value={lastname}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* e-mail */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label='Correo electrónico'
                                size='small'
                                name="email"
                                error={email_err}
                                helperText={email_err ? "Ingrese el correo electrónico" : ""}
                                inputRef={email_ref}
                                value={email}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* teléfono */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label='Teléfono'
                                size='small'
                                name="phone"
                                error={phone_err}
                                helperText={phone_err ? "Ingrese el número de teléfono" : ""}
                                inputRef={phone_ref}
                                value={phone}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography variant='body1' gutterBottom sx={{ fontWeight: '600', mt: 3 }}>Información médica</Typography>
                <Grid container spacing={2}>
                    {/* Cedula */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label='Cédula profesional'
                                size='small'
                                name="ced"
                                error={ced_err}
                                helperText={ced_err ? "Ingrese la cédula profesional" : ""}
                                inputRef={ced_ref}
                                value={ced}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* especialidad */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label='Especialidad'
                                size='small'
                                name="speciality"
                                error={speciality_err}
                                helperText={speciality_err ? "Ingrese la especialidad" : ""}
                                inputRef={speciality_ref}
                                value={speciality}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* subespecialidad */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label='Subespecialidad'
                                size='small'
                                name="sub_speciality"
                                error={sub_speciality_err}
                                helperText={sub_speciality_err ? "Ingrese la sub-especialidad" : ""}
                                inputRef={sub_speciality_ref}
                                value={sub_speciality}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* hospital */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label='Hospital'
                                size='small'
                                name="hospital"
                                error={hospital_err}
                                helperText={hospital_err ? "Ingrese el hospital" : ""}
                                inputRef={hospital_ref}
                                value={hospital}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* tipo hospital */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label='Tipo hospital'
                                size='small'
                                name="hospital_type"
                                error={hospital_type_err}
                                helperText={hospital_type_err ? "Ingrese el tipo de hospital" : ""}
                                inputRef={hospital_type_ref}
                                value={hospital_type}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* estado */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label='Estado'
                                size='small'
                                name="state"
                                error={state_err}
                                helperText={state_err ? "Ingrese el estado" : ""}
                                inputRef={state_ref}
                                value={state}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* municipio */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label='Municipio'
                                size='small'
                                name="mun"
                                error={mun_err}
                                helperText={mun_err ? "Ingrese el municipio" : ""}
                                inputRef={mun_ref}
                                value={mun}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    {/* Colonia */}
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                label='Colonia'
                                size='small'
                                name="colony"
                                error={colony_err}
                                helperText={colony_err ? "Ingrese la colonia" : ""}
                                inputRef={colony_ref}
                                value={colony}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cerrar</Button>
                <Button onClick={submit} autoFocus>
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditDirectory;