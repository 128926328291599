import React, { useState, useEffect } from 'react'

//Components
import SucursalesIndex from '../SucursalesIndex/SucursalesIndex'

//MUI
import { Box, Divider, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';

const estadosArray = [
    'Chiapas', 'Ciudad de México', 'Estado de México', 'Guanajuato', 'Jalisco', 'Nuevo León', 'Oaxaca', 'Puebla', 'Querétaro', 
    'Sonora', 'Tlaxcala'
]

const SucursalesIndexList = ({ data }) => {

    const [item, setItem] = useState(data)
    const [stateInput, setStateInput] = useState('');
    const [resultsFound, setResultsFound] = useState(true);

    const handleStateChange = (e) => {
        setStateInput(e.target.value)
    }

    const applyFilters = () => {
        let updatedList = data;

        if (stateInput) {
            updatedList = updatedList.filter(
                (item) =>
                    item.state === stateInput
            );
        }

        setItem(updatedList);

        !updatedList.length ? setResultsFound(false) : setResultsFound(true);
    };

    useEffect(() => {
        applyFilters();
    }, [stateInput])

    const handleClear = () => {
        setItem(data)
        setStateInput('')
    }

    return (
        <>
            {/* <FormControl fullWidth sx={{ mb: 2 }}>
                <TextField
                    className={classes.root}
                    value={stateInput}
                    onChange={handleStateChange}
                    label='Seleccione su estado'
                    select
                    InputProps={{ sx: { borderRadius: '17px' } }}
                    endAdornment={<IconButton sx={{display: stateInput ? "": "none"}} onClick={handleClear}><ClearIcon/></IconButton>}
                >
                    {
                        estadosArray.map((res, index) =>
                            <MenuItem key={index}
                                value={res}>
                                {res}
                            </MenuItem>
                        )
                    }
                </TextField>
            </FormControl> */}
            <Box className='index_select'>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Seleccione su estado</InputLabel>
                    <Select
                        label='Seleccione su estado'
                        value={stateInput}
                        onChange={handleStateChange}
                        displayEmpty
                        sx={{
                            "& .MuiSelect-iconOutlined": { display: stateInput ? 'none' : '', color: '#56C596' },
                            "&.Mui-focused .MuiIconButton-root": { color: '#56C596' },
                        }}
                        endAdornment={<IconButton sx={{ visibility: stateInput ? "visible" : "hidden" }} onClick={handleClear}><ClearIcon /></IconButton>}
                    >
                        {
                            estadosArray.map((res, index) =>
                                <MenuItem key={index}
                                    value={res}>
                                    {res}
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ p: 2, border: '1px solid #56C596', borderRadius: '20px', boxShadow: 'rgba(50, 157, 156, 0.35) 0px 5px 15px' }}>
                <div className="scroll">
                    {
                        resultsFound
                            ?
                            <>
                                {
                                    item.map((res) => (
                                        <div key={res.id}>
                                            <SucursalesIndex res={res} />
                                            <Divider sx={{ my: 2.5 }} />
                                        </div>
                                    ))
                                }
                            </>
                            :
                            <>no hay resultados</>
                    }
                </div>
            </Box>
            {/* <Button onClick={handleClear}>Borrar</Button> */}
        </>
    )
}

export default SucursalesIndexList