import React from 'react'

import Evento_1 from '../../assets/images/Evento_1.jpeg'
import Evento_2 from '../../assets/images/Evento_2.jpeg'
// import Evento_3 from '../../assets/images/Evento_3.jpeg'
import Evento_4 from '../../assets/images/Evento_4.jpeg'
import Evento_5 from '../../assets/images/Evento_5.jpeg'
import Evento_6 from '../../assets/images/Evento_6.jpeg'
import Evento_7 from '../../assets/images/Evento_7.jpeg'
import Evento_8 from '../../assets/images/Evento_8.jpeg'
import Evento_9 from '../../assets/images/Evento_9.jpeg'
import Evento_10 from '../../assets/images/Evento_10.jpeg'
import Evento_11 from '../../assets/images/Evento_11.jpeg'

//MUI
import { Box, Button, Container, Grid, Typography, useMediaQuery  } from '@mui/material'
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#BCE03B',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#9aba27',
        color: '#fff'
    },
}));

const EventosMesRiñon = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    return (
        <Container maxWidth='lg' className='last-view events' sx={{ mt: matches ? 5 : 18 }}>
            <img src={Evento_1} width='100%' alt={Evento_1} />
            <Box sx={{ my: 4 }}>
                <Typography variant='h5' sx={{ color: '#004876', fontWeight: 'bold' }} align='center'>
                    ¡No te pierdas nuestros eventos del mes!
                </Typography>
            </Box>
            <img src={Evento_2} width='100%' alt={Evento_2} />
            <Box sx={{ my: 4, p: 1.5, textAlign: 'center', bgcolor: '#00A7E1' }}>
                <Typography variant='h6' sx={{ color: '#fff', fontWeight: 'bold', letterSpacing: '1px' }}>QUÉRETARO</Typography>
            </Box>
            <Grid container spacing={4}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                    <img src={Evento_10} width='80%' alt={Evento_10} />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ my: 'auto', textAlign: 'center' }}>
                    <Typography variant='body1' gutterBottom>
                        <i class="fa-solid fa-location-dot" style={{ color: 'red' }}></i> Clínica de nefrología y diálisis de Querétaro.
                    </Typography>
                    <Typography variant='body1' color='text.secondary' paragraph>De 9:00 a 17:00 horas</Typography>
                    <ColorButton
                        component='a'
                        href='https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=N3BlbXVrZTJyamI1NjcxODF0NjVjYTUxMmEgY19mNDI4ZGQwYjEwM2UwYWFmNTdjZDM4ZDQ0NzYyN2I1Yjg4N2RmNWI1MzM5MTk0YzBlMGQ2YWU4ODZjZmVmN2RkQGc&tmsrc=c_f428dd0b103e0aaf57cd38d447627b5b887df5b5339194c0e0d6ae886cfef7dd%40group.calendar.google.com'
                        target='_blank'
                    >
                        Agregar a mi calendario
                    </ColorButton>
                </Grid>
            </Grid>
            <Box sx={{ my: 4, p: 1.5, textAlign: 'center', bgcolor: '#00A7E1' }}>
                <Typography variant='h6' sx={{ color: '#fff', fontWeight: 'bold', letterSpacing: '1px' }}>PUEBLA</Typography>
            </Box>
            <Grid container spacing={4}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                    <img src={Evento_5} width='380' height='380' alt={Evento_5} style={{ marginBottom: '15px' }} />
                    <Typography variant='body1' gutterBottom>
                        <i class="fa-solid fa-location-dot" style={{ color: 'red' }}></i> Hospital General del Norte Puebla, Puebla.
                    </Typography>
                    <Typography variant='body1' color='text.secondary' paragraph>De 8:30 a 14:00 horas</Typography>
                    <ColorButton
                        component='a'
                        href='https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=NHBpMnRqYm9mcTVvaGJjMnFwdm50dWNhbTUgY19mNDI4ZGQwYjEwM2UwYWFmNTdjZDM4ZDQ0NzYyN2I1Yjg4N2RmNWI1MzM5MTk0YzBlMGQ2YWU4ODZjZmVmN2RkQGc&tmsrc=c_f428dd0b103e0aaf57cd38d447627b5b887df5b5339194c0e0d6ae886cfef7dd%40group.calendar.google.com'
                        target='_blank'
                    >
                        Agregar a mi calendario
                    </ColorButton>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                    <img src={Evento_6} width='380' height='380' alt={Evento_6} style={{ marginBottom: '15px' }} />
                    <Typography variant='body1' gutterBottom>
                        <i class="fa-solid fa-location-dot" style={{ color: 'red' }}></i> Hospital Ángeles, Puebla, Puebla.
                    </Typography>
                    <Typography variant='body1' color='text.secondary' paragraph>De 13:00 a 15:00 horas</Typography>
                    <ColorButton
                        component='a'
                        href='https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=N2JpanZjYXBxajRucGRidHA1dGZxMm8wYmUgY19mNDI4ZGQwYjEwM2UwYWFmNTdjZDM4ZDQ0NzYyN2I1Yjg4N2RmNWI1MzM5MTk0YzBlMGQ2YWU4ODZjZmVmN2RkQGc&tmsrc=c_f428dd0b103e0aaf57cd38d447627b5b887df5b5339194c0e0d6ae886cfef7dd%40group.calendar.google.com'
                        target='_blank'
                    >
                        Agregar a mi calendario
                    </ColorButton>
                </Grid>
            </Grid>
            <Box sx={{ my: 4, p: 1.5, textAlign: 'center', bgcolor: '#00A7E1' }}>
                <Typography variant='h6' sx={{ color: '#fff', fontWeight: 'bold', letterSpacing: '1px' }}>CIUDAD DE MÉXICO</Typography>
            </Box>
            <Grid container spacing={4}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                    <img src={Evento_4} width='350' height='350' alt={Evento_4} style={{ marginBottom: '15px' }} />
                    <Typography variant='body1' gutterBottom>
                        <i class="fa-solid fa-location-dot" style={{ color: 'red' }}></i> Auditorio Dr. Gregorio Posadas Calderon
                    </Typography>
                    <Typography variant='body1' color='text.secondary' paragraph>De 8:00 a 15:00 horas</Typography>
                    <ColorButton
                        component='a'
                        href='https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MWloZnFvNzFnODhwZHFjYWswaWdtZm0zOTMgY19mNDI4ZGQwYjEwM2UwYWFmNTdjZDM4ZDQ0NzYyN2I1Yjg4N2RmNWI1MzM5MTk0YzBlMGQ2YWU4ODZjZmVmN2RkQGc&tmsrc=c_f428dd0b103e0aaf57cd38d447627b5b887df5b5339194c0e0d6ae886cfef7dd%40group.calendar.google.com'
                        target='_blank'
                    >
                        Agregar a mi calendario
                    </ColorButton>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                    <img src={Evento_9} width='350' height='350' alt={Evento_9} style={{ marginBottom: '15px' }} />
                    <Typography variant='body1' gutterBottom>
                        <i class="fa-solid fa-location-dot" style={{ color: 'red' }}></i> Hospital General de México, Dr. Eduardo Liceaga
                    </Typography>
                    <Typography variant='body1' color='text.secondary' paragraph>De 9:00 a 17:00 horas</Typography>
                    <ColorButton
                        component='a'
                        href='https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MGQwYW5wYmxuNWhoZDJxZWVjaWVmaGEwcTkgY19mNDI4ZGQwYjEwM2UwYWFmNTdjZDM4ZDQ0NzYyN2I1Yjg4N2RmNWI1MzM5MTk0YzBlMGQ2YWU4ODZjZmVmN2RkQGc&tmsrc=c_f428dd0b103e0aaf57cd38d447627b5b887df5b5339194c0e0d6ae886cfef7dd%40group.calendar.google.com'
                        target='_blank'
                    >
                        Agregar a mi calendario
                    </ColorButton>
                </Grid>
            </Grid>
            <Box sx={{ my: 4, p: 1.5, textAlign: 'center', bgcolor: '#00A7E1' }}>
                <Typography variant='h6' sx={{ color: '#fff', fontWeight: 'bold', letterSpacing: '1px' }}>GUADALAJARA</Typography>
            </Box>
            <Grid container spacing={4}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                    <img src={Evento_7} width='380' height='380' alt={Evento_7} style={{ marginBottom: '15px' }} />
                    <Typography variant='body1' gutterBottom>
                        <i class="fa-solid fa-location-dot" style={{ color: 'red' }}></i> UMAE Hospital de Especialidades, CMNO Unidad de investigación Biomédica 02
                    </Typography>
                    <Typography variant='body1' color='text.secondary' paragraph>De 8:00 a 13:00 horas</Typography>
                    <ColorButton
                        component='a'
                        href='https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=M2pza2l2YWtib2hlbHZvcDV1MzZqajd1aHYgY19mNDI4ZGQwYjEwM2UwYWFmNTdjZDM4ZDQ0NzYyN2I1Yjg4N2RmNWI1MzM5MTk0YzBlMGQ2YWU4ODZjZmVmN2RkQGc&tmsrc=c_f428dd0b103e0aaf57cd38d447627b5b887df5b5339194c0e0d6ae886cfef7dd%40group.calendar.google.com'
                        target='_blank'
                    >
                        Agregar a mi calendario
                    </ColorButton>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                    <img src={Evento_8} width='380' height='380' alt={Evento_8} style={{ marginBottom: '15px' }} />
                    <Typography variant='body1' gutterBottom>
                        <i class="fa-solid fa-location-dot" style={{ color: 'red' }}></i> Explanada del Hospital Civil Fray Antonio Alcalde "Civil Viejo"
                    </Typography>
                    <Typography variant='body1' color='text.secondary' paragraph>De 9:00 a 11:30 horas</Typography>
                    <ColorButton
                        component='a'
                        href='https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MWNzYWNyZGs3bXQxNjVucW5ncGxhcWlhaG4gY19mNDI4ZGQwYjEwM2UwYWFmNTdjZDM4ZDQ0NzYyN2I1Yjg4N2RmNWI1MzM5MTk0YzBlMGQ2YWU4ODZjZmVmN2RkQGc&tmsrc=c_f428dd0b103e0aaf57cd38d447627b5b887df5b5339194c0e0d6ae886cfef7dd%40group.calendar.google.com'
                        target='_blank'
                    >
                        Agregar a mi calendario
                    </ColorButton>
                </Grid>
            </Grid>
            <Box sx={{ my: 4, p: 1.5, textAlign: 'center', bgcolor: '#00A7E1' }}>
                <Typography variant='h6' sx={{ color: '#fff', fontWeight: 'bold', letterSpacing: '1px' }}>EVENTOS EN LINEA</Typography>
            </Box>
            <Grid container spacing={4}>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                    <img src={Evento_11} width='80%' alt={Evento_11} />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ my: 'auto', textAlign: 'center' }}>
                    <a rel="noopener noreferrer"
                        href='https://www.instagram.com/nutricion.renal/' target='_blank'>
                        <i class="fa-solid fa-location-dot" style={{ color: 'red' }}></i> instagram.com/nutricion.renal/
                    </a>
                    <Typography variant='body1' color='text.secondary' paragraph sx={{ mt: 1 }}>9 de Marzon 1:00 pm</Typography>
                </Grid>
            </Grid>
        </Container>
    )
}

export default EventosMesRiñon