import React from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';

//Owl carousel
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

//CSS
import './paneluser.css'

//MUI
import {
    Box, Container, Divider, Grid, Typography,
    useMediaQuery
} from "@mui/material";

const PanelComponent = () => {
    // usuario iniciado
    const { loggedUser } = useSelector(state => state.user);
    const { email, username } = loggedUser;

    const matches = useMediaQuery('(max-width: 950px)');

    return (
        <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }} className="last-view">
            <Typography variant='h4' sx={{ fontWeight: 'light', mb: 3 }} align='center'>Mi cuenta</Typography>
            {/* Cards */}
            <Grid container spacing={2}>
                {/* Mis pedidos */}
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className="mis-pedidos">
                    <Link to="/mi-cuenta/mis-pedidos" style={{ textDecoration: 'none', color: '#000' }}>
                        <Box sx={{ display: 'flex', mx: 'auto', p: 3 }} className="mi-cuenta-cards">
                            <i className="fa-solid fa-box-open" style={{ fontSize: '2rem' }}></i>
                            <Box sx={{ ml: 2.2 }}>
                                <Typography variant='body1' sx={{ fontWeight: 'bold' }} gutterBottom>Mis pedidos</Typography>
                                <Typography variant='body1'>
                                    Información de pedidos, rastrear paquetes, comprar de nuevo.
                                </Typography>
                            </Box>
                        </Box>
                    </Link>
                </Grid>
                {/* Fin Mis pedidos */}

                {/* Información y seguridad */}
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className="informacion-seguridad">
                    <Link to="/mi-cuenta/informacion-y-seguridad" style={{ textDecoration: 'none', color: '#000' }}>
                        <Box sx={{ display: 'flex', mx: 'auto', p: 3 }} className="mi-cuenta-cards">
                            <i className="fa-solid fa-lock" style={{ fontSize: '2rem' }}></i>
                            <Box sx={{ ml: 2.2 }}>
                                <Typography variant='body1' sx={{ fontWeight: 'bold' }} gutterBottom>Información y seguridad</Typography>
                                <Typography variant='body1'>Edita información de cuenta
                                    (administra tus contraseñas, nombre y correo electrónico).
                                </Typography>
                            </Box>
                        </Box>
                    </Link>
                </Grid>
                {/* Fin Información y seguridad */}

                {/* Direcciones de envio */}
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <Link to="/mi-cuenta/direcciones-de-envio" style={{ textDecoration: 'none', color: '#000' }}>
                        <Box sx={{ display: 'flex', mx: 'auto', p: 3 }} className="mi-cuenta-cards">
                            <i className="fa-solid fa-location-dot" style={{ fontSize: '2rem' }}></i>
                            <Box sx={{ ml: 2.2 }}>
                                <Typography variant='body1' sx={{ fontWeight: 'bold' }} gutterBottom>Mis direcciones de envío</Typography>
                                <Typography variant='body1'>
                                    Administra tus direcciones de envío.
                                </Typography>
                            </Box>
                        </Box>
                    </Link>
                </Grid>
                {/* Fin Direcciones de envio */}

                {/* Direcciones de facturación */}
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <Link to="/mi-cuenta/direcciones-de-facturacion" style={{ textDecoration: 'none', color: '#000' }}>
                        <Box sx={{ display: 'flex', mx: 'auto', p: 3 }} className="mi-cuenta-cards">
                            <i className="fa-solid fa-address-book" style={{ fontSize: '2rem' }}></i>
                            <Box sx={{ ml: 2.2 }}>
                                <Typography variant='body1' sx={{ fontWeight: 'bold' }} gutterBottom>Mis direcciones de facturación</Typography>
                                <Typography variant='body1'>
                                    Administra tus direcciones de facturación.
                                </Typography>
                            </Box>
                        </Box>
                    </Link>
                </Grid>
                {/* Fin Direcciones de facturación */}

                {/* Métodos de pago */}
                {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Link to="/metodos-de-pago" style={{ textDecoration: 'none', color: '#000' }}>
                            <Box sx={{ display: 'flex', mx: 'auto', p: 3 }} className="mi-cuenta-cards">
                                <i className="fa-solid fa-credit-card" style={{ fontSize: '2rem' }}></i>
                                <Box sx={{ ml: 2.2 }}>
                                    <Typography variant='body1' sx={{ fontWeight: 'bold' }} gutterBottom>Métodos de pago</Typography>
                                    <Typography variant='body1'>
                                        Administra y configura tus métodos de pago, beneficios plan de lealtad Nefrópolis.
                                    </Typography>
                                </Box>
                            </Box>
                        </Link>
                    </Grid> */}
                {/* Fin Métodos de pago */}
            </Grid>
            {/* Fin Cards */}


            {/* Datos personales */}
            <Box sx={{ mt: 6 }}>
                <Box sx={{ p: 3 }} className="datos-personales">
                    <Typography variant='h5' sx={{ fontWeight: '600' }}>Datos personales</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant='body1' paragraph><b>Correo electrónico:</b> <br></br>{email}</Typography>
                    <Typography variant='body1'><b>Nombre:</b> <br></br>{username}</Typography>
                </Box>
            </Box>
            {/* Fin Datos personales */}
        </Container>
    );
};

export default PanelComponent;