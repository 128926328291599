import React from 'react'

import place from '../../../assets/resources/Nefro_place.png'
import whatsIcon from '../../../assets/resources/Whatsapp.png'

//MUI
import { Grid, Typography } from '@mui/material'

const SucursalesIndex = ({res}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                <img src={place} alt={place} width='35' />
            </Grid>
            <Grid item xl={8.5} lg={8} md={10} sm={12} xs={12}
                sx={{
                    textAlign: { xl: 'left', lg: 'left', md: 'left', sm: 'center', xs: 'center' }
                }}
            >
                <Typography variant='body2' sx={{ lineHeight: 'normal', color: '#205072' }}>
                    Sucursal
                </Typography>
                <Typography
                    sx={{
                        fontSize: '18px', lineHeight: 'normal',
                        color: '#5552B2', fontWeight: '600'
                    }}
                    gutterBottom
                >
                    {res.name}
                </Typography>
                <Typography variant='body2' sx={{ color: '#205072' }} paragraph>
                    {res.address}
                </Typography>
                <Typography variant='body2' sx={{ color: '#205072' }}>
                    Horario
                </Typography>
                <Typography variant='body2' sx={{ color: '#205072' }} paragraph>
                    Lun - Vie. 9:30 a.m. - 2 p.m / 2:30 p.m. - 6 p.m.
                </Typography>
                <Typography variant='body1' sx={{ color: '#5552B2' }}>
                    Tel. {res.phone}
                </Typography>
            </Grid>
            <Grid item xl={1.5} lg={2} md={12} sm={12} xs={12}
                sx={{
                    my: 'auto',
                    display: { xl: 'block', lg: 'block', md: 'none', sm: 'none', xs: 'none' }
                }}
            >
                <img src={whatsIcon} alt={whatsIcon} width='40' />
            </Grid>
        </Grid>
    )
}

export default SucursalesIndex