import { types } from '../types/types';

const initialState = {
    doctors: []
};

export const doctorReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.doctorsLoaded:
            return {
                ...state,
                doctors: action.payload
            }
        case types.doctorUpdated:
            return {
                ...state,
                doctors: [
                    ...state.doctors.map(
                        e => (e.idMedicoEspecialista === action.payload.idMedicoEspecialista)
                            ? ({ ...e, ...action.payload }) : e
                    )
                ]
            }
        case types.doctorDeleted:
            return {
                ...state,
                doctors: [
                    ...state.doctors.filter(
                        e => (e.idMedicoEspecialista !== action.payload.idMedicoEspecialista)
                    )
                ]
            }
        default:
            return state;
    }
};