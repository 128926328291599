import { useState, useEffect } from 'react';

export default function useScript(url, name) {
    const [lib, setLib] = useState({});

    useEffect(() => {
        const script = document.createElement('script');
        // script.type = 'text/javascript';
        script.src = url;
        script.async = true;
        // script.setAttribute('data-error', window.errorCallback);
        // script.setAttribute('data-error', function errorCallback() {
        //     console.log('nefro-error');
        // });
        // script.dataset.error = function errorCallback() {
        //     console.log('nefro-error');
        // };
        script.onload = () => setLib({
            [name]: window[name]
        });

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
    
    return lib;
};