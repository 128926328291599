import React, { useEffect, useState } from "react";

import TuOrden from "../TuOrden";

//CSS
import './paymentcheckout.css'

import paypal from '../../../../assets/images/paypal.png';
import mercado_pago from '../../../../assets/images/mp_logo.png';
import visa_master from '../../../../assets/images/Visa-MasterCard.png';
import logo_openpay from '../../../../assets/images/logotipo_openpay.jpg';

//MUI
import {
    Grid, Radio, Box, Accordion,
    AccordionSummary, Typography
} from "@mui/material";

const PaymentCheckoutComponent = ({
    page, setPage,
    shippingValues, billingValues,
    paymentValues, setPaymentValues
}) => {
    // datos de pago
    const { payment_method } = paymentValues;
    //Accordion
    const [expanded, setExpanded] = useState('panel1');

    useEffect(() => {
        setExpanded(payment_method ? payment_method : 'panel1');
    }, [payment_method]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        setPaymentValues(e => ({ ...e, payment_method: panel }));
    };

    return (
        <>
            {/* Contenido */}
            <Box sx={{ mt: 4 }}>
                {/* Row */}
                <Grid container spacing={4}>
                    <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>

                        {/* Pago con tarjeta de crédito o débito (EvoPayments) */}
                        {/* <Accordion
                            expanded={expanded === "EvoPayments"}
                            onChange={handleChange("EvoPayments")}
                        >
                            <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Grid container wrap="wrap">
                                    <Grid item md={9} xs>
                                        <Box display="flex" alignItems="center">
                                            <Radio checked={expanded === "EvoPayments"} />
                                            <Typography variant="subtitle1">
                                                Tarjeta de Crédito y Débito
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={3} xs sx={{my: 'auto'}}>
                                        <Box display="flex" flexDirection="row-reverse" alignItems="center">
                                            <img
                                                className="logo_payments"
                                                src={visa_master}
                                                width="70%"
                                                alt="visa-mastercard-american"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                        </Accordion> */}
                        {/* Fin tarjeta de débito o crédito (EvoPayments) */}

                        {/* Pago con tarjeta de crédito o débito (OpenPay) */}
                        <Accordion
                            expanded={expanded === "OpenPay"}
                            onChange={handleChange("OpenPay")}
                        >
                            <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Grid container wrap="wrap">
                                    <Grid item md={9} xs>
                                        <Box display="flex" alignItems="center">
                                            <Radio checked={expanded === "OpenPay"} />
                                            <Typography variant="subtitle1">
                                                Tarjeta de crédito/débito
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={3} xs sx={{ my: 'auto' }}>
                                        <Box display="flex" flexDirection="row-reverse" alignItems="center">
                                            <img
                                                className="logo_payments"
                                                src={logo_openpay}
                                                width="70%"
                                                alt="visa-mastercard-american"
                                            />
                                            <img
                                                className="logo_payments"
                                                src={visa_master}
                                                width="70%"
                                                alt="visa-mastercard-american"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                        </Accordion>
                        {/* Fin tarjeta de débito o crédito (OpenPay) */}

                        {/* Paypal */}
                        <Accordion
                            expanded={expanded === "PayPal"}
                            onChange={handleChange("PayPal")}
                        >
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Grid container wrap="wrap">
                                    <Grid item md={9} xs>
                                        <Box display="flex" alignItems="center">
                                            <Radio checked={expanded === "PayPal"} />
                                            <Typography variant="subtitle1">PayPal </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={3} xs sx={{ my: 'auto' }}>
                                        <Box display="flex" flexDirection="row-reverse" alignItems="center">
                                            <img
                                                className="logo_payments"
                                                src={paypal}
                                                width="80%"
                                                alt="paypal_logo"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                        </Accordion>
                        {/* Fin paypal */}

                        {/* Mercadopago */}
                        <Accordion
                            expanded={expanded === "MercadoPago"}
                            onChange={handleChange("MercadoPago")}
                        >
                            <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Grid container wrap="wrap">
                                    <Grid item md={9} xs>
                                        <Box display="flex" alignItems="center">
                                            <Radio checked={expanded === "MercadoPago"} />
                                            <Typography variant="subtitle1">Efectivo </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={3} xs sx={{ my: 'auto' }}>
                                        <Box display="flex" flexDirection="row-reverse" alignItems="center">
                                            <img
                                                className="mp_img"
                                                src={mercado_pago}
                                                width="180%"
                                                alt="mercadopago_logo"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                        </Accordion>
                        {/* Fin Mercadopago */}
                    </Grid>

                    {/* Total */}
                    <TuOrden
                        page={page}
                        setPage={setPage}
                        shippingValues={shippingValues}
                        billingValues={billingValues}
                        paymentValues={paymentValues}
                        setPaymentValues={setPaymentValues} />
                    {/* Fin Total */}
                </Grid>
                {/* Fin row */}
            </Box>
            {/* Fin contenido */}
        </>
    );
};

export default PaymentCheckoutComponent;