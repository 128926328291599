import React from 'react'

//CSS
import './landingpages.css'

//Imagenes
import img_1 from '../../assets/images/promociones/descuento_adulto_mayor.png'
// import img_2 from '../../assets/images/promociones/cumpleaños.png'
import img_3 from '../../assets/images/promociones/descuentos_nin.png'
import img_4 from '../../assets/images/promociones/nr.png'

//MUI
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';

const items = [
    {
        id: 1,
        image: img_1,
        title: 'Descuentos adulto mayor (Mayores de 60 años) ',
        description: 'Todos los días 5% de descuento en todos los productos NIN para Adultos Mayores. (Máximo 5 productos por compra, válido sólo en sucursal presentando tu credencial del INAPAM)',
        list: [],
        extra: ''
    },
    {
        id: 2,
        image: img_3,
        title: 'Descuentos Exclusivos NIN',
        description: 'Contamos con paquetes promocionales para cada mes. Consulta a tu ejecutivo de ventas para más información sobre las promociones vigentes.',
        list: [],
        extra: ''
    },
    {
        id: 3,
        image: img_4,
        title: 'Consulta Nutrición Renal',
        description: '',
        list: [
            {
                value: '10% en tu primera consulta de Nutrición renal (Precio normal $600)'
            },
            {
                value: '25% de descuento en tu paquete de Consulta + BCM (*Sólo en Gdl)'
            },
        ],
        extra: '*Las consultas de nutrición podrán ser de manera presencial en la ciudad de Guadalajara y de forma virtual para cualquier parte de la República Mexicana.'
    },
]

const Promociones = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    return (
        <>
            {/* Header */}
            <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18 }}>
                <Typography align='center' sx={{ fontWeight: '600', textTransform: 'uppercase', fontSize: '26px' }} paragraph>
                    PROMOCIONES VIGENTES
                </Typography>
            </Container>

            {/* Contenido */}
            <Container maxWidth="lg" sx={{ mt: 5 }} className="last-view">
                <Grid container spacing={4}>
                    {
                        items.map(res =>
                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} key={res.id} sx={{ mx: 'auto' }}>
                                <Box sx={{ textAlign: 'center', mb: 1 }}>
                                    <img src={res.image} alt={res.image} width='50%' />
                                </Box>
                                <Typography variant='h6' align='center' paragraph sx={{ fontWeight: '600' }}>{res.title}</Typography>
                                <Typography variant='body1'>{res.description}</Typography>
                                {
                                    res.list.length > 0
                                        ?
                                        <>
                                            {
                                                <ul>
                                                    {
                                                        res.list.map((li, index) => (
                                                            <li key={index}>{li.value}</li>
                                                        ))
                                                    }
                                                </ul>
                                            }
                                        </>
                                        :
                                        null
                                }
                                {
                                    res.extra.length > 0
                                        ?
                                        <Typography variant='body1'>{res.extra}</Typography>
                                        :
                                        null
                                }
                            </Grid>
                        )
                    }
                </Grid>

            </Container>
            {/* Fin contenido */}
        </>
    )
}

export default Promociones