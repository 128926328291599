export const DOCTOR_INIT = {
    name: '',
    lastname: '',
    email: '',
    phone: '',
    ced: '',
    speciality: '',
    sub_speciality: '',
    hospital: '',
    hospital_type: '',
    state: '',
    mun: '',
    colony: ''
};

// export const DOCTOR_INIT = {
//     name: 'Juan',
//     lastname: 'Sandoval',
//     email: 'jpalejo1@hotmail.com',
//     phone: '3320223543',
//     ced: 'ABCD990000',
//     speciality: 'Software',
//     sub_speciality: 'Backend',
//     hospital: 'Maria de los Angeles',
//     hospital_type: 'Transformaciones',
//     state: 'Jalisco',
//     mun: 'Guadalajara',
//     colony: 'Huentiwood'
// };

export const DOCTOR_UPD = {
    name: '',
    lastname: '',
    email: '',
    phone: '',
    ced: '',
    speciality: '',
    sub_speciality: '',
    hospital: '',
    hospital_type: '',
    state: '',
    mun: '',
    colony: '',
    idMedicoEspecialista: 0,
    idEspecialidad: 0,
    idSubEspecialidad: 0,
    idAsentamiento: 0,
    idMunicipio: 0,
    idEntidadFederativa: 0,
    idHospital: 0,
    idTipoHospital: 0
};

export const DOCTOR_FIELDS_ERR_1 = {
    name_err: false,
    lastname_err: false,
    email_err: false,
    phone_err: false,
    ced_err: false,
    speciality_err: false,
    sub_speciality_err: false,
    hospital_err: false,
    hospital_type_err: false,
    state_err: false,
    mun_err: false,
    colony_err: false,
};