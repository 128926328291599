import React, { useEffect, useState } from 'react';

import { DateRange } from 'react-date-range';
import es from "date-fns/locale/es";
import { format, startOfDay, endOfDay, addWeeks } from 'date-fns';

import PromoOneDialog from './PromoOneDialog';

import { updatePromo } from '../../../../actions/admin/promotion';
import { basicMsg, simpleConfirmDialog } from '../../../../actions/swal_msg';
import { getPromotionText } from '../../../../helpers/admin/promotion/promotionHelper';

import {
    Button, Dialog, DialogTitle, DialogContent, DialogActions,
    Stack, Typography, IconButton, TextField, Box
} from "@mui/material";
import UpdateIcon from '@mui/icons-material/Update';
import RestoreIcon from '@mui/icons-material/Restore';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';

const today = new Date();
const dayStart = startOfDay(today);
const todayPlusWeek = addWeeks(endOfDay(today), 1);

const PromotionDialog = ({
    currentPromotion,
    setActivePromotion, setOtherPromotion,
    dialogStatus, setDialogStatus
}) => {
    const {
        _id, promo, pkg_qty, discount, start_date, end_date
    } = currentPromotion;
    
    // rango de fechas en <DateRange />
    const [range, setRange] = useState([
        {
            startDate: dayStart,
            endDate: todayPlusWeek,
            key: 'selection'
        }
    ]);
    // duración de promoción <DateRange />
    const [promotionData, setPromotionData] = useState({
        pkg_qty: '', discount: '',
        startDate: dayStart,
        endDate: todayPlusWeek,
    });
    const {
        startDate, endDate, pkg_qty: qty_data, discount: discount_data
    } = promotionData;
    // estado de diálogo
    const [dateDialog, setDateDialog] = useState(false);

    const [promoTitle, setPromoTitle] = useState({
        before: 'Promoción',
        after: 'Promoción'
    });
    const { before, after } = promoTitle;

    useEffect(() => {
        if (promo !== '') {
            const _start = new Date(start_date);
            const _end = new Date(end_date);
            setPromotionData(e => ({
                ...e, startDate: _start, endDate: _end,
                discount: discount ? discount : '',
                pkg_qty: pkg_qty ? pkg_qty : ''
            }));
            setRange([{ startDate: _start, endDate: _end, key: 'selection' }]);
        }
    }, [currentPromotion]);

    useEffect(() => {
        getPromotionText(currentPromotion, qty_data, discount_data, setPromoTitle);
    }, [_id, qty_data, discount_data]);

    // cambio de fecha
    const filterByDate = () => {
        setPromotionData(e => ({
            ...e,
            startDate: range[0].startDate, // inicio día
            endDate: endOfDay(range[0].endDate) // fin día
        }));
        setDateDialog(false);
    };

    // colocar fecha duración de promoción antes de cambios
    const restoreInitialDate = () => {
        const _start = new Date(start_date);
        const _end = new Date(end_date);
        setPromotionData(e => ({ ...e, startDate: _start, endDate: _end }));
        setRange([{ startDate: _start, endDate: _end, key: 'selection' }]);
        setDateDialog(false);
    };

    // cerrar diálogo de fecha sin guardar cambios
    const closeDateDialog = () => {
        setRange([{ startDate, endDate, key: 'selection' }]);
        setDateDialog(false);
    };

    // validación de fechas, descuento y pack
    const formValidation = () => {
        if (startDate && endDate) {
            if (!['2x1', '4x3', 'ABC'].includes(promo)) {
                // valor de descuento a numérico
                const num = Number(discount_data);
                if (discount_data === '')
                    basicMsg('Ingrese el porcentaje de descuento');
                else if (num < 1 || num > 100)
                    basicMsg('Porcentaje de descuento incorrecto');
                else {
                    if (promo === 'CompraX%Descuento') {
                        const qty = Number(qty_data);
                        if (qty_data === '')
                            basicMsg('Ingrese la cantidad de paquete');
                        else if (qty < 2 || qty > 99)
                            basicMsg('Cantidad de paquete incorrecto');
                        else return true;
                    } else return true;
                }
            } else return true;
        } else
            basicMsg('Seleccione fecha de promoción');
        return false;
    };
    
    const confirmUpdate = async () => {
        if (formValidation()) {
            if (await simpleConfirmDialog('Actualizar la promoción?', 'Actualizar'))
                updatePromotion();
        }
    };

    // actualizar promoción
    const updatePromotion = async () => {
        const req = await updatePromo({
            _id, promo, ...promotionData
        });
        if (req.status) {
            const { model } = req;
            const now = new Date();
            setOtherPromotion(e => e.filter(e => e._id !== _id));
            setActivePromotion(e => e.filter(e => e._id !== _id));
            if (model.start_date <= now && model.end_date >= now)
                setActivePromotion(e => ([{ ...currentPromotion, ...model }, ...e]));
            else
                setOtherPromotion(e => ([{ ...currentPromotion, ...model }, ...e]));
            
            setDialogStatus(false);
        }
    };

    return (
        <Dialog
            open={ dialogStatus }
            onClose={ () => setDialogStatus(false) }
            fullWidth={true}
            maxWidth={'lg'}
            sx={{ zIndex: 1000 }}
        >
            <DialogTitle>
                Editar promoción
            </DialogTitle>
            <DialogContent>
                {
                    ['2x1', '4x3', 'ABC'].includes(promo) ?
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            sx={{ mb: 2 }}>
                            <StarPurple500Icon />
                            <Typography variant="subtitle1">
                                { after }
                            </Typography>
                        </Stack>
                    :
                        <Stack
                            direction="column"
                            sx={{ mb: 2 }}>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                                sx={{ mb: 2 }}>
                                <UpdateIcon />
                                <Typography variant="subtitle2" style={{ textDecoration: "line-through" }}>
                                    { `Antes: ${before}` }
                                </Typography>
                            </Stack>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                                sx={{ mb: 2 }}>
                                <StarPurple500Icon />
                                <Typography variant="subtitle1">
                                    { `Ahora: ${after}` }
                                </Typography>
                            </Stack>
                        </Stack>
                }
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{ mb: 2 }}>
                    <Typography variant="body1">
                        Duración de la promoción
                    </Typography>
                    <IconButton onClick={() => { setDateDialog(true) }} sx={{ my: 'auto' }}>
                        <CalendarMonthIcon />
                    </IconButton>
                </Stack>

                <Stack
                    direction="row"
                    spacing={2}>
                    <TextField
                        size='small'
                        fullWidth
                        disabled
                        label="Fecha de inicio"
                        value={`${format(startDate, "d 'de' MMMM yyyy", { locale: es })}`} />
                    <TextField
                        size='small'
                        fullWidth
                        disabled
                        label="Fecha de finalización"
                        value={`${format(endDate, "d 'de' MMMM yyyy", { locale: es })}`} />
                </Stack>

                <PromoOneDialog
                    promo={ promo }
                    promotionData={ promotionData }
                    setPromotionData={ setPromotionData }
                />
                
                <Dialog
                    open={ dateDialog }
                    onClose={ closeDateDialog }>
                    <DialogTitle>
                        Filtrar por fecha
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ border: '1px solid #e1e1e1', borderRadius: '3px' }}>
                            <DateRange
                                editableDateInputs={true}
                                onChange={ item => setRange([item.selection]) }
                                moveRangeOnFirstSelection={false}
                                ranges={range}
                                locale={es}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <IconButton aria-label="delete" size="large"
                            onClick={ restoreInitialDate }>
                            <RestoreIcon fontSize="inherit" />
                        </IconButton>
                        <Button onClick={ closeDateDialog }>Cancelar</Button>
                        <Button onClick={ filterByDate }>Guardar</Button>
                    </DialogActions>
                </Dialog>
            </DialogContent>
            <DialogActions>
                <Button onClick={ () => setDialogStatus(false) }>
                    Cancelar
                </Button>
                <Button variant="contained"
                    onClick={ confirmUpdate }>
                    Actualizar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PromotionDialog;