import React, { useState, useEffect } from 'react'
import { CustomFetch } from '../../../Utils/CustomFecth'
import { SucursalesData } from '../../../data/Sucursales/SucursalesData'
import SucursalesIndexList from '../SucursalesIndexList/SucursalesIndexList'

const SucursalesIndexContainer = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(false)
        CustomFetch(SucursalesData).then(data => {
            setData(data)
            setLoading(true)
        })
    }, [])

    return (
        <>
            {
                loading
                    ?
                    <SucursalesIndexList data={data} />
                    :
                    <>Cargando</>
            }
        </>
    )
}

export default SucursalesIndexContainer