// import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import PanelComponent from '../components/User/Panel/PanelComponent';
import UserBilling from '../components/User/UserBilling/UserBilling';
import PedidosComponent from '../components/User/Pedidos/PedidosComponent';
import NewBilling from '../components/User/UserBilling/NewBilling/NewBilling';
import AddressComponent from '../components/User/Direcciones/AddressComponent';
import UISComponent from '../components/User/Información-Seguridad/UISComponent';
import DetallesPedidoComponent from '../components/User/Pedidos/DetallesPedidoComponent';
import NewAddressComponent from '../components/User/Direcciones/Nueva-Dirección/NewAddressComponent';

export const UserRoutes = () => {
    // useEffect(() => {
    //     window.zE('webWidget', 'hide');
    // }, []);

    return (
        <>
            <Routes>
                <Route path="/" element={<PanelComponent />} />
                <Route path='/informacion-y-seguridad' element={<UISComponent />} />
                <Route path='mis-pedidos' element={<PedidosComponent />}></Route>
                <Route path='direcciones-de-envio' element={<AddressComponent />}></Route>
                <Route path='nueva-direccion-de-envio' element={<NewAddressComponent />}></Route>
                <Route path='direcciones-de-facturacion' element={<UserBilling />}></Route>
                <Route path='nueva-direccion-facturacion' element={<NewBilling />}></Route>
                {/* <Route path='metodos-de-pago' element={<UserPaymentsComponent />}></Route>
                <Route path='nuevo-metodo-de-pago' element={<AddPaymentComponent />}></Route> */}
                <Route path='detalles-pedido' element={<DetallesPedidoComponent />}></Route>
                <Route path="*" element={<Navigate to="/mi-cuenta" replace />} />
            </Routes>
        </>
    )
};