
import { types } from '../types/types';
import { COUPON_INIT } from '../constants/shop/coupon/coupon_const';

const initialState = {
    coupons: [],
    current: COUPON_INIT,
    action: 'add',
    modal: false
};

export const couponReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.couponsLoaded:
            return {
                ...state,
                coupons: [...action.payload],
            }
        case types.couponAdded:
            return {
                ...state,
                coupons: [...state.coupons, action.payload],
                current: COUPON_INIT,
                action: 'added',
                modal: false
            }
        case types.couponOpenModal:
            return {
                ...state,
                modal: true
            }
        case types.couponCloseModal:
            return {
                ...state,
                current: COUPON_INIT,
                action: "add",
                modal: false
            }
        case types.couponEdit:
            return {
                ...state,
                current: action.payload,
                action: "edit",
                modal: true
            }
        case types.couponUpdated:
            return {
                ...state,
                coupons: state.coupons.map(
                    e => (e._id === action.payload._id) ? action.payload : e
                ),
                current: COUPON_INIT,
                action: "updated",
                modal: false
            }
        case types.couponDeleted:
            return {
                ...state,
                coupons: state.coupons.filter(
                    e => (e._id !== action.payload)
                ),
                action: "deleted"
            }
        case types.couponSelected:
            return {
                ...state,
                coupons: state.coupons.map(
                    e => (e._id === action.payload) ? {...e, selected: !e.selected} : e
                ),
            }
        case types.couponsSelectedDeleted:
            return {
                ...state,
                coupons: state.coupons.filter(
                    e => !action.payload.includes(e._id)
                ),
                action: 'selectedDel'
            }
        default:
            return state;
    }
};