import React, { useEffect, useState } from "react";

import TuOrden from "../TuOrden";
import CheckoutBilling from "./CheckoutBilling";

import { regex_num } from "../../../../constants/regex";
import { CHECKOUT_DISABLED_INPUT, RESIDENCE_TYPE } from "../../../../constants/constants";

import { getPostalcodeData, saveClientEmail } from "../../../../actions/checkout";
import { myShippingAddresses } from "../../../../actions/user-account";
import { myBillingAddresses } from "../../../../actions/user-account/billing/ua_billing";

//CSS
import './personalcheckout.css';

import {
    Box, Grid, Typography, Button, Card, CardContent,
    CardActions, Dialog, DialogContent, Select,
    Avatar, MenuItem, TextField, InputLabel,
    FormControl, InputAdornment
} from "@mui/material";
import DomainIcon from '@mui/icons-material/Domain';
import SignpostIcon from '@mui/icons-material/Signpost';
import ApartmentIcon from '@mui/icons-material/Apartment';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

const PersonalInfoCheckoutComponent = ({
    page, setPage,
    shippingValues, setShippingValues,
    billingValues, setBillingValues,
    paymentValues, setPaymentValues
}) => {
    // información de envío
    const {
        name, lastname, email, tel, street, ext_num,
        int_num, crossing_streets, postalcode, colony,
        city, state, references, residence_type,
        residence_type_select
    } = shippingValues;

    const { billing_postalcode } = billingValues;

    // información recuperada con el código postal
    // colonias disponibles para campo de envío
    const [colonies, setColonies] = useState([]);

    const [billingColonies, setBillingColonies] = useState([]);

    // deshabilitación de campos
    const [disabledFields, setDisabledFields] = useState(CHECKOUT_DISABLED_INPUT);
    const { cityInput, stateInput } = disabledFields;

    // direcciones de envío del usuario
    const [addresses, setAddresses] = useState([]);
    // direcciones de facturación del usuario
    const [billingAddresses, setBillingAddresses] = useState([]);

    // abrir / cerrar modal
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getShippingAdresses();
        getBillingAdresses();
        // primera opción en tipo de domicilio
        setShippingValues({
            ...shippingValues,
            residence_type_select: RESIDENCE_TYPE[0]
        });
    }, []);

    // obtiene direcciones de envío del usuario
    const getShippingAdresses = async () => {
        const req = await myShippingAddresses();
        if (req.status)
            setAddresses(req.resp);
    };

    // obtiene direcciones de facturación del usuario
    const getBillingAdresses = async () => {
        const req = await myBillingAddresses();
        if (req.status)
            setBillingAddresses(req.resp);
    };

    // cambios en datos de envío
    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        // propiedades con únicamente números
        if ((['ext_num', 'tel', 'postalcode'].includes(name))) {
            if (value === '' || regex_num.test(value))
                setShippingValues({ ...shippingValues, [name]: value });
        } else
            setShippingValues({ ...shippingValues, [name]: value });
    };

    // campo pierde foco
    const handleInputBlur = async ({ target }) => {
        // busca información del CP ingresado
        if (target.name === 'postalcode') {
            const resp = await getPostalcodeData(postalcode);
            if (resp.status) {
                const { resp: { municipio, estado, asentamiento } } = resp;
                setShippingValues({
                    ...shippingValues,
                    colony: '',
                    city: municipio,
                    state: estado
                });
                setColonies(asentamiento);
                // deshabilita campos
                setDisabledFields({
                    ...disabledFields,
                    cityInput: true, stateInput: true
                });
            } else {
                // limpia colonias disponibles
                setColonies([]);
                // habilita campos
                setDisabledFields({
                    ...disabledFields,
                    cityInput: false, stateInput: false
                });
            }
        } else if (target.name === 'billing_postalcode') {
            const resp = await getPostalcodeData(billing_postalcode);
            if (resp.status) {
                const { resp: { municipio, estado, asentamiento } } = resp;
                setBillingValues({
                    ...billingValues,
                    billing_colony: '',
                    billing_city: municipio,
                    billing_state: estado
                });
                setBillingColonies(asentamiento);
                setDisabledFields({
                    ...disabledFields,
                    billCityInput: true, billStateInput: true
                });
            } else {
                setBillingColonies([]);
                setDisabledFields({
                    ...disabledFields,
                    billCityInput: false, billStateInput: false
                });
            }
        }
        // else if (target.name === 'email') {
        //     setTimeout(async () => {
        //         await saveClientEmail(email);
        //     }, 1000);
        // }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const selectAddress = address_id => () => {
        handleClose();
        const filtered = addresses.find(e => e._id === address_id);
        if (filtered)
            setShippingValues({
                ...shippingValues,
                ...filtered
            });
    };

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {
                    addresses.length > 0 &&
                    <>
                        <Button onClick={handleOpen}>Mis direcciones</Button>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            maxWidth='xl'
                            fullWidth={true}
                        >
                            <DialogContent>
                                <Typography variant="h5" paragraph>Mis direcciones de envío</Typography>
                                <Grid container spacing={3}>
                                    {
                                        addresses.map(e =>
                                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}
                                                key={e._id}>
                                                <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
                                                    <CardContent>
                                                        <Typography variant='body1' sx={{ fontWeight: '600' }}>{e.name} {e.lastname}</Typography>
                                                        <Typography variant='body1'>{`${e.street} #${e.ext_num} `}
                                                            {e.int_num ? `interior ${e.int_num}` : ''}</Typography>
                                                        <Typography variant='body1'>{e.colony}</Typography>
                                                        <Typography variant='body1'>{`${e.city}, ${e.state}, C.P.${e.postalcode}`}</Typography>
                                                        <Typography variant='body1'>Teléfono: {e.tel}</Typography>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button size="small" onClick={selectAddress(e._id)}>Seleccionar</Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        )}
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </>
                }
            </Box>

            <Box sx={{ mt: 1 }}>
                <Grid container spacing={4}>
                    <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
                        {/* Datos de envío */}
                        <Box sx={{ p: 3 }} className="personal-info-form">
                            <Box sx={{ mb: 5 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Avatar sx={{ bgcolor: '#2699FB' }}>1</Avatar>
                                </Box>
                                <Typography variant='h6' sx={{ fontWeight: '600', mt: .5 }} align='center'>Datos de envío</Typography>
                            </Box>
                            <Grid container spacing={3.5}>
                                {/* Nombres */}
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id=""
                                            label="Nombre (s)"
                                            inputProps={{ maxLength: 60 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fa-solid fa-user"></i>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            name="name"
                                            value={name}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Apellidos */}
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id=""
                                            label="Apellidos"
                                            inputProps={{ maxLength: 60 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fa-solid fa-user-group"></i>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            name="lastname"
                                            value={lastname}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Correo electronico */}
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id=""
                                            label="Correo electrónico"
                                            inputProps={{ maxLength: 120 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fa-solid fa-envelope"></i>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            name="email"
                                            value={ email }
                                            onChange={ handleInputChange }
                                            onBlur={ handleInputBlur }
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Número de telefono */}
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id=""
                                            label="Número telefónico"
                                            inputProps={{ maxLength: 10 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fa-solid fa-phone"></i>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            name="tel"
                                            value={tel}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Calle */}
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id=""
                                            label="Calle"
                                            inputProps={{ maxLength: 60 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fa-solid fa-house"></i>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            name="street"
                                            value={street}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Número exterior*/}
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id=""
                                            label="Número exterior"
                                            inputProps={{ maxLength: 4 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fa-solid fa-hashtag"></i>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            name="ext_num"
                                            value={ext_num}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id=""
                                            label="Número interior"
                                            inputProps={{ maxLength: 8 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fa-solid fa-hashtag"></i>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            name="int_num"
                                            value={int_num}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Cruce de calles */}
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id=""
                                            label="Entre las calles"
                                            inputProps={{ maxLength: 200 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <i className="fa-solid fa-road"></i>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            name="crossing_streets"
                                            value={crossing_streets}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Código postal */}
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id=""
                                            label="Código postal"
                                            inputProps={{ maxLength: 5 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MarkunreadMailboxIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            name="postalcode"
                                            value={postalcode}
                                            onChange={handleInputChange}
                                            onBlur={handleInputBlur}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Colonia */}
                                {
                                    colonies.length <= 0 ?
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    id=""
                                                    label="Colonia"
                                                    inputProps={{ maxLength: 100 }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <LocationCityIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                    name="colony"
                                                    value={colony}
                                                    onChange={handleInputChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        :
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel>Colonia</InputLabel>
                                                <Select
                                                    id=""
                                                    name="colony"
                                                    value={colony}
                                                    label="Colonia"
                                                    onChange={handleInputChange}
                                                    variant="standard"
                                                >
                                                    {
                                                        colonies.map(({ nombre }) =>
                                                            <MenuItem key={nombre}
                                                                value={nombre}>{nombre}
                                                            </MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                }

                                {/* Municipio */}
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id=""
                                            label="Municipio"
                                            inputProps={{ maxLength: 100 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <DomainIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            disabled={cityInput}
                                            name="city"
                                            value={city}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Estado */}
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id=""
                                            label="Estado"
                                            inputProps={{ maxLength: 100 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <ApartmentIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            disabled={stateInput}
                                            name="state"
                                            value={state}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Referencias de domicilio */}
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id=""
                                            label="Referencias del domicilio"
                                            inputProps={{ maxLength: 200 }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SignpostIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="standard"
                                            name="references"
                                            value={references}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Tipo de domicilio */}
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Tipo de domicilio</InputLabel>
                                        <Select
                                            name="residence_type_select"
                                            value={residence_type_select}
                                            label="Tipo de domicilio"
                                            onChange={handleInputChange}
                                            variant="standard">
                                            {
                                                RESIDENCE_TYPE.map(e =>
                                                    <MenuItem key={e}
                                                        value={e}>{e}
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {
                                    residence_type_select === 'Otro' &&
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    label="Escriba tipo de domicilio"
                                                    inputProps={{ maxLength: 60 }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <OtherHousesIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    variant="standard"
                                                    name="residence_type"
                                                    value={residence_type}
                                                    onChange={handleInputChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                }
                            </Grid>
                        </Box>
                        {/* Fin Datos de envío */}

                        {/* Datos de facturación */}
                        <CheckoutBilling
                            billingValues={ billingValues }
                            setBillingValues={ setBillingValues }
                            billingAddresses={ billingAddresses }
                            disabledFields={ disabledFields }
                            billingColonies={ billingColonies }
                            setBillingColonies={ setBillingColonies }
                            handleInputBlur={ handleInputBlur }
                        />
                    </Grid>

                    <TuOrden
                        page={ page }
                        setPage={ setPage }
                        billingValues={ billingValues }
                        shippingValues={ shippingValues }
                        paymentValues={ paymentValues }
                        setPaymentValues={ setPaymentValues } />
                </Grid>
            </Box>
        </>
    );
};

export default PersonalInfoCheckoutComponent;