import { basicMsg } from "../../../actions/swal_msg";
import { regex_email } from "../../../constants/regex";

// formulario para agregar sucursal
export const form1Validator = ({
    name, phone, mobile, email, address, postalCode, colony, city,
    state, links: { whatsapp, facebook, google_maps }, file
}, setInputErr, flag) => {
    // flag indica si se guarda nueva imagen o se actualiza actual
    const { number, message } = whatsapp;
    const { url } = facebook;
    const { src, title, width, height } = google_maps;

    const name_len = name.trim().length;
    const phone_len = phone.trim().length;
    const mobile_len = mobile.trim().length;
    const email_len = email.trim().length;
    const address_len = address.trim().length;
    const pc_len = postalCode.trim().length;
    const colony_len = colony.trim().length;
    const city_len = city.trim().length;
    const state_len = state.trim().length;
    const number_len = number.trim().length;
    const msg_len = message.trim().length;
    const url_len = url.trim().length;
    const src_len = src.trim().length;
    const title_len = title.trim().length;
    const width_len = width.trim().length;
    const height_len = height.trim().length;

    // establece errores en los campos
    setInputErr(e => ({
        ...e,
        name_err: (name_len < 11 || name_len > 100)
            ? true : false,
        phone_err: (phone_len < 5 || phone_len > 15)
            ? true : false,
        mobile_err: (mobile_len < 5 || mobile_len > 15)
            ? true : false,
        email_err: (email_len < 5 || email_len > 120 || !regex_email.test(email))
            ? true : false,
        address_err: (address_len < 5 || address_len > 100)
            ? true : false,
        pc_err: (pc_len !== 5)
            ? true : false,
        colony_err: (colony_len < 5 || colony_len > 100)
            ? true : false,
        city_err: (city_len < 3 || city_len > 100)
            ? true : false,
        state_err: (state_len < 3 || state_len > 100)
            ? true : false,
        wsp_number_err: (number_len < 5 || number_len > 15)
            ? true : false,
        wsp_message_err: (msg_len < 3 || msg_len > 200)
            ? true : false,
        facebook_url_err: (url_len < 11 || url_len > 500)
            ? true : false,
        iframe_src_err: (src_len < 11 || src_len > 1000)
            ? true : false,
        iframe_title_err: (title_len < 4 || title_len > 60)
            ? true : false,
        iframe_width_err: (width_len < 2 || width_len > 20)
            ? true : false,
        iframe_height_err: (height_len < 2 || height_len > 20)
            ? true : false,
    }));

    if (name_len > 10 && name_len <= 100) {
        if (phone_len > 4 && phone_len <= 15) {
            if (mobile_len > 4 && mobile_len <= 15) {
                if (email_len > 4 && email_len <= 120 && regex_email.test(email)) {
                    if (address_len > 4 && address_len <= 100) {
                        if (pc_len === 5) {
                            if (colony_len > 4 && colony_len <= 100) {
                                if (city_len > 2 && city_len <= 100) {
                                    if (state_len > 2 && state_len <= 100) {
                                        if (number_len > 4 && number_len <= 15) {
                                            if (msg_len > 2 && msg_len <= 200) {
                                                if (url_len > 10 && url_len <= 500) {
                                                    if (src_len > 10 && src_len <= 1000) {
                                                        if (title_len > 3 && title_len <= 60) {
                                                            if (width_len > 1 && width_len <= 20) {
                                                                if (height_len > 1 && height_len <= 20) {
                                                                    // if (file)
                                                                    if ((flag === 'save' && file) || (flag === 'update'))
                                                                        return { status: true, field: '' };
                                                                    else
                                                                        basicMsg('Suba imagen de sucursal');
                                                                } else
                                                                    return { status: false, field: 'height' };
                                                            } else
                                                                return { status: false, field: 'width' };
                                                        } else
                                                            return { status: false, field: 'title' };
                                                    } else
                                                        return { status: false, field: 'src' };
                                                } else
                                                    return { status: false, field: 'url' };
                                            } else
                                                return { status: false, field: 'msg' };
                                        } else
                                            return { status: false, field: 'number' };
                                    } else
                                        return { status: false, field: 'state' };
                                } else
                                    return { status: false, field: 'city' };
                            } else
                                return { status: false, field: 'colony' };
                        } else
                            return { status: false, field: 'postalcode' };
                    } else
                        return { status: false, field: 'address' };
                } else
                    return { status: false, field: 'email' };
            } else
                return { status: false, field: 'mobile' };
        } else
            return { status: false, field: 'phone' };
    }
    return { status: false, field: 'name' };
};