import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { navigateToProductPage } from '../../../actions/shop';

import shape from '../../../assets/resources/shape.png';
import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";

import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

//MUI
import {
    Box, IconButton, Stack, Typography,
    Link
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const RecentlyViewed = ({ product_id }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const {
        navigation, // url de navegación
        rvProducts // productos vistos recientemente
    } = useSelector(state => state.shop);
    const { nav_url } = navigation;

    useEffect(() => {
        // redireccionar y mostrar información de variante
        if (nav_url !== '')
            navigate(`/tienda/producto/${nav_url}`);
    }, [navigation]);

    useEffect(() => {
        setProducts(rvProducts.filter(e => e.prod_id !== product_id));
    }, [rvProducts]);

    // dirigirse a página de variante
    const goToProductPage = (nav_url, nav_id) => () => {
        dispatch(navigateToProductPage({ nav_url, nav_id }));
    };
    
    return (
        <>
            <Box sx={{
                    mb: 3, display: 'flex',
                    justifyContent: 'space-between',
                    gap: '10px', flexWrap: 'wrap'
                }}>
                <Box sx={{ my: 'auto' }}>
                    <Typography variant='h5' color='text.primary'>
                        Productos vistos recientemente
                    </Typography>
                </Box>
                <Stack direction='row' spacing={1.2} justifyContent="flex-end" sx={{ my: 'auto' }}>
                    <IconButton className='best-selling-swiper-button-prev' sx={{ bgcolor: '#F8F9F9' }} size='small'>
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton className='best-selling-swiper-button-next' sx={{ bgcolor: '#F8F9F9' }} size='small'>
                        <ChevronRightIcon />
                    </IconButton>
                </Stack>
            </Box>
            <Swiper
                breakpoints={{
                    0: {
                        slidesPerView: 1.6,
                    },
                    400: {
                        slidesPerView: 1.6,
                    },
                    600: {
                        slidesPerView: 2,
                    },
                    700: {
                        slidesPerView: 3,
                    },
                    1000: {
                        slidesPerView: 4,
                    },
                }}
                spaceBetween={20}
                navigation={{
                    nextEl: '.best-selling-swiper-button-next',
                    prevEl: '.best-selling-swiper-button-prev',
                }}
                loop={true}
                modules={[Navigation]}>
                {
                    products.map(({ prod_var_id, title, variant_title, url, price, images, reviews, item_promotion }) => {
                        let review_prom = 0;
                        let review_count = 0

                        if (reviews.length > 0) {
                            reviews.forEach(element => {
                                review_prom += element.rating;
                                review_count++;
                            });
                            review_prom = Math.round(review_prom / review_count);
                        }
                        return (
                            <SwiperSlide key={prod_var_id}
                                style={{ textAlign: 'center' }}>
                                <Link component="button" variant="body1"
                                    onClick={ goToProductPage(url, prod_var_id) }>
                                    {
                                        images.length > 0 ?
                                            <img src={`${GOOGLE_DRIVE_CDN}${images[0].id}`}
                                                width='250' height="250"
                                                style={{ objectFit: 'contain' }}
                                                alt={images[0].alt}
                                            />
                                        :
                                            <img src={no_image} width='250' height="250"
                                                style={{ objectFit: 'contain' }}
                                                alt='producto-nefropolis'
                                                />
                                    }
                                </Link>

                                {
                                    item_promotion.promo !== '' &&
                                        <div className="shop_top-image">
                                            <img src={shape} alt={shape} width='75' style={{ pointerEvents: 'none' }} />
                                            <div className="shop_centered_image">
                                                {
                                                    ['2x1', '4x3', 'Descuento%Producto'].includes(item_promotion.promo)
                                                        ?
                                                        <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>
                                                            {item_promotion.text}
                                                        </Typography>
                                                        :
                                                        <Typography sx={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>
                                                            Promoción
                                                        </Typography>
                                                }
                                            </div>
                                        </div>
                                }

                                <Typography variant='body1'
                                    gutterBottom
                                    sx={{ fontWeight: '600' }}>
                                    {`${title} ${variant_title}`}
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                    <div className="ratings-shop">
                                        {
                                            review_count > 0 ?
                                                Array.from(Array(review_prom), (e, j) => {
                                                    return <i className="fas fa-star"
                                                        style={{
                                                            color: '#FFA41C',
                                                            stroke: "#FFA41C",
                                                            strokeWidth: 0.2
                                                        }}
                                                        key={j}></i>
                                                })
                                                :
                                                <>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                    <i className="far fa-star"></i>
                                                </>
                                        }
                                    </div>
                                </Box>

                                <Typography variant="h6"
                                    sx={{ fontWeight: '600', color: '#F90606', mt: 1 }}
                                    align='center'>
                                    ${price} MXN
                                </Typography>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </>
    );
};

export default RecentlyViewed;