import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { basicMsg } from '../../../../actions/swal_msg';
import ProductVariantBatch from './Batch/ProductVariantBatch';
import { regex_dec, regex_num } from '../../../../constants/regex';

import {
    FormControl, InputLabel, Select, InputAdornment,
    OutlinedInput, ImageList, ImageListItem, ImageListItemBar,
    IconButton, Button, Box, TextField, Tooltip, FormGroup, Switch,
    FormControlLabel, Grid, MenuItem, Accordion, Typography,

} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from '@emotion/styled';

const Input = styled('input')({
    width: 100,
    display: 'none',
});

const NewVariant = ({ pos, title, variant, setProdValues }) => {
    const matches = useMediaQuery('(max-width:600px)');

    // valores de variante
    const {
        key, variant_title, price, description,
        stock, iva, sat_key, claveUnidad, unidad,
        barcode, odoo_id, available, images, batches
    } = variant;

    const label = `variant-contained-button-file-${key}`;
    
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        fnSetValues('images', items);
    };

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (['iva', 'stock', 'sat_key', 'odoo_id'].includes(name)) {
            if (value === '' || regex_num.test(value))
                fnSetValues(name, value);
        } else if (name === 'price') {
            if (value === '' || regex_dec.test(value))
                fnSetValues(name, value);
        } else if (name === 'claveUnidad')
            fnSetValues(name, value.toUpperCase());
        else fnSetValues(name, value);
    };

    // setter
    const fnSetValues = (_name, _value) => {
        setProdValues(e => ({
            ...e,
            variants: e.variants.map(u =>
                (u.key === key) ?
                    { ...u, [_name]: _value } : u)
        }));
    };

    // ¿variante disponible?
    const handleSwitchChange = ({ target }) => {
        fnSetValues('available', target.checked);
    };

    // subir imágenes de ordenador
    const loadedImages = ({ target }) => {
        let count = images.length; // total de imágenes
        let exceeded = false; // se alcanzó el límite?
        if (count >= 6) {
            basicMsg('No puede agregar más imágenes en esta variante');
            return;
        }
        if (target.files.length > 0) {
            const target_files = [];
            [...target.files].forEach(element => {
                if (count >= 6) exceeded = true;
                else {
                    if (['image/jpeg', 'image/png', 'image/webp'].includes(element.type)) {
                        target_files.push({ img: element, alt: '' });
                        count++;
                    }
                }
            });
            setProdValues(prod => ({
                ...prod,
                variants: prod.variants.map(e =>
                    (e.key === key) ?
                        {
                            ...e,
                            images: [...e.images, ...target_files]
                        } : e
                )
            }));
            if (exceeded) basicMsg('Se alcanzó el límite de imágenes');
        }
    };

    // remueve imágenes de producto
    const removeImg = name => () => {
        setProdValues(prod => ({
            ...prod,
            variants: prod.variants.map(e =>
                (e.key === key) ?
                    {
                        ...e,
                        images: e.images.filter(i => (i.img.name !== name)),
                    } : e
            )
        }));
    };

    const deleteVariant = () => {
        setProdValues(prod => ({
            ...prod,
            variants: prod.variants.filter(e =>
                (e.key !== key)
            )
        }));
    };

    const handleAltChange = index => ({ target }) => {
        const alt = target.value;
        setProdValues(prod => ({
            ...prod,
            variants: prod.variants.map(e =>
                (e.key === key) ?
                    {
                        ...e,
                        images: e.images.map((u, i) => (i === index) ? {
                            ...u,
                            alt
                        } : u )
                    } : e
            )
        }));
    };
    
    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography sx={{ fontWeight: 'bold' }}>
                        {
                            title !== '' ?
                                variant_title !== '' ?
                                    `Variante ${pos + 1} (${title} ${variant_title})`
                                : `Variante ${pos + 1} (${title})`
                            :
                                variant_title !== '' ?
                                    `Variante ${pos + 1} (${variant_title})`
                                : `Variante ${pos + 1}`
                        }
                    </Typography>
                </AccordionSummary>
                
                <AccordionDetails>
                    <Box>
                        <label htmlFor={ label }>
                            <Input accept="image/*"
                                id={ label }
                                multiple
                                type="file"
                                onChange={ loadedImages } />
                            <Button
                                style={{ backgroundColor: '#2699FB', color: 'white' }}
                                variant="contained"
                                component="span">
                                Elegir imágenes
                            </Button>
                        </label>
                    </Box>
                    <DragDropContext onDragEnd={ handleOnDragEnd }>
                        <Droppable droppableId="images">
                            {(provided) => (
                            <ImageList cols={ matches ? 2 : 4 }
                                { ...provided.droppableProps } ref={ provided.innerRef }>
                                { images.map((item, index) => {
                                    return (
                                        <Draggable key={ item.img.name } draggableId={ item.img.name }
                                            index={ index }>
                                            {(prov) => (
                                                <ImageListItem
                                                    cols={1} rows={1}
                                                    ref={ prov.innerRef } { ...prov.draggableProps }
                                                    { ...prov.dragHandleProps }>
                                                    <img
                                                        src={ URL.createObjectURL(item.img) }
                                                        alt={ item.img.title }
                                                        loading="lazy" />
                                                    <ImageListItemBar
                                                        title={
                                                            <TextField label="Atributo ALT"
                                                                variant="standard" size="small"
                                                                onChange={ handleAltChange(index) } />
                                                        }
                                                        actionIcon={
                                                            <IconButton
                                                                sx={{ color: 'rgba(0, 0, 0, 0.5)' }}
                                                                aria-label={`imagen #${index} de variante ${key}`}
                                                                onClick={ removeImg(item.img.name)}>
                                                                <RemoveCircleIcon />
                                                            </IconButton>
                                                        }
                                                        position="below" />
                                                    
                                                </ImageListItem>
                                            )}
                                        </Draggable>
                                    );
                                }) }
                                { provided.placeholder }
                            </ImageList>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {
                        /* Identifica la variante del producto.
                        Utilícese cuando se desea ser específico en el nombre de producto */
                    }
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={11}>
                            <TextField
                                fullWidth
                                name="variant_title"
                                label="Nombre de variante (Ejemplo: Sin refrigeración)"
                                variant="outlined"
                                value={ variant_title }
                                inputProps={{ maxLength: 200 }}
                                onChange={ handleInputChange } />
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title="Utilice si desea especificar la variante de producto. Ejemplo: Sabor Fresa Alta Proteína">
                                <IconButton>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="variant-input-price">Precio</InputLabel>
                                <OutlinedInput
                                    id="variant-input-price"
                                    name="price"
                                    label="Precio"
                                    value={ price }
                                    inputProps={{ maxLength: 8 }}
                                    onChange={ handleInputChange }
                                    startAdornment={
                                        <InputAdornment position="start">$</InputAdornment>
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="barcode"
                                label="Código de barras"
                                variant="outlined"
                                value={ barcode }
                                inputProps={{ maxLength: 32 }}
                                onChange={ handleInputChange } />
                        </Grid>
                    </Grid>

                    <TextField sx={{ mb: 2 }}
                        fullWidth
                        name="description"
                        label="Descripción"
                        multiline
                        variant="outlined"
                        rows={4}
                        value={ description }
                        inputProps={{ maxLength: 2000 }}
                        onChange={handleInputChange} />
                        
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                            <TextField fullWidth
                                name="sat_key"
                                label="Clave SAT"
                                variant="outlined"
                                value={ sat_key }
                                inputProps={{ maxLength: 8 }}
                                onChange={ handleInputChange } />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="iva-variant-label">IVA</InputLabel>
                                <Select
                                    labelId="iva-variant-label"
                                    name="iva"
                                    value={ iva }
                                    label="IVA"
                                    onChange={ handleInputChange }>
                                    <MenuItem value={0}>0%</MenuItem>
                                    <MenuItem value={16}>16%</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                            <TextField fullWidth
                                name="claveUnidad"
                                label="Clave unidad"
                                variant="outlined"
                                value={ claveUnidad }
                                inputProps={{ maxLength: 3 }}
                                onChange={ handleInputChange } />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth
                                name="unidad"
                                label="Unidad de medidad"
                                variant="outlined"
                                value={ unidad }
                                inputProps={{ maxLength: 64 }}
                                onChange={ handleInputChange } />
                        </Grid>
                    </Grid>

                    <TextField sx={{ mb: 2 }}
                        fullWidth
                        name="stock"
                        label="Cantidad disponible"
                        variant="outlined"
                        value={ stock }
                        inputProps={{ maxLength: 5 }}
                        onChange={ handleInputChange } />
                        
                    <TextField sx={{ mb: 2 }}
                        fullWidth
                        name="odoo_id"
                        label="ID de producto en Odoo"
                        variant="outlined"
                        value={ odoo_id }
                        inputProps={{ maxLength: 32 }}
                        onChange={ handleInputChange } />

                    <FormGroup sx={{ mb: 2 }}>
                        <FormControlLabel
                            control={
                            <Switch
                                name="available"
                                checked={ available }
                                onChange={ handleSwitchChange } />
                            }
                            label="Mostrar en tienda" />
                    </FormGroup>

                    <ProductVariantBatch
                        variant_key={key}
                        batches={batches}
                        setProdValues={setProdValues} />

                    <div>
                        <Button
                            startIcon={<DeleteIcon />}
                            style={{ color: 'red' }}
                            onClick={ deleteVariant }>
                            Eliminar variante
                        </Button>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default NewVariant;