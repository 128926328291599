import React, { useEffect, useState } from 'react';

import {
    FormControl, Input, InputLabel, FormHelperText, Stack,
    Button, Typography, Tooltip, IconButton, TextField,
    // Box
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// import { basicMsg } from '../../../../actions/swal_msg';

import {
    addTrackingGuide,
    // uploadOrderFile
} from '../../../../actions/orders';

const init = {
    file: '',
    partner: 'Estándar',
    tracking: ''
};

const ShipOrder = ({ setData, selectedOrder, setSelectedOrder }) => {
    const { id, order_num, shipping } = selectedOrder;

    // guia de seguimiento
    const [values, setValues] = useState(init);
    const {
        // file,
        partner, tracking
    } = values;

    const [loadingButton, setLoadingButton] = useState(false);

    useEffect(() => {
        // marca error?
        setValues(e => ({ ...e, partner: shipping.partner }));
    }, []);

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (value === '' || value.length <= 64) {
            setValues(e => ({ ...e, [name]: value }))
        }
        // setTracking(target.value.toUpperCase());
    };

    const formValidator = () => {
        if (partner.length > 2 && partner.length <= 64) {
            if (tracking.length > 2 && tracking.length <= 64) {
                // if (file) {
                    if (id.length === 24)
                        return true;
                    else alert('No se pudo recuperar ID de pedido');
                // } else alert('No ha subido archivo');
            } else alert('Ingrese guía de seguimiento');
        } else alert('Ingrese paquetería');
        return false;
    };

    const saveTrackingGuide = async () => {
        if (formValidator()) {
            setLoadingButton(true);
            // const req = await uploadOrderFile(file, order_num);
            // if (req.status) {
            //     const fileId = req.resp.fileId;
            //     const req2 = await addTrackingGuide(
            //         { ...values, id, order_num, fileId }
            //     );
            //     if (req2.status) {
            //         setSelectedOrder({
            //             ...selectedOrder,
            //             delivery_status: 'Enviado',
            //             shipping: {
            //                 ...selectedOrder.shipping,
            //                 status: 'shipped',
            //                 tracking_guide: tracking
            //             }, fileId
            //         });
            //         setData(i => i.map(
            //             e => (e.id === id) ? ({
            //                 ...e,
            //                 delivery_status: 'Enviado',
            //                 shipping: {
            //                     ...e.shipping,
            //                     status: 'shipped',
            //                     tracking_guide: tracking
            //                 }, fileId
            //             }) : e
            //         ));
            //     }
            const req = await addTrackingGuide(
                { ...values, id, order_num }
            );
            if (req.status) {
                setSelectedOrder({
                    ...selectedOrder,
                    delivery_status: 'Enviado',
                    shipping: {
                        ...selectedOrder.shipping,
                        partner,
                        status: 'shipped',
                        tracking_guide: tracking
                    }
                });
                setData(i => i.map(
                    e => (e.id === id) ? ({
                        ...e,
                        delivery_status: 'Enviado',
                        shipping: {
                            ...e.shipping,
                            partner,
                            status: 'shipped',
                            tracking_guide: tracking
                        }
                    }) : e
                ));
            }
            setLoadingButton(false);
        }
    };

    // cargar archivo
    // const loadFile = ({ target }) => {
    //     const files = target.files;
    //     if (files.length > 0) {
    //         if (['application/pdf', 'application/msword',
    //             'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(files[0].type))
    //             setValues((e) => ({ ...e, file: files[0] }));
    //         else basicMsg('Formato no soportado');
    //     }
    // };

    return (
        <Stack spacing={2}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start">
            <TextField
                name="partner"
                label="Paquetería"
                variant="standard"
                value={ partner }
                inputProps={{ maxLength: 64 }}
                onChange={ handleInputChange } />
            <FormControl
                variant="standard">
                <InputLabel htmlFor="component-disabled">Guía de seguimiento</InputLabel>
                <Input
                    name="tracking"
                    label="Guía de seguimiento"
                    variant="standard"
                    value={ tracking }
                    inputProps={{ maxLength: 64 }}
                    onChange={ handleInputChange } />
                <FormHelperText>Tracking</FormHelperText>
            </FormControl>

            {/* {
                file &&
                    <Typography variant="overline" display="block" gutterBottom>
                        Archivo subido: { file.name }
                    </Typography>
            }
            <Box>
                <Button
                    variant="outlined"
                    component="label"
                    size="small">
                    Subir archivo
                    <input hidden accept="application/pdf, application/msword,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        name="archivo" type="file" onChange={ loadFile } />
                </Button>
            </Box> */}

            <Stack direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}>
                {
                    loadingButton ?
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined">
                            Actualizando
                        </LoadingButton>
                    :
                        <Button variant="contained"
                            onClick={ saveTrackingGuide }>
                            Despachar
                        </Button>
                }
                
                <Tooltip
                    title={
                        <Typography variant="caption">
                            Actualiza paquetería y envía correo electrónico
                        </Typography>
                    }
                >
                    <IconButton>
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Stack>
    );
};

export default ShipOrder;