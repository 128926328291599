import React, { useState, useEffect, useRef } from 'react'

import banner_1 from '../../../assets/images/banners/Banner_Desktop_DM_01.png'
import banner_2 from '../../../assets/images/banners/Banner_Móvil_DM_01.png'

//MUI
import { Container, Typography, useMediaQuery } from '@mui/material'

//Components
import MedicalDirectoryList from '../MedicalDirectoryList/MedicalDirectoryList'

//Utils
import { MedicalCustomFetch } from '../MedicalCustomFetch'
import { MedicalData } from '../MedicalData'
import MedicalDirectorySkeleton from '../MedicalDirectorySkeleton/MedicalDirectorySkeleton'

const MedicalDirectoryListContainer = () => {

    const matches = useMediaQuery('(max-width: 500px)')

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(false)
        MedicalCustomFetch(MedicalData)
            .then(res => {
                setData(res)
                setLoading(true)
            })
    }, [])

    const myRef = useRef(null);

    return (
        <div className='bg_medical'>
            <div className='banner_medical'>
                {
                    matches
                        ?
                        <img src={banner_2} alt={banner_2} width='100%' />
                        :
                        <img src={banner_1} alt={banner_1} width='100%' />
                }
            </div>

            <Container maxWidth='lg' sx={{ pt: 5 }}>
                <Typography variant='body1' align='center' sx={{ fontSize: '18px', color: '#313131' }}>
                    Contamos con una red estratégica de médicos y especialistas de soporte para la atención oportuna
                    de la Enfermedad Renal Crónica.
                </Typography>
            </Container>

            <div ref={myRef}></div>

            <Container maxWidth='xl' sx={{ mt: 5 }} className='last-view'>
                {
                    loading
                        ?
                        <MedicalDirectoryList data={data} myRef={myRef} />
                        :
                        <MedicalDirectorySkeleton listsToRender={9} />
                }
            </Container>
        </div>
    )
}

export default MedicalDirectoryListContainer