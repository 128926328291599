import React from "react";

//MUI
import Chip from '@mui/material/Chip';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box } from "@mui/material";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
};

const labels = ['Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Clientes nuevos',
            data: [5, 8, 22, 38, 10, 15],
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
    ],
};

const SegundoSemestre = ({ secondSemester }) => {
    return (
        <>
            <Bar options={options} data={secondSemester} />
            <Box sx={{textAlign: 'center', mt: 2.5}}>
                <Chip
                    label="Segundo semestre"
                    size="small"
                    color='primary'
                    sx={{ borderRadius: '6px', p: 1, fontWeight: 'bold' }}
                />
            </Box>
        </>
    )
}

export default SegundoSemestre