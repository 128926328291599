// Frequently Bought Together (FBT)
// Comprados juntos frecuentemente
import React, { useState, useEffect } from 'react';

import {
    IconButton, Typography, Box, FormControl,
    InputLabel, Select, OutlinedInput, Chip,
    MenuItem, Avatar, Tooltip, Checkbox, ListItemText
  } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const ProductFBT = ({ allProducts, setAllProducts }) => {
    const [select, setSelect] = useState(false);
    // nombre de productos
    const [productStrings, setProductStrings] = useState([]);
    // const handleChange = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setProductName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    // useEffect(() => {
    //     const data = JSON.parse(localStorage.getItem("Comprados juntos frecuentemente"));
    //     if (data) {
    //         setProductName(data);
    //     }
    // }, []);

    useEffect(() => {
        // array de título de variantes
        const productStrings = allProducts.filter(e => e.checked).map(({ title }) => {
            return title;
        });
        setProductStrings(productStrings);
        // const data = JSON.parse(localStorage.getItem("Comprados juntos frecuentemente"));
        // if (data) {
        //     setProductName(data);
        // }
    }, [allProducts]);
  
    // useEffect(() => {
    //     localStorage.setItem("Comprados juntos frecuentemente", JSON.stringify(productName));
    // }, [productName]);

    // producto se seleccionó
    const selectProduct = prod_var_id => () => {
        setAllProducts(
            e => e.map(i => i.prod_var_id === prod_var_id ?
                { ...i, checked: !i.checked } : i
            )
        );
    };

    return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="body1" sx={{fontWeight: '600'}}>
                Comprados juntos frecuentemente
            </Typography>
            {
                select === false ?
                    <Box sx={{
                            display: 'flex', justifyContent: 'flex-start',
                            flexWrap: 'wrap', gap: '10px', mt: 2
                        }}>
                        {
                            productStrings.length > 0 ?
                                <>
                                    {
                                        productStrings.map(u => (
                                            <Tooltip key={u} title={u}>
                                                <Avatar className='avatar_fill'>
                                                    { u.slice(0, 2) }
                                                </Avatar>
                                            </Tooltip>
                                        ))
                                    }
                                </>
                            :
                                <Box sx={{ my: 'auto' }}>
                                    <Avatar className='avatar_empty'>
                                        <AssignmentIcon />
                                    </Avatar>
                                </Box>
                        }
                        <Box sx={{ my: 'auto' }}>
                            <IconButton onClick={() => { setSelect(true) }}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Box>
                :
                    <FormControl sx={{ width: '100%', mt: 2 }}>
                        <InputLabel id="demo-multiple-chip-label">Productos</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            onBlur={() => { setSelect(false) }}
                            multiple
                            value={ productStrings }
                            // onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Productos" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value}
                                            avatar={<Avatar sx={{ textTransform: 'uppercase' }}>{value.slice(0, 2)}</Avatar>} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}>
                            {allProducts.map(({ prod_var_id, title, checked }) => (
                                <MenuItem
                                    key={ prod_var_id }
                                    value={ prod_var_id }>
                                    <Checkbox checked={ checked }
                                        onClick={ selectProduct(prod_var_id) } />
                                    <ListItemText primary={ `${title}` } />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
            }
        </Box>
    );
};

export default ProductFBT;