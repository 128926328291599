import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';

import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

//MUI
import {
    Box, Button, Container, Grid, Typography, Breadcrumbs,
    Backdrop, CircularProgress, ListItem, ListItemText,
    useMediaQuery
} from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';

const DetallesPedidoComponent = () => {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width: 950px)');

    const { activeOrder } = useSelector(state => state.user_account);
    const {
        order_num, summary, coupon, address,
        payment, products, shipping
    } = activeOrder;
    const { subtotal, total } = summary;
    const {
        name, lastname, street, ext_num, int_num,
        postalcode, colony, city, state
    } = address;
    const { cost } = shipping;

    useEffect(() => {
        if (order_num === '') navigate('/mi-cuenta/mis-pedidos');
    }, []);

    const goToProduct = url => () => {
        navigate(`/tienda/producto/${url}`);
    };
    
    return (
        <>
            {
                order_num !== '' ?
                    <>
                        <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }}>
                            <Typography variant='h4'
                                sx={{ fontWeight: 'light' }}
                                gutterBottom>
                                Detalles del pedido
                            </Typography>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link underline="hover" color="inherit" to="/mi-cuenta">
                                    Mi cuenta
                                </Link>
                                <Link underline="hover" color="inherit" to="/mi-cuenta/mis-pedidos">
                                    Mis pedidos
                                </Link>
                                <Typography color="text.primary">Detalles del pedido</Typography>
                            </Breadcrumbs>

                            <Box sx={{
                                mt: 5, boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)',
                                p: 3, borderRadius: '5px'
                            }}>
                                <Grid container spacing={2}>
                                    {
                                        products.map(({
                                            title, variant_title, url, category, images
                                        }) =>
                                            <>
                                            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                                {
                                                    images.length > 0 ?
                                                        <img width="100%"
                                                            alt={ images[0].alt }
                                                            src={ `${GOOGLE_DRIVE_CDN}${images[0].id}` }
                                                        />
                                                    :
                                                        <img width="100%"
                                                            alt="producto-nefropolis"
                                                            src={ no_image }
                                                        />
                                                }
                                            </Grid>
                                            <Grid item xl={9} lg={9} md={6} sm={12} xs={12} sx={{ my: 'auto' }}>
                                                <div className="info-producto-pedidos">
                                                    <Box sx={{ my: 'auto' }}>
                                                        <Typography variant='h6'
                                                            sx={{ fontWeight: '600' }} gutterBottom>
                                                            { title } { variant_title }
                                                        </Typography>
                                                        <Typography variant='body2'
                                                            color='text.secondary'
                                                            sx={{ textTransform: 'uppercase' }}>
                                                            Categorías: {
                                                                category.map(
                                                                    (e, i) => i === category.length - 1 ? e : `${e}, `
                                                                )
                                                            }
                                                        </Typography>
                                                        <Typography variant='body2'
                                                            color='text.secondary'
                                                            sx={{ textTransform: 'uppercase' }}>
                                                            Etiquetas: Sin etiquetas
                                                        </Typography>
                                                        <Button
                                                            sx={{ mt: 1 }}
                                                            variant='contained'
                                                            onClick={ goToProduct(url) }
                                                            endIcon={<LoopIcon />}
                                                        >
                                                            Comprar nuevamente
                                                        </Button>
                                                    </Box>
                                                    {/* Buttons */}
                                                    <Box sx={{ my: 'auto' }} className='details_btn_buy'>
                                                        <Button variant='contained'
                                                            sx={{ width: '100%', textTransform: 'none' }}
                                                            onClick={ goToProduct(url) }>
                                                            Escribir una opinión sobre el producto
                                                        </Button>
                                                    </Box>
                                                    {/* Fin Buttons */}
                                                </div>
                                            </Grid>
                                            </>
                                        )
                                    }
                                </Grid>
                            </Box>
                            
                            <Box sx={{ mt: 5 }} className="last-view">
                                <Box sx={{
                                    boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)', p: 3, borderRadius: '3px'
                                }}>
                                    <Typography variant='h5'
                                        sx={{ fontWeight: 'light' }}
                                        gutterBottom>
                                        { `Resumen del pedido: ONL${order_num}` }
                                    </Typography>
                                    <Grid container spacing={2.5}>
                                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                                            <Typography variant='h6' sx={{ fontWeight: '600' }}>
                                                Dirección de envío: 
                                            </Typography>
                                            <Typography variant='body1'>
                                                { `${name} ${lastname}` }
                                            </Typography>
                                            <Typography variant='body1'>
                                                { `${street} #${ext_num} ${
                                                    int_num && `, interior ${int_num}`
                                                }` }
                                            </Typography>
                                            <Typography variant='body1' sx={{ textTransform: 'uppercase' }}>
                                                { colony }
                                            </Typography>
                                            <Typography variant='body1' sx={{ textTransform: 'uppercase' }}>
                                                { city }, { state }, { postalcode }
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                            <Typography variant='h6' sx={{ fontWeight: '600' }}>
                                                Método de pago:
                                            </Typography>
                                            <Typography variant='body1'>
                                                { payment.method }
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
                                            <Typography variant='h6' sx={{ fontWeight: '600' }}>
                                                Resumen:
                                            </Typography>
                                            <Box sx={{
                                                display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'
                                            }}>
                                                <Typography variant='body1'>Productos: </Typography>
                                                <Typography variant='body1' sx={{ color: '#229954' }}>
                                                    ${ subtotal }
                                                </Typography>
                                                {
                                                    products.map(({
                                                        _id, title, variant_title, price,
                                                        quantity, promotion, discount
                                                    }) =>
                                                        <ListItem sx={{ px: 0 }} key={_id}>
                                                            <ListItemText
                                                                primary={
                                                                    variant_title ?
                                                                        `${title} - ${variant_title} (${quantity})`
                                                                    :
                                                                        `${title} (${quantity})`
                                                                }
                                                                secondary={
                                                                    promotion && `Descuento de $${discount.by_promotions}`
                                                                }
                                                            />
                                                            <Typography className="text-success" variant="body2">
                                                                ${
                                                                    promotion ?
                                                                        discount.item_total
                                                                    : price
                                                                }
                                                            </Typography>
                                                        </ListItem>
                                                    )
                                                }
                                            </Box>

                                            {
                                                coupon &&
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        flexWrap: 'wrap'
                                                    }}>
                                                        <Typography variant='body'>Cupón:</Typography>
                                                        <Typography variant='body' sx={{ color: '#229954' }}>
                                                            { coupon.name }
                                                        </Typography>
                                                    </Box>
                                            }

                                            <Box mb={.5} sx={{
                                                display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'
                                            }}>
                                                <Typography variant='body1'>Costo de envío: </Typography>
                                                <Typography variant='body1' sx={{ color: '#229954' }}>
                                                    { cost === 0 ? 'Gratis' : `$${cost}` }
                                                </Typography>
                                            </Box>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                flexWrap: 'wrap'
                                            }}>
                                                <Typography variant='body1' sx={{ fontWeight: '600' }}>
                                                    Total (IVA):
                                                </Typography>
                                                <Typography variant='body1' sx={{ fontWeight: '600' }}>
                                                    ${ total }
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Container>
                    </>
                :
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
            }
        </>
    );
};

export default DetallesPedidoComponent;