import { msg } from "../swal_msg";
import { protectedReq } from "../../helpers/requestHelper";

// lista de productos para ordenamiento
export const productSortList = async () => {
    try {
        const resp = await protectedReq('product/info/sort-list');
        const body = await resp.json();
        if (!body.status) {
            if (['unavailable-token', 'invalid-token'].includes(body.msg)) {
                msg('warning', 'Cuenta',
                'Su sesión ha expirado');
                localStorage.removeItem('token');
                return { status: false, msg: 'redirect' };
            } else if (body.msg === 'server-err')
                msg('error', 'Productos',
                'Ocurrió un incidente. Intente de nuevo, por favor');
        }
        return body;
    } catch (err) {
        msg('error', 'Productos',
        'No se puede mostrar la lista de productos');
        return { status: false };
    };
};

export const saveListOrder = async (data) => {
    try {
        const { shop_products, categories, more_products } = data;
        // crear campo para posición de ordenamiento
        // (tienda / categorías / más productos)
        let allProducts = shop_products.map(({ _id }, i) => ({
            _id, position: { // se asigna posición dentro de tienda
                shop: i, categories: [], more_products: 0
            }
        }));
        // asignar posición de producto en sección 'más productos'
        more_products.map((e, index) => {
            const { _id } = e;
            const i = allProducts.findIndex(u => u._id === _id);
            if (i > -1) // se actualiza en posición de producto
                allProducts[i] = {
                    ...allProducts[i],
                    position: {
                        ...allProducts[i].position,
                        more_products: index
                    }
                };
            return e;
        });
        // asignar posición de producto dentro de las categorías
        const allCategories = categories.map(({ url, products }) => ({
            url, products: products.map(({ _id }, i) => ({ _id, position: i }))
        }));
        for (const e of allCategories) {
            const { url, products } = e;
            for (const prod of products) {
                const { _id, position } = prod;
                const i = allProducts.findIndex(u => u._id === _id);
                if (i > -1) // se incorpora producto dentro de categoría
                    allProducts[i] = {
                        ...allProducts[i],
                        position: {
                            ...allProducts[i].position,
                            categories: [
                                ...allProducts[i].position.categories,
                                { url, position }
                            ]
                        }
                    };
            };
        };
        
        const resp = await protectedReq('product/info/save-list', allProducts, 'POST');
        const body = await resp.json();
        if (!body.status) {
            if (['unavailable-token', 'invalid-token'].includes(body.msg)) {
                msg('warning', 'Cuenta',
                'Su sesión ha expirado');
                localStorage.removeItem('token');
                return { status: false, msg: 'redirect' };
            } else if (body.msg === 'missing-updates')
                msg('error', 'Productos',
                'No se han actualizado todos los pedidos');
            else if (body.msg === 'server-err')
                msg('error', 'Productos',
                'Ocurrió un incidente. Intente de nuevo, por favor');
        }
        return body;
    } catch (err) {
        msg('error', 'Productos',
        'No se pudo actualizar el orden de lista');
        return { status: false };
    };
};