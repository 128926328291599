import React from 'react'

import './footer.css'

//MUI
import { Box, Container } from '@mui/material'

//Components
import Contact from '../Contact/Contact'

//Router dom
import { Link } from 'react-router-dom'

const Footer = ({ FormRef }) => {
    return (
        <footer style={{ textAlign: 'center' }} ref={FormRef}>

            <Box height={6}></Box>

            <Box sx={{ bgcolor: '#fff', py: 5 }}>
                <Container maxWidth='xl'>
                    <Contact />
                </Container>
            </Box>

            <Box className='links_footer' mt={3}>
                <Link to="/politica-de-privacidad" target="_blank" style={{ color: '#fff' }}>Aviso de privacidad</Link>
                <Link to="/terminos-y-condiciones" target="_blank" style={{ color: '#fff' }}>Términos y condiciones</Link>
                <Link to="/politica-de-envios" target="_blank" style={{ color: '#fff' }}>Política de Envíos</Link>
                <Link to="/politica-de-facturacion" target="_blank" style={{ color: '#fff' }}>Política de facturación</Link>
                <Link to={"/sucursales"} target="_blank" style={{ color: '#fff' }}>Sucursales</Link>
                <Link to="/preguntas-frecuentes" target="_blank" style={{ color: '#fff' }}>FAQS</Link>
            </Box>

            {/* Copyright */}
            <Box className='copyright' sx={{ py: 3 }}>
                Nefrópolis © {new Date().getFullYear()}
            </Box>
            {/* Copyright */}
        </footer>
    )
}

export default Footer