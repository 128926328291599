import React from "react";

import './faqs.css'

//Router-dom
import { Link } from 'react-router-dom';

import Faq from 'react-faq-component';

//MUI
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";

const data = {
    title: "Envíos",
    rows: [
        {
            title: "¿Realizan entregas a domicilio?",
            content: `Si tu código postal tiene cobertura con entrega a domicilio, el paquete se te entregará hasta la puerta de tu casa. Si, por el contrario, tu domicilio es de zona extendida o de difícil acceso, el paquete llegará a la oficina ocurre más cercana y tendrás cierto tiempo para recoger el pedido.`,
        },
        {
            title: "¿Realizan envíos a todo el país?",
            content:
                "Sí, realizamos envíos a toda la República Mexicana, a excepción de aquellos lugares donde la paquetería no cuente con cobertura o se reserve el derecho de algún destino.",
        },
        {
            title: "¿Realizan envíos a fuera de México?",
            content: `No, lamentablemente no contamos con envíos fuera de la República Mexicana.`,
        },
        {
            title: "¿Cuánto cuestan los envíos?",
            content: "El costo del envío dependerá del peso del producto/s y de la zona a la que éste se mande.",
        },
        {
            title: "¿Cuáles son los tiempos de entrega?",
            content: <p>El tiempo de entrega aproximado es de 3 a 7 días hábiles dependiendo de tu código postal. Podrás consultar más información sobre nuestra política de envíos en el siguiente enlace: <Link to={"/terminos-y-condiciones"}>terminos y condiciones</Link>.</p>
        },
        {
            title: "¿Cuáles son los días de envío?",
            content: "Los pedidos se envían de lunes a jueves. Los pedidos hechos después del jueves se agendarán hasta el siguiente día hábil de envío."
        }
    ],
};

const compras = {
    title: "Compras",
    rows: [
        {
            title: "¿Qué hago si mi pago no se acredita?",
            content: `Si realizaste tu pago en Oxxo, el tiempo de 24 a 48 horas, si después de ese tiempo, sigue sin cambiar el estatus, comunícate con nosotros. Una vez acreditado el pago, deberás recibir un correo de confirmación en donde se te informará el estatus de tu pedido.`,
        },
        {
            title: "¿Cómo solicito mi factura?",
            content:
                "Puedes solicitar tu factura al momento de hacer tu pedido en nuestra tienda, llenando los datos correspondientes (Razón Social, RFC). Una vez que recibamos tus datos, te haremos llegar la factura solicitada al correo que nos proporcionaste.",
        },
        {
            title: "¿Qué métodos de pago manejan?",
            content: <div>
                <Typography variant="body1" paragraph sx={{ fontWeight: 'bold' }}>Manejamos diversas formas de pago:</Typography>
                <ul>
                    <li >
                        Pagos en OXXO.</li>
                    <li >
                        Pagos con tarjeta de crédito y débito.</li>
                    <li >
                        Depósitos y transferencias bancarias.</li>
                    <li >
                        Paypal.</li>
                    <li >
                        MercadoPago.</li>
                </ul>
            </div>,
        },
        {
            title: "¿Cómo puedo obtener precio de mayoreo?",
            content: <p>Realizando compras mínimas de 5,000 pesos mensuales. Puedes comunicarte con nosotros al <a href="tel:3338268009">33 3826 8009</a> o al <a href="tel:3324966190">3324 96 6190</a> para brindarte más información.</p>,
        },
        {
            title: "¿Quisiera saber cómo puedo ser distribuidor de la marca?",
            content: <p>Puedes ponerte en contacto con nosotros al teléfono <a href="tel:3318011239">3318011239</a> o por correo electrónico <a href="mailto:contacto@nin.com.mx">contacto@nin.com.mx</a></p>,
        },
        {
            title: "¿Dónde puedo comprar su producto directamente?",
            content: <p>Contamos con 14 sucursales a los largo de la República Mexicana, en el siguiente link encontrará todas las direcciones: <Link to={"/sucursales"}>ver todas las sucursales</Link>.</p>
        },
        {
            title: "¿Tienen disponible Ureless?",
            content: <p>Ese producto salió del mercado, por lo que no está disponible. Pero tenemos disponibles estas alternativas que le ayudarán a disminuir la acumulación de toxinas urémicas. <a href="https://tienda.nefropolis.com/product-category/prebioticos-y-probioticos/" target="_blank" rel="noreferrer">Prebióticos y probióticos</a>.</p>
        }
    ],
};

const pedidos = {
    title: "Pedidos",
    rows: [
        {
            title: "¿Cómo saber dónde se encuentra mi pedido?",
            content: <p>Podrás checar el estatus de tu pedido en el siguiente enlace: <a href="https://tienda.nefropolis.com/mi-cuenta/orders/" target="_blank" rel="noreferrer">Mis pedidos</a>, Recuerda que en cuanto se vea reflejado el pago de tu pedido, se programa para envío. Si el estatus no ha cambiado y ya realizaste tu pago, comunícate con nosotros.</p>
        },
        {
            title: 'Quisiera saber el número de guía de mi pedido',
            content: "El número de guía y la paquetería se envía al correo electrónico registrado, una vez que tu pedido sea despachado. Si no lo has recibido y el estatus de tu pedido se encuentra como “completado”, por favor envíanos tu número de pedido y nombre para brindarte la información solicitada. Una vez que tengas el número de guía puedes rastrearlo en la plataforma de la paquetería donde se hizo el envío."
        },
        {
            title: "¿Tienen disponibles los productos en sus sucursales?",
            content: <p>Para consultar la existencia de los productos es necesario comunicarse a la sucursal de su interés, en el siguiente enlace las encontrarás con dirección y número de contacto <a href="https://www.nefropolis.com/sucursales" target="_blank" rel="noreferrer">ver información</a>.</p>
        },
        {
            title: "¿Qué puedo hacer si mi paquete llega mal?",
            content: <p>Si tienes alguna queja, envía tu solicitud de queja al correo electrónico <a href="mailto:asistencia@nefropolis.com">asistencia@nefropolis.com</a> consulta nuestra política de queja de cliente <a href="https://docs.google.com/spreadsheets/d/1sFVVKC6_QkCRS4lzBTVGheB7DilAAWMS/edit#gid=1658879335" target="_blank" rel="noopener noreferrer">aquí</a></p>
        },
        {
            title: "¿Cuentan con devoluciones?",
            content: <p>Si tienes alguna queja, envía tu solicitud de queja al correo electrónico <a href="mailto:asistencia@nefropolis.com">asistencia@nefropolis.com</a> consulta nuestra política de queja de cliente <a href="https://docs.google.com/spreadsheets/d/1sFVVKC6_QkCRS4lzBTVGheB7DilAAWMS/edit#gid=1658879335" target="_blank" rel="noopener noreferrer">aquí</a></p>
        },
        {
            title: "¿Tienen servicio de Nutrición?",
            content: <div>
                <p>En Nefrópolis no contamos con consultas nutricionales, pero te recomendamos comunicarte con Nutrición Renal. Para esto es necesario contar con estudios de laboratorio recientes (no mayor a 2 meses) QS6, EGO, ES6.</p>
                <p>La cita puede ser de manera presencial en GDL o virtual. El horario de servicio es de 09:00 a 17:00 hrs, para mayor información comunícate al 33 1975 6668.</p>
            </div>
        }
    ]
}

const PreguntasFrecuentesComponent = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    return (
        <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }} className="last-view">
            <Typography variant='h4' sx={{ fontWeight: 'light' }} align='center' paragraph>Preguntas Frecuentes</Typography>
            <Box sx={{ mt: 5 }}>
                <Typography variant='h5' sx={{ fontWeight: '600' }} gutterBottom>¿Cómo realizo mi compra?</Typography>
                <Typography variant='body1' paragraph>Comprar en nuestra tienda en línea es muy fácil y rápido.
                    Te invitamos a ver el siguiente video tutorial para comprar
                    en línea: <a href="https://www.youtube.com/watch?v=8BR0659HPDU&ab_channel=Nefr%C3%B3polisSucursales">Compra en línea</a>.
                </Typography>
                <Grid container spacing={0}>
                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12} className="video-responsive" sx={{ mx: 'auto' }}>
                        <iframe src="https://www.youtube.com/embed/8BR0659HPDU?si=cyxbDmm2X9q-xOZf"
                            width="560" height="315"
                            title="Compras nefrópolis"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowFullScreen>
                        </iframe>
                    </Grid>
                </Grid>

                <Box sx={{ mt: 5 }} className="faqs">
                    <Box>
                        <Faq data={data}
                            styles={{
                                rowTitleColor: "#0000FF"
                            }}
                        />
                    </Box>

                    <Box sx={{ mt: 5 }}>
                        <Faq data={compras}
                            styles={{
                                rowTitleColor: "#0000FF"
                            }}
                        />
                    </Box>

                    <Box sx={{ mt: 5 }} >
                        <Faq data={pedidos}
                            styles={{
                                rowTitleColor: "#0000FF"
                            }}
                        />
                    </Box>
                </Box>

            </Box>
        </Container>
    )
}

export default PreguntasFrecuentesComponent