import React from "react";

//MUI
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import { Box, Divider, Typography } from '@mui/material'

const AcumuladoMensual = ({ total_sales }) => {
    const { total, qty } = total_sales;

    const cardStyle = {
        minWidth: '100%'
    }

    return (
        <Card style={cardStyle} >
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <Box sx={{ my: 'auto' }}>
                        <i className="fa-solid fa-cash-register" style={{ fontSize: '1.5rem' }}></i>
                    </Box>

                    <Box sx={{ my: 'auto' }}>
                        <Chip className="font-weight-bold"
                            color="primary"
                            label="Mensual"
                            size="small"
                            sx={{ borderRadius: '6px', p: 1 }}
                        />
                    </Box>
                </Box>
                <Typography variant='body1' sx={{ fontWeight: 'bold', mt: 2.5 }}>
                    Total de ventas: {qty}
                </Typography>
                <Divider sx={{ my: 3 }} />
                <Typography variant='h6' sx={{ fontStyle: 'italic' }}>
                    Ganancias totales: ${ total }
                </Typography>
            </CardContent>
        </Card>
    )
}

export default AcumuladoMensual