import React from 'react'

import './PoliticaQuejas.css'

//MUI
import { Box, Container, Typography, useMediaQuery } from '@mui/material'

const PoliticaQueja = () => {

    const matches = useMediaQuery('(max-width: 950px)')

    return (
        <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }} className='last-view'>
            <Typography variant='h4' sx={{ fontWeight: 'light' }} align='center' paragraph>Política de quejas de cliente</Typography>
            <Box sx={{ mb: 3 }}>
                <Typography variant='h5' paragraph>a) Definiciones</Typography>
                <Typography variant='body1' gutterBottom><b>Empaque primario:</b> envase primario que contiene el producto en su presentación individual (ejemplo: envase de plástico).</Typography>
                <Typography variant='body1' gutterBottom><b>Empaque secundario:</b> empaque de complemento que agrupa varios empaques primarios (ejemplo: caja de cartón).</Typography>
                <Typography variant='body1' gutterBottom><b>Empaque terciario:</b> empaque de embalaje para transporte y almacenamiento, Producto (ejemplo: tarima con varias cajas emplayadas de cartón).</Typography>
                <Typography variant='body1' gutterBottom><b>Defecto de fabricación:</b> son aquellos que surgen en la fase de elaboración y su característica distintiva es que suponen una divergencia entre el producto inicialmente diseñado y solicitado contra el efectivamente fabricado.</Typography>
                <Typography variant='body1' gutterBottom><b>Defecto menor:</b> es una discrepancia mínima con respecto a los estándares del producto, pero no afecta la capacidad de uso del producto (ejemplo: etiqueta levemente maltratada, envase primario con rayones o polvo que pueden ser retirados fácilmente, empaque secundario en mal estado, pero producto intacto).</Typography>
                <Typography variant='body1' gutterBottom><b>Defecto mayor:</b> es el que causa que el producto no funcione de acuerdo al propósito para el cuál fue producido (ejemplo: producto golpeado, producto con empaque primario sucio).</Typography>
                <Typography variant='body1' gutterBottom><b>Defecto crítico:</b> es aquel que se considera peligroso o inseguro para el consumo (ejemplo: producto  contaminado, producto caducado, producto con empaque primario abierto y expuesto).</Typography>
                <Typography variant='body1' gutterBottom><b>Pedido incompleto:</b> Todo aquel pedido que esté incompleto ya sea por desabasto de alguno de los  productos o por descuido al momento de levantar el pedido o empacarlo.</Typography>
                <Typography variant='body1' gutterBottom><b>Pedido equivocado:</b> Cuando un pedido llega con los productos cambiados, equivocados o al domicilio  incorrecto.</Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
                <Typography variant='h5' paragraph>b) Clasificación de la queja</Typography>
                <Typography variant='body1'>Para nosotros es muy importante conocer y atender sus quejas. Para poder dar la solución inmediata y de forma eficaz, le solicitamos que identifique el tipo de queja que tiene y según enseguida se describe; nos haga llegar toda la información correspondiente a su queja para poderla atender en brevedad y de forma oportuna.</Typography>
                <ol className='point_b'>
                    <li>
                        <Typography variant='body1' paragraph>
                            Empaque secundario o terciario dañado o en mal estado.
                        </Typography>
                        <Typography variant='body1' paragraph>Si cuando recibes tu paquete observas cualquier desperfecto y el empaque secundario o terciario está dañado o en mal estado, es imprescindible que conserves el paquete, el embalaje y el contenido para poder comprobar los desperfectos. Te solicitamos que primero hagas revisión del empaque primario (producto) y con certeza hagas una revisión de los productos para observar si hay producto dañado o en mal estado (defecto mayor o crítico).</Typography>
                        <Typography variant='body1'>En caso que el empaque primario (producto) esté en buen estado y no haya sufrido ningún daño mayor o crítico; el producto no será reembolsado ni reemplazado por uno nuevo debido a que el daño del empaque secundario pudo haber sido causado por el manejo del servicio de paquetería. En este caso, te solicitamos que llenes el VT-REG-01 Registro de queja del cliente, para hacernos llegar la evidencia y proceder con las acciones correctivas y preventivas por parte del proveedor de envíos y logística.</Typography>
                    </li>
                    <li>
                        <Typography variant='body1' paragraph>
                            Empaque primario dañado o en mal estado
                        </Typography>
                        <Typography variant='body1' paragraph>Si cuando recibes tu paquete observas cualquier desperfecto y el empaque primario está dañado o en mal estado, es imprescindible que conserves el paquete y el producto para poder comprobar los desperfectos. Te solicitamos que primero hagas revisión del empaque primario (producto) y con certeza hagas una revisión de los productos para observar si hay producto dañado o en mal estado (defecto mayor o crítico).</Typography>
                        <Typography variant='body1' paragraph>En caso que el empaque primario (producto) esté en mal estado o dañado; el producto podrá ser reembolsado después de la revisión de la queja. Debemos analizar si el producto se dañó durante su traslado o es un defecto derivado del proceso de fabricación; para ello te solicitamos que llenes el VT-REG-01 Registro de queja del cliente, para hacernos llegar la evidencia y proceder con las acciones correctivas y preventivas por parte del proveedor de envíos y logística.</Typography>
                        <Typography variant='body1'><b>Nota:</b> Los envases de plástico colapsados sufren este daño cuando se lleva un producto de una parte alta a una parte baja; en este caso el envase tenderá a aplastarse. Este efecto se debe a que la presión atmosférica en el lugar donde se envasa el producto es menor a la del lugar de destino; es decir, la presión fuera del envase será mayor que la presión dentro del envase y este hecho generará un colapso si es que el envase de acuerdo al cambio de presión atmosférica. Este efecto, no se considera como un defecto. </Typography>
                    </li>
                    <li>
                        <Typography variant='body1' paragraph>Producto con defecto de fabricación</Typography>
                        <Typography variant='body1'>Se cataloga como un defecto de fabricación:</Typography>
                        <ol type="a">
                            <li>Etiquetado incorrecto</li>
                            <li>Peso o conteo de producto inadecuado</li>
                            <li>Contaminación visible</li>
                            <li>Liner de garantía sin sellar</li>
                        </ol>
                        <Typography variant='body1'>Posibles respuestas por defecto de fabricación:</Typography>
                        <ol type="a">
                            <li>Reposición del producto, sin retorno del dañado</li>
                            <li>Reposición del producto, con retorno del producto dañado</li>
                            <li>Reclamo improcedente</li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='body1' paragraph>Producto equivocado</Typography>
                        <Typography variant='body1' paragraph>Si recibe un producto diferente al que usted solicitó, es indispensable que no abra los envases ni haga uso del producto; así como que la manipulación del mismo sea cuidadosa con el fin de evitar que se dañe. El desvío pudo haberse dado en el proceso de fabricación y acondicionamiento del producto o por la paquetería, por lo que te solicitamos que llenes el VT-REG-01 Registro de queja del cliente, para hacernos llegar la evidencia y proceder con las acciones correctivas y preventivas.</Typography>
                        <Typography variant='body1'>Posibles respuestas por producto equivocado:</Typography>
                        <ol type='a'>
                            <li>Reposición del producto, sin retorno del equivocado</li>
                            <li>Reposición del producto, con retorno del producto equivocado</li>
                            <li>El cliente decide quedarse con el producto después de un acuerdo comercial que convenga a todas
                                las partes interesadas</li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='body1' paragraph>Queja en el servicio de atención y venta</Typography>
                        <Typography variant='body1'>Este tipo de queja (servicios o atención) se dan cuando por medio de un ejecutivo de atención a ventas o por un el servicio prestado por colaborador de nuestra empresa no cumplieron las expectativas o generaron un descontento en su experiencia de compra. Es nuestro compromiso mejorar la calidad en el servicio y atención de nuestro personal para de esta forma evitar reincidencias y garantizar la satisfacción de tus compras. Este tipo de incidentes se pueden dar de la siguiente manera:</Typography>
                        <ol type='a'>
                            <li>Tiempo de respuesta lento en alguno de los canales de comunicación de la empresa (ejemplo: mail, correo
                                electrónico, WhatsApp, etc.)</li>
                            <li>Tiempo de entrega extendidos (tiempo de fabricación lento y entrega tardía, falta de stock inmediato)</li>
                            <li>Tono de comunicación (ejemplo: grosero, prepotente, altanero, me grita, se burla de mí, etc.)</li>
                            <li>Otros</li>
                        </ol>
                    </li>
                    <li>
                        <Typography variant='body1' paragraph>Pedido incompleto</Typography>
                        <Typography variant='body1' gutterBottom>Es indispensable que al detectar esta situación, se reporte de inmediato con la evidencia correspondiente.</Typography>
                        <Typography variant='body1'>Posibles respuestas al pedido incompleto:</Typography>
                        <ol type='a'>
                            <li>Se envía el producto faltante en el siguiente pedido</li>
                            <li>Se envía el producto faltante en cuanto se tenga en stock</li>
                        </ol>
                    </li>
                </ol>
            </Box>
            <Box sx={{ mb: 3 }}>
                <Typography variant='h5' paragraph>c) Cláusulas</Typography>
                <Typography variant='body1' gutterBottom>1) Toda queja debe ser emitida en un máximo de 48 horas después de haber sido realizada la compra o recibido su producto (Solo aplica para productos enviados)</Typography>
                <Typography variant='body1' gutterBottom>2) A fin de cumplir con las políticas de devolución por defecto, los productos no deberán consumirse en más de un 20% del envase</Typography>
                <Typography variant='body1' gutterBottom>3) No se aceptan productos refrigerados que no cumplan con la cadena de refrigeración, ni medicamentos refrigerados</Typography>
                <Typography variant='body1' gutterBottom>4) Si se presenta un defecto de fabricación en la venta realizada del mostrador de una sucursal como, por ejemplo: producto mal sellado, producto incompleto, visiblemente en mal estado; el producto no deberá ser consumido ni manipulado para poder hacer la reposición de inmediato del producto</Typography>
                <Typography variant='body1' gutterBottom>5) Si la queja es levantada directamente en el punto de venta deberá correr el proceso de evaluación y solución por medio de atención a clientes dentro del plazo establecido en la cláusula 1.</Typography>
                <Typography variant='body1' gutterBottom>6) Quejas de productos de otros laboratorios deben correr con el mismo proceso de evaluación y seguimiento por atención a clientes.</Typography>
                <Typography variant='body1' gutterBottom>7) En todos los puntos de venta el cliente y el ejecutivo de ventas deberán asegurarse de las condiciones en las que sale el producto; así como fecha de caducidad, contenido del envase, el cerrado y sellado del mismo.</Typography>
                <Typography variant='body1' gutterBottom>8) Toda queja está sujeta a revisión y validación para el análisis de la solución al cliente</Typography>
                <Typography variant='body1' gutterBottom>9) Quedan prohibidas todas aquellas soluciones que no determine atención a clientes</Typography>
                <Typography variant='body1' gutterBottom>10) Toda queja de producto deberá contar con 10 fotografías de distintos ángulos, con el fin de asegurar que las imágenes capten el daño; es importante contemplar los 6 lados de la caja, el contenido, el estado del producto y el espacio donde está pegada la guía. Además de realizar el llenado correcto del formato específico para DHL y FedEx.</Typography>
                <Typography variant='body1' gutterBottom>11) Quejas relacionadas a envíos por paquetería deberán ser reportadas en un máximo de 18 hrs para el seguimiento oportuno con el proveedor.</Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
                <Typography variant='h5' paragraph>d) Rastreabilidad y evidencias</Typography>
                <Typography variant='body1' gutterBottom>Estimado cliente, para poder atender tus quejas y sugerencias; es necesario que nos proporciones toda la información correspondiente a la misma. Por ello, te solicitamos que llenes el VT-REG-01 Registro de queja del cliente, para hacernos llegar la evidencia y proceder con las acciones correctivas y preventivas de la incidencia en cuestión. Sólo podrán proceder las quejas que cuenten con el formato completamente contestado y que cuenten con la información solicitada, en caso de no tener la información completa, es probable que no podamos atender tu queja.</Typography>
                <Typography variant='body1'>En el caso de empaques dañados, defectos de fabricación y producto equivocado; es indispensable enviar al menos 5 fotografías de evidencia adjuntos al correo junto con el VT-REG-01 Registro de queja del cliente</Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
                <Typography variant='h5' paragraph>e) Canal de recepción de quejas</Typography>
                <Typography variant='body1'>Para poder dar la atención que se merece, tu queja deberá ser resuelta por nuestro asesor de servicio al cliente. Para ello, puedes ponerte en contacto por el mail: <a href="mailto:asistencia@nefropolis.com">asistencia@nefropolis.com</a>.</Typography>
                <Typography variant='body1'>También puedes solicitar a tu ejecutivo de ventas que te oriente y apoye en esta solicitud; quién derivará con el asesor de servicio al cliente tu queja. </Typography>
            </Box>
            <Box>
                <Typography variant='h5' paragraph>f) Tiempo de respuesta y seguimiento</Typography>
                <Typography variant='body1'>Toda queja de cliente deberá ser emitida en un período no mayor de 48 horas hábiles una vez recibido su pedido; después de ese período de tiempo no procederá la queja. Una vez enviada la queja será emitida en un periodo de tiempo de 48 horas, de lunes a viernes en un horario de 9:00 am a 6:00pm. El tiempo de solución dependerá del tipo de incidencia.</Typography>
            </Box>
        </Container>
    )
}

export default PoliticaQueja