import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

const CategoryCheckboxActive = ({cat, setActiveCategories}) => {
  const { _id, category, check } = cat;
  const onChangeCheckbox = () => {
    setActiveCategories(e => e.map(i => i._id === _id ? { ...i, check: !i.check } : i));
  };
  return (
    <FormControlLabel
      control={ <Checkbox checked={ check } /> }
      label={ category } onChange={ onChangeCheckbox } />
  )
};

export default CategoryCheckboxActive;