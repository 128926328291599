import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import '../Branches.css';
import BranchGoogleMaps from './BranchGoogleMaps';

import { basicMsg } from '../../../../actions/swal_msg';
import { getPostalcodeData } from '../../../../actions/checkout';
import { saveBranchMainImage } from '../../../../actions/admin/branch';

import { FieldValues } from '../../../../helpers/admin/branch/branch';
import { form1Validator } from '../../../../validators/Admin/Branch/BranchValidator';

import { BRANCH_FIELD_ERR, BRANCH_INIT } from '../../../../constants/admin/branch_constants';
import { GOOGLE_DRIVE_CDN, NoImg, CHECKOUT_DISABLED_INPUT } from '../../../../constants/constants';

//MUI
import {
    Box, Button, Container, Divider, FormControl,
    FormHelperText, Grid, MenuItem, TextField,
    Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from "@mui/material/styles";

const ButtonGreen = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#56C596',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontWeight: '600',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#358f69',
        color: '#fff'
    }
}));

const NewBranch = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState(BRANCH_INIT);
    const [inputErr, setInputErr] = useState(BRANCH_FIELD_ERR);

    const [loading, setLoading] = useState(false);

    const {
        name, phone, mobile, email, address, postalCode,
        colony, city, state, links: { whatsapp, facebook }
    } = values;
    const { number, message } = whatsapp;
    const { url } = facebook;

    const {
        name_err, phone_err, mobile_err, email_err, address_err,
        pc_err, colony_err, city_err, state_err,
        wsp_number_err, wsp_message_err, facebook_url_err,
    } = inputErr;

    const name_ref = useRef();
    const phone_ref = useRef();
    const mobile_ref = useRef();
    const email_ref = useRef();
    const address_ref = useRef();
    const postalCode_ref = useRef();
    const city_ref = useRef();
    const state_ref = useRef();
    const wsp_number_ref = useRef();
    const wsp_message_ref = useRef();
    const facebook_url_ref = useRef();
    const iframe_src_ref = useRef();
    const iframe_title_ref = useRef();
    const iframe_width_ref = useRef();
    const iframe_height_ref = useRef();

    // información recuperada con el código postal
    const [colonies, setColonies] = useState([]);

    // deshabilitación de campos
    const [disabledFields, setDisabledFields] = useState(CHECKOUT_DISABLED_INPUT);
    const { cityInput, stateInput } = disabledFields;

    const inputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        FieldValues({ name, value }, setValues, { inputErr, setInputErr });
    };

    // campo pierde foco
    const handleInputBlur = async ({ target }) => {
        // busca información del CP ingresado
        if (target.name === 'postalCode') {
            const resp = await getPostalcodeData(postalCode);
            if (resp.status) {
                const { resp: { municipio, estado, asentamiento } } = resp;
                setValues({
                    ...values,
                    colony: '',
                    city: municipio,
                    state: estado
                });
                setColonies(asentamiento);
                setDisabledFields({
                    ...disabledFields,
                    cityInput: true, stateInput: true
                });
            } else {
                setColonies([]);
                setDisabledFields({
                    ...disabledFields,
                    cityInput: false, stateInput: false
                });
            }
        }
    };

    // imagen principal de sucursal
    const [file, setFile] = useState();
    const handleChange = ({ target }) => {
        if (target.files.length > 0) {
            const ImgFile = target.files[0];
            if (['image/jpeg', 'image/png', 'image/webp'].includes(ImgFile.type))
                setFile(ImgFile);
            else basicMsg('Imagen no compatible');
        }
    };

    //Validación del formulario
    const formValidator = () => {
        const validation = form1Validator({ ...values, file }, setInputErr, 'save');
        if (validation.status) return true;
        else {
            const { field } = validation;
            switch(field) {
                case 'src':
                    iframe_src_ref.current.select();
                    break;
                case 'url':
                    facebook_url_ref.current.select();
                    break;
                case 'msg':
                    wsp_message_ref.current.select();
                    break;
                case 'number':
                    wsp_number_ref.current.select();
                    break;
                case 'state':
                    state_ref.current.select();
                    break;
                case 'city':
                    city_ref.current.select();
                    break;
                case 'postalcode':
                    postalCode_ref.current.select();
                    break;
                case 'address':
                    address_ref.current.select();
                    break;
                case 'email':
                    email_ref.current.select();
                    email_ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
                    break;
                case 'mobile':
                    mobile_ref.current.select();
                    mobile_ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
                    break;
                case 'phone':
                    phone_ref.current.select();
                    phone_ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
                    break;
                case 'name':
                    name_ref.current.select();
                    name_ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
                    break;
                default:
                    break;
            }
        }
        return false;
    };

    // envío de formulario
    const submit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (formValidator()) {
            const req = await saveBranchMainImage({ ...values, file });
            if (req.status)
                navigate('/nefro-admin/sucursales');
        }
        setLoading(false);
    };

    return (
        <>
            <Container maxWidth='xl' sx={{ mb: 5 }} style={{ marginTop: '90px' }}>
                <Typography variant='h5' paragraph>Crear nueva sucursal</Typography>
                <Box sx={{ p: 3, boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)', borderRadius: '6px', bgcolor: '#fff' }}>
                    <Divider textAlign="left" sx={{ my: 3, fontWeight: '600' }}>Información de la sucursal</Divider>
                    <Grid container spacing={4}>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <img src={
                                    file ? URL.createObjectURL(file) : `${GOOGLE_DRIVE_CDN}${NoImg}`
                                } alt='imagen-sucursal' width='100%' height='300'
                                style={{ objectFit: 'contain', border: '1px solid #EAEDED' }}
                            />
                            <div style={{ marginTop: '10px' }}>
                                <input
                                    accept="image/*"
                                    type="file"
                                    id="select-image_suc"
                                    name="images"
                                    style={{ display: 'none' }} onChange={handleChange}
                                />
                                <label htmlFor="select-image_suc">
                                    <Button style={{ borderRadius: 0, textTransform: 'none' }} variant="contained" color="primary" component="span">
                                        Seleccionar imagen
                                    </Button>
                                </label>
                            </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            inputProps={{ className: "scroll_input" }}
                                            variant='outlined'
                                            label='Nombre'
                                            size='small'
                                            name='name'
                                            value={name}
                                            error={name_err}
                                            helperText={name_err ? "Ingrese el nombre de la sucursal" : ""}
                                            inputRef={name_ref}
                                            onChange={inputChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            inputProps={{ className: "scroll_input" }}
                                            variant='outlined'
                                            label='Telefono'
                                            size='small'
                                            name='phone'
                                            value={phone}
                                            error={phone_err}
                                            helperText={phone_err ? "Ingrese el telefono de la sucursal" : ""}
                                            inputRef={phone_ref}
                                            onChange={inputChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            inputProps={{ className: "scroll_input" }}
                                            variant='outlined'
                                            label='Celular'
                                            size='small'
                                            name='mobile'
                                            value={mobile}
                                            error={mobile_err}
                                            helperText={mobile_err ? "Ingrese el celular de la sucursal" : ""}
                                            inputRef={mobile_ref}
                                            onChange={inputChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            inputProps={{ className: "scroll_input" }}
                                            variant='outlined'
                                            label='Correo electrónico'
                                            size='small'
                                            name='email'
                                            value={email}
                                            error={email_err}
                                            helperText={email_err ? "Ingrese el correo electrónico de la sucursal" : ""}
                                            inputRef={email_ref}
                                            onChange={inputChange}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider textAlign="left" sx={{ my: 3, fontWeight: '600' }}>Dirección</Divider>
                    <Grid container spacing={2}>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    variant='outlined'
                                    label='Calle'
                                    size='small'
                                    name='address'
                                    value={address}
                                    error={address_err}
                                    helperText={address_err ? "Ingrese la dirección de la sucursal" : ""}
                                    inputRef={address_ref}
                                    onChange={inputChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    variant='outlined'
                                    label='Código postal'
                                    size='small'
                                    name='postalCode'
                                    value={postalCode}
                                    error={pc_err}
                                    helperText={pc_err ? "Ingrese el código postal de la sucursal" : ""}
                                    inputRef={postalCode_ref}
                                    onChange={inputChange}
                                    onBlur={handleInputBlur}
                                />
                            </FormControl>
                        </Grid>
                        {/* Colonia */}
                        {
                            colonies.length === 0 ?
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Colonia"
                                            size='small'
                                            name="colony"
                                            value={colony}
                                            error={colony_err}
                                            helperText={colony_err ? "Ingrese colonia de la sucursal" : ""}
                                            onChange={inputChange}
                                        />
                                    </FormControl>
                                </Grid>
                            :
                                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            size='small'
                                            name="colony"
                                            value={colony}
                                            error={colony_err}
                                            label="Colonia"
                                            select
                                            onChange={inputChange}
                                        >
                                            {
                                                colonies.map(({ nombre }) =>
                                                    <MenuItem key={nombre}
                                                        value={nombre}>{nombre}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextField>
                                        {
                                            colony_err &&
                                            <FormHelperText sx={{ mx: 0 }}
                                                error={colony_err}>
                                                Ingrese colonia de la sucursal
                                            </FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                        }
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    variant='outlined'
                                    label='Ciudad'
                                    size='small'
                                    name='city'
                                    value={city}
                                    error={city_err}
                                    helperText={city_err ? "Ingrese la ciudad de la sucursal" : ""}
                                    inputRef={city_ref}
                                    disabled={cityInput}
                                    onChange={inputChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    variant='outlined'
                                    label='Estado'
                                    size='small'
                                    name='state'
                                    value={state}
                                    error={state_err}
                                    helperText={state_err ? "Ingrese el estado de la sucursal" : ""}
                                    inputRef={state_ref}
                                    disabled={stateInput}
                                    onChange={inputChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider textAlign="left" sx={{ my: 3, fontWeight: 'bold' }}>Redes sociales</Divider>
                    <Grid container spacing={2}>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    variant='outlined'
                                    label='WhatsApp (número)'
                                    size='small'
                                    name='number'
                                    value={number}
                                    error={wsp_number_err}
                                    helperText={wsp_number_err ? "Ingrese el número de WhatsApp" : ""}
                                    inputRef={wsp_number_ref}
                                    onChange={inputChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    variant='outlined'
                                    label='Mensaje (Whatsapp)'
                                    size='small'
                                    name='message'
                                    value={message}
                                    error={wsp_message_err}
                                    helperText={wsp_message_err ? "Ingrese el mensaje de WhatsApp" : ""}
                                    inputRef={wsp_message_ref}
                                    onChange={inputChange}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    variant='outlined'
                                    label='Facebook (enlace)'
                                    size='small'
                                    name='url'
                                    value={url}
                                    error={facebook_url_err}
                                    helperText={facebook_url_err ? "Ingrese el url de facebook" : ""}
                                    inputRef={facebook_url_ref}
                                    onChange={inputChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <BranchGoogleMaps
                        values={ values }
                        setValues={ setValues }
                        inputErr={ inputErr }
                        setInputErr={ setInputErr }
                        inputRef={ {
                            iframe_src_ref, iframe_title_ref,
                            iframe_width_ref, iframe_height_ref
                        } }
                    />

                    {/* <BranchPromotions /> */}
                    
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                        {
                            loading ?
                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined">
                                    Guardando
                                </LoadingButton>
                            :
                                <ButtonGreen variant='contained' onClick={ submit }>
                                    Crear nueva sucursal
                                </ButtonGreen>
                        }
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default NewBranch