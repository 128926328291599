import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import VariantBatch from './VariantBatch';

import { basicMsg } from '../../../../../actions/swal_msg';
import { variantInit2 } from '../../../../../constants/constants';
import { regex_dec, regex_num } from '../../../../../constants/regex';
import { addVariant, v_accordionExpanded } from '../../../../../actions/product';
import { form1Validator } from '../../../../../validators/Admin/Product/VariantValidator';

import {
    Accordion, AccordionSummary, AccordionDetails,
    Typography, Button, ImageList, ImageListItem,
    IconButton, Grid, FormControl, InputLabel, OutlinedInput,
    TextField, InputAdornment, ImageListItemBar, MenuItem,
    Switch, Select, FormGroup, FormControlLabel, Box
} from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import useMediaQuery from '@mui/material/useMediaQuery';

const VariantAdd = () => {
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width:600px)');
    // producto activo
    const { activeVariants } = useSelector(state => state.product);
    // cambios en accordion
    const { variantAccordion } = useSelector(state => state.ui);
    // variante activa
    const { prod_id, folderId } = activeVariants;
    
    // nueva variante
    const [values, setValues] = useState(variantInit2);
    const {
        variant_title, price, description,
        stock, iva, sat_key, claveUnidad, unidad,
        barcode, odoo_id, available, images
    } = values;
    // lotes de variante
    const [batches, setBatches] = useState([]);

    const label = { inputProps: { 'aria-label': 'New variant switch' } };

    useEffect(() => {
        if (!variantAccordion)
            setValues(variantInit2);
    }, [variantAccordion]);

    // limpiar lotes
    useEffect(() => {
        setBatches([]);
    }, [activeVariants]);

    // cambios en campos
    const handleInputChange = ({target}) => {
        const name = target.name;
        const value = target.value;
        if (['iva', 'stock', 'sat_key', 'odoo_id'].includes(name)) {
            if (value === '' || regex_num.test(value))
                setValues(e => ({ ...e, [name]: value }));
        } else if (name === 'price') {
            if (value === '' || regex_dec.test(value))
                setValues(e => ({ ...e, [name]: value }));
        } else if (name === 'claveUnidad')
            setValues(e => ({ ...e, [name]: value.toUpperCase() }));
        else setValues(e => ({ ...e, [name]: value }));
    };

    // cambios en switch
    const handleSwitchChange = ({ target }) => {
        setValues(e => ({ ...e, [target.name]: target.checked }));
    };

    // subir imágenes de ordenador
    const loadedImages = ({ target }) => {
        let count = images.length; // total de imágenes
        let exceeded = false; // se alcanzó el límite?
        if (count >= 6) {
            basicMsg('No puede agregar más imágenes en esta variante');
            return;
        }
        if (target.files.length > 0) {
            const target_files = [];
            [...target.files].forEach(element => {
                if (count >= 6) exceeded = true;
                else {
                    if (['image/jpeg', 'image/png', 'image/webp'].includes(element.type)) {
                        target_files.push({ img: element, alt: '' });
                        count++;
                    }
                }
            });
            setValues(e => ({
                ...e,
                images: [...e.images, ...target_files]
            }));
            if (exceeded) basicMsg('Se alcanzó el límite de imágenes');
        }
    };

    // remueve imágenes de producto
    const removeImg = name => () => {
        setValues(e => ({
            ...e,
            images: e.images.filter(i => (i.img.name !== name))
        }));
    };

    // reordenar imágenes
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        setValues(e => ({
            ...e,
            images: items
        }));
    };

    // editar propiedad ALT de imágenes
    const handleAltChange = name => ({ target }) => {
        const alt = target.value;
        setValues(e => ({
            ...e,
            images: e.images.map(u => (u.img.name === name) ? {
                ...u,
                alt
            } : u)
        }));
    };

    // guardar nueva variante de producto
    const saveVariant = () => {
        // validación básica de formulario
        if (form1Validator(values)) {
            dispatch(addVariant(prod_id, folderId, values, batches));
        }
    };
    
    // estado del acordión
    const handleChange = () => {
        dispatch(v_accordionExpanded(!variantAccordion));
    };
    
    return (
        <Accordion expanded={ variantAccordion } onChange={ handleChange }>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel-header">
            <Typography>Agregar nueva</Typography>
          </AccordionSummary>
          <AccordionDetails>

            <Box textAlign='center' sx={{ mb: 2 }}>
                <Button 
                    variant="contained"
                    component="label">
                    Subir archivo
                    <input hidden accept="image/*"
                        multiple type="file" onChange={ loadedImages } />
                </Button>
            </Box>

            <DragDropContext onDragEnd={ handleOnDragEnd }>
                <Droppable droppableId="new_images">
                    {(provided) => (
                    <ImageList cols={ matches ? 2 : 4 }
                        { ...provided.droppableProps } ref={ provided.innerRef }>
                        { images.map((item, index) => {
                            return (
                                <Draggable key={ item.img.name } draggableId={ item.img.name }
                                    index={ index }>
                                    {(prov) => (
                                        <ImageListItem cols={1} rows={1}
                                            ref={ prov.innerRef } { ...prov.draggableProps }
                                            { ...prov.dragHandleProps }>
                                            <img
                                                src={ URL.createObjectURL(item.img) }
                                                alt={ item.img.name }
                                                loading="lazy" />
                                            <ImageListItemBar
                                                title={
                                                    <TextField label="Atributo ALT"
                                                        variant="standard" size="small"
                                                        onChange={ handleAltChange(item.img.name) } />
                                                }
                                                actionIcon={
                                                    <IconButton
                                                        sx={{ color: 'rgba(0, 0, 0, 0.5)' }}
                                                        aria-label={`imagen #${index} de variante`}
                                                        onClick={ removeImg(item.img.name)} >
                                                        <RemoveCircleIcon />
                                                    </IconButton>
                                                }
                                                position="below" />
                                        </ImageListItem>
                                    )}
                                </Draggable>
                            );
                        }) }
                        { provided.placeholder }
                    </ImageList>
                    )}
                </Droppable>
            </DragDropContext>

            <TextField sx={{ mb: 2 }}
                fullWidth
                name="variant_title"
                label="Título de variante"
                variant="outlined"
                value={ variant_title }
                inputProps={{ maxLength: 200 }}
                onChange={ handleInputChange } />

            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="variant-input-price">Precio</InputLabel>
                        <OutlinedInput
                            id="variant-input-price"
                            name="price"
                            label="Precio"
                            value={ price }
                            onChange={ handleInputChange }
                            inputProps={{ maxLength: 8 }}
                            startAdornment={
                                <InputAdornment position="start">$</InputAdornment>
                            } />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField sx={{ mb: 2 }}
                        fullWidth
                        name="barcode"
                        label="Código de barras"
                        variant="outlined"
                        value={ barcode }
                        inputProps={{ maxLength: 32 }}
                        onChange={ handleInputChange } />
                </Grid>
            </Grid>

            <TextField sx={{ mb: 2 }}
                fullWidth
                name="description"
                label="Descripción"
                multiline
                variant="outlined"
                rows={4}
                value={ description }
                inputProps={{ maxLength: 2000 }}
                onChange={ handleInputChange } />
            
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                    <TextField fullWidth
                        name="sat_key"
                        label="Clave SAT"
                        variant="outlined"
                        value={ sat_key }
                        inputProps={{ maxLength: 8 }}
                        onChange={ handleInputChange } />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="iva-variant-label">IVA</InputLabel>
                        <Select
                            labelId="iva-variant-label"
                            name="iva"
                            value={ iva }
                            label="IVA"
                            onChange={ handleInputChange }>
                            <MenuItem value={0}>0%</MenuItem>
                            <MenuItem value={16}>16%</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                    <TextField fullWidth
                        name="claveUnidad"
                        label="Clave unidad"
                        variant="outlined"
                        value={ claveUnidad }
                        inputProps={{ maxLength: 3 }}
                        onChange={ handleInputChange } />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth
                        name="unidad"
                        label="Unidad de medidad"
                        variant="outlined"
                        value={ unidad }
                        inputProps={{ maxLength: 64 }}
                        onChange={ handleInputChange } />
                </Grid>
            </Grid>

            <TextField sx={{ mb: 2 }}
                fullWidth
                name="stock"
                label="Cantidad disponible"
                variant="outlined"
                value={ stock }
                inputProps={{ maxLength: 5 }}
                onChange={ handleInputChange } />
            
            <TextField sx={{ mb: 2 }}
                fullWidth
                name="odoo_id"
                label="ID de producto en Odoo"
                variant="outlined"
                value={ odoo_id }
                inputProps={{ maxLength: 32 }}
                onChange={ handleInputChange } />

            <FormGroup sx={{ mb: 2 }}>
                <FormControlLabel control={
                    <Switch { ...label }
                        name="available"
                        checked={ available }
                        onChange={ handleSwitchChange } />
                }
                label="Mostrar en tienda" />
            </FormGroup>

            <VariantBatch
                batches={batches}
                setBatches={setBatches} />

            <Button fullWidth
                sx={{ mt: 2 }}
                variant="outlined"
                startIcon={<SaveAltIcon />}
                onClick={ saveVariant }>
                Guardar variante
            </Button>
          </AccordionDetails>
        </Accordion>
    );
};

export default VariantAdd;