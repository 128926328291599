import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { categoriesLoad } from '../../../../actions/category';
import { loadProductList, productDefault } from '../../../../actions/product';

import ProductModal from './ProductModal';
import ProductListItem from './ProductListItem';
import ProductListMenu from './ProductListMenu';
import VariantAdd from './VariantList/VariantAdd';
import VariantItem from './VariantList/VariantItem';
import VariantModal from './VariantList/VariantModal';

import { Alert, Grid, Typography, Fab, Input, InputAdornment, Container } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import useMediaQuery from '@mui/material/useMediaQuery';

const ProductList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:600px)');
  // lista de productos y variantes activas
  const { products, activeVariants } = useSelector(state => state.product);
  
  const { prod_id, variants } = activeVariants;
  const [anchorEl, setAnchorEl] = useState(null);
  // búsqueda
  const [search, setSearch] = useState("");
  // productos filtrados por búsqueda
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    dispatch(productDefault()); // reset (cambiar funcion)
    
    dispatch(loadProductList()); // productos
    dispatch(categoriesLoad()); // categorías
  }, []);

  useEffect(() => {
    filterProducts(search);
  }, [products]);

  const handleOpen = () => {
    navigate('/nefro-admin/producto-agregar');
  };

  const handleSearchChange = ({ target }) => {
    const _value = target.value;
    setSearch(_value);
    if (products.length > 0) {
      filterProducts(_value);
    }
  };

  // filtrar productos de acuerdo a campo de texto
  const filterProducts = (_value) => {
    if (_value.trim() === '')
      setFiltered(products);
    else {
      const _search = _value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      setFiltered(products.filter((e) =>
        e.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search)
        ||
        e.prod_variants.find(u => u.variant_title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search))
        ||
        e.category.find(u => u.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search))
      ));
    }
  };

  const myRef = useRef(null);

  return (
    <>
      <Container maxWidth='xl' sx={{ mt: 12 }} ref={myRef}>
        <Input
          fullWidth
          id="search_into"
          placeholder="Buscar producto..."
          value={search}
          onChange={handleSearchChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Container>

      <Container maxWidth='xxl' sx={{ mt: 5 }}>
        <Grid container
          direction={matches ? "row" : "row-reverse"}
          spacing={4} sx={{ p: 2 }}>

          <Grid item xs={12} sm={12} md={6}>
            <div className='products_admin_fixed'>
              {
                prod_id !== '' ?
                  variants.length > 0 ?
                    <>
                      {
                        variants.map((variant, i) =>
                          <VariantItem key={variant._id}
                            pos={i + 1}
                            prod_id={prod_id}
                            variant={variant} />
                        )
                      }
                      <VariantAdd />
                    </>
                  :
                    <>
                      <Typography variant="overline" display="block" gutterBottom>
                        Producto sin variantes
                      </Typography>
                      <VariantAdd />
                    </>
                :
                  <>
                  <Typography variant="overline" display="block" gutterBottom>
                    Seleccione producto para editar
                  </Typography>
                  <VariantAdd />
                  </>
              }

            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {
              products.length <= 0 ?
                <Grid item xs={12} md={4}>
                  <Alert severity="info">
                    No tiene productos en tienda
                  </Alert>
                </Grid>
              :
                filtered.map(product =>
                  <ProductListItem key={product._id} product={product} myRef={myRef}
                    anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                )
            }
          </Grid>
          <ProductModal />
          <VariantModal />
          <ProductListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
          <Fab
            color='primary'
            aria-label="add" sx={{
              position: "fixed",
              bottom: 20,
              right: 20,
              zIndex: 1
            }}
            onClick={handleOpen}>
            <AddIcon />
          </Fab>
        </Grid>
      </Container>
    </>
  );
};

export default ProductList;