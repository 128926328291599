import * as React from 'react';

import DrawerList from './Drawer/DrawerList';

import {
  Box, AppBar, Drawer, IconButton, Toolbar
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

//Router-dom
import { Link } from 'react-router-dom';

//IMG
import logo from '../../assets/images/Logo_Nefropolis.png';

function ResponsiveDrawer() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: 'flex', mb: 8 }}>
      <AppBar position="fixed" style={{ background: '#ffff', zIndex: '2' }}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, color: 'black' }}>
            <MenuIcon />
          </IconButton>
          <div className="text-right">
            <Link to="/">
              <img src={logo} width="130" alt="Nefrópolis" />
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <Box component="nav"
        aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          PaperProps={{
            sx: { width: "280px" },
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          {<DrawerList open={() => { setMobileOpen(false) }} />}
        </Drawer>
      </Box>
    </Box>
  );
};

export default ResponsiveDrawer;