// promoción compra A y recibe descuento en B / promoción compra A y B, recibe descuento en ambos
import React, { useEffect, useState }  from 'react';

import ProductSelect from './PromoTwo/ProductSelect';

import { prodPromo } from '../../../../constants/constants';
import { savePromoOne } from '../../../../actions/admin/promotion';
import { basicMsg, confirmDialog } from '../../../../actions/swal_msg';
import { getProductPromotions } from '../../../../helpers/admin/promotion/promotionHelper';

import {
    Typography, Grid, Stack, Card, CardMedia,
    CardActionArea, CardContent, Button, Alert,
    TextField
} from "@mui/material";
import StarPurple500Icon from '@mui/icons-material/StarPurple500';

const num_regex = /^[0-9]*$/;
const num_dot_regex = /^(?!,$)[\d,.]+$/;

const init = {
    discount_text: 'Promoción: Descuento %',
    type: 'Descuento porcentual',
    maxLength: 3
};

const PromoFour = ({
    promotion,
    activePromotion, setActivePromotion,
    otherPromotion, setOtherPromotion,
    today
}) => {
    const { promo, start_date, end_date } = promotion;

    const [data, setData] = useState(init);
    // tipo de descuento / longitud de campo
    const { discount_text, type, maxLength } = data;

    // búsqueda de producto
    const [discount, setDiscount] = useState('');
    // producto A seleccionado
    const [productA, setProductA] = useState(prodPromo);
    // producto B seleccionado
    const [productB, setProductB] = useState(prodPromo);
    
    useEffect(() => {
        if (promo === 'Compra$Descuento')
            setData({
                ...data,
                discount_text: `Promoción: En compra de Producto A, recibe ${discount} pesos de descuento en Producto B`,
                type: 'Descuento neto', maxLength: 10
            });
        else {
            if (promo === 'Compra%Descuento')
                setData({
                    ...data,
                    discount_text: `Promoción: En compra de Producto A, recibe ${discount}% de descuento en Producto B`
                });
            else if (promo === 'CompraAB%Descuento')
                setData({
                    ...data,
                    discount_text: `Promoción: En compra de Producto A y producto B, recibe ${discount}% de descuento en ambos`
                });
            else
                setData(init);
        }
        // Descuento porcentual
    }, [promotion]);

    // producto buscando
    const handleInputChange = ({ target }) => {
        const value = target.value;
        if (promo === 'Compra$Descuento') {
            if (value === '' || num_dot_regex.test(value))
                setDiscount(value);
        } else {
            if (value === '' || num_regex.test(value))
                setDiscount(value);
        }
    };

    // productos A y B fueron seleccionados, se introdujo descuento
    const validation = () => {
        // cuenta el número de puntos en descuento
        const count = (discount.match(/\./g) || []).length;
        // valor de descuento a numérico
        const num = Number(discount);
        
        if (productA.variant_id === '')
            basicMsg('Seleccione producto A');
        else if (productB.variant_id === '')
            basicMsg('Seleccione producto B');
        else if (discount === '')
            basicMsg(promo === 'Compra$Descuento' ?
                'Seleccione la cantidad de descuento' :
                'Seleccione el porcentaje de descuento'
            );
        else if (count > 1)
            basicMsg('La cantidad de descuento es incorrecta');
        else if (promo === 'Compra%Descuento' && (num < 1 || num > 100))
            basicMsg('Porcentaje de descuento es incorrecto');
        else if (promo === 'Compra$Descuento' && num > productB.price)
            basicMsg('Descuento supera precio de producto');
        else return true;
        return false;
    };

    // verificar si productos tienen promoción
    const checkPromos = async () => {
        if (validation()) {
            // mismos productos seleccionados
            if (productA.variant_id === productB.variant_id) basicMsg('Producto A y B son iguales');
            else {
                const ActivePromotions = getProductPromotions(promo, activePromotion, otherPromotion, { productA, productB });
                if (ActivePromotions.status) {
                    if (await confirmDialog('¿Registrar la promoción?', ActivePromotions.html, 'Registrar'))
                        savePromo();
                } else savePromo();
            }
        }
    };

    // guardar promoción en BD
    const savePromo = async () => {
        const req = await savePromoOne({
            ...promotion,
            discount,
            variant_a: {
                ...productA,
                _id: productA.variant_id,
            },
            variant_b: {
                ...productB,
                _id: productB.variant_id,
            },
        }, 'save-promo-four');
        if (req.status) {
            // promoción activa
            if (start_date <= today && end_date >= today)
                setActivePromotion(e => ([
                    ...e,
                    {
                        ...promotion,
                        discount,
                        variant_a: {
                            ...productA,
                            _id: productA.variant_id,
                        },
                        variant_b: {
                            ...productB,
                            _id: productB.variant_id,
                        },
                        _id: req.resp._id,
                    }
                ]));
            else // promoción guardada (no activa)
                setOtherPromotion(e => ([
                    ...e,
                    {
                        ...promotion,
                        discount,
                        variant_a: {
                            ...productA,
                            _id: productA.variant_id,
                        },
                        variant_b: {
                            ...productB,
                            _id: productB.variant_id,
                        },
                        _id: req.resp._id,
                    }
                ]));
            // limpiar campos
            setProductA(prodPromo);
            setProductB(prodPromo);
            setDiscount('');
        }
    };

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{ mb: 2 }}>
                    <Typography variant="h6">
                        { discount_text }
                    </Typography>
                    <StarPurple500Icon />
                </Stack>

                <Grid container spacing={4}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        {
                            productA.variant_id !== '' &&
                            <>
                                <Card sx={{ width: '100%' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            sx={{ height: 200, backgroundSize: 'contain' }}
                                            image={productA.image_id}
                                            alt="product-A"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {`${productA.title} ${productA.variant_title}`.trim()}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Producto A seleccionado
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </>
                        }
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        {
                            productB.variant_id !== '' &&
                            <>
                                <Card sx={{ width: '100%' }}>
                                    <CardActionArea>
                                        <CardMedia
                                            sx={{ height: 200, backgroundSize: 'contain' }}
                                            image={productB.image_id}
                                            alt="product-B"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {`${productB.title} ${productB.variant_title}`.trim()}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Producto B seleccionado
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </>
                        }
                    </Grid>
                </Grid>

                <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    name="discount"
                    label={type}
                    variant="standard"
                    inputProps={{
                        maxLength
                    }}
                    value={ discount }
                    onChange={ handleInputChange } />

                <ProductSelect sx={{ my: 5 }}
                    text={'Producto A'}
                    setProduct={ setProductA } />

                <ProductSelect sx={{ my: 5 }}
                    text={'Producto B'}
                    setProduct={ setProductB } />

                {
                    (productA.variant_id !== '' &&
                        productB.variant_id !== '') ?
                        <Button variant="contained"
                            onClick={ checkPromos }>
                            Guardar promoción
                        </Button>
                    :
                        <Alert severity="info" sx={{ mt: 5 }}>
                            Seleccione todos los productos para guardar la promoción
                        </Alert>
                }
            </Grid>
        </Grid>
    );
};

export default PromoFour;