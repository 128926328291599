import React from 'react';

import {
    DialogActions, DialogContent, IconButton, Typography,
    Button, Dialog, DialogTitle
} from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';

const CSFDialog = ({ onClose, open, inputRef }) => {

    const closeDialog = () => {
        inputRef.current.click();
        onClose();
    };

    return (
        <Dialog onClose={ onClose } open={open}>
            <DialogTitle>
                Suba un archivo CSF
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={ closeDialog }
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}>
                <AttachFileIcon />
            </IconButton>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Para poder emitir correctamente su factura, es necesario que ingrese los datos de facturación en el formulario y adjunte su Constancia de Situación Fiscal (CSF) donde se muestre el detalle de sus datos.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={ onClose }>
                    Entendido
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CSFDialog;