// user_account_billing
import axios from 'axios';

import { basicMsg, valMsg } from '../../swal_msg';
import { protectedReq } from "../../../helpers/requestHelper";

const base_url = process.env.REACT_APP_NEFRO_API_URL;

export const myBillingAddresses = async() => {
    try {
        const resp = await protectedReq('account/billing/addresses');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'server-err')
                valMsg('error', 'Direcciones de facturación',
                'Ocurrió un incidente. Intente de nuevo, por favor');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Direcciones de facturación',
        'No se pueden mostrar sus direcciones');
        return { status: false };
    };
};

// se sube documento CIF
const uploadCIFDocument = async(file) => {
    try {
        const data = new FormData();
        data.append('file', file);
        const url = `${base_url}/account/billing/cif`;
        // token sesión usuario
        const token = localStorage.getItem('token') || '';
        const config = {
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            }
        };
        return await axios.post(url, data, config).then(function(response) {
            const { data: { status, msg, resp } } = response;
            if (status) return { status: true, resp };
            else return { status: false, msg };
        })
        .catch(function(err) {
            return { status: false, msg: 'req-err' };
        });
    } catch (err) {
        return { status: false, msg: 'axios-err' };
    };
};

export const addBillingAddress = async(model) => {
    try {
        const {
            billing_city, billing_colony,
            billing_postalcode, billing_state,
            billing_tel, cif, ...billing
        } = model;
        const axiosResp = await uploadCIFDocument(cif);
        if (axiosResp.status) {
            const { resp: { fileId } } = axiosResp;
            const data = {
                ...billing,
                tel: billing_tel,
                colony: billing_colony,
                postalcode: billing_postalcode,
                city: billing_city,
                state: billing_state,
                cif: fileId
            };
            const resp = await protectedReq('account/billing/save', data, 'POST');
            const body = await resp.json();
            if (body.status)
                basicMsg('Dirección de facturación guardada');
            else {
                if (body.msg === 'not-saved')
                    valMsg('warning', 'Dirección de facturación',
                    'No se guardó su dirección. Intente de nuevo, por favor');
                else if (body.msg === 'server-err')
                    valMsg('error', 'Dirección de facturación',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else valMsg('warning', 'Dirección de facturación',
                    'Dirección no guardada. Inténtelo de nuevo, por favor');
            }
            return body;
        } else {
            const { msg } = axiosResp;
            if (msg === 'file-not-uploaded')
                valMsg('warning', 'Dirección de facturación',
                'No se ha subido su documento CIF');
            else if (['folder-not-saved', 'folder-not-created'].includes(msg))
                valMsg('warning', 'Dirección de facturación',
                'No se ha guardado su documento CIF');
            else if (msg === 'account-not-found')
                valMsg('warning', 'Dirección de facturación',
                'Incidente al sincronizar su documento CIF');
            else if (msg === 'no-files')
                valMsg('warning', 'Dirección de facturación',
                'No se ha enviado su documento CIF');
            else if (msg === 'server-err')
                valMsg('error', 'Dirección de facturación',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Dirección de facturación',
                'Dirección no guardada. Inténtelo de nuevo, por favor');
        }
        return { status: false };
    } catch (err) {
        valMsg('error', 'Dirección de facturación',
        'No se pudo guardar su dirección');
        return { status: false };
    };
};

export const updBillingAddress = async(model) => {
    try {
        const {
            billing_city, billing_colony,
            billing_postalcode, billing_state,
            billing_tel, ...billing
        } = model;
        const data = {
            ...billing,
            tel: billing_tel,
            colony: billing_colony,
            postalcode: billing_postalcode,
            city: billing_city,
            state: billing_state
        };
        const resp = await protectedReq('account/billing/update', data, 'PUT');
        const body = await resp.json();
        if (body.status) {
            basicMsg('Dirección de facturación actualizada');
            return { status: true, resp: data };
        } else {
            if (body.msg === 'not-updated')
                valMsg('warning', 'Facturación',
                'Dirección no actualizada');
            else if (body.msg === 'miss')
                valMsg('warning', 'Facturación',
                'Falta información en su dirección de facturación');
            else if (body.msg === 'server-err')
                valMsg('error', 'Facturación',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Facturación',
                'No hemos podido actualizar su dirección');
            return { status: false };
        }
    } catch (err) {
        valMsg('error', 'Facturación',
        'No se pudo actualizar su dirección');
        return { status: false };
    };
};

export const delBillingAddress = async(values) => {
    try {
        const { _id, cif } = values;
        const resp = await protectedReq('account/billing/delete', { _id, cif }, 'DELETE');
        const body = await resp.json();
        if (body.status)
            basicMsg('Dirección de facturación eliminada');
        else {
            if (body.msg === 'not-deleted')
                valMsg('warning', 'Facturación',
                'No se eliminó su dirección. Intente de nuevo, por favor');
            else if (body.msg === 'server-err')
                valMsg('error', 'Facturación',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else valMsg('warning', 'Facturación',
                'No hemos podido eliminar su dirección');
        }
        return body;
    } catch (err) {
        valMsg('error', 'Facturación',
        'No se pudo eliminar su dirección');
        return false;
    }
};