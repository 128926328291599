import React from 'react'

import '../MedicalDirectory.css'

//Router dom
import { Link } from 'react-router-dom';

import nefro_place from '../../../assets/resources/Nefro_place.png'

//MUI
import { Box, Avatar, Typography, Button, useMediaQuery, Divider, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const ButtonDirectory = styled(Button)(({ theme }) => ({
    backgroundColor: '#2A2AC0',
    color: '#fff',
    padding: '10px 42px 10px 42px',
    borderRadius: '30px',
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: '#242493',
        color: '#fff'
    },
}));

const MedicalDirectoryItem = ({ item }) => {

    const matches = useMediaQuery('(max-width: 500px)')

    const matchesIcon = useMediaQuery('(max-width: 375px)')

    return (
        <>
            {
                matches
                    ?
                    <>
                        <Box className='medical_directory_mobile'>
                            <Box my='auto'>
                                {
                                    item.img.length > 0
                                        ?
                                        <Avatar alt={item.img} src={item.img} sx={{ pointerEvents: 'none' }}
                                            className='medical_directory_avatar_mobile'
                                        />
                                        :
                                        <Avatar
                                            sx={{ fontWeight: 'bold', backgroundColor: '#2699FB' }}
                                            className='medical_directory_avatar_mobile'
                                        >
                                            {item.name.charAt(0)}{item.lastname.charAt(0)}
                                        </Avatar>
                                }
                            </Box>
                            <Box>
                                <Typography variant='body1' sx={{ fontWeight: '700' }}>
                                    {item.name} {item.lastname}
                                </Typography>
                                <Typography variant='body1' sx={{ color: '#5552B2', fontWeight: '600' }} gutterBottom>{item.specialty}</Typography>
                                <Typography variant='body1' sx={{ textTransform: 'uppercase', color: '#205072' }} paragraph>Cedula - {item.ic}</Typography>
                                <Typography variant='body2' sx={{ color: '#205072', my: 'auto' }}>
                                    {item.street} {item.int_num} <br />
                                    {item.colony} C.P. {item.postalcode} <br />
                                    {item.city}, {item.state}
                                </Typography>
                            </Box>
                            <Box my='auto' className='btn_directory_more'>
                                <IconButton LinkComponent={Link} to={`/directorio-medico/${item.name}`}>
                                    {
                                        matchesIcon
                                            ?
                                            <MoreHorizIcon />
                                            :
                                            <MoreVertIcon />
                                    }
                                </IconButton>
                            </Box>
                        </Box>
                        <Divider sx={{ mt: 3.5 }} />
                    </>
                    :
                    <Box sx={{ px: 4, py: 3, border: '1px solid #56C596', borderRadius: '15px', textAlign: 'center', boxShadow: 'rgba(50, 157, 156, 0.30) 0px 5px 15px' }}>
                        <Box sx={{ mb: 2.5 }}>
                            {
                                item.img.length > 0
                                    ?
                                    <Avatar alt={item.img} src={item.img} sx={{ width: 135, height: 135, mx: 'auto', pointerEvents: 'none' }} />
                                    :
                                    <Avatar sx={{ width: 135, height: 135, mx: 'auto', fontSize: '32px', fontWeight: 'bold', backgroundColor: '#2699FB' }}>
                                        {item.name.charAt(0)}{item.lastname.charAt(0)}
                                    </Avatar>
                            }
                        </Box>
                        <Typography variant='body1' sx={{ fontWeight: '700' }}>
                            {item.name} {item.lastname}
                        </Typography>
                        <Typography variant='body1' sx={{ color: '#5552B2', fontWeight: '600' }} gutterBottom>{item.specialty}</Typography>
                        <Typography variant='body1' sx={{ textTransform: 'uppercase', color: '#205072' }}>Cedula - {item.ic}</Typography>
                        <Box display='flex' justifyContent='space-evenly' flexWrap='wrap' gap='15px' my={4}>
                            <Box sx={{my: 'auto'}}>
                                <img src={nefro_place} width='27' alt={nefro_place} />
                            </Box>
                            <Typography variant='body2' sx={{ color: '#205072', my: 'auto' }} align='center'>
                                {item.street} {item.int_num} <br />
                                {item.colony} C.P. {item.postalcode} <br />
                                {item.city}, {item.state}
                            </Typography>
                        </Box>
                        <ButtonDirectory LinkComponent={Link} to={`/directorio-medico/${item.name}`}>Ver más</ButtonDirectory>
                    </Box>
            }
        </>
    )
}

export default MedicalDirectoryItem