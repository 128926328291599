import React from 'react';

import { ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const KeywordItem = ({ keyword, setProdValues }) => {
    // eliminar keyword
    const onClick = () => {
        setProdValues(e => ({
            ...e,
            keywords: e.keywords.filter(i => (keyword !== i))
            })
        );
    };

    return (
        <ListItem
            secondaryAction={
            <IconButton edge="end" aria-label="delete"
                onClick={ onClick }>
                <DeleteIcon />
            </IconButton>
            }>
            <ListItemText
                primary={ keyword } />
        </ListItem>
    );
};

export default KeywordItem;