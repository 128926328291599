import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DateRange } from 'react-date-range';
import es from "date-fns/locale/es";
import { format, startOfDay, endOfDay, addWeeks } from 'date-fns';

import ProductList from './ProductList';
import CouponSummary from './CouponSummary';

import { couponSave } from '../../../../actions/coupon';
import { loadProductList } from '../../../../actions/product';
import { COUPON_INIT } from '../../../../constants/shop/coupon/coupon_const';
import { form1Validator } from '../../../../validators/Admin/Coupon/CouponValidator';
import {
	CouponHandleInputChange, CouponHandleSwitchChange
} from '../../../../helpers/handlers/admin/coupon/couponHandler';

import {
	FormControlLabel, Grid, Card, CardContent, TextField,
	Typography, Stack, IconButton, ToggleButton, ToggleButtonGroup,
	FormControl, FormGroup, RadioGroup, Radio, Checkbox, InputLabel,
	Select, MenuItem, Switch, Button, Snackbar, Dialog, DialogTitle,
	DialogContent, Box, DialogActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const today = new Date();
const dayStart = startOfDay(today);
const todayPlusWeek = addWeeks(endOfDay(today), 1);

const CouponSave = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// referencias a campos
	const nInputRef = useRef();

	const [coupon, setCoupon] = useState(COUPON_INIT);
	const {
		_class, name, description, use_method, discount,
		available, restrictions, purchase_requirements
	} = coupon;
	const { type, _value } = discount;
	const { one_per_user, limited, quantity } = restrictions;
	const {
		active, min_purchase_amount, minimum_items_quantity,
		applies_to
	} = purchase_requirements;
	const { amount } = min_purchase_amount;
	const { qty } = minimum_items_quantity;
	const { category, product } = applies_to;
	// productos seleccionados
	const { list } = product;

	// errores en campos
	const [inputError, setInputError] = useState({
		name_err: false
	});
	const { name_err } = inputError;

	const [snackbarStatus, setSnackbarStatus] = useState({
		open: false,
		message: ''
	});
	const { open, message } = snackbarStatus;

	// rango de fechas en <DateRange />
    const [range, setRange] = useState([
        {
            startDate: dayStart,
            endDate: todayPlusWeek,
            key: 'selection'
        }
    ]);
    // duración de promoción <DateRange />
    const [defaultRange, setDefaultRange] = useState({
        startDate: dayStart,
        endDate: todayPlusWeek,
    });
    const { startDate, endDate } = defaultRange;
    // estado de diálogo
    const [dateDialog, setDateDialog] = useState(false);

	// cerrar snackbar
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') return;
		setSnackbarStatus(e => ({ ...e, open: false }));
	};

	// action de snackbar
	const action = (
		<React.Fragment>
			<Button color="secondary" size="small" onClick={ handleClose }>
				OK
			</Button>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={ handleClose }>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	useEffect(() => {
		dispatch(loadProductList()); // productos
	}, []);

	useEffect(() => {
		if (use_method === 'auto')
			setCoupon(e => ({ ...e, purchase_requirements: { ...e.purchase_requirements, active: true }}));
	}, [use_method]);

	useEffect(() => {
		setCoupon(e => ({ ...e, discount: { ...e.discount, _value: '' }}));
	}, [type]);

	useEffect(() => {
		if (!active)
			setCoupon(e => ({
				...e,
				purchase_requirements: {
					...e.purchase_requirements,
					min_purchase_amount: {
						...e.purchase_requirements.min_purchase_amount,
						active: false
					},
					minimum_items_quantity: {
						...e.purchase_requirements.minimum_items_quantity,
						active: false
					}
				}
			}));
	}, [active]);

	const handleInputChange = ({ target }) => {
		const name = target.name;
		const value = name === 'name' ? target.value.toUpperCase() : target.value;
		const handle = CouponHandleInputChange({ name, value }, coupon, setInputError);
		if (handle.status) setCoupon(handle.coupon);
	};

	const handleToggle = ({ target }, _method) => {
		setCoupon(e => ({ ...e, use_method: _method }));
	};

	const handleChange = ({ target }) => {
		const handle = CouponHandleSwitchChange(target, coupon);
		if (handle.status) setCoupon(handle.coupon);
	};

	// guardar cupón
	const save = async () => {
		const form = form1Validator(coupon, setInputError);
		if (form.status) {
			const req1 = await couponSave({ ...coupon, startDate, endDate });
			if (req1.status) navigate('/nefro-admin/cupones');
		} else
			if (form.msg) setSnackbarStatus({ open: true, message: form.msg });
	};

	// cambio de fecha
    const filterByDate = () => {
        setDefaultRange({
            ...defaultRange,
            startDate: range[0].startDate, // inicio día
            endDate: endOfDay(range[0].endDate) // fin día
        });
        setDateDialog(false);
    };
	
	return (
		<Grid container spacing={2} sx={{ backgroundColor: '#F5F5F5', p: 4 }}>
			<Grid item xs={12}>
				<Stack direction="row">
					<IconButton aria-label="go-back"
						onClick={ () => navigate('/nefro-admin/cupones') }>
						<ArrowBackIcon />
					</IconButton>
					<Typography variant="h6" gutterBottom>
						Registrar nuevo cupón
					</Typography>
				</Stack>
			</Grid>
			<Grid item xs={12} md={8}>
				<Card sx={{ mb: 2 }}>
					<CardContent>
						<Typography variant="body1"
							color="text.secondary"
							gutterBottom
							sx={{ fontWeight: 'Bold' }}
						>
							Clase de cupón
						</Typography>
						<FormControl>
							<RadioGroup
								aria-labelledby="coupon-type-radio-buttons-group-label"
								name="_class"
								value={ _class }
								onChange={ handleInputChange }
							>
								<FormControlLabel value="order_discount"
									control={
										<Radio size="small" sx={{
											'&.Mui-checked': {
												color:  '#93D50A',
											},
										}} />
									}
									label="Monto de descuento de pedido" />
								{
									_class === 'order_discount' &&
										<Stack>
											<FormControl fullWidth
												variant="standard" sx={{ mb: 1 }}>
												<InputLabel id="discount-type-select-label">
													Tipo de descuento
												</InputLabel>
												<Select
													labelId="discount-type-select-label"
													id="discount-type-select"
													name="type"
													value={ type }
													label="Tipo de descuento"
													onChange={ handleInputChange }
												>
													<MenuItem value="percentage">Descuento porcentual</MenuItem>
													<MenuItem value="fixed">Descuento fijo $</MenuItem>
												</Select>
											</FormControl>
											<TextField sx={{ mb: 2 }}
												fullWidth
												name="_value"
												label={ type === 'percentage' ? 'Descuento %' : 'Descuento $' }
												variant="standard"
												inputProps={{ maxLength: type === 'percentage' ? 3 : 8 }}
												value={ _value }
												onChange={ handleInputChange } />
										</Stack>
								}
								<FormControlLabel value="free_shipping"
									control={
										<Radio size="small" sx={{
											'&.Mui-checked': {
												color:  '#93D50A',
											},
										}} />
									}
									label="Envío gratis" />
							</RadioGroup>
						</FormControl>
					</CardContent>
				</Card>

				<Card sx={{ mb: 2 }}>
					<CardContent>
						<Typography variant="body1"
							color="text.secondary"
							gutterBottom
							sx={{ fontWeight: 'Bold' }}
						>
							Información de cupón
						</Typography>

						<TextField sx={{ mb: 2 }}
							fullWidth
							name="name"
							label="Código de descuento"
							variant="standard"
							error={ name_err }
							helperText={ name_err ? "Ingrese nombre de cupón" : "" }
							inputProps={{ maxLength: 30 }}
							inputRef={ nInputRef }
							value={ name }
							onChange={ handleInputChange } />
							
						<TextField sx={{ mb: 2 }}
							fullWidth
							name="description"
							label="Descripción"
							multiline
          					rows={2}
							helperText="Se muestra cuando se aplica el código"
							inputProps={{ maxLength: 100 }}
							value={ description }
							onChange={ handleInputChange } />

						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={2}
							sx={{ mb: 2 }}>
							<Typography variant="body1">
								Duración de la promoción
							</Typography>
							<IconButton onClick={() => { setDateDialog(true) }} sx={{ my: 'auto' }}>
								<CalendarMonthIcon />
							</IconButton>
						</Stack>

						<Stack
							direction="row"
							spacing={2}>
							<TextField
								size='small'
								fullWidth
								disabled
								label="Fecha de inicio"
								value={`${format(startDate, "d 'de' MMMM yyyy", { locale: es })}`} />
							<TextField
								size='small'
								fullWidth
								disabled
								label="Fecha de finalización"
								value={`${format(endDate, "d 'de' MMMM yyyy", { locale: es })}`} />
						</Stack>
					</CardContent>
				</Card>

				<Card sx={{ mb: 2 }}>
					<CardContent>
						<Typography variant="body1"
							color="text.secondary"
							gutterBottom
							sx={{ fontWeight: 'Bold' }}
						>
							Método
						</Typography>
						<ToggleButtonGroup
							size="small"
							color="primary"
							name="use_method"
							value={ use_method }
							exclusive
							onChange={ handleToggle }
							aria-label="Platform"
							sx={{ mb: 2 }}>
							<ToggleButton value="by_code">Mediante código</ToggleButton>
							<ToggleButton value="auto">Descuento automático</ToggleButton>
						</ToggleButtonGroup>

						<FormGroup>
							<FormControlLabel
								control={
									<Switch name='available'
										checked={ available }
										onChange={ handleChange }
										inputProps={{ 'aria-label': 'coupon-availability' }}
									/>
								}
								label={
									available ? 'Cupón activo' : 'Cupón deshabilitado'
								}
							/>
						</FormGroup>
					</CardContent>
				</Card>

				<Card sx={{ mb: 2 }}>
					<CardContent>
						<Typography variant="body1"
							color="text.secondary"
							gutterBottom
							sx={{ fontWeight: 'Bold' }}
						>
							Requisitos de compra
						</Typography>
						<FormGroup>
							<FormControlLabel control={
								<Switch
									name="purchase_requirements"
									checked={ active }
									onChange={ handleChange }
								/>
							} label={
								active ? 'Cumplir los requisitos siguientes:' : 'Sin requisitos'
							} />
						</FormGroup>

						<FormGroup>
							<FormControlLabel control={
								<Checkbox
									name="min_purchase_amount"
									disabled={ !active }
									checked={ min_purchase_amount.active }
									onChange={ handleChange }
								/>
							} label="Monto mínimo de compra ($)" />
							
							{
								min_purchase_amount.active &&
									<TextField sx={{ mb: 2 }}
										fullWidth
										name="amount"
										label="Monto"
										variant="standard"
										inputProps={{ maxLength: 10 }}
										value={ amount }
										onChange={ handleInputChange } />
							}

							<FormControlLabel control={
								<Checkbox
									name="minimum_items_quantity"
									disabled={ !active }
									checked={ minimum_items_quantity.active }
									onChange={ handleChange }
								/>
							} label="Cantidad mínima de artículos" />
							
							{
								minimum_items_quantity.active &&
									<TextField sx={{ mb: 2 }}
										fullWidth
										name="qty"
										label="Cantidad"
										variant="standard"
										inputProps={{ maxLength: 4 }}
										value={ qty }
										onChange={ handleInputChange } />
							}
							
							<FormControlLabel control={
								<Checkbox
									disabled={ true }
									checked={ category.active }
								/>
							} label="Aplica al tener productos en carrito con las siguientes categorías" />

							<FormControlLabel control={
								<Checkbox
									name="product_req_active"
									disabled={ !active }
									checked={ product.active }
									onChange={ handleChange }
								/>
							} label="Aplica al tener los siguientes productos en carrito" />
							{/* } label="Aplica en las siguientes categorías" /> */}
							
							{
								product.active &&
									<ProductList
										list={ list }
										setCoupon={ setCoupon }
									/>
							}
						</FormGroup>
					</CardContent>
				</Card>

				<Card sx={{ mb: 2 }}>
					<CardContent>
						<Typography variant="body1"
							color="text.secondary"
							gutterBottom
							sx={{ fontWeight: 'Bold' }}
						>
							Límite de cupón
						</Typography>

						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox name="limited"
										checked={ limited }
										onChange={ handleChange }
										/>
									} label="Definir cantidad de cupones disponibles"
							/>
							
							{
								limited &&
									<TextField sx={{ mb: 2 }}
										fullWidth
										name="quantity"
										label="Cantidad"
										variant="standard"
										inputProps={{ maxLength: 4 }}
										value={ quantity }
										onChange={ handleInputChange } />
							}

							{/* {
								limited &&
									<Divider />
							} */}
							<FormControlLabel
								control={
									<Checkbox name="one_per_user"
										checked={ one_per_user }
										onChange={ handleChange }
										/>
									} label="Un uso por usuario" />
									
							<FormControlLabel
								control={
									<Checkbox disabled
										name="limited"
										checked={ false }
									/>
									} label="Cupón exclusivo de cliente (No disponible)"
							/>
							<TextField sx={{ mb: 2 }}
								fullWidth
								disabled
								name="email"
								label="Correo de cliente (deshabilitado)"
								variant="standard"
								inputProps={{ maxLength: 120 }}
							/>
						</FormGroup>
					</CardContent>
				</Card>

				<Card sx={{ mb: 2 }}>
					<CardContent>
						<Typography variant="body1"
							color="text.secondary"
							gutterBottom
							sx={{ fontWeight: 'Bold' }}
						>
							Compatibilidad con otras promociones (deshabilitado)
						</Typography>

						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox name="products"
										disabled={ true }
										checked={ true }
										onChange={ handleInputChange }
										/>
									} label="Cupón se puede combinar con promociones" />
							<FormControlLabel
								control={
									<Checkbox name="products"
										disabled={ true }
										checked={ false }
										onChange={ handleInputChange }
										/>
									} label="Cupón se puede combinar con otros cupones de descuento" />
						</FormGroup>
					</CardContent>
				</Card>

				<Button fullWidth
					variant="contained"
					onClick={ save }>
					Guardar
				</Button>
			</Grid>

			<Grid item xs={12} md={4}>
				<CouponSummary coupon={ coupon } />
			</Grid>

			<Grid item xs={12}>
				<Snackbar
					open={ open }
					autoHideDuration={ 6000 }
					onClose={ handleClose }
					message={ message }
					action={ action }
				/>
			</Grid>

			<Grid item xs={12}>
				<Dialog
					open={dateDialog}
					onClose={() => { setDateDialog(false) }}>
					<DialogTitle>
						Filtrar por fecha
					</DialogTitle>
					<DialogContent>
						<Box sx={{ border: '1px solid #e1e1e1', borderRadius: '3px' }}>
							<DateRange
								editableDateInputs={true}
								onChange={item => setRange([item.selection])}
								moveRangeOnFirstSelection={false}
								ranges={range}
								locale={es}
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => { setDateDialog(false) }}>Cancelar</Button>
						<Button onClick={filterByDate}>Seleccionar</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		</Grid>
	);
};

export default CouponSave;