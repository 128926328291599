// peticiones a backend
import axios from 'axios';

const base_url = process.env.REACT_APP_NEFRO_API_URL; // servidor en Node.js (peticiones - MongoDB)
const nefro_url = process.env.REACT_APP_API_NEFROPOLIS_URL; // API de Nefrópolis (dirección; cp, colonia, ciudad, estado)

// peticiones de aplicación
const request = (endpoint, obj, method = 'GET') => {
    const url = `${base_url}/${endpoint}`;
    if (method === 'GET') {
        return fetch(url, { method });
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(obj)
        });
    }
};

// petición restringida (sólo administradores)
const protectedReq = (endpoint, obj, method = 'GET') => {
    const url = `${base_url}/${endpoint}`;
    // token guardado
    const token = localStorage.getItem('token') || '';
    if (method === 'GET') {
        return fetch(url, {
            method,
            headers: {
                'x-token': token
            }
        });
    } else {
        return fetch(url, {
            method,
            headers: {
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify(obj)
        });
    }
};

// petición a api.nefropolis
const nefroRequest = async(endpoint, form, method = 'GET') => {
    const url = `${nefro_url}/${endpoint}`;
    if (method === 'GET') {
        const resp = await axios.get(url);
        return resp;
    } else {
        const resp = await axios.post(url, form);
        return resp;
    }
};

const axiosProtectedReq = async(endpoint, data, method = 'GET') => {
    // token guardado
    const token = localStorage.getItem('token') || '';
    const config = {
        headers: {
            'Content-type': 'application/json',
            'x-token': token
        }
    };
    try {
        const url = `${base_url}/${endpoint}`;
        const resp = method === 'POST' ?
            await axios.post(url, data, config)
        :
            await axios.get(url, config);
        if (resp) return resp.data;
        else return { status: false, msg: 'request-err' };
    } catch (err) {
        return { status: false, msg: 'request-err' };
    }
};

export {
    request,
    protectedReq,
    nefroRequest,
    axiosProtectedReq
};