import React from 'react';

import { CircularProgress, Alert, AlertTitle,
Link, Typography } from '@mui/material';

const RequestMessage = ({ msg }) => {
    switch (msg) {
        case 'default':
            return (
                <>
                    <Typography variant="overline" display="block" gutterBottom>
                        Cargando
                    </Typography>
                    <CircularProgress />
                </>
            );
        case 'website-err':
            return (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    Ocurrió un incidente en sitio web — <strong>Recargue la página</strong>
                </Alert>
            );
        case 'server-err':
            return (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    Ocurrió un incidente — <strong>Recargue la página</strong>
                </Alert>
            );
        case 'not-found':
            return (
                <Alert severity="warning">
                    <AlertTitle>Solicitud no encontrada</AlertTitle>
                    Solicitud de restablecimiento de contraseña no encontrada — <strong>Realice una nueva solicitud en </strong>
                    <Link href="/login">Nefrópolis</Link>
                </Alert>
            );
        case 'request-expired':
            return (
                <Alert severity="warning">
                    <AlertTitle>Solicitud ha expirado</AlertTitle>
                    Solicitud de restablecimiento de contraseña ha expirado — <strong>Realice una nueva solicitud en </strong>
                    <Link href="/login">Nefrópolis</Link>
                </Alert>
            );
        case 'pwd-reset':
            return (
                <Alert severity="info">
                    <AlertTitle>Solicitud utilizada</AlertTitle>
                    Ya ha restablecido su contraseña anteriormente — <strong>Realice una nueva solicitud en </strong>
                    <Link href="/login">Nefrópolis</Link>
                </Alert>
            );
        case 'valid-request':
            return (
                <Alert severity="success">Restablezca su contraseña</Alert>
            );
        default:
            return (
                <Alert severity="info">
                    <AlertTitle>Solicitud</AlertTitle>
                    No se puede restablecer la contraseña — <strong>Recargue la página o inicie sesión en </strong>
                    <Link href="/login">Nefrópolis</Link>
                </Alert>
            );
    }
};

export default RequestMessage;