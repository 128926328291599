import React, { useState } from 'react';
import * as XLSX from 'xlsx';

import { basicMsg } from '../../../../actions/swal_msg';
import { massiveLoad } from '../../../../actions/product';

import FileFieldList from './FileFieldList';
import AdditionalNoteList from './AdditionalNoteList';

import { Grid, Button, Typography, Snackbar, Container, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ProductLoad = () => {
    // archivo a subir
    const [file, setFile] = useState('');
    // botón de carga
    const [loading, setLoading] = useState(false);
    // array de productos tomados del archivo
    const [productsJson, setProductsJson] = useState([]);
    // snackbar
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('Carga masiva finalizada');

    // subida de archivo
    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            setFile(e.target.files[0].name);
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setProductsJson(json);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    };

    // subida de productos
    const submit = async () => {
        if (productsJson.length > 0) {
            setLoading(true);
            const status = await massiveLoad(productsJson);
            setLoading(false);
            if (status) {
                setFile('');
                setProductsJson([]);
                setMessage('Carga masiva finalizada');
            } else setMessage('Carga masiva fallida');
            setOpen(true);
        } else basicMsg('Suba archivo');
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <Container maxWidth='xxl'>
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} md={6}>
                    {
                        file &&
                        <Typography variant="overline" display="block" gutterBottom>
                            Archivo subido: {file}
                        </Typography>
                    }
                    <Box>
                        <Button
                            variant="contained"
                            component="label">
                            Subir archivo
                            <input hidden accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                multiple type="file" onChange={readUploadFile} />
                        </Button>
                    </Box>
                    <LoadingButton
                        sx={{ mt: 2 }}
                        endIcon={<CloudUploadIcon />}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        onClick={submit}>
                        Iniciar carga
                    </LoadingButton>
                    <AdditionalNoteList />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FileFieldList />
                </Grid>
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={message}
                    action={action}
                />
            </Grid>
        </Container>
    );
};

export default ProductLoad;