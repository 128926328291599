export const getFilesCount = (row, imgFile) => {
    let filesCount = 0;
    let files = [];
    if (imgFile !== '') {
        files = [...files, imgFile];
        filesCount++;
    }
    for (const e of row) {
        for (const col of e.col) {
            if ((col.type === 'img' || col.type === 'video')
                && !col.uploaded) {
                    files = [...files, col.file];
                    filesCount++;
                }
        }
    }
    return {
        filesCount, files
    };
};