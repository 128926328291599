import React, { useState } from 'react'

//CSS
import '../SignUp.css'

//Router dom
import { Link } from 'react-router-dom';

//MUI
import { Grid, IconButton, TextField, InputLabel, MenuItem, FormControl, Select, FormHelperText, Container, Box, Typography, Button } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { DesktopDatePicker } from "@mui/lab";
import esLocale from 'date-fns/locale/es';

const InformacionPersonal = (props) => {

    //Fecha de nacimiento
    const [startDate, setStartDate] = React.useState(null);

    //Genero
    const [genero, setGenero] = useState('');
    const handleChange = (event) => {
        setGenero(event.target.value);
    };

    //Estado
    const [estado, setEstado] = useState('');
    const handleChangeEstado = (event) => {
        setEstado(event.target.value);
    };

    //Colonia
    const [colonia, setColonia] = useState('');
    const handleChangeColonia = (event) => {
        setColonia(event.target.value);
    };

    return (
        <>
            <Container maxWidth='xl' sx={{ my: 5 }}>
                {/* Title header */}
                <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} >
                    <Box sx={{ my: 'auto', mr: 1 }}>
                        <IconButton onClick={props.prevStep} color="primary">
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ my: 'auto' }}>
                        <Typography variant='h4' align='center' sx={{ fontWeight: 'light' }}>Información personal</Typography>
                    </Box>
                </Box>
                {/* Fin title */}

                {/* Form */}
                <Box sx={{ mt: 4 }}>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {/* Fecha de nacimiento */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                <div className="desktop-datepicker-register">
                                    <DesktopDatePicker
                                        label="Fecha de nacimiento"
                                        value={startDate}
                                        onChange={(newValue) => {
                                            setStartDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                                    />
                                </div>

                                <div className="mobile-datepicker-register">
                                    <MobileDatePicker
                                        label="Fecha de nacimiento"
                                        value={startDate}
                                        onChange={(newValue) => {
                                            setStartDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
                                    />
                                </div>
                            </LocalizationProvider>
                        </Grid>

                        {/* Genero */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Genero</InputLabel>
                                <Select
                                    id=""
                                    value={genero}
                                    label="Genero"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={10}>Masculino</MenuItem>
                                    <MenuItem value={20}>Femenido</MenuItem>
                                    <MenuItem value={30}>Prefiero no decirlo</MenuItem>
                                </Select>
                                <Link to="" style={{ textDecoration: 'none' }}>
                                    <FormHelperText>¿por qué me preguntan esto?</FormHelperText>
                                </Link>
                            </FormControl>
                        </Grid>

                        {/* Pais */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField id="" label="País" variant="outlined" disabled defaultValue={'MÉXICO'} />
                            </FormControl>
                        </Grid>

                        {/* Estado */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Estado</InputLabel>
                                <Select
                                    id=""
                                    value={estado}
                                    label="Estado"
                                    onChange={handleChangeEstado}
                                >
                                    <MenuItem value={10}>1</MenuItem>
                                    <MenuItem value={20}>2</MenuItem>
                                    <MenuItem value={30}>3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* municipio */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField id="" label="Municipio" variant="outlined" />
                            </FormControl>
                        </Grid>

                        {/* Codigo postal */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField id="" label="Código postal" variant="outlined" />
                            </FormControl>
                        </Grid>

                        {/* Calle y numero */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField id="" label="Calle y número" variant="outlined" />
                            </FormControl>
                        </Grid>

                        {/* colonia */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Colonia</InputLabel>
                                <Select
                                    id=""
                                    value={colonia}
                                    label="Colonia"
                                    onChange={handleChangeColonia}
                                >
                                    <MenuItem value={10}>1</MenuItem>
                                    <MenuItem value={20}>2</MenuItem>
                                    <MenuItem value={30}>3</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* Cruce de calles */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField id="" label="Entre las calles" variant="outlined" />
                            </FormControl>
                        </Grid>

                        {/* Referencias */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField id="" label="Referencias" variant="outlined" />
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Box sx={{ mt: 3 }}>
                        <Button variant='contained' onClick={props.nextstep}>Terminar</Button>
                    </Box>
                </Box>
                {/* Fin form */}

            </Container>
        </>
    )
}

export default InformacionPersonal