import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ShippingMethods from '../Cart/ShippingMethods';

import { loadCart } from '../../../actions/shop';
import { processOrder } from '../../../actions/payment';
import { basicMsg, msg } from '../../../actions/swal_msg';
import { checkOrderCoupon, saveClientData } from '../../../actions/checkout';

import { shippingAddressFormValidator } from '../../../validators/ShippingAddressValidator';

import {
    Box, Button, Divider, Grid, List, ListItem,
    ListItemText, Typography, Stack
} from "@mui/material";

const TuOrden = ({
    page, setPage,
    shippingValues, billingValues,
    paymentValues, setPaymentValues
}) => {
    const dispatch = useDispatch();
    const {
        cart: {
            item, // productos
            summary, // resumen
            item_summary, // tu orden
            cart_promotion,
            coupon, // cupón aplicado
        }, // carrito
        shipping: {
            methods: { // métodos de envío
                selected // seleccionado
            }
        }
    } = useSelector(state => state.shop);
    
    const { name } = coupon; // nombre cupón
    const { _id, cost } = selected; // ID y costo de método de envío
    const { promos_discount, discount, total } = summary;
    // correo de cliente
    const { email } = shippingValues;
    // datos de pago
    const { payment_method } = paymentValues;

    const validate = async () => {
        // page 0 = información personal
        if (page === 0) {
            if (_id) {
                if (shippingAddressFormValidator(shippingValues, billingValues)) {
                    if (coupon && coupon.name) { // hay cupón aplicado
                        const CouponCheck = await checkOrderCoupon({ email, name });
                        if (CouponCheck.status) { // cupón disponible y aplicado
                            goToPage(); // ir a métodos de pago
                            saveClientData(shippingValues);
                        } else { // cupón no está disponible
                            const { resp: { cart_msg, ...cartData } } = CouponCheck;
                            if (cart_msg.includes('refresh-cart')) // se recuperó carrito
                                dispatch(loadCart(cartData)); // se recarga carrito sin cupón
                            saveClientData(shippingValues);
                        }
                    } else {
                        goToPage(); // ir a métodos de pago
                        saveClientData(shippingValues);
                    }
                }
            } else basicMsg('Seleccione método de envío');
        } else if (page === 1) {
            // page 1 = métodos de pago
            if (payment_method.length > 2
                && payment_method.length <= 30) {
                const req = await processOrder({
                    addressValues: shippingValues,
                    billingValues,
                    summary,
                    shipping: selected,
                    cart: item,
                    couponData: coupon,
                    payment_method
                });
                
                if (req.status) { // pedido registrado
                    if (payment_method === 'PayPal') {
                        const { order_id, nefro_reference } = req.resp;
                        setPaymentValues({
                            ...paymentValues,
                            order_id,
                            nefro_reference
                        });
                    // } else if (payment_method === 'EvoPayments') {
                    //     const { session_id } = req.resp;
                    //     setPaymentValues({
                    //         ...paymentValues,
                    //         session_id
                    //     });
                    } else if (payment_method === 'MercadoPago') {
                        const { id } = req.resp;
                        setPaymentValues({
                            ...paymentValues,
                            preference_id: id
                        });
                    }
                    else if (payment_method === 'OpenPay') {
                        const { redirect_url } = req.resp;
                        setPaymentValues({
                            ...paymentValues,
                            redirect_url
                        });
                    }
                    goToPage();
                } else { // actualizar carrito (en caso de promociones expiradas)
                    if (req.cartData)
                        dispatch(loadCart(req.cartData));
                }
            } else msg('info', 'Checkout',
                'No ha seleccionado método de pago');
        }
    };

    const goToPage = () => {
        setPage(e => e + 1);
        window.scrollTo({
            top: 0,
        });
    };
    
    return (
        <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
            <Box sx={{ p: 3 }} className="total-form-fixed">
                <Typography variant='h5'
                    sx={{ fontWeight: '600', textAlign: { xs: 'center', sm: 'center', md: 'left' } }}>
                    Tu orden
                </Typography>
                <List disablePadding>
                    {
                        item_summary.map((e, i) =>
                            <ListItem sx={{ px: 0 }} key={i}>
                                <ListItemText
                                    primary={`${e.title} ${e.variant_title} (${e.quantity})`}
                                    secondary={
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary">
                                            { e.promo_text !== '' ? e.promo_text : '' }
                                        </Typography>
                                    }
                                />
                                {
                                    (
                                        e.discount_by_promo !== 0 ||
                                        e.discount_by_coupon !== 0
                                    ) ?
                                        <Stack spacing={1}>
                                            <Typography sx={{ color: '#000', fontWeight: '600', textDecoration: 'line-through' }}
                                                variant="body2">
                                                ${ e.item_subtotal }
                                            </Typography>
                                            <Typography sx={{ color: '#229954', fontWeight: '600' }} variant="body2">
                                                ${ e.item_total }
                                            </Typography>
                                        </Stack>
                                    :
                                        <Typography sx={{ color: '#229954', fontWeight: '600' }} variant="body2">
                                            ${ e.item_subtotal }
                                        </Typography>
                                }
                            </ListItem>
                        )
                    }
                    <Divider sx={{ my: 2 }} />
                    {
                        name !== '' && (
                            <ListItem sx={{ px: 0 }}>
                                <ListItemText primary={ name } secondary={
                                    coupon._class === 'order_discount' ?
                                        `Cupón ${
                                            coupon.discount.type === 'fixed' ?
                                                `$${coupon.discount._value}`
                                            :
                                                `${coupon.discount._value}%`
                                        }`
                                    :
                                        `Cupón Envío Gratis`
                                    }
                                />
                                <Typography sx={{ color: '#C0392B', fontWeight: '600' }} variant="body2">
                                    {
                                        coupon._class === 'order_discount' ?
                                            `- $${
                                                coupon.discount.type === 'fixed'
                                                    ? coupon.discount._value
                                                    : discount
                                            }`
                                        :
                                            `¡Tiene envío gratis!`
                                    }
                                </Typography>
                            </ListItem>
                        )
                    }

                    {
                        promos_discount !== 0 &&
                            <ListItem sx={{ px: 0 }}>
                                <ListItemText primary="Descuento por promociones" secondary={
                                    `${ cart_promotion.filter(e => e.active).length } activa(s)`
                                } />
                                <Typography sx={{ color: '#C0392B', fontWeight: '600' }}
                                    variant="body2">
                                    - ${ promos_discount }
                                </Typography>
                            </ListItem>
                    }

                    {
                        _id &&
                            <ListItem sx={{ px: 0 }}>
                                <ListItemText primary="Envío" />
                                <Typography sx={{ color: '#229954', fontWeight: '600' }}
                                    variant="body2">
                                    { cost === 0 ? 'Gratis' : `$${cost}` }
                                </Typography>
                            </ListItem>
                    }
                    {
                        _id &&
                            <ListItem sx={{ px: 0 }}>
                                <ListItemText primary="Total + envío" />
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                    ${ total }
                                </Typography>
                            </ListItem>
                    }
                </List>
                    
                <ShippingMethods
                    display={
                        (selected && selected._id)
                        ? false : true
                    }
                />

                <Divider sx={{ my: 2 }} />
                <Box sx={{ mb: 1 }}>
                    <Button onClick={ validate }
                        sx={{ height: '50px', width: '100%' }}
                        variant='contained'>continuar</Button>
                </Box>
                <Typography
                    sx={{ fontSize: '13px' }}>
                    El monto total que se muestra es el precio final con IVA y costos de envío incluido.
                </Typography>
            </Box>
        </Grid>
    );
};

export default TuOrden;