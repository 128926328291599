const {
    format, parseISO, compareAsc, startOfDay, endOfDay,
    startOfWeek, endOfWeek, addDays, getMonth,
    startOfMonth, endOfMonth, subMonths,
    startOfQuarter, endOfQuarter, startOfYear
} = require('date-fns');
const { es } = require('date-fns/locale');

// Top Productos
export const TopProductos = (myDate, all_orders) => {
    const today = parseISO(myDate);
    // Top 10 productos del día
    const today_orders = all_orders.filter(({ order_date }) => 
        compareAsc(parseISO(order_date), startOfDay(today)) >= 0 &&
        compareAsc(endOfDay(today), parseISO(order_date)) >= 0
    );
    // ventas totales
    const todayTotal = getTotal(today_orders);
    // top productos
    const todayTop = orderTopProducts(today_orders);

    // Top 10 productos de la semana
    const week_orders = all_orders.filter(({ order_date }) => 
        compareAsc(parseISO(order_date), startOfWeek(today, { weekStartsOn: 1 })) >= 0 &&
        compareAsc(endOfWeek(today, { weekStartsOn: 1 }), parseISO(order_date)) >= 0
    );
    const weekTotal = getTotal(week_orders);
    const weekTop = orderTopProducts(week_orders);

    // Top 10 productos de la quincena
    const startMonth = startOfMonth(today);
    const endMonth = endOfMonth(today);
    const halfMonth = addDays(new Date(startMonth), 15);
    const dateCompare = compareAsc(halfMonth, today);
    // mitad de mes
    const fortnight_orders = dateCompare <= 0 ? // segunda quincena
        all_orders.filter(({ order_date }) => 
            compareAsc(parseISO(order_date), halfMonth) >= 0 &&
            compareAsc(endMonth, parseISO(order_date)) >= 0
        )
    : // primera quincena
        all_orders.filter(({ order_date }) => 
            compareAsc(parseISO(order_date), startMonth) >= 0 &&
            compareAsc(halfMonth, parseISO(order_date)) >= 0
        );
    const fortnightTop = orderTopProducts(fortnight_orders);

    // Top 10 productos del mes
    const month_orders = all_orders.filter(({ order_date }) => 
        compareAsc(parseISO(order_date), startMonth) >= 0 &&
        compareAsc(endMonth, parseISO(order_date)) >= 0
    );
    const monthTotal = getTotal(month_orders);
    const monthTop = orderTopProducts(month_orders);

    // Top 10 productos del trimestre
    const quarter_orders = all_orders.filter(({ order_date }) => 
        compareAsc(parseISO(order_date), startOfQuarter(today)) >= 0 &&
        compareAsc(endOfQuarter(today), parseISO(order_date)) >= 0
    );
    const quarterTotal = getTotal(quarter_orders);
    const quarterTop = orderTopProducts(quarter_orders);

    // Top 10 productos del año
    const year_to_date_orders = all_orders.filter(({ order_date }) => 
        compareAsc(parseISO(order_date), startOfYear(today)) >= 0 &&
        compareAsc(today, parseISO(order_date)) >= 0
    );
    const yearToDateTotal = getTotal(year_to_date_orders);
    const yearToDateTop = orderTopProducts(year_to_date_orders);

    const { avg_general_ticket, generalTicketData } = getAvgGeneralTicket(year_to_date_orders);
    const { avg_clients_ticket, clientsTicketData } = getAvgNewClientsTicket(year_to_date_orders);

    // Top 10 productos en los últimos 12 meses
    const annual_orders = all_orders.filter(({ order_date }) => 
        compareAsc(parseISO(order_date), subMonths(today, 12)) >= 0 &&
        compareAsc(today, parseISO(order_date)) >= 0
    );
    const annualTop = orderTopProducts(annual_orders);

    return {
        todayTotal, weekTotal, monthTotal, quarterTotal, yearToDateTotal,
        todayTop, weekTop, fortnightTop, monthTop, quarterTop, yearToDateTop,
        annualTop, avg_general_ticket, generalTicketData, avg_clients_ticket,
        clientsTicketData, all_orders
    };
};

// Venta total de Top productos
const getTotal = (orders) => {
    const total = orders.reduce((partialSum, a) => partialSum + a.summary.total, 0);
    return { total, qty: orders.length };
};

// Top productos
const orderTopProducts = (orders) => {
    // maybe use reduce
    let products = [];
    orders.map(order => {
        order.products.map(prod => {
            const { title, variant_title, price, quantity, ...product } = prod;
            // venta producto
            const sum = price * quantity;
            // posición producto en arreglo
            const i = products.findIndex(e => e.prod_var_id === prod.prod_var_id);
            // producto en arreglo
            if (i > -1)
                products[i] = {
                    ...products[i],
                    total_sale: products[i].total_sale + sum,
                    total_qty: products[i].total_qty + quantity
                };
            // agregar producto a arreglo
            else {
                products = [...products,
                { ...product,
                    title: `${title} ${variant_title}`,
                    total_sale: sum,
                    total_qty: quantity
                }];
            }
            return prod;
        });
        return order;
    });
    if (products.length > 0) {
        products.sort((a, b) => b.total_qty - a.total_qty);
        const sliced = products.length > 10 ?
            products.slice(0, 10)
        : products.slice(0, products.length);
        return {
            labels: sliced.map(e => e.title), // título productos
            data: sliced.map(e => e.total_qty) // total de ventas
        }
    } else return { labels: ['Sin ventas'], data: [1] }
};

// Ticket Promedio General
const getAvgGeneralTicket = (orders) => {
    let months = [];
    orders.map(order => {
        const { summary, order_date } = order;
        const month_num = getMonth(parseISO(order_date));
        // mes de venta
        const _month = format(parseISO(order_date), `LLLL`, { locale: es });
        const month = _month.charAt(0).toUpperCase() + _month.slice(1);
        // posición de mes en arreglo
        const i = months.findIndex(e => e.mes === month);
        
        if (i > -1) // mes ya en arreglo
            months[i] = {
                ...months[i],
                clients: months[i].clients + 1,
                ticket_prom: months[i].ticket_prom + summary.total
            };
        else
            months = [...months,
            {
                mes: month, // mes de venta
                clients: 1, // cantidad de clientes por mes
                ticket_prom: summary.total, // venta total por mes
                month_num // posición de mes en arreglo
            }];
        return order;
    });
    // promedio de tickets
    let generalTicketData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const avg_general_ticket = months.map(({ mes, clients, ticket_prom, month_num }) => {
        const ganancia = Math.round((ticket_prom / clients) * 1e2) / 1e2;
        generalTicketData[month_num] = ganancia;
        return { mes, ganancia: `$${ganancia}` };
    });
    return {
        avg_general_ticket, generalTicketData
    };
};

// Ticket Promedio Clientes Nuevos
const getAvgNewClientsTicket = (orders) => {
    let months = [];
    let clients = [];
    orders.map(order => {
        const { summary, address, order_date } = order;
        // cliente nuevo
        const client = clients.find(e => e === address.email);
        if (!client)  {
            // agregamos correo de cliente para omitirlo en la próxima orden
            clients = [...clients, address.email];
            const month_num = getMonth(parseISO(order_date));
            const _month = format(parseISO(order_date), `LLLL`, { locale: es });
            const month = _month.charAt(0).toUpperCase() + _month.slice(1);
            // posición de mes en arreglo
            const i = months.findIndex(e => e.mes === month);
            // mes ya en arreglo
            if (i > -1)
                months[i] = {
                    ...months[i],
                    clients: months[i].clients + 1,
                    ticket_prom: months[i].ticket_prom + summary.total
                };
            else
                months = [...months,
                    {
                        mes: month, // mes de venta
                        clients: 1, // cantidad de clientes por mes
                        ticket_prom: summary.total, // venta total por mes
                        month_num // posición de mes en arreglo
                    }];
        }   
        return order;
    });
    let clientsTicketData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const avg_clients_ticket = months.map(({ mes, clients, ticket_prom, month_num }) => {
        const ganancia = Math.round((ticket_prom / clients) * 1e2) / 1e2;
        clientsTicketData[month_num] = ganancia;
        return { mes, ganancia: `$${ganancia}` };
    });
    return {
        avg_clients_ticket, clientsTicketData
    };
};