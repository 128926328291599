import React, { useEffect, useState }  from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import FeaturedProducts from "../../Tienda/Shop/FeaturedProducts/FeaturedProducts";

import { getOrder } from "../../../actions/shop";

import { GOOGLE_DRIVE_CDN, orderInit } from "../../../constants/constants";

//CSS
import './rastreo.css';
import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';

//Owl carousel
// import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import {
    Container, Typography, Box, Breadcrumbs, Stepper, Step,
    List, Backdrop, CircularProgress, ListItem, ListItemText,
    StepLabel, StepContent, Grid, useMediaQuery
} from '@mui/material';

const RastreoComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const matches = useMediaQuery('(max-width: 950px)');

    // pedido
    const { state } = location;
    // referencia pedido
    const { reference } = useParams();

    const { loggedUser } = useSelector(state => state.user);
    
    const [order, setOrder] = useState(orderInit); // orderTracking
    const {
        order_num, summary, coupon,
        address, products, shipping
    } = order;

    const {
        name, lastname, street, ext_num, int_num, tel,
        postalcode, colony, city, state: address_state
    } = address;
    const { discount, total } = summary;
    const { cost, est_delivery_date } = shipping;
    
    const [steps, setSteps] = useState([]);
    const [activeStep, setActiveStep] = useState();

    useEffect(() => {
        if (state && state.order_num) {
            setOrder(state);
            const { shipping: { shippingStep } } = state;
            setSteps(shippingStep.steps);
            setActiveStep(shippingStep.activeStep);

        } else getMyOrder();
    }, []);

    // cargar orden de pago mediante referencia en url
    const getMyOrder = async () => {
        const data = await getOrder(reference);
        if (data.status) {
            const { resp: { shipping: { shippingStep } } } = data;
            setSteps(shippingStep.steps);
            setActiveStep(shippingStep.activeStep);
            setOrder({
                ...order,
                ...data.resp
            });
        } else
            navigate(`/gracias-por-tu-compra/${reference}`);
    };

    return (
        <>
            {
                order_num !== '' ?
                <>
                    {/* Title y breadcrumb */}
                    <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }}>
                        <Typography variant='h4' sx={{ fontWeight: 'light' }} gutterBottom >Rastreo de pedido</Typography>
                        {
                            loggedUser.email !== '' ?
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" to="/mi-cuenta">
                                        Mi cuenta
                                    </Link>
                                    <Link underline="hover" color="inherit" to="/mi-cuenta/mis-pedidos">
                                        Mis pedidos
                                    </Link>
                                    <Typography color="text.primary">Seguimiento de entrega</Typography>
                                </Breadcrumbs>
                            :
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link underline="hover" color="inherit" to={ `/gracias-por-tu-compra/${reference}` }>
                                        Nota de pedido
                                    </Link>
                                    <Typography color="text.primary">Seguimiento de entrega</Typography>
                                </Breadcrumbs>
                        }
                        
                    </Container>
                    {/* Fin Title y breadcrumb */}

                    {/* Pedido */}
                    <Container maxWidth='xl' sx={{ mt: 6 }}>
                        <Box sx={{ border: '1px solid #CCD1D1', p: 2, borderRadius: '5px', mb: 3 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                {/* Numero de pedido */}
                                <Box>
                                    <Typography variant='body2'>No. de pedido: <b>ONL-{ order_num }</b></Typography>
                                </Box>

                                {/* Fecha estimada */}
                                <Box>
                                    <Typography variant='body2'>Fecha estimada de entrega: <b>{ est_delivery_date }</b></Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Grid container spacing={3}>
                            {/* Imagen producto */}
                            <Grid item xl={5} lg={5} md={6} sm={12} xs={12} order={{ xs: 1, sm: 1, md: 2 }}
                                className="animate__animated animate__fadeInLeft">
                                <Link to="">
                                    {
                                        products[0].images.length > 0 ?
                                            <img src={`${GOOGLE_DRIVE_CDN}${products[0].images[0].id}`}
                                                width='100%' alt={products[0].images[0].alt} />
                                        :
                                            <img src={ no_image } width='100%' alt="producto-nefropolis" />
                                    
                                    }
                                    </Link>
                            </Grid>

                            {/* Seguimiento de producto */}
                            <Grid item xl={7} lg={7} md={6} sm={12} xs={12} sx={{ m: 'auto' }} order={{ xs: 2, sm: 2, md: 1 }}>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {steps.map((step) => (
                                        <Step key={step.label}>
                                            <StepLabel>
                                                {step.label}
                                            </StepLabel>
                                            <StepContent>
                                                <Typography>{step.description}</Typography>
                                                <Box sx={{ mb: 6 }}></Box>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Grid>
                        </Grid>
                    </Container>
                    {/* Fin Pedido */}

                    {/* Información del pedido */}
                    <Container maxWidth='xl' sx={{ mt: 5 }}>
                        <Box sx={{ p: 3, mx: 'auto' }} className="rastreo-box-shadow">
                            <Typography variant='h5' sx={{ fontWeight: 'light' }}>
                                Detalles del pedido:
                            </Typography>
                            <List disablePadding>
                                {/* Producto(s) */}
                                {
                                    products.map(({ prod_var_id, title, variant_title, price, quantity, discount }) =>
                                        <ListItem sx={{ px: 0 }} key={prod_var_id}>
                                            <ListItemText
                                                primary={
                                                    variant_title ?
                                                        `${title} - ${variant_title} (${quantity})`
                                                    :
                                                        `${title} (${quantity})`
                                                }
                                                secondary={
                                                    (discount && discount.total !== 0)
                                                        && `Descuento de $${ discount.total }`
                                                } />
                                            <Typography className="text-success" variant="body2">
                                                ${
                                                    (discount && discount.item_total) ?
                                                        discount.item_total
                                                    :
                                                        price * quantity
                                                }
                                            </Typography>
                                        </ListItem>
                                    )
                                }
                                {/* Cupón en caso de existir */}
                                {
                                    (coupon && coupon.name) &&
                                        <ListItem sx={{ px: 0 }}>
                                            <ListItemText
                                                primary={ coupon.name }
                                                secondary={
                                                    coupon._class === 'order_discount' ?
                                                        `Cupón ${
                                                            coupon.discount.type === 'fixed' ?
                                                                `$${coupon.discount._value}`
                                                            :
                                                                `${coupon.discount._value}%`
                                                        }`
                                                    :
                                                        `Cupón Envío Gratis`
                                                }
                                            />
                                            <Typography className="text-danger" variant="body2">
                                                {
                                                    coupon._class === 'order_discount' ?
                                                        `- $${
                                                            coupon.discount.type === 'fixed'
                                                                ? coupon.discount._value
                                                                : discount
                                                        }`
                                                    :
                                                        `¡Envío gratis!`
                                                }
                                            </Typography>
                                        </ListItem>
                                }
                                {/* Costo de envío */}
                                <ListItem sx={{ px: 0, pb: 0 }} >
                                    <ListItemText primary="Costo de envío" />
                                    <Typography className="text-success" variant="body2">
                                        { cost === 0 ? 'Gratis' : `$${cost}` }
                                    </Typography>
                                </ListItem>
                                {/* Total + envío */}
                                <ListItem sx={{ px: 0, py: 0 }}>
                                    <ListItemText primary="Total + envío" />
                                    <Typography className="text-success" variant="body2">
                                        ${ total }
                                    </Typography>
                                </ListItem>
                            </List>
                            <hr />
                            <Typography variant='h5' sx={{ fontWeight: 'light' }} paragraph>Dirección de envío:</Typography>
                            <Typography variant='body1'>{ `${name} ${lastname}`}</Typography>
                            <Typography variant='body1'>{ `${street} #${ext_num}` }{`${int_num && `, interior ${int_num}`}`}</Typography>
                            <Typography variant='body1'>{ colony }</Typography>
                            <Typography variant='body1'>{ `${city}, ${address_state}, C.P. ${postalcode}`}</Typography>
                            <Typography variant='body1'>Numero de teléfono: {tel}</Typography>
                        </Box>
                    </Container>
                    {/* Fin Información del pedido */}

                    {/* Módulo para promociones */}
                    <Container maxWidth='xl' sx={{ mt: 5 }}>
                        <FeaturedProducts />
                    </Container>
                    {/* Fin módulo para promociones */}
                </>
                :
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
        </>
    );
};

export default RastreoComponent;