import React from 'react';

import { List, ListItem, Avatar, ListItemAvatar, ListItemText,
    ListSubheader, Link } from '@mui/material';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import { useNavigate } from 'react-router-dom';

const AdditionalNoteList = () => {
    const navigate = useNavigate();
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Notas adicionales
                </ListSubheader>
            }>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <LabelImportantIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Dejar vacíos los campos que no se deseen llenar en la carga masiva" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <LabelImportantIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="No todas las columnas del xlsx son tomadas en cuenta (Precio Distribuidor/Precio Farmacia, por ejemplo)" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <LabelImportantIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Recordar que las columnas categorias y palabras_clave son para múltiples valores (Suplementos,Alimentos, por ejemplo)" />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <LabelImportantIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary={
                    <>
                        { `Las imágenes las puede subir desde ` }
                        <Link component="button"
                            underline="none"
                            onClick={ () => navigate('/nefro-admin/productos') }>
                            aquí
                        </Link>
                    </>
                } />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                <Avatar>
                    <LabelImportantIcon />
                </Avatar>
                </ListItemAvatar>
                <ListItemText primary={
                    <>
                        { `Si no conoce el odoo_id, puede consultarlo ` }
                        <Link component="button"
                            underline="none"
                            onClick={ () => navigate('/nefro-admin/productos-odoo') }>
                            aquí
                        </Link>
                    </>
                } />
            </ListItem>
        </List>
    );
};

export default AdditionalNoteList;