import React from 'react';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import MyPaypalButtons from './MyPayPalButtons';

const SCRIPT_PROVIDER_OPTIONS = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "MXN",
    "disable-funding": "card"
};

// PayPal
// const initialOptions = {
//     "client-id": "AW4xIECFGx5dVqHdvcTl9fBZgI7a8dF_qJSX2Mdmdg8GhcVPOEQXOZplhTwK14KViCtG9u9Kp5YYTcd7",
//     currency: "MXN",
//     intent: "capture",
//     "data-client-token": "eyJicmFpbnRyZWUiOnsiYXV0aG9yaXphdGlvbkZpbmdlcnByaW50IjoiMDE1YTRmZjMzNWQzMGY1MjFmN2MzM2U4YmE4NmNiYmJlNzgzYmMxNjY3YjJkZTRjNjk1ZjY0MjQzNzlkN2NhNnxtZXJjaGFudF9pZD1yd3dua3FnMnhnNTZobTJuJnB1YmxpY19rZXk9NjNrdm4zN3Z0MjlxYjRkZiZjcmVhdGVkX2F0PTIwMjItMTAtMDRUMTY6NTc6MjEuODQxWiIsInZlcnNpb24iOiIzLXBheXBhbCJ9LCJwYXlwYWwiOnsiaWRUb2tlbiI6bnVsbCwiYWNjZXNzVG9rZW4iOiJBMjFBQUppb0NFRXRpekZUR25KZU5HOGxWYy1GMHR6a3JsbnJxODU2Tzc5R2RHZmdaai02aU1fb3ZxNVZEM0czMDc4bFEtRVdUYW1SUTZoZlN1d0hzLU5SUk4wTTZRMUJ3In19",
// };

const MyPayPalProvider = ({ paymentValues }) => {
    return (
        <PayPalScriptProvider
            options={ SCRIPT_PROVIDER_OPTIONS }
            currency="MXN"
            deferLoading={ false }>
            <MyPaypalButtons
                paymentValues={ paymentValues } />
        </PayPalScriptProvider>
    );
};

export default MyPayPalProvider;