import React from "react"

//CSS
import './accountcreate.css'

import Logo from '../../../../assets/images/Logo.png'
import { Container, Grid, Typography } from "@mui/material"

const AccountCreateComponent = () => {
    return (
        <>
            <Container maxWidth='xxl' sx={{ mt: 5, p: 4, textAlign: 'center', bgcolor: '#F8F9FA' }}>
                <Grid container spacing={4}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ m: 'auto' }}>
                        <img className="animate__animated animate__fadeInDown animate__slow logo-acc-create"
                            src={Logo} width='40%' alt="Logo" />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ m: 'auto' }} className="message-thx-register">
                        <i className="fa-solid fa-circle-check" style={{ fontSize: '4rem', color: 'green' }}></i>
                        <Typography paragraph variant='h4' sx={{ fontWeight: 'bold', textTransform: 'uppercase', mt: 2 }} className="title-thx">
                            ¡Gracias por registrarse!
                        </Typography>
                        <Typography variant="body1">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth='xl' sx={{ mt: 5 }} className="last-view">
                <Typography variant="h5" align="center" sx={{ fontWeight: 'bold' }} paragraph>Lorem Ipsum is simply dummy text.</Typography>
                <Grid container spacing={4} sx={{ textAlign: 'center' }}>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <i className="fa-solid fa-user" style={{ fontSize: '3rem', color: '#2699FB' }}></i>
                        <Typography sx={{ m: 3, fontSize: '20px' }}>Lorem ipsum dolor sit amet.</Typography>
                        <Typography variant="body1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec lectus
                            non lacus semper vestibulum. Morbi scelerisque massa in est ultrices ornare.
                            Duis nec augue ut mauris ullamcorper finibus eget quis neque.
                        </Typography>
                    </Grid>

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <i className="fa-solid fa-shop" style={{ fontSize: '3rem', color: '#2699FB' }}></i>
                        <Typography sx={{ m: 3, fontSize: '20px' }}>Lorem ipsum dolor sit amet.</Typography>
                        <Typography variant="body1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec lectus
                            non lacus semper vestibulum. Morbi scelerisque massa in est ultrices ornare.
                            Duis nec augue ut mauris ullamcorper finibus eget quis neque.
                        </Typography>
                    </Grid>

                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <i className="fa-solid fa-bag-shopping" style={{ fontSize: '3rem', color: '#2699FB' }}></i>
                        <Typography sx={{ m: 3, fontSize: '20px' }}>Lorem ipsum dolor sit amet.</Typography>
                        <Typography variant="body1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec lectus
                            non lacus semper vestibulum. Morbi scelerisque massa in est ultrices ornare.
                            Duis nec augue ut mauris ullamcorper finibus eget quis neque.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default AccountCreateComponent