import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//Components
import EditDirectory from './EditDirectory/EditDirectory';
import { deleteDoctor, loadDoctors } from '../../../actions/admin/doctor';
import { DOCTOR_UPD } from '../../../constants/admin/doctor_constants';

// import img_1 from '../../../assets/resources/Admins/Diego.jpg';

//MUI
import { Avatar, Box, Container, Fab, ListItemIcon, MenuItem, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { simpleConfirmDialog } from '../../../actions/swal_msg';

const AdminDirectory = () => {
    const dispatch = useDispatch();
    const { doctors } = useSelector(state => state.doctor);

    const [activeDoctor, setActiveDoctor] = useState(DOCTOR_UPD);

    useEffect(() => {
        dispatch(loadDoctors());
    }, []);

    const columns = [

        {
            accessorKey: 'name.firstName',
            header: 'Nombre',
            Cell: ({ renderedCellValue, row }) => (
                <>
                    {
                        row.original.img.length > 0
                            ?
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                }}
                            >
                                <Avatar sx={{ width: '40px', height: '40px' }} alt={row.original.img} src={row.original.img} />
                                <span>{renderedCellValue}</span>
                            </Box>
                            :
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                }}
                            >
                                <Avatar sx={{ bgcolor: '#2699FB', width: '40px', height: '40px', fontSize: '12px' }}>
                                    {row.original.name.firstName.charAt(0)}{row.original.name.lastName.charAt(0)}
                                </Avatar>
                                <span>{renderedCellValue}</span>
                            </Box>
                    }
                </>
            ),
        },
        {
            accessorKey: 'name.lastName',
            header: 'Apellidos',
        },
        {
            accessorKey: 'email',
            enableClickToCopy: true,
            header: 'Email',
        },
        {
            accessorKey: 'speciality',
            header: 'Especialidad',
        },
        {
            accessorKey: 'phone',
            header: 'Teléfono',
        },
    ]

    //Modal editar
    const [openModal, setOpenModal] = useState(false);

    const handleClickModal = (row, closeMenu) => () => {
        const {
            name: {
                firstName,
                lastName
            },
            email, speciality, subEspecialidad, phone, cedulaProfesional,
            municipio, estado, hospital, tipoHospital, asentamiento,
            idMedicoEspecialista, idEspecialidad, idSubEspecialidad, idAsentamiento,
            idMunicipio, idEntidadFederativa, idHospital, idTipoHospital
        } = row;
        setActiveDoctor((e) => ({
            ...e,
            name: firstName, lastname: lastName, email, phone, ced: cedulaProfesional,
            speciality, sub_speciality: subEspecialidad, hospital, hospital_type: tipoHospital,
            state: estado, mun: municipio, colony: asentamiento,
            idMedicoEspecialista, idEspecialidad, idSubEspecialidad, idAsentamiento,
            idMunicipio, idEntidadFederativa, idHospital, idTipoHospital
        }));
        closeMenu();
        setOpenModal(true);
    };

    // eliminar médico
    const selectDeleteDoctor = (row, closeMenu) => async () => {
        closeMenu();
        if (await simpleConfirmDialog('¿Eliminar al médico?', 'Sí, continuar'))
            dispatch(deleteDoctor(row));
    };

    return (
        <>
            <Container maxWidth='xxl' sx={{ mb: 5 }} style={{ marginTop: '90px' }}>
                <Typography variant='h5' sx={{ mb: 4 }}>Lista de médicos</Typography>
                <Box>
                    <MaterialReactTable
                        columns={columns}
                        data={ doctors }
                        enableColumnFilterModes
                        enablePinning
                        enableRowActions
                        positionToolbarAlertBanner="bottom"
                        localization={MRT_Localization_ES}
                        renderDetailPanel={({ row }) => (
                            <Box display='flex' justifyContent='space-around' >
                                <Box>
                                    <Typography variant='body1'>
                                        <b>Cédula profesional: </b>{row.original.cedpro}
                                    </Typography>
                                    <Typography variant='body1'>
                                        <b>Sub-Especialidad: </b>{row.original.cedpro}
                                    </Typography>
                                    <Typography variant='body1'>
                                        <b>Hospital: </b>{row.original.cedpro}
                                    </Typography>
                                    <Typography variant='body1'>
                                        <b>Tipo de hospital: </b>{row.original.cedpro}
                                    </Typography>
                                </Box>
                                <Box>
                                <Typography variant='body1'>
                                    <b>Estado: </b>{row.original.cedpro}
                                </Typography>
                                <Typography variant='body1'>
                                    <b>Municipio: </b>{row.original.cedpro}
                                </Typography>
                                <Typography variant='body1'>
                                    <b>Colonia: </b>{row.original.cedpro}
                                </Typography>
                                </Box>
                            </Box>
                        )}
                        renderRowActionMenuItems={({ row: { original }, closeMenu }) => [
                            <MenuItem
                                key={0}
                                onClick={ handleClickModal(original, closeMenu) }
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <EditIcon />
                                </ListItemIcon>
                                Editar
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={ selectDeleteDoctor(original, closeMenu) }
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <DeleteIcon />
                                </ListItemIcon>
                                Eliminar
                            </MenuItem>,
                        ]}
                    />
                </Box>
            </Container>

            <Fab
                LinkComponent={Link}
                to='/nefro-admin/nuevo-medico'
                color="primary"
                sx={{
                    position: "fixed",
                    bottom: '35px',
                    right: '35px',
                    '&:hover': {
                        color: '#fff',
                    },
                }}
            >
                <AddIcon />
            </Fab>

            <EditDirectory
                openModal={openModal}
                setOpenModal={setOpenModal}
                activeDoctor={activeDoctor}
                setValues={setActiveDoctor}
            />
        </>
    );
};

export default AdminDirectory;