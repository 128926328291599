import React, { useState } from 'react';

//Table
import MaterialTable, { MTableToolbar } from "material-table";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CarritoAbandonadoDialog from './CarritoAbandonadoDialog';
import { adminOrder, lostOrderColumns } from '../../../constants/constants';

const PedidoAbandonado = ({ data, setData }) => {
    // estado de Dialog
    const [dialog, setDialog] = useState(false);
    // orden seleccionada
    const [selectedOrder, setSelectedOrder] = useState(adminOrder);
    // pedido seleccionado
    const selected = (order_num) => {
        const _order = data.find(e => e.order_num === order_num);
        if (_order) {
            setSelectedOrder(_order);
            setDialog(true);
        }
    };

    return (
        <>
            <div style={{ width: '100%', height: '100%' }}>
                <MaterialTable
                    title="Pedidos no completados"
                    data={data}
                    columns={lostOrderColumns}
                    options={{
                        exportButton: true,
                        filtering: true,
                        headerStyle: {
                            zIndex: 1
                        },
                    }}
                    components={{
                        Toolbar: props => (<MTableToolbar {...props} classes={{ root: "dummy-class" }} />),
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: "No hay datos por mostrar",
                            addTooltip: 'Agregar',
                            deleteTooltip: 'Eliminar',
                            editTooltip: 'Editar',
                            filterRow: {
                                filterTooltip: 'Filtrar'
                            },
                            editRow: {
                                deleteText: '¿Segura(o) que quiere eliminar?',
                                cancelTooltip: 'Cancelar',
                                saveTooltip: 'Guardar'
                            }
                        },
                        grouping: {
                            placeholder: "Arrastre un encabezado aquí para agrupar",
                            groupedBy: 'Agrupado por:'
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'filas',
                            labelRowsPerPage: 'Filas por página:',
                            firstAriaLabel: 'Primera página',
                            firstTooltip: 'Primera página',
                            previousAriaLabel: 'Página anterior',
                            previousTooltip: 'Página anterior',
                            nextAriaLabel: 'Página siguiente',
                            nextTooltip: 'Página siguiente',
                            lastAriaLabel: 'Última página',
                            lastTooltip: 'Última página'
                        },
                        toolbar: {
                            addRemoveColumns: 'Agregar o eliminar columnas',
                            nRowsSelected: '{0} fila(s) seleccionada(s)',
                            showColumnsTitle: 'Mostrar columnas',
                            showColumnsAriaLabel: 'Mostrar columnas',
                            exportTitle: 'Exportar',
                            exportAriaLabel: 'Exportar',
                            exportName: "Exportar como CSV",
                            exportPDFName: "Exportar como PDF",
                            searchTooltip: 'Buscar',
                            searchPlaceholder: 'Buscar'
                        }
                    }}
                    actions={[
                        {
                            icon: () => <RemoveRedEyeIcon />,
                            tooltip: 'Ver pedido',
                            onClick: (event, rowData) => {
                                selected(rowData.order_num)
                            }

                        }
                    ]}
                />

                <CarritoAbandonadoDialog
                    setData={setData}
                    selectedOrder={selectedOrder}
                    setSelectedOrder={setSelectedOrder}
                    dialog={dialog}
                    setDialog={setDialog} />
            </div>
        </>
    );
};

export default PedidoAbandonado;