import React from 'react';
// import * as XLSX from 'xlsx';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

import ProductUpdate from './ProductUpdate';
import ProductMassiveUpdate from './ProductMassiveUpdate';
import { productsInfo } from '../../../actions/admin/advanced';

import {
    Grid, Typography, Card, CardContent, CardActions,
    Button, Alert, AlertTitle
} from '@mui/material';
import ProductStock from '../advanced/sections/ProductStock';
// import OrderFilter from '../advanced/sections/OrderFilter';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const ProductTools = () => {

    const getProducts = async () => {
        const req = await productsInfo();
        if (req.status)
            exportToExcel(req.resp);
    };

    const exportToExcel = async (array) => {
        const ws = XLSX.utils.json_to_sheet(array);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `productos_nefroapp_nueva_plantilla${fileExtension}`);
    };

    return (
        <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
                <Alert severity="warning">
                <Typography variant="h6" gutterBottom>
                    <AlertTitle>Configuración extra</AlertTitle>
                    Sección exclusiva para miembros de IT
                </Typography>
                </Alert>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Productos de Tienda y ID de Odoo
                        </Typography>
                        <Typography variant="body2">
                            Exportar información de los productos de la tienda a documento .xlsx
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small"
                            onClick={ getProducts }>Exportar</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <ProductUpdate />
            </Grid>
            <Grid item xs={12} md={4}>
                <ProductMassiveUpdate />
            </Grid>
            <Grid item xs={12} md={4}>
                <ProductStock />
            </Grid>
            {/* <Grid item xs={12} md={4}>
                <OrderFilter />
            </Grid> */}
            {/* <Grid item md={6} xs={12}>
                <ProductPriceUpdate />
            </Grid> */}
        </Grid>
    )
};

export default ProductTools;