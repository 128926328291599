import React from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';

const BasicDateTimePicker = ({ values, setValues }) => {
    const { publication_date } = values;
    
    return (
        <LocalizationProvider dateAdapter={ AdapterDateFns }>
            <DateTimePicker
                label="Fecha de publicación"
                value={ publication_date }
                onChange={(newValue) => {
                    setValues({ ...values, publication_date: newValue });
                }}
                renderInput={
                    (params) =>
                    <TextField fullWidth
                        {...params} />
                }
            />
        </LocalizationProvider>
    );
};

export default BasicDateTimePicker;