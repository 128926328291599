import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import BannerImageList from './BannerImageList';
import { uploadBannerImages } from '../../../../actions/admin/banner';

import {
    Button, Card, CardActions, CardContent, CardMedia,
    TextField, Container, DialogActions, IconButton,
    Collapse, ImageList, ImageListItem, useMediaQuery
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    })
}));

const DesktopContainer = ({ banner, setBanner, deletedImages, setDeletedImages }) => {
    const { type, images } = banner;
    const matches = useMediaQuery('(max-width: 768.5px)');

    const [loading, setLoading] = useState(false);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        
        setBanner(e => ({
            ...e,
            images: items
        }));
    };
    
    const loadedImages = ({ target }) => {
        let count = images.length; // total de imágenes
        let exceeded = false; // se alcanzó el límite?
        if (count >= 20) {
            alert('No puede agregar más imágenes');
            return;
        }
        if (target.files.length > 0) {
            let target_files = [];
            [...target.files].forEach(element => {
                if (count >= 20) exceeded = true;
                else {
                    if (['image/jpeg', 'image/png', 'image/webp'].includes(element.type)) {
                        // target_files.push(element);
                        target_files = [...target_files, {
                            img: element, url: '', expanded: true }
                        ];
                        count++;
                    }
                }
            });
            setBanner(e => ({
                ...e,
                images: [...e.images, ...target_files]
            }));
            if (exceeded) alert('Se alcanzó el límite de imágenes');
        }
    };
    
    // remueve imágenes de producto
    const removeImg = name => () => {
        setBanner(e => ({
            ...e,
            images: e.images.filter(i => (i.name !== name))
        }));
    };

    const save = async() => {
        // setBanner({ ...banner, desktopImages: images });
        // handleCloseDialog();
        setLoading(true);
        if (images.length <= 0 ||
            (images.length > 0 && !images.find(e => e.url === ''))) {
            const req = await uploadBannerImages(banner[type], type, images, deletedImages[type]);
            if (req.status) {
                setBanner({
                    ...banner,
                    [type]: {
                        ...banner[type],
                        ...req.resp
                    },
                    images: []
                });
                setDeletedImages({
                    ...deletedImages,
                    [type]: []
                });
                alert('Cambios guardados');
            }
        } else alert('Ingrese URL de todas las imágenes');
        setLoading(false);
    };

    const handleExpandClick = img => () => {
        setBanner(e => ({
            ...e,
            images: images.map(i => (i.img === img) ? { ...i, expanded: !i.expanded } : i)
        }));
    };

    // URL de banner
    const handleInputChange = img => ({ target: { value } }) => {
        if (value.length < 1001)
            setBanner(e => ({
                ...e,
                images: images.map(i => (i.img === img) ? { ...i, url: value } : i)
            }));
    };

    return (
        <Container maxWidth='xl'>
            <BannerImageList
                banner={ banner }
                setBanner={ setBanner }
                deletedImages={ deletedImages }
                setDeletedImages={ setDeletedImages }
            />

            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="file">
                    {(provided) => (
                        <ImageList gap={20} cols={ matches ? 2 : 3 }
                            { ...provided.droppableProps }
                            ref={ provided.innerRef }>
                            {
                                images.length > 0 &&
                                images.map(({ img, url, expanded }, i) => {
                                    return (
                                        <Draggable key={img.name} draggableId={img.name} index={i}>
                                            {(provided) => (
                                                <Card key={i}
                                                    ref={ provided.innerRef }
                                                    { ...provided.draggableProps }
                                                    { ...provided.dragHandleProps }>
                                                    {/* <ImageListItem sx={
                                                        matches ? { height: '100% !important' } : { height: '250px !important' }
                                                    }>
                                                        <img
                                                            src={URL.createObjectURL(item)}
                                                            alt={item}
                                                            loading="lazy"
                                                            style={{ border: '1px solid green' }}
                                                        />
                                                        <ImageListItemBar
                                                            actionIcon={
                                                                <IconButton
                                                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                                    onClick={removeImg(item.name)}
                                                                >
                                                                    <RemoveCircleIcon />
                                                                </IconButton>
                                                            }
                                                        />
                                                    </ImageListItem> */}
                                                    <CardMedia
                                                        component="img"
                                                        alt={ 'banner-nefropolis' }
                                                        height="140"
                                                        image={ URL.createObjectURL(img) }
                                                    />
                                                    <CardActions>
                                                        <IconButton
                                                            onClick={ removeImg(img) }
                                                        >
                                                            <RemoveCircleIcon />
                                                        </IconButton>
                                                        <ExpandMore
                                                            expand={ expanded }
                                                            onClick={ handleExpandClick(img) }
                                                            aria-expanded={ expanded }
                                                            aria-label="show more"
                                                        >
                                                            <ExpandMoreIcon />
                                                        </ExpandMore>
                                                    </CardActions>
                                                    <Collapse in={ expanded } timeout="auto" unmountOnExit>
                                                        <CardContent>
                                                            <TextField fullWidth
                                                                variant="standard"
                                                                label='URL'
                                                                size='small'
                                                                name="URL"
                                                                value={ url }
                                                                onChange={ handleInputChange(img) }
                                                            />
                                                        </CardContent>
                                                    </Collapse>
                                                </Card>
                                            )}
                                        </Draggable>
                                    );
                                })
                            }
                            {provided.placeholder}
                            <ImageListItem sx={{ height: '250px !important' }} className='uploader'>
                                <input
                                    accept="image/*"
                                    type="file"
                                    id="select-image-1"
                                    multiple
                                    name="images"
                                    onChange={loadedImages}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="select-image-1" style={{ marginBottom: 0 }}>
                                    <IconButton variant="outlined" color="primary" component="span">
                                        <CameraAltIcon />
                                    </IconButton>
                                </label>
                            </ImageListItem>
                        </ImageList>
                    )}
                </Droppable>
            </DragDropContext>
            <DialogActions>
                {
                    loading ?
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                        >
                            Guardando
                        </LoadingButton>
                    :
                        <Button onClick={ save }>Guardar cambios</Button>
                }
            </DialogActions>
        </Container>
    );
};

export default DesktopContainer;